import React, { useState, lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import HomeHeader from '../components/HomeHeader'
import HomeFooter from '../components/HomeFooter'
import Loading from '../../../component/Loading'
const HomePage = lazy(() => import('./Home'))

function Home (props) {
  const { match } = props
  const [mainIndex, setMainIndex] = useState(0)
  const [paymentSlide, setPaymentSlide] = useState(false)
  return (
    <>
      <HomeHeader home {...props}
        paymentSlide={paymentSlide}
        setPaymentSlide={setPaymentSlide}
        setMainIndex={setMainIndex}
        showNotificationIcon
        sportsRequired showBalance/>
      <Suspense fallback={<Loading />}>
        <HomePage {...props} homePage mainIndex={mainIndex} paymentSlide={paymentSlide}
        setPaymentSlide={setPaymentSlide} isPublic={match?.path === '/home/:sportsType/v1'}/>
      </Suspense>
      <HomeFooter {...props} isPublic={props.match?.path === '/home/:sportsType/v1'} />
    </>
  )
}

Home.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string
  })
}

export default Home
