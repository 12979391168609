import React, { Fragment } from 'react'
import Skeleton from 'react-loading-skeleton'
import PropTypes from 'prop-types'
import { Card } from 'reactstrap'

function SkeletonUpcoming (props) {
  const { scoredData } = props
  return (
    <Fragment>
      {
        scoredData
          ? (
            <Fragment>
              {Array(1)
                .fill()
                .map((item, index) => (
                  <Card key={index} className='scorecard'>
                    <div className='match-date-time d-flex justify-content-between align-items-center'>
                      <div>
                        <Skeleton height={20} width={80} />
                      </div>
                      <div>
                        <Skeleton height={25} width={80} />
                      </div>
                    </div>
                    <div className="scorecard-item">
                      <div className="first">
                        <p><Skeleton height={50} style={{ borderRadius: '50%' }} width={50} /></p>
                        <p><Skeleton height={20} width={40} /></p>
                      </div>
                      <div className="second">
                        <div><Skeleton height={50} width={50} /></div>
                        <div className='dash'><Skeleton height={20} width={20} /></div>
                        <div><Skeleton height={50} width={50} /></div>
                      </div>
                      <div className="third">
                        <p><Skeleton height={50} style={{ borderRadius: '50%' }} width={50} /></p>
                        <p><Skeleton height={20} width={40} /></p>
                      </div>
                    </div>
                    <div className="w-txt">
                      {' '}
                      <Skeleton height={10} width={350} />
                      {' '}
                    </div>
                  </Card>
                ))}
            </Fragment>
            )
          : (
            <Fragment>
              {Array(3)
                .fill()
                .map((item, index) => (
                  <Card key={index} className="leagues-card border-0">
                    <div key={index} className="match-box px-0">
                      <div key={index} className="match-i">
                        <center>
                          <Skeleton height={15} width={200} />
                        </center>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <div className="team d-flex align-items-center">
                            <div className="t-img"><Skeleton circle={true} className="border-0" height={60} width={60} /></div>
                            <div className="name">
                              <h3><Skeleton className="border-0" width={60} /></h3>
                            </div>
                          </div>
                          <div className="time"><Skeleton className="border-0" width={60} /></div>
                          <div className="team d-flex align-items-center">
                            <div className="name">
                              <h3><Skeleton className="border-0" width={60} /></h3>
                            </div>
                            <div className="t-img"><Skeleton circle={true} className="border-0" height={60} width={60} /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                ))}
            </Fragment>
            )
      }
    </Fragment>
  )
}

SkeletonUpcoming.propTypes = {
  scoredData: PropTypes.bool
}
export default SkeletonUpcoming
