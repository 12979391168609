/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/prefer-default-export */
import axios from '../../axios/instanceAxios'
import {
  GET_FETCH_LIVE_INNING, GET_FULL_SCORED, CLEAR_GET_FULL_SCORED, CLEAR_GET_FETCH_LIVE_INNING
} from "../../constants";

const errMsg = 'Server is unavailable.'

export const getFullScored = (matchId, token) => (dispatch) => { // get ads List
  dispatch({ type: CLEAR_GET_FULL_SCORED });
  axios.get(`/scorecard/${matchId}/v1`,  { headers: { Authorization: token } }).then((response) => {
    dispatch({
      type: GET_FULL_SCORED,
      payload: {
        data: response.data.data,
        resMessage: response.data.message,
        resStatus: true,
        isFetchFullScored: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: GET_FULL_SCORED,
      payload: {
        data: [],
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false,
        isFetchFullScored: false
      }
    })
  })
}


export const getFetchLiveInnings = (matchId, nInningNumber) => (dispatch) => { // get ads List
  dispatch({ type: CLEAR_GET_FETCH_LIVE_INNING });
  axios.get(`/live-innings/${matchId}/v2?nInningNumber=${nInningNumber}`).then((response) => {
    dispatch({
      type: GET_FETCH_LIVE_INNING,
      payload: {
        data: response.data.data,
        resMessage: response.data.message,
        resStatus: true,
        isFetchLiveInning: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: GET_FETCH_LIVE_INNING,
      payload: {
        data: [],
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false,
        isFetchLiveInning: false
      }
    })
  })
}
