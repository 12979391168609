import React, { useState, useEffect, useRef } from 'react'
import { Alert, Button, Input, Modal, ModalBody } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import BankDetail from '../../../../../common/src/components/User/BankDetail'
import success from '../../../assests/images/DepositSuccess.svg'
import Loading from '../../../component/Loading'
import { useHistory } from 'react-router-dom'
import { currencyConverter } from '../../../helper'

function WithdrawPage (props) {
  const {
    resMessage,
    modalMessage,
    loading,
    bankData,
    addWithdraw,
    location,
    isAddedWithdraw,
    withdrawPending,
    onCheckWithDrawLimit,
    currencyLogo,
    settingValidation,
    paymentList,
    onGetPaymentList,
    token,
    withdrawMessage,
    userInformation
  } = props
  const history = useHistory()
  const [PaymentGateway, setPaymentGateway] = useState('')
  const [PaymentGatewayKey, setPaymentGatewayKey] = useState('')
  const [Amount, setAmount] = useState(0)
  const [Error, setError] = useState('')
  const [Error2, setError2] = useState('')
  const [validationWithdraw, setValidationWithdraw] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [withdrawModal, setWithdrawModal] = useState(false)
  // const [bankDetail, setBankDetail] = useState({ sBankName: '', sBranchName: '', sAccountHolderName: '', sAccountNo: '', sIFSC: '' })
  const previousProps = useRef({ resMessage, bankData, isAddedWithdraw, withdrawPending }).current

  useEffect(() => { // Handle bankData
    onGetPaymentList()
  }, [token])

  useEffect(() => {
    paymentList && paymentList.length > 0 && paymentList.map((data) => {
      if (data?.eKey === 'PAYBROKERS') {
        setPaymentGateway(data._id)
        setPaymentGatewayKey(data.eKey)
      } else {
        setPaymentGateway('')
        setPaymentGatewayKey('')
      }
      return true
    })
  }, [paymentList])

  // useEffect(() => { // Handle bankData
  //   if (previousProps.bankData !== bankData) {
  //     if (bankData && bankData._id) {
  //       setBankDetail({ ...bankDetail, sBankName: bankData.sBankName, sBranchName: bankData.sBranchName, sAccountHolderName: bankData.sAccountHolderName, sAccountNo: bankData.nAcNo, sIFSC: bankData.sIFSC })
  //     }
  //   }
  //   return () => {
  //     previousProps.bankData = bankData
  //   }
  // }, [bankData])

  useEffect(() => {
    if (settingValidation) {
      setValidationWithdraw(settingValidation)
      setAmount(settingValidation?.nMin)
    }
  }, [settingValidation])

  useEffect(() => { // Handle bankData
    if (previousProps.withdrawPending !== withdrawPending) {
      if (withdrawPending === false) {
        addWithdraw(PaymentGateway, Amount)
      } else if (withdrawPending === true && withdrawMessage) {
        setModalOpen(true)
        setError2(withdrawMessage)
      }
    }
    return () => {
      previousProps.withdrawPending = withdrawPending
    }
  }, [withdrawPending])

  useEffect(() => {
    if (modalOpen) {
      setInterval(() => {
        setModalOpen(false)
      }, 2000)
    }
  }, [modalOpen])

  useEffect(() => {
    if (previousProps.isAddedWithdraw !== isAddedWithdraw) {
      if (isAddedWithdraw) {
        setWithdrawModal(true)
      }
    }
    return () => {
      previousProps.isAddedWithdraw = isAddedWithdraw
    }
  }, [isAddedWithdraw])

  function handleOnClick () {
    props.history.push({ pathname: '/profile' })
  }

  const handleonKeyPress = (e) => {
    if (e.key === '+' || e.key === '-' || e.key === 'e' || e.key === 'E') {
      e.preventDefault()
    }
  }

  function withDraw () {
    if (PaymentGateway && (Amount >= 1)) {
      onCheckWithDrawLimit()
    } else {
      if (location && location.state && location.state.userInformation && (Amount > location.state.userInformation.nCurrentWinningBalance)) {
        setError(<FormattedMessage id="Withdraw_not_greaterThan_WinBalance" />)
        setModalOpen(true)
      }
    }
  }
  const handleChangeAmount = (e) => {
    // if (e.target.value > validationWithdraw?.nMax || e.target.value < validationWithdraw?.nMin) setError(<Fragment><FormattedMessage id="Withdraw_Between_To" /> {`${currencyLogo} ${validationWithdraw?.nMin}`} <FormattedMessage id="Common_and" /> {`${currencyLogo} ${validationWithdraw?.nMax}`} </Fragment>)
    if (e.target.value > validationWithdraw?.nMax) {
      setError(`${validationWithdraw?.sMaxMessage}`)
    } else if (e.target.value < validationWithdraw?.nMin) {
      setError(`${validationWithdraw?.sMinMessage}`)
    } else setError('')
    setAmount(e.target.value)
  }
  return (
    <>
      {loading && <Loading />}
      { modalOpen ? <Alert isOpen={modalOpen} color="primary">{Error2}</Alert> : ''}
      {userInformation && <div className='withdraw-container deposit-banner'>
        <p><FormattedMessage id='Available_Cash_Balance' /></p>
        <h2>{currencyLogo}{currencyConverter(userInformation?.nCurrentTotalBalance)}</h2>
      </div>}
        <div className="user-container bg-white no-footer">
          {modalMessage && !withdrawModal ? <Alert isOpen={modalMessage} color="primary">{resMessage}</Alert> : ''}
          <div className="form pb-0 pt-3 deposit-o" >
            <p className="m-msg text-left mt-0"><FormattedMessage id="withDraw_amountWithdraw" /></p>
            <div className="position-relative pb-4">
              <span className="c-icon">{currencyLogo}</span>
              <Input type="number" autoComplete='off' className="m" value={Amount} onChange={handleChangeAmount} min="0" onKeyPress={handleonKeyPress}/>
              {Error && <p className="error-text">{Error}</p>}
            </div>
          </div>
          <div className="form pb-0 pt-3 deposit-o">
            <h4 className="w-title"><FormattedMessage id="withDraw_withdrawalAmount" /></h4>
            <ul className="payment-o">
              {
                paymentList && paymentList.length > 0 && paymentList.map((data, index) => {
                  return (
                    <li className={`d-flex align-items-center ${!data.bEnable && 'disable'}`} key={data._id} disabled={!data.bEnable}>
                      <Input type="radio" autoComplete='off' name="Withdrawal" id={`withdraw${index}`} className="d-none" value='CASHFREE' onChange={() => {
                        setPaymentGateway(data._id)
                        setPaymentGatewayKey(data.eKey)
                      }}/>
                      <label htmlFor={`withdraw${index}`}>
                        <span>
                          <b>
                            {data.sTitle}
                          </b>
                          <br />
                            {data.sInfo}
                        </span>
                      </label>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <div className="btn-bottom position-relative mx-3 mb-3 text-center">
            {
              PaymentGatewayKey === 'AMAZON' && (
                <button onClick={() => {
                  history.push('/more/amazon-withdraw-tnc')
                }} className="btn-link"><FormattedMessage id="League_Amazon_Gifts" /></button>
              )
            }
            <Button disabled={!Amount || !PaymentGateway || Error} color="primary" className="w-100 d-block" onClick={withDraw}><FormattedMessage id="User_proceed" /></Button>
            {/* <button onClick={() => {
              history.push('/bankdetails')
            }} className="btn-link">
            {bankDetailsAdded ? <FormattedMessage id="withDraw_viewBankDetails" /> : <FormattedMessage id="withDraw_addBankDetails" />}
            </button> */}
          </div>
          <Modal isOpen={withdrawModal} className='payment-modal'>
            <ModalBody className='payment-modal-body d-flex flex-column justify-content-center align-items-center'>
              <img src={success} alt='Success'></img>
              <h2><FormattedMessage id='Withdrawal_request' /></h2>
              <p><FormattedMessage id='Withdraw_amount' ></FormattedMessage><span>{currencyLogo + currencyConverter(Amount)}</span><FormattedMessage id='Sent_success' /></p>
              <Button onClick={handleOnClick}><FormattedMessage id='Okay'/></Button>
            </ModalBody>
          </Modal>
        </div>
      </>
  )
}
WithdrawPage.propTypes = {
  modalMessage: PropTypes.bool,
  loading: PropTypes.bool,
  showBankTab: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  AddBankDetails: PropTypes.func,
  setBankTab: PropTypes.func,
  onGetBankDetails: PropTypes.func,
  onCheckWithDrawLimit: PropTypes.func,
  handleChange: PropTypes.func,
  onGetPaymentList: PropTypes.func,
  resMessage: PropTypes.string,
  paymentList: PropTypes.array,
  settingValidation: PropTypes.object,
  withdrawPending: PropTypes.bool,
  isAddedWithdraw: PropTypes.bool,
  setLoading: PropTypes.func,
  addWithdraw: PropTypes.func,
  bankData: PropTypes.object,
  currencyLogo: PropTypes.string,
  withdrawMessage: PropTypes.string,
  token: PropTypes.string,
  userInformation: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.shape({
      userInformation: PropTypes.object
    })
  })
}
export default BankDetail(WithdrawPage)
