import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTournamentList, myMatchesList } from '../../redux/actions/match'
import { GetUserProfile } from '../../redux/actions/profile'
import qs from 'query-string'

export const TournamentList = (Component) => {
  return (props) => {
    const { match, subIndex, mainIndex, history, location } = props
    const dispatch = useDispatch()
    const [tournaments, setTournaments] = useState([])
    const [url, setUrl] = useState('')
    const [loading, setLoading] = useState(false)
    const token = useSelector(state => state.auth.token) || localStorage.getItem('Token')
    const getUrlLink = useSelector(state => state.url.getUrl)
    const resStatus = useSelector(state => state.match.resStatus)
    const resMessage = useSelector(state => state.match.resMessage)
    const currencyLogo = useSelector(state => state.settings.currencyLogo)
    const tournamentList = useSelector(state => state.match.tournamentList)
    const matchList = useSelector(state => state.match.matchList)
    const userInfo = useSelector(state => state.profile.userInfo)
    const activeSports = useSelector(state => state.activeSports.activeSports)
    const previousProps = useRef({ resMessage, resStatus, tournamentList, matchList }).current
    const obj = qs.parse(location.search)
  
    useEffect(() => {
      if (!(history && history.location && history.location.pathname && history.location.pathname.includes('/v1'))) {
        getTournamentListFunc(match.params.sportsType)
        setLoading(true)
      } else if (match && match.params && match.params.sportsType && obj && obj.matchType && obj.matchType !== 'L' && obj.matchType !== 'CMP') {
        getTournamentListFunc(match.params.sportsType)
        setLoading(true)
      }
    }, [])

    useEffect(() => {
      if (subIndex === 1) {
        getTournamentListFunc(match.params.sportsType)
      } else if(mainIndex && subIndex !== undefined && token) {
        getMyLiveCmpMatches(mainIndex, subIndex)
      }
    }, [mainIndex, subIndex, token])

    useEffect(() => {
      if (history && history.location && history.location.pathname && history.location.pathname.includes('/v1') && (obj && obj.matchType && (obj.matchType === 'L' || obj.matchType === 'CMP'))) {
        setTournaments([])
      } else if (history && history.location && history.location.pathname && history.location.pathname.includes('/v1') && subIndex === 1) {
        getTournamentListFunc(match.params.sportsType)
      }
    }, [subIndex])
    
    useEffect(() => {
      if (previousProps.tournamentList !== tournamentList) {
        if (tournamentList) {
          let top = [], bottom = []
          if (tournamentList.length !== 0) {
            top = tournamentList.length > 0 && tournamentList.filter(match => match.bMatchOnTop).sort((a, b) => new Date(a.dStartDate) - new Date(b.dStartDate))
            bottom = tournamentList.length > 0 && tournamentList.filter(match => !match.bMatchOnTop).sort((a, b) => new Date(a.dStartDate) - new Date(b.dStartDate))
          }
          setTournaments([...top, ...bottom])
          setLoading(false)
        }
      }
      return () => {
        previousProps.tournamentList = tournamentList
      }
    }, [tournamentList])

    useEffect(() => {
      if (previousProps.matchList !== matchList) { // handle the response
        if (matchList) {
          let top = [], bottom = []
          if (matchList.length !== 0) {
            top = matchList.length !== 0 && matchList.filter(match => match.bMatchOnTop).sort((a, b) => new Date(a.dStartDate) - new Date(b.dStartDate))
            bottom = matchList.length !== 0 && matchList.filter(match => !match.bMatchOnTop).sort((a, b) => new Date(a.dStartDate) - new Date(b.dStartDate))
          }
          setTournaments([...top, ...bottom])
          setLoading(false)
        }
      }
      return () => {
        previousProps.matchList = matchList
      }
    }, [matchList])

    useEffect(() => {
      if (getUrlLink) {
        setUrl(getUrlLink)
      }
    }, [getUrlLink])

    useEffect(() => {
      if (previousProps.resMessage !== resMessage) {
        if (resStatus) {
          setLoading(false)
        }                                                       
      }
      return () => {
        previousProps.resMessage = resMessage
      }
    }, [resMessage, resStatus])

    function getTournamentListFunc (sportsType) {
      dispatch(getTournamentList(sportsType))
    }

    function  getMyLiveCmpMatches(MainIndex, subIndex) {
      if (subIndex) {
        let sportsType = ''
        const activeSportsArr = [...activeSports];
        activeSportsArr.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => ((a.nPosition > b.nPosition) ? 1 : -1));
        sportsType =  activeSportsArr[MainIndex - 1].sKey
        // if (subIndex === 1) {
        //   sportsType && dispatch(getTournamentList(sportsType))
        // } else 
        if (subIndex === 2) {
          sportsType && dispatch(myMatchesList(sportsType, token, 'L', 'TOURNAMENT'))
        } else if (subIndex === 3) {
          sportsType && dispatch(myMatchesList(sportsType, token, 'CMP', 'TOURNAMENT'))
        }
        setLoading(true)
      }
    }

    function onGetUserProfile() {
      token && dispatch(GetUserProfile(token))
    }

    return (
      <Component
        {...props}
        resStatus={resStatus}
        resMessage={resMessage}
        activeSports={activeSports}
        token={token}
        loading={loading}
        userInfo={userInfo}
        currencyLogo={currencyLogo}
        onGetUserProfile={onGetUserProfile}
        url={url}
        list={tournaments}
      />
    )
  }
}

export default TournamentList