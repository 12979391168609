import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import HomeHeader from '../components/HomeHeader'
import HomeFooter from '../components/HomeFooter'
import Loading from '../../../component/Loading'
const GameLeaderboardPage = lazy(() => import('./GameLeaderboard'))

function GameLeaderboard (props) {
  return (
    <>
      <HomeHeader {...props} active/>
        <Suspense fallback={<Loading />}>
          <GameLeaderboardPage {...props} />
        </Suspense>
        <HomeFooter {...props} isPublic={props.match?.path === '/game/leader-board/v1'}/>
    </>
  )
}

GameLeaderboard.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string
  })
}

export default GameLeaderboard
