export const countries = [
  {
    id: 1,
    country_name: 'Brazil'
  }
]

export const states = [
  { nCountryId: 1, sName: 'Acre', id: 1, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Alagoas', id: 2, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Amapá', id: 3, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Amazonas', id: 4, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Bahia', id: 5, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Ceará', id: 6, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Distrito Federal', id: 7, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Espírito Santo', id: 8, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Goiás', id: 9, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Maranhão', id: 10, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Mato Grosso', id: 11, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Mato Grosso do Sul', id: 12, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Minas Gerais', id: 13, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Pará', id: 14, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Paraíba', id: 15, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Paraná', id: 16, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Pernambuco', id: 17, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Piauí', id: 18, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Rio de Janeiro', id: 19, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Rio Grande do Norte', id: 20, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Rio Grande do Sul', id: 21, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Rondônia', id: 22, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Roraima', id: 23, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Santa Catarina', id: 24, eStatus: 'Y' },
  { nCountryId: 1, sName: 'São Paulo', id: 25, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Sergipe', id: 26, eStatus: 'Y' },
  { nCountryId: 1, sName: 'Tocantins', id: 27, eStatus: 'Y' }
]

export const cities = [
  { id: 0, city_name: 'Acrelândia', state_id: 1 },
  { id: 1, city_name: 'Assis Brasil', state_id: 1 },
  { id: 2, city_name: 'Brasiléia', state_id: 1 },
  { id: 3, city_name: 'Bujari', state_id: 1 },
  { id: 4, city_name: 'Capixaba', state_id: 1 },
  { id: 5, city_name: 'Cruzeiro do Sul', state_id: 1 },
  { id: 6, city_name: 'Epitaciolândia', state_id: 1 },
  { id: 7, city_name: 'Feijó', state_id: 1 },
  { id: 8, city_name: 'Jordão', state_id: 1 },
  { id: 9, city_name: 'Mâncio Lima', state_id: 1 },
  { id: 10, city_name: 'Manoel Urbano', state_id: 1 },
  { id: 11, city_name: 'Marechal Thaumaturgo', state_id: 1 },
  { id: 12, city_name: 'Plácido de Castro', state_id: 1 },
  { id: 13, city_name: 'Porto Acre', state_id: 1 },
  { id: 14, city_name: 'Porto Walter', state_id: 1 },
  { id: 15, city_name: 'Rio Branco', state_id: 1 },
  { id: 16, city_name: 'Rodrigues Alves', state_id: 1 },
  { id: 17, city_name: 'Santa Rosa do Purus', state_id: 1 },
  { id: 18, city_name: 'Sena Madureira', state_id: 1 },
  { id: 19, city_name: 'Senador Guiomard', state_id: 1 },
  { id: 20, city_name: 'Tarauacá', state_id: 1 },
  { id: 21, city_name: 'Xapuri', state_id: 1 },
  { id: 22, city_name: 'Água Branca', state_id: 2 },
  { id: 23, city_name: 'Anadia', state_id: 2 },
  { id: 24, city_name: 'Arapiraca', state_id: 2 },
  { id: 25, city_name: 'Atalaia', state_id: 2 },
  { id: 26, city_name: 'Barra de Santo Antônio', state_id: 2 },
  { id: 27, city_name: 'Barra de São Miguel', state_id: 2 },
  { id: 28, city_name: 'Batalha', state_id: 2 },
  { id: 29, city_name: 'Belém', state_id: 2 },
  { id: 30, city_name: 'Belo Monte', state_id: 2 },
  { id: 31, city_name: 'Boca da Mata', state_id: 2 },
  { id: 32, city_name: 'Branquinha', state_id: 2 },
  { id: 33, city_name: 'Cacimbinhas', state_id: 2 },
  { id: 34, city_name: 'Cajueiro', state_id: 2 },
  { id: 35, city_name: 'Campestre', state_id: 2 },
  { id: 36, city_name: 'Campo Alegre', state_id: 2 },
  { id: 37, city_name: 'Campo Grande', state_id: 2 },
  { id: 38, city_name: 'Canapi', state_id: 2 },
  { id: 39, city_name: 'Capela', state_id: 2 },
  { id: 40, city_name: 'Carneiros', state_id: 2 },
  { id: 41, city_name: 'Chã Preta', state_id: 2 },
  { id: 42, city_name: 'Coité do Nóia', state_id: 2 },
  { id: 43, city_name: 'Colônia Leopoldina', state_id: 2 },
  { id: 44, city_name: 'Coqueiro Seco', state_id: 2 },
  { id: 45, city_name: 'Coruripe', state_id: 2 },
  { id: 46, city_name: 'Craíbas', state_id: 2 },
  { id: 47, city_name: 'Delmiro Gouveia', state_id: 2 },
  { id: 48, city_name: 'Dois Riachos', state_id: 2 },
  { id: 49, city_name: 'Estrela de Alagoas', state_id: 2 },
  { id: 50, city_name: 'Feira Grande', state_id: 2 },
  { id: 51, city_name: 'Feliz Deserto', state_id: 2 },
  { id: 52, city_name: 'Flexeiras', state_id: 2 },
  { id: 53, city_name: 'Girau do Ponciano', state_id: 2 },
  { id: 54, city_name: 'Ibateguara', state_id: 2 },
  { id: 55, city_name: 'Igaci', state_id: 2 },
  { id: 56, city_name: 'Igreja Nova', state_id: 2 },
  { id: 57, city_name: 'Inhapi', state_id: 2 },
  { id: 58, city_name: 'Jacaré dos Homens', state_id: 2 },
  { id: 59, city_name: 'Jacuípe', state_id: 2 },
  { id: 60, city_name: 'Japaratinga', state_id: 2 },
  { id: 61, city_name: 'Jaramataia', state_id: 2 },
  { id: 62, city_name: 'Jequiá da Praia', state_id: 2 },
  { id: 63, city_name: 'Joaquim Gomes', state_id: 2 },
  { id: 64, city_name: 'Jundiá', state_id: 2 },
  { id: 65, city_name: 'Junqueiro', state_id: 2 },
  { id: 66, city_name: 'Lagoa da Canoa', state_id: 2 },
  { id: 67, city_name: 'Limoeiro de Anadia', state_id: 2 },
  { id: 68, city_name: 'Maceió', state_id: 2 },
  { id: 69, city_name: 'Major Isidoro', state_id: 2 },
  { id: 70, city_name: 'Mar Vermelho', state_id: 2 },
  { id: 71, city_name: 'Maragogi', state_id: 2 },
  { id: 72, city_name: 'Maravilha', state_id: 2 },
  { id: 73, city_name: 'Marechal Deodoro', state_id: 2 },
  { id: 74, city_name: 'Maribondo', state_id: 2 },
  { id: 75, city_name: 'Mata Grande', state_id: 2 },
  { id: 76, city_name: 'Matriz de Camaragibe', state_id: 2 },
  { id: 77, city_name: 'Messias', state_id: 2 },
  { id: 78, city_name: 'Minador do Negrão', state_id: 2 },
  { id: 79, city_name: 'Monteirópolis', state_id: 2 },
  { id: 80, city_name: 'Murici', state_id: 2 },
  { id: 81, city_name: 'Novo Lino', state_id: 2 },
  { id: 82, city_name: "Olho d'Água das Flores", state_id: 2 },
  { id: 83, city_name: "Olho d'Água do Casado", state_id: 2 },
  { id: 84, city_name: "Olho d'Água Grande", state_id: 2 },
  { id: 85, city_name: 'Olivença', state_id: 2 },
  { id: 86, city_name: 'Ouro Branco', state_id: 2 },
  { id: 87, city_name: 'Palestina', state_id: 2 },
  { id: 88, city_name: 'Palmeira dos Índios', state_id: 2 },
  { id: 89, city_name: 'Pão de Açúcar', state_id: 2 },
  { id: 90, city_name: 'Pariconha', state_id: 2 },
  { id: 91, city_name: 'Paripueira', state_id: 2 },
  { id: 92, city_name: 'Passo de Camaragibe', state_id: 2 },
  { id: 93, city_name: 'Paulo Jacinto', state_id: 2 },
  { id: 94, city_name: 'Penedo', state_id: 2 },
  { id: 95, city_name: 'Piaçabuçu', state_id: 2 },
  { id: 96, city_name: 'Pilar', state_id: 2 },
  { id: 97, city_name: 'Pindoba', state_id: 2 },
  { id: 98, city_name: 'Piranhas', state_id: 2 },
  { id: 99, city_name: 'Poço das Trincheiras', state_id: 2 },
  { id: 100, city_name: 'Porto Calvo', state_id: 2 },
  { id: 101, city_name: 'Porto de Pedras', state_id: 2 },
  { id: 102, city_name: 'Porto Real do Colégio', state_id: 2 },
  { id: 103, city_name: 'Quebrangulo', state_id: 2 },
  { id: 104, city_name: 'Rio Largo', state_id: 2 },
  { id: 105, city_name: 'Roteiro', state_id: 2 },
  { id: 106, city_name: 'Santa Luzia do Norte', state_id: 2 },
  { id: 107, city_name: 'Santana do Ipanema', state_id: 2 },
  { id: 108, city_name: 'Santana do Mundaú', state_id: 2 },
  { id: 109, city_name: 'São Brás', state_id: 2 },
  { id: 110, city_name: 'São José da Laje', state_id: 2 },
  { id: 111, city_name: 'São José da Tapera', state_id: 2 },
  { id: 112, city_name: 'São Luís do Quitunde', state_id: 2 },
  { id: 113, city_name: 'São Miguel dos Campos', state_id: 2 },
  { id: 114, city_name: 'São Miguel dos Milagres', state_id: 2 },
  { id: 115, city_name: 'São Sebastião', state_id: 2 },
  { id: 116, city_name: 'Satuba', state_id: 2 },
  { id: 117, city_name: 'Senador Rui Palmeira', state_id: 2 },
  { id: 118, city_name: "Tanque d'Arca", state_id: 2 },
  { id: 119, city_name: 'Taquarana', state_id: 2 },
  { id: 120, city_name: 'Teotônio Vilela', state_id: 2 },
  { id: 121, city_name: 'Traipu', state_id: 2 },
  { id: 122, city_name: 'União dos Palmares', state_id: 2 },
  { id: 123, city_name: 'Viçosa', state_id: 2 },
  { id: 124, city_name: 'Amapá', state_id: 3 },
  { id: 125, city_name: 'Calçoene', state_id: 3 },
  { id: 126, city_name: 'Cutias', state_id: 3 },
  { id: 127, city_name: 'Ferreira Gomes', state_id: 3 },
  { id: 128, city_name: 'Itaubal', state_id: 3 },
  { id: 129, city_name: 'Laranjal do Jari', state_id: 3 },
  { id: 130, city_name: 'Macapá', state_id: 3 },
  { id: 131, city_name: 'Mazagão', state_id: 3 },
  { id: 132, city_name: 'Oiapoque', state_id: 3 },
  { id: 133, city_name: 'Pedra Branca do Amapari', state_id: 3 },
  { id: 134, city_name: 'Porto Grande', state_id: 3 },
  { id: 135, city_name: 'Pracuúba', state_id: 3 },
  { id: 136, city_name: 'Santana', state_id: 3 },
  { id: 137, city_name: 'Serra do Navio', state_id: 3 },
  { id: 138, city_name: 'Tartarugalzinho', state_id: 3 },
  { id: 139, city_name: 'Vitória do Jari', state_id: 3 },
  { id: 140, city_name: 'Alvarães', state_id: 4 },
  { id: 141, city_name: 'Amaturá', state_id: 4 },
  { id: 142, city_name: 'Anamã', state_id: 4 },
  { id: 143, city_name: 'Anori', state_id: 4 },
  { id: 144, city_name: 'Apuí', state_id: 4 },
  { id: 145, city_name: 'Atalaia do Norte', state_id: 4 },
  { id: 146, city_name: 'Autazes', state_id: 4 },
  { id: 147, city_name: 'Barcelos', state_id: 4 },
  { id: 148, city_name: 'Barreirinha', state_id: 4 },
  { id: 149, city_name: 'Benjamin Constant', state_id: 4 },
  { id: 150, city_name: 'Beruri', state_id: 4 },
  { id: 151, city_name: 'Boa Vista do Ramos', state_id: 4 },
  { id: 152, city_name: 'Boca do Acre', state_id: 4 },
  { id: 153, city_name: 'Borba', state_id: 4 },
  { id: 154, city_name: 'Caapiranga', state_id: 4 },
  { id: 155, city_name: 'Canutama', state_id: 4 },
  { id: 156, city_name: 'Carauari', state_id: 4 },
  { id: 157, city_name: 'Careiro', state_id: 4 },
  { id: 158, city_name: 'Careiro da Várzea', state_id: 4 },
  { id: 159, city_name: 'Coari', state_id: 4 },
  { id: 160, city_name: 'Codajás', state_id: 4 },
  { id: 161, city_name: 'Eirunepé', state_id: 4 },
  { id: 162, city_name: 'Envira', state_id: 4 },
  { id: 163, city_name: 'Fonte Boa', state_id: 4 },
  { id: 164, city_name: 'Guajará', state_id: 4 },
  { id: 165, city_name: 'Humaitá', state_id: 4 },
  { id: 166, city_name: 'Ipixuna', state_id: 4 },
  { id: 167, city_name: 'Iranduba', state_id: 4 },
  { id: 168, city_name: 'Itacoatiara', state_id: 4 },
  { id: 169, city_name: 'Itamarati', state_id: 4 },
  { id: 170, city_name: 'Itapiranga', state_id: 4 },
  { id: 171, city_name: 'Japurá', state_id: 4 },
  { id: 172, city_name: 'Juruá', state_id: 4 },
  { id: 173, city_name: 'Jutaí', state_id: 4 },
  { id: 174, city_name: 'Lábrea', state_id: 4 },
  { id: 175, city_name: 'Manacapuru', state_id: 4 },
  { id: 176, city_name: 'Manaquiri', state_id: 4 },
  { id: 177, city_name: 'Manaus', state_id: 4 },
  { id: 178, city_name: 'Manicoré', state_id: 4 },
  { id: 179, city_name: 'Maraã', state_id: 4 },
  { id: 180, city_name: 'Maués', state_id: 4 },
  { id: 181, city_name: 'Nhamundá', state_id: 4 },
  { id: 182, city_name: 'Nova Olinda do Norte', state_id: 4 },
  { id: 183, city_name: 'Novo Airão', state_id: 4 },
  { id: 184, city_name: 'Novo Aripuanã', state_id: 4 },
  { id: 185, city_name: 'Parintins', state_id: 4 },
  { id: 186, city_name: 'Pauini', state_id: 4 },
  { id: 187, city_name: 'Presidente Figueiredo', state_id: 4 },
  { id: 188, city_name: 'Rio Preto da Eva', state_id: 4 },
  { id: 189, city_name: 'Santa Isabel do Rio Negro', state_id: 4 },
  { id: 190, city_name: 'Santo Antônio do Içá', state_id: 4 },
  { id: 191, city_name: 'São Gabriel da Cachoeira', state_id: 4 },
  { id: 192, city_name: 'São Paulo de Olivença', state_id: 4 },
  { id: 193, city_name: 'São Sebastião do Uatumã', state_id: 4 },
  { id: 194, city_name: 'Silves', state_id: 4 },
  { id: 195, city_name: 'Tabatinga', state_id: 4 },
  { id: 196, city_name: 'Tapauá', state_id: 4 },
  { id: 197, city_name: 'Tefé', state_id: 4 },
  { id: 198, city_name: 'Tonantins', state_id: 4 },
  { id: 199, city_name: 'Uarini', state_id: 4 },
  { id: 200, city_name: 'Urucará', state_id: 4 },
  { id: 201, city_name: 'Urucurituba', state_id: 4 },
  { id: 202, city_name: 'Abaíra', state_id: 5 },
  { id: 203, city_name: 'Abaré', state_id: 5 },
  { id: 204, city_name: 'Acajutiba', state_id: 5 },
  { id: 205, city_name: 'Adustina', state_id: 5 },
  { id: 206, city_name: 'Água Fria', state_id: 5 },
  { id: 207, city_name: 'Aiquara', state_id: 5 },
  { id: 208, city_name: 'Alagoinhas', state_id: 5 },
  { id: 209, city_name: 'Alcobaça', state_id: 5 },
  { id: 210, city_name: 'Almadina', state_id: 5 },
  { id: 211, city_name: 'Amargosa', state_id: 5 },
  { id: 212, city_name: 'Amélia Rodrigues', state_id: 5 },
  { id: 213, city_name: 'América Dourada', state_id: 5 },
  { id: 214, city_name: 'Anagé', state_id: 5 },
  { id: 215, city_name: 'Andaraí', state_id: 5 },
  { id: 216, city_name: 'Andorinha', state_id: 5 },
  { id: 217, city_name: 'Angical', state_id: 5 },
  { id: 218, city_name: 'Anguera', state_id: 5 },
  { id: 219, city_name: 'Antas', state_id: 5 },
  { id: 220, city_name: 'Antônio Cardoso', state_id: 5 },
  { id: 221, city_name: 'Antônio Gonçalves', state_id: 5 },
  { id: 222, city_name: 'Aporá', state_id: 5 },
  { id: 223, city_name: 'Apuarema', state_id: 5 },
  { id: 224, city_name: 'Araçás', state_id: 5 },
  { id: 225, city_name: 'Aracatu', state_id: 5 },
  { id: 226, city_name: 'Araci', state_id: 5 },
  { id: 227, city_name: 'Aramari', state_id: 5 },
  { id: 228, city_name: 'Arataca', state_id: 5 },
  { id: 229, city_name: 'Aratuípe', state_id: 5 },
  { id: 230, city_name: 'Aurelino Leal', state_id: 5 },
  { id: 231, city_name: 'Baianópolis', state_id: 5 },
  { id: 232, city_name: 'Baixa Grande', state_id: 5 },
  { id: 233, city_name: 'Banzaê', state_id: 5 },
  { id: 234, city_name: 'Barra', state_id: 5 },
  { id: 235, city_name: 'Barra da Estiva', state_id: 5 },
  { id: 236, city_name: 'Barra do Choça', state_id: 5 },
  { id: 237, city_name: 'Barra do Mendes', state_id: 5 },
  { id: 238, city_name: 'Barra do Rocha', state_id: 5 },
  { id: 239, city_name: 'Barreiras', state_id: 5 },
  { id: 240, city_name: 'Barro Alto', state_id: 5 },
  { id: 241, city_name: 'Barro Preto', state_id: 5 },
  { id: 242, city_name: 'Barrocas', state_id: 5 },
  { id: 243, city_name: 'Belmonte', state_id: 5 },
  { id: 244, city_name: 'Belo Campo', state_id: 5 },
  { id: 245, city_name: 'Biritinga', state_id: 5 },
  { id: 246, city_name: 'Boa Nova', state_id: 5 },
  { id: 247, city_name: 'Boa Vista do Tupim', state_id: 5 },
  { id: 248, city_name: 'Bom Jesus da Lapa', state_id: 5 },
  { id: 249, city_name: 'Bom Jesus da Serra', state_id: 5 },
  { id: 250, city_name: 'Boninal', state_id: 5 },
  { id: 251, city_name: 'Bonito', state_id: 5 },
  { id: 252, city_name: 'Boquira', state_id: 5 },
  { id: 253, city_name: 'Botuporã', state_id: 5 },
  { id: 254, city_name: 'Brejões', state_id: 5 },
  { id: 255, city_name: 'Brejolândia', state_id: 5 },
  { id: 256, city_name: 'Brotas de Macaúbas', state_id: 5 },
  { id: 257, city_name: 'Brumado', state_id: 5 },
  { id: 258, city_name: 'Buerarema', state_id: 5 },
  { id: 259, city_name: 'Buritirama', state_id: 5 },
  { id: 260, city_name: 'Caatiba', state_id: 5 },
  { id: 261, city_name: 'Cabaceiras do Paraguaçu', state_id: 5 },
  { id: 262, city_name: 'Cachoeira', state_id: 5 },
  { id: 263, city_name: 'Caculé', state_id: 5 },
  { id: 264, city_name: 'Caém', state_id: 5 },
  { id: 265, city_name: 'Caetanos', state_id: 5 },
  { id: 266, city_name: 'Caetité', state_id: 5 },
  { id: 267, city_name: 'Cafarnaum', state_id: 5 },
  { id: 268, city_name: 'Cairu', state_id: 5 },
  { id: 269, city_name: 'Caldeirão Grande', state_id: 5 },
  { id: 270, city_name: 'Camacan', state_id: 5 },
  { id: 271, city_name: 'Camaçari', state_id: 5 },
  { id: 272, city_name: 'Camamu', state_id: 5 },
  { id: 273, city_name: 'Campo Alegre de Lourdes', state_id: 5 },
  { id: 274, city_name: 'Campo Formoso', state_id: 5 },
  { id: 275, city_name: 'Canápolis', state_id: 5 },
  { id: 276, city_name: 'Canarana', state_id: 5 },
  { id: 277, city_name: 'Canavieiras', state_id: 5 },
  { id: 278, city_name: 'Candeal', state_id: 5 },
  { id: 279, city_name: 'Candeias', state_id: 5 },
  { id: 280, city_name: 'Candiba', state_id: 5 },
  { id: 281, city_name: 'Cândido Sales', state_id: 5 },
  { id: 282, city_name: 'Cansanção', state_id: 5 },
  { id: 283, city_name: 'Canudos', state_id: 5 },
  { id: 284, city_name: 'Capela do Alto Alegre', state_id: 5 },
  { id: 285, city_name: 'Capim Grosso', state_id: 5 },
  { id: 286, city_name: 'Caraíbas', state_id: 5 },
  { id: 287, city_name: 'Caravelas', state_id: 5 },
  { id: 288, city_name: 'Cardeal da Silva', state_id: 5 },
  { id: 289, city_name: 'Carinhanha', state_id: 5 },
  { id: 290, city_name: 'Casa Nova', state_id: 5 },
  { id: 291, city_name: 'Castro Alves', state_id: 5 },
  { id: 292, city_name: 'Catolândia', state_id: 5 },
  { id: 293, city_name: 'Catu', state_id: 5 },
  { id: 294, city_name: 'Caturama', state_id: 5 },
  { id: 295, city_name: 'Central', state_id: 5 },
  { id: 296, city_name: 'Chorrochó', state_id: 5 },
  { id: 297, city_name: 'Cícero Dantas', state_id: 5 },
  { id: 298, city_name: 'Cipó', state_id: 5 },
  { id: 299, city_name: 'Coaraci', state_id: 5 },
  { id: 300, city_name: 'Cocos', state_id: 5 },
  { id: 301, city_name: 'Conceição da Feira', state_id: 5 },
  { id: 302, city_name: 'Conceição do Almeida', state_id: 5 },
  { id: 303, city_name: 'Conceição do Coité', state_id: 5 },
  { id: 304, city_name: 'Conceição do Jacuípe', state_id: 5 },
  { id: 305, city_name: 'Conde', state_id: 5 },
  { id: 306, city_name: 'Condeúba', state_id: 5 },
  { id: 307, city_name: 'Contendas do Sincorá', state_id: 5 },
  { id: 308, city_name: 'Coração de Maria', state_id: 5 },
  { id: 309, city_name: 'Cordeiros', state_id: 5 },
  { id: 310, city_name: 'Coribe', state_id: 5 },
  { id: 311, city_name: 'Coronel João Sá', state_id: 5 },
  { id: 312, city_name: 'Correntina', state_id: 5 },
  { id: 313, city_name: 'Cotegipe', state_id: 5 },
  { id: 314, city_name: 'Cravolândia', state_id: 5 },
  { id: 315, city_name: 'Crisópolis', state_id: 5 },
  { id: 316, city_name: 'Cristópolis', state_id: 5 },
  { id: 317, city_name: 'Cruz das Almas', state_id: 5 },
  { id: 318, city_name: 'Curaçá', state_id: 5 },
  { id: 319, city_name: 'Dário Meira', state_id: 5 },
  { id: 320, city_name: "Dias d'Ávila", state_id: 5 },
  { id: 321, city_name: 'Dom Basílio', state_id: 5 },
  { id: 322, city_name: 'Dom Macedo Costa', state_id: 5 },
  { id: 323, city_name: 'Elísio Medrado', state_id: 5 },
  { id: 324, city_name: 'Encruzilhada', state_id: 5 },
  { id: 325, city_name: 'Entre Rios', state_id: 5 },
  { id: 326, city_name: 'Érico Cardoso', state_id: 5 },
  { id: 327, city_name: 'Esplanada', state_id: 5 },
  { id: 328, city_name: 'Euclides da Cunha', state_id: 5 },
  { id: 329, city_name: 'Eunápolis', state_id: 5 },
  { id: 330, city_name: 'Fátima', state_id: 5 },
  { id: 331, city_name: 'Feira da Mata', state_id: 5 },
  { id: 332, city_name: 'Feira de Santana', state_id: 5 },
  { id: 333, city_name: 'Filadélfia', state_id: 5 },
  { id: 334, city_name: 'Firmino Alves', state_id: 5 },
  { id: 335, city_name: 'Floresta Azul', state_id: 5 },
  { id: 336, city_name: 'Formosa do Rio Preto', state_id: 5 },
  { id: 337, city_name: 'Gandu', state_id: 5 },
  { id: 338, city_name: 'Gavião', state_id: 5 },
  { id: 339, city_name: 'Gentio do Ouro', state_id: 5 },
  { id: 340, city_name: 'Glória', state_id: 5 },
  { id: 341, city_name: 'Gongogi', state_id: 5 },
  { id: 342, city_name: 'Governador Mangabeira', state_id: 5 },
  { id: 343, city_name: 'Guajeru', state_id: 5 },
  { id: 344, city_name: 'Guanambi', state_id: 5 },
  { id: 345, city_name: 'Guaratinga', state_id: 5 },
  { id: 346, city_name: 'Heliópolis', state_id: 5 },
  { id: 347, city_name: 'Iaçu', state_id: 5 },
  { id: 348, city_name: 'Ibiassucê', state_id: 5 },
  { id: 349, city_name: 'Ibicaraí', state_id: 5 },
  { id: 350, city_name: 'Ibicoara', state_id: 5 },
  { id: 351, city_name: 'Ibicuí', state_id: 5 },
  { id: 352, city_name: 'Ibipeba', state_id: 5 },
  { id: 353, city_name: 'Ibipitanga', state_id: 5 },
  { id: 354, city_name: 'Ibiquera', state_id: 5 },
  { id: 355, city_name: 'Ibirapitanga', state_id: 5 },
  { id: 356, city_name: 'Ibirapuã', state_id: 5 },
  { id: 357, city_name: 'Ibirataia', state_id: 5 },
  { id: 358, city_name: 'Ibitiara', state_id: 5 },
  { id: 359, city_name: 'Ibititá', state_id: 5 },
  { id: 360, city_name: 'Ibotirama', state_id: 5 },
  { id: 361, city_name: 'Ichu', state_id: 5 },
  { id: 362, city_name: 'Igaporã', state_id: 5 },
  { id: 363, city_name: 'Igrapiúna', state_id: 5 },
  { id: 364, city_name: 'Iguaí', state_id: 5 },
  { id: 365, city_name: 'Ilhéus', state_id: 5 },
  { id: 366, city_name: 'Inhambupe', state_id: 5 },
  { id: 367, city_name: 'Ipecaetá', state_id: 5 },
  { id: 368, city_name: 'Ipiaú', state_id: 5 },
  { id: 369, city_name: 'Ipirá', state_id: 5 },
  { id: 370, city_name: 'Ipupiara', state_id: 5 },
  { id: 371, city_name: 'Irajuba', state_id: 5 },
  { id: 372, city_name: 'Iramaia', state_id: 5 },
  { id: 373, city_name: 'Iraquara', state_id: 5 },
  { id: 374, city_name: 'Irará', state_id: 5 },
  { id: 375, city_name: 'Irecê', state_id: 5 },
  { id: 376, city_name: 'Itabela', state_id: 5 },
  { id: 377, city_name: 'Itaberaba', state_id: 5 },
  { id: 378, city_name: 'Itabuna', state_id: 5 },
  { id: 379, city_name: 'Itacaré', state_id: 5 },
  { id: 380, city_name: 'Itaeté', state_id: 5 },
  { id: 381, city_name: 'Itagi', state_id: 5 },
  { id: 382, city_name: 'Itagibá', state_id: 5 },
  { id: 383, city_name: 'Itagimirim', state_id: 5 },
  { id: 384, city_name: 'Itaguaçu da Bahia', state_id: 5 },
  { id: 385, city_name: 'Itaju do Colônia', state_id: 5 },
  { id: 386, city_name: 'Itajuípe', state_id: 5 },
  { id: 387, city_name: 'Itamaraju', state_id: 5 },
  { id: 388, city_name: 'Itamari', state_id: 5 },
  { id: 389, city_name: 'Itambé', state_id: 5 },
  { id: 390, city_name: 'Itanagra', state_id: 5 },
  { id: 391, city_name: 'Itanhém', state_id: 5 },
  { id: 392, city_name: 'Itaparica', state_id: 5 },
  { id: 393, city_name: 'Itapé', state_id: 5 },
  { id: 394, city_name: 'Itapebi', state_id: 5 },
  { id: 395, city_name: 'Itapetinga', state_id: 5 },
  { id: 396, city_name: 'Itapicuru', state_id: 5 },
  { id: 397, city_name: 'Itapitanga', state_id: 5 },
  { id: 398, city_name: 'Itaquara', state_id: 5 },
  { id: 399, city_name: 'Itarantim', state_id: 5 },
  { id: 400, city_name: 'Itatim', state_id: 5 },
  { id: 401, city_name: 'Itiruçu', state_id: 5 },
  { id: 402, city_name: 'Itiúba', state_id: 5 },
  { id: 403, city_name: 'Itororó', state_id: 5 },
  { id: 404, city_name: 'Ituaçu', state_id: 5 },
  { id: 405, city_name: 'Ituberá', state_id: 5 },
  { id: 406, city_name: 'Iuiu', state_id: 5 },
  { id: 407, city_name: 'Jaborandi', state_id: 5 },
  { id: 408, city_name: 'Jacaraci', state_id: 5 },
  { id: 409, city_name: 'Jacobina', state_id: 5 },
  { id: 410, city_name: 'Jaguaquara', state_id: 5 },
  { id: 411, city_name: 'Jaguarari', state_id: 5 },
  { id: 412, city_name: 'Jaguaripe', state_id: 5 },
  { id: 413, city_name: 'Jandaíra', state_id: 5 },
  { id: 414, city_name: 'Jequié', state_id: 5 },
  { id: 415, city_name: 'Jeremoabo', state_id: 5 },
  { id: 416, city_name: 'Jiquiriçá', state_id: 5 },
  { id: 417, city_name: 'Jitaúna', state_id: 5 },
  { id: 418, city_name: 'João Dourado', state_id: 5 },
  { id: 419, city_name: 'Juazeiro', state_id: 5 },
  { id: 420, city_name: 'Jucuruçu', state_id: 5 },
  { id: 421, city_name: 'Jussara', state_id: 5 },
  { id: 422, city_name: 'Jussari', state_id: 5 },
  { id: 423, city_name: 'Jussiape', state_id: 5 },
  { id: 424, city_name: 'Lafaiete Coutinho', state_id: 5 },
  { id: 425, city_name: 'Lagoa Real', state_id: 5 },
  { id: 426, city_name: 'Laje', state_id: 5 },
  { id: 427, city_name: 'Lajedão', state_id: 5 },
  { id: 428, city_name: 'Lajedinho', state_id: 5 },
  { id: 429, city_name: 'Lajedo do Tabocal', state_id: 5 },
  { id: 430, city_name: 'Lamarão', state_id: 5 },
  { id: 431, city_name: 'Lapão', state_id: 5 },
  { id: 432, city_name: 'Lauro de Freitas', state_id: 5 },
  { id: 433, city_name: 'Lençóis', state_id: 5 },
  { id: 434, city_name: 'Licínio de Almeida', state_id: 5 },
  { id: 435, city_name: 'Livramento de Nossa Senhora', state_id: 5 },
  { id: 436, city_name: 'Luís Eduardo Magalhães', state_id: 5 },
  { id: 437, city_name: 'Macajuba', state_id: 5 },
  { id: 438, city_name: 'Macarani', state_id: 5 },
  { id: 439, city_name: 'Macaúbas', state_id: 5 },
  { id: 440, city_name: 'Macururé', state_id: 5 },
  { id: 441, city_name: 'Madre de Deus', state_id: 5 },
  { id: 442, city_name: 'Maetinga', state_id: 5 },
  { id: 443, city_name: 'Maiquinique', state_id: 5 },
  { id: 444, city_name: 'Mairi', state_id: 5 },
  { id: 445, city_name: 'Malhada', state_id: 5 },
  { id: 446, city_name: 'Malhada de Pedras', state_id: 5 },
  { id: 447, city_name: 'Manoel Vitorino', state_id: 5 },
  { id: 448, city_name: 'Mansidão', state_id: 5 },
  { id: 449, city_name: 'Maracás', state_id: 5 },
  { id: 450, city_name: 'Maragogipe', state_id: 5 },
  { id: 451, city_name: 'Maraú', state_id: 5 },
  { id: 452, city_name: 'Marcionílio Souza', state_id: 5 },
  { id: 453, city_name: 'Mascote', state_id: 5 },
  { id: 454, city_name: 'Mata de São João', state_id: 5 },
  { id: 455, city_name: 'Matina', state_id: 5 },
  { id: 456, city_name: 'Medeiros Neto', state_id: 5 },
  { id: 457, city_name: 'Miguel Calmon', state_id: 5 },
  { id: 458, city_name: 'Milagres', state_id: 5 },
  { id: 459, city_name: 'Mirangaba', state_id: 5 },
  { id: 460, city_name: 'Mirante', state_id: 5 },
  { id: 461, city_name: 'Monte Santo', state_id: 5 },
  { id: 462, city_name: 'Morpará', state_id: 5 },
  { id: 463, city_name: 'Morro do Chapéu', state_id: 5 },
  { id: 464, city_name: 'Mortugaba', state_id: 5 },
  { id: 465, city_name: 'Mucugê', state_id: 5 },
  { id: 466, city_name: 'Mucuri', state_id: 5 },
  { id: 467, city_name: 'Mulungu do Morro', state_id: 5 },
  { id: 468, city_name: 'Mundo Novo', state_id: 5 },
  { id: 469, city_name: 'Muniz Ferreira', state_id: 5 },
  { id: 470, city_name: 'Muquém do São Francisco', state_id: 5 },
  { id: 471, city_name: 'Muritiba', state_id: 5 },
  { id: 472, city_name: 'Mutuípe', state_id: 5 },
  { id: 473, city_name: 'Nazaré', state_id: 5 },
  { id: 474, city_name: 'Nilo Peçanha', state_id: 5 },
  { id: 475, city_name: 'Nordestina', state_id: 5 },
  { id: 476, city_name: 'Nova Canaã', state_id: 5 },
  { id: 477, city_name: 'Nova Fátima', state_id: 5 },
  { id: 478, city_name: 'Nova Ibiá', state_id: 5 },
  { id: 479, city_name: 'Nova Itarana', state_id: 5 },
  { id: 480, city_name: 'Nova Redenção', state_id: 5 },
  { id: 481, city_name: 'Nova Soure', state_id: 5 },
  { id: 482, city_name: 'Nova Viçosa', state_id: 5 },
  { id: 483, city_name: 'Novo Horizonte', state_id: 5 },
  { id: 484, city_name: 'Novo Triunfo', state_id: 5 },
  { id: 485, city_name: 'Olindina', state_id: 5 },
  { id: 486, city_name: 'Oliveira dos Brejinhos', state_id: 5 },
  { id: 487, city_name: 'Ouriçangas', state_id: 5 },
  { id: 488, city_name: 'Ourolândia', state_id: 5 },
  { id: 489, city_name: 'Palmas de Monte Alto', state_id: 5 },
  { id: 490, city_name: 'Palmeiras', state_id: 5 },
  { id: 491, city_name: 'Paramirim', state_id: 5 },
  { id: 492, city_name: 'Paratinga', state_id: 5 },
  { id: 493, city_name: 'Paripiranga', state_id: 5 },
  { id: 494, city_name: 'Pau Brasil', state_id: 5 },
  { id: 495, city_name: 'Paulo Afonso', state_id: 5 },
  { id: 496, city_name: 'Pé de Serra', state_id: 5 },
  { id: 497, city_name: 'Pedrão', state_id: 5 },
  { id: 498, city_name: 'Pedro Alexandre', state_id: 5 },
  { id: 499, city_name: 'Piatã', state_id: 5 },
  { id: 500, city_name: 'Pilão Arcado', state_id: 5 },
  { id: 501, city_name: 'Pindaí', state_id: 5 },
  { id: 502, city_name: 'Pindobaçu', state_id: 5 },
  { id: 503, city_name: 'Pintadas', state_id: 5 },
  { id: 504, city_name: 'Piraí do Norte', state_id: 5 },
  { id: 505, city_name: 'Piripá', state_id: 5 },
  { id: 506, city_name: 'Piritiba', state_id: 5 },
  { id: 507, city_name: 'Planaltino', state_id: 5 },
  { id: 508, city_name: 'Planalto', state_id: 5 },
  { id: 509, city_name: 'Poções', state_id: 5 },
  { id: 510, city_name: 'Pojuca', state_id: 5 },
  { id: 511, city_name: 'Ponto Novo', state_id: 5 },
  { id: 512, city_name: 'Porto Seguro', state_id: 5 },
  { id: 513, city_name: 'Potiraguá', state_id: 5 },
  { id: 514, city_name: 'Prado', state_id: 5 },
  { id: 515, city_name: 'Presidente Dutra', state_id: 5 },
  { id: 516, city_name: 'Presidente Jânio Quadros', state_id: 5 },
  { id: 517, city_name: 'Presidente Tancredo Neves', state_id: 5 },
  { id: 518, city_name: 'Queimadas', state_id: 5 },
  { id: 519, city_name: 'Quijingue', state_id: 5 },
  { id: 520, city_name: 'Quixabeira', state_id: 5 },
  { id: 521, city_name: 'Rafael Jambeiro', state_id: 5 },
  { id: 522, city_name: 'Remanso', state_id: 5 },
  { id: 523, city_name: 'Retirolândia', state_id: 5 },
  { id: 524, city_name: 'Riachão das Neves', state_id: 5 },
  { id: 525, city_name: 'Riachão do Jacuípe', state_id: 5 },
  { id: 526, city_name: 'Riacho de Santana', state_id: 5 },
  { id: 527, city_name: 'Ribeira do Amparo', state_id: 5 },
  { id: 528, city_name: 'Ribeira do Pombal', state_id: 5 },
  { id: 529, city_name: 'Ribeirão do Largo', state_id: 5 },
  { id: 530, city_name: 'Rio de Contas', state_id: 5 },
  { id: 531, city_name: 'Rio do Antônio', state_id: 5 },
  { id: 532, city_name: 'Rio do Pires', state_id: 5 },
  { id: 533, city_name: 'Rio Real', state_id: 5 },
  { id: 534, city_name: 'Rodelas', state_id: 5 },
  { id: 535, city_name: 'Ruy Barbosa', state_id: 5 },
  { id: 536, city_name: 'Salinas da Margarida', state_id: 5 },
  { id: 537, city_name: 'Salvador', state_id: 5 },
  { id: 538, city_name: 'Santa Bárbara', state_id: 5 },
  { id: 539, city_name: 'Santa Brígida', state_id: 5 },
  { id: 540, city_name: 'Santa Cruz Cabrália', state_id: 5 },
  { id: 541, city_name: 'Santa Cruz da Vitória', state_id: 5 },
  { id: 542, city_name: 'Santa Inês', state_id: 5 },
  { id: 543, city_name: 'Santa Luzia', state_id: 5 },
  { id: 544, city_name: 'Santa Maria da Vitória', state_id: 5 },
  { id: 545, city_name: 'Santa Rita de Cássia', state_id: 5 },
  { id: 546, city_name: 'Santa Terezinha', state_id: 5 },
  { id: 547, city_name: 'Santaluz', state_id: 5 },
  { id: 548, city_name: 'Santanópolis', state_id: 5 },
  { id: 549, city_name: 'Santo Amaro', state_id: 5 },
  { id: 550, city_name: 'Santo Antônio de Jesus', state_id: 5 },
  { id: 551, city_name: 'Santo Estêvão', state_id: 5 },
  { id: 552, city_name: 'São Desidério', state_id: 5 },
  { id: 553, city_name: 'São Domingos', state_id: 5 },
  { id: 554, city_name: 'São Felipe', state_id: 5 },
  { id: 555, city_name: 'São Félix', state_id: 5 },
  { id: 556, city_name: 'São Félix do Coribe', state_id: 5 },
  { id: 557, city_name: 'São Francisco do Conde', state_id: 5 },
  { id: 558, city_name: 'São Gabriel', state_id: 5 },
  { id: 559, city_name: 'São Gonçalo dos Campos', state_id: 5 },
  { id: 560, city_name: 'São José da Vitória', state_id: 5 },
  { id: 561, city_name: 'São José do Jacuípe', state_id: 5 },
  { id: 562, city_name: 'São Miguel das Matas', state_id: 5 },
  { id: 563, city_name: 'São Sebastião do Passé', state_id: 5 },
  { id: 564, city_name: 'Sapeaçu', state_id: 5 },
  { id: 565, city_name: 'Sátiro Dias', state_id: 5 },
  { id: 566, city_name: 'Saubara', state_id: 5 },
  { id: 567, city_name: 'Saúde', state_id: 5 },
  { id: 568, city_name: 'Seabra', state_id: 5 },
  { id: 569, city_name: 'Sebastião Laranjeiras', state_id: 5 },
  { id: 570, city_name: 'Senhor do Bonfim', state_id: 5 },
  { id: 571, city_name: 'Sento Sé', state_id: 5 },
  { id: 572, city_name: 'Serra do Ramalho', state_id: 5 },
  { id: 573, city_name: 'Serra Dourada', state_id: 5 },
  { id: 574, city_name: 'Serra Preta', state_id: 5 },
  { id: 575, city_name: 'Serrinha', state_id: 5 },
  { id: 576, city_name: 'Serrolândia', state_id: 5 },
  { id: 577, city_name: 'Simões Filho', state_id: 5 },
  { id: 578, city_name: 'Sítio do Mato', state_id: 5 },
  { id: 579, city_name: 'Sítio do Quinto', state_id: 5 },
  { id: 580, city_name: 'Sobradinho', state_id: 5 },
  { id: 581, city_name: 'Souto Soares', state_id: 5 },
  { id: 582, city_name: 'Tabocas do Brejo Velho', state_id: 5 },
  { id: 583, city_name: 'Tanhaçu', state_id: 5 },
  { id: 584, city_name: 'Tanque Novo', state_id: 5 },
  { id: 585, city_name: 'Tanquinho', state_id: 5 },
  { id: 586, city_name: 'Taperoá', state_id: 5 },
  { id: 587, city_name: 'Tapiramutá', state_id: 5 },
  { id: 588, city_name: 'Teixeira de Freitas', state_id: 5 },
  { id: 589, city_name: 'Teodoro Sampaio', state_id: 5 },
  { id: 590, city_name: 'Teofilândia', state_id: 5 },
  { id: 591, city_name: 'Teolândia', state_id: 5 },
  { id: 592, city_name: 'Terra Nova', state_id: 5 },
  { id: 593, city_name: 'Tremedal', state_id: 5 },
  { id: 594, city_name: 'Tucano', state_id: 5 },
  { id: 595, city_name: 'Uauá', state_id: 5 },
  { id: 596, city_name: 'Ubaíra', state_id: 5 },
  { id: 597, city_name: 'Ubaitaba', state_id: 5 },
  { id: 598, city_name: 'Ubatã', state_id: 5 },
  { id: 599, city_name: 'Uibaí', state_id: 5 },
  { id: 600, city_name: 'Umburanas', state_id: 5 },
  { id: 601, city_name: 'Una', state_id: 5 },
  { id: 602, city_name: 'Urandi', state_id: 5 },
  { id: 603, city_name: 'Uruçuca', state_id: 5 },
  { id: 604, city_name: 'Utinga', state_id: 5 },
  { id: 605, city_name: 'Valença', state_id: 5 },
  { id: 606, city_name: 'Valente', state_id: 5 },
  { id: 607, city_name: 'Várzea da Roça', state_id: 5 },
  { id: 608, city_name: 'Várzea do Poço', state_id: 5 },
  { id: 609, city_name: 'Várzea Nova', state_id: 5 },
  { id: 610, city_name: 'Varzedo', state_id: 5 },
  { id: 611, city_name: 'Vera Cruz', state_id: 5 },
  { id: 612, city_name: 'Vereda', state_id: 5 },
  { id: 613, city_name: 'Vitória da Conquista', state_id: 5 },
  { id: 614, city_name: 'Wagner', state_id: 5 },
  { id: 615, city_name: 'Wanderley', state_id: 5 },
  { id: 616, city_name: 'Wenceslau Guimarães', state_id: 5 },
  { id: 617, city_name: 'Xique-Xique', state_id: 5 },
  { id: 618, city_name: 'Abaiara', state_id: 6 },
  { id: 619, city_name: 'Acarape', state_id: 6 },
  { id: 620, city_name: 'Acaraú', state_id: 6 },
  { id: 621, city_name: 'Acopiara', state_id: 6 },
  { id: 622, city_name: 'Aiuaba', state_id: 6 },
  { id: 623, city_name: 'Alcântaras', state_id: 6 },
  { id: 624, city_name: 'Altaneira', state_id: 6 },
  { id: 625, city_name: 'Alto Santo', state_id: 6 },
  { id: 626, city_name: 'Amontada', state_id: 6 },
  { id: 627, city_name: 'Antonina do Norte', state_id: 6 },
  { id: 628, city_name: 'Apuiarés', state_id: 6 },
  { id: 629, city_name: 'Aquiraz', state_id: 6 },
  { id: 630, city_name: 'Aracati', state_id: 6 },
  { id: 631, city_name: 'Aracoiaba', state_id: 6 },
  { id: 632, city_name: 'Ararendá', state_id: 6 },
  { id: 633, city_name: 'Araripe', state_id: 6 },
  { id: 634, city_name: 'Aratuba', state_id: 6 },
  { id: 635, city_name: 'Arneiroz', state_id: 6 },
  { id: 636, city_name: 'Assaré', state_id: 6 },
  { id: 637, city_name: 'Aurora', state_id: 6 },
  { id: 638, city_name: 'Baixio', state_id: 6 },
  { id: 639, city_name: 'Banabuiú', state_id: 6 },
  { id: 640, city_name: 'Barbalha', state_id: 6 },
  { id: 641, city_name: 'Barreira', state_id: 6 },
  { id: 642, city_name: 'Barro', state_id: 6 },
  { id: 643, city_name: 'Barroquinha', state_id: 6 },
  { id: 644, city_name: 'Baturité', state_id: 6 },
  { id: 645, city_name: 'Beberibe', state_id: 6 },
  { id: 646, city_name: 'Bela Cruz', state_id: 6 },
  { id: 647, city_name: 'Boa Viagem', state_id: 6 },
  { id: 648, city_name: 'Brejo Santo', state_id: 6 },
  { id: 649, city_name: 'Camocim', state_id: 6 },
  { id: 650, city_name: 'Campos Sales', state_id: 6 },
  { id: 651, city_name: 'Canindé', state_id: 6 },
  { id: 652, city_name: 'Capistrano', state_id: 6 },
  { id: 653, city_name: 'Caridade', state_id: 6 },
  { id: 654, city_name: 'Cariré', state_id: 6 },
  { id: 655, city_name: 'Caririaçu', state_id: 6 },
  { id: 656, city_name: 'Cariús', state_id: 6 },
  { id: 657, city_name: 'Carnaubal', state_id: 6 },
  { id: 658, city_name: 'Cascavel', state_id: 6 },
  { id: 659, city_name: 'Catarina', state_id: 6 },
  { id: 660, city_name: 'Catunda', state_id: 6 },
  { id: 661, city_name: 'Caucaia', state_id: 6 },
  { id: 662, city_name: 'Cedro', state_id: 6 },
  { id: 663, city_name: 'Chaval', state_id: 6 },
  { id: 664, city_name: 'Choró', state_id: 6 },
  { id: 665, city_name: 'Chorozinho', state_id: 6 },
  { id: 666, city_name: 'Coreaú', state_id: 6 },
  { id: 667, city_name: 'Crateús', state_id: 6 },
  { id: 668, city_name: 'Crato', state_id: 6 },
  { id: 669, city_name: 'Croatá', state_id: 6 },
  { id: 670, city_name: 'Cruz', state_id: 6 },
  { id: 671, city_name: 'Deputado Irapuan Pinheiro', state_id: 6 },
  { id: 672, city_name: 'Ereré', state_id: 6 },
  { id: 673, city_name: 'Eusébio', state_id: 6 },
  { id: 674, city_name: 'Farias Brito', state_id: 6 },
  { id: 675, city_name: 'Forquilha', state_id: 6 },
  { id: 676, city_name: 'Fortaleza', state_id: 6 },
  { id: 677, city_name: 'Fortim', state_id: 6 },
  { id: 678, city_name: 'Frecheirinha', state_id: 6 },
  { id: 679, city_name: 'General Sampaio', state_id: 6 },
  { id: 680, city_name: 'Graça', state_id: 6 },
  { id: 681, city_name: 'Granja', state_id: 6 },
  { id: 682, city_name: 'Granjeiro', state_id: 6 },
  { id: 683, city_name: 'Groaíras', state_id: 6 },
  { id: 684, city_name: 'Guaiúba', state_id: 6 },
  { id: 685, city_name: 'Guaraciaba do Norte', state_id: 6 },
  { id: 686, city_name: 'Guaramiranga', state_id: 6 },
  { id: 687, city_name: 'Hidrolândia', state_id: 6 },
  { id: 688, city_name: 'Horizonte', state_id: 6 },
  { id: 689, city_name: 'Ibaretama', state_id: 6 },
  { id: 690, city_name: 'Ibiapina', state_id: 6 },
  { id: 691, city_name: 'Ibicuitinga', state_id: 6 },
  { id: 692, city_name: 'Icapuí', state_id: 6 },
  { id: 693, city_name: 'Icó', state_id: 6 },
  { id: 694, city_name: 'Iguatu', state_id: 6 },
  { id: 695, city_name: 'Independência', state_id: 6 },
  { id: 696, city_name: 'Ipaporanga', state_id: 6 },
  { id: 697, city_name: 'Ipaumirim', state_id: 6 },
  { id: 698, city_name: 'Ipu', state_id: 6 },
  { id: 699, city_name: 'Ipueiras', state_id: 6 },
  { id: 700, city_name: 'Iracema', state_id: 6 },
  { id: 701, city_name: 'Irauçuba', state_id: 6 },
  { id: 702, city_name: 'Itaiçaba', state_id: 6 },
  { id: 703, city_name: 'Itaitinga', state_id: 6 },
  { id: 704, city_name: 'Itapajé', state_id: 6 },
  { id: 705, city_name: 'Itapipoca', state_id: 6 },
  { id: 706, city_name: 'Itapiúna', state_id: 6 },
  { id: 707, city_name: 'Itarema', state_id: 6 },
  { id: 708, city_name: 'Itatira', state_id: 6 },
  { id: 709, city_name: 'Jaguaretama', state_id: 6 },
  { id: 710, city_name: 'Jaguaribara', state_id: 6 },
  { id: 711, city_name: 'Jaguaribe', state_id: 6 },
  { id: 712, city_name: 'Jaguaruana', state_id: 6 },
  { id: 713, city_name: 'Jardim', state_id: 6 },
  { id: 714, city_name: 'Jati', state_id: 6 },
  { id: 715, city_name: 'Jijoca de Jericoacoara', state_id: 6 },
  { id: 716, city_name: 'Juazeiro do Norte', state_id: 6 },
  { id: 717, city_name: 'Jucás', state_id: 6 },
  { id: 718, city_name: 'Lavras da Mangabeira', state_id: 6 },
  { id: 719, city_name: 'Limoeiro do Norte', state_id: 6 },
  { id: 720, city_name: 'Madalena', state_id: 6 },
  { id: 721, city_name: 'Maracanaú', state_id: 6 },
  { id: 722, city_name: 'Maranguape', state_id: 6 },
  { id: 723, city_name: 'Marco', state_id: 6 },
  { id: 724, city_name: 'Martinópole', state_id: 6 },
  { id: 725, city_name: 'Massapê', state_id: 6 },
  { id: 726, city_name: 'Mauriti', state_id: 6 },
  { id: 727, city_name: 'Meruoca', state_id: 6 },
  { id: 728, city_name: 'Milhã', state_id: 6 },
  { id: 729, city_name: 'Miraíma', state_id: 6 },
  { id: 730, city_name: 'Missão Velha', state_id: 6 },
  { id: 731, city_name: 'Mombaça', state_id: 6 },
  { id: 732, city_name: 'Monsenhor Tabosa', state_id: 6 },
  { id: 733, city_name: 'Morada Nova', state_id: 6 },
  { id: 734, city_name: 'Moraújo', state_id: 6 },
  { id: 735, city_name: 'Morrinhos', state_id: 6 },
  { id: 736, city_name: 'Mucambo', state_id: 6 },
  { id: 737, city_name: 'Mulungu', state_id: 6 },
  { id: 738, city_name: 'Nova Olinda', state_id: 6 },
  { id: 739, city_name: 'Nova Russas', state_id: 6 },
  { id: 740, city_name: 'Novo Oriente', state_id: 6 },
  { id: 741, city_name: 'Ocara', state_id: 6 },
  { id: 742, city_name: 'Orós', state_id: 6 },
  { id: 743, city_name: 'Pacajus', state_id: 6 },
  { id: 744, city_name: 'Pacatuba', state_id: 6 },
  { id: 745, city_name: 'Pacoti', state_id: 6 },
  { id: 746, city_name: 'Pacujá', state_id: 6 },
  { id: 747, city_name: 'Palhano', state_id: 6 },
  { id: 748, city_name: 'Palmácia', state_id: 6 },
  { id: 749, city_name: 'Paracuru', state_id: 6 },
  { id: 750, city_name: 'Paraipaba', state_id: 6 },
  { id: 751, city_name: 'Parambu', state_id: 6 },
  { id: 752, city_name: 'Paramoti', state_id: 6 },
  { id: 753, city_name: 'Pedra Branca', state_id: 6 },
  { id: 754, city_name: 'Penaforte', state_id: 6 },
  { id: 755, city_name: 'Pentecoste', state_id: 6 },
  { id: 756, city_name: 'Pereiro', state_id: 6 },
  { id: 757, city_name: 'Pindoretama', state_id: 6 },
  { id: 758, city_name: 'Piquet Carneiro', state_id: 6 },
  { id: 759, city_name: 'Pires Ferreira', state_id: 6 },
  { id: 760, city_name: 'Poranga', state_id: 6 },
  { id: 761, city_name: 'Porteiras', state_id: 6 },
  { id: 762, city_name: 'Potengi', state_id: 6 },
  { id: 763, city_name: 'Potiretama', state_id: 6 },
  { id: 764, city_name: 'Quiterianópolis', state_id: 6 },
  { id: 765, city_name: 'Quixadá', state_id: 6 },
  { id: 766, city_name: 'Quixelô', state_id: 6 },
  { id: 767, city_name: 'Quixeramobim', state_id: 6 },
  { id: 768, city_name: 'Quixeré', state_id: 6 },
  { id: 769, city_name: 'Redenção', state_id: 6 },
  { id: 770, city_name: 'Reriutaba', state_id: 6 },
  { id: 771, city_name: 'Russas', state_id: 6 },
  { id: 772, city_name: 'Saboeiro', state_id: 6 },
  { id: 773, city_name: 'Salitre', state_id: 6 },
  { id: 774, city_name: 'Santa Quitéria', state_id: 6 },
  { id: 775, city_name: 'Santana do Acaraú', state_id: 6 },
  { id: 776, city_name: 'Santana do Cariri', state_id: 6 },
  { id: 777, city_name: 'São Benedito', state_id: 6 },
  { id: 778, city_name: 'São Gonçalo do Amarante', state_id: 6 },
  { id: 779, city_name: 'São João do Jaguaribe', state_id: 6 },
  { id: 780, city_name: 'São Luís do Curu', state_id: 6 },
  { id: 781, city_name: 'Senador Pompeu', state_id: 6 },
  { id: 782, city_name: 'Senador Sá', state_id: 6 },
  { id: 783, city_name: 'Sobral', state_id: 6 },
  { id: 784, city_name: 'Solonópole', state_id: 6 },
  { id: 785, city_name: 'Tabuleiro do Norte', state_id: 6 },
  { id: 786, city_name: 'Tamboril', state_id: 6 },
  { id: 787, city_name: 'Tarrafas', state_id: 6 },
  { id: 788, city_name: 'Tauá', state_id: 6 },
  { id: 789, city_name: 'Tejuçuoca', state_id: 6 },
  { id: 790, city_name: 'Tianguá', state_id: 6 },
  { id: 791, city_name: 'Trairi', state_id: 6 },
  { id: 792, city_name: 'Tururu', state_id: 6 },
  { id: 793, city_name: 'Ubajara', state_id: 6 },
  { id: 794, city_name: 'Umari', state_id: 6 },
  { id: 795, city_name: 'Umirim', state_id: 6 },
  { id: 796, city_name: 'Uruburetama', state_id: 6 },
  { id: 797, city_name: 'Uruoca', state_id: 6 },
  { id: 798, city_name: 'Varjota', state_id: 6 },
  { id: 799, city_name: 'Várzea Alegre', state_id: 6 },
  { id: 800, city_name: 'Viçosa do Ceará', state_id: 6 },
  { id: 801, city_name: 'Brasília', state_id: 7 },
  { id: 802, city_name: 'Afonso Cláudio', state_id: 8 },
  { id: 803, city_name: 'Água Doce do Norte', state_id: 8 },
  { id: 804, city_name: 'Águia Branca', state_id: 8 },
  { id: 805, city_name: 'Alegre', state_id: 8 },
  { id: 806, city_name: 'Alfredo Chaves', state_id: 8 },
  { id: 807, city_name: 'Alto Rio Novo', state_id: 8 },
  { id: 808, city_name: 'Anchieta', state_id: 8 },
  { id: 809, city_name: 'Apiacá', state_id: 8 },
  { id: 810, city_name: 'Aracruz', state_id: 8 },
  { id: 811, city_name: 'Atílio Vivácqua', state_id: 8 },
  { id: 812, city_name: 'Baixo Guandu', state_id: 8 },
  { id: 813, city_name: 'Barra de São Francisco', state_id: 8 },
  { id: 814, city_name: 'Boa Esperança', state_id: 8 },
  { id: 815, city_name: 'Bom Jesus do Norte', state_id: 8 },
  { id: 816, city_name: 'Brejetuba', state_id: 8 },
  { id: 817, city_name: 'Cachoeiro de Itapemirim', state_id: 8 },
  { id: 818, city_name: 'Cariacica', state_id: 8 },
  { id: 819, city_name: 'Castelo', state_id: 8 },
  { id: 820, city_name: 'Colatina', state_id: 8 },
  { id: 821, city_name: 'Conceição da Barra', state_id: 8 },
  { id: 822, city_name: 'Conceição do Castelo', state_id: 8 },
  { id: 823, city_name: 'Divino de São Lourenço', state_id: 8 },
  { id: 824, city_name: 'Domingos Martins', state_id: 8 },
  { id: 825, city_name: 'Dores do Rio Preto', state_id: 8 },
  { id: 826, city_name: 'Ecoporanga', state_id: 8 },
  { id: 827, city_name: 'Fundão', state_id: 8 },
  { id: 828, city_name: 'Governador Lindenberg', state_id: 8 },
  { id: 829, city_name: 'Guaçuí', state_id: 8 },
  { id: 830, city_name: 'Guarapari', state_id: 8 },
  { id: 831, city_name: 'Ibatiba', state_id: 8 },
  { id: 832, city_name: 'Ibiraçu', state_id: 8 },
  { id: 833, city_name: 'Ibitirama', state_id: 8 },
  { id: 834, city_name: 'Iconha', state_id: 8 },
  { id: 835, city_name: 'Irupi', state_id: 8 },
  { id: 836, city_name: 'Itaguaçu', state_id: 8 },
  { id: 837, city_name: 'Itapemirim', state_id: 8 },
  { id: 838, city_name: 'Itarana', state_id: 8 },
  { id: 839, city_name: 'Iúna', state_id: 8 },
  { id: 840, city_name: 'Jaguaré', state_id: 8 },
  { id: 841, city_name: 'Jerônimo Monteiro', state_id: 8 },
  { id: 842, city_name: 'João Neiva', state_id: 8 },
  { id: 843, city_name: 'Laranja da Terra', state_id: 8 },
  { id: 844, city_name: 'Linhares', state_id: 8 },
  { id: 845, city_name: 'Mantenópolis', state_id: 8 },
  { id: 846, city_name: 'Marataízes', state_id: 8 },
  { id: 847, city_name: 'Marechal Floriano', state_id: 8 },
  { id: 848, city_name: 'Marilândia', state_id: 8 },
  { id: 849, city_name: 'Mimoso do Sul', state_id: 8 },
  { id: 850, city_name: 'Montanha', state_id: 8 },
  { id: 851, city_name: 'Mucurici', state_id: 8 },
  { id: 852, city_name: 'Muniz Freire', state_id: 8 },
  { id: 853, city_name: 'Muqui', state_id: 8 },
  { id: 854, city_name: 'Nova Venécia', state_id: 8 },
  { id: 855, city_name: 'Pancas', state_id: 8 },
  { id: 856, city_name: 'Pedro Canário', state_id: 8 },
  { id: 857, city_name: 'Pinheiros', state_id: 8 },
  { id: 858, city_name: 'Piúma', state_id: 8 },
  { id: 859, city_name: 'Ponto Belo', state_id: 8 },
  { id: 860, city_name: 'Presidente Kennedy', state_id: 8 },
  { id: 861, city_name: 'Rio Bananal', state_id: 8 },
  { id: 862, city_name: 'Rio Novo do Sul', state_id: 8 },
  { id: 863, city_name: 'Santa Leopoldina', state_id: 8 },
  { id: 864, city_name: 'Santa Maria de Jetibá', state_id: 8 },
  { id: 865, city_name: 'Santa Teresa', state_id: 8 },
  { id: 866, city_name: 'São Domingos do Norte', state_id: 8 },
  { id: 867, city_name: 'São Gabriel da Palha', state_id: 8 },
  { id: 868, city_name: 'São José do Calçado', state_id: 8 },
  { id: 869, city_name: 'São Mateus', state_id: 8 },
  { id: 870, city_name: 'São Roque do Canaã', state_id: 8 },
  { id: 871, city_name: 'Serra', state_id: 8 },
  { id: 872, city_name: 'Sooretama', state_id: 8 },
  { id: 873, city_name: 'Vargem Alta', state_id: 8 },
  { id: 874, city_name: 'Venda Nova do Imigrante', state_id: 8 },
  { id: 875, city_name: 'Viana', state_id: 8 },
  { id: 876, city_name: 'Vila Pavão', state_id: 8 },
  { id: 877, city_name: 'Vila Valério', state_id: 8 },
  { id: 878, city_name: 'Vila Velha', state_id: 8 },
  { id: 879, city_name: 'Vitória', state_id: 8 },
  { id: 880, city_name: 'Abadia de Goiás', state_id: 9 },
  { id: 881, city_name: 'Abadiânia', state_id: 9 },
  { id: 882, city_name: 'Acreúna', state_id: 9 },
  { id: 883, city_name: 'Adelândia', state_id: 9 },
  { id: 884, city_name: 'Água Fria de Goiás', state_id: 9 },
  { id: 885, city_name: 'Água Limpa', state_id: 9 },
  { id: 886, city_name: 'Águas Lindas de Goiás', state_id: 9 },
  { id: 887, city_name: 'Alexânia', state_id: 9 },
  { id: 888, city_name: 'Aloândia', state_id: 9 },
  { id: 889, city_name: 'Alto Horizonte', state_id: 9 },
  { id: 890, city_name: 'Alto Paraíso de Goiás', state_id: 9 },
  { id: 891, city_name: 'Alvorada do Norte', state_id: 9 },
  { id: 892, city_name: 'Amaralina', state_id: 9 },
  { id: 893, city_name: 'Americano do Brasil', state_id: 9 },
  { id: 894, city_name: 'Amorinópolis', state_id: 9 },
  { id: 895, city_name: 'Anápolis', state_id: 9 },
  { id: 896, city_name: 'Anhanguera', state_id: 9 },
  { id: 897, city_name: 'Anicuns', state_id: 9 },
  { id: 898, city_name: 'Aparecida de Goiânia', state_id: 9 },
  { id: 899, city_name: 'Aparecida do Rio Doce', state_id: 9 },
  { id: 900, city_name: 'Aporé', state_id: 9 },
  { id: 901, city_name: 'Araçu', state_id: 9 },
  { id: 902, city_name: 'Aragarças', state_id: 9 },
  { id: 903, city_name: 'Aragoiânia', state_id: 9 },
  { id: 904, city_name: 'Araguapaz', state_id: 9 },
  { id: 905, city_name: 'Arenópolis', state_id: 9 },
  { id: 906, city_name: 'Aruanã', state_id: 9 },
  { id: 907, city_name: 'Aurilândia', state_id: 9 },
  { id: 908, city_name: 'Avelinópolis', state_id: 9 },
  { id: 909, city_name: 'Baliza', state_id: 9 },
  { id: 910, city_name: 'Bela Vista de Goiás', state_id: 9 },
  { id: 911, city_name: 'Bom Jardim de Goiás', state_id: 9 },
  { id: 912, city_name: 'Bom Jesus de Goiás', state_id: 9 },
  { id: 913, city_name: 'Bonfinópolis', state_id: 9 },
  { id: 914, city_name: 'Bonópolis', state_id: 9 },
  { id: 915, city_name: 'Brazabrantes', state_id: 9 },
  { id: 916, city_name: 'Britânia', state_id: 9 },
  { id: 917, city_name: 'Buriti Alegre', state_id: 9 },
  { id: 918, city_name: 'Buriti de Goiás', state_id: 9 },
  { id: 919, city_name: 'Buritinópolis', state_id: 9 },
  { id: 920, city_name: 'Cabeceiras', state_id: 9 },
  { id: 921, city_name: 'Cachoeira Alta', state_id: 9 },
  { id: 922, city_name: 'Cachoeira de Goiás', state_id: 9 },
  { id: 923, city_name: 'Cachoeira Dourada', state_id: 9 },
  { id: 924, city_name: 'Caçu', state_id: 9 },
  { id: 925, city_name: 'Caiapônia', state_id: 9 },
  { id: 926, city_name: 'Caldas Novas', state_id: 9 },
  { id: 927, city_name: 'Caldazinha', state_id: 9 },
  { id: 928, city_name: 'Campestre de Goiás', state_id: 9 },
  { id: 929, city_name: 'Campinaçu', state_id: 9 },
  { id: 930, city_name: 'Campinorte', state_id: 9 },
  { id: 931, city_name: 'Campo Alegre de Goiás', state_id: 9 },
  { id: 932, city_name: 'Campo Limpo de Goiás', state_id: 9 },
  { id: 933, city_name: 'Campos Belos', state_id: 9 },
  { id: 934, city_name: 'Campos Verdes', state_id: 9 },
  { id: 935, city_name: 'Carmo do Rio Verde', state_id: 9 },
  { id: 936, city_name: 'Castelândia', state_id: 9 },
  { id: 937, city_name: 'Catalão', state_id: 9 },
  { id: 938, city_name: 'Caturaí', state_id: 9 },
  { id: 939, city_name: 'Cavalcante', state_id: 9 },
  { id: 940, city_name: 'Ceres', state_id: 9 },
  { id: 941, city_name: 'Cezarina', state_id: 9 },
  { id: 942, city_name: 'Chapadão do Céu', state_id: 9 },
  { id: 943, city_name: 'Cidade Ocidental', state_id: 9 },
  { id: 944, city_name: 'Cocalzinho de Goiás', state_id: 9 },
  { id: 945, city_name: 'Colinas do Sul', state_id: 9 },
  { id: 946, city_name: 'Córrego do Ouro', state_id: 9 },
  { id: 947, city_name: 'Corumbá de Goiás', state_id: 9 },
  { id: 948, city_name: 'Corumbaíba', state_id: 9 },
  { id: 949, city_name: 'Cristalina', state_id: 9 },
  { id: 950, city_name: 'Cristianópolis', state_id: 9 },
  { id: 951, city_name: 'Crixás', state_id: 9 },
  { id: 952, city_name: 'Cromínia', state_id: 9 },
  { id: 953, city_name: 'Cumari', state_id: 9 },
  { id: 954, city_name: 'Damianópolis', state_id: 9 },
  { id: 955, city_name: 'Damolândia', state_id: 9 },
  { id: 956, city_name: 'Davinópolis', state_id: 9 },
  { id: 957, city_name: 'Diorama', state_id: 9 },
  { id: 958, city_name: 'Divinópolis de Goiás', state_id: 9 },
  { id: 959, city_name: 'Doverlândia', state_id: 9 },
  { id: 960, city_name: 'Edealina', state_id: 9 },
  { id: 961, city_name: 'Edéia', state_id: 9 },
  { id: 962, city_name: 'Estrela do Norte', state_id: 9 },
  { id: 963, city_name: 'Faina', state_id: 9 },
  { id: 964, city_name: 'Fazenda Nova', state_id: 9 },
  { id: 965, city_name: 'Firminópolis', state_id: 9 },
  { id: 966, city_name: 'Flores de Goiás', state_id: 9 },
  { id: 967, city_name: 'Formosa', state_id: 9 },
  { id: 968, city_name: 'Formoso', state_id: 9 },
  { id: 969, city_name: 'Gameleira de Goiás', state_id: 9 },
  { id: 970, city_name: 'Goianápolis', state_id: 9 },
  { id: 971, city_name: 'Goiandira', state_id: 9 },
  { id: 972, city_name: 'Goianésia', state_id: 9 },
  { id: 973, city_name: 'Goiânia', state_id: 9 },
  { id: 974, city_name: 'Goianira', state_id: 9 },
  { id: 975, city_name: 'Goiás', state_id: 9 },
  { id: 976, city_name: 'Goiatuba', state_id: 9 },
  { id: 977, city_name: 'Gouvelândia', state_id: 9 },
  { id: 978, city_name: 'Guapó', state_id: 9 },
  { id: 979, city_name: 'Guaraíta', state_id: 9 },
  { id: 980, city_name: 'Guarani de Goiás', state_id: 9 },
  { id: 981, city_name: 'Guarinos', state_id: 9 },
  { id: 982, city_name: 'Heitoraí', state_id: 9 },
  { id: 983, city_name: 'Hidrolina', state_id: 9 },
  { id: 984, city_name: 'Iaciara', state_id: 9 },
  { id: 985, city_name: 'Inaciolândia', state_id: 9 },
  { id: 986, city_name: 'Indiara', state_id: 9 },
  { id: 987, city_name: 'Inhumas', state_id: 9 },
  { id: 988, city_name: 'Ipameri', state_id: 9 },
  { id: 989, city_name: 'Ipiranga de Goiás', state_id: 9 },
  { id: 990, city_name: 'Iporá', state_id: 9 },
  { id: 991, city_name: 'Israelândia', state_id: 9 },
  { id: 992, city_name: 'Itaberaí', state_id: 9 },
  { id: 993, city_name: 'Itaguari', state_id: 9 },
  { id: 994, city_name: 'Itaguaru', state_id: 9 },
  { id: 995, city_name: 'Itajá', state_id: 9 },
  { id: 996, city_name: 'Itapaci', state_id: 9 },
  { id: 997, city_name: 'Itapirapuã', state_id: 9 },
  { id: 998, city_name: 'Itapuranga', state_id: 9 },
  { id: 999, city_name: 'Itarumã', state_id: 9 },
  { id: 1000, city_name: 'Itauçu', state_id: 9 },
  { id: 1001, city_name: 'Itumbiara', state_id: 9 },
  { id: 1002, city_name: 'Ivolândia', state_id: 9 },
  { id: 1003, city_name: 'Jandaia', state_id: 9 },
  { id: 1004, city_name: 'Jaraguá', state_id: 9 },
  { id: 1005, city_name: 'Jataí', state_id: 9 },
  { id: 1006, city_name: 'Jaupaci', state_id: 9 },
  { id: 1007, city_name: 'Jesúpolis', state_id: 9 },
  { id: 1008, city_name: 'Joviânia', state_id: 9 },
  { id: 1009, city_name: 'Lagoa Santa', state_id: 9 },
  { id: 1010, city_name: 'Leopoldo de Bulhões', state_id: 9 },
  { id: 1011, city_name: 'Luziânia', state_id: 9 },
  { id: 1012, city_name: 'Mairipotaba', state_id: 9 },
  { id: 1013, city_name: 'Mambaí', state_id: 9 },
  { id: 1014, city_name: 'Mara Rosa', state_id: 9 },
  { id: 1015, city_name: 'Marzagão', state_id: 9 },
  { id: 1016, city_name: 'Matrinchã', state_id: 9 },
  { id: 1017, city_name: 'Maurilândia', state_id: 9 },
  { id: 1018, city_name: 'Mimoso de Goiás', state_id: 9 },
  { id: 1019, city_name: 'Minaçu', state_id: 9 },
  { id: 1020, city_name: 'Mineiros', state_id: 9 },
  { id: 1021, city_name: 'Moiporá', state_id: 9 },
  { id: 1022, city_name: 'Monte Alegre de Goiás', state_id: 9 },
  { id: 1023, city_name: 'Montes Claros de Goiás', state_id: 9 },
  { id: 1024, city_name: 'Montividiu', state_id: 9 },
  { id: 1025, city_name: 'Montividiu do Norte', state_id: 9 },
  { id: 1026, city_name: 'Morro Agudo de Goiás', state_id: 9 },
  { id: 1027, city_name: 'Mossâmedes', state_id: 9 },
  { id: 1028, city_name: 'Mozarlândia', state_id: 9 },
  { id: 1029, city_name: 'Mutunópolis', state_id: 9 },
  { id: 1030, city_name: 'Nazário', state_id: 9 },
  { id: 1031, city_name: 'Nerópolis', state_id: 9 },
  { id: 1032, city_name: 'Niquelândia', state_id: 9 },
  { id: 1033, city_name: 'Nova América', state_id: 9 },
  { id: 1034, city_name: 'Nova Aurora', state_id: 9 },
  { id: 1035, city_name: 'Nova Crixás', state_id: 9 },
  { id: 1036, city_name: 'Nova Glória', state_id: 9 },
  { id: 1037, city_name: 'Nova Iguaçu de Goiás', state_id: 9 },
  { id: 1038, city_name: 'Nova Roma', state_id: 9 },
  { id: 1039, city_name: 'Nova Veneza', state_id: 9 },
  { id: 1040, city_name: 'Novo Brasil', state_id: 9 },
  { id: 1041, city_name: 'Novo Gama', state_id: 9 },
  { id: 1042, city_name: 'Novo Planalto', state_id: 9 },
  { id: 1043, city_name: 'Orizona', state_id: 9 },
  { id: 1044, city_name: 'Ouro Verde de Goiás', state_id: 9 },
  { id: 1045, city_name: 'Ouvidor', state_id: 9 },
  { id: 1046, city_name: 'Padre Bernardo', state_id: 9 },
  { id: 1047, city_name: 'Palestina de Goiás', state_id: 9 },
  { id: 1048, city_name: 'Palmeiras de Goiás', state_id: 9 },
  { id: 1049, city_name: 'Palmelo', state_id: 9 },
  { id: 1050, city_name: 'Palminópolis', state_id: 9 },
  { id: 1051, city_name: 'Panamá', state_id: 9 },
  { id: 1052, city_name: 'Paranaiguara', state_id: 9 },
  { id: 1053, city_name: 'Paraúna', state_id: 9 },
  { id: 1054, city_name: 'Perolândia', state_id: 9 },
  { id: 1055, city_name: 'Petrolina de Goiás', state_id: 9 },
  { id: 1056, city_name: 'Pilar de Goiás', state_id: 9 },
  { id: 1057, city_name: 'Piracanjuba', state_id: 9 },
  { id: 1058, city_name: 'Pirenópolis', state_id: 9 },
  { id: 1059, city_name: 'Pires do Rio', state_id: 9 },
  { id: 1060, city_name: 'Planaltina', state_id: 9 },
  { id: 1061, city_name: 'Pontalina', state_id: 9 },
  { id: 1062, city_name: 'Porangatu', state_id: 9 },
  { id: 1063, city_name: 'Porteirão', state_id: 9 },
  { id: 1064, city_name: 'Portelândia', state_id: 9 },
  { id: 1065, city_name: 'Posse', state_id: 9 },
  { id: 1066, city_name: 'Professor Jamil', state_id: 9 },
  { id: 1067, city_name: 'Quirinópolis', state_id: 9 },
  { id: 1068, city_name: 'Rialma', state_id: 9 },
  { id: 1069, city_name: 'Rianápolis', state_id: 9 },
  { id: 1070, city_name: 'Rio Quente', state_id: 9 },
  { id: 1071, city_name: 'Rio Verde', state_id: 9 },
  { id: 1072, city_name: 'Rubiataba', state_id: 9 },
  { id: 1073, city_name: 'Sanclerlândia', state_id: 9 },
  { id: 1074, city_name: 'Santa Bárbara de Goiás', state_id: 9 },
  { id: 1075, city_name: 'Santa Cruz de Goiás', state_id: 9 },
  { id: 1076, city_name: 'Santa Fé de Goiás', state_id: 9 },
  { id: 1077, city_name: 'Santa Helena de Goiás', state_id: 9 },
  { id: 1078, city_name: 'Santa Isabel', state_id: 9 },
  { id: 1079, city_name: 'Santa Rita do Araguaia', state_id: 9 },
  { id: 1080, city_name: 'Santa Rita do Novo Destino', state_id: 9 },
  { id: 1081, city_name: 'Santa Rosa de Goiás', state_id: 9 },
  { id: 1082, city_name: 'Santa Tereza de Goiás', state_id: 9 },
  { id: 1083, city_name: 'Santa Terezinha de Goiás', state_id: 9 },
  { id: 1084, city_name: 'Santo Antônio da Barra', state_id: 9 },
  { id: 1085, city_name: 'Santo Antônio de Goiás', state_id: 9 },
  { id: 1086, city_name: 'Santo Antônio do Descoberto', state_id: 9 },
  { id: 1087, city_name: 'São Francisco de Goiás', state_id: 9 },
  { id: 1088, city_name: 'São João da Paraúna', state_id: 9 },
  { id: 1089, city_name: "São João d'Aliança", state_id: 9 },
  { id: 1090, city_name: 'São Luís de Montes Belos', state_id: 9 },
  { id: 1091, city_name: 'São Luiz do Norte', state_id: 9 },
  { id: 1092, city_name: 'São Miguel do Araguaia', state_id: 9 },
  { id: 1093, city_name: 'São Miguel do Passa Quatro', state_id: 9 },
  { id: 1094, city_name: 'São Patrício', state_id: 9 },
  { id: 1095, city_name: 'São Simão', state_id: 9 },
  { id: 1096, city_name: 'Senador Canedo', state_id: 9 },
  { id: 1097, city_name: 'Serranópolis', state_id: 9 },
  { id: 1098, city_name: 'Silvânia', state_id: 9 },
  { id: 1099, city_name: 'Simolândia', state_id: 9 },
  { id: 1100, city_name: "Sítio d'Abadia", state_id: 9 },
  { id: 1101, city_name: 'Taquaral de Goiás', state_id: 9 },
  { id: 1102, city_name: 'Teresina de Goiás', state_id: 9 },
  { id: 1103, city_name: 'Terezópolis de Goiás', state_id: 9 },
  { id: 1104, city_name: 'Três Ranchos', state_id: 9 },
  { id: 1105, city_name: 'Trindade', state_id: 9 },
  { id: 1106, city_name: 'Trombas', state_id: 9 },
  { id: 1107, city_name: 'Turvânia', state_id: 9 },
  { id: 1108, city_name: 'Turvelândia', state_id: 9 },
  { id: 1109, city_name: 'Uirapuru', state_id: 9 },
  { id: 1110, city_name: 'Uruaçu', state_id: 9 },
  { id: 1111, city_name: 'Uruana', state_id: 9 },
  { id: 1112, city_name: 'Urutaí', state_id: 9 },
  { id: 1113, city_name: 'Valparaíso de Goiás', state_id: 9 },
  { id: 1114, city_name: 'Varjão', state_id: 9 },
  { id: 1115, city_name: 'Vianópolis', state_id: 9 },
  { id: 1116, city_name: 'Vicentinópolis', state_id: 9 },
  { id: 1117, city_name: 'Vila Boa', state_id: 9 },
  { id: 1118, city_name: 'Vila Propício', state_id: 9 },
  { id: 1119, city_name: 'Açailândia', state_id: 10 },
  { id: 1120, city_name: 'Afonso Cunha', state_id: 10 },
  { id: 1121, city_name: 'Água Doce do Maranhão', state_id: 10 },
  { id: 1122, city_name: 'Alcântara', state_id: 10 },
  { id: 1123, city_name: 'Aldeias Altas', state_id: 10 },
  { id: 1124, city_name: 'Altamira do Maranhão', state_id: 10 },
  { id: 1125, city_name: 'Alto Alegre do Maranhão', state_id: 10 },
  { id: 1126, city_name: 'Alto Alegre do Pindaré', state_id: 10 },
  { id: 1127, city_name: 'Alto Parnaíba', state_id: 10 },
  { id: 1128, city_name: 'Amapá do Maranhão', state_id: 10 },
  { id: 1129, city_name: 'Amarante do Maranhão', state_id: 10 },
  { id: 1130, city_name: 'Anajatuba', state_id: 10 },
  { id: 1131, city_name: 'Anapurus', state_id: 10 },
  { id: 1132, city_name: 'Apicum-Açu', state_id: 10 },
  { id: 1133, city_name: 'Araguanã', state_id: 10 },
  { id: 1134, city_name: 'Araioses', state_id: 10 },
  { id: 1135, city_name: 'Arame', state_id: 10 },
  { id: 1136, city_name: 'Arari', state_id: 10 },
  { id: 1137, city_name: 'Axixá', state_id: 10 },
  { id: 1138, city_name: 'Bacabal', state_id: 10 },
  { id: 1139, city_name: 'Bacabeira', state_id: 10 },
  { id: 1140, city_name: 'Bacuri', state_id: 10 },
  { id: 1141, city_name: 'Bacurituba', state_id: 10 },
  { id: 1142, city_name: 'Balsas', state_id: 10 },
  { id: 1143, city_name: 'Barão de Grajaú', state_id: 10 },
  { id: 1144, city_name: 'Barra do Corda', state_id: 10 },
  { id: 1145, city_name: 'Barreirinhas', state_id: 10 },
  { id: 1146, city_name: 'Bela Vista do Maranhão', state_id: 10 },
  { id: 1147, city_name: 'Belágua', state_id: 10 },
  { id: 1148, city_name: 'Benedito Leite', state_id: 10 },
  { id: 1149, city_name: 'Bequimão', state_id: 10 },
  { id: 1150, city_name: 'Bernardo do Mearim', state_id: 10 },
  { id: 1151, city_name: 'Boa Vista do Gurupi', state_id: 10 },
  { id: 1152, city_name: 'Bom Jardim', state_id: 10 },
  { id: 1153, city_name: 'Bom Jesus das Selvas', state_id: 10 },
  { id: 1154, city_name: 'Bom Lugar', state_id: 10 },
  { id: 1155, city_name: 'Brejo', state_id: 10 },
  { id: 1156, city_name: 'Brejo de Areia', state_id: 10 },
  { id: 1157, city_name: 'Buriti', state_id: 10 },
  { id: 1158, city_name: 'Buriti Bravo', state_id: 10 },
  { id: 1159, city_name: 'Buriticupu', state_id: 10 },
  { id: 1160, city_name: 'Buritirana', state_id: 10 },
  { id: 1161, city_name: 'Cachoeira Grande', state_id: 10 },
  { id: 1162, city_name: 'Cajapió', state_id: 10 },
  { id: 1163, city_name: 'Cajari', state_id: 10 },
  { id: 1164, city_name: 'Campestre do Maranhão', state_id: 10 },
  { id: 1165, city_name: 'Cândido Mendes', state_id: 10 },
  { id: 1166, city_name: 'Cantanhede', state_id: 10 },
  { id: 1167, city_name: 'Capinzal do Norte', state_id: 10 },
  { id: 1168, city_name: 'Carolina', state_id: 10 },
  { id: 1169, city_name: 'Carutapera', state_id: 10 },
  { id: 1170, city_name: 'Caxias', state_id: 10 },
  { id: 1171, city_name: 'Cedral', state_id: 10 },
  { id: 1172, city_name: 'Central do Maranhão', state_id: 10 },
  { id: 1173, city_name: 'Centro do Guilherme', state_id: 10 },
  { id: 1174, city_name: 'Centro Novo do Maranhão', state_id: 10 },
  { id: 1175, city_name: 'Chapadinha', state_id: 10 },
  { id: 1176, city_name: 'Cidelândia', state_id: 10 },
  { id: 1177, city_name: 'Codó', state_id: 10 },
  { id: 1178, city_name: 'Coelho Neto', state_id: 10 },
  { id: 1179, city_name: 'Colinas', state_id: 10 },
  { id: 1180, city_name: 'Conceição do Lago-Açu', state_id: 10 },
  { id: 1181, city_name: 'Coroatá', state_id: 10 },
  { id: 1182, city_name: 'Cururupu', state_id: 10 },
  { id: 1183, city_name: 'Dom Pedro', state_id: 10 },
  { id: 1184, city_name: 'Duque Bacelar', state_id: 10 },
  { id: 1185, city_name: 'Esperantinópolis', state_id: 10 },
  { id: 1186, city_name: 'Estreito', state_id: 10 },
  { id: 1187, city_name: 'Feira Nova do Maranhão', state_id: 10 },
  { id: 1188, city_name: 'Fernando Falcão', state_id: 10 },
  { id: 1189, city_name: 'Formosa da Serra Negra', state_id: 10 },
  { id: 1190, city_name: 'Fortaleza dos Nogueiras', state_id: 10 },
  { id: 1191, city_name: 'Fortuna', state_id: 10 },
  { id: 1192, city_name: 'Godofredo Viana', state_id: 10 },
  { id: 1193, city_name: 'Gonçalves Dias', state_id: 10 },
  { id: 1194, city_name: 'Governador Archer', state_id: 10 },
  { id: 1195, city_name: 'Governador Edison Lobão', state_id: 10 },
  { id: 1196, city_name: 'Governador Eugênio Barros', state_id: 10 },
  { id: 1197, city_name: 'Governador Luiz Rocha', state_id: 10 },
  { id: 1198, city_name: 'Governador Newton Bello', state_id: 10 },
  { id: 1199, city_name: 'Governador Nunes Freire', state_id: 10 },
  { id: 1200, city_name: 'Graça Aranha', state_id: 10 },
  { id: 1201, city_name: 'Grajaú', state_id: 10 },
  { id: 1202, city_name: 'Guimarães', state_id: 10 },
  { id: 1203, city_name: 'Humberto de Campos', state_id: 10 },
  { id: 1204, city_name: 'Icatu', state_id: 10 },
  { id: 1205, city_name: 'Igarapé do Meio', state_id: 10 },
  { id: 1206, city_name: 'Igarapé Grande', state_id: 10 },
  { id: 1207, city_name: 'Imperatriz', state_id: 10 },
  { id: 1208, city_name: 'Itaipava do Grajaú', state_id: 10 },
  { id: 1209, city_name: 'Itapecuru Mirim', state_id: 10 },
  { id: 1210, city_name: 'Itinga do Maranhão', state_id: 10 },
  { id: 1211, city_name: 'Jatobá', state_id: 10 },
  { id: 1212, city_name: 'Jenipapo dos Vieiras', state_id: 10 },
  { id: 1213, city_name: 'João Lisboa', state_id: 10 },
  { id: 1214, city_name: 'Joselândia', state_id: 10 },
  { id: 1215, city_name: 'Junco do Maranhão', state_id: 10 },
  { id: 1216, city_name: 'Lago da Pedra', state_id: 10 },
  { id: 1217, city_name: 'Lago do Junco', state_id: 10 },
  { id: 1218, city_name: 'Lago dos Rodrigues', state_id: 10 },
  { id: 1219, city_name: 'Lago Verde', state_id: 10 },
  { id: 1220, city_name: 'Lagoa do Mato', state_id: 10 },
  { id: 1221, city_name: 'Lagoa Grande do Maranhão', state_id: 10 },
  { id: 1222, city_name: 'Lajeado Novo', state_id: 10 },
  { id: 1223, city_name: 'Lima Campos', state_id: 10 },
  { id: 1224, city_name: 'Loreto', state_id: 10 },
  { id: 1225, city_name: 'Luís Domingues', state_id: 10 },
  { id: 1226, city_name: 'Magalhães de Almeida', state_id: 10 },
  { id: 1227, city_name: 'Maracaçumé', state_id: 10 },
  { id: 1228, city_name: 'Marajá do Sena', state_id: 10 },
  { id: 1229, city_name: 'Maranhãozinho', state_id: 10 },
  { id: 1230, city_name: 'Mata Roma', state_id: 10 },
  { id: 1231, city_name: 'Matinha', state_id: 10 },
  { id: 1232, city_name: 'Matões', state_id: 10 },
  { id: 1233, city_name: 'Matões do Norte', state_id: 10 },
  { id: 1234, city_name: 'Milagres do Maranhão', state_id: 10 },
  { id: 1235, city_name: 'Mirador', state_id: 10 },
  { id: 1236, city_name: 'Miranda do Norte', state_id: 10 },
  { id: 1237, city_name: 'Mirinzal', state_id: 10 },
  { id: 1238, city_name: 'Monção', state_id: 10 },
  { id: 1239, city_name: 'Montes Altos', state_id: 10 },
  { id: 1240, city_name: 'Morros', state_id: 10 },
  { id: 1241, city_name: 'Nina Rodrigues', state_id: 10 },
  { id: 1242, city_name: 'Nova Colinas', state_id: 10 },
  { id: 1243, city_name: 'Nova Iorque', state_id: 10 },
  { id: 1244, city_name: 'Nova Olinda do Maranhão', state_id: 10 },
  { id: 1245, city_name: "Olho d'Água das Cunhãs", state_id: 10 },
  { id: 1246, city_name: 'Olinda Nova do Maranhão', state_id: 10 },
  { id: 1247, city_name: 'Paço do Lumiar', state_id: 10 },
  { id: 1248, city_name: 'Palmeirândia', state_id: 10 },
  { id: 1249, city_name: 'Paraibano', state_id: 10 },
  { id: 1250, city_name: 'Parnarama', state_id: 10 },
  { id: 1251, city_name: 'Passagem Franca', state_id: 10 },
  { id: 1252, city_name: 'Pastos Bons', state_id: 10 },
  { id: 1253, city_name: 'Paulino Neves', state_id: 10 },
  { id: 1254, city_name: 'Paulo Ramos', state_id: 10 },
  { id: 1255, city_name: 'Pedreiras', state_id: 10 },
  { id: 1256, city_name: 'Pedro do Rosário', state_id: 10 },
  { id: 1257, city_name: 'Penalva', state_id: 10 },
  { id: 1258, city_name: 'Peri Mirim', state_id: 10 },
  { id: 1259, city_name: 'Peritoró', state_id: 10 },
  { id: 1260, city_name: 'Pindaré-Mirim', state_id: 10 },
  { id: 1261, city_name: 'Pinheiro', state_id: 10 },
  { id: 1262, city_name: 'Pio XII', state_id: 10 },
  { id: 1263, city_name: 'Pirapemas', state_id: 10 },
  { id: 1264, city_name: 'Poção de Pedras', state_id: 10 },
  { id: 1265, city_name: 'Porto Franco', state_id: 10 },
  { id: 1266, city_name: 'Porto Rico do Maranhão', state_id: 10 },
  { id: 1267, city_name: 'Presidente Juscelino', state_id: 10 },
  { id: 1268, city_name: 'Presidente Médici', state_id: 10 },
  { id: 1269, city_name: 'Presidente Sarney', state_id: 10 },
  { id: 1270, city_name: 'Presidente Vargas', state_id: 10 },
  { id: 1271, city_name: 'Primeira Cruz', state_id: 10 },
  { id: 1272, city_name: 'Raposa', state_id: 10 },
  { id: 1273, city_name: 'Riachão', state_id: 10 },
  { id: 1274, city_name: 'Ribamar Fiquene', state_id: 10 },
  { id: 1275, city_name: 'Rosário', state_id: 10 },
  { id: 1276, city_name: 'Sambaíba', state_id: 10 },
  { id: 1277, city_name: 'Santa Filomena do Maranhão', state_id: 10 },
  { id: 1278, city_name: 'Santa Helena', state_id: 10 },
  { id: 1279, city_name: 'Santa Luzia do Paruá', state_id: 10 },
  { id: 1280, city_name: 'Santa Quitéria do Maranhão', state_id: 10 },
  { id: 1281, city_name: 'Santa Rita', state_id: 10 },
  { id: 1282, city_name: 'Santana do Maranhão', state_id: 10 },
  { id: 1283, city_name: 'Santo Amaro do Maranhão', state_id: 10 },
  { id: 1284, city_name: 'Santo Antônio dos Lopes', state_id: 10 },
  { id: 1285, city_name: 'São Benedito do Rio Preto', state_id: 10 },
  { id: 1286, city_name: 'São Bento', state_id: 10 },
  { id: 1287, city_name: 'São Bernardo', state_id: 10 },
  { id: 1288, city_name: 'São Domingos do Azeitão', state_id: 10 },
  { id: 1289, city_name: 'São Domingos do Maranhão', state_id: 10 },
  { id: 1290, city_name: 'São Félix de Balsas', state_id: 10 },
  { id: 1291, city_name: 'São Francisco do Brejão', state_id: 10 },
  { id: 1292, city_name: 'São Francisco do Maranhão', state_id: 10 },
  { id: 1293, city_name: 'São João Batista', state_id: 10 },
  { id: 1294, city_name: 'São João do Carú', state_id: 10 },
  { id: 1295, city_name: 'São João do Paraíso', state_id: 10 },
  { id: 1296, city_name: 'São João do Soter', state_id: 10 },
  { id: 1297, city_name: 'São João dos Patos', state_id: 10 },
  { id: 1298, city_name: 'São José de Ribamar', state_id: 10 },
  { id: 1299, city_name: 'São José dos Basílios', state_id: 10 },
  { id: 1300, city_name: 'São Luís', state_id: 10 },
  { id: 1301, city_name: 'São Luís Gonzaga do Maranhão', state_id: 10 },
  { id: 1302, city_name: 'São Mateus do Maranhão', state_id: 10 },
  { id: 1303, city_name: 'São Pedro da Água Branca', state_id: 10 },
  { id: 1304, city_name: 'São Pedro dos Crentes', state_id: 10 },
  { id: 1305, city_name: 'São Raimundo das Mangabeiras', state_id: 10 },
  { id: 1306, city_name: 'São Raimundo do Doca Bezerra', state_id: 10 },
  { id: 1307, city_name: 'São Roberto', state_id: 10 },
  { id: 1308, city_name: 'São Vicente Ferrer', state_id: 10 },
  { id: 1309, city_name: 'Satubinha', state_id: 10 },
  { id: 1310, city_name: 'Senador Alexandre Costa', state_id: 10 },
  { id: 1311, city_name: 'Senador La Rocque', state_id: 10 },
  { id: 1312, city_name: 'Serrano do Maranhão', state_id: 10 },
  { id: 1313, city_name: 'Sítio Novo', state_id: 10 },
  { id: 1314, city_name: 'Sucupira do Norte', state_id: 10 },
  { id: 1315, city_name: 'Sucupira do Riachão', state_id: 10 },
  { id: 1316, city_name: 'Tasso Fragoso', state_id: 10 },
  { id: 1317, city_name: 'Timbiras', state_id: 10 },
  { id: 1318, city_name: 'Timon', state_id: 10 },
  { id: 1319, city_name: 'Trizidela do Vale', state_id: 10 },
  { id: 1320, city_name: 'Tufilândia', state_id: 10 },
  { id: 1321, city_name: 'Tuntum', state_id: 10 },
  { id: 1322, city_name: 'Turiaçu', state_id: 10 },
  { id: 1323, city_name: 'Turilândia', state_id: 10 },
  { id: 1324, city_name: 'Tutóia', state_id: 10 },
  { id: 1325, city_name: 'Urbano Santos', state_id: 10 },
  { id: 1326, city_name: 'Vargem Grande', state_id: 10 },
  { id: 1327, city_name: 'Vila Nova dos Martírios', state_id: 10 },
  { id: 1328, city_name: 'Vitória do Mearim', state_id: 10 },
  { id: 1329, city_name: 'Vitorino Freire', state_id: 10 },
  { id: 1330, city_name: 'Zé Doca', state_id: 10 },
  { id: 1331, city_name: 'Acorizal', state_id: 11 },
  { id: 1332, city_name: 'Água Boa', state_id: 11 },
  { id: 1333, city_name: 'Alta Floresta', state_id: 11 },
  { id: 1334, city_name: 'Alto Araguaia', state_id: 11 },
  { id: 1335, city_name: 'Alto Boa Vista', state_id: 11 },
  { id: 1336, city_name: 'Alto Garças', state_id: 11 },
  { id: 1337, city_name: 'Alto Paraguai', state_id: 11 },
  { id: 1338, city_name: 'Alto Taquari', state_id: 11 },
  { id: 1339, city_name: 'Apiacás', state_id: 11 },
  { id: 1340, city_name: 'Araguaiana', state_id: 11 },
  { id: 1341, city_name: 'Araguainha', state_id: 11 },
  { id: 1342, city_name: 'Araputanga', state_id: 11 },
  { id: 1343, city_name: 'Arenápolis', state_id: 11 },
  { id: 1344, city_name: 'Aripuanã', state_id: 11 },
  { id: 1345, city_name: 'Barão de Melgaço', state_id: 11 },
  { id: 1346, city_name: 'Barra do Bugres', state_id: 11 },
  { id: 1347, city_name: 'Barra do Garças', state_id: 11 },
  { id: 1348, city_name: 'Bom Jesus do Araguaia', state_id: 11 },
  { id: 1349, city_name: 'Brasnorte', state_id: 11 },
  { id: 1350, city_name: 'Cáceres', state_id: 11 },
  { id: 1351, city_name: 'Campinápolis', state_id: 11 },
  { id: 1352, city_name: 'Campo Novo do Parecis', state_id: 11 },
  { id: 1353, city_name: 'Campo Verde', state_id: 11 },
  { id: 1354, city_name: 'Campos de Júlio', state_id: 11 },
  { id: 1355, city_name: 'Canabrava do Norte', state_id: 11 },
  { id: 1356, city_name: 'Carlinda', state_id: 11 },
  { id: 1357, city_name: 'Castanheira', state_id: 11 },
  { id: 1358, city_name: 'Chapada dos Guimarães', state_id: 11 },
  { id: 1359, city_name: 'Cláudia', state_id: 11 },
  { id: 1360, city_name: 'Cocalinho', state_id: 11 },
  { id: 1361, city_name: 'Colíder', state_id: 11 },
  { id: 1362, city_name: 'Colniza', state_id: 11 },
  { id: 1363, city_name: 'Comodoro', state_id: 11 },
  { id: 1364, city_name: 'Confresa', state_id: 11 },
  { id: 1365, city_name: "Conquista D'Oeste", state_id: 11 },
  { id: 1366, city_name: 'Cotriguaçu', state_id: 11 },
  { id: 1367, city_name: 'Cuiabá', state_id: 11 },
  { id: 1368, city_name: 'Curvelândia', state_id: 11 },
  { id: 1369, city_name: 'Denise', state_id: 11 },
  { id: 1370, city_name: 'Diamantino', state_id: 11 },
  { id: 1371, city_name: 'Dom Aquino', state_id: 11 },
  { id: 1372, city_name: 'Feliz Natal', state_id: 11 },
  { id: 1373, city_name: "Figueirópolis D'Oeste", state_id: 11 },
  { id: 1374, city_name: 'Gaúcha do Norte', state_id: 11 },
  { id: 1375, city_name: 'General Carneiro', state_id: 11 },
  { id: 1376, city_name: "Glória D'Oeste", state_id: 11 },
  { id: 1377, city_name: 'Guarantã do Norte', state_id: 11 },
  { id: 1378, city_name: 'Guiratinga', state_id: 11 },
  { id: 1379, city_name: 'Indiavaí', state_id: 11 },
  { id: 1380, city_name: 'Ipiranga do Norte', state_id: 11 },
  { id: 1381, city_name: 'Itanhangá', state_id: 11 },
  { id: 1382, city_name: 'Itaúba', state_id: 11 },
  { id: 1383, city_name: 'Itiquira', state_id: 11 },
  { id: 1384, city_name: 'Jaciara', state_id: 11 },
  { id: 1385, city_name: 'Jangada', state_id: 11 },
  { id: 1386, city_name: 'Jauru', state_id: 11 },
  { id: 1387, city_name: 'Juara', state_id: 11 },
  { id: 1388, city_name: 'Juína', state_id: 11 },
  { id: 1389, city_name: 'Juruena', state_id: 11 },
  { id: 1390, city_name: 'Juscimeira', state_id: 11 },
  { id: 1391, city_name: "Lambari D'Oeste", state_id: 11 },
  { id: 1392, city_name: 'Lucas do Rio Verde', state_id: 11 },
  { id: 1393, city_name: 'Luciara', state_id: 11 },
  { id: 1394, city_name: 'Marcelândia', state_id: 11 },
  { id: 1395, city_name: 'Matupá', state_id: 11 },
  { id: 1396, city_name: "Mirassol d'Oeste", state_id: 11 },
  { id: 1397, city_name: 'Nobres', state_id: 11 },
  { id: 1398, city_name: 'Nortelândia', state_id: 11 },
  { id: 1399, city_name: 'Nossa Senhora do Livramento', state_id: 11 },
  { id: 1400, city_name: 'Nova Bandeirantes', state_id: 11 },
  { id: 1401, city_name: 'Nova Brasilândia', state_id: 11 },
  { id: 1402, city_name: 'Nova Canaã do Norte', state_id: 11 },
  { id: 1403, city_name: 'Nova Guarita', state_id: 11 },
  { id: 1404, city_name: 'Nova Lacerda', state_id: 11 },
  { id: 1405, city_name: 'Nova Marilândia', state_id: 11 },
  { id: 1406, city_name: 'Nova Maringá', state_id: 11 },
  { id: 1407, city_name: 'Nova Monte Verde', state_id: 11 },
  { id: 1408, city_name: 'Nova Mutum', state_id: 11 },
  { id: 1409, city_name: 'Nova Nazaré', state_id: 11 },
  { id: 1410, city_name: 'Nova Olímpia', state_id: 11 },
  { id: 1411, city_name: 'Nova Santa Helena', state_id: 11 },
  { id: 1412, city_name: 'Nova Ubiratã', state_id: 11 },
  { id: 1413, city_name: 'Nova Xavantina', state_id: 11 },
  { id: 1414, city_name: 'Novo Horizonte do Norte', state_id: 11 },
  { id: 1415, city_name: 'Novo Mundo', state_id: 11 },
  { id: 1416, city_name: 'Novo Santo Antônio', state_id: 11 },
  { id: 1417, city_name: 'Novo São Joaquim', state_id: 11 },
  { id: 1418, city_name: 'Paranaíta', state_id: 11 },
  { id: 1419, city_name: 'Paranatinga', state_id: 11 },
  { id: 1420, city_name: 'Pedra Preta', state_id: 11 },
  { id: 1421, city_name: 'Peixoto de Azevedo', state_id: 11 },
  { id: 1422, city_name: 'Planalto da Serra', state_id: 11 },
  { id: 1423, city_name: 'Poconé', state_id: 11 },
  { id: 1424, city_name: 'Pontal do Araguaia', state_id: 11 },
  { id: 1425, city_name: 'Ponte Branca', state_id: 11 },
  { id: 1426, city_name: 'Pontes e Lacerda', state_id: 11 },
  { id: 1427, city_name: 'Porto Alegre do Norte', state_id: 11 },
  { id: 1428, city_name: 'Porto dos Gaúchos', state_id: 11 },
  { id: 1429, city_name: 'Porto Esperidião', state_id: 11 },
  { id: 1430, city_name: 'Porto Estrela', state_id: 11 },
  { id: 1431, city_name: 'Poxoréu', state_id: 11 },
  { id: 1432, city_name: 'Primavera do Leste', state_id: 11 },
  { id: 1433, city_name: 'Querência', state_id: 11 },
  { id: 1434, city_name: 'Reserva do Cabaçal', state_id: 11 },
  { id: 1435, city_name: 'Ribeirão Cascalheira', state_id: 11 },
  { id: 1436, city_name: 'Ribeirãozinho', state_id: 11 },
  { id: 1437, city_name: 'Rondolândia', state_id: 11 },
  { id: 1438, city_name: 'Rondonópolis', state_id: 11 },
  { id: 1439, city_name: 'Rosário Oeste', state_id: 11 },
  { id: 1440, city_name: 'Salto do Céu', state_id: 11 },
  { id: 1441, city_name: 'Santa Carmem', state_id: 11 },
  { id: 1442, city_name: 'Santa Cruz do Xingu', state_id: 11 },
  { id: 1443, city_name: 'Santa Rita do Trivelato', state_id: 11 },
  { id: 1444, city_name: 'Santo Afonso', state_id: 11 },
  { id: 1445, city_name: 'Santo Antônio do Leste', state_id: 11 },
  { id: 1446, city_name: 'Santo Antônio do Leverger', state_id: 11 },
  { id: 1447, city_name: 'São Félix do Araguaia', state_id: 11 },
  { id: 1448, city_name: 'São José do Povo', state_id: 11 },
  { id: 1449, city_name: 'São José do Rio Claro', state_id: 11 },
  { id: 1450, city_name: 'São José do Xingu', state_id: 11 },
  { id: 1451, city_name: 'São José dos Quatro Marcos', state_id: 11 },
  { id: 1452, city_name: 'São Pedro da Cipa', state_id: 11 },
  { id: 1453, city_name: 'Sapezal', state_id: 11 },
  { id: 1454, city_name: 'Serra Nova Dourada', state_id: 11 },
  { id: 1455, city_name: 'Sinop', state_id: 11 },
  { id: 1456, city_name: 'Sorriso', state_id: 11 },
  { id: 1457, city_name: 'Tabaporã', state_id: 11 },
  { id: 1458, city_name: 'Tangará da Serra', state_id: 11 },
  { id: 1459, city_name: 'Tapurah', state_id: 11 },
  { id: 1460, city_name: 'Terra Nova do Norte', state_id: 11 },
  { id: 1461, city_name: 'Tesouro', state_id: 11 },
  { id: 1462, city_name: 'Torixoréu', state_id: 11 },
  { id: 1463, city_name: 'União do Sul', state_id: 11 },
  { id: 1464, city_name: 'Vale de São Domingos', state_id: 11 },
  { id: 1465, city_name: 'Várzea Grande', state_id: 11 },
  { id: 1466, city_name: 'Vera', state_id: 11 },
  { id: 1467, city_name: 'Vila Bela da Santíssima Trindade', state_id: 11 },
  { id: 1468, city_name: 'Vila Rica', state_id: 11 },
  { id: 1469, city_name: 'Água Clara', state_id: 12 },
  { id: 1470, city_name: 'Alcinópolis', state_id: 12 },
  { id: 1471, city_name: 'Amambai', state_id: 12 },
  { id: 1472, city_name: 'Anastácio', state_id: 12 },
  { id: 1473, city_name: 'Anaurilândia', state_id: 12 },
  { id: 1474, city_name: 'Angélica', state_id: 12 },
  { id: 1475, city_name: 'Antônio João', state_id: 12 },
  { id: 1476, city_name: 'Aparecida do Taboado', state_id: 12 },
  { id: 1477, city_name: 'Aquidauana', state_id: 12 },
  { id: 1478, city_name: 'Aral Moreira', state_id: 12 },
  { id: 1479, city_name: 'Bandeirantes', state_id: 12 },
  { id: 1480, city_name: 'Bataguassu', state_id: 12 },
  { id: 1481, city_name: 'Batayporã', state_id: 12 },
  { id: 1482, city_name: 'Bela Vista', state_id: 12 },
  { id: 1483, city_name: 'Bodoquena', state_id: 12 },
  { id: 1484, city_name: 'Brasilândia', state_id: 12 },
  { id: 1485, city_name: 'Caarapó', state_id: 12 },
  { id: 1486, city_name: 'Camapuã', state_id: 12 },
  { id: 1487, city_name: 'Caracol', state_id: 12 },
  { id: 1488, city_name: 'Cassilândia', state_id: 12 },
  { id: 1489, city_name: 'Chapadão do Sul', state_id: 12 },
  { id: 1490, city_name: 'Corguinho', state_id: 12 },
  { id: 1491, city_name: 'Coronel Sapucaia', state_id: 12 },
  { id: 1492, city_name: 'Corumbá', state_id: 12 },
  { id: 1493, city_name: 'Costa Rica', state_id: 12 },
  { id: 1494, city_name: 'Coxim', state_id: 12 },
  { id: 1495, city_name: 'Deodápolis', state_id: 12 },
  { id: 1496, city_name: 'Dois Irmãos do Buriti', state_id: 12 },
  { id: 1497, city_name: 'Douradina', state_id: 12 },
  { id: 1498, city_name: 'Dourados', state_id: 12 },
  { id: 1499, city_name: 'Eldorado', state_id: 12 },
  { id: 1500, city_name: 'Fátima do Sul', state_id: 12 },
  { id: 1501, city_name: 'Figueirão', state_id: 12 },
  { id: 1502, city_name: 'Glória de Dourados', state_id: 12 },
  { id: 1503, city_name: 'Guia Lopes da Laguna', state_id: 12 },
  { id: 1504, city_name: 'Iguatemi', state_id: 12 },
  { id: 1505, city_name: 'Inocência', state_id: 12 },
  { id: 1506, city_name: 'Itaporã', state_id: 12 },
  { id: 1507, city_name: 'Itaquiraí', state_id: 12 },
  { id: 1508, city_name: 'Ivinhema', state_id: 12 },
  { id: 1509, city_name: 'Japorã', state_id: 12 },
  { id: 1510, city_name: 'Jaraguari', state_id: 12 },
  { id: 1511, city_name: 'Jateí', state_id: 12 },
  { id: 1512, city_name: 'Juti', state_id: 12 },
  { id: 1513, city_name: 'Ladário', state_id: 12 },
  { id: 1514, city_name: 'Laguna Carapã', state_id: 12 },
  { id: 1515, city_name: 'Maracaju', state_id: 12 },
  { id: 1516, city_name: 'Miranda', state_id: 12 },
  { id: 1517, city_name: 'Naviraí', state_id: 12 },
  { id: 1518, city_name: 'Nioaque', state_id: 12 },
  { id: 1519, city_name: 'Nova Alvorada do Sul', state_id: 12 },
  { id: 1520, city_name: 'Nova Andradina', state_id: 12 },
  { id: 1521, city_name: 'Novo Horizonte do Sul', state_id: 12 },
  { id: 1522, city_name: 'Paraíso das Águas', state_id: 12 },
  { id: 1523, city_name: 'Paranaíba', state_id: 12 },
  { id: 1524, city_name: 'Paranhos', state_id: 12 },
  { id: 1525, city_name: 'Pedro Gomes', state_id: 12 },
  { id: 1526, city_name: 'Ponta Porã', state_id: 12 },
  { id: 1527, city_name: 'Porto Murtinho', state_id: 12 },
  { id: 1528, city_name: 'Ribas do Rio Pardo', state_id: 12 },
  { id: 1529, city_name: 'Rio Brilhante', state_id: 12 },
  { id: 1530, city_name: 'Rio Negro', state_id: 12 },
  { id: 1531, city_name: 'Rio Verde de Mato Grosso', state_id: 12 },
  { id: 1532, city_name: 'Rochedo', state_id: 12 },
  { id: 1533, city_name: 'Santa Rita do Pardo', state_id: 12 },
  { id: 1534, city_name: 'São Gabriel do Oeste', state_id: 12 },
  { id: 1535, city_name: 'Selvíria', state_id: 12 },
  { id: 1536, city_name: 'Sete Quedas', state_id: 12 },
  { id: 1537, city_name: 'Sidrolândia', state_id: 12 },
  { id: 1538, city_name: 'Sonora', state_id: 12 },
  { id: 1539, city_name: 'Tacuru', state_id: 12 },
  { id: 1540, city_name: 'Taquarussu', state_id: 12 },
  { id: 1541, city_name: 'Terenos', state_id: 12 },
  { id: 1542, city_name: 'Três Lagoas', state_id: 12 },
  { id: 1543, city_name: 'Vicentina', state_id: 12 },
  { id: 1544, city_name: 'Abadia dos Dourados', state_id: 13 },
  { id: 1545, city_name: 'Abaeté', state_id: 13 },
  { id: 1546, city_name: 'Abre Campo', state_id: 13 },
  { id: 1547, city_name: 'Acaiaca', state_id: 13 },
  { id: 1548, city_name: 'Açucena', state_id: 13 },
  { id: 1549, city_name: 'Água Comprida', state_id: 13 },
  { id: 1550, city_name: 'Aguanil', state_id: 13 },
  { id: 1551, city_name: 'Águas Formosas', state_id: 13 },
  { id: 1552, city_name: 'Águas Vermelhas', state_id: 13 },
  { id: 1553, city_name: 'Aimorés', state_id: 13 },
  { id: 1554, city_name: 'Aiuruoca', state_id: 13 },
  { id: 1555, city_name: 'Alagoa', state_id: 13 },
  { id: 1556, city_name: 'Albertina', state_id: 13 },
  { id: 1557, city_name: 'Além Paraíba', state_id: 13 },
  { id: 1558, city_name: 'Alfenas', state_id: 13 },
  { id: 1559, city_name: 'Alfredo Vasconcelos', state_id: 13 },
  { id: 1560, city_name: 'Almenara', state_id: 13 },
  { id: 1561, city_name: 'Alpercata', state_id: 13 },
  { id: 1562, city_name: 'Alpinópolis', state_id: 13 },
  { id: 1563, city_name: 'Alterosa', state_id: 13 },
  { id: 1564, city_name: 'Alto Caparaó', state_id: 13 },
  { id: 1565, city_name: 'Alto Jequitibá', state_id: 13 },
  { id: 1566, city_name: 'Alto Rio Doce', state_id: 13 },
  { id: 1567, city_name: 'Alvarenga', state_id: 13 },
  { id: 1568, city_name: 'Alvinópolis', state_id: 13 },
  { id: 1569, city_name: 'Alvorada de Minas', state_id: 13 },
  { id: 1570, city_name: 'Amparo do Serra', state_id: 13 },
  { id: 1571, city_name: 'Andradas', state_id: 13 },
  { id: 1572, city_name: 'Andrelândia', state_id: 13 },
  { id: 1573, city_name: 'Angelândia', state_id: 13 },
  { id: 1574, city_name: 'Antônio Carlos', state_id: 13 },
  { id: 1575, city_name: 'Antônio Dias', state_id: 13 },
  { id: 1576, city_name: 'Antônio Prado de Minas', state_id: 13 },
  { id: 1577, city_name: 'Araçaí', state_id: 13 },
  { id: 1578, city_name: 'Aracitaba', state_id: 13 },
  { id: 1579, city_name: 'Araçuaí', state_id: 13 },
  { id: 1580, city_name: 'Araguari', state_id: 13 },
  { id: 1581, city_name: 'Arantina', state_id: 13 },
  { id: 1582, city_name: 'Araponga', state_id: 13 },
  { id: 1583, city_name: 'Araporã', state_id: 13 },
  { id: 1584, city_name: 'Arapuá', state_id: 13 },
  { id: 1585, city_name: 'Araújos', state_id: 13 },
  { id: 1586, city_name: 'Araxá', state_id: 13 },
  { id: 1587, city_name: 'Arceburgo', state_id: 13 },
  { id: 1588, city_name: 'Arcos', state_id: 13 },
  { id: 1589, city_name: 'Areado', state_id: 13 },
  { id: 1590, city_name: 'Argirita', state_id: 13 },
  { id: 1591, city_name: 'Aricanduva', state_id: 13 },
  { id: 1592, city_name: 'Arinos', state_id: 13 },
  { id: 1593, city_name: 'Astolfo Dutra', state_id: 13 },
  { id: 1594, city_name: 'Ataléia', state_id: 13 },
  { id: 1595, city_name: 'Augusto de Lima', state_id: 13 },
  { id: 1596, city_name: 'Baependi', state_id: 13 },
  { id: 1597, city_name: 'Baldim', state_id: 13 },
  { id: 1598, city_name: 'Bambuí', state_id: 13 },
  { id: 1599, city_name: 'Bandeira', state_id: 13 },
  { id: 1600, city_name: 'Bandeira do Sul', state_id: 13 },
  { id: 1601, city_name: 'Barão de Cocais', state_id: 13 },
  { id: 1602, city_name: 'Barão de Monte Alto', state_id: 13 },
  { id: 1603, city_name: 'Barbacena', state_id: 13 },
  { id: 1604, city_name: 'Barra Longa', state_id: 13 },
  { id: 1605, city_name: 'Barroso', state_id: 13 },
  { id: 1606, city_name: 'Bela Vista de Minas', state_id: 13 },
  { id: 1607, city_name: 'Belmiro Braga', state_id: 13 },
  { id: 1608, city_name: 'Belo Horizonte', state_id: 13 },
  { id: 1609, city_name: 'Belo Oriente', state_id: 13 },
  { id: 1610, city_name: 'Belo Vale', state_id: 13 },
  { id: 1611, city_name: 'Berilo', state_id: 13 },
  { id: 1612, city_name: 'Berizal', state_id: 13 },
  { id: 1613, city_name: 'Bertópolis', state_id: 13 },
  { id: 1614, city_name: 'Betim', state_id: 13 },
  { id: 1615, city_name: 'Bias Fortes', state_id: 13 },
  { id: 1616, city_name: 'Bicas', state_id: 13 },
  { id: 1617, city_name: 'Biquinhas', state_id: 13 },
  { id: 1618, city_name: 'Bocaina de Minas', state_id: 13 },
  { id: 1619, city_name: 'Bocaiúva', state_id: 13 },
  { id: 1620, city_name: 'Bom Despacho', state_id: 13 },
  { id: 1621, city_name: 'Bom Jardim de Minas', state_id: 13 },
  { id: 1622, city_name: 'Bom Jesus da Penha', state_id: 13 },
  { id: 1623, city_name: 'Bom Jesus do Amparo', state_id: 13 },
  { id: 1624, city_name: 'Bom Jesus do Galho', state_id: 13 },
  { id: 1625, city_name: 'Bom Repouso', state_id: 13 },
  { id: 1626, city_name: 'Bom Sucesso', state_id: 13 },
  { id: 1627, city_name: 'Bonfim', state_id: 13 },
  { id: 1628, city_name: 'Bonfinópolis de Minas', state_id: 13 },
  { id: 1629, city_name: 'Bonito de Minas', state_id: 13 },
  { id: 1630, city_name: 'Borda da Mata', state_id: 13 },
  { id: 1631, city_name: 'Botelhos', state_id: 13 },
  { id: 1632, city_name: 'Botumirim', state_id: 13 },
  { id: 1633, city_name: 'Brás Pires', state_id: 13 },
  { id: 1634, city_name: 'Brasilândia de Minas', state_id: 13 },
  { id: 1635, city_name: 'Brasília de Minas', state_id: 13 },
  { id: 1636, city_name: 'Braúnas', state_id: 13 },
  { id: 1637, city_name: 'Brazópolis', state_id: 13 },
  { id: 1638, city_name: 'Brumadinho', state_id: 13 },
  { id: 1639, city_name: 'Bueno Brandão', state_id: 13 },
  { id: 1640, city_name: 'Buenópolis', state_id: 13 },
  { id: 1641, city_name: 'Bugre', state_id: 13 },
  { id: 1642, city_name: 'Buritis', state_id: 13 },
  { id: 1643, city_name: 'Buritizeiro', state_id: 13 },
  { id: 1644, city_name: 'Cabeceira Grande', state_id: 13 },
  { id: 1645, city_name: 'Cabo Verde', state_id: 13 },
  { id: 1646, city_name: 'Cachoeira da Prata', state_id: 13 },
  { id: 1647, city_name: 'Cachoeira de Minas', state_id: 13 },
  { id: 1648, city_name: 'Cachoeira de Pajeú', state_id: 13 },
  { id: 1649, city_name: 'Caetanópolis', state_id: 13 },
  { id: 1650, city_name: 'Caeté', state_id: 13 },
  { id: 1651, city_name: 'Caiana', state_id: 13 },
  { id: 1652, city_name: 'Cajuri', state_id: 13 },
  { id: 1653, city_name: 'Caldas', state_id: 13 },
  { id: 1654, city_name: 'Camacho', state_id: 13 },
  { id: 1655, city_name: 'Camanducaia', state_id: 13 },
  { id: 1656, city_name: 'Cambuí', state_id: 13 },
  { id: 1657, city_name: 'Cambuquira', state_id: 13 },
  { id: 1658, city_name: 'Campanário', state_id: 13 },
  { id: 1659, city_name: 'Campanha', state_id: 13 },
  { id: 1660, city_name: 'Campina Verde', state_id: 13 },
  { id: 1661, city_name: 'Campo Azul', state_id: 13 },
  { id: 1662, city_name: 'Campo Belo', state_id: 13 },
  { id: 1663, city_name: 'Campo do Meio', state_id: 13 },
  { id: 1664, city_name: 'Campo Florido', state_id: 13 },
  { id: 1665, city_name: 'Campos Altos', state_id: 13 },
  { id: 1666, city_name: 'Campos Gerais', state_id: 13 },
  { id: 1667, city_name: 'Cana Verde', state_id: 13 },
  { id: 1668, city_name: 'Canaã', state_id: 13 },
  { id: 1669, city_name: 'Cantagalo', state_id: 13 },
  { id: 1670, city_name: 'Caparaó', state_id: 13 },
  { id: 1671, city_name: 'Capela Nova', state_id: 13 },
  { id: 1672, city_name: 'Capelinha', state_id: 13 },
  { id: 1673, city_name: 'Capetinga', state_id: 13 },
  { id: 1674, city_name: 'Capim Branco', state_id: 13 },
  { id: 1675, city_name: 'Capinópolis', state_id: 13 },
  { id: 1676, city_name: 'Capitão Andrade', state_id: 13 },
  { id: 1677, city_name: 'Capitão Enéas', state_id: 13 },
  { id: 1678, city_name: 'Capitólio', state_id: 13 },
  { id: 1679, city_name: 'Caputira', state_id: 13 },
  { id: 1680, city_name: 'Caraí', state_id: 13 },
  { id: 1681, city_name: 'Caranaíba', state_id: 13 },
  { id: 1682, city_name: 'Carandaí', state_id: 13 },
  { id: 1683, city_name: 'Carangola', state_id: 13 },
  { id: 1684, city_name: 'Caratinga', state_id: 13 },
  { id: 1685, city_name: 'Carbonita', state_id: 13 },
  { id: 1686, city_name: 'Careaçu', state_id: 13 },
  { id: 1687, city_name: 'Carlos Chagas', state_id: 13 },
  { id: 1688, city_name: 'Carmésia', state_id: 13 },
  { id: 1689, city_name: 'Carmo da Cachoeira', state_id: 13 },
  { id: 1690, city_name: 'Carmo da Mata', state_id: 13 },
  { id: 1691, city_name: 'Carmo de Minas', state_id: 13 },
  { id: 1692, city_name: 'Carmo do Cajuru', state_id: 13 },
  { id: 1693, city_name: 'Carmo do Paranaíba', state_id: 13 },
  { id: 1694, city_name: 'Carmo do Rio Claro', state_id: 13 },
  { id: 1695, city_name: 'Carmópolis de Minas', state_id: 13 },
  { id: 1696, city_name: 'Carneirinho', state_id: 13 },
  { id: 1697, city_name: 'Carrancas', state_id: 13 },
  { id: 1698, city_name: 'Carvalhópolis', state_id: 13 },
  { id: 1699, city_name: 'Carvalhos', state_id: 13 },
  { id: 1700, city_name: 'Casa Grande', state_id: 13 },
  { id: 1701, city_name: 'Cascalho Rico', state_id: 13 },
  { id: 1702, city_name: 'Cássia', state_id: 13 },
  { id: 1703, city_name: 'Cataguases', state_id: 13 },
  { id: 1704, city_name: 'Catas Altas', state_id: 13 },
  { id: 1705, city_name: 'Catas Altas da Noruega', state_id: 13 },
  { id: 1706, city_name: 'Catuji', state_id: 13 },
  { id: 1707, city_name: 'Catuti', state_id: 13 },
  { id: 1708, city_name: 'Caxambu', state_id: 13 },
  { id: 1709, city_name: 'Cedro do Abaeté', state_id: 13 },
  { id: 1710, city_name: 'Central de Minas', state_id: 13 },
  { id: 1711, city_name: 'Centralina', state_id: 13 },
  { id: 1712, city_name: 'Chácara', state_id: 13 },
  { id: 1713, city_name: 'Chalé', state_id: 13 },
  { id: 1714, city_name: 'Chapada do Norte', state_id: 13 },
  { id: 1715, city_name: 'Chapada Gaúcha', state_id: 13 },
  { id: 1716, city_name: 'Chiador', state_id: 13 },
  { id: 1717, city_name: 'Cipotânea', state_id: 13 },
  { id: 1718, city_name: 'Claraval', state_id: 13 },
  { id: 1719, city_name: 'Claro dos Poções', state_id: 13 },
  { id: 1720, city_name: 'Cláudio', state_id: 13 },
  { id: 1721, city_name: 'Coimbra', state_id: 13 },
  { id: 1722, city_name: 'Coluna', state_id: 13 },
  { id: 1723, city_name: 'Comendador Gomes', state_id: 13 },
  { id: 1724, city_name: 'Comercinho', state_id: 13 },
  { id: 1725, city_name: 'Conceição da Aparecida', state_id: 13 },
  { id: 1726, city_name: 'Conceição da Barra de Minas', state_id: 13 },
  { id: 1727, city_name: 'Conceição das Alagoas', state_id: 13 },
  { id: 1728, city_name: 'Conceição das Pedras', state_id: 13 },
  { id: 1729, city_name: 'Conceição de Ipanema', state_id: 13 },
  { id: 1730, city_name: 'Conceição do Mato Dentro', state_id: 13 },
  { id: 1731, city_name: 'Conceição do Pará', state_id: 13 },
  { id: 1732, city_name: 'Conceição do Rio Verde', state_id: 13 },
  { id: 1733, city_name: 'Conceição dos Ouros', state_id: 13 },
  { id: 1734, city_name: 'Cônego Marinho', state_id: 13 },
  { id: 1735, city_name: 'Confins', state_id: 13 },
  { id: 1736, city_name: 'Congonhal', state_id: 13 },
  { id: 1737, city_name: 'Congonhas', state_id: 13 },
  { id: 1738, city_name: 'Congonhas do Norte', state_id: 13 },
  { id: 1739, city_name: 'Conquista', state_id: 13 },
  { id: 1740, city_name: 'Conselheiro Lafaiete', state_id: 13 },
  { id: 1741, city_name: 'Conselheiro Pena', state_id: 13 },
  { id: 1742, city_name: 'Consolação', state_id: 13 },
  { id: 1743, city_name: 'Contagem', state_id: 13 },
  { id: 1744, city_name: 'Coqueiral', state_id: 13 },
  { id: 1745, city_name: 'Coração de Jesus', state_id: 13 },
  { id: 1746, city_name: 'Cordisburgo', state_id: 13 },
  { id: 1747, city_name: 'Cordislândia', state_id: 13 },
  { id: 1748, city_name: 'Corinto', state_id: 13 },
  { id: 1749, city_name: 'Coroaci', state_id: 13 },
  { id: 1750, city_name: 'Coromandel', state_id: 13 },
  { id: 1751, city_name: 'Coronel Fabriciano', state_id: 13 },
  { id: 1752, city_name: 'Coronel Murta', state_id: 13 },
  { id: 1753, city_name: 'Coronel Pacheco', state_id: 13 },
  { id: 1754, city_name: 'Coronel Xavier Chaves', state_id: 13 },
  { id: 1755, city_name: 'Córrego Danta', state_id: 13 },
  { id: 1756, city_name: 'Córrego do Bom Jesus', state_id: 13 },
  { id: 1757, city_name: 'Córrego Fundo', state_id: 13 },
  { id: 1758, city_name: 'Córrego Novo', state_id: 13 },
  { id: 1759, city_name: 'Couto de Magalhães de Minas', state_id: 13 },
  { id: 1760, city_name: 'Crisólita', state_id: 13 },
  { id: 1761, city_name: 'Cristais', state_id: 13 },
  { id: 1762, city_name: 'Cristália', state_id: 13 },
  { id: 1763, city_name: 'Cristiano Otoni', state_id: 13 },
  { id: 1764, city_name: 'Cristina', state_id: 13 },
  { id: 1765, city_name: 'Crucilândia', state_id: 13 },
  { id: 1766, city_name: 'Cruzeiro da Fortaleza', state_id: 13 },
  { id: 1767, city_name: 'Cruzília', state_id: 13 },
  { id: 1768, city_name: 'Cuparaque', state_id: 13 },
  { id: 1769, city_name: 'Curral de Dentro', state_id: 13 },
  { id: 1770, city_name: 'Curvelo', state_id: 13 },
  { id: 1771, city_name: 'Datas', state_id: 13 },
  { id: 1772, city_name: 'Delfim Moreira', state_id: 13 },
  { id: 1773, city_name: 'Delfinópolis', state_id: 13 },
  { id: 1774, city_name: 'Delta', state_id: 13 },
  { id: 1775, city_name: 'Descoberto', state_id: 13 },
  { id: 1776, city_name: 'Desterro de Entre Rios', state_id: 13 },
  { id: 1777, city_name: 'Desterro do Melo', state_id: 13 },
  { id: 1778, city_name: 'Diamantina', state_id: 13 },
  { id: 1779, city_name: 'Diogo de Vasconcelos', state_id: 13 },
  { id: 1780, city_name: 'Dionísio', state_id: 13 },
  { id: 1781, city_name: 'Divinésia', state_id: 13 },
  { id: 1782, city_name: 'Divino', state_id: 13 },
  { id: 1783, city_name: 'Divino das Laranjeiras', state_id: 13 },
  { id: 1784, city_name: 'Divinolândia de Minas', state_id: 13 },
  { id: 1785, city_name: 'Divinópolis', state_id: 13 },
  { id: 1786, city_name: 'Divisa Alegre', state_id: 13 },
  { id: 1787, city_name: 'Divisa Nova', state_id: 13 },
  { id: 1788, city_name: 'Divisópolis', state_id: 13 },
  { id: 1789, city_name: 'Dom Bosco', state_id: 13 },
  { id: 1790, city_name: 'Dom Cavati', state_id: 13 },
  { id: 1791, city_name: 'Dom Joaquim', state_id: 13 },
  { id: 1792, city_name: 'Dom Silvério', state_id: 13 },
  { id: 1793, city_name: 'Dom Viçoso', state_id: 13 },
  { id: 1794, city_name: 'Dona Euzébia', state_id: 13 },
  { id: 1795, city_name: 'Dores de Campos', state_id: 13 },
  { id: 1796, city_name: 'Dores de Guanhães', state_id: 13 },
  { id: 1797, city_name: 'Dores do Indaiá', state_id: 13 },
  { id: 1798, city_name: 'Dores do Turvo', state_id: 13 },
  { id: 1799, city_name: 'Doresópolis', state_id: 13 },
  { id: 1800, city_name: 'Douradoquara', state_id: 13 },
  { id: 1801, city_name: 'Durandé', state_id: 13 },
  { id: 1802, city_name: 'Elói Mendes', state_id: 13 },
  { id: 1803, city_name: 'Engenheiro Caldas', state_id: 13 },
  { id: 1804, city_name: 'Engenheiro Navarro', state_id: 13 },
  { id: 1805, city_name: 'Entre Folhas', state_id: 13 },
  { id: 1806, city_name: 'Entre Rios de Minas', state_id: 13 },
  { id: 1807, city_name: 'Ervália', state_id: 13 },
  { id: 1808, city_name: 'Esmeraldas', state_id: 13 },
  { id: 1809, city_name: 'Espera Feliz', state_id: 13 },
  { id: 1810, city_name: 'Espinosa', state_id: 13 },
  { id: 1811, city_name: 'Espírito Santo do Dourado', state_id: 13 },
  { id: 1812, city_name: 'Estiva', state_id: 13 },
  { id: 1813, city_name: 'Estrela Dalva', state_id: 13 },
  { id: 1814, city_name: 'Estrela do Indaiá', state_id: 13 },
  { id: 1815, city_name: 'Estrela do Sul', state_id: 13 },
  { id: 1816, city_name: 'Eugenópolis', state_id: 13 },
  { id: 1817, city_name: 'Ewbank da Câmara', state_id: 13 },
  { id: 1818, city_name: 'Extrema', state_id: 13 },
  { id: 1819, city_name: 'Fama', state_id: 13 },
  { id: 1820, city_name: 'Faria Lemos', state_id: 13 },
  { id: 1821, city_name: 'Felício dos Santos', state_id: 13 },
  { id: 1822, city_name: 'Felisburgo', state_id: 13 },
  { id: 1823, city_name: 'Felixlândia', state_id: 13 },
  { id: 1824, city_name: 'Fernandes Tourinho', state_id: 13 },
  { id: 1825, city_name: 'Ferros', state_id: 13 },
  { id: 1826, city_name: 'Fervedouro', state_id: 13 },
  { id: 1827, city_name: 'Florestal', state_id: 13 },
  { id: 1828, city_name: 'Formiga', state_id: 13 },
  { id: 1829, city_name: 'Fortaleza de Minas', state_id: 13 },
  { id: 1830, city_name: 'Fortuna de Minas', state_id: 13 },
  { id: 1831, city_name: 'Francisco Badaró', state_id: 13 },
  { id: 1832, city_name: 'Francisco Dumont', state_id: 13 },
  { id: 1833, city_name: 'Francisco Sá', state_id: 13 },
  { id: 1834, city_name: 'Franciscópolis', state_id: 13 },
  { id: 1835, city_name: 'Frei Gaspar', state_id: 13 },
  { id: 1836, city_name: 'Frei Inocêncio', state_id: 13 },
  { id: 1837, city_name: 'Frei Lagonegro', state_id: 13 },
  { id: 1838, city_name: 'Fronteira', state_id: 13 },
  { id: 1839, city_name: 'Fronteira dos Vales', state_id: 13 },
  { id: 1840, city_name: 'Fruta de Leite', state_id: 13 },
  { id: 1841, city_name: 'Frutal', state_id: 13 },
  { id: 1842, city_name: 'Funilândia', state_id: 13 },
  { id: 1843, city_name: 'Galiléia', state_id: 13 },
  { id: 1844, city_name: 'Gameleiras', state_id: 13 },
  { id: 1845, city_name: 'Glaucilândia', state_id: 13 },
  { id: 1846, city_name: 'Goiabeira', state_id: 13 },
  { id: 1847, city_name: 'Goianá', state_id: 13 },
  { id: 1848, city_name: 'Gonçalves', state_id: 13 },
  { id: 1849, city_name: 'Gonzaga', state_id: 13 },
  { id: 1850, city_name: 'Gouveia', state_id: 13 },
  { id: 1851, city_name: 'Governador Valadares', state_id: 13 },
  { id: 1852, city_name: 'Grão Mogol', state_id: 13 },
  { id: 1853, city_name: 'Grupiara', state_id: 13 },
  { id: 1854, city_name: 'Guanhães', state_id: 13 },
  { id: 1855, city_name: 'Guapé', state_id: 13 },
  { id: 1856, city_name: 'Guaraciaba', state_id: 13 },
  { id: 1857, city_name: 'Guaraciama', state_id: 13 },
  { id: 1858, city_name: 'Guaranésia', state_id: 13 },
  { id: 1859, city_name: 'Guarani', state_id: 13 },
  { id: 1860, city_name: 'Guarará', state_id: 13 },
  { id: 1861, city_name: 'Guarda-Mor', state_id: 13 },
  { id: 1862, city_name: 'Guaxupé', state_id: 13 },
  { id: 1863, city_name: 'Guidoval', state_id: 13 },
  { id: 1864, city_name: 'Guimarânia', state_id: 13 },
  { id: 1865, city_name: 'Guiricema', state_id: 13 },
  { id: 1866, city_name: 'Gurinhatã', state_id: 13 },
  { id: 1867, city_name: 'Heliodora', state_id: 13 },
  { id: 1868, city_name: 'Iapu', state_id: 13 },
  { id: 1869, city_name: 'Ibertioga', state_id: 13 },
  { id: 1870, city_name: 'Ibiá', state_id: 13 },
  { id: 1871, city_name: 'Ibiaí', state_id: 13 },
  { id: 1872, city_name: 'Ibiracatu', state_id: 13 },
  { id: 1873, city_name: 'Ibiraci', state_id: 13 },
  { id: 1874, city_name: 'Ibirité', state_id: 13 },
  { id: 1875, city_name: 'Ibitiúra de Minas', state_id: 13 },
  { id: 1876, city_name: 'Ibituruna', state_id: 13 },
  { id: 1877, city_name: 'Icaraí de Minas', state_id: 13 },
  { id: 1878, city_name: 'Igarapé', state_id: 13 },
  { id: 1879, city_name: 'Igaratinga', state_id: 13 },
  { id: 1880, city_name: 'Iguatama', state_id: 13 },
  { id: 1881, city_name: 'Ijaci', state_id: 13 },
  { id: 1882, city_name: 'Ilicínea', state_id: 13 },
  { id: 1883, city_name: 'Imbé de Minas', state_id: 13 },
  { id: 1884, city_name: 'Inconfidentes', state_id: 13 },
  { id: 1885, city_name: 'Indaiabira', state_id: 13 },
  { id: 1886, city_name: 'Indianópolis', state_id: 13 },
  { id: 1887, city_name: 'Ingaí', state_id: 13 },
  { id: 1888, city_name: 'Inhapim', state_id: 13 },
  { id: 1889, city_name: 'Inhaúma', state_id: 13 },
  { id: 1890, city_name: 'Inimutaba', state_id: 13 },
  { id: 1891, city_name: 'Ipaba', state_id: 13 },
  { id: 1892, city_name: 'Ipanema', state_id: 13 },
  { id: 1893, city_name: 'Ipatinga', state_id: 13 },
  { id: 1894, city_name: 'Ipiaçu', state_id: 13 },
  { id: 1895, city_name: 'Ipuiúna', state_id: 13 },
  { id: 1896, city_name: 'Iraí de Minas', state_id: 13 },
  { id: 1897, city_name: 'Itabira', state_id: 13 },
  { id: 1898, city_name: 'Itabirinha', state_id: 13 },
  { id: 1899, city_name: 'Itabirito', state_id: 13 },
  { id: 1900, city_name: 'Itacambira', state_id: 13 },
  { id: 1901, city_name: 'Itacarambi', state_id: 13 },
  { id: 1902, city_name: 'Itaguara', state_id: 13 },
  { id: 1903, city_name: 'Itaipé', state_id: 13 },
  { id: 1904, city_name: 'Itajubá', state_id: 13 },
  { id: 1905, city_name: 'Itamarandiba', state_id: 13 },
  { id: 1906, city_name: 'Itamarati de Minas', state_id: 13 },
  { id: 1907, city_name: 'Itambacuri', state_id: 13 },
  { id: 1908, city_name: 'Itambé do Mato Dentro', state_id: 13 },
  { id: 1909, city_name: 'Itamogi', state_id: 13 },
  { id: 1910, city_name: 'Itamonte', state_id: 13 },
  { id: 1911, city_name: 'Itanhandu', state_id: 13 },
  { id: 1912, city_name: 'Itanhomi', state_id: 13 },
  { id: 1913, city_name: 'Itaobim', state_id: 13 },
  { id: 1914, city_name: 'Itapagipe', state_id: 13 },
  { id: 1915, city_name: 'Itapecerica', state_id: 13 },
  { id: 1916, city_name: 'Itapeva', state_id: 13 },
  { id: 1917, city_name: 'Itatiaiuçu', state_id: 13 },
  { id: 1918, city_name: 'Itaú de Minas', state_id: 13 },
  { id: 1919, city_name: 'Itaúna', state_id: 13 },
  { id: 1920, city_name: 'Itaverava', state_id: 13 },
  { id: 1921, city_name: 'Itinga', state_id: 13 },
  { id: 1922, city_name: 'Itueta', state_id: 13 },
  { id: 1923, city_name: 'Ituiutaba', state_id: 13 },
  { id: 1924, city_name: 'Itumirim', state_id: 13 },
  { id: 1925, city_name: 'Iturama', state_id: 13 },
  { id: 1926, city_name: 'Itutinga', state_id: 13 },
  { id: 1927, city_name: 'Jaboticatubas', state_id: 13 },
  { id: 1928, city_name: 'Jacinto', state_id: 13 },
  { id: 1929, city_name: 'Jacuí', state_id: 13 },
  { id: 1930, city_name: 'Jacutinga', state_id: 13 },
  { id: 1931, city_name: 'Jaguaraçu', state_id: 13 },
  { id: 1932, city_name: 'Jaíba', state_id: 13 },
  { id: 1933, city_name: 'Jampruca', state_id: 13 },
  { id: 1934, city_name: 'Janaúba', state_id: 13 },
  { id: 1935, city_name: 'Januária', state_id: 13 },
  { id: 1936, city_name: 'Japaraíba', state_id: 13 },
  { id: 1937, city_name: 'Japonvar', state_id: 13 },
  { id: 1938, city_name: 'Jeceaba', state_id: 13 },
  { id: 1939, city_name: 'Jenipapo de Minas', state_id: 13 },
  { id: 1940, city_name: 'Jequeri', state_id: 13 },
  { id: 1941, city_name: 'Jequitaí', state_id: 13 },
  { id: 1942, city_name: 'Jequitibá', state_id: 13 },
  { id: 1943, city_name: 'Jequitinhonha', state_id: 13 },
  { id: 1944, city_name: 'Jesuânia', state_id: 13 },
  { id: 1945, city_name: 'Joaíma', state_id: 13 },
  { id: 1946, city_name: 'Joanésia', state_id: 13 },
  { id: 1947, city_name: 'João Monlevade', state_id: 13 },
  { id: 1948, city_name: 'João Pinheiro', state_id: 13 },
  { id: 1949, city_name: 'Joaquim Felício', state_id: 13 },
  { id: 1950, city_name: 'Jordânia', state_id: 13 },
  { id: 1951, city_name: 'José Gonçalves de Minas', state_id: 13 },
  { id: 1952, city_name: 'José Raydan', state_id: 13 },
  { id: 1953, city_name: 'Josenópolis', state_id: 13 },
  { id: 1954, city_name: 'Juatuba', state_id: 13 },
  { id: 1955, city_name: 'Juiz de Fora', state_id: 13 },
  { id: 1956, city_name: 'Juramento', state_id: 13 },
  { id: 1957, city_name: 'Juruaia', state_id: 13 },
  { id: 1958, city_name: 'Juvenília', state_id: 13 },
  { id: 1959, city_name: 'Ladainha', state_id: 13 },
  { id: 1960, city_name: 'Lagamar', state_id: 13 },
  { id: 1961, city_name: 'Lagoa da Prata', state_id: 13 },
  { id: 1962, city_name: 'Lagoa dos Patos', state_id: 13 },
  { id: 1963, city_name: 'Lagoa Dourada', state_id: 13 },
  { id: 1964, city_name: 'Lagoa Formosa', state_id: 13 },
  { id: 1965, city_name: 'Lagoa Grande', state_id: 13 },
  { id: 1966, city_name: 'Lajinha', state_id: 13 },
  { id: 1967, city_name: 'Lambari', state_id: 13 },
  { id: 1968, city_name: 'Lamim', state_id: 13 },
  { id: 1969, city_name: 'Laranjal', state_id: 13 },
  { id: 1970, city_name: 'Lassance', state_id: 13 },
  { id: 1971, city_name: 'Lavras', state_id: 13 },
  { id: 1972, city_name: 'Leandro Ferreira', state_id: 13 },
  { id: 1973, city_name: 'Leme do Prado', state_id: 13 },
  { id: 1974, city_name: 'Leopoldina', state_id: 13 },
  { id: 1975, city_name: 'Liberdade', state_id: 13 },
  { id: 1976, city_name: 'Lima Duarte', state_id: 13 },
  { id: 1977, city_name: 'Limeira do Oeste', state_id: 13 },
  { id: 1978, city_name: 'Lontra', state_id: 13 },
  { id: 1979, city_name: 'Luisburgo', state_id: 13 },
  { id: 1980, city_name: 'Luislândia', state_id: 13 },
  { id: 1981, city_name: 'Luminárias', state_id: 13 },
  { id: 1982, city_name: 'Luz', state_id: 13 },
  { id: 1983, city_name: 'Machacalis', state_id: 13 },
  { id: 1984, city_name: 'Machado', state_id: 13 },
  { id: 1985, city_name: 'Madre de Deus de Minas', state_id: 13 },
  { id: 1986, city_name: 'Malacacheta', state_id: 13 },
  { id: 1987, city_name: 'Mamonas', state_id: 13 },
  { id: 1988, city_name: 'Manga', state_id: 13 },
  { id: 1989, city_name: 'Manhuaçu', state_id: 13 },
  { id: 1990, city_name: 'Manhumirim', state_id: 13 },
  { id: 1991, city_name: 'Mantena', state_id: 13 },
  { id: 1992, city_name: 'Mar de Espanha', state_id: 13 },
  { id: 1993, city_name: 'Maravilhas', state_id: 13 },
  { id: 1994, city_name: 'Maria da Fé', state_id: 13 },
  { id: 1995, city_name: 'Mariana', state_id: 13 },
  { id: 1996, city_name: 'Marilac', state_id: 13 },
  { id: 1997, city_name: 'Mário Campos', state_id: 13 },
  { id: 1998, city_name: 'Maripá de Minas', state_id: 13 },
  { id: 1999, city_name: 'Marliéria', state_id: 13 },
  { id: 2000, city_name: 'Marmelópolis', state_id: 13 },
  { id: 2001, city_name: 'Martinho Campos', state_id: 13 },
  { id: 2002, city_name: 'Martins Soares', state_id: 13 },
  { id: 2003, city_name: 'Mata Verde', state_id: 13 },
  { id: 2004, city_name: 'Materlândia', state_id: 13 },
  { id: 2005, city_name: 'Mateus Leme', state_id: 13 },
  { id: 2006, city_name: 'Mathias Lobato', state_id: 13 },
  { id: 2007, city_name: 'Matias Barbosa', state_id: 13 },
  { id: 2008, city_name: 'Matias Cardoso', state_id: 13 },
  { id: 2009, city_name: 'Matipó', state_id: 13 },
  { id: 2010, city_name: 'Mato Verde', state_id: 13 },
  { id: 2011, city_name: 'Matozinhos', state_id: 13 },
  { id: 2012, city_name: 'Matutina', state_id: 13 },
  { id: 2013, city_name: 'Medeiros', state_id: 13 },
  { id: 2014, city_name: 'Medina', state_id: 13 },
  { id: 2015, city_name: 'Mendes Pimentel', state_id: 13 },
  { id: 2016, city_name: 'Mercês', state_id: 13 },
  { id: 2017, city_name: 'Mesquita', state_id: 13 },
  { id: 2018, city_name: 'Minas Novas', state_id: 13 },
  { id: 2019, city_name: 'Minduri', state_id: 13 },
  { id: 2020, city_name: 'Mirabela', state_id: 13 },
  { id: 2021, city_name: 'Miradouro', state_id: 13 },
  { id: 2022, city_name: 'Miraí', state_id: 13 },
  { id: 2023, city_name: 'Miravânia', state_id: 13 },
  { id: 2024, city_name: 'Moeda', state_id: 13 },
  { id: 2025, city_name: 'Moema', state_id: 13 },
  { id: 2026, city_name: 'Monjolos', state_id: 13 },
  { id: 2027, city_name: 'Monsenhor Paulo', state_id: 13 },
  { id: 2028, city_name: 'Montalvânia', state_id: 13 },
  { id: 2029, city_name: 'Monte Alegre de Minas', state_id: 13 },
  { id: 2030, city_name: 'Monte Azul', state_id: 13 },
  { id: 2031, city_name: 'Monte Belo', state_id: 13 },
  { id: 2032, city_name: 'Monte Carmelo', state_id: 13 },
  { id: 2033, city_name: 'Monte Formoso', state_id: 13 },
  { id: 2034, city_name: 'Monte Santo de Minas', state_id: 13 },
  { id: 2035, city_name: 'Monte Sião', state_id: 13 },
  { id: 2036, city_name: 'Montes Claros', state_id: 13 },
  { id: 2037, city_name: 'Montezuma', state_id: 13 },
  { id: 2038, city_name: 'Morada Nova de Minas', state_id: 13 },
  { id: 2039, city_name: 'Morro da Garça', state_id: 13 },
  { id: 2040, city_name: 'Morro do Pilar', state_id: 13 },
  { id: 2041, city_name: 'Munhoz', state_id: 13 },
  { id: 2042, city_name: 'Muriaé', state_id: 13 },
  { id: 2043, city_name: 'Mutum', state_id: 13 },
  { id: 2044, city_name: 'Muzambinho', state_id: 13 },
  { id: 2045, city_name: 'Nacip Raydan', state_id: 13 },
  { id: 2046, city_name: 'Nanuque', state_id: 13 },
  { id: 2047, city_name: 'Naque', state_id: 13 },
  { id: 2048, city_name: 'Natalândia', state_id: 13 },
  { id: 2049, city_name: 'Natércia', state_id: 13 },
  { id: 2050, city_name: 'Nazareno', state_id: 13 },
  { id: 2051, city_name: 'Nepomuceno', state_id: 13 },
  { id: 2052, city_name: 'Ninheira', state_id: 13 },
  { id: 2053, city_name: 'Nova Belém', state_id: 13 },
  { id: 2054, city_name: 'Nova Era', state_id: 13 },
  { id: 2055, city_name: 'Nova Lima', state_id: 13 },
  { id: 2056, city_name: 'Nova Módica', state_id: 13 },
  { id: 2057, city_name: 'Nova Ponte', state_id: 13 },
  { id: 2058, city_name: 'Nova Porteirinha', state_id: 13 },
  { id: 2059, city_name: 'Nova Resende', state_id: 13 },
  { id: 2060, city_name: 'Nova Serrana', state_id: 13 },
  { id: 2061, city_name: 'Nova União', state_id: 13 },
  { id: 2062, city_name: 'Novo Cruzeiro', state_id: 13 },
  { id: 2063, city_name: 'Novo Oriente de Minas', state_id: 13 },
  { id: 2064, city_name: 'Novorizonte', state_id: 13 },
  { id: 2065, city_name: 'Olaria', state_id: 13 },
  { id: 2066, city_name: "Olhos-d'Água", state_id: 13 },
  { id: 2067, city_name: 'Olímpio Noronha', state_id: 13 },
  { id: 2068, city_name: 'Oliveira', state_id: 13 },
  { id: 2069, city_name: 'Oliveira Fortes', state_id: 13 },
  { id: 2070, city_name: 'Onça de Pitangui', state_id: 13 },
  { id: 2071, city_name: 'Oratórios', state_id: 13 },
  { id: 2072, city_name: 'Orizânia', state_id: 13 },
  { id: 2073, city_name: 'Ouro Fino', state_id: 13 },
  { id: 2074, city_name: 'Ouro Preto', state_id: 13 },
  { id: 2075, city_name: 'Ouro Verde de Minas', state_id: 13 },
  { id: 2076, city_name: 'Padre Carvalho', state_id: 13 },
  { id: 2077, city_name: 'Padre Paraíso', state_id: 13 },
  { id: 2078, city_name: 'Pai Pedro', state_id: 13 },
  { id: 2079, city_name: 'Paineiras', state_id: 13 },
  { id: 2080, city_name: 'Pains', state_id: 13 },
  { id: 2081, city_name: 'Paiva', state_id: 13 },
  { id: 2082, city_name: 'Palma', state_id: 13 },
  { id: 2083, city_name: 'Palmópolis', state_id: 13 },
  { id: 2084, city_name: 'Papagaios', state_id: 13 },
  { id: 2085, city_name: 'Pará de Minas', state_id: 13 },
  { id: 2086, city_name: 'Paracatu', state_id: 13 },
  { id: 2087, city_name: 'Paraguaçu', state_id: 13 },
  { id: 2088, city_name: 'Paraisópolis', state_id: 13 },
  { id: 2089, city_name: 'Paraopeba', state_id: 13 },
  { id: 2090, city_name: 'Passa Quatro', state_id: 13 },
  { id: 2091, city_name: 'Passa Tempo', state_id: 13 },
  { id: 2092, city_name: 'Passa Vinte', state_id: 13 },
  { id: 2093, city_name: 'Passabém', state_id: 13 },
  { id: 2094, city_name: 'Passos', state_id: 13 },
  { id: 2095, city_name: 'Patis', state_id: 13 },
  { id: 2096, city_name: 'Patos de Minas', state_id: 13 },
  { id: 2097, city_name: 'Patrocínio', state_id: 13 },
  { id: 2098, city_name: 'Patrocínio do Muriaé', state_id: 13 },
  { id: 2099, city_name: 'Paula Cândido', state_id: 13 },
  { id: 2100, city_name: 'Paulistas', state_id: 13 },
  { id: 2101, city_name: 'Pavão', state_id: 13 },
  { id: 2102, city_name: 'Peçanha', state_id: 13 },
  { id: 2103, city_name: 'Pedra Azul', state_id: 13 },
  { id: 2104, city_name: 'Pedra Bonita', state_id: 13 },
  { id: 2105, city_name: 'Pedra do Anta', state_id: 13 },
  { id: 2106, city_name: 'Pedra do Indaiá', state_id: 13 },
  { id: 2107, city_name: 'Pedra Dourada', state_id: 13 },
  { id: 2108, city_name: 'Pedralva', state_id: 13 },
  { id: 2109, city_name: 'Pedras de Maria da Cruz', state_id: 13 },
  { id: 2110, city_name: 'Pedrinópolis', state_id: 13 },
  { id: 2111, city_name: 'Pedro Leopoldo', state_id: 13 },
  { id: 2112, city_name: 'Pedro Teixeira', state_id: 13 },
  { id: 2113, city_name: 'Pequeri', state_id: 13 },
  { id: 2114, city_name: 'Pequi', state_id: 13 },
  { id: 2115, city_name: 'Perdigão', state_id: 13 },
  { id: 2116, city_name: 'Perdizes', state_id: 13 },
  { id: 2117, city_name: 'Perdões', state_id: 13 },
  { id: 2118, city_name: 'Periquito', state_id: 13 },
  { id: 2119, city_name: 'Pescador', state_id: 13 },
  { id: 2120, city_name: 'Piau', state_id: 13 },
  { id: 2121, city_name: 'Piedade de Caratinga', state_id: 13 },
  { id: 2122, city_name: 'Piedade de Ponte Nova', state_id: 13 },
  { id: 2123, city_name: 'Piedade do Rio Grande', state_id: 13 },
  { id: 2124, city_name: 'Piedade dos Gerais', state_id: 13 },
  { id: 2125, city_name: 'Pimenta', state_id: 13 },
  { id: 2126, city_name: "Pingo-d'Água", state_id: 13 },
  { id: 2127, city_name: 'Pintópolis', state_id: 13 },
  { id: 2128, city_name: 'Piracema', state_id: 13 },
  { id: 2129, city_name: 'Pirajuba', state_id: 13 },
  { id: 2130, city_name: 'Piranga', state_id: 13 },
  { id: 2131, city_name: 'Piranguçu', state_id: 13 },
  { id: 2132, city_name: 'Piranguinho', state_id: 13 },
  { id: 2133, city_name: 'Pirapetinga', state_id: 13 },
  { id: 2134, city_name: 'Pirapora', state_id: 13 },
  { id: 2135, city_name: 'Piraúba', state_id: 13 },
  { id: 2136, city_name: 'Pitangui', state_id: 13 },
  { id: 2137, city_name: 'Piumhi', state_id: 13 },
  { id: 2138, city_name: 'Planura', state_id: 13 },
  { id: 2139, city_name: 'Poço Fundo', state_id: 13 },
  { id: 2140, city_name: 'Poços de Caldas', state_id: 13 },
  { id: 2141, city_name: 'Pocrane', state_id: 13 },
  { id: 2142, city_name: 'Pompéu', state_id: 13 },
  { id: 2143, city_name: 'Ponte Nova', state_id: 13 },
  { id: 2144, city_name: 'Ponto Chique', state_id: 13 },
  { id: 2145, city_name: 'Ponto dos Volantes', state_id: 13 },
  { id: 2146, city_name: 'Porteirinha', state_id: 13 },
  { id: 2147, city_name: 'Porto Firme', state_id: 13 },
  { id: 2148, city_name: 'Poté', state_id: 13 },
  { id: 2149, city_name: 'Pouso Alegre', state_id: 13 },
  { id: 2150, city_name: 'Pouso Alto', state_id: 13 },
  { id: 2151, city_name: 'Prados', state_id: 13 },
  { id: 2152, city_name: 'Prata', state_id: 13 },
  { id: 2153, city_name: 'Pratápolis', state_id: 13 },
  { id: 2154, city_name: 'Pratinha', state_id: 13 },
  { id: 2155, city_name: 'Presidente Bernardes', state_id: 13 },
  { id: 2156, city_name: 'Presidente Kubitschek', state_id: 13 },
  { id: 2157, city_name: 'Presidente Olegário', state_id: 13 },
  { id: 2158, city_name: 'Prudente de Morais', state_id: 13 },
  { id: 2159, city_name: 'Quartel Geral', state_id: 13 },
  { id: 2160, city_name: 'Queluzito', state_id: 13 },
  { id: 2161, city_name: 'Raposos', state_id: 13 },
  { id: 2162, city_name: 'Raul Soares', state_id: 13 },
  { id: 2163, city_name: 'Recreio', state_id: 13 },
  { id: 2164, city_name: 'Reduto', state_id: 13 },
  { id: 2165, city_name: 'Resende Costa', state_id: 13 },
  { id: 2166, city_name: 'Resplendor', state_id: 13 },
  { id: 2167, city_name: 'Ressaquinha', state_id: 13 },
  { id: 2168, city_name: 'Riachinho', state_id: 13 },
  { id: 2169, city_name: 'Riacho dos Machados', state_id: 13 },
  { id: 2170, city_name: 'Ribeirão das Neves', state_id: 13 },
  { id: 2171, city_name: 'Ribeirão Vermelho', state_id: 13 },
  { id: 2172, city_name: 'Rio Acima', state_id: 13 },
  { id: 2173, city_name: 'Rio Casca', state_id: 13 },
  { id: 2174, city_name: 'Rio do Prado', state_id: 13 },
  { id: 2175, city_name: 'Rio Doce', state_id: 13 },
  { id: 2176, city_name: 'Rio Espera', state_id: 13 },
  { id: 2177, city_name: 'Rio Manso', state_id: 13 },
  { id: 2178, city_name: 'Rio Novo', state_id: 13 },
  { id: 2179, city_name: 'Rio Paranaíba', state_id: 13 },
  { id: 2180, city_name: 'Rio Pardo de Minas', state_id: 13 },
  { id: 2181, city_name: 'Rio Piracicaba', state_id: 13 },
  { id: 2182, city_name: 'Rio Pomba', state_id: 13 },
  { id: 2183, city_name: 'Rio Preto', state_id: 13 },
  { id: 2184, city_name: 'Rio Vermelho', state_id: 13 },
  { id: 2185, city_name: 'Ritápolis', state_id: 13 },
  { id: 2186, city_name: 'Rochedo de Minas', state_id: 13 },
  { id: 2187, city_name: 'Rodeiro', state_id: 13 },
  { id: 2188, city_name: 'Romaria', state_id: 13 },
  { id: 2189, city_name: 'Rosário da Limeira', state_id: 13 },
  { id: 2190, city_name: 'Rubelita', state_id: 13 },
  { id: 2191, city_name: 'Rubim', state_id: 13 },
  { id: 2192, city_name: 'Sabará', state_id: 13 },
  { id: 2193, city_name: 'Sabinópolis', state_id: 13 },
  { id: 2194, city_name: 'Sacramento', state_id: 13 },
  { id: 2195, city_name: 'Salinas', state_id: 13 },
  { id: 2196, city_name: 'Salto da Divisa', state_id: 13 },
  { id: 2197, city_name: 'Santa Bárbara do Leste', state_id: 13 },
  { id: 2198, city_name: 'Santa Bárbara do Monte Verde', state_id: 13 },
  { id: 2199, city_name: 'Santa Bárbara do Tugúrio', state_id: 13 },
  { id: 2200, city_name: 'Santa Cruz de Minas', state_id: 13 },
  { id: 2201, city_name: 'Santa Cruz de Salinas', state_id: 13 },
  { id: 2202, city_name: 'Santa Cruz do Escalvado', state_id: 13 },
  { id: 2203, city_name: 'Santa Efigênia de Minas', state_id: 13 },
  { id: 2204, city_name: 'Santa Fé de Minas', state_id: 13 },
  { id: 2205, city_name: 'Santa Helena de Minas', state_id: 13 },
  { id: 2206, city_name: 'Santa Juliana', state_id: 13 },
  { id: 2207, city_name: 'Santa Margarida', state_id: 13 },
  { id: 2208, city_name: 'Santa Maria de Itabira', state_id: 13 },
  { id: 2209, city_name: 'Santa Maria do Salto', state_id: 13 },
  { id: 2210, city_name: 'Santa Maria do Suaçuí', state_id: 13 },
  { id: 2211, city_name: 'Santa Rita de Caldas', state_id: 13 },
  { id: 2212, city_name: 'Santa Rita de Ibitipoca', state_id: 13 },
  { id: 2213, city_name: 'Santa Rita de Jacutinga', state_id: 13 },
  { id: 2214, city_name: 'Santa Rita de Minas', state_id: 13 },
  { id: 2215, city_name: 'Santa Rita do Itueto', state_id: 13 },
  { id: 2216, city_name: 'Santa Rita do Sapucaí', state_id: 13 },
  { id: 2217, city_name: 'Santa Rosa da Serra', state_id: 13 },
  { id: 2218, city_name: 'Santa Vitória', state_id: 13 },
  { id: 2219, city_name: 'Santana da Vargem', state_id: 13 },
  { id: 2220, city_name: 'Santana de Cataguases', state_id: 13 },
  { id: 2221, city_name: 'Santana de Pirapama', state_id: 13 },
  { id: 2222, city_name: 'Santana do Deserto', state_id: 13 },
  { id: 2223, city_name: 'Santana do Garambéu', state_id: 13 },
  { id: 2224, city_name: 'Santana do Jacaré', state_id: 13 },
  { id: 2225, city_name: 'Santana do Manhuaçu', state_id: 13 },
  { id: 2226, city_name: 'Santana do Paraíso', state_id: 13 },
  { id: 2227, city_name: 'Santana do Riacho', state_id: 13 },
  { id: 2228, city_name: 'Santana dos Montes', state_id: 13 },
  { id: 2229, city_name: 'Santo Antônio do Amparo', state_id: 13 },
  { id: 2230, city_name: 'Santo Antônio do Aventureiro', state_id: 13 },
  { id: 2231, city_name: 'Santo Antônio do Grama', state_id: 13 },
  { id: 2232, city_name: 'Santo Antônio do Itambé', state_id: 13 },
  { id: 2233, city_name: 'Santo Antônio do Jacinto', state_id: 13 },
  { id: 2234, city_name: 'Santo Antônio do Monte', state_id: 13 },
  { id: 2235, city_name: 'Santo Antônio do Retiro', state_id: 13 },
  { id: 2236, city_name: 'Santo Antônio do Rio Abaixo', state_id: 13 },
  { id: 2237, city_name: 'Santo Hipólito', state_id: 13 },
  { id: 2238, city_name: 'Santos Dumont', state_id: 13 },
  { id: 2239, city_name: 'São Bento Abade', state_id: 13 },
  { id: 2240, city_name: 'São Brás do Suaçuí', state_id: 13 },
  { id: 2241, city_name: 'São Domingos das Dores', state_id: 13 },
  { id: 2242, city_name: 'São Domingos do Prata', state_id: 13 },
  { id: 2243, city_name: 'São Félix de Minas', state_id: 13 },
  { id: 2244, city_name: 'São Francisco', state_id: 13 },
  { id: 2245, city_name: 'São Francisco de Paula', state_id: 13 },
  { id: 2246, city_name: 'São Francisco de Sales', state_id: 13 },
  { id: 2247, city_name: 'São Francisco do Glória', state_id: 13 },
  { id: 2248, city_name: 'São Geraldo', state_id: 13 },
  { id: 2249, city_name: 'São Geraldo da Piedade', state_id: 13 },
  { id: 2250, city_name: 'São Geraldo do Baixio', state_id: 13 },
  { id: 2251, city_name: 'São Gonçalo do Abaeté', state_id: 13 },
  { id: 2252, city_name: 'São Gonçalo do Pará', state_id: 13 },
  { id: 2253, city_name: 'São Gonçalo do Rio Abaixo', state_id: 13 },
  { id: 2254, city_name: 'São Gonçalo do Rio Preto', state_id: 13 },
  { id: 2255, city_name: 'São Gonçalo do Sapucaí', state_id: 13 },
  { id: 2256, city_name: 'São Gotardo', state_id: 13 },
  { id: 2257, city_name: 'São João Batista do Glória', state_id: 13 },
  { id: 2258, city_name: 'São João da Lagoa', state_id: 13 },
  { id: 2259, city_name: 'São João da Mata', state_id: 13 },
  { id: 2260, city_name: 'São João da Ponte', state_id: 13 },
  { id: 2261, city_name: 'São João das Missões', state_id: 13 },
  { id: 2262, city_name: 'São João del Rei', state_id: 13 },
  { id: 2263, city_name: 'São João do Manhuaçu', state_id: 13 },
  { id: 2264, city_name: 'São João do Manteninha', state_id: 13 },
  { id: 2265, city_name: 'São João do Oriente', state_id: 13 },
  { id: 2266, city_name: 'São João do Pacuí', state_id: 13 },
  { id: 2267, city_name: 'São João Evangelista', state_id: 13 },
  { id: 2268, city_name: 'São João Nepomuceno', state_id: 13 },
  { id: 2269, city_name: 'São Joaquim de Bicas', state_id: 13 },
  { id: 2270, city_name: 'São José da Barra', state_id: 13 },
  { id: 2271, city_name: 'São José da Lapa', state_id: 13 },
  { id: 2272, city_name: 'São José da Safira', state_id: 13 },
  { id: 2273, city_name: 'São José da Varginha', state_id: 13 },
  { id: 2274, city_name: 'São José do Alegre', state_id: 13 },
  { id: 2275, city_name: 'São José do Divino', state_id: 13 },
  { id: 2276, city_name: 'São José do Goiabal', state_id: 13 },
  { id: 2277, city_name: 'São José do Jacuri', state_id: 13 },
  { id: 2278, city_name: 'São José do Mantimento', state_id: 13 },
  { id: 2279, city_name: 'São Lourenço', state_id: 13 },
  { id: 2280, city_name: 'São Miguel do Anta', state_id: 13 },
  { id: 2281, city_name: 'São Pedro da União', state_id: 13 },
  { id: 2282, city_name: 'São Pedro do Suaçuí', state_id: 13 },
  { id: 2283, city_name: 'São Pedro dos Ferros', state_id: 13 },
  { id: 2284, city_name: 'São Romão', state_id: 13 },
  { id: 2285, city_name: 'São Roque de Minas', state_id: 13 },
  { id: 2286, city_name: 'São Sebastião da Bela Vista', state_id: 13 },
  { id: 2287, city_name: 'São Sebastião da Vargem Alegre', state_id: 13 },
  { id: 2288, city_name: 'São Sebastião do Anta', state_id: 13 },
  { id: 2289, city_name: 'São Sebastião do Maranhão', state_id: 13 },
  { id: 2290, city_name: 'São Sebastião do Oeste', state_id: 13 },
  { id: 2291, city_name: 'São Sebastião do Paraíso', state_id: 13 },
  { id: 2292, city_name: 'São Sebastião do Rio Preto', state_id: 13 },
  { id: 2293, city_name: 'São Sebastião do Rio Verde', state_id: 13 },
  { id: 2294, city_name: 'São Tiago', state_id: 13 },
  { id: 2295, city_name: 'São Tomás de Aquino', state_id: 13 },
  { id: 2296, city_name: 'São Tomé das Letras', state_id: 13 },
  { id: 2297, city_name: 'São Vicente de Minas', state_id: 13 },
  { id: 2298, city_name: 'Sapucaí-Mirim', state_id: 13 },
  { id: 2299, city_name: 'Sardoá', state_id: 13 },
  { id: 2300, city_name: 'Sarzedo', state_id: 13 },
  { id: 2301, city_name: 'Sem-Peixe', state_id: 13 },
  { id: 2302, city_name: 'Senador Amaral', state_id: 13 },
  { id: 2303, city_name: 'Senador Cortes', state_id: 13 },
  { id: 2304, city_name: 'Senador Firmino', state_id: 13 },
  { id: 2305, city_name: 'Senador José Bento', state_id: 13 },
  { id: 2306, city_name: 'Senador Modestino Gonçalves', state_id: 13 },
  { id: 2307, city_name: 'Senhora de Oliveira', state_id: 13 },
  { id: 2308, city_name: 'Senhora do Porto', state_id: 13 },
  { id: 2309, city_name: 'Senhora dos Remédios', state_id: 13 },
  { id: 2310, city_name: 'Sericita', state_id: 13 },
  { id: 2311, city_name: 'Seritinga', state_id: 13 },
  { id: 2312, city_name: 'Serra Azul de Minas', state_id: 13 },
  { id: 2313, city_name: 'Serra da Saudade', state_id: 13 },
  { id: 2314, city_name: 'Serra do Salitre', state_id: 13 },
  { id: 2315, city_name: 'Serra dos Aimorés', state_id: 13 },
  { id: 2316, city_name: 'Serrania', state_id: 13 },
  { id: 2317, city_name: 'Serranópolis de Minas', state_id: 13 },
  { id: 2318, city_name: 'Serranos', state_id: 13 },
  { id: 2319, city_name: 'Serro', state_id: 13 },
  { id: 2320, city_name: 'Sete Lagoas', state_id: 13 },
  { id: 2321, city_name: 'Setubinha', state_id: 13 },
  { id: 2322, city_name: 'Silveirânia', state_id: 13 },
  { id: 2323, city_name: 'Silvianópolis', state_id: 13 },
  { id: 2324, city_name: 'Simão Pereira', state_id: 13 },
  { id: 2325, city_name: 'Simonésia', state_id: 13 },
  { id: 2326, city_name: 'Sobrália', state_id: 13 },
  { id: 2327, city_name: 'Soledade de Minas', state_id: 13 },
  { id: 2328, city_name: 'Tabuleiro', state_id: 13 },
  { id: 2329, city_name: 'Taiobeiras', state_id: 13 },
  { id: 2330, city_name: 'Taparuba', state_id: 13 },
  { id: 2331, city_name: 'Tapira', state_id: 13 },
  { id: 2332, city_name: 'Tapiraí', state_id: 13 },
  { id: 2333, city_name: 'Taquaraçu de Minas', state_id: 13 },
  { id: 2334, city_name: 'Tarumirim', state_id: 13 },
  { id: 2335, city_name: 'Teixeiras', state_id: 13 },
  { id: 2336, city_name: 'Teófilo Otoni', state_id: 13 },
  { id: 2337, city_name: 'Timóteo', state_id: 13 },
  { id: 2338, city_name: 'Tiradentes', state_id: 13 },
  { id: 2339, city_name: 'Tiros', state_id: 13 },
  { id: 2340, city_name: 'Tocantins', state_id: 13 },
  { id: 2341, city_name: 'Tocos do Moji', state_id: 13 },
  { id: 2342, city_name: 'Toledo', state_id: 13 },
  { id: 2343, city_name: 'Tombos', state_id: 13 },
  { id: 2344, city_name: 'Três Corações', state_id: 13 },
  { id: 2345, city_name: 'Três Marias', state_id: 13 },
  { id: 2346, city_name: 'Três Pontas', state_id: 13 },
  { id: 2347, city_name: 'Tumiritinga', state_id: 13 },
  { id: 2348, city_name: 'Tupaciguara', state_id: 13 },
  { id: 2349, city_name: 'Turmalina', state_id: 13 },
  { id: 2350, city_name: 'Turvolândia', state_id: 13 },
  { id: 2351, city_name: 'Ubá', state_id: 13 },
  { id: 2352, city_name: 'Ubaí', state_id: 13 },
  { id: 2353, city_name: 'Ubaporanga', state_id: 13 },
  { id: 2354, city_name: 'Uberaba', state_id: 13 },
  { id: 2355, city_name: 'Uberlândia', state_id: 13 },
  { id: 2356, city_name: 'Umburatiba', state_id: 13 },
  { id: 2357, city_name: 'Unaí', state_id: 13 },
  { id: 2358, city_name: 'União de Minas', state_id: 13 },
  { id: 2359, city_name: 'Uruana de Minas', state_id: 13 },
  { id: 2360, city_name: 'Urucânia', state_id: 13 },
  { id: 2361, city_name: 'Urucuia', state_id: 13 },
  { id: 2362, city_name: 'Vargem Alegre', state_id: 13 },
  { id: 2363, city_name: 'Vargem Bonita', state_id: 13 },
  { id: 2364, city_name: 'Vargem Grande do Rio Pardo', state_id: 13 },
  { id: 2365, city_name: 'Varginha', state_id: 13 },
  { id: 2366, city_name: 'Varjão de Minas', state_id: 13 },
  { id: 2367, city_name: 'Várzea da Palma', state_id: 13 },
  { id: 2368, city_name: 'Varzelândia', state_id: 13 },
  { id: 2369, city_name: 'Vazante', state_id: 13 },
  { id: 2370, city_name: 'Verdelândia', state_id: 13 },
  { id: 2371, city_name: 'Veredinha', state_id: 13 },
  { id: 2372, city_name: 'Veríssimo', state_id: 13 },
  { id: 2373, city_name: 'Vermelho Novo', state_id: 13 },
  { id: 2374, city_name: 'Vespasiano', state_id: 13 },
  { id: 2375, city_name: 'Vieiras', state_id: 13 },
  { id: 2376, city_name: 'Virgem da Lapa', state_id: 13 },
  { id: 2377, city_name: 'Virgínia', state_id: 13 },
  { id: 2378, city_name: 'Virginópolis', state_id: 13 },
  { id: 2379, city_name: 'Virgolândia', state_id: 13 },
  { id: 2380, city_name: 'Visconde do Rio Branco', state_id: 13 },
  { id: 2381, city_name: 'Volta Grande', state_id: 13 },
  { id: 2382, city_name: 'Wenceslau Braz', state_id: 13 },
  { id: 2383, city_name: 'Abaetetuba', state_id: 14 },
  { id: 2384, city_name: 'Abel Figueiredo', state_id: 14 },
  { id: 2385, city_name: 'Acará', state_id: 14 },
  { id: 2386, city_name: 'Afuá', state_id: 14 },
  { id: 2387, city_name: 'Água Azul do Norte', state_id: 14 },
  { id: 2388, city_name: 'Alenquer', state_id: 14 },
  { id: 2389, city_name: 'Almeirim', state_id: 14 },
  { id: 2390, city_name: 'Altamira', state_id: 14 },
  { id: 2391, city_name: 'Anajás', state_id: 14 },
  { id: 2392, city_name: 'Ananindeua', state_id: 14 },
  { id: 2393, city_name: 'Anapu', state_id: 14 },
  { id: 2394, city_name: 'Augusto Corrêa', state_id: 14 },
  { id: 2395, city_name: 'Aurora do Pará', state_id: 14 },
  { id: 2396, city_name: 'Aveiro', state_id: 14 },
  { id: 2397, city_name: 'Bagre', state_id: 14 },
  { id: 2398, city_name: 'Baião', state_id: 14 },
  { id: 2399, city_name: 'Bannach', state_id: 14 },
  { id: 2400, city_name: 'Barcarena', state_id: 14 },
  { id: 2401, city_name: 'Belterra', state_id: 14 },
  { id: 2402, city_name: 'Benevides', state_id: 14 },
  { id: 2403, city_name: 'Bom Jesus do Tocantins', state_id: 14 },
  { id: 2404, city_name: 'Bragança', state_id: 14 },
  { id: 2405, city_name: 'Brasil Novo', state_id: 14 },
  { id: 2406, city_name: 'Brejo Grande do Araguaia', state_id: 14 },
  { id: 2407, city_name: 'Breu Branco', state_id: 14 },
  { id: 2408, city_name: 'Breves', state_id: 14 },
  { id: 2409, city_name: 'Bujaru', state_id: 14 },
  { id: 2410, city_name: 'Cachoeira do Arari', state_id: 14 },
  { id: 2411, city_name: 'Cachoeira do Piriá', state_id: 14 },
  { id: 2412, city_name: 'Cametá', state_id: 14 },
  { id: 2413, city_name: 'Canaã dos Carajás', state_id: 14 },
  { id: 2414, city_name: 'Capanema', state_id: 14 },
  { id: 2415, city_name: 'Capitão Poço', state_id: 14 },
  { id: 2416, city_name: 'Castanhal', state_id: 14 },
  { id: 2417, city_name: 'Chaves', state_id: 14 },
  { id: 2418, city_name: 'Colares', state_id: 14 },
  { id: 2419, city_name: 'Conceição do Araguaia', state_id: 14 },
  { id: 2420, city_name: 'Concórdia do Pará', state_id: 14 },
  { id: 2421, city_name: 'Cumaru do Norte', state_id: 14 },
  { id: 2422, city_name: 'Curionópolis', state_id: 14 },
  { id: 2423, city_name: 'Curralinho', state_id: 14 },
  { id: 2424, city_name: 'Curuá', state_id: 14 },
  { id: 2425, city_name: 'Curuçá', state_id: 14 },
  { id: 2426, city_name: 'Dom Eliseu', state_id: 14 },
  { id: 2427, city_name: 'Eldorado do Carajás', state_id: 14 },
  { id: 2428, city_name: 'Faro', state_id: 14 },
  { id: 2429, city_name: 'Floresta do Araguaia', state_id: 14 },
  { id: 2430, city_name: 'Garrafão do Norte', state_id: 14 },
  { id: 2431, city_name: 'Goianésia do Pará', state_id: 14 },
  { id: 2432, city_name: 'Gurupá', state_id: 14 },
  { id: 2433, city_name: 'Igarapé-Açu', state_id: 14 },
  { id: 2434, city_name: 'Igarapé-Miri', state_id: 14 },
  { id: 2435, city_name: 'Inhangapi', state_id: 14 },
  { id: 2436, city_name: 'Ipixuna do Pará', state_id: 14 },
  { id: 2437, city_name: 'Irituia', state_id: 14 },
  { id: 2438, city_name: 'Itaituba', state_id: 14 },
  { id: 2439, city_name: 'Itupiranga', state_id: 14 },
  { id: 2440, city_name: 'Jacareacanga', state_id: 14 },
  { id: 2441, city_name: 'Jacundá', state_id: 14 },
  { id: 2442, city_name: 'Juruti', state_id: 14 },
  { id: 2443, city_name: 'Limoeiro do Ajuru', state_id: 14 },
  { id: 2444, city_name: 'Mãe do Rio', state_id: 14 },
  { id: 2445, city_name: 'Magalhães Barata', state_id: 14 },
  { id: 2446, city_name: 'Marabá', state_id: 14 },
  { id: 2447, city_name: 'Maracanã', state_id: 14 },
  { id: 2448, city_name: 'Marapanim', state_id: 14 },
  { id: 2449, city_name: 'Marituba', state_id: 14 },
  { id: 2450, city_name: 'Medicilândia', state_id: 14 },
  { id: 2451, city_name: 'Melgaço', state_id: 14 },
  { id: 2452, city_name: 'Mocajuba', state_id: 14 },
  { id: 2453, city_name: 'Moju', state_id: 14 },
  { id: 2454, city_name: 'Mojuí dos Campos', state_id: 14 },
  { id: 2455, city_name: 'Monte Alegre', state_id: 14 },
  { id: 2456, city_name: 'Muaná', state_id: 14 },
  { id: 2457, city_name: 'Nova Esperança do Piriá', state_id: 14 },
  { id: 2458, city_name: 'Nova Ipixuna', state_id: 14 },
  { id: 2459, city_name: 'Nova Timboteua', state_id: 14 },
  { id: 2460, city_name: 'Novo Progresso', state_id: 14 },
  { id: 2461, city_name: 'Novo Repartimento', state_id: 14 },
  { id: 2462, city_name: 'Óbidos', state_id: 14 },
  { id: 2463, city_name: 'Oeiras do Pará', state_id: 14 },
  { id: 2464, city_name: 'Oriximiná', state_id: 14 },
  { id: 2465, city_name: 'Ourém', state_id: 14 },
  { id: 2466, city_name: 'Ourilândia do Norte', state_id: 14 },
  { id: 2467, city_name: 'Pacajá', state_id: 14 },
  { id: 2468, city_name: 'Palestina do Pará', state_id: 14 },
  { id: 2469, city_name: 'Paragominas', state_id: 14 },
  { id: 2470, city_name: 'Parauapebas', state_id: 14 },
  { id: 2471, city_name: "Pau D'Arco", state_id: 14 },
  { id: 2472, city_name: 'Peixe-Boi', state_id: 14 },
  { id: 2473, city_name: 'Piçarra', state_id: 14 },
  { id: 2474, city_name: 'Placas', state_id: 14 },
  { id: 2475, city_name: 'Ponta de Pedras', state_id: 14 },
  { id: 2476, city_name: 'Portel', state_id: 14 },
  { id: 2477, city_name: 'Porto de Moz', state_id: 14 },
  { id: 2478, city_name: 'Prainha', state_id: 14 },
  { id: 2479, city_name: 'Primavera', state_id: 14 },
  { id: 2480, city_name: 'Quatipuru', state_id: 14 },
  { id: 2481, city_name: 'Rio Maria', state_id: 14 },
  { id: 2482, city_name: 'Rondon do Pará', state_id: 14 },
  { id: 2483, city_name: 'Rurópolis', state_id: 14 },
  { id: 2484, city_name: 'Salinópolis', state_id: 14 },
  { id: 2485, city_name: 'Salvaterra', state_id: 14 },
  { id: 2486, city_name: 'Santa Bárbara do Pará', state_id: 14 },
  { id: 2487, city_name: 'Santa Cruz do Arari', state_id: 14 },
  { id: 2488, city_name: 'Santa Izabel do Pará', state_id: 14 },
  { id: 2489, city_name: 'Santa Luzia do Pará', state_id: 14 },
  { id: 2490, city_name: 'Santa Maria das Barreiras', state_id: 14 },
  { id: 2491, city_name: 'Santa Maria do Pará', state_id: 14 },
  { id: 2492, city_name: 'Santana do Araguaia', state_id: 14 },
  { id: 2493, city_name: 'Santarém', state_id: 14 },
  { id: 2494, city_name: 'Santarém Novo', state_id: 14 },
  { id: 2495, city_name: 'Santo Antônio do Tauá', state_id: 14 },
  { id: 2496, city_name: 'São Caetano de Odivelas', state_id: 14 },
  { id: 2497, city_name: 'São Domingos do Araguaia', state_id: 14 },
  { id: 2498, city_name: 'São Domingos do Capim', state_id: 14 },
  { id: 2499, city_name: 'São Félix do Xingu', state_id: 14 },
  { id: 2500, city_name: 'São Francisco do Pará', state_id: 14 },
  { id: 2501, city_name: 'São Geraldo do Araguaia', state_id: 14 },
  { id: 2502, city_name: 'São João da Ponta', state_id: 14 },
  { id: 2503, city_name: 'São João de Pirabas', state_id: 14 },
  { id: 2504, city_name: 'São João do Araguaia', state_id: 14 },
  { id: 2505, city_name: 'São Miguel do Guamá', state_id: 14 },
  { id: 2506, city_name: 'São Sebastião da Boa Vista', state_id: 14 },
  { id: 2507, city_name: 'Sapucaia', state_id: 14 },
  { id: 2508, city_name: 'Senador José Porfírio', state_id: 14 },
  { id: 2509, city_name: 'Soure', state_id: 14 },
  { id: 2510, city_name: 'Tailândia', state_id: 14 },
  { id: 2511, city_name: 'Terra Alta', state_id: 14 },
  { id: 2512, city_name: 'Terra Santa', state_id: 14 },
  { id: 2513, city_name: 'Tomé-Açu', state_id: 14 },
  { id: 2514, city_name: 'Tracuateua', state_id: 14 },
  { id: 2515, city_name: 'Trairão', state_id: 14 },
  { id: 2516, city_name: 'Tucumã', state_id: 14 },
  { id: 2517, city_name: 'Tucuruí', state_id: 14 },
  { id: 2518, city_name: 'Ulianópolis', state_id: 14 },
  { id: 2519, city_name: 'Uruará', state_id: 14 },
  { id: 2520, city_name: 'Vigia', state_id: 14 },
  { id: 2521, city_name: 'Viseu', state_id: 14 },
  { id: 2522, city_name: 'Vitória do Xingu', state_id: 14 },
  { id: 2523, city_name: 'Xinguara', state_id: 14 },
  { id: 2524, city_name: 'Aguiar', state_id: 15 },
  { id: 2525, city_name: 'Alagoa Grande', state_id: 15 },
  { id: 2526, city_name: 'Alagoa Nova', state_id: 15 },
  { id: 2527, city_name: 'Alagoinha', state_id: 15 },
  { id: 2528, city_name: 'Alcantil', state_id: 15 },
  { id: 2529, city_name: 'Algodão de Jandaíra', state_id: 15 },
  { id: 2530, city_name: 'Alhandra', state_id: 15 },
  { id: 2531, city_name: 'Amparo', state_id: 15 },
  { id: 2532, city_name: 'Aparecida', state_id: 15 },
  { id: 2533, city_name: 'Araçagi', state_id: 15 },
  { id: 2534, city_name: 'Arara', state_id: 15 },
  { id: 2535, city_name: 'Araruna', state_id: 15 },
  { id: 2536, city_name: 'Areia', state_id: 15 },
  { id: 2537, city_name: 'Areia de Baraúnas', state_id: 15 },
  { id: 2538, city_name: 'Areial', state_id: 15 },
  { id: 2539, city_name: 'Aroeiras', state_id: 15 },
  { id: 2540, city_name: 'Assunção', state_id: 15 },
  { id: 2541, city_name: 'Baía da Traição', state_id: 15 },
  { id: 2542, city_name: 'Bananeiras', state_id: 15 },
  { id: 2543, city_name: 'Baraúna', state_id: 15 },
  { id: 2544, city_name: 'Barra de Santa Rosa', state_id: 15 },
  { id: 2545, city_name: 'Barra de Santana', state_id: 15 },
  { id: 2546, city_name: 'Bayeux', state_id: 15 },
  { id: 2547, city_name: 'Belém do Brejo do Cruz', state_id: 15 },
  { id: 2548, city_name: 'Bernardino Batista', state_id: 15 },
  { id: 2549, city_name: 'Boa Ventura', state_id: 15 },
  { id: 2550, city_name: 'Boa Vista', state_id: 15 },
  { id: 2551, city_name: 'Bom Jesus', state_id: 15 },
  { id: 2552, city_name: 'Bonito de Santa Fé', state_id: 15 },
  { id: 2553, city_name: 'Boqueirão', state_id: 15 },
  { id: 2554, city_name: 'Borborema', state_id: 15 },
  { id: 2555, city_name: 'Brejo do Cruz', state_id: 15 },
  { id: 2556, city_name: 'Brejo dos Santos', state_id: 15 },
  { id: 2557, city_name: 'Caaporã', state_id: 15 },
  { id: 2558, city_name: 'Cabaceiras', state_id: 15 },
  { id: 2559, city_name: 'Cabedelo', state_id: 15 },
  { id: 2560, city_name: 'Cachoeira dos Índios', state_id: 15 },
  { id: 2561, city_name: 'Cacimba de Areia', state_id: 15 },
  { id: 2562, city_name: 'Cacimba de Dentro', state_id: 15 },
  { id: 2563, city_name: 'Cacimbas', state_id: 15 },
  { id: 2564, city_name: 'Caiçara', state_id: 15 },
  { id: 2565, city_name: 'Cajazeiras', state_id: 15 },
  { id: 2566, city_name: 'Cajazeirinhas', state_id: 15 },
  { id: 2567, city_name: 'Caldas Brandão', state_id: 15 },
  { id: 2568, city_name: 'Camalaú', state_id: 15 },
  { id: 2569, city_name: 'Campina Grande', state_id: 15 },
  { id: 2570, city_name: 'Capim', state_id: 15 },
  { id: 2571, city_name: 'Caraúbas', state_id: 15 },
  { id: 2572, city_name: 'Carrapateira', state_id: 15 },
  { id: 2573, city_name: 'Casserengue', state_id: 15 },
  { id: 2574, city_name: 'Catingueira', state_id: 15 },
  { id: 2575, city_name: 'Catolé do Rocha', state_id: 15 },
  { id: 2576, city_name: 'Caturité', state_id: 15 },
  { id: 2577, city_name: 'Conceição', state_id: 15 },
  { id: 2578, city_name: 'Condado', state_id: 15 },
  { id: 2579, city_name: 'Congo', state_id: 15 },
  { id: 2580, city_name: 'Coremas', state_id: 15 },
  { id: 2581, city_name: 'Coxixola', state_id: 15 },
  { id: 2582, city_name: 'Cruz do Espírito Santo', state_id: 15 },
  { id: 2583, city_name: 'Cubati', state_id: 15 },
  { id: 2584, city_name: 'Cuité', state_id: 15 },
  { id: 2585, city_name: 'Cuité de Mamanguape', state_id: 15 },
  { id: 2586, city_name: 'Cuitegi', state_id: 15 },
  { id: 2587, city_name: 'Curral de Cima', state_id: 15 },
  { id: 2588, city_name: 'Curral Velho', state_id: 15 },
  { id: 2589, city_name: 'Damião', state_id: 15 },
  { id: 2590, city_name: 'Desterro', state_id: 15 },
  { id: 2591, city_name: 'Diamante', state_id: 15 },
  { id: 2592, city_name: 'Dona Inês', state_id: 15 },
  { id: 2593, city_name: 'Duas Estradas', state_id: 15 },
  { id: 2594, city_name: 'Emas', state_id: 15 },
  { id: 2595, city_name: 'Esperança', state_id: 15 },
  { id: 2596, city_name: 'Fagundes', state_id: 15 },
  { id: 2597, city_name: 'Frei Martinho', state_id: 15 },
  { id: 2598, city_name: 'Gado Bravo', state_id: 15 },
  { id: 2599, city_name: 'Guarabira', state_id: 15 },
  { id: 2600, city_name: 'Gurinhém', state_id: 15 },
  { id: 2601, city_name: 'Gurjão', state_id: 15 },
  { id: 2602, city_name: 'Ibiara', state_id: 15 },
  { id: 2603, city_name: 'Igaracy', state_id: 15 },
  { id: 2604, city_name: 'Imaculada', state_id: 15 },
  { id: 2605, city_name: 'Ingá', state_id: 15 },
  { id: 2606, city_name: 'Itabaiana', state_id: 15 },
  { id: 2607, city_name: 'Itaporanga', state_id: 15 },
  { id: 2608, city_name: 'Itapororoca', state_id: 15 },
  { id: 2609, city_name: 'Itatuba', state_id: 15 },
  { id: 2610, city_name: 'Jacaraú', state_id: 15 },
  { id: 2611, city_name: 'Jericó', state_id: 15 },
  { id: 2612, city_name: 'João Pessoa', state_id: 15 },
  { id: 2613, city_name: 'Joca Claudino', state_id: 15 },
  { id: 2614, city_name: 'Juarez Távora', state_id: 15 },
  { id: 2615, city_name: 'Juazeirinho', state_id: 15 },
  { id: 2616, city_name: 'Junco do Seridó', state_id: 15 },
  { id: 2617, city_name: 'Juripiranga', state_id: 15 },
  { id: 2618, city_name: 'Juru', state_id: 15 },
  { id: 2619, city_name: 'Lagoa', state_id: 15 },
  { id: 2620, city_name: 'Lagoa de Dentro', state_id: 15 },
  { id: 2621, city_name: 'Lagoa Seca', state_id: 15 },
  { id: 2622, city_name: 'Lastro', state_id: 15 },
  { id: 2623, city_name: 'Livramento', state_id: 15 },
  { id: 2624, city_name: 'Logradouro', state_id: 15 },
  { id: 2625, city_name: 'Lucena', state_id: 15 },
  { id: 2626, city_name: "Mãe d'Água", state_id: 15 },
  { id: 2627, city_name: 'Malta', state_id: 15 },
  { id: 2628, city_name: 'Mamanguape', state_id: 15 },
  { id: 2629, city_name: 'Manaíra', state_id: 15 },
  { id: 2630, city_name: 'Marcação', state_id: 15 },
  { id: 2631, city_name: 'Mari', state_id: 15 },
  { id: 2632, city_name: 'Marizópolis', state_id: 15 },
  { id: 2633, city_name: 'Massaranduba', state_id: 15 },
  { id: 2634, city_name: 'Mataraca', state_id: 15 },
  { id: 2635, city_name: 'Matinhas', state_id: 15 },
  { id: 2636, city_name: 'Mato Grosso', state_id: 15 },
  { id: 2637, city_name: 'Maturéia', state_id: 15 },
  { id: 2638, city_name: 'Mogeiro', state_id: 15 },
  { id: 2639, city_name: 'Montadas', state_id: 15 },
  { id: 2640, city_name: 'Monte Horebe', state_id: 15 },
  { id: 2641, city_name: 'Monteiro', state_id: 15 },
  { id: 2642, city_name: 'Natuba', state_id: 15 },
  { id: 2643, city_name: 'Nazarezinho', state_id: 15 },
  { id: 2644, city_name: 'Nova Floresta', state_id: 15 },
  { id: 2645, city_name: 'Nova Palmeira', state_id: 15 },
  { id: 2646, city_name: "Olho d'Água", state_id: 15 },
  { id: 2647, city_name: 'Olivedos', state_id: 15 },
  { id: 2648, city_name: 'Ouro Velho', state_id: 15 },
  { id: 2649, city_name: 'Parari', state_id: 15 },
  { id: 2650, city_name: 'Passagem', state_id: 15 },
  { id: 2651, city_name: 'Patos', state_id: 15 },
  { id: 2652, city_name: 'Paulista', state_id: 15 },
  { id: 2653, city_name: 'Pedra Lavrada', state_id: 15 },
  { id: 2654, city_name: 'Pedras de Fogo', state_id: 15 },
  { id: 2655, city_name: 'Pedro Régis', state_id: 15 },
  { id: 2656, city_name: 'Piancó', state_id: 15 },
  { id: 2657, city_name: 'Picuí', state_id: 15 },
  { id: 2658, city_name: 'Pilões', state_id: 15 },
  { id: 2659, city_name: 'Pilõezinhos', state_id: 15 },
  { id: 2660, city_name: 'Pirpirituba', state_id: 15 },
  { id: 2661, city_name: 'Pitimbu', state_id: 15 },
  { id: 2662, city_name: 'Pocinhos', state_id: 15 },
  { id: 2663, city_name: 'Poço Dantas', state_id: 15 },
  { id: 2664, city_name: 'Poço de José de Moura', state_id: 15 },
  { id: 2665, city_name: 'Pombal', state_id: 15 },
  { id: 2666, city_name: 'Princesa Isabel', state_id: 15 },
  { id: 2667, city_name: 'Puxinanã', state_id: 15 },
  { id: 2668, city_name: 'Quixaba', state_id: 15 },
  { id: 2669, city_name: 'Remígio', state_id: 15 },
  { id: 2670, city_name: 'Riachão do Bacamarte', state_id: 15 },
  { id: 2671, city_name: 'Riachão do Poço', state_id: 15 },
  { id: 2672, city_name: 'Riacho de Santo Antônio', state_id: 15 },
  { id: 2673, city_name: 'Riacho dos Cavalos', state_id: 15 },
  { id: 2674, city_name: 'Rio Tinto', state_id: 15 },
  { id: 2675, city_name: 'Salgadinho', state_id: 15 },
  { id: 2676, city_name: 'Salgado de São Félix', state_id: 15 },
  { id: 2677, city_name: 'Santa Cecília', state_id: 15 },
  { id: 2678, city_name: 'Santa Cruz', state_id: 15 },
  { id: 2679, city_name: 'Santa Teresinha', state_id: 15 },
  { id: 2680, city_name: 'Santana de Mangueira', state_id: 15 },
  { id: 2681, city_name: 'Santana dos Garrotes', state_id: 15 },
  { id: 2682, city_name: 'Santo André', state_id: 15 },
  { id: 2683, city_name: 'São Bentinho', state_id: 15 },
  { id: 2684, city_name: 'São Domingos do Cariri', state_id: 15 },
  { id: 2685, city_name: 'São João do Cariri', state_id: 15 },
  { id: 2686, city_name: 'São João do Rio do Peixe', state_id: 15 },
  { id: 2687, city_name: 'São João do Tigre', state_id: 15 },
  { id: 2688, city_name: 'São José da Lagoa Tapada', state_id: 15 },
  { id: 2689, city_name: 'São José de Caiana', state_id: 15 },
  { id: 2690, city_name: 'São José de Espinharas', state_id: 15 },
  { id: 2691, city_name: 'São José de Piranhas', state_id: 15 },
  { id: 2692, city_name: 'São José de Princesa', state_id: 15 },
  { id: 2693, city_name: 'São José do Bonfim', state_id: 15 },
  { id: 2694, city_name: 'São José do Brejo do Cruz', state_id: 15 },
  { id: 2695, city_name: 'São José do Sabugi', state_id: 15 },
  { id: 2696, city_name: 'São José dos Cordeiros', state_id: 15 },
  { id: 2697, city_name: 'São José dos Ramos', state_id: 15 },
  { id: 2698, city_name: 'São Mamede', state_id: 15 },
  { id: 2699, city_name: 'São Miguel de Taipu', state_id: 15 },
  { id: 2700, city_name: 'São Sebastião de Lagoa de Roça', state_id: 15 },
  { id: 2701, city_name: 'São Sebastião do Umbuzeiro', state_id: 15 },
  { id: 2702, city_name: 'São Vicente do Seridó', state_id: 15 },
  { id: 2703, city_name: 'Sapé', state_id: 15 },
  { id: 2704, city_name: 'Serra Branca', state_id: 15 },
  { id: 2705, city_name: 'Serra da Raiz', state_id: 15 },
  { id: 2706, city_name: 'Serra Grande', state_id: 15 },
  { id: 2707, city_name: 'Serra Redonda', state_id: 15 },
  { id: 2708, city_name: 'Serraria', state_id: 15 },
  { id: 2709, city_name: 'Sertãozinho', state_id: 15 },
  { id: 2710, city_name: 'Sobrado', state_id: 15 },
  { id: 2711, city_name: 'Solânea', state_id: 15 },
  { id: 2712, city_name: 'Soledade', state_id: 15 },
  { id: 2713, city_name: 'Sossêgo', state_id: 15 },
  { id: 2714, city_name: 'Sousa', state_id: 15 },
  { id: 2715, city_name: 'Sumé', state_id: 15 },
  { id: 2716, city_name: 'Tacima', state_id: 15 },
  { id: 2717, city_name: 'Tavares', state_id: 15 },
  { id: 2718, city_name: 'Teixeira', state_id: 15 },
  { id: 2719, city_name: 'Tenório', state_id: 15 },
  { id: 2720, city_name: 'Triunfo', state_id: 15 },
  { id: 2721, city_name: 'Uiraúna', state_id: 15 },
  { id: 2722, city_name: 'Umbuzeiro', state_id: 15 },
  { id: 2723, city_name: 'Várzea', state_id: 15 },
  { id: 2724, city_name: 'Vieirópolis', state_id: 15 },
  { id: 2725, city_name: 'Vista Serrana', state_id: 15 },
  { id: 2726, city_name: 'Zabelê', state_id: 15 },
  { id: 2727, city_name: 'Abatiá', state_id: 16 },
  { id: 2728, city_name: 'Adrianópolis', state_id: 16 },
  { id: 2729, city_name: 'Agudos do Sul', state_id: 16 },
  { id: 2730, city_name: 'Almirante Tamandaré', state_id: 16 },
  { id: 2731, city_name: 'Altamira do Paraná', state_id: 16 },
  { id: 2732, city_name: 'Alto Paraíso', state_id: 16 },
  { id: 2733, city_name: 'Alto Paraná', state_id: 16 },
  { id: 2734, city_name: 'Alto Piquiri', state_id: 16 },
  { id: 2735, city_name: 'Altônia', state_id: 16 },
  { id: 2736, city_name: 'Alvorada do Sul', state_id: 16 },
  { id: 2737, city_name: 'Amaporã', state_id: 16 },
  { id: 2738, city_name: 'Ampére', state_id: 16 },
  { id: 2739, city_name: 'Anahy', state_id: 16 },
  { id: 2740, city_name: 'Andirá', state_id: 16 },
  { id: 2741, city_name: 'Ângulo', state_id: 16 },
  { id: 2742, city_name: 'Antonina', state_id: 16 },
  { id: 2743, city_name: 'Antônio Olinto', state_id: 16 },
  { id: 2744, city_name: 'Apucarana', state_id: 16 },
  { id: 2745, city_name: 'Arapongas', state_id: 16 },
  { id: 2746, city_name: 'Arapoti', state_id: 16 },
  { id: 2747, city_name: 'Arapuã', state_id: 16 },
  { id: 2748, city_name: 'Araucária', state_id: 16 },
  { id: 2749, city_name: 'Ariranha do Ivaí', state_id: 16 },
  { id: 2750, city_name: 'Assaí', state_id: 16 },
  { id: 2751, city_name: 'Assis Chateaubriand', state_id: 16 },
  { id: 2752, city_name: 'Astorga', state_id: 16 },
  { id: 2753, city_name: 'Balsa Nova', state_id: 16 },
  { id: 2754, city_name: 'Barbosa Ferraz', state_id: 16 },
  { id: 2755, city_name: 'Barra do Jacaré', state_id: 16 },
  { id: 2756, city_name: 'Barracão', state_id: 16 },
  { id: 2757, city_name: 'Bela Vista da Caroba', state_id: 16 },
  { id: 2758, city_name: 'Bela Vista do Paraíso', state_id: 16 },
  { id: 2759, city_name: 'Bituruna', state_id: 16 },
  { id: 2760, city_name: 'Boa Esperança do Iguaçu', state_id: 16 },
  { id: 2761, city_name: 'Boa Ventura de São Roque', state_id: 16 },
  { id: 2762, city_name: 'Boa Vista da Aparecida', state_id: 16 },
  { id: 2763, city_name: 'Bocaiúva do Sul', state_id: 16 },
  { id: 2764, city_name: 'Bom Jesus do Sul', state_id: 16 },
  { id: 2765, city_name: 'Bom Sucesso do Sul', state_id: 16 },
  { id: 2766, city_name: 'Borrazópolis', state_id: 16 },
  { id: 2767, city_name: 'Braganey', state_id: 16 },
  { id: 2768, city_name: 'Brasilândia do Sul', state_id: 16 },
  { id: 2769, city_name: 'Cafeara', state_id: 16 },
  { id: 2770, city_name: 'Cafelândia', state_id: 16 },
  { id: 2771, city_name: 'Cafezal do Sul', state_id: 16 },
  { id: 2772, city_name: 'Califórnia', state_id: 16 },
  { id: 2773, city_name: 'Cambará', state_id: 16 },
  { id: 2774, city_name: 'Cambé', state_id: 16 },
  { id: 2775, city_name: 'Cambira', state_id: 16 },
  { id: 2776, city_name: 'Campina da Lagoa', state_id: 16 },
  { id: 2777, city_name: 'Campina do Simão', state_id: 16 },
  { id: 2778, city_name: 'Campina Grande do Sul', state_id: 16 },
  { id: 2779, city_name: 'Campo Bonito', state_id: 16 },
  { id: 2780, city_name: 'Campo do Tenente', state_id: 16 },
  { id: 2781, city_name: 'Campo Largo', state_id: 16 },
  { id: 2782, city_name: 'Campo Magro', state_id: 16 },
  { id: 2783, city_name: 'Campo Mourão', state_id: 16 },
  { id: 2784, city_name: 'Cândido de Abreu', state_id: 16 },
  { id: 2785, city_name: 'Candói', state_id: 16 },
  { id: 2786, city_name: 'Capitão Leônidas Marques', state_id: 16 },
  { id: 2787, city_name: 'Carambeí', state_id: 16 },
  { id: 2788, city_name: 'Carlópolis', state_id: 16 },
  { id: 2789, city_name: 'Castro', state_id: 16 },
  { id: 2790, city_name: 'Catanduvas', state_id: 16 },
  { id: 2791, city_name: 'Centenário do Sul', state_id: 16 },
  { id: 2792, city_name: 'Cerro Azul', state_id: 16 },
  { id: 2793, city_name: 'Céu Azul', state_id: 16 },
  { id: 2794, city_name: 'Chopinzinho', state_id: 16 },
  { id: 2795, city_name: 'Cianorte', state_id: 16 },
  { id: 2796, city_name: 'Cidade Gaúcha', state_id: 16 },
  { id: 2797, city_name: 'Clevelândia', state_id: 16 },
  { id: 2798, city_name: 'Colombo', state_id: 16 },
  { id: 2799, city_name: 'Colorado', state_id: 16 },
  { id: 2800, city_name: 'Congonhinhas', state_id: 16 },
  { id: 2801, city_name: 'Conselheiro Mairinck', state_id: 16 },
  { id: 2802, city_name: 'Contenda', state_id: 16 },
  { id: 2803, city_name: 'Corbélia', state_id: 16 },
  { id: 2804, city_name: 'Cornélio Procópio', state_id: 16 },
  { id: 2805, city_name: 'Coronel Domingos Soares', state_id: 16 },
  { id: 2806, city_name: 'Coronel Vivida', state_id: 16 },
  { id: 2807, city_name: 'Corumbataí do Sul', state_id: 16 },
  { id: 2808, city_name: 'Cruz Machado', state_id: 16 },
  { id: 2809, city_name: 'Cruzeiro do Iguaçu', state_id: 16 },
  { id: 2810, city_name: 'Cruzeiro do Oeste', state_id: 16 },
  { id: 2811, city_name: 'Cruzmaltina', state_id: 16 },
  { id: 2812, city_name: 'Curitiba', state_id: 16 },
  { id: 2813, city_name: 'Curiúva', state_id: 16 },
  { id: 2814, city_name: 'Diamante do Norte', state_id: 16 },
  { id: 2815, city_name: 'Diamante do Sul', state_id: 16 },
  { id: 2816, city_name: "Diamante D'Oeste", state_id: 16 },
  { id: 2817, city_name: 'Dois Vizinhos', state_id: 16 },
  { id: 2818, city_name: 'Doutor Camargo', state_id: 16 },
  { id: 2819, city_name: 'Doutor Ulysses', state_id: 16 },
  { id: 2820, city_name: 'Enéas Marques', state_id: 16 },
  { id: 2821, city_name: 'Engenheiro Beltrão', state_id: 16 },
  { id: 2822, city_name: 'Entre Rios do Oeste', state_id: 16 },
  { id: 2823, city_name: 'Esperança Nova', state_id: 16 },
  { id: 2824, city_name: 'Espigão Alto do Iguaçu', state_id: 16 },
  { id: 2825, city_name: 'Farol', state_id: 16 },
  { id: 2826, city_name: 'Faxinal', state_id: 16 },
  { id: 2827, city_name: 'Fazenda Rio Grande', state_id: 16 },
  { id: 2828, city_name: 'Fênix', state_id: 16 },
  { id: 2829, city_name: 'Fernandes Pinheiro', state_id: 16 },
  { id: 2830, city_name: 'Figueira', state_id: 16 },
  { id: 2831, city_name: 'Flor da Serra do Sul', state_id: 16 },
  { id: 2832, city_name: 'Floraí', state_id: 16 },
  { id: 2833, city_name: 'Floresta', state_id: 16 },
  { id: 2834, city_name: 'Florestópolis', state_id: 16 },
  { id: 2835, city_name: 'Flórida', state_id: 16 },
  { id: 2836, city_name: 'Formosa do Oeste', state_id: 16 },
  { id: 2837, city_name: 'Foz do Iguaçu', state_id: 16 },
  { id: 2838, city_name: 'Foz do Jordão', state_id: 16 },
  { id: 2839, city_name: 'Francisco Alves', state_id: 16 },
  { id: 2840, city_name: 'Francisco Beltrão', state_id: 16 },
  { id: 2841, city_name: 'Godoy Moreira', state_id: 16 },
  { id: 2842, city_name: 'Goioerê', state_id: 16 },
  { id: 2843, city_name: 'Goioxim', state_id: 16 },
  { id: 2844, city_name: 'Grandes Rios', state_id: 16 },
  { id: 2845, city_name: 'Guaíra', state_id: 16 },
  { id: 2846, city_name: 'Guairaçá', state_id: 16 },
  { id: 2847, city_name: 'Guamiranga', state_id: 16 },
  { id: 2848, city_name: 'Guapirama', state_id: 16 },
  { id: 2849, city_name: 'Guaporema', state_id: 16 },
  { id: 2850, city_name: 'Guaraci', state_id: 16 },
  { id: 2851, city_name: 'Guaraniaçu', state_id: 16 },
  { id: 2852, city_name: 'Guarapuava', state_id: 16 },
  { id: 2853, city_name: 'Guaraqueçaba', state_id: 16 },
  { id: 2854, city_name: 'Guaratuba', state_id: 16 },
  { id: 2855, city_name: 'Honório Serpa', state_id: 16 },
  { id: 2856, city_name: 'Ibaiti', state_id: 16 },
  { id: 2857, city_name: 'Ibema', state_id: 16 },
  { id: 2858, city_name: 'Ibiporã', state_id: 16 },
  { id: 2859, city_name: 'Icaraíma', state_id: 16 },
  { id: 2860, city_name: 'Iguaraçu', state_id: 16 },
  { id: 2861, city_name: 'Imbaú', state_id: 16 },
  { id: 2862, city_name: 'Imbituva', state_id: 16 },
  { id: 2863, city_name: 'Inácio Martins', state_id: 16 },
  { id: 2864, city_name: 'Inajá', state_id: 16 },
  { id: 2865, city_name: 'Ipiranga', state_id: 16 },
  { id: 2866, city_name: 'Iporã', state_id: 16 },
  { id: 2867, city_name: 'Iracema do Oeste', state_id: 16 },
  { id: 2868, city_name: 'Irati', state_id: 16 },
  { id: 2869, city_name: 'Iretama', state_id: 16 },
  { id: 2870, city_name: 'Itaguajé', state_id: 16 },
  { id: 2871, city_name: 'Itaipulândia', state_id: 16 },
  { id: 2872, city_name: 'Itambaracá', state_id: 16 },
  { id: 2873, city_name: "Itapejara d'Oeste", state_id: 16 },
  { id: 2874, city_name: 'Itaperuçu', state_id: 16 },
  { id: 2875, city_name: 'Itaúna do Sul', state_id: 16 },
  { id: 2876, city_name: 'Ivaí', state_id: 16 },
  { id: 2877, city_name: 'Ivaiporã', state_id: 16 },
  { id: 2878, city_name: 'Ivaté', state_id: 16 },
  { id: 2879, city_name: 'Ivatuba', state_id: 16 },
  { id: 2880, city_name: 'Jaboti', state_id: 16 },
  { id: 2881, city_name: 'Jacarezinho', state_id: 16 },
  { id: 2882, city_name: 'Jaguapitã', state_id: 16 },
  { id: 2883, city_name: 'Jaguariaíva', state_id: 16 },
  { id: 2884, city_name: 'Jandaia do Sul', state_id: 16 },
  { id: 2885, city_name: 'Janiópolis', state_id: 16 },
  { id: 2886, city_name: 'Japira', state_id: 16 },
  { id: 2887, city_name: 'Jardim Alegre', state_id: 16 },
  { id: 2888, city_name: 'Jardim Olinda', state_id: 16 },
  { id: 2889, city_name: 'Jataizinho', state_id: 16 },
  { id: 2890, city_name: 'Jesuítas', state_id: 16 },
  { id: 2891, city_name: 'Joaquim Távora', state_id: 16 },
  { id: 2892, city_name: 'Jundiaí do Sul', state_id: 16 },
  { id: 2893, city_name: 'Juranda', state_id: 16 },
  { id: 2894, city_name: 'Kaloré', state_id: 16 },
  { id: 2895, city_name: 'Lapa', state_id: 16 },
  { id: 2896, city_name: 'Laranjeiras do Sul', state_id: 16 },
  { id: 2897, city_name: 'Leópolis', state_id: 16 },
  { id: 2898, city_name: 'Lidianópolis', state_id: 16 },
  { id: 2899, city_name: 'Lindoeste', state_id: 16 },
  { id: 2900, city_name: 'Loanda', state_id: 16 },
  { id: 2901, city_name: 'Lobato', state_id: 16 },
  { id: 2902, city_name: 'Londrina', state_id: 16 },
  { id: 2903, city_name: 'Luiziana', state_id: 16 },
  { id: 2904, city_name: 'Lunardelli', state_id: 16 },
  { id: 2905, city_name: 'Lupionópolis', state_id: 16 },
  { id: 2906, city_name: 'Mallet', state_id: 16 },
  { id: 2907, city_name: 'Mamborê', state_id: 16 },
  { id: 2908, city_name: 'Mandaguaçu', state_id: 16 },
  { id: 2909, city_name: 'Mandaguari', state_id: 16 },
  { id: 2910, city_name: 'Mandirituba', state_id: 16 },
  { id: 2911, city_name: 'Manfrinópolis', state_id: 16 },
  { id: 2912, city_name: 'Mangueirinha', state_id: 16 },
  { id: 2913, city_name: 'Manoel Ribas', state_id: 16 },
  { id: 2914, city_name: 'Marechal Cândido Rondon', state_id: 16 },
  { id: 2915, city_name: 'Maria Helena', state_id: 16 },
  { id: 2916, city_name: 'Marialva', state_id: 16 },
  { id: 2917, city_name: 'Marilândia do Sul', state_id: 16 },
  { id: 2918, city_name: 'Marilena', state_id: 16 },
  { id: 2919, city_name: 'Mariluz', state_id: 16 },
  { id: 2920, city_name: 'Maringá', state_id: 16 },
  { id: 2921, city_name: 'Mariópolis', state_id: 16 },
  { id: 2922, city_name: 'Maripá', state_id: 16 },
  { id: 2923, city_name: 'Marmeleiro', state_id: 16 },
  { id: 2924, city_name: 'Marquinho', state_id: 16 },
  { id: 2925, city_name: 'Marumbi', state_id: 16 },
  { id: 2926, city_name: 'Matelândia', state_id: 16 },
  { id: 2927, city_name: 'Matinhos', state_id: 16 },
  { id: 2928, city_name: 'Mato Rico', state_id: 16 },
  { id: 2929, city_name: 'Mauá da Serra', state_id: 16 },
  { id: 2930, city_name: 'Medianeira', state_id: 16 },
  { id: 2931, city_name: 'Mercedes', state_id: 16 },
  { id: 2932, city_name: 'Miraselva', state_id: 16 },
  { id: 2933, city_name: 'Missal', state_id: 16 },
  { id: 2934, city_name: 'Moreira Sales', state_id: 16 },
  { id: 2935, city_name: 'Morretes', state_id: 16 },
  { id: 2936, city_name: 'Munhoz de Melo', state_id: 16 },
  { id: 2937, city_name: 'Nossa Senhora das Graças', state_id: 16 },
  { id: 2938, city_name: 'Nova Aliança do Ivaí', state_id: 16 },
  { id: 2939, city_name: 'Nova América da Colina', state_id: 16 },
  { id: 2940, city_name: 'Nova Cantu', state_id: 16 },
  { id: 2941, city_name: 'Nova Esperança', state_id: 16 },
  { id: 2942, city_name: 'Nova Esperança do Sudoeste', state_id: 16 },
  { id: 2943, city_name: 'Nova Laranjeiras', state_id: 16 },
  { id: 2944, city_name: 'Nova Londrina', state_id: 16 },
  { id: 2945, city_name: 'Nova Prata do Iguaçu', state_id: 16 },
  { id: 2946, city_name: 'Nova Santa Bárbara', state_id: 16 },
  { id: 2947, city_name: 'Nova Santa Rosa', state_id: 16 },
  { id: 2948, city_name: 'Nova Tebas', state_id: 16 },
  { id: 2949, city_name: 'Novo Itacolomi', state_id: 16 },
  { id: 2950, city_name: 'Ortigueira', state_id: 16 },
  { id: 2951, city_name: 'Ourizona', state_id: 16 },
  { id: 2952, city_name: 'Ouro Verde do Oeste', state_id: 16 },
  { id: 2953, city_name: 'Paiçandu', state_id: 16 },
  { id: 2954, city_name: 'Palmas', state_id: 16 },
  { id: 2955, city_name: 'Palmeira', state_id: 16 },
  { id: 2956, city_name: 'Palmital', state_id: 16 },
  { id: 2957, city_name: 'Palotina', state_id: 16 },
  { id: 2958, city_name: 'Paraíso do Norte', state_id: 16 },
  { id: 2959, city_name: 'Paranacity', state_id: 16 },
  { id: 2960, city_name: 'Paranaguá', state_id: 16 },
  { id: 2961, city_name: 'Paranapoema', state_id: 16 },
  { id: 2962, city_name: 'Paranavaí', state_id: 16 },
  { id: 2963, city_name: 'Pato Bragado', state_id: 16 },
  { id: 2964, city_name: 'Pato Branco', state_id: 16 },
  { id: 2965, city_name: 'Paula Freitas', state_id: 16 },
  { id: 2966, city_name: 'Paulo Frontin', state_id: 16 },
  { id: 2967, city_name: 'Peabiru', state_id: 16 },
  { id: 2968, city_name: 'Perobal', state_id: 16 },
  { id: 2969, city_name: 'Pérola', state_id: 16 },
  { id: 2970, city_name: "Pérola d'Oeste", state_id: 16 },
  { id: 2971, city_name: 'Piên', state_id: 16 },
  { id: 2972, city_name: 'Pinhais', state_id: 16 },
  { id: 2973, city_name: 'Pinhal de São Bento', state_id: 16 },
  { id: 2974, city_name: 'Pinhalão', state_id: 16 },
  { id: 2975, city_name: 'Pinhão', state_id: 16 },
  { id: 2976, city_name: 'Piraí do Sul', state_id: 16 },
  { id: 2977, city_name: 'Piraquara', state_id: 16 },
  { id: 2978, city_name: 'Pitanga', state_id: 16 },
  { id: 2979, city_name: 'Pitangueiras', state_id: 16 },
  { id: 2980, city_name: 'Planaltina do Paraná', state_id: 16 },
  { id: 2981, city_name: 'Ponta Grossa', state_id: 16 },
  { id: 2982, city_name: 'Pontal do Paraná', state_id: 16 },
  { id: 2983, city_name: 'Porecatu', state_id: 16 },
  { id: 2984, city_name: 'Porto Amazonas', state_id: 16 },
  { id: 2985, city_name: 'Porto Barreiro', state_id: 16 },
  { id: 2986, city_name: 'Porto Rico', state_id: 16 },
  { id: 2987, city_name: 'Porto Vitória', state_id: 16 },
  { id: 2988, city_name: 'Prado Ferreira', state_id: 16 },
  { id: 2989, city_name: 'Pranchita', state_id: 16 },
  { id: 2990, city_name: 'Presidente Castelo Branco', state_id: 16 },
  { id: 2991, city_name: 'Primeiro de Maio', state_id: 16 },
  { id: 2992, city_name: 'Prudentópolis', state_id: 16 },
  { id: 2993, city_name: 'Quarto Centenário', state_id: 16 },
  { id: 2994, city_name: 'Quatiguá', state_id: 16 },
  { id: 2995, city_name: 'Quatro Barras', state_id: 16 },
  { id: 2996, city_name: 'Quatro Pontes', state_id: 16 },
  { id: 2997, city_name: 'Quedas do Iguaçu', state_id: 16 },
  { id: 2998, city_name: 'Querência do Norte', state_id: 16 },
  { id: 2999, city_name: 'Quinta do Sol', state_id: 16 },
  { id: 3000, city_name: 'Quitandinha', state_id: 16 },
  { id: 3001, city_name: 'Ramilândia', state_id: 16 },
  { id: 3002, city_name: 'Rancho Alegre', state_id: 16 },
  { id: 3003, city_name: "Rancho Alegre D'Oeste", state_id: 16 },
  { id: 3004, city_name: 'Realeza', state_id: 16 },
  { id: 3005, city_name: 'Rebouças', state_id: 16 },
  { id: 3006, city_name: 'Renascença', state_id: 16 },
  { id: 3007, city_name: 'Reserva', state_id: 16 },
  { id: 3008, city_name: 'Reserva do Iguaçu', state_id: 16 },
  { id: 3009, city_name: 'Ribeirão Claro', state_id: 16 },
  { id: 3010, city_name: 'Ribeirão do Pinhal', state_id: 16 },
  { id: 3011, city_name: 'Rio Azul', state_id: 16 },
  { id: 3012, city_name: 'Rio Bom', state_id: 16 },
  { id: 3013, city_name: 'Rio Bonito do Iguaçu', state_id: 16 },
  { id: 3014, city_name: 'Rio Branco do Ivaí', state_id: 16 },
  { id: 3015, city_name: 'Rio Branco do Sul', state_id: 16 },
  { id: 3016, city_name: 'Rolândia', state_id: 16 },
  { id: 3017, city_name: 'Roncador', state_id: 16 },
  { id: 3018, city_name: 'Rondon', state_id: 16 },
  { id: 3019, city_name: 'Rosário do Ivaí', state_id: 16 },
  { id: 3020, city_name: 'Sabáudia', state_id: 16 },
  { id: 3021, city_name: 'Salgado Filho', state_id: 16 },
  { id: 3022, city_name: 'Salto do Itararé', state_id: 16 },
  { id: 3023, city_name: 'Salto do Lontra', state_id: 16 },
  { id: 3024, city_name: 'Santa Amélia', state_id: 16 },
  { id: 3025, city_name: 'Santa Cecília do Pavão', state_id: 16 },
  { id: 3026, city_name: 'Santa Cruz de Monte Castelo', state_id: 16 },
  { id: 3027, city_name: 'Santa Fé', state_id: 16 },
  { id: 3028, city_name: 'Santa Isabel do Ivaí', state_id: 16 },
  { id: 3029, city_name: 'Santa Izabel do Oeste', state_id: 16 },
  { id: 3030, city_name: 'Santa Lúcia', state_id: 16 },
  { id: 3031, city_name: 'Santa Maria do Oeste', state_id: 16 },
  { id: 3032, city_name: 'Santa Mariana', state_id: 16 },
  { id: 3033, city_name: 'Santa Mônica', state_id: 16 },
  { id: 3034, city_name: 'Santa Tereza do Oeste', state_id: 16 },
  { id: 3035, city_name: 'Santa Terezinha de Itaipu', state_id: 16 },
  { id: 3036, city_name: 'Santana do Itararé', state_id: 16 },
  { id: 3037, city_name: 'Santo Antônio da Platina', state_id: 16 },
  { id: 3038, city_name: 'Santo Antônio do Caiuá', state_id: 16 },
  { id: 3039, city_name: 'Santo Antônio do Paraíso', state_id: 16 },
  { id: 3040, city_name: 'Santo Antônio do Sudoeste', state_id: 16 },
  { id: 3041, city_name: 'Santo Inácio', state_id: 16 },
  { id: 3042, city_name: 'São Carlos do Ivaí', state_id: 16 },
  { id: 3043, city_name: 'São Jerônimo da Serra', state_id: 16 },
  { id: 3044, city_name: 'São João', state_id: 16 },
  { id: 3045, city_name: 'São João do Caiuá', state_id: 16 },
  { id: 3046, city_name: 'São João do Ivaí', state_id: 16 },
  { id: 3047, city_name: 'São João do Triunfo', state_id: 16 },
  { id: 3048, city_name: 'São Jorge do Ivaí', state_id: 16 },
  { id: 3049, city_name: 'São Jorge do Patrocínio', state_id: 16 },
  { id: 3050, city_name: "São Jorge d'Oeste", state_id: 16 },
  { id: 3051, city_name: 'São José da Boa Vista', state_id: 16 },
  { id: 3052, city_name: 'São José das Palmeiras', state_id: 16 },
  { id: 3053, city_name: 'São José dos Pinhais', state_id: 16 },
  { id: 3054, city_name: 'São Manoel do Paraná', state_id: 16 },
  { id: 3055, city_name: 'São Mateus do Sul', state_id: 16 },
  { id: 3056, city_name: 'São Miguel do Iguaçu', state_id: 16 },
  { id: 3057, city_name: 'São Pedro do Iguaçu', state_id: 16 },
  { id: 3058, city_name: 'São Pedro do Ivaí', state_id: 16 },
  { id: 3059, city_name: 'São Pedro do Paraná', state_id: 16 },
  { id: 3060, city_name: 'São Sebastião da Amoreira', state_id: 16 },
  { id: 3061, city_name: 'São Tomé', state_id: 16 },
  { id: 3062, city_name: 'Sapopema', state_id: 16 },
  { id: 3063, city_name: 'Sarandi', state_id: 16 },
  { id: 3064, city_name: 'Saudade do Iguaçu', state_id: 16 },
  { id: 3065, city_name: 'Sengés', state_id: 16 },
  { id: 3066, city_name: 'Serranópolis do Iguaçu', state_id: 16 },
  { id: 3067, city_name: 'Sertaneja', state_id: 16 },
  { id: 3068, city_name: 'Sertanópolis', state_id: 16 },
  { id: 3069, city_name: 'Siqueira Campos', state_id: 16 },
  { id: 3070, city_name: 'Sulina', state_id: 16 },
  { id: 3071, city_name: 'Tamarana', state_id: 16 },
  { id: 3072, city_name: 'Tamboara', state_id: 16 },
  { id: 3073, city_name: 'Tapejara', state_id: 16 },
  { id: 3074, city_name: 'Teixeira Soares', state_id: 16 },
  { id: 3075, city_name: 'Telêmaco Borba', state_id: 16 },
  { id: 3076, city_name: 'Terra Boa', state_id: 16 },
  { id: 3077, city_name: 'Terra Rica', state_id: 16 },
  { id: 3078, city_name: 'Terra Roxa', state_id: 16 },
  { id: 3079, city_name: 'Tibagi', state_id: 16 },
  { id: 3080, city_name: 'Tijucas do Sul', state_id: 16 },
  { id: 3081, city_name: 'Tomazina', state_id: 16 },
  { id: 3082, city_name: 'Três Barras do Paraná', state_id: 16 },
  { id: 3083, city_name: 'Tunas do Paraná', state_id: 16 },
  { id: 3084, city_name: 'Tuneiras do Oeste', state_id: 16 },
  { id: 3085, city_name: 'Tupãssi', state_id: 16 },
  { id: 3086, city_name: 'Turvo', state_id: 16 },
  { id: 3087, city_name: 'Ubiratã', state_id: 16 },
  { id: 3088, city_name: 'Umuarama', state_id: 16 },
  { id: 3089, city_name: 'União da Vitória', state_id: 16 },
  { id: 3090, city_name: 'Uniflor', state_id: 16 },
  { id: 3091, city_name: 'Uraí', state_id: 16 },
  { id: 3092, city_name: 'Ventania', state_id: 16 },
  { id: 3093, city_name: 'Vera Cruz do Oeste', state_id: 16 },
  { id: 3094, city_name: 'Verê', state_id: 16 },
  { id: 3095, city_name: 'Virmond', state_id: 16 },
  { id: 3096, city_name: 'Vitorino', state_id: 16 },
  { id: 3097, city_name: 'Xambrê', state_id: 16 },
  { id: 3098, city_name: 'Abreu e Lima', state_id: 17 },
  { id: 3099, city_name: 'Afogados da Ingazeira', state_id: 17 },
  { id: 3100, city_name: 'Afrânio', state_id: 17 },
  { id: 3101, city_name: 'Agrestina', state_id: 17 },
  { id: 3102, city_name: 'Água Preta', state_id: 17 },
  { id: 3103, city_name: 'Águas Belas', state_id: 17 },
  { id: 3104, city_name: 'Aliança', state_id: 17 },
  { id: 3105, city_name: 'Altinho', state_id: 17 },
  { id: 3106, city_name: 'Amaraji', state_id: 17 },
  { id: 3107, city_name: 'Angelim', state_id: 17 },
  { id: 3108, city_name: 'Araçoiaba', state_id: 17 },
  { id: 3109, city_name: 'Araripina', state_id: 17 },
  { id: 3110, city_name: 'Arcoverde', state_id: 17 },
  { id: 3111, city_name: 'Barra de Guabiraba', state_id: 17 },
  { id: 3112, city_name: 'Barreiros', state_id: 17 },
  { id: 3113, city_name: 'Belém de Maria', state_id: 17 },
  { id: 3114, city_name: 'Belém do São Francisco', state_id: 17 },
  { id: 3115, city_name: 'Belo Jardim', state_id: 17 },
  { id: 3116, city_name: 'Betânia', state_id: 17 },
  { id: 3117, city_name: 'Bezerros', state_id: 17 },
  { id: 3118, city_name: 'Bodocó', state_id: 17 },
  { id: 3119, city_name: 'Bom Conselho', state_id: 17 },
  { id: 3120, city_name: 'Brejão', state_id: 17 },
  { id: 3121, city_name: 'Brejinho', state_id: 17 },
  { id: 3122, city_name: 'Brejo da Madre de Deus', state_id: 17 },
  { id: 3123, city_name: 'Buenos Aires', state_id: 17 },
  { id: 3124, city_name: 'Buíque', state_id: 17 },
  { id: 3125, city_name: 'Cabo de Santo Agostinho', state_id: 17 },
  { id: 3126, city_name: 'Cabrobó', state_id: 17 },
  { id: 3127, city_name: 'Cachoeirinha', state_id: 17 },
  { id: 3128, city_name: 'Caetés', state_id: 17 },
  { id: 3129, city_name: 'Calçado', state_id: 17 },
  { id: 3130, city_name: 'Calumbi', state_id: 17 },
  { id: 3131, city_name: 'Camaragibe', state_id: 17 },
  { id: 3132, city_name: 'Camocim de São Félix', state_id: 17 },
  { id: 3133, city_name: 'Camutanga', state_id: 17 },
  { id: 3134, city_name: 'Canhotinho', state_id: 17 },
  { id: 3135, city_name: 'Capoeiras', state_id: 17 },
  { id: 3136, city_name: 'Carnaíba', state_id: 17 },
  { id: 3137, city_name: 'Carnaubeira da Penha', state_id: 17 },
  { id: 3138, city_name: 'Carpina', state_id: 17 },
  { id: 3139, city_name: 'Caruaru', state_id: 17 },
  { id: 3140, city_name: 'Casinhas', state_id: 17 },
  { id: 3141, city_name: 'Catende', state_id: 17 },
  { id: 3142, city_name: 'Chã de Alegria', state_id: 17 },
  { id: 3143, city_name: 'Chã Grande', state_id: 17 },
  { id: 3144, city_name: 'Correntes', state_id: 17 },
  { id: 3145, city_name: 'Cortês', state_id: 17 },
  { id: 3146, city_name: 'Cumaru', state_id: 17 },
  { id: 3147, city_name: 'Cupira', state_id: 17 },
  { id: 3148, city_name: 'Custódia', state_id: 17 },
  { id: 3149, city_name: 'Dormentes', state_id: 17 },
  { id: 3150, city_name: 'Escada', state_id: 17 },
  { id: 3151, city_name: 'Exu', state_id: 17 },
  { id: 3152, city_name: 'Feira Nova', state_id: 17 },
  { id: 3153, city_name: 'Fernando de Noronha', state_id: 17 },
  { id: 3154, city_name: 'Ferreiros', state_id: 17 },
  { id: 3155, city_name: 'Flores', state_id: 17 },
  { id: 3156, city_name: 'Frei Miguelinho', state_id: 17 },
  { id: 3157, city_name: 'Gameleira', state_id: 17 },
  { id: 3158, city_name: 'Garanhuns', state_id: 17 },
  { id: 3159, city_name: 'Glória do Goitá', state_id: 17 },
  { id: 3160, city_name: 'Goiana', state_id: 17 },
  { id: 3161, city_name: 'Granito', state_id: 17 },
  { id: 3162, city_name: 'Gravatá', state_id: 17 },
  { id: 3163, city_name: 'Iati', state_id: 17 },
  { id: 3164, city_name: 'Ibimirim', state_id: 17 },
  { id: 3165, city_name: 'Ibirajuba', state_id: 17 },
  { id: 3166, city_name: 'Igarassu', state_id: 17 },
  { id: 3167, city_name: 'Iguaracy', state_id: 17 },
  { id: 3168, city_name: 'Ilha de Itamaracá', state_id: 17 },
  { id: 3169, city_name: 'Ingazeira', state_id: 17 },
  { id: 3170, city_name: 'Ipojuca', state_id: 17 },
  { id: 3171, city_name: 'Ipubi', state_id: 17 },
  { id: 3172, city_name: 'Itacuruba', state_id: 17 },
  { id: 3173, city_name: 'Itaíba', state_id: 17 },
  { id: 3174, city_name: 'Itapetim', state_id: 17 },
  { id: 3175, city_name: 'Itapissuma', state_id: 17 },
  { id: 3176, city_name: 'Itaquitinga', state_id: 17 },
  { id: 3177, city_name: 'Jaboatão dos Guararapes', state_id: 17 },
  { id: 3178, city_name: 'Jaqueira', state_id: 17 },
  { id: 3179, city_name: 'Jataúba', state_id: 17 },
  { id: 3180, city_name: 'João Alfredo', state_id: 17 },
  { id: 3181, city_name: 'Joaquim Nabuco', state_id: 17 },
  { id: 3182, city_name: 'Jucati', state_id: 17 },
  { id: 3183, city_name: 'Jupi', state_id: 17 },
  { id: 3184, city_name: 'Jurema', state_id: 17 },
  { id: 3185, city_name: 'Lagoa de Itaenga', state_id: 17 },
  { id: 3186, city_name: 'Lagoa do Carro', state_id: 17 },
  { id: 3187, city_name: 'Lagoa do Ouro', state_id: 17 },
  { id: 3188, city_name: 'Lagoa dos Gatos', state_id: 17 },
  { id: 3189, city_name: 'Lajedo', state_id: 17 },
  { id: 3190, city_name: 'Limoeiro', state_id: 17 },
  { id: 3191, city_name: 'Macaparana', state_id: 17 },
  { id: 3192, city_name: 'Machados', state_id: 17 },
  { id: 3193, city_name: 'Manari', state_id: 17 },
  { id: 3194, city_name: 'Maraial', state_id: 17 },
  { id: 3195, city_name: 'Mirandiba', state_id: 17 },
  { id: 3196, city_name: 'Moreilândia', state_id: 17 },
  { id: 3197, city_name: 'Moreno', state_id: 17 },
  { id: 3198, city_name: 'Nazaré da Mata', state_id: 17 },
  { id: 3199, city_name: 'Olinda', state_id: 17 },
  { id: 3200, city_name: 'Orobó', state_id: 17 },
  { id: 3201, city_name: 'Orocó', state_id: 17 },
  { id: 3202, city_name: 'Ouricuri', state_id: 17 },
  { id: 3203, city_name: 'Palmares', state_id: 17 },
  { id: 3204, city_name: 'Palmeirina', state_id: 17 },
  { id: 3205, city_name: 'Panelas', state_id: 17 },
  { id: 3206, city_name: 'Paranatama', state_id: 17 },
  { id: 3207, city_name: 'Parnamirim', state_id: 17 },
  { id: 3208, city_name: 'Passira', state_id: 17 },
  { id: 3209, city_name: 'Paudalho', state_id: 17 },
  { id: 3210, city_name: 'Pedra', state_id: 17 },
  { id: 3211, city_name: 'Pesqueira', state_id: 17 },
  { id: 3212, city_name: 'Petrolândia', state_id: 17 },
  { id: 3213, city_name: 'Petrolina', state_id: 17 },
  { id: 3214, city_name: 'Poção', state_id: 17 },
  { id: 3215, city_name: 'Pombos', state_id: 17 },
  { id: 3216, city_name: 'Quipapá', state_id: 17 },
  { id: 3217, city_name: 'Recife', state_id: 17 },
  { id: 3218, city_name: 'Riacho das Almas', state_id: 17 },
  { id: 3219, city_name: 'Ribeirão', state_id: 17 },
  { id: 3220, city_name: 'Rio Formoso', state_id: 17 },
  { id: 3221, city_name: 'Sairé', state_id: 17 },
  { id: 3222, city_name: 'Salgueiro', state_id: 17 },
  { id: 3223, city_name: 'Saloá', state_id: 17 },
  { id: 3224, city_name: 'Sanharó', state_id: 17 },
  { id: 3225, city_name: 'Santa Cruz da Baixa Verde', state_id: 17 },
  { id: 3226, city_name: 'Santa Cruz do Capibaribe', state_id: 17 },
  { id: 3227, city_name: 'Santa Filomena', state_id: 17 },
  { id: 3228, city_name: 'Santa Maria da Boa Vista', state_id: 17 },
  { id: 3229, city_name: 'Santa Maria do Cambucá', state_id: 17 },
  { id: 3230, city_name: 'São Benedito do Sul', state_id: 17 },
  { id: 3231, city_name: 'São Bento do Una', state_id: 17 },
  { id: 3232, city_name: 'São Caitano', state_id: 17 },
  { id: 3233, city_name: 'São Joaquim do Monte', state_id: 17 },
  { id: 3234, city_name: 'São José da Coroa Grande', state_id: 17 },
  { id: 3235, city_name: 'São José do Belmonte', state_id: 17 },
  { id: 3236, city_name: 'São José do Egito', state_id: 17 },
  { id: 3237, city_name: 'São Lourenço da Mata', state_id: 17 },
  { id: 3238, city_name: 'São Vicente Férrer', state_id: 17 },
  { id: 3239, city_name: 'Serra Talhada', state_id: 17 },
  { id: 3240, city_name: 'Serrita', state_id: 17 },
  { id: 3241, city_name: 'Sertânia', state_id: 17 },
  { id: 3242, city_name: 'Sirinhaém', state_id: 17 },
  { id: 3243, city_name: 'Solidão', state_id: 17 },
  { id: 3244, city_name: 'Surubim', state_id: 17 },
  { id: 3245, city_name: 'Tabira', state_id: 17 },
  { id: 3246, city_name: 'Tacaimbó', state_id: 17 },
  { id: 3247, city_name: 'Tacaratu', state_id: 17 },
  { id: 3248, city_name: 'Tamandaré', state_id: 17 },
  { id: 3249, city_name: 'Taquaritinga do Norte', state_id: 17 },
  { id: 3250, city_name: 'Terezinha', state_id: 17 },
  { id: 3251, city_name: 'Timbaúba', state_id: 17 },
  { id: 3252, city_name: 'Toritama', state_id: 17 },
  { id: 3253, city_name: 'Tracunhaém', state_id: 17 },
  { id: 3254, city_name: 'Tupanatinga', state_id: 17 },
  { id: 3255, city_name: 'Tuparetama', state_id: 17 },
  { id: 3256, city_name: 'Venturosa', state_id: 17 },
  { id: 3257, city_name: 'Verdejante', state_id: 17 },
  { id: 3258, city_name: 'Vertente do Lério', state_id: 17 },
  { id: 3259, city_name: 'Vertentes', state_id: 17 },
  { id: 3260, city_name: 'Vicência', state_id: 17 },
  { id: 3261, city_name: 'Vitória de Santo Antão', state_id: 17 },
  { id: 3262, city_name: 'Xexéu', state_id: 17 },
  { id: 3263, city_name: 'Acauã', state_id: 18 },
  { id: 3264, city_name: 'Agricolândia', state_id: 18 },
  { id: 3265, city_name: 'Alagoinha do Piauí', state_id: 18 },
  { id: 3266, city_name: 'Alegrete do Piauí', state_id: 18 },
  { id: 3267, city_name: 'Alto Longá', state_id: 18 },
  { id: 3268, city_name: 'Altos', state_id: 18 },
  { id: 3269, city_name: 'Alvorada do Gurguéia', state_id: 18 },
  { id: 3270, city_name: 'Amarante', state_id: 18 },
  { id: 3271, city_name: 'Angical do Piauí', state_id: 18 },
  { id: 3272, city_name: 'Anísio de Abreu', state_id: 18 },
  { id: 3273, city_name: 'Antônio Almeida', state_id: 18 },
  { id: 3274, city_name: 'Aroazes', state_id: 18 },
  { id: 3275, city_name: 'Aroeiras do Itaim', state_id: 18 },
  { id: 3276, city_name: 'Arraial', state_id: 18 },
  { id: 3277, city_name: 'Assunção do Piauí', state_id: 18 },
  { id: 3278, city_name: 'Avelino Lopes', state_id: 18 },
  { id: 3279, city_name: 'Baixa Grande do Ribeiro', state_id: 18 },
  { id: 3280, city_name: "Barra D'Alcântara", state_id: 18 },
  { id: 3281, city_name: 'Barras', state_id: 18 },
  { id: 3282, city_name: 'Barreiras do Piauí', state_id: 18 },
  { id: 3283, city_name: 'Barro Duro', state_id: 18 },
  { id: 3284, city_name: 'Bela Vista do Piauí', state_id: 18 },
  { id: 3285, city_name: 'Belém do Piauí', state_id: 18 },
  { id: 3286, city_name: 'Beneditinos', state_id: 18 },
  { id: 3287, city_name: 'Bertolínia', state_id: 18 },
  { id: 3288, city_name: 'Betânia do Piauí', state_id: 18 },
  { id: 3289, city_name: 'Boa Hora', state_id: 18 },
  { id: 3290, city_name: 'Bocaina', state_id: 18 },
  { id: 3291, city_name: 'Bom Princípio do Piauí', state_id: 18 },
  { id: 3292, city_name: 'Bonfim do Piauí', state_id: 18 },
  { id: 3293, city_name: 'Boqueirão do Piauí', state_id: 18 },
  { id: 3294, city_name: 'Brasileira', state_id: 18 },
  { id: 3295, city_name: 'Brejo do Piauí', state_id: 18 },
  { id: 3296, city_name: 'Buriti dos Lopes', state_id: 18 },
  { id: 3297, city_name: 'Buriti dos Montes', state_id: 18 },
  { id: 3298, city_name: 'Cabeceiras do Piauí', state_id: 18 },
  { id: 3299, city_name: 'Cajazeiras do Piauí', state_id: 18 },
  { id: 3300, city_name: 'Cajueiro da Praia', state_id: 18 },
  { id: 3301, city_name: 'Caldeirão Grande do Piauí', state_id: 18 },
  { id: 3302, city_name: 'Campinas do Piauí', state_id: 18 },
  { id: 3303, city_name: 'Campo Alegre do Fidalgo', state_id: 18 },
  { id: 3304, city_name: 'Campo Grande do Piauí', state_id: 18 },
  { id: 3305, city_name: 'Campo Largo do Piauí', state_id: 18 },
  { id: 3306, city_name: 'Campo Maior', state_id: 18 },
  { id: 3307, city_name: 'Canavieira', state_id: 18 },
  { id: 3308, city_name: 'Canto do Buriti', state_id: 18 },
  { id: 3309, city_name: 'Capitão de Campos', state_id: 18 },
  { id: 3310, city_name: 'Capitão Gervásio Oliveira', state_id: 18 },
  { id: 3311, city_name: 'Caraúbas do Piauí', state_id: 18 },
  { id: 3312, city_name: 'Caridade do Piauí', state_id: 18 },
  { id: 3313, city_name: 'Castelo do Piauí', state_id: 18 },
  { id: 3314, city_name: 'Caxingó', state_id: 18 },
  { id: 3315, city_name: 'Cocal', state_id: 18 },
  { id: 3316, city_name: 'Cocal de Telha', state_id: 18 },
  { id: 3317, city_name: 'Cocal dos Alves', state_id: 18 },
  { id: 3318, city_name: 'Coivaras', state_id: 18 },
  { id: 3319, city_name: 'Colônia do Gurguéia', state_id: 18 },
  { id: 3320, city_name: 'Colônia do Piauí', state_id: 18 },
  { id: 3321, city_name: 'Conceição do Canindé', state_id: 18 },
  { id: 3322, city_name: 'Coronel José Dias', state_id: 18 },
  { id: 3323, city_name: 'Corrente', state_id: 18 },
  { id: 3324, city_name: 'Cristalândia do Piauí', state_id: 18 },
  { id: 3325, city_name: 'Cristino Castro', state_id: 18 },
  { id: 3326, city_name: 'Curimatá', state_id: 18 },
  { id: 3327, city_name: 'Currais', state_id: 18 },
  { id: 3328, city_name: 'Curral Novo do Piauí', state_id: 18 },
  { id: 3329, city_name: 'Curralinhos', state_id: 18 },
  { id: 3330, city_name: 'Demerval Lobão', state_id: 18 },
  { id: 3331, city_name: 'Dirceu Arcoverde', state_id: 18 },
  { id: 3332, city_name: 'Dom Expedito Lopes', state_id: 18 },
  { id: 3333, city_name: 'Dom Inocêncio', state_id: 18 },
  { id: 3334, city_name: 'Domingos Mourão', state_id: 18 },
  { id: 3335, city_name: 'Elesbão Veloso', state_id: 18 },
  { id: 3336, city_name: 'Eliseu Martins', state_id: 18 },
  { id: 3337, city_name: 'Esperantina', state_id: 18 },
  { id: 3338, city_name: 'Fartura do Piauí', state_id: 18 },
  { id: 3339, city_name: 'Flores do Piauí', state_id: 18 },
  { id: 3340, city_name: 'Floresta do Piauí', state_id: 18 },
  { id: 3341, city_name: 'Floriano', state_id: 18 },
  { id: 3342, city_name: 'Francinópolis', state_id: 18 },
  { id: 3343, city_name: 'Francisco Ayres', state_id: 18 },
  { id: 3344, city_name: 'Francisco Macedo', state_id: 18 },
  { id: 3345, city_name: 'Francisco Santos', state_id: 18 },
  { id: 3346, city_name: 'Fronteiras', state_id: 18 },
  { id: 3347, city_name: 'Geminiano', state_id: 18 },
  { id: 3348, city_name: 'Gilbués', state_id: 18 },
  { id: 3349, city_name: 'Guadalupe', state_id: 18 },
  { id: 3350, city_name: 'Guaribas', state_id: 18 },
  { id: 3351, city_name: 'Hugo Napoleão', state_id: 18 },
  { id: 3352, city_name: 'Ilha Grande', state_id: 18 },
  { id: 3353, city_name: 'Inhuma', state_id: 18 },
  { id: 3354, city_name: 'Ipiranga do Piauí', state_id: 18 },
  { id: 3355, city_name: 'Isaías Coelho', state_id: 18 },
  { id: 3356, city_name: 'Itainópolis', state_id: 18 },
  { id: 3357, city_name: 'Itaueira', state_id: 18 },
  { id: 3358, city_name: 'Jacobina do Piauí', state_id: 18 },
  { id: 3359, city_name: 'Jaicós', state_id: 18 },
  { id: 3360, city_name: 'Jardim do Mulato', state_id: 18 },
  { id: 3361, city_name: 'Jatobá do Piauí', state_id: 18 },
  { id: 3362, city_name: 'Jerumenha', state_id: 18 },
  { id: 3363, city_name: 'João Costa', state_id: 18 },
  { id: 3364, city_name: 'Joaquim Pires', state_id: 18 },
  { id: 3365, city_name: 'Joca Marques', state_id: 18 },
  { id: 3366, city_name: 'José de Freitas', state_id: 18 },
  { id: 3367, city_name: 'Juazeiro do Piauí', state_id: 18 },
  { id: 3368, city_name: 'Júlio Borges', state_id: 18 },
  { id: 3369, city_name: 'Lagoa Alegre', state_id: 18 },
  { id: 3370, city_name: 'Lagoa de São Francisco', state_id: 18 },
  { id: 3371, city_name: 'Lagoa do Barro do Piauí', state_id: 18 },
  { id: 3372, city_name: 'Lagoa do Piauí', state_id: 18 },
  { id: 3373, city_name: 'Lagoa do Sítio', state_id: 18 },
  { id: 3374, city_name: 'Lagoinha do Piauí', state_id: 18 },
  { id: 3375, city_name: 'Landri Sales', state_id: 18 },
  { id: 3376, city_name: 'Luís Correia', state_id: 18 },
  { id: 3377, city_name: 'Luzilândia', state_id: 18 },
  { id: 3378, city_name: 'Madeiro', state_id: 18 },
  { id: 3379, city_name: 'Manoel Emídio', state_id: 18 },
  { id: 3380, city_name: 'Marcolândia', state_id: 18 },
  { id: 3381, city_name: 'Marcos Parente', state_id: 18 },
  { id: 3382, city_name: 'Massapê do Piauí', state_id: 18 },
  { id: 3383, city_name: 'Matias Olímpio', state_id: 18 },
  { id: 3384, city_name: 'Miguel Alves', state_id: 18 },
  { id: 3385, city_name: 'Miguel Leão', state_id: 18 },
  { id: 3386, city_name: 'Milton Brandão', state_id: 18 },
  { id: 3387, city_name: 'Monsenhor Gil', state_id: 18 },
  { id: 3388, city_name: 'Monsenhor Hipólito', state_id: 18 },
  { id: 3389, city_name: 'Monte Alegre do Piauí', state_id: 18 },
  { id: 3390, city_name: 'Morro Cabeça no Tempo', state_id: 18 },
  { id: 3391, city_name: 'Morro do Chapéu do Piauí', state_id: 18 },
  { id: 3392, city_name: 'Murici dos Portelas', state_id: 18 },
  { id: 3393, city_name: 'Nazaré do Piauí', state_id: 18 },
  { id: 3394, city_name: 'Nazária', state_id: 18 },
  { id: 3395, city_name: 'Nossa Senhora de Nazaré', state_id: 18 },
  { id: 3396, city_name: 'Nossa Senhora dos Remédios', state_id: 18 },
  { id: 3397, city_name: 'Nova Santa Rita', state_id: 18 },
  { id: 3398, city_name: 'Novo Oriente do Piauí', state_id: 18 },
  { id: 3399, city_name: 'Oeiras', state_id: 18 },
  { id: 3400, city_name: "Olho D'Água do Piauí", state_id: 18 },
  { id: 3401, city_name: 'Padre Marcos', state_id: 18 },
  { id: 3402, city_name: 'Paes Landim', state_id: 18 },
  { id: 3403, city_name: 'Pajeú do Piauí', state_id: 18 },
  { id: 3404, city_name: 'Palmeira do Piauí', state_id: 18 },
  { id: 3405, city_name: 'Palmeirais', state_id: 18 },
  { id: 3406, city_name: 'Paquetá', state_id: 18 },
  { id: 3407, city_name: 'Parnaguá', state_id: 18 },
  { id: 3408, city_name: 'Parnaíba', state_id: 18 },
  { id: 3409, city_name: 'Passagem Franca do Piauí', state_id: 18 },
  { id: 3410, city_name: 'Patos do Piauí', state_id: 18 },
  { id: 3411, city_name: "Pau D'Arco do Piauí", state_id: 18 },
  { id: 3412, city_name: 'Paulistana', state_id: 18 },
  { id: 3413, city_name: 'Pavussu', state_id: 18 },
  { id: 3414, city_name: 'Pedro II', state_id: 18 },
  { id: 3415, city_name: 'Pedro Laurentino', state_id: 18 },
  { id: 3416, city_name: 'Picos', state_id: 18 },
  { id: 3417, city_name: 'Pimenteiras', state_id: 18 },
  { id: 3418, city_name: 'Pio IX', state_id: 18 },
  { id: 3419, city_name: 'Piracuruca', state_id: 18 },
  { id: 3420, city_name: 'Piripiri', state_id: 18 },
  { id: 3421, city_name: 'Porto', state_id: 18 },
  { id: 3422, city_name: 'Porto Alegre do Piauí', state_id: 18 },
  { id: 3423, city_name: 'Prata do Piauí', state_id: 18 },
  { id: 3424, city_name: 'Queimada Nova', state_id: 18 },
  { id: 3425, city_name: 'Redenção do Gurguéia', state_id: 18 },
  { id: 3426, city_name: 'Regeneração', state_id: 18 },
  { id: 3427, city_name: 'Riacho Frio', state_id: 18 },
  { id: 3428, city_name: 'Ribeira do Piauí', state_id: 18 },
  { id: 3429, city_name: 'Ribeiro Gonçalves', state_id: 18 },
  { id: 3430, city_name: 'Rio Grande do Piauí', state_id: 18 },
  { id: 3431, city_name: 'Santa Cruz do Piauí', state_id: 18 },
  { id: 3432, city_name: 'Santa Cruz dos Milagres', state_id: 18 },
  { id: 3433, city_name: 'Santa Luz', state_id: 18 },
  { id: 3434, city_name: 'Santa Rosa do Piauí', state_id: 18 },
  { id: 3435, city_name: 'Santana do Piauí', state_id: 18 },
  { id: 3436, city_name: 'Santo Antônio de Lisboa', state_id: 18 },
  { id: 3437, city_name: 'Santo Antônio dos Milagres', state_id: 18 },
  { id: 3438, city_name: 'Santo Inácio do Piauí', state_id: 18 },
  { id: 3439, city_name: 'São Braz do Piauí', state_id: 18 },
  { id: 3440, city_name: 'São Félix do Piauí', state_id: 18 },
  { id: 3441, city_name: 'São Francisco de Assis do Piauí', state_id: 18 },
  { id: 3442, city_name: 'São Francisco do Piauí', state_id: 18 },
  { id: 3443, city_name: 'São Gonçalo do Gurguéia', state_id: 18 },
  { id: 3444, city_name: 'São Gonçalo do Piauí', state_id: 18 },
  { id: 3445, city_name: 'São João da Canabrava', state_id: 18 },
  { id: 3446, city_name: 'São João da Fronteira', state_id: 18 },
  { id: 3447, city_name: 'São João da Serra', state_id: 18 },
  { id: 3448, city_name: 'São João da Varjota', state_id: 18 },
  { id: 3449, city_name: 'São João do Arraial', state_id: 18 },
  { id: 3450, city_name: 'São João do Piauí', state_id: 18 },
  { id: 3451, city_name: 'São José do Peixe', state_id: 18 },
  { id: 3452, city_name: 'São José do Piauí', state_id: 18 },
  { id: 3453, city_name: 'São Julião', state_id: 18 },
  { id: 3454, city_name: 'São Lourenço do Piauí', state_id: 18 },
  { id: 3455, city_name: 'São Luis do Piauí', state_id: 18 },
  { id: 3456, city_name: 'São Miguel da Baixa Grande', state_id: 18 },
  { id: 3457, city_name: 'São Miguel do Fidalgo', state_id: 18 },
  { id: 3458, city_name: 'São Miguel do Tapuio', state_id: 18 },
  { id: 3459, city_name: 'São Pedro do Piauí', state_id: 18 },
  { id: 3460, city_name: 'São Raimundo Nonato', state_id: 18 },
  { id: 3461, city_name: 'Sebastião Barros', state_id: 18 },
  { id: 3462, city_name: 'Sebastião Leal', state_id: 18 },
  { id: 3463, city_name: 'Sigefredo Pacheco', state_id: 18 },
  { id: 3464, city_name: 'Simões', state_id: 18 },
  { id: 3465, city_name: 'Simplício Mendes', state_id: 18 },
  { id: 3466, city_name: 'Socorro do Piauí', state_id: 18 },
  { id: 3467, city_name: 'Sussuapara', state_id: 18 },
  { id: 3468, city_name: 'Tamboril do Piauí', state_id: 18 },
  { id: 3469, city_name: 'Tanque do Piauí', state_id: 18 },
  { id: 3470, city_name: 'Teresina', state_id: 18 },
  { id: 3471, city_name: 'União', state_id: 18 },
  { id: 3472, city_name: 'Uruçuí', state_id: 18 },
  { id: 3473, city_name: 'Valença do Piauí', state_id: 18 },
  { id: 3474, city_name: 'Várzea Branca', state_id: 18 },
  { id: 3475, city_name: 'Vera Mendes', state_id: 18 },
  { id: 3476, city_name: 'Vila Nova do Piauí', state_id: 18 },
  { id: 3477, city_name: 'Wall Ferraz', state_id: 18 },
  { id: 3478, city_name: 'Angra dos Reis', state_id: 19 },
  { id: 3479, city_name: 'Aperibé', state_id: 19 },
  { id: 3480, city_name: 'Araruama', state_id: 19 },
  { id: 3481, city_name: 'Areal', state_id: 19 },
  { id: 3482, city_name: 'Armação dos Búzios', state_id: 19 },
  { id: 3483, city_name: 'Arraial do Cabo', state_id: 19 },
  { id: 3484, city_name: 'Barra do Piraí', state_id: 19 },
  { id: 3485, city_name: 'Barra Mansa', state_id: 19 },
  { id: 3486, city_name: 'Belford Roxo', state_id: 19 },
  { id: 3487, city_name: 'Bom Jesus do Itabapoana', state_id: 19 },
  { id: 3488, city_name: 'Cabo Frio', state_id: 19 },
  { id: 3489, city_name: 'Cachoeiras de Macacu', state_id: 19 },
  { id: 3490, city_name: 'Cambuci', state_id: 19 },
  { id: 3491, city_name: 'Campos dos Goytacazes', state_id: 19 },
  { id: 3492, city_name: 'Carapebus', state_id: 19 },
  { id: 3493, city_name: 'Cardoso Moreira', state_id: 19 },
  { id: 3494, city_name: 'Carmo', state_id: 19 },
  { id: 3495, city_name: 'Casimiro de Abreu', state_id: 19 },
  { id: 3496, city_name: 'Comendador Levy Gasparian', state_id: 19 },
  { id: 3497, city_name: 'Conceição de Macabu', state_id: 19 },
  { id: 3498, city_name: 'Cordeiro', state_id: 19 },
  { id: 3499, city_name: 'Duas Barras', state_id: 19 },
  { id: 3500, city_name: 'Duque de Caxias', state_id: 19 },
  { id: 3501, city_name: 'Engenheiro Paulo de Frontin', state_id: 19 },
  { id: 3502, city_name: 'Guapimirim', state_id: 19 },
  { id: 3503, city_name: 'Iguaba Grande', state_id: 19 },
  { id: 3504, city_name: 'Itaboraí', state_id: 19 },
  { id: 3505, city_name: 'Itaguaí', state_id: 19 },
  { id: 3506, city_name: 'Italva', state_id: 19 },
  { id: 3507, city_name: 'Itaocara', state_id: 19 },
  { id: 3508, city_name: 'Itaperuna', state_id: 19 },
  { id: 3509, city_name: 'Itatiaia', state_id: 19 },
  { id: 3510, city_name: 'Japeri', state_id: 19 },
  { id: 3511, city_name: 'Laje do Muriaé', state_id: 19 },
  { id: 3512, city_name: 'Macaé', state_id: 19 },
  { id: 3513, city_name: 'Macuco', state_id: 19 },
  { id: 3514, city_name: 'Magé', state_id: 19 },
  { id: 3515, city_name: 'Mangaratiba', state_id: 19 },
  { id: 3516, city_name: 'Maricá', state_id: 19 },
  { id: 3517, city_name: 'Mendes', state_id: 19 },
  { id: 3518, city_name: 'Miguel Pereira', state_id: 19 },
  { id: 3519, city_name: 'Miracema', state_id: 19 },
  { id: 3520, city_name: 'Natividade', state_id: 19 },
  { id: 3521, city_name: 'Nilópolis', state_id: 19 },
  { id: 3522, city_name: 'Niterói', state_id: 19 },
  { id: 3523, city_name: 'Nova Friburgo', state_id: 19 },
  { id: 3524, city_name: 'Nova Iguaçu', state_id: 19 },
  { id: 3525, city_name: 'Paracambi', state_id: 19 },
  { id: 3526, city_name: 'Paraíba do Sul', state_id: 19 },
  { id: 3527, city_name: 'Paraty', state_id: 19 },
  { id: 3528, city_name: 'Paty do Alferes', state_id: 19 },
  { id: 3529, city_name: 'Petrópolis', state_id: 19 },
  { id: 3530, city_name: 'Pinheiral', state_id: 19 },
  { id: 3531, city_name: 'Piraí', state_id: 19 },
  { id: 3532, city_name: 'Porciúncula', state_id: 19 },
  { id: 3533, city_name: 'Porto Real', state_id: 19 },
  { id: 3534, city_name: 'Quatis', state_id: 19 },
  { id: 3535, city_name: 'Queimados', state_id: 19 },
  { id: 3536, city_name: 'Quissamã', state_id: 19 },
  { id: 3537, city_name: 'Resende', state_id: 19 },
  { id: 3538, city_name: 'Rio Bonito', state_id: 19 },
  { id: 3539, city_name: 'Rio Claro', state_id: 19 },
  { id: 3540, city_name: 'Rio das Flores', state_id: 19 },
  { id: 3541, city_name: 'Rio das Ostras', state_id: 19 },
  { id: 3542, city_name: 'Rio de Janeiro', state_id: 19 },
  { id: 3543, city_name: 'Santa Maria Madalena', state_id: 19 },
  { id: 3544, city_name: 'Santo Antônio de Pádua', state_id: 19 },
  { id: 3545, city_name: 'São Fidélis', state_id: 19 },
  { id: 3546, city_name: 'São Francisco de Itabapoana', state_id: 19 },
  { id: 3547, city_name: 'São Gonçalo', state_id: 19 },
  { id: 3548, city_name: 'São João da Barra', state_id: 19 },
  { id: 3549, city_name: 'São João de Meriti', state_id: 19 },
  { id: 3550, city_name: 'São José de Ubá', state_id: 19 },
  { id: 3551, city_name: 'São José do Vale do Rio Preto', state_id: 19 },
  { id: 3552, city_name: 'São Pedro da Aldeia', state_id: 19 },
  { id: 3553, city_name: 'São Sebastião do Alto', state_id: 19 },
  { id: 3554, city_name: 'Saquarema', state_id: 19 },
  { id: 3555, city_name: 'Seropédica', state_id: 19 },
  { id: 3556, city_name: 'Silva Jardim', state_id: 19 },
  { id: 3557, city_name: 'Sumidouro', state_id: 19 },
  { id: 3558, city_name: 'Tanguá', state_id: 19 },
  { id: 3559, city_name: 'Teresópolis', state_id: 19 },
  { id: 3560, city_name: 'Trajano de Moraes', state_id: 19 },
  { id: 3561, city_name: 'Três Rios', state_id: 19 },
  { id: 3562, city_name: 'Varre-Sai', state_id: 19 },
  { id: 3563, city_name: 'Vassouras', state_id: 19 },
  { id: 3564, city_name: 'Volta Redonda', state_id: 19 },
  { id: 3565, city_name: 'Acari', state_id: 20 },
  { id: 3566, city_name: 'Açu', state_id: 20 },
  { id: 3567, city_name: 'Afonso Bezerra', state_id: 20 },
  { id: 3568, city_name: 'Água Nova', state_id: 20 },
  { id: 3569, city_name: 'Alexandria', state_id: 20 },
  { id: 3570, city_name: 'Almino Afonso', state_id: 20 },
  { id: 3571, city_name: 'Alto do Rodrigues', state_id: 20 },
  { id: 3572, city_name: 'Angicos', state_id: 20 },
  { id: 3573, city_name: 'Antônio Martins', state_id: 20 },
  { id: 3574, city_name: 'Apodi', state_id: 20 },
  { id: 3575, city_name: 'Areia Branca', state_id: 20 },
  { id: 3576, city_name: 'Arês', state_id: 20 },
  { id: 3577, city_name: 'Baía Formosa', state_id: 20 },
  { id: 3578, city_name: 'Barcelona', state_id: 20 },
  { id: 3579, city_name: 'Bento Fernandes', state_id: 20 },
  { id: 3580, city_name: 'Bodó', state_id: 20 },
  { id: 3581, city_name: 'Caiçara do Norte', state_id: 20 },
  { id: 3582, city_name: 'Caiçara do Rio do Vento', state_id: 20 },
  { id: 3583, city_name: 'Caicó', state_id: 20 },
  { id: 3584, city_name: 'Campo Redondo', state_id: 20 },
  { id: 3585, city_name: 'Canguaretama', state_id: 20 },
  { id: 3586, city_name: 'Carnaúba dos Dantas', state_id: 20 },
  { id: 3587, city_name: 'Carnaubais', state_id: 20 },
  { id: 3588, city_name: 'Ceará-Mirim', state_id: 20 },
  { id: 3589, city_name: 'Cerro Corá', state_id: 20 },
  { id: 3590, city_name: 'Coronel Ezequiel', state_id: 20 },
  { id: 3591, city_name: 'Coronel João Pessoa', state_id: 20 },
  { id: 3592, city_name: 'Cruzeta', state_id: 20 },
  { id: 3593, city_name: 'Currais Novos', state_id: 20 },
  { id: 3594, city_name: 'Doutor Severiano', state_id: 20 },
  { id: 3595, city_name: 'Encanto', state_id: 20 },
  { id: 3596, city_name: 'Equador', state_id: 20 },
  { id: 3597, city_name: 'Espírito Santo', state_id: 20 },
  { id: 3598, city_name: 'Extremoz', state_id: 20 },
  { id: 3599, city_name: 'Felipe Guerra', state_id: 20 },
  { id: 3600, city_name: 'Fernando Pedroza', state_id: 20 },
  { id: 3601, city_name: 'Florânia', state_id: 20 },
  { id: 3602, city_name: 'Francisco Dantas', state_id: 20 },
  { id: 3603, city_name: 'Frutuoso Gomes', state_id: 20 },
  { id: 3604, city_name: 'Galinhos', state_id: 20 },
  { id: 3605, city_name: 'Goianinha', state_id: 20 },
  { id: 3606, city_name: 'Governador Dix-Sept Rosado', state_id: 20 },
  { id: 3607, city_name: 'Grossos', state_id: 20 },
  { id: 3608, city_name: 'Guamaré', state_id: 20 },
  { id: 3609, city_name: 'Ielmo Marinho', state_id: 20 },
  { id: 3610, city_name: 'Ipanguaçu', state_id: 20 },
  { id: 3611, city_name: 'Ipueira', state_id: 20 },
  { id: 3612, city_name: 'Itaú', state_id: 20 },
  { id: 3613, city_name: 'Jaçanã', state_id: 20 },
  { id: 3614, city_name: 'Janduís', state_id: 20 },
  { id: 3615, city_name: 'Januário Cicco', state_id: 20 },
  { id: 3616, city_name: 'Japi', state_id: 20 },
  { id: 3617, city_name: 'Jardim de Angicos', state_id: 20 },
  { id: 3618, city_name: 'Jardim de Piranhas', state_id: 20 },
  { id: 3619, city_name: 'Jardim do Seridó', state_id: 20 },
  { id: 3620, city_name: 'João Câmara', state_id: 20 },
  { id: 3621, city_name: 'João Dias', state_id: 20 },
  { id: 3622, city_name: 'José da Penha', state_id: 20 },
  { id: 3623, city_name: 'Jucurutu', state_id: 20 },
  { id: 3624, city_name: "Lagoa d'Anta", state_id: 20 },
  { id: 3625, city_name: 'Lagoa de Pedras', state_id: 20 },
  { id: 3626, city_name: 'Lagoa de Velhos', state_id: 20 },
  { id: 3627, city_name: 'Lagoa Nova', state_id: 20 },
  { id: 3628, city_name: 'Lagoa Salgada', state_id: 20 },
  { id: 3629, city_name: 'Lajes', state_id: 20 },
  { id: 3630, city_name: 'Lajes Pintadas', state_id: 20 },
  { id: 3631, city_name: 'Lucrécia', state_id: 20 },
  { id: 3632, city_name: 'Luís Gomes', state_id: 20 },
  { id: 3633, city_name: 'Macaíba', state_id: 20 },
  { id: 3634, city_name: 'Macau', state_id: 20 },
  { id: 3635, city_name: 'Major Sales', state_id: 20 },
  { id: 3636, city_name: 'Marcelino Vieira', state_id: 20 },
  { id: 3637, city_name: 'Martins', state_id: 20 },
  { id: 3638, city_name: 'Maxaranguape', state_id: 20 },
  { id: 3639, city_name: 'Messias Targino', state_id: 20 },
  { id: 3640, city_name: 'Montanhas', state_id: 20 },
  { id: 3641, city_name: 'Monte das Gameleiras', state_id: 20 },
  { id: 3642, city_name: 'Mossoró', state_id: 20 },
  { id: 3643, city_name: 'Natal', state_id: 20 },
  { id: 3644, city_name: 'Nísia Floresta', state_id: 20 },
  { id: 3645, city_name: 'Nova Cruz', state_id: 20 },
  { id: 3646, city_name: "Olho d'Água do Borges", state_id: 20 },
  { id: 3647, city_name: 'Paraná', state_id: 20 },
  { id: 3648, city_name: 'Paraú', state_id: 20 },
  { id: 3649, city_name: 'Parazinho', state_id: 20 },
  { id: 3650, city_name: 'Parelhas', state_id: 20 },
  { id: 3651, city_name: 'Passa e Fica', state_id: 20 },
  { id: 3652, city_name: 'Patu', state_id: 20 },
  { id: 3653, city_name: 'Pau dos Ferros', state_id: 20 },
  { id: 3654, city_name: 'Pedra Grande', state_id: 20 },
  { id: 3655, city_name: 'Pedro Avelino', state_id: 20 },
  { id: 3656, city_name: 'Pedro Velho', state_id: 20 },
  { id: 3657, city_name: 'Pendências', state_id: 20 },
  { id: 3658, city_name: 'Poço Branco', state_id: 20 },
  { id: 3659, city_name: 'Portalegre', state_id: 20 },
  { id: 3660, city_name: 'Porto do Mangue', state_id: 20 },
  { id: 3661, city_name: 'Pureza', state_id: 20 },
  { id: 3662, city_name: 'Rafael Fernandes', state_id: 20 },
  { id: 3663, city_name: 'Rafael Godeiro', state_id: 20 },
  { id: 3664, city_name: 'Riacho da Cruz', state_id: 20 },
  { id: 3665, city_name: 'Riachuelo', state_id: 20 },
  { id: 3666, city_name: 'Rio do Fogo', state_id: 20 },
  { id: 3667, city_name: 'Rodolfo Fernandes', state_id: 20 },
  { id: 3668, city_name: 'Santa Maria', state_id: 20 },
  { id: 3669, city_name: 'Santana do Matos', state_id: 20 },
  { id: 3670, city_name: 'Santana do Seridó', state_id: 20 },
  { id: 3671, city_name: 'Santo Antônio', state_id: 20 },
  { id: 3672, city_name: 'São Bento do Norte', state_id: 20 },
  { id: 3673, city_name: 'São Bento do Trairí', state_id: 20 },
  { id: 3674, city_name: 'São Fernando', state_id: 20 },
  { id: 3675, city_name: 'São Francisco do Oeste', state_id: 20 },
  { id: 3676, city_name: 'São João do Sabugi', state_id: 20 },
  { id: 3677, city_name: 'São José de Mipibu', state_id: 20 },
  { id: 3678, city_name: 'São José do Campestre', state_id: 20 },
  { id: 3679, city_name: 'São José do Seridó', state_id: 20 },
  { id: 3680, city_name: 'São Miguel', state_id: 20 },
  { id: 3681, city_name: 'São Miguel do Gostoso', state_id: 20 },
  { id: 3682, city_name: 'São Paulo do Potengi', state_id: 20 },
  { id: 3683, city_name: 'São Pedro', state_id: 20 },
  { id: 3684, city_name: 'São Rafael', state_id: 20 },
  { id: 3685, city_name: 'São Vicente', state_id: 20 },
  { id: 3686, city_name: 'Senador Elói de Souza', state_id: 20 },
  { id: 3687, city_name: 'Senador Georgino Avelino', state_id: 20 },
  { id: 3688, city_name: 'Serra Caiada', state_id: 20 },
  { id: 3689, city_name: 'Serra de São Bento', state_id: 20 },
  { id: 3690, city_name: 'Serra do Mel', state_id: 20 },
  { id: 3691, city_name: 'Serra Negra do Norte', state_id: 20 },
  { id: 3692, city_name: 'Serrinha dos Pintos', state_id: 20 },
  { id: 3693, city_name: 'Severiano Melo', state_id: 20 },
  { id: 3694, city_name: 'Taboleiro Grande', state_id: 20 },
  { id: 3695, city_name: 'Taipu', state_id: 20 },
  { id: 3696, city_name: 'Tangará', state_id: 20 },
  { id: 3697, city_name: 'Tenente Ananias', state_id: 20 },
  { id: 3698, city_name: 'Tenente Laurentino Cruz', state_id: 20 },
  { id: 3699, city_name: 'Tibau', state_id: 20 },
  { id: 3700, city_name: 'Tibau do Sul', state_id: 20 },
  { id: 3701, city_name: 'Timbaúba dos Batistas', state_id: 20 },
  { id: 3702, city_name: 'Touros', state_id: 20 },
  { id: 3703, city_name: 'Triunfo Potiguar', state_id: 20 },
  { id: 3704, city_name: 'Umarizal', state_id: 20 },
  { id: 3705, city_name: 'Upanema', state_id: 20 },
  { id: 3706, city_name: 'Venha-Ver', state_id: 20 },
  { id: 3707, city_name: 'Vila Flor', state_id: 20 },
  { id: 3708, city_name: 'Aceguá', state_id: 21 },
  { id: 3709, city_name: 'Água Santa', state_id: 21 },
  { id: 3710, city_name: 'Agudo', state_id: 21 },
  { id: 3711, city_name: 'Ajuricaba', state_id: 21 },
  { id: 3712, city_name: 'Alecrim', state_id: 21 },
  { id: 3713, city_name: 'Alegrete', state_id: 21 },
  { id: 3714, city_name: 'Alegria', state_id: 21 },
  { id: 3715, city_name: 'Almirante Tamandaré do Sul', state_id: 21 },
  { id: 3716, city_name: 'Alpestre', state_id: 21 },
  { id: 3717, city_name: 'Alto Alegre', state_id: 21 },
  { id: 3718, city_name: 'Alto Feliz', state_id: 21 },
  { id: 3719, city_name: 'Alvorada', state_id: 21 },
  { id: 3720, city_name: 'Amaral Ferrador', state_id: 21 },
  { id: 3721, city_name: 'Ametista do Sul', state_id: 21 },
  { id: 3722, city_name: 'André da Rocha', state_id: 21 },
  { id: 3723, city_name: 'Anta Gorda', state_id: 21 },
  { id: 3724, city_name: 'Antônio Prado', state_id: 21 },
  { id: 3725, city_name: 'Arambaré', state_id: 21 },
  { id: 3726, city_name: 'Araricá', state_id: 21 },
  { id: 3727, city_name: 'Aratiba', state_id: 21 },
  { id: 3728, city_name: 'Arroio do Meio', state_id: 21 },
  { id: 3729, city_name: 'Arroio do Padre', state_id: 21 },
  { id: 3730, city_name: 'Arroio do Sal', state_id: 21 },
  { id: 3731, city_name: 'Arroio do Tigre', state_id: 21 },
  { id: 3732, city_name: 'Arroio dos Ratos', state_id: 21 },
  { id: 3733, city_name: 'Arroio Grande', state_id: 21 },
  { id: 3734, city_name: 'Arvorezinha', state_id: 21 },
  { id: 3735, city_name: 'Augusto Pestana', state_id: 21 },
  { id: 3736, city_name: 'Áurea', state_id: 21 },
  { id: 3737, city_name: 'Bagé', state_id: 21 },
  { id: 3738, city_name: 'Balneário Pinhal', state_id: 21 },
  { id: 3739, city_name: 'Barão', state_id: 21 },
  { id: 3740, city_name: 'Barão de Cotegipe', state_id: 21 },
  { id: 3741, city_name: 'Barão do Triunfo', state_id: 21 },
  { id: 3742, city_name: 'Barra do Guarita', state_id: 21 },
  { id: 3743, city_name: 'Barra do Quaraí', state_id: 21 },
  { id: 3744, city_name: 'Barra do Ribeiro', state_id: 21 },
  { id: 3745, city_name: 'Barra do Rio Azul', state_id: 21 },
  { id: 3746, city_name: 'Barra Funda', state_id: 21 },
  { id: 3747, city_name: 'Barros Cassal', state_id: 21 },
  { id: 3748, city_name: 'Benjamin Constant do Sul', state_id: 21 },
  { id: 3749, city_name: 'Bento Gonçalves', state_id: 21 },
  { id: 3750, city_name: 'Boa Vista das Missões', state_id: 21 },
  { id: 3751, city_name: 'Boa Vista do Buricá', state_id: 21 },
  { id: 3752, city_name: 'Boa Vista do Cadeado', state_id: 21 },
  { id: 3753, city_name: 'Boa Vista do Incra', state_id: 21 },
  { id: 3754, city_name: 'Boa Vista do Sul', state_id: 21 },
  { id: 3755, city_name: 'Bom Princípio', state_id: 21 },
  { id: 3756, city_name: 'Bom Progresso', state_id: 21 },
  { id: 3757, city_name: 'Bom Retiro do Sul', state_id: 21 },
  { id: 3758, city_name: 'Boqueirão do Leão', state_id: 21 },
  { id: 3759, city_name: 'Bossoroca', state_id: 21 },
  { id: 3760, city_name: 'Bozano', state_id: 21 },
  { id: 3761, city_name: 'Braga', state_id: 21 },
  { id: 3762, city_name: 'Brochier', state_id: 21 },
  { id: 3763, city_name: 'Butiá', state_id: 21 },
  { id: 3764, city_name: 'Caçapava do Sul', state_id: 21 },
  { id: 3765, city_name: 'Cacequi', state_id: 21 },
  { id: 3766, city_name: 'Cachoeira do Sul', state_id: 21 },
  { id: 3767, city_name: 'Cacique Doble', state_id: 21 },
  { id: 3768, city_name: 'Caibaté', state_id: 21 },
  { id: 3769, city_name: 'Camaquã', state_id: 21 },
  { id: 3770, city_name: 'Camargo', state_id: 21 },
  { id: 3771, city_name: 'Cambará do Sul', state_id: 21 },
  { id: 3772, city_name: 'Campestre da Serra', state_id: 21 },
  { id: 3773, city_name: 'Campina das Missões', state_id: 21 },
  { id: 3774, city_name: 'Campinas do Sul', state_id: 21 },
  { id: 3775, city_name: 'Campo Bom', state_id: 21 },
  { id: 3776, city_name: 'Campo Novo', state_id: 21 },
  { id: 3777, city_name: 'Campos Borges', state_id: 21 },
  { id: 3778, city_name: 'Candelária', state_id: 21 },
  { id: 3779, city_name: 'Cândido Godói', state_id: 21 },
  { id: 3780, city_name: 'Candiota', state_id: 21 },
  { id: 3781, city_name: 'Canela', state_id: 21 },
  { id: 3782, city_name: 'Canguçu', state_id: 21 },
  { id: 3783, city_name: 'Canoas', state_id: 21 },
  { id: 3784, city_name: 'Canudos do Vale', state_id: 21 },
  { id: 3785, city_name: 'Capão Bonito do Sul', state_id: 21 },
  { id: 3786, city_name: 'Capão da Canoa', state_id: 21 },
  { id: 3787, city_name: 'Capão do Cipó', state_id: 21 },
  { id: 3788, city_name: 'Capão do Leão', state_id: 21 },
  { id: 3789, city_name: 'Capela de Santana', state_id: 21 },
  { id: 3790, city_name: 'Capitão', state_id: 21 },
  { id: 3791, city_name: 'Capivari do Sul', state_id: 21 },
  { id: 3792, city_name: 'Caraá', state_id: 21 },
  { id: 3793, city_name: 'Carazinho', state_id: 21 },
  { id: 3794, city_name: 'Carlos Barbosa', state_id: 21 },
  { id: 3795, city_name: 'Carlos Gomes', state_id: 21 },
  { id: 3796, city_name: 'Casca', state_id: 21 },
  { id: 3797, city_name: 'Caseiros', state_id: 21 },
  { id: 3798, city_name: 'Catuípe', state_id: 21 },
  { id: 3799, city_name: 'Caxias do Sul', state_id: 21 },
  { id: 3800, city_name: 'Centenário', state_id: 21 },
  { id: 3801, city_name: 'Cerrito', state_id: 21 },
  { id: 3802, city_name: 'Cerro Branco', state_id: 21 },
  { id: 3803, city_name: 'Cerro Grande', state_id: 21 },
  { id: 3804, city_name: 'Cerro Grande do Sul', state_id: 21 },
  { id: 3805, city_name: 'Cerro Largo', state_id: 21 },
  { id: 3806, city_name: 'Chapada', state_id: 21 },
  { id: 3807, city_name: 'Charqueadas', state_id: 21 },
  { id: 3808, city_name: 'Charrua', state_id: 21 },
  { id: 3809, city_name: 'Chiapetta', state_id: 21 },
  { id: 3810, city_name: 'Chuí', state_id: 21 },
  { id: 3811, city_name: 'Chuvisca', state_id: 21 },
  { id: 3812, city_name: 'Cidreira', state_id: 21 },
  { id: 3813, city_name: 'Ciríaco', state_id: 21 },
  { id: 3814, city_name: 'Condor', state_id: 21 },
  { id: 3815, city_name: 'Constantina', state_id: 21 },
  { id: 3816, city_name: 'Coqueiro Baixo', state_id: 21 },
  { id: 3817, city_name: 'Coqueiros do Sul', state_id: 21 },
  { id: 3818, city_name: 'Coronel Barros', state_id: 21 },
  { id: 3819, city_name: 'Coronel Bicaco', state_id: 21 },
  { id: 3820, city_name: 'Coronel Pilar', state_id: 21 },
  { id: 3821, city_name: 'Cotiporã', state_id: 21 },
  { id: 3822, city_name: 'Coxilha', state_id: 21 },
  { id: 3823, city_name: 'Crissiumal', state_id: 21 },
  { id: 3824, city_name: 'Cristal', state_id: 21 },
  { id: 3825, city_name: 'Cristal do Sul', state_id: 21 },
  { id: 3826, city_name: 'Cruz Alta', state_id: 21 },
  { id: 3827, city_name: 'Cruzaltense', state_id: 21 },
  { id: 3828, city_name: 'David Canabarro', state_id: 21 },
  { id: 3829, city_name: 'Derrubadas', state_id: 21 },
  { id: 3830, city_name: 'Dezesseis de Novembro', state_id: 21 },
  { id: 3831, city_name: 'Dilermando de Aguiar', state_id: 21 },
  { id: 3832, city_name: 'Dois Irmãos', state_id: 21 },
  { id: 3833, city_name: 'Dois Irmãos das Missões', state_id: 21 },
  { id: 3834, city_name: 'Dois Lajeados', state_id: 21 },
  { id: 3835, city_name: 'Dom Feliciano', state_id: 21 },
  { id: 3836, city_name: 'Dom Pedrito', state_id: 21 },
  { id: 3837, city_name: 'Dom Pedro de Alcântara', state_id: 21 },
  { id: 3838, city_name: 'Dona Francisca', state_id: 21 },
  { id: 3839, city_name: 'Doutor Maurício Cardoso', state_id: 21 },
  { id: 3840, city_name: 'Doutor Ricardo', state_id: 21 },
  { id: 3841, city_name: 'Eldorado do Sul', state_id: 21 },
  { id: 3842, city_name: 'Encantado', state_id: 21 },
  { id: 3843, city_name: 'Encruzilhada do Sul', state_id: 21 },
  { id: 3844, city_name: 'Engenho Velho', state_id: 21 },
  { id: 3845, city_name: 'Entre Rios do Sul', state_id: 21 },
  { id: 3846, city_name: 'Entre-Ijuís', state_id: 21 },
  { id: 3847, city_name: 'Erebango', state_id: 21 },
  { id: 3848, city_name: 'Erechim', state_id: 21 },
  { id: 3849, city_name: 'Ernestina', state_id: 21 },
  { id: 3850, city_name: 'Erval Grande', state_id: 21 },
  { id: 3851, city_name: 'Erval Seco', state_id: 21 },
  { id: 3852, city_name: 'Esmeralda', state_id: 21 },
  { id: 3853, city_name: 'Esperança do Sul', state_id: 21 },
  { id: 3854, city_name: 'Espumoso', state_id: 21 },
  { id: 3855, city_name: 'Estação', state_id: 21 },
  { id: 3856, city_name: 'Estância Velha', state_id: 21 },
  { id: 3857, city_name: 'Esteio', state_id: 21 },
  { id: 3858, city_name: 'Estrela', state_id: 21 },
  { id: 3859, city_name: 'Estrela Velha', state_id: 21 },
  { id: 3860, city_name: 'Eugênio de Castro', state_id: 21 },
  { id: 3861, city_name: 'Fagundes Varela', state_id: 21 },
  { id: 3862, city_name: 'Farroupilha', state_id: 21 },
  { id: 3863, city_name: 'Faxinal do Soturno', state_id: 21 },
  { id: 3864, city_name: 'Faxinalzinho', state_id: 21 },
  { id: 3865, city_name: 'Fazenda Vilanova', state_id: 21 },
  { id: 3866, city_name: 'Feliz', state_id: 21 },
  { id: 3867, city_name: 'Flores da Cunha', state_id: 21 },
  { id: 3868, city_name: 'Floriano Peixoto', state_id: 21 },
  { id: 3869, city_name: 'Fontoura Xavier', state_id: 21 },
  { id: 3870, city_name: 'Formigueiro', state_id: 21 },
  { id: 3871, city_name: 'Forquetinha', state_id: 21 },
  { id: 3872, city_name: 'Fortaleza dos Valos', state_id: 21 },
  { id: 3873, city_name: 'Frederico Westphalen', state_id: 21 },
  { id: 3874, city_name: 'Garibaldi', state_id: 21 },
  { id: 3875, city_name: 'Garruchos', state_id: 21 },
  { id: 3876, city_name: 'Gaurama', state_id: 21 },
  { id: 3877, city_name: 'General Câmara', state_id: 21 },
  { id: 3878, city_name: 'Gentil', state_id: 21 },
  { id: 3879, city_name: 'Getúlio Vargas', state_id: 21 },
  { id: 3880, city_name: 'Giruá', state_id: 21 },
  { id: 3881, city_name: 'Glorinha', state_id: 21 },
  { id: 3882, city_name: 'Gramado', state_id: 21 },
  { id: 3883, city_name: 'Gramado dos Loureiros', state_id: 21 },
  { id: 3884, city_name: 'Gramado Xavier', state_id: 21 },
  { id: 3885, city_name: 'Gravataí', state_id: 21 },
  { id: 3886, city_name: 'Guabiju', state_id: 21 },
  { id: 3887, city_name: 'Guaíba', state_id: 21 },
  { id: 3888, city_name: 'Guaporé', state_id: 21 },
  { id: 3889, city_name: 'Guarani das Missões', state_id: 21 },
  { id: 3890, city_name: 'Harmonia', state_id: 21 },
  { id: 3891, city_name: 'Herval', state_id: 21 },
  { id: 3892, city_name: 'Herveiras', state_id: 21 },
  { id: 3893, city_name: 'Horizontina', state_id: 21 },
  { id: 3894, city_name: 'Hulha Negra', state_id: 21 },
  { id: 3895, city_name: 'Ibarama', state_id: 21 },
  { id: 3896, city_name: 'Ibiaçá', state_id: 21 },
  { id: 3897, city_name: 'Ibiraiaras', state_id: 21 },
  { id: 3898, city_name: 'Ibirapuitã', state_id: 21 },
  { id: 3899, city_name: 'Ibirubá', state_id: 21 },
  { id: 3900, city_name: 'Igrejinha', state_id: 21 },
  { id: 3901, city_name: 'Ijuí', state_id: 21 },
  { id: 3902, city_name: 'Ilópolis', state_id: 21 },
  { id: 3903, city_name: 'Imbé', state_id: 21 },
  { id: 3904, city_name: 'Imigrante', state_id: 21 },
  { id: 3905, city_name: 'Inhacorá', state_id: 21 },
  { id: 3906, city_name: 'Ipê', state_id: 21 },
  { id: 3907, city_name: 'Ipiranga do Sul', state_id: 21 },
  { id: 3908, city_name: 'Iraí', state_id: 21 },
  { id: 3909, city_name: 'Itaara', state_id: 21 },
  { id: 3910, city_name: 'Itacurubi', state_id: 21 },
  { id: 3911, city_name: 'Itapuca', state_id: 21 },
  { id: 3912, city_name: 'Itaqui', state_id: 21 },
  { id: 3913, city_name: 'Itati', state_id: 21 },
  { id: 3914, city_name: 'Itatiba do Sul', state_id: 21 },
  { id: 3915, city_name: 'Ivorá', state_id: 21 },
  { id: 3916, city_name: 'Ivoti', state_id: 21 },
  { id: 3917, city_name: 'Jaboticaba', state_id: 21 },
  { id: 3918, city_name: 'Jacuizinho', state_id: 21 },
  { id: 3919, city_name: 'Jaguarão', state_id: 21 },
  { id: 3920, city_name: 'Jaguari', state_id: 21 },
  { id: 3921, city_name: 'Jaquirana', state_id: 21 },
  { id: 3922, city_name: 'Jari', state_id: 21 },
  { id: 3923, city_name: 'Jóia', state_id: 21 },
  { id: 3924, city_name: 'Júlio de Castilhos', state_id: 21 },
  { id: 3925, city_name: 'Lagoa Bonita do Sul', state_id: 21 },
  { id: 3926, city_name: 'Lagoa dos Três Cantos', state_id: 21 },
  { id: 3927, city_name: 'Lagoa Vermelha', state_id: 21 },
  { id: 3928, city_name: 'Lagoão', state_id: 21 },
  { id: 3929, city_name: 'Lajeado', state_id: 21 },
  { id: 3930, city_name: 'Lajeado do Bugre', state_id: 21 },
  { id: 3931, city_name: 'Lavras do Sul', state_id: 21 },
  { id: 3932, city_name: 'Liberato Salzano', state_id: 21 },
  { id: 3933, city_name: 'Lindolfo Collor', state_id: 21 },
  { id: 3934, city_name: 'Linha Nova', state_id: 21 },
  { id: 3935, city_name: 'Maçambará', state_id: 21 },
  { id: 3936, city_name: 'Machadinho', state_id: 21 },
  { id: 3937, city_name: 'Mampituba', state_id: 21 },
  { id: 3938, city_name: 'Manoel Viana', state_id: 21 },
  { id: 3939, city_name: 'Maquiné', state_id: 21 },
  { id: 3940, city_name: 'Maratá', state_id: 21 },
  { id: 3941, city_name: 'Marau', state_id: 21 },
  { id: 3942, city_name: 'Marcelino Ramos', state_id: 21 },
  { id: 3943, city_name: 'Mariana Pimentel', state_id: 21 },
  { id: 3944, city_name: 'Mariano Moro', state_id: 21 },
  { id: 3945, city_name: 'Marques de Souza', state_id: 21 },
  { id: 3946, city_name: 'Mata', state_id: 21 },
  { id: 3947, city_name: 'Mato Castelhano', state_id: 21 },
  { id: 3948, city_name: 'Mato Leitão', state_id: 21 },
  { id: 3949, city_name: 'Mato Queimado', state_id: 21 },
  { id: 3950, city_name: 'Maximiliano de Almeida', state_id: 21 },
  { id: 3951, city_name: 'Minas do Leão', state_id: 21 },
  { id: 3952, city_name: 'Miraguaí', state_id: 21 },
  { id: 3953, city_name: 'Montauri', state_id: 21 },
  { id: 3954, city_name: 'Monte Alegre dos Campos', state_id: 21 },
  { id: 3955, city_name: 'Monte Belo do Sul', state_id: 21 },
  { id: 3956, city_name: 'Montenegro', state_id: 21 },
  { id: 3957, city_name: 'Mormaço', state_id: 21 },
  { id: 3958, city_name: 'Morrinhos do Sul', state_id: 21 },
  { id: 3959, city_name: 'Morro Redondo', state_id: 21 },
  { id: 3960, city_name: 'Morro Reuter', state_id: 21 },
  { id: 3961, city_name: 'Mostardas', state_id: 21 },
  { id: 3962, city_name: 'Muçum', state_id: 21 },
  { id: 3963, city_name: 'Muitos Capões', state_id: 21 },
  { id: 3964, city_name: 'Muliterno', state_id: 21 },
  { id: 3965, city_name: 'Não-Me-Toque', state_id: 21 },
  { id: 3966, city_name: 'Nicolau Vergueiro', state_id: 21 },
  { id: 3967, city_name: 'Nonoai', state_id: 21 },
  { id: 3968, city_name: 'Nova Alvorada', state_id: 21 },
  { id: 3969, city_name: 'Nova Araçá', state_id: 21 },
  { id: 3970, city_name: 'Nova Bassano', state_id: 21 },
  { id: 3971, city_name: 'Nova Boa Vista', state_id: 21 },
  { id: 3972, city_name: 'Nova Bréscia', state_id: 21 },
  { id: 3973, city_name: 'Nova Candelária', state_id: 21 },
  { id: 3974, city_name: 'Nova Esperança do Sul', state_id: 21 },
  { id: 3975, city_name: 'Nova Hartz', state_id: 21 },
  { id: 3976, city_name: 'Nova Pádua', state_id: 21 },
  { id: 3977, city_name: 'Nova Palma', state_id: 21 },
  { id: 3978, city_name: 'Nova Petrópolis', state_id: 21 },
  { id: 3979, city_name: 'Nova Prata', state_id: 21 },
  { id: 3980, city_name: 'Nova Ramada', state_id: 21 },
  { id: 3981, city_name: 'Nova Roma do Sul', state_id: 21 },
  { id: 3982, city_name: 'Novo Barreiro', state_id: 21 },
  { id: 3983, city_name: 'Novo Cabrais', state_id: 21 },
  { id: 3984, city_name: 'Novo Hamburgo', state_id: 21 },
  { id: 3985, city_name: 'Novo Machado', state_id: 21 },
  { id: 3986, city_name: 'Novo Tiradentes', state_id: 21 },
  { id: 3987, city_name: 'Novo Xingu', state_id: 21 },
  { id: 3988, city_name: 'Osório', state_id: 21 },
  { id: 3989, city_name: 'Paim Filho', state_id: 21 },
  { id: 3990, city_name: 'Palmares do Sul', state_id: 21 },
  { id: 3991, city_name: 'Palmeira das Missões', state_id: 21 },
  { id: 3992, city_name: 'Palmitinho', state_id: 21 },
  { id: 3993, city_name: 'Panambi', state_id: 21 },
  { id: 3994, city_name: 'Pantano Grande', state_id: 21 },
  { id: 3995, city_name: 'Paraí', state_id: 21 },
  { id: 3996, city_name: 'Paraíso do Sul', state_id: 21 },
  { id: 3997, city_name: 'Pareci Novo', state_id: 21 },
  { id: 3998, city_name: 'Parobé', state_id: 21 },
  { id: 3999, city_name: 'Passa Sete', state_id: 21 },
  { id: 4000, city_name: 'Passo do Sobrado', state_id: 21 },
  { id: 4001, city_name: 'Passo Fundo', state_id: 21 },
  { id: 4002, city_name: 'Paulo Bento', state_id: 21 },
  { id: 4003, city_name: 'Paverama', state_id: 21 },
  { id: 4004, city_name: 'Pedras Altas', state_id: 21 },
  { id: 4005, city_name: 'Pedro Osório', state_id: 21 },
  { id: 4006, city_name: 'Pejuçara', state_id: 21 },
  { id: 4007, city_name: 'Pelotas', state_id: 21 },
  { id: 4008, city_name: 'Picada Café', state_id: 21 },
  { id: 4009, city_name: 'Pinhal', state_id: 21 },
  { id: 4010, city_name: 'Pinhal da Serra', state_id: 21 },
  { id: 4011, city_name: 'Pinhal Grande', state_id: 21 },
  { id: 4012, city_name: 'Pinheirinho do Vale', state_id: 21 },
  { id: 4013, city_name: 'Pinheiro Machado', state_id: 21 },
  { id: 4014, city_name: 'Pinto Bandeira', state_id: 21 },
  { id: 4015, city_name: 'Pirapó', state_id: 21 },
  { id: 4016, city_name: 'Piratini', state_id: 21 },
  { id: 4017, city_name: 'Poço das Antas', state_id: 21 },
  { id: 4018, city_name: 'Pontão', state_id: 21 },
  { id: 4019, city_name: 'Ponte Preta', state_id: 21 },
  { id: 4020, city_name: 'Portão', state_id: 21 },
  { id: 4021, city_name: 'Porto Alegre', state_id: 21 },
  { id: 4022, city_name: 'Porto Lucena', state_id: 21 },
  { id: 4023, city_name: 'Porto Mauá', state_id: 21 },
  { id: 4024, city_name: 'Porto Vera Cruz', state_id: 21 },
  { id: 4025, city_name: 'Porto Xavier', state_id: 21 },
  { id: 4026, city_name: 'Pouso Novo', state_id: 21 },
  { id: 4027, city_name: 'Presidente Lucena', state_id: 21 },
  { id: 4028, city_name: 'Progresso', state_id: 21 },
  { id: 4029, city_name: 'Protásio Alves', state_id: 21 },
  { id: 4030, city_name: 'Putinga', state_id: 21 },
  { id: 4031, city_name: 'Quaraí', state_id: 21 },
  { id: 4032, city_name: 'Quatro Irmãos', state_id: 21 },
  { id: 4033, city_name: 'Quevedos', state_id: 21 },
  { id: 4034, city_name: 'Quinze de Novembro', state_id: 21 },
  { id: 4035, city_name: 'Redentora', state_id: 21 },
  { id: 4036, city_name: 'Relvado', state_id: 21 },
  { id: 4037, city_name: 'Restinga Sêca', state_id: 21 },
  { id: 4038, city_name: 'Rio dos Índios', state_id: 21 },
  { id: 4039, city_name: 'Rio Grande', state_id: 21 },
  { id: 4040, city_name: 'Rio Pardo', state_id: 21 },
  { id: 4041, city_name: 'Riozinho', state_id: 21 },
  { id: 4042, city_name: 'Roca Sales', state_id: 21 },
  { id: 4043, city_name: 'Rodeio Bonito', state_id: 21 },
  { id: 4044, city_name: 'Rolador', state_id: 21 },
  { id: 4045, city_name: 'Rolante', state_id: 21 },
  { id: 4046, city_name: 'Ronda Alta', state_id: 21 },
  { id: 4047, city_name: 'Rondinha', state_id: 21 },
  { id: 4048, city_name: 'Roque Gonzales', state_id: 21 },
  { id: 4049, city_name: 'Rosário do Sul', state_id: 21 },
  { id: 4050, city_name: 'Sagrada Família', state_id: 21 },
  { id: 4051, city_name: 'Saldanha Marinho', state_id: 21 },
  { id: 4052, city_name: 'Salto do Jacuí', state_id: 21 },
  { id: 4053, city_name: 'Salvador das Missões', state_id: 21 },
  { id: 4054, city_name: 'Salvador do Sul', state_id: 21 },
  { id: 4055, city_name: 'Sananduva', state_id: 21 },
  { id: 4056, city_name: 'Santa Bárbara do Sul', state_id: 21 },
  { id: 4057, city_name: 'Santa Cecília do Sul', state_id: 21 },
  { id: 4058, city_name: 'Santa Clara do Sul', state_id: 21 },
  { id: 4059, city_name: 'Santa Cruz do Sul', state_id: 21 },
  { id: 4060, city_name: 'Santa Margarida do Sul', state_id: 21 },
  { id: 4061, city_name: 'Santa Maria do Herval', state_id: 21 },
  { id: 4062, city_name: 'Santa Rosa', state_id: 21 },
  { id: 4063, city_name: 'Santa Tereza', state_id: 21 },
  { id: 4064, city_name: 'Santa Vitória do Palmar', state_id: 21 },
  { id: 4065, city_name: 'Santana da Boa Vista', state_id: 21 },
  { id: 4066, city_name: "Sant'Ana do Livramento", state_id: 21 },
  { id: 4067, city_name: 'Santiago', state_id: 21 },
  { id: 4068, city_name: 'Santo Ângelo', state_id: 21 },
  { id: 4069, city_name: 'Santo Antônio da Patrulha', state_id: 21 },
  { id: 4070, city_name: 'Santo Antônio das Missões', state_id: 21 },
  { id: 4071, city_name: 'Santo Antônio do Palma', state_id: 21 },
  { id: 4072, city_name: 'Santo Antônio do Planalto', state_id: 21 },
  { id: 4073, city_name: 'Santo Augusto', state_id: 21 },
  { id: 4074, city_name: 'Santo Cristo', state_id: 21 },
  { id: 4075, city_name: 'Santo Expedito do Sul', state_id: 21 },
  { id: 4076, city_name: 'São Borja', state_id: 21 },
  { id: 4077, city_name: 'São Domingos do Sul', state_id: 21 },
  { id: 4078, city_name: 'São Francisco de Assis', state_id: 21 },
  { id: 4079, city_name: 'São Jerônimo', state_id: 21 },
  { id: 4080, city_name: 'São João da Urtiga', state_id: 21 },
  { id: 4081, city_name: 'São João do Polêsine', state_id: 21 },
  { id: 4082, city_name: 'São Jorge', state_id: 21 },
  { id: 4083, city_name: 'São José das Missões', state_id: 21 },
  { id: 4084, city_name: 'São José do Herval', state_id: 21 },
  { id: 4085, city_name: 'São José do Hortêncio', state_id: 21 },
  { id: 4086, city_name: 'São José do Inhacorá', state_id: 21 },
  { id: 4087, city_name: 'São José do Norte', state_id: 21 },
  { id: 4088, city_name: 'São José do Ouro', state_id: 21 },
  { id: 4089, city_name: 'São José do Sul', state_id: 21 },
  { id: 4090, city_name: 'São José dos Ausentes', state_id: 21 },
  { id: 4091, city_name: 'São Leopoldo', state_id: 21 },
  { id: 4092, city_name: 'São Lourenço do Sul', state_id: 21 },
  { id: 4093, city_name: 'São Luiz Gonzaga', state_id: 21 },
  { id: 4094, city_name: 'São Marcos', state_id: 21 },
  { id: 4095, city_name: 'São Martinho', state_id: 21 },
  { id: 4096, city_name: 'São Martinho da Serra', state_id: 21 },
  { id: 4097, city_name: 'São Miguel das Missões', state_id: 21 },
  { id: 4098, city_name: 'São Nicolau', state_id: 21 },
  { id: 4099, city_name: 'São Paulo das Missões', state_id: 21 },
  { id: 4100, city_name: 'São Pedro da Serra', state_id: 21 },
  { id: 4101, city_name: 'São Pedro das Missões', state_id: 21 },
  { id: 4102, city_name: 'São Pedro do Butiá', state_id: 21 },
  { id: 4103, city_name: 'São Pedro do Sul', state_id: 21 },
  { id: 4104, city_name: 'São Sebastião do Caí', state_id: 21 },
  { id: 4105, city_name: 'São Sepé', state_id: 21 },
  { id: 4106, city_name: 'São Valentim', state_id: 21 },
  { id: 4107, city_name: 'São Valentim do Sul', state_id: 21 },
  { id: 4108, city_name: 'São Valério do Sul', state_id: 21 },
  { id: 4109, city_name: 'São Vendelino', state_id: 21 },
  { id: 4110, city_name: 'São Vicente do Sul', state_id: 21 },
  { id: 4111, city_name: 'Sapiranga', state_id: 21 },
  { id: 4112, city_name: 'Sapucaia do Sul', state_id: 21 },
  { id: 4113, city_name: 'Seberi', state_id: 21 },
  { id: 4114, city_name: 'Sede Nova', state_id: 21 },
  { id: 4115, city_name: 'Segredo', state_id: 21 },
  { id: 4116, city_name: 'Selbach', state_id: 21 },
  { id: 4117, city_name: 'Senador Salgado Filho', state_id: 21 },
  { id: 4118, city_name: 'Sentinela do Sul', state_id: 21 },
  { id: 4119, city_name: 'Serafina Corrêa', state_id: 21 },
  { id: 4120, city_name: 'Sério', state_id: 21 },
  { id: 4121, city_name: 'Sertão', state_id: 21 },
  { id: 4122, city_name: 'Sertão Santana', state_id: 21 },
  { id: 4123, city_name: 'Sete de Setembro', state_id: 21 },
  { id: 4124, city_name: 'Severiano de Almeida', state_id: 21 },
  { id: 4125, city_name: 'Silveira Martins', state_id: 21 },
  { id: 4126, city_name: 'Sinimbu', state_id: 21 },
  { id: 4127, city_name: 'Tabaí', state_id: 21 },
  { id: 4128, city_name: 'Tapera', state_id: 21 },
  { id: 4129, city_name: 'Tapes', state_id: 21 },
  { id: 4130, city_name: 'Taquara', state_id: 21 },
  { id: 4131, city_name: 'Taquari', state_id: 21 },
  { id: 4132, city_name: 'Taquaruçu do Sul', state_id: 21 },
  { id: 4133, city_name: 'Tenente Portela', state_id: 21 },
  { id: 4134, city_name: 'Terra de Areia', state_id: 21 },
  { id: 4135, city_name: 'Teutônia', state_id: 21 },
  { id: 4136, city_name: 'Tio Hugo', state_id: 21 },
  { id: 4137, city_name: 'Tiradentes do Sul', state_id: 21 },
  { id: 4138, city_name: 'Toropi', state_id: 21 },
  { id: 4139, city_name: 'Torres', state_id: 21 },
  { id: 4140, city_name: 'Tramandaí', state_id: 21 },
  { id: 4141, city_name: 'Travesseiro', state_id: 21 },
  { id: 4142, city_name: 'Três Arroios', state_id: 21 },
  { id: 4143, city_name: 'Três Cachoeiras', state_id: 21 },
  { id: 4144, city_name: 'Três Coroas', state_id: 21 },
  { id: 4145, city_name: 'Três de Maio', state_id: 21 },
  { id: 4146, city_name: 'Três Forquilhas', state_id: 21 },
  { id: 4147, city_name: 'Três Palmeiras', state_id: 21 },
  { id: 4148, city_name: 'Três Passos', state_id: 21 },
  { id: 4149, city_name: 'Trindade do Sul', state_id: 21 },
  { id: 4150, city_name: 'Tucunduva', state_id: 21 },
  { id: 4151, city_name: 'Tunas', state_id: 21 },
  { id: 4152, city_name: 'Tupanci do Sul', state_id: 21 },
  { id: 4153, city_name: 'Tupanciretã', state_id: 21 },
  { id: 4154, city_name: 'Tupandi', state_id: 21 },
  { id: 4155, city_name: 'Tuparendi', state_id: 21 },
  { id: 4156, city_name: 'Turuçu', state_id: 21 },
  { id: 4157, city_name: 'Ubiretama', state_id: 21 },
  { id: 4158, city_name: 'União da Serra', state_id: 21 },
  { id: 4159, city_name: 'Unistalda', state_id: 21 },
  { id: 4160, city_name: 'Uruguaiana', state_id: 21 },
  { id: 4161, city_name: 'Vacaria', state_id: 21 },
  { id: 4162, city_name: 'Vale do Sol', state_id: 21 },
  { id: 4163, city_name: 'Vale Real', state_id: 21 },
  { id: 4164, city_name: 'Vale Verde', state_id: 21 },
  { id: 4165, city_name: 'Vanini', state_id: 21 },
  { id: 4166, city_name: 'Venâncio Aires', state_id: 21 },
  { id: 4167, city_name: 'Veranópolis', state_id: 21 },
  { id: 4168, city_name: 'Vespasiano Corrêa', state_id: 21 },
  { id: 4169, city_name: 'Viadutos', state_id: 21 },
  { id: 4170, city_name: 'Viamão', state_id: 21 },
  { id: 4171, city_name: 'Vicente Dutra', state_id: 21 },
  { id: 4172, city_name: 'Victor Graeff', state_id: 21 },
  { id: 4173, city_name: 'Vila Flores', state_id: 21 },
  { id: 4174, city_name: 'Vila Lângaro', state_id: 21 },
  { id: 4175, city_name: 'Vila Maria', state_id: 21 },
  { id: 4176, city_name: 'Vila Nova do Sul', state_id: 21 },
  { id: 4177, city_name: 'Vista Alegre', state_id: 21 },
  { id: 4178, city_name: 'Vista Alegre do Prata', state_id: 21 },
  { id: 4179, city_name: 'Vista Gaúcha', state_id: 21 },
  { id: 4180, city_name: 'Vitória das Missões', state_id: 21 },
  { id: 4181, city_name: 'Westfália', state_id: 21 },
  { id: 4182, city_name: 'Xangri-lá', state_id: 21 },
  { id: 4183, city_name: "Alta Floresta D'Oeste", state_id: 22 },
  { id: 4184, city_name: 'Alto Alegre dos Parecis', state_id: 22 },
  { id: 4185, city_name: "Alvorada D'Oeste", state_id: 22 },
  { id: 4186, city_name: 'Ariquemes', state_id: 22 },
  { id: 4187, city_name: 'Cabixi', state_id: 22 },
  { id: 4188, city_name: 'Cacaulândia', state_id: 22 },
  { id: 4189, city_name: 'Cacoal', state_id: 22 },
  { id: 4190, city_name: 'Campo Novo de Rondônia', state_id: 22 },
  { id: 4191, city_name: 'Candeias do Jamari', state_id: 22 },
  { id: 4192, city_name: 'Castanheiras', state_id: 22 },
  { id: 4193, city_name: 'Cerejeiras', state_id: 22 },
  { id: 4194, city_name: 'Chupinguaia', state_id: 22 },
  { id: 4195, city_name: 'Colorado do Oeste', state_id: 22 },
  { id: 4196, city_name: 'Corumbiara', state_id: 22 },
  { id: 4197, city_name: 'Costa Marques', state_id: 22 },
  { id: 4198, city_name: 'Cujubim', state_id: 22 },
  { id: 4199, city_name: "Espigão D'Oeste", state_id: 22 },
  { id: 4200, city_name: 'Governador Jorge Teixeira', state_id: 22 },
  { id: 4201, city_name: 'Guajará-Mirim', state_id: 22 },
  { id: 4202, city_name: 'Itapuã do Oeste', state_id: 22 },
  { id: 4203, city_name: 'Jaru', state_id: 22 },
  { id: 4204, city_name: 'Ji-Paraná', state_id: 22 },
  { id: 4205, city_name: "Machadinho D'Oeste", state_id: 22 },
  { id: 4206, city_name: 'Ministro Andreazza', state_id: 22 },
  { id: 4207, city_name: 'Mirante da Serra', state_id: 22 },
  { id: 4208, city_name: 'Monte Negro', state_id: 22 },
  { id: 4209, city_name: "Nova Brasilândia D'Oeste", state_id: 22 },
  { id: 4210, city_name: 'Nova Mamoré', state_id: 22 },
  { id: 4211, city_name: 'Novo Horizonte do Oeste', state_id: 22 },
  { id: 4212, city_name: 'Ouro Preto do Oeste', state_id: 22 },
  { id: 4213, city_name: 'Parecis', state_id: 22 },
  { id: 4214, city_name: 'Pimenta Bueno', state_id: 22 },
  { id: 4215, city_name: 'Pimenteiras do Oeste', state_id: 22 },
  { id: 4216, city_name: 'Porto Velho', state_id: 22 },
  { id: 4217, city_name: 'Primavera de Rondônia', state_id: 22 },
  { id: 4218, city_name: 'Rio Crespo', state_id: 22 },
  { id: 4219, city_name: 'Rolim de Moura', state_id: 22 },
  { id: 4220, city_name: "Santa Luzia D'Oeste", state_id: 22 },
  { id: 4221, city_name: "São Felipe D'Oeste", state_id: 22 },
  { id: 4222, city_name: 'São Francisco do Guaporé', state_id: 22 },
  { id: 4223, city_name: 'São Miguel do Guaporé', state_id: 22 },
  { id: 4224, city_name: 'Seringueiras', state_id: 22 },
  { id: 4225, city_name: 'Teixeirópolis', state_id: 22 },
  { id: 4226, city_name: 'Theobroma', state_id: 22 },
  { id: 4227, city_name: 'Urupá', state_id: 22 },
  { id: 4228, city_name: 'Vale do Anari', state_id: 22 },
  { id: 4229, city_name: 'Vale do Paraíso', state_id: 22 },
  { id: 4230, city_name: 'Vilhena', state_id: 22 },
  { id: 4231, city_name: 'Amajari', state_id: 23 },
  { id: 4232, city_name: 'Cantá', state_id: 23 },
  { id: 4233, city_name: 'Caracaraí', state_id: 23 },
  { id: 4234, city_name: 'Caroebe', state_id: 23 },
  { id: 4235, city_name: 'Mucajaí', state_id: 23 },
  { id: 4236, city_name: 'Normandia', state_id: 23 },
  { id: 4237, city_name: 'Pacaraima', state_id: 23 },
  { id: 4238, city_name: 'Rorainópolis', state_id: 23 },
  { id: 4239, city_name: 'São João da Baliza', state_id: 23 },
  { id: 4240, city_name: 'São Luiz', state_id: 23 },
  { id: 4241, city_name: 'Uiramutã', state_id: 23 },
  { id: 4242, city_name: 'Abdon Batista', state_id: 24 },
  { id: 4243, city_name: 'Abelardo Luz', state_id: 24 },
  { id: 4244, city_name: 'Agrolândia', state_id: 24 },
  { id: 4245, city_name: 'Agronômica', state_id: 24 },
  { id: 4246, city_name: 'Água Doce', state_id: 24 },
  { id: 4247, city_name: 'Águas de Chapecó', state_id: 24 },
  { id: 4248, city_name: 'Águas Frias', state_id: 24 },
  { id: 4249, city_name: 'Águas Mornas', state_id: 24 },
  { id: 4250, city_name: 'Alfredo Wagner', state_id: 24 },
  { id: 4251, city_name: 'Alto Bela Vista', state_id: 24 },
  { id: 4252, city_name: 'Angelina', state_id: 24 },
  { id: 4253, city_name: 'Anita Garibaldi', state_id: 24 },
  { id: 4254, city_name: 'Anitápolis', state_id: 24 },
  { id: 4255, city_name: 'Apiúna', state_id: 24 },
  { id: 4256, city_name: 'Arabutã', state_id: 24 },
  { id: 4257, city_name: 'Araquari', state_id: 24 },
  { id: 4258, city_name: 'Araranguá', state_id: 24 },
  { id: 4259, city_name: 'Armazém', state_id: 24 },
  { id: 4260, city_name: 'Arroio Trinta', state_id: 24 },
  { id: 4261, city_name: 'Arvoredo', state_id: 24 },
  { id: 4262, city_name: 'Ascurra', state_id: 24 },
  { id: 4263, city_name: 'Atalanta', state_id: 24 },
  { id: 4264, city_name: 'Balneário Arroio do Silva', state_id: 24 },
  { id: 4265, city_name: 'Balneário Barra do Sul', state_id: 24 },
  { id: 4266, city_name: 'Balneário Camboriú', state_id: 24 },
  { id: 4267, city_name: 'Balneário Gaivota', state_id: 24 },
  { id: 4268, city_name: 'Balneário Piçarras', state_id: 24 },
  { id: 4269, city_name: 'Balneário Rincão', state_id: 24 },
  { id: 4270, city_name: 'Bandeirante', state_id: 24 },
  { id: 4271, city_name: 'Barra Bonita', state_id: 24 },
  { id: 4272, city_name: 'Barra Velha', state_id: 24 },
  { id: 4273, city_name: 'Bela Vista do Toldo', state_id: 24 },
  { id: 4274, city_name: 'Benedito Novo', state_id: 24 },
  { id: 4275, city_name: 'Biguaçu', state_id: 24 },
  { id: 4276, city_name: 'Blumenau', state_id: 24 },
  { id: 4277, city_name: 'Bocaina do Sul', state_id: 24 },
  { id: 4278, city_name: 'Bom Jardim da Serra', state_id: 24 },
  { id: 4279, city_name: 'Bom Jesus do Oeste', state_id: 24 },
  { id: 4280, city_name: 'Bom Retiro', state_id: 24 },
  { id: 4281, city_name: 'Bombinhas', state_id: 24 },
  { id: 4282, city_name: 'Botuverá', state_id: 24 },
  { id: 4283, city_name: 'Braço do Norte', state_id: 24 },
  { id: 4284, city_name: 'Braço do Trombudo', state_id: 24 },
  { id: 4285, city_name: 'Brunópolis', state_id: 24 },
  { id: 4286, city_name: 'Brusque', state_id: 24 },
  { id: 4287, city_name: 'Caçador', state_id: 24 },
  { id: 4288, city_name: 'Caibi', state_id: 24 },
  { id: 4289, city_name: 'Calmon', state_id: 24 },
  { id: 4290, city_name: 'Camboriú', state_id: 24 },
  { id: 4291, city_name: 'Campo Belo do Sul', state_id: 24 },
  { id: 4292, city_name: 'Campo Erê', state_id: 24 },
  { id: 4293, city_name: 'Campos Novos', state_id: 24 },
  { id: 4294, city_name: 'Canelinha', state_id: 24 },
  { id: 4295, city_name: 'Canoinhas', state_id: 24 },
  { id: 4296, city_name: 'Capão Alto', state_id: 24 },
  { id: 4297, city_name: 'Capinzal', state_id: 24 },
  { id: 4298, city_name: 'Capivari de Baixo', state_id: 24 },
  { id: 4299, city_name: 'Caxambu do Sul', state_id: 24 },
  { id: 4300, city_name: 'Celso Ramos', state_id: 24 },
  { id: 4301, city_name: 'Cerro Negro', state_id: 24 },
  { id: 4302, city_name: 'Chapadão do Lageado', state_id: 24 },
  { id: 4303, city_name: 'Chapecó', state_id: 24 },
  { id: 4304, city_name: 'Cocal do Sul', state_id: 24 },
  { id: 4305, city_name: 'Concórdia', state_id: 24 },
  { id: 4306, city_name: 'Cordilheira Alta', state_id: 24 },
  { id: 4307, city_name: 'Coronel Freitas', state_id: 24 },
  { id: 4308, city_name: 'Coronel Martins', state_id: 24 },
  { id: 4309, city_name: 'Correia Pinto', state_id: 24 },
  { id: 4310, city_name: 'Corupá', state_id: 24 },
  { id: 4311, city_name: 'Criciúma', state_id: 24 },
  { id: 4312, city_name: 'Cunha Porã', state_id: 24 },
  { id: 4313, city_name: 'Cunhataí', state_id: 24 },
  { id: 4314, city_name: 'Curitibanos', state_id: 24 },
  { id: 4315, city_name: 'Descanso', state_id: 24 },
  { id: 4316, city_name: 'Dionísio Cerqueira', state_id: 24 },
  { id: 4317, city_name: 'Dona Emma', state_id: 24 },
  { id: 4318, city_name: 'Doutor Pedrinho', state_id: 24 },
  { id: 4319, city_name: 'Ermo', state_id: 24 },
  { id: 4320, city_name: 'Erval Velho', state_id: 24 },
  { id: 4321, city_name: 'Faxinal dos Guedes', state_id: 24 },
  { id: 4322, city_name: 'Flor do Sertão', state_id: 24 },
  { id: 4323, city_name: 'Florianópolis', state_id: 24 },
  { id: 4324, city_name: 'Formosa do Sul', state_id: 24 },
  { id: 4325, city_name: 'Forquilhinha', state_id: 24 },
  { id: 4326, city_name: 'Fraiburgo', state_id: 24 },
  { id: 4327, city_name: 'Frei Rogério', state_id: 24 },
  { id: 4328, city_name: 'Galvão', state_id: 24 },
  { id: 4329, city_name: 'Garopaba', state_id: 24 },
  { id: 4330, city_name: 'Garuva', state_id: 24 },
  { id: 4331, city_name: 'Gaspar', state_id: 24 },
  { id: 4332, city_name: 'Governador Celso Ramos', state_id: 24 },
  { id: 4333, city_name: 'Grão-Pará', state_id: 24 },
  { id: 4334, city_name: 'Gravatal', state_id: 24 },
  { id: 4335, city_name: 'Guabiruba', state_id: 24 },
  { id: 4336, city_name: 'Guaramirim', state_id: 24 },
  { id: 4337, city_name: 'Guarujá do Sul', state_id: 24 },
  { id: 4338, city_name: 'Guatambú', state_id: 24 },
  { id: 4339, city_name: "Herval d'Oeste", state_id: 24 },
  { id: 4340, city_name: 'Ibiam', state_id: 24 },
  { id: 4341, city_name: 'Ibicaré', state_id: 24 },
  { id: 4342, city_name: 'Ibirama', state_id: 24 },
  { id: 4343, city_name: 'Içara', state_id: 24 },
  { id: 4344, city_name: 'Ilhota', state_id: 24 },
  { id: 4345, city_name: 'Imaruí', state_id: 24 },
  { id: 4346, city_name: 'Imbituba', state_id: 24 },
  { id: 4347, city_name: 'Imbuia', state_id: 24 },
  { id: 4348, city_name: 'Indaial', state_id: 24 },
  { id: 4349, city_name: 'Iomerê', state_id: 24 },
  { id: 4350, city_name: 'Ipira', state_id: 24 },
  { id: 4351, city_name: 'Iporã do Oeste', state_id: 24 },
  { id: 4352, city_name: 'Ipuaçu', state_id: 24 },
  { id: 4353, city_name: 'Ipumirim', state_id: 24 },
  { id: 4354, city_name: 'Iraceminha', state_id: 24 },
  { id: 4355, city_name: 'Irani', state_id: 24 },
  { id: 4356, city_name: 'Irineópolis', state_id: 24 },
  { id: 4357, city_name: 'Itá', state_id: 24 },
  { id: 4358, city_name: 'Itaiópolis', state_id: 24 },
  { id: 4359, city_name: 'Itajaí', state_id: 24 },
  { id: 4360, city_name: 'Itapema', state_id: 24 },
  { id: 4361, city_name: 'Itapoá', state_id: 24 },
  { id: 4362, city_name: 'Ituporanga', state_id: 24 },
  { id: 4363, city_name: 'Jaborá', state_id: 24 },
  { id: 4364, city_name: 'Jacinto Machado', state_id: 24 },
  { id: 4365, city_name: 'Jaguaruna', state_id: 24 },
  { id: 4366, city_name: 'Jaraguá do Sul', state_id: 24 },
  { id: 4367, city_name: 'Jardinópolis', state_id: 24 },
  { id: 4368, city_name: 'Joaçaba', state_id: 24 },
  { id: 4369, city_name: 'Joinville', state_id: 24 },
  { id: 4370, city_name: 'José Boiteux', state_id: 24 },
  { id: 4371, city_name: 'Jupiá', state_id: 24 },
  { id: 4372, city_name: 'Lacerdópolis', state_id: 24 },
  { id: 4373, city_name: 'Lages', state_id: 24 },
  { id: 4374, city_name: 'Laguna', state_id: 24 },
  { id: 4375, city_name: 'Lajeado Grande', state_id: 24 },
  { id: 4376, city_name: 'Laurentino', state_id: 24 },
  { id: 4377, city_name: 'Lauro Müller', state_id: 24 },
  { id: 4378, city_name: 'Lebon Régis', state_id: 24 },
  { id: 4379, city_name: 'Leoberto Leal', state_id: 24 },
  { id: 4380, city_name: 'Lindóia do Sul', state_id: 24 },
  { id: 4381, city_name: 'Lontras', state_id: 24 },
  { id: 4382, city_name: 'Luiz Alves', state_id: 24 },
  { id: 4383, city_name: 'Luzerna', state_id: 24 },
  { id: 4384, city_name: 'Macieira', state_id: 24 },
  { id: 4385, city_name: 'Mafra', state_id: 24 },
  { id: 4386, city_name: 'Major Gercino', state_id: 24 },
  { id: 4387, city_name: 'Major Vieira', state_id: 24 },
  { id: 4388, city_name: 'Maracajá', state_id: 24 },
  { id: 4389, city_name: 'Marema', state_id: 24 },
  { id: 4390, city_name: 'Matos Costa', state_id: 24 },
  { id: 4391, city_name: 'Meleiro', state_id: 24 },
  { id: 4392, city_name: 'Mirim Doce', state_id: 24 },
  { id: 4393, city_name: 'Modelo', state_id: 24 },
  { id: 4394, city_name: 'Mondaí', state_id: 24 },
  { id: 4395, city_name: 'Monte Carlo', state_id: 24 },
  { id: 4396, city_name: 'Monte Castelo', state_id: 24 },
  { id: 4397, city_name: 'Morro da Fumaça', state_id: 24 },
  { id: 4398, city_name: 'Morro Grande', state_id: 24 },
  { id: 4399, city_name: 'Navegantes', state_id: 24 },
  { id: 4400, city_name: 'Nova Erechim', state_id: 24 },
  { id: 4401, city_name: 'Nova Itaberaba', state_id: 24 },
  { id: 4402, city_name: 'Nova Trento', state_id: 24 },
  { id: 4403, city_name: 'Orleans', state_id: 24 },
  { id: 4404, city_name: 'Otacílio Costa', state_id: 24 },
  { id: 4405, city_name: 'Ouro', state_id: 24 },
  { id: 4406, city_name: 'Ouro Verde', state_id: 24 },
  { id: 4407, city_name: 'Paial', state_id: 24 },
  { id: 4408, city_name: 'Painel', state_id: 24 },
  { id: 4409, city_name: 'Palhoça', state_id: 24 },
  { id: 4410, city_name: 'Palma Sola', state_id: 24 },
  { id: 4411, city_name: 'Palmitos', state_id: 24 },
  { id: 4412, city_name: 'Papanduva', state_id: 24 },
  { id: 4413, city_name: 'Paraíso', state_id: 24 },
  { id: 4414, city_name: 'Passo de Torres', state_id: 24 },
  { id: 4415, city_name: 'Passos Maia', state_id: 24 },
  { id: 4416, city_name: 'Paulo Lopes', state_id: 24 },
  { id: 4417, city_name: 'Pedras Grandes', state_id: 24 },
  { id: 4418, city_name: 'Penha', state_id: 24 },
  { id: 4419, city_name: 'Peritiba', state_id: 24 },
  { id: 4420, city_name: 'Pescaria Brava', state_id: 24 },
  { id: 4421, city_name: 'Pinhalzinho', state_id: 24 },
  { id: 4422, city_name: 'Pinheiro Preto', state_id: 24 },
  { id: 4423, city_name: 'Piratuba', state_id: 24 },
  { id: 4424, city_name: 'Planalto Alegre', state_id: 24 },
  { id: 4425, city_name: 'Pomerode', state_id: 24 },
  { id: 4426, city_name: 'Ponte Alta', state_id: 24 },
  { id: 4427, city_name: 'Ponte Alta do Norte', state_id: 24 },
  { id: 4428, city_name: 'Ponte Serrada', state_id: 24 },
  { id: 4429, city_name: 'Porto Belo', state_id: 24 },
  { id: 4430, city_name: 'Porto União', state_id: 24 },
  { id: 4431, city_name: 'Pouso Redondo', state_id: 24 },
  { id: 4432, city_name: 'Praia Grande', state_id: 24 },
  { id: 4433, city_name: 'Presidente Castello Branco', state_id: 24 },
  { id: 4434, city_name: 'Presidente Getúlio', state_id: 24 },
  { id: 4435, city_name: 'Presidente Nereu', state_id: 24 },
  { id: 4436, city_name: 'Princesa', state_id: 24 },
  { id: 4437, city_name: 'Quilombo', state_id: 24 },
  { id: 4438, city_name: 'Rancho Queimado', state_id: 24 },
  { id: 4439, city_name: 'Rio das Antas', state_id: 24 },
  { id: 4440, city_name: 'Rio do Campo', state_id: 24 },
  { id: 4441, city_name: 'Rio do Oeste', state_id: 24 },
  { id: 4442, city_name: 'Rio do Sul', state_id: 24 },
  { id: 4443, city_name: 'Rio dos Cedros', state_id: 24 },
  { id: 4444, city_name: 'Rio Fortuna', state_id: 24 },
  { id: 4445, city_name: 'Rio Negrinho', state_id: 24 },
  { id: 4446, city_name: 'Rio Rufino', state_id: 24 },
  { id: 4447, city_name: 'Riqueza', state_id: 24 },
  { id: 4448, city_name: 'Rodeio', state_id: 24 },
  { id: 4449, city_name: 'Romelândia', state_id: 24 },
  { id: 4450, city_name: 'Salete', state_id: 24 },
  { id: 4451, city_name: 'Saltinho', state_id: 24 },
  { id: 4452, city_name: 'Salto Veloso', state_id: 24 },
  { id: 4453, city_name: 'Sangão', state_id: 24 },
  { id: 4454, city_name: 'Santa Rosa de Lima', state_id: 24 },
  { id: 4455, city_name: 'Santa Rosa do Sul', state_id: 24 },
  { id: 4456, city_name: 'Santa Terezinha do Progresso', state_id: 24 },
  { id: 4457, city_name: 'Santiago do Sul', state_id: 24 },
  { id: 4458, city_name: 'Santo Amaro da Imperatriz', state_id: 24 },
  { id: 4459, city_name: 'São Bento do Sul', state_id: 24 },
  { id: 4460, city_name: 'São Bernardino', state_id: 24 },
  { id: 4461, city_name: 'São Bonifácio', state_id: 24 },
  { id: 4462, city_name: 'São Carlos', state_id: 24 },
  { id: 4463, city_name: 'São Cristóvão do Sul', state_id: 24 },
  { id: 4464, city_name: 'São Francisco do Sul', state_id: 24 },
  { id: 4465, city_name: 'São João do Itaperiú', state_id: 24 },
  { id: 4466, city_name: 'São João do Oeste', state_id: 24 },
  { id: 4467, city_name: 'São João do Sul', state_id: 24 },
  { id: 4468, city_name: 'São Joaquim', state_id: 24 },
  { id: 4469, city_name: 'São José', state_id: 24 },
  { id: 4470, city_name: 'São José do Cedro', state_id: 24 },
  { id: 4471, city_name: 'São José do Cerrito', state_id: 24 },
  { id: 4472, city_name: 'São Lourenço do Oeste', state_id: 24 },
  { id: 4473, city_name: 'São Ludgero', state_id: 24 },
  { id: 4474, city_name: 'São Miguel da Boa Vista', state_id: 24 },
  { id: 4475, city_name: 'São Miguel do Oeste', state_id: 24 },
  { id: 4476, city_name: 'São Pedro de Alcântara', state_id: 24 },
  { id: 4477, city_name: 'Saudades', state_id: 24 },
  { id: 4478, city_name: 'Schroeder', state_id: 24 },
  { id: 4479, city_name: 'Seara', state_id: 24 },
  { id: 4480, city_name: 'Serra Alta', state_id: 24 },
  { id: 4481, city_name: 'Siderópolis', state_id: 24 },
  { id: 4482, city_name: 'Sombrio', state_id: 24 },
  { id: 4483, city_name: 'Sul Brasil', state_id: 24 },
  { id: 4484, city_name: 'Taió', state_id: 24 },
  { id: 4485, city_name: 'Tigrinhos', state_id: 24 },
  { id: 4486, city_name: 'Tijucas', state_id: 24 },
  { id: 4487, city_name: 'Timbé do Sul', state_id: 24 },
  { id: 4488, city_name: 'Timbó', state_id: 24 },
  { id: 4489, city_name: 'Timbó Grande', state_id: 24 },
  { id: 4490, city_name: 'Três Barras', state_id: 24 },
  { id: 4491, city_name: 'Treviso', state_id: 24 },
  { id: 4492, city_name: 'Treze de Maio', state_id: 24 },
  { id: 4493, city_name: 'Treze Tílias', state_id: 24 },
  { id: 4494, city_name: 'Trombudo Central', state_id: 24 },
  { id: 4495, city_name: 'Tubarão', state_id: 24 },
  { id: 4496, city_name: 'Tunápolis', state_id: 24 },
  { id: 4497, city_name: 'União do Oeste', state_id: 24 },
  { id: 4498, city_name: 'Urubici', state_id: 24 },
  { id: 4499, city_name: 'Urupema', state_id: 24 },
  { id: 4500, city_name: 'Urussanga', state_id: 24 },
  { id: 4501, city_name: 'Vargeão', state_id: 24 },
  { id: 4502, city_name: 'Vargem', state_id: 24 },
  { id: 4503, city_name: 'Vidal Ramos', state_id: 24 },
  { id: 4504, city_name: 'Videira', state_id: 24 },
  { id: 4505, city_name: 'Vitor Meireles', state_id: 24 },
  { id: 4506, city_name: 'Witmarsum', state_id: 24 },
  { id: 4507, city_name: 'Xanxerê', state_id: 24 },
  { id: 4508, city_name: 'Xavantina', state_id: 24 },
  { id: 4509, city_name: 'Xaxim', state_id: 24 },
  { id: 4510, city_name: 'Zortéa', state_id: 24 },
  { id: 4511, city_name: 'Adamantina', state_id: 25 },
  { id: 4512, city_name: 'Adolfo', state_id: 25 },
  { id: 4513, city_name: 'Aguaí', state_id: 25 },
  { id: 4514, city_name: 'Águas da Prata', state_id: 25 },
  { id: 4515, city_name: 'Águas de Lindóia', state_id: 25 },
  { id: 4516, city_name: 'Águas de Santa Bárbara', state_id: 25 },
  { id: 4517, city_name: 'Águas de São Pedro', state_id: 25 },
  { id: 4518, city_name: 'Agudos', state_id: 25 },
  { id: 4519, city_name: 'Alambari', state_id: 25 },
  { id: 4520, city_name: 'Alfredo Marcondes', state_id: 25 },
  { id: 4521, city_name: 'Altair', state_id: 25 },
  { id: 4522, city_name: 'Altinópolis', state_id: 25 },
  { id: 4523, city_name: 'Alumínio', state_id: 25 },
  { id: 4524, city_name: 'Álvares Florence', state_id: 25 },
  { id: 4525, city_name: 'Álvares Machado', state_id: 25 },
  { id: 4526, city_name: 'Álvaro de Carvalho', state_id: 25 },
  { id: 4527, city_name: 'Alvinlândia', state_id: 25 },
  { id: 4528, city_name: 'Americana', state_id: 25 },
  { id: 4529, city_name: 'Américo Brasiliense', state_id: 25 },
  { id: 4530, city_name: 'Américo de Campos', state_id: 25 },
  { id: 4531, city_name: 'Analândia', state_id: 25 },
  { id: 4532, city_name: 'Andradina', state_id: 25 },
  { id: 4533, city_name: 'Angatuba', state_id: 25 },
  { id: 4534, city_name: 'Anhembi', state_id: 25 },
  { id: 4535, city_name: 'Anhumas', state_id: 25 },
  { id: 4536, city_name: "Aparecida d'Oeste", state_id: 25 },
  { id: 4537, city_name: 'Apiaí', state_id: 25 },
  { id: 4538, city_name: 'Araçariguama', state_id: 25 },
  { id: 4539, city_name: 'Araçatuba', state_id: 25 },
  { id: 4540, city_name: 'Araçoiaba da Serra', state_id: 25 },
  { id: 4541, city_name: 'Aramina', state_id: 25 },
  { id: 4542, city_name: 'Arandu', state_id: 25 },
  { id: 4543, city_name: 'Arapeí', state_id: 25 },
  { id: 4544, city_name: 'Araraquara', state_id: 25 },
  { id: 4545, city_name: 'Araras', state_id: 25 },
  { id: 4546, city_name: 'Arco-Íris', state_id: 25 },
  { id: 4547, city_name: 'Arealva', state_id: 25 },
  { id: 4548, city_name: 'Areias', state_id: 25 },
  { id: 4549, city_name: 'Areiópolis', state_id: 25 },
  { id: 4550, city_name: 'Ariranha', state_id: 25 },
  { id: 4551, city_name: 'Artur Nogueira', state_id: 25 },
  { id: 4552, city_name: 'Arujá', state_id: 25 },
  { id: 4553, city_name: 'Aspásia', state_id: 25 },
  { id: 4554, city_name: 'Assis', state_id: 25 },
  { id: 4555, city_name: 'Atibaia', state_id: 25 },
  { id: 4556, city_name: 'Auriflama', state_id: 25 },
  { id: 4557, city_name: 'Avaí', state_id: 25 },
  { id: 4558, city_name: 'Avanhandava', state_id: 25 },
  { id: 4559, city_name: 'Avaré', state_id: 25 },
  { id: 4560, city_name: 'Bady Bassitt', state_id: 25 },
  { id: 4561, city_name: 'Balbinos', state_id: 25 },
  { id: 4562, city_name: 'Bálsamo', state_id: 25 },
  { id: 4563, city_name: 'Bananal', state_id: 25 },
  { id: 4564, city_name: 'Barão de Antonina', state_id: 25 },
  { id: 4565, city_name: 'Barbosa', state_id: 25 },
  { id: 4566, city_name: 'Bariri', state_id: 25 },
  { id: 4567, city_name: 'Barra do Chapéu', state_id: 25 },
  { id: 4568, city_name: 'Barra do Turvo', state_id: 25 },
  { id: 4569, city_name: 'Barretos', state_id: 25 },
  { id: 4570, city_name: 'Barrinha', state_id: 25 },
  { id: 4571, city_name: 'Barueri', state_id: 25 },
  { id: 4572, city_name: 'Bastos', state_id: 25 },
  { id: 4573, city_name: 'Batatais', state_id: 25 },
  { id: 4574, city_name: 'Bauru', state_id: 25 },
  { id: 4575, city_name: 'Bebedouro', state_id: 25 },
  { id: 4576, city_name: 'Bento de Abreu', state_id: 25 },
  { id: 4577, city_name: 'Bernardino de Campos', state_id: 25 },
  { id: 4578, city_name: 'Bertioga', state_id: 25 },
  { id: 4579, city_name: 'Bilac', state_id: 25 },
  { id: 4580, city_name: 'Birigui', state_id: 25 },
  { id: 4581, city_name: 'Biritiba Mirim', state_id: 25 },
  { id: 4582, city_name: 'Boa Esperança do Sul', state_id: 25 },
  { id: 4583, city_name: 'Bofete', state_id: 25 },
  { id: 4584, city_name: 'Boituva', state_id: 25 },
  { id: 4585, city_name: 'Bom Jesus dos Perdões', state_id: 25 },
  { id: 4586, city_name: 'Bom Sucesso de Itararé', state_id: 25 },
  { id: 4587, city_name: 'Borá', state_id: 25 },
  { id: 4588, city_name: 'Boracéia', state_id: 25 },
  { id: 4589, city_name: 'Borebi', state_id: 25 },
  { id: 4590, city_name: 'Botucatu', state_id: 25 },
  { id: 4591, city_name: 'Bragança Paulista', state_id: 25 },
  { id: 4592, city_name: 'Braúna', state_id: 25 },
  { id: 4593, city_name: 'Brejo Alegre', state_id: 25 },
  { id: 4594, city_name: 'Brodowski', state_id: 25 },
  { id: 4595, city_name: 'Brotas', state_id: 25 },
  { id: 4596, city_name: 'Buri', state_id: 25 },
  { id: 4597, city_name: 'Buritama', state_id: 25 },
  { id: 4598, city_name: 'Buritizal', state_id: 25 },
  { id: 4599, city_name: 'Cabrália Paulista', state_id: 25 },
  { id: 4600, city_name: 'Cabreúva', state_id: 25 },
  { id: 4601, city_name: 'Caçapava', state_id: 25 },
  { id: 4602, city_name: 'Cachoeira Paulista', state_id: 25 },
  { id: 4603, city_name: 'Caconde', state_id: 25 },
  { id: 4604, city_name: 'Caiabu', state_id: 25 },
  { id: 4605, city_name: 'Caieiras', state_id: 25 },
  { id: 4606, city_name: 'Caiuá', state_id: 25 },
  { id: 4607, city_name: 'Cajamar', state_id: 25 },
  { id: 4608, city_name: 'Cajati', state_id: 25 },
  { id: 4609, city_name: 'Cajobi', state_id: 25 },
  { id: 4610, city_name: 'Cajuru', state_id: 25 },
  { id: 4611, city_name: 'Campina do Monte Alegre', state_id: 25 },
  { id: 4612, city_name: 'Campinas', state_id: 25 },
  { id: 4613, city_name: 'Campo Limpo Paulista', state_id: 25 },
  { id: 4614, city_name: 'Campos do Jordão', state_id: 25 },
  { id: 4615, city_name: 'Campos Novos Paulista', state_id: 25 },
  { id: 4616, city_name: 'Cananéia', state_id: 25 },
  { id: 4617, city_name: 'Canas', state_id: 25 },
  { id: 4618, city_name: 'Cândido Mota', state_id: 25 },
  { id: 4619, city_name: 'Cândido Rodrigues', state_id: 25 },
  { id: 4620, city_name: 'Canitar', state_id: 25 },
  { id: 4621, city_name: 'Capão Bonito', state_id: 25 },
  { id: 4622, city_name: 'Capela do Alto', state_id: 25 },
  { id: 4623, city_name: 'Capivari', state_id: 25 },
  { id: 4624, city_name: 'Caraguatatuba', state_id: 25 },
  { id: 4625, city_name: 'Carapicuíba', state_id: 25 },
  { id: 4626, city_name: 'Cardoso', state_id: 25 },
  { id: 4627, city_name: 'Casa Branca', state_id: 25 },
  { id: 4628, city_name: 'Cássia dos Coqueiros', state_id: 25 },
  { id: 4629, city_name: 'Castilho', state_id: 25 },
  { id: 4630, city_name: 'Catanduva', state_id: 25 },
  { id: 4631, city_name: 'Catiguá', state_id: 25 },
  { id: 4632, city_name: 'Cerqueira César', state_id: 25 },
  { id: 4633, city_name: 'Cerquilho', state_id: 25 },
  { id: 4634, city_name: 'Cesário Lange', state_id: 25 },
  { id: 4635, city_name: 'Charqueada', state_id: 25 },
  { id: 4636, city_name: 'Chavantes', state_id: 25 },
  { id: 4637, city_name: 'Clementina', state_id: 25 },
  { id: 4638, city_name: 'Colina', state_id: 25 },
  { id: 4639, city_name: 'Colômbia', state_id: 25 },
  { id: 4640, city_name: 'Conchal', state_id: 25 },
  { id: 4641, city_name: 'Conchas', state_id: 25 },
  { id: 4642, city_name: 'Cordeirópolis', state_id: 25 },
  { id: 4643, city_name: 'Coroados', state_id: 25 },
  { id: 4644, city_name: 'Coronel Macedo', state_id: 25 },
  { id: 4645, city_name: 'Corumbataí', state_id: 25 },
  { id: 4646, city_name: 'Cosmópolis', state_id: 25 },
  { id: 4647, city_name: 'Cosmorama', state_id: 25 },
  { id: 4648, city_name: 'Cotia', state_id: 25 },
  { id: 4649, city_name: 'Cravinhos', state_id: 25 },
  { id: 4650, city_name: 'Cristais Paulista', state_id: 25 },
  { id: 4651, city_name: 'Cruzália', state_id: 25 },
  { id: 4652, city_name: 'Cruzeiro', state_id: 25 },
  { id: 4653, city_name: 'Cubatão', state_id: 25 },
  { id: 4654, city_name: 'Cunha', state_id: 25 },
  { id: 4655, city_name: 'Descalvado', state_id: 25 },
  { id: 4656, city_name: 'Diadema', state_id: 25 },
  { id: 4657, city_name: 'Dirce Reis', state_id: 25 },
  { id: 4658, city_name: 'Divinolândia', state_id: 25 },
  { id: 4659, city_name: 'Dobrada', state_id: 25 },
  { id: 4660, city_name: 'Dois Córregos', state_id: 25 },
  { id: 4661, city_name: 'Dolcinópolis', state_id: 25 },
  { id: 4662, city_name: 'Dourado', state_id: 25 },
  { id: 4663, city_name: 'Dracena', state_id: 25 },
  { id: 4664, city_name: 'Duartina', state_id: 25 },
  { id: 4665, city_name: 'Dumont', state_id: 25 },
  { id: 4666, city_name: 'Echaporã', state_id: 25 },
  { id: 4667, city_name: 'Elias Fausto', state_id: 25 },
  { id: 4668, city_name: 'Elisiário', state_id: 25 },
  { id: 4669, city_name: 'Embaúba', state_id: 25 },
  { id: 4670, city_name: 'Embu das Artes', state_id: 25 },
  { id: 4671, city_name: 'Embu-Guaçu', state_id: 25 },
  { id: 4672, city_name: 'Emilianópolis', state_id: 25 },
  { id: 4673, city_name: 'Engenheiro Coelho', state_id: 25 },
  { id: 4674, city_name: 'Espírito Santo do Pinhal', state_id: 25 },
  { id: 4675, city_name: 'Espírito Santo do Turvo', state_id: 25 },
  { id: 4676, city_name: 'Estiva Gerbi', state_id: 25 },
  { id: 4677, city_name: "Estrela d'Oeste", state_id: 25 },
  { id: 4678, city_name: 'Euclides da Cunha Paulista', state_id: 25 },
  { id: 4679, city_name: 'Fartura', state_id: 25 },
  { id: 4680, city_name: 'Fernando Prestes', state_id: 25 },
  { id: 4681, city_name: 'Fernandópolis', state_id: 25 },
  { id: 4682, city_name: 'Fernão', state_id: 25 },
  { id: 4683, city_name: 'Ferraz de Vasconcelos', state_id: 25 },
  { id: 4684, city_name: 'Flora Rica', state_id: 25 },
  { id: 4685, city_name: 'Floreal', state_id: 25 },
  { id: 4686, city_name: 'Flórida Paulista', state_id: 25 },
  { id: 4687, city_name: 'Florínea', state_id: 25 },
  { id: 4688, city_name: 'Franca', state_id: 25 },
  { id: 4689, city_name: 'Francisco Morato', state_id: 25 },
  { id: 4690, city_name: 'Franco da Rocha', state_id: 25 },
  { id: 4691, city_name: 'Gabriel Monteiro', state_id: 25 },
  { id: 4692, city_name: 'Gália', state_id: 25 },
  { id: 4693, city_name: 'Garça', state_id: 25 },
  { id: 4694, city_name: 'Gastão Vidigal', state_id: 25 },
  { id: 4695, city_name: 'Gavião Peixoto', state_id: 25 },
  { id: 4696, city_name: 'General Salgado', state_id: 25 },
  { id: 4697, city_name: 'Getulina', state_id: 25 },
  { id: 4698, city_name: 'Glicério', state_id: 25 },
  { id: 4699, city_name: 'Guaiçara', state_id: 25 },
  { id: 4700, city_name: 'Guaimbê', state_id: 25 },
  { id: 4701, city_name: 'Guapiaçu', state_id: 25 },
  { id: 4702, city_name: 'Guapiara', state_id: 25 },
  { id: 4703, city_name: 'Guará', state_id: 25 },
  { id: 4704, city_name: 'Guaraçaí', state_id: 25 },
  { id: 4705, city_name: "Guarani d'Oeste", state_id: 25 },
  { id: 4706, city_name: 'Guarantã', state_id: 25 },
  { id: 4707, city_name: 'Guararapes', state_id: 25 },
  { id: 4708, city_name: 'Guararema', state_id: 25 },
  { id: 4709, city_name: 'Guaratinguetá', state_id: 25 },
  { id: 4710, city_name: 'Guareí', state_id: 25 },
  { id: 4711, city_name: 'Guariba', state_id: 25 },
  { id: 4712, city_name: 'Guarujá', state_id: 25 },
  { id: 4713, city_name: 'Guarulhos', state_id: 25 },
  { id: 4714, city_name: 'Guatapará', state_id: 25 },
  { id: 4715, city_name: 'Guzolândia', state_id: 25 },
  { id: 4716, city_name: 'Herculândia', state_id: 25 },
  { id: 4717, city_name: 'Holambra', state_id: 25 },
  { id: 4718, city_name: 'Hortolândia', state_id: 25 },
  { id: 4719, city_name: 'Iacanga', state_id: 25 },
  { id: 4720, city_name: 'Iacri', state_id: 25 },
  { id: 4721, city_name: 'Iaras', state_id: 25 },
  { id: 4722, city_name: 'Ibaté', state_id: 25 },
  { id: 4723, city_name: 'Ibirá', state_id: 25 },
  { id: 4724, city_name: 'Ibirarema', state_id: 25 },
  { id: 4725, city_name: 'Ibitinga', state_id: 25 },
  { id: 4726, city_name: 'Ibiúna', state_id: 25 },
  { id: 4727, city_name: 'Icém', state_id: 25 },
  { id: 4728, city_name: 'Iepê', state_id: 25 },
  { id: 4729, city_name: 'Igaraçu do Tietê', state_id: 25 },
  { id: 4730, city_name: 'Igarapava', state_id: 25 },
  { id: 4731, city_name: 'Igaratá', state_id: 25 },
  { id: 4732, city_name: 'Iguape', state_id: 25 },
  { id: 4733, city_name: 'Ilha Comprida', state_id: 25 },
  { id: 4734, city_name: 'Ilha Solteira', state_id: 25 },
  { id: 4735, city_name: 'Ilhabela', state_id: 25 },
  { id: 4736, city_name: 'Indaiatuba', state_id: 25 },
  { id: 4737, city_name: 'Indiana', state_id: 25 },
  { id: 4738, city_name: 'Indiaporã', state_id: 25 },
  { id: 4739, city_name: 'Inúbia Paulista', state_id: 25 },
  { id: 4740, city_name: 'Ipaussu', state_id: 25 },
  { id: 4741, city_name: 'Iperó', state_id: 25 },
  { id: 4742, city_name: 'Ipeúna', state_id: 25 },
  { id: 4743, city_name: 'Ipiguá', state_id: 25 },
  { id: 4744, city_name: 'Iporanga', state_id: 25 },
  { id: 4745, city_name: 'Ipuã', state_id: 25 },
  { id: 4746, city_name: 'Iracemápolis', state_id: 25 },
  { id: 4747, city_name: 'Irapuã', state_id: 25 },
  { id: 4748, city_name: 'Irapuru', state_id: 25 },
  { id: 4749, city_name: 'Itaberá', state_id: 25 },
  { id: 4750, city_name: 'Itaí', state_id: 25 },
  { id: 4751, city_name: 'Itajobi', state_id: 25 },
  { id: 4752, city_name: 'Itaju', state_id: 25 },
  { id: 4753, city_name: 'Itanhaém', state_id: 25 },
  { id: 4754, city_name: 'Itaoca', state_id: 25 },
  { id: 4755, city_name: 'Itapecerica da Serra', state_id: 25 },
  { id: 4756, city_name: 'Itapetininga', state_id: 25 },
  { id: 4757, city_name: 'Itapevi', state_id: 25 },
  { id: 4758, city_name: 'Itapira', state_id: 25 },
  { id: 4759, city_name: 'Itapirapuã Paulista', state_id: 25 },
  { id: 4760, city_name: 'Itápolis', state_id: 25 },
  { id: 4761, city_name: 'Itapuí', state_id: 25 },
  { id: 4762, city_name: 'Itapura', state_id: 25 },
  { id: 4763, city_name: 'Itaquaquecetuba', state_id: 25 },
  { id: 4764, city_name: 'Itararé', state_id: 25 },
  { id: 4765, city_name: 'Itariri', state_id: 25 },
  { id: 4766, city_name: 'Itatiba', state_id: 25 },
  { id: 4767, city_name: 'Itatinga', state_id: 25 },
  { id: 4768, city_name: 'Itirapina', state_id: 25 },
  { id: 4769, city_name: 'Itirapuã', state_id: 25 },
  { id: 4770, city_name: 'Itobi', state_id: 25 },
  { id: 4771, city_name: 'Itu', state_id: 25 },
  { id: 4772, city_name: 'Itupeva', state_id: 25 },
  { id: 4773, city_name: 'Ituverava', state_id: 25 },
  { id: 4774, city_name: 'Jaboticabal', state_id: 25 },
  { id: 4775, city_name: 'Jacareí', state_id: 25 },
  { id: 4776, city_name: 'Jaci', state_id: 25 },
  { id: 4777, city_name: 'Jacupiranga', state_id: 25 },
  { id: 4778, city_name: 'Jaguariúna', state_id: 25 },
  { id: 4779, city_name: 'Jales', state_id: 25 },
  { id: 4780, city_name: 'Jambeiro', state_id: 25 },
  { id: 4781, city_name: 'Jandira', state_id: 25 },
  { id: 4782, city_name: 'Jarinu', state_id: 25 },
  { id: 4783, city_name: 'Jaú', state_id: 25 },
  { id: 4784, city_name: 'Jeriquara', state_id: 25 },
  { id: 4785, city_name: 'Joanópolis', state_id: 25 },
  { id: 4786, city_name: 'João Ramalho', state_id: 25 },
  { id: 4787, city_name: 'José Bonifácio', state_id: 25 },
  { id: 4788, city_name: 'Júlio Mesquita', state_id: 25 },
  { id: 4789, city_name: 'Jumirim', state_id: 25 },
  { id: 4790, city_name: 'Jundiaí', state_id: 25 },
  { id: 4791, city_name: 'Junqueirópolis', state_id: 25 },
  { id: 4792, city_name: 'Juquiá', state_id: 25 },
  { id: 4793, city_name: 'Juquitiba', state_id: 25 },
  { id: 4794, city_name: 'Lagoinha', state_id: 25 },
  { id: 4795, city_name: 'Laranjal Paulista', state_id: 25 },
  { id: 4796, city_name: 'Lavínia', state_id: 25 },
  { id: 4797, city_name: 'Lavrinhas', state_id: 25 },
  { id: 4798, city_name: 'Leme', state_id: 25 },
  { id: 4799, city_name: 'Lençóis Paulista', state_id: 25 },
  { id: 4800, city_name: 'Limeira', state_id: 25 },
  { id: 4801, city_name: 'Lindóia', state_id: 25 },
  { id: 4802, city_name: 'Lins', state_id: 25 },
  { id: 4803, city_name: 'Lorena', state_id: 25 },
  { id: 4804, city_name: 'Lourdes', state_id: 25 },
  { id: 4805, city_name: 'Louveira', state_id: 25 },
  { id: 4806, city_name: 'Lucélia', state_id: 25 },
  { id: 4807, city_name: 'Lucianópolis', state_id: 25 },
  { id: 4808, city_name: 'Luís Antônio', state_id: 25 },
  { id: 4809, city_name: 'Luiziânia', state_id: 25 },
  { id: 4810, city_name: 'Lupércio', state_id: 25 },
  { id: 4811, city_name: 'Lutécia', state_id: 25 },
  { id: 4812, city_name: 'Macatuba', state_id: 25 },
  { id: 4813, city_name: 'Macaubal', state_id: 25 },
  { id: 4814, city_name: 'Macedônia', state_id: 25 },
  { id: 4815, city_name: 'Magda', state_id: 25 },
  { id: 4816, city_name: 'Mairinque', state_id: 25 },
  { id: 4817, city_name: 'Mairiporã', state_id: 25 },
  { id: 4818, city_name: 'Manduri', state_id: 25 },
  { id: 4819, city_name: 'Marabá Paulista', state_id: 25 },
  { id: 4820, city_name: 'Maracaí', state_id: 25 },
  { id: 4821, city_name: 'Marapoama', state_id: 25 },
  { id: 4822, city_name: 'Mariápolis', state_id: 25 },
  { id: 4823, city_name: 'Marília', state_id: 25 },
  { id: 4824, city_name: 'Marinópolis', state_id: 25 },
  { id: 4825, city_name: 'Martinópolis', state_id: 25 },
  { id: 4826, city_name: 'Matão', state_id: 25 },
  { id: 4827, city_name: 'Mauá', state_id: 25 },
  { id: 4828, city_name: 'Mendonça', state_id: 25 },
  { id: 4829, city_name: 'Meridiano', state_id: 25 },
  { id: 4830, city_name: 'Mesópolis', state_id: 25 },
  { id: 4831, city_name: 'Miguelópolis', state_id: 25 },
  { id: 4832, city_name: 'Mineiros do Tietê', state_id: 25 },
  { id: 4833, city_name: 'Mira Estrela', state_id: 25 },
  { id: 4834, city_name: 'Miracatu', state_id: 25 },
  { id: 4835, city_name: 'Mirandópolis', state_id: 25 },
  { id: 4836, city_name: 'Mirante do Paranapanema', state_id: 25 },
  { id: 4837, city_name: 'Mirassol', state_id: 25 },
  { id: 4838, city_name: 'Mirassolândia', state_id: 25 },
  { id: 4839, city_name: 'Mococa', state_id: 25 },
  { id: 4840, city_name: 'Mogi das Cruzes', state_id: 25 },
  { id: 4841, city_name: 'Mogi Guaçu', state_id: 25 },
  { id: 4842, city_name: 'Mogi Mirim', state_id: 25 },
  { id: 4843, city_name: 'Mombuca', state_id: 25 },
  { id: 4844, city_name: 'Monções', state_id: 25 },
  { id: 4845, city_name: 'Mongaguá', state_id: 25 },
  { id: 4846, city_name: 'Monte Alegre do Sul', state_id: 25 },
  { id: 4847, city_name: 'Monte Alto', state_id: 25 },
  { id: 4848, city_name: 'Monte Aprazível', state_id: 25 },
  { id: 4849, city_name: 'Monte Azul Paulista', state_id: 25 },
  { id: 4850, city_name: 'Monte Mor', state_id: 25 },
  { id: 4851, city_name: 'Monteiro Lobato', state_id: 25 },
  { id: 4852, city_name: 'Morro Agudo', state_id: 25 },
  { id: 4853, city_name: 'Morungaba', state_id: 25 },
  { id: 4854, city_name: 'Motuca', state_id: 25 },
  { id: 4855, city_name: 'Murutinga do Sul', state_id: 25 },
  { id: 4856, city_name: 'Nantes', state_id: 25 },
  { id: 4857, city_name: 'Narandiba', state_id: 25 },
  { id: 4858, city_name: 'Natividade da Serra', state_id: 25 },
  { id: 4859, city_name: 'Nazaré Paulista', state_id: 25 },
  { id: 4860, city_name: 'Neves Paulista', state_id: 25 },
  { id: 4861, city_name: 'Nhandeara', state_id: 25 },
  { id: 4862, city_name: 'Nipoã', state_id: 25 },
  { id: 4863, city_name: 'Nova Aliança', state_id: 25 },
  { id: 4864, city_name: 'Nova Campina', state_id: 25 },
  { id: 4865, city_name: 'Nova Canaã Paulista', state_id: 25 },
  { id: 4866, city_name: 'Nova Castilho', state_id: 25 },
  { id: 4867, city_name: 'Nova Europa', state_id: 25 },
  { id: 4868, city_name: 'Nova Granada', state_id: 25 },
  { id: 4869, city_name: 'Nova Guataporanga', state_id: 25 },
  { id: 4870, city_name: 'Nova Independência', state_id: 25 },
  { id: 4871, city_name: 'Nova Luzitânia', state_id: 25 },
  { id: 4872, city_name: 'Nova Odessa', state_id: 25 },
  { id: 4873, city_name: 'Novais', state_id: 25 },
  { id: 4874, city_name: 'Nuporanga', state_id: 25 },
  { id: 4875, city_name: 'Ocauçu', state_id: 25 },
  { id: 4876, city_name: 'Óleo', state_id: 25 },
  { id: 4877, city_name: 'Olímpia', state_id: 25 },
  { id: 4878, city_name: 'Onda Verde', state_id: 25 },
  { id: 4879, city_name: 'Oriente', state_id: 25 },
  { id: 4880, city_name: 'Orindiúva', state_id: 25 },
  { id: 4881, city_name: 'Orlândia', state_id: 25 },
  { id: 4882, city_name: 'Osasco', state_id: 25 },
  { id: 4883, city_name: 'Oscar Bressane', state_id: 25 },
  { id: 4884, city_name: 'Osvaldo Cruz', state_id: 25 },
  { id: 4885, city_name: 'Ourinhos', state_id: 25 },
  { id: 4886, city_name: 'Ouroeste', state_id: 25 },
  { id: 4887, city_name: 'Pacaembu', state_id: 25 },
  { id: 4888, city_name: 'Palmares Paulista', state_id: 25 },
  { id: 4889, city_name: "Palmeira d'Oeste", state_id: 25 },
  { id: 4890, city_name: 'Panorama', state_id: 25 },
  { id: 4891, city_name: 'Paraguaçu Paulista', state_id: 25 },
  { id: 4892, city_name: 'Paraibuna', state_id: 25 },
  { id: 4893, city_name: 'Paranapanema', state_id: 25 },
  { id: 4894, city_name: 'Paranapuã', state_id: 25 },
  { id: 4895, city_name: 'Parapuã', state_id: 25 },
  { id: 4896, city_name: 'Pardinho', state_id: 25 },
  { id: 4897, city_name: 'Pariquera-Açu', state_id: 25 },
  { id: 4898, city_name: 'Parisi', state_id: 25 },
  { id: 4899, city_name: 'Patrocínio Paulista', state_id: 25 },
  { id: 4900, city_name: 'Paulicéia', state_id: 25 },
  { id: 4901, city_name: 'Paulínia', state_id: 25 },
  { id: 4902, city_name: 'Paulistânia', state_id: 25 },
  { id: 4903, city_name: 'Paulo de Faria', state_id: 25 },
  { id: 4904, city_name: 'Pederneiras', state_id: 25 },
  { id: 4905, city_name: 'Pedra Bela', state_id: 25 },
  { id: 4906, city_name: 'Pedranópolis', state_id: 25 },
  { id: 4907, city_name: 'Pedregulho', state_id: 25 },
  { id: 4908, city_name: 'Pedreira', state_id: 25 },
  { id: 4909, city_name: 'Pedrinhas Paulista', state_id: 25 },
  { id: 4910, city_name: 'Pedro de Toledo', state_id: 25 },
  { id: 4911, city_name: 'Penápolis', state_id: 25 },
  { id: 4912, city_name: 'Pereira Barreto', state_id: 25 },
  { id: 4913, city_name: 'Pereiras', state_id: 25 },
  { id: 4914, city_name: 'Peruíbe', state_id: 25 },
  { id: 4915, city_name: 'Piacatu', state_id: 25 },
  { id: 4916, city_name: 'Piedade', state_id: 25 },
  { id: 4917, city_name: 'Pilar do Sul', state_id: 25 },
  { id: 4918, city_name: 'Pindamonhangaba', state_id: 25 },
  { id: 4919, city_name: 'Pindorama', state_id: 25 },
  { id: 4920, city_name: 'Piquerobi', state_id: 25 },
  { id: 4921, city_name: 'Piquete', state_id: 25 },
  { id: 4922, city_name: 'Piracaia', state_id: 25 },
  { id: 4923, city_name: 'Piracicaba', state_id: 25 },
  { id: 4924, city_name: 'Piraju', state_id: 25 },
  { id: 4925, city_name: 'Pirajuí', state_id: 25 },
  { id: 4926, city_name: 'Pirangi', state_id: 25 },
  { id: 4927, city_name: 'Pirapora do Bom Jesus', state_id: 25 },
  { id: 4928, city_name: 'Pirapozinho', state_id: 25 },
  { id: 4929, city_name: 'Pirassununga', state_id: 25 },
  { id: 4930, city_name: 'Piratininga', state_id: 25 },
  { id: 4931, city_name: 'Platina', state_id: 25 },
  { id: 4932, city_name: 'Poá', state_id: 25 },
  { id: 4933, city_name: 'Poloni', state_id: 25 },
  { id: 4934, city_name: 'Pompéia', state_id: 25 },
  { id: 4935, city_name: 'Pongaí', state_id: 25 },
  { id: 4936, city_name: 'Pontal', state_id: 25 },
  { id: 4937, city_name: 'Pontalinda', state_id: 25 },
  { id: 4938, city_name: 'Pontes Gestal', state_id: 25 },
  { id: 4939, city_name: 'Populina', state_id: 25 },
  { id: 4940, city_name: 'Porangaba', state_id: 25 },
  { id: 4941, city_name: 'Porto Feliz', state_id: 25 },
  { id: 4942, city_name: 'Porto Ferreira', state_id: 25 },
  { id: 4943, city_name: 'Potim', state_id: 25 },
  { id: 4944, city_name: 'Potirendaba', state_id: 25 },
  { id: 4945, city_name: 'Pracinha', state_id: 25 },
  { id: 4946, city_name: 'Pradópolis', state_id: 25 },
  { id: 4947, city_name: 'Pratânia', state_id: 25 },
  { id: 4948, city_name: 'Presidente Alves', state_id: 25 },
  { id: 4949, city_name: 'Presidente Epitácio', state_id: 25 },
  { id: 4950, city_name: 'Presidente Prudente', state_id: 25 },
  { id: 4951, city_name: 'Presidente Venceslau', state_id: 25 },
  { id: 4952, city_name: 'Promissão', state_id: 25 },
  { id: 4953, city_name: 'Quadra', state_id: 25 },
  { id: 4954, city_name: 'Quatá', state_id: 25 },
  { id: 4955, city_name: 'Queiroz', state_id: 25 },
  { id: 4956, city_name: 'Queluz', state_id: 25 },
  { id: 4957, city_name: 'Quintana', state_id: 25 },
  { id: 4958, city_name: 'Rafard', state_id: 25 },
  { id: 4959, city_name: 'Rancharia', state_id: 25 },
  { id: 4960, city_name: 'Redenção da Serra', state_id: 25 },
  { id: 4961, city_name: 'Regente Feijó', state_id: 25 },
  { id: 4962, city_name: 'Reginópolis', state_id: 25 },
  { id: 4963, city_name: 'Registro', state_id: 25 },
  { id: 4964, city_name: 'Restinga', state_id: 25 },
  { id: 4965, city_name: 'Ribeira', state_id: 25 },
  { id: 4966, city_name: 'Ribeirão Bonito', state_id: 25 },
  { id: 4967, city_name: 'Ribeirão Branco', state_id: 25 },
  { id: 4968, city_name: 'Ribeirão Corrente', state_id: 25 },
  { id: 4969, city_name: 'Ribeirão do Sul', state_id: 25 },
  { id: 4970, city_name: 'Ribeirão dos Índios', state_id: 25 },
  { id: 4971, city_name: 'Ribeirão Grande', state_id: 25 },
  { id: 4972, city_name: 'Ribeirão Pires', state_id: 25 },
  { id: 4973, city_name: 'Ribeirão Preto', state_id: 25 },
  { id: 4974, city_name: 'Rifaina', state_id: 25 },
  { id: 4975, city_name: 'Rincão', state_id: 25 },
  { id: 4976, city_name: 'Rinópolis', state_id: 25 },
  { id: 4977, city_name: 'Rio das Pedras', state_id: 25 },
  { id: 4978, city_name: 'Rio Grande da Serra', state_id: 25 },
  { id: 4979, city_name: 'Riolândia', state_id: 25 },
  { id: 4980, city_name: 'Riversul', state_id: 25 },
  { id: 4981, city_name: 'Rosana', state_id: 25 },
  { id: 4982, city_name: 'Roseira', state_id: 25 },
  { id: 4983, city_name: 'Rubiácea', state_id: 25 },
  { id: 4984, city_name: 'Rubinéia', state_id: 25 },
  { id: 4985, city_name: 'Sabino', state_id: 25 },
  { id: 4986, city_name: 'Sagres', state_id: 25 },
  { id: 4987, city_name: 'Sales', state_id: 25 },
  { id: 4988, city_name: 'Sales Oliveira', state_id: 25 },
  { id: 4989, city_name: 'Salesópolis', state_id: 25 },
  { id: 4990, city_name: 'Salmourão', state_id: 25 },
  { id: 4991, city_name: 'Salto', state_id: 25 },
  { id: 4992, city_name: 'Salto de Pirapora', state_id: 25 },
  { id: 4993, city_name: 'Salto Grande', state_id: 25 },
  { id: 4994, city_name: 'Sandovalina', state_id: 25 },
  { id: 4995, city_name: 'Santa Adélia', state_id: 25 },
  { id: 4996, city_name: 'Santa Albertina', state_id: 25 },
  { id: 4997, city_name: "Santa Bárbara d'Oeste", state_id: 25 },
  { id: 4998, city_name: 'Santa Branca', state_id: 25 },
  { id: 4999, city_name: "Santa Clara d'Oeste", state_id: 25 },
  { id: 5000, city_name: 'Santa Cruz da Conceição', state_id: 25 },
  { id: 5001, city_name: 'Santa Cruz da Esperança', state_id: 25 },
  { id: 5002, city_name: 'Santa Cruz das Palmeiras', state_id: 25 },
  { id: 5003, city_name: 'Santa Cruz do Rio Pardo', state_id: 25 },
  { id: 5004, city_name: 'Santa Ernestina', state_id: 25 },
  { id: 5005, city_name: 'Santa Fé do Sul', state_id: 25 },
  { id: 5006, city_name: 'Santa Gertrudes', state_id: 25 },
  { id: 5007, city_name: 'Santa Maria da Serra', state_id: 25 },
  { id: 5008, city_name: 'Santa Mercedes', state_id: 25 },
  { id: 5009, city_name: 'Santa Rita do Passa Quatro', state_id: 25 },
  { id: 5010, city_name: "Santa Rita d'Oeste", state_id: 25 },
  { id: 5011, city_name: 'Santa Rosa de Viterbo', state_id: 25 },
  { id: 5012, city_name: 'Santa Salete', state_id: 25 },
  { id: 5013, city_name: 'Santana da Ponte Pensa', state_id: 25 },
  { id: 5014, city_name: 'Santana de Parnaíba', state_id: 25 },
  { id: 5015, city_name: 'Santo Anastácio', state_id: 25 },
  { id: 5016, city_name: 'Santo Antônio da Alegria', state_id: 25 },
  { id: 5017, city_name: 'Santo Antônio de Posse', state_id: 25 },
  { id: 5018, city_name: 'Santo Antônio do Aracanguá', state_id: 25 },
  { id: 5019, city_name: 'Santo Antônio do Jardim', state_id: 25 },
  { id: 5020, city_name: 'Santo Antônio do Pinhal', state_id: 25 },
  { id: 5021, city_name: 'Santo Expedito', state_id: 25 },
  { id: 5022, city_name: 'Santópolis do Aguapeí', state_id: 25 },
  { id: 5023, city_name: 'Santos', state_id: 25 },
  { id: 5024, city_name: 'São Bento do Sapucaí', state_id: 25 },
  { id: 5025, city_name: 'São Bernardo do Campo', state_id: 25 },
  { id: 5026, city_name: 'São Caetano do Sul', state_id: 25 },
  { id: 5027, city_name: 'São João da Boa Vista', state_id: 25 },
  { id: 5028, city_name: 'São João das Duas Pontes', state_id: 25 },
  { id: 5029, city_name: 'São João de Iracema', state_id: 25 },
  { id: 5030, city_name: "São João do Pau d'Alho", state_id: 25 },
  { id: 5031, city_name: 'São Joaquim da Barra', state_id: 25 },
  { id: 5032, city_name: 'São José da Bela Vista', state_id: 25 },
  { id: 5033, city_name: 'São José do Barreiro', state_id: 25 },
  { id: 5034, city_name: 'São José do Rio Pardo', state_id: 25 },
  { id: 5035, city_name: 'São José do Rio Preto', state_id: 25 },
  { id: 5036, city_name: 'São José dos Campos', state_id: 25 },
  { id: 5037, city_name: 'São Lourenço da Serra', state_id: 25 },
  { id: 5038, city_name: 'São Luiz do Paraitinga', state_id: 25 },
  { id: 5039, city_name: 'São Manuel', state_id: 25 },
  { id: 5040, city_name: 'São Miguel Arcanjo', state_id: 25 },
  { id: 5041, city_name: 'São Paulo', state_id: 25 },
  { id: 5042, city_name: 'São Pedro do Turvo', state_id: 25 },
  { id: 5043, city_name: 'São Roque', state_id: 25 },
  { id: 5044, city_name: 'São Sebastião da Grama', state_id: 25 },
  { id: 5045, city_name: 'Sarapuí', state_id: 25 },
  { id: 5046, city_name: 'Sarutaiá', state_id: 25 },
  { id: 5047, city_name: 'Sebastianópolis do Sul', state_id: 25 },
  { id: 5048, city_name: 'Serra Azul', state_id: 25 },
  { id: 5049, city_name: 'Serra Negra', state_id: 25 },
  { id: 5050, city_name: 'Serrana', state_id: 25 },
  { id: 5051, city_name: 'Sete Barras', state_id: 25 },
  { id: 5052, city_name: 'Severínia', state_id: 25 },
  { id: 5053, city_name: 'Silveiras', state_id: 25 },
  { id: 5054, city_name: 'Socorro', state_id: 25 },
  { id: 5055, city_name: 'Sorocaba', state_id: 25 },
  { id: 5056, city_name: 'Sud Mennucci', state_id: 25 },
  { id: 5057, city_name: 'Sumaré', state_id: 25 },
  { id: 5058, city_name: 'Suzanápolis', state_id: 25 },
  { id: 5059, city_name: 'Suzano', state_id: 25 },
  { id: 5060, city_name: 'Tabapuã', state_id: 25 },
  { id: 5061, city_name: 'Taboão da Serra', state_id: 25 },
  { id: 5062, city_name: 'Taciba', state_id: 25 },
  { id: 5063, city_name: 'Taguaí', state_id: 25 },
  { id: 5064, city_name: 'Taiaçu', state_id: 25 },
  { id: 5065, city_name: 'Taiúva', state_id: 25 },
  { id: 5066, city_name: 'Tambaú', state_id: 25 },
  { id: 5067, city_name: 'Tanabi', state_id: 25 },
  { id: 5068, city_name: 'Tapiratiba', state_id: 25 },
  { id: 5069, city_name: 'Taquaral', state_id: 25 },
  { id: 5070, city_name: 'Taquaritinga', state_id: 25 },
  { id: 5071, city_name: 'Taquarituba', state_id: 25 },
  { id: 5072, city_name: 'Taquarivaí', state_id: 25 },
  { id: 5073, city_name: 'Tarabai', state_id: 25 },
  { id: 5074, city_name: 'Tarumã', state_id: 25 },
  { id: 5075, city_name: 'Tatuí', state_id: 25 },
  { id: 5076, city_name: 'Taubaté', state_id: 25 },
  { id: 5077, city_name: 'Tejupá', state_id: 25 },
  { id: 5078, city_name: 'Tietê', state_id: 25 },
  { id: 5079, city_name: 'Timburi', state_id: 25 },
  { id: 5080, city_name: 'Torre de Pedra', state_id: 25 },
  { id: 5081, city_name: 'Torrinha', state_id: 25 },
  { id: 5082, city_name: 'Trabiju', state_id: 25 },
  { id: 5083, city_name: 'Tremembé', state_id: 25 },
  { id: 5084, city_name: 'Três Fronteiras', state_id: 25 },
  { id: 5085, city_name: 'Tuiuti', state_id: 25 },
  { id: 5086, city_name: 'Tupã', state_id: 25 },
  { id: 5087, city_name: 'Tupi Paulista', state_id: 25 },
  { id: 5088, city_name: 'Turiúba', state_id: 25 },
  { id: 5089, city_name: 'Ubarana', state_id: 25 },
  { id: 5090, city_name: 'Ubatuba', state_id: 25 },
  { id: 5091, city_name: 'Ubirajara', state_id: 25 },
  { id: 5092, city_name: 'Uchoa', state_id: 25 },
  { id: 5093, city_name: 'União Paulista', state_id: 25 },
  { id: 5094, city_name: 'Urânia', state_id: 25 },
  { id: 5095, city_name: 'Uru', state_id: 25 },
  { id: 5096, city_name: 'Urupês', state_id: 25 },
  { id: 5097, city_name: 'Valentim Gentil', state_id: 25 },
  { id: 5098, city_name: 'Valinhos', state_id: 25 },
  { id: 5099, city_name: 'Valparaíso', state_id: 25 },
  { id: 5100, city_name: 'Vargem Grande do Sul', state_id: 25 },
  { id: 5101, city_name: 'Vargem Grande Paulista', state_id: 25 },
  { id: 5102, city_name: 'Várzea Paulista', state_id: 25 },
  { id: 5103, city_name: 'Vinhedo', state_id: 25 },
  { id: 5104, city_name: 'Viradouro', state_id: 25 },
  { id: 5105, city_name: 'Vista Alegre do Alto', state_id: 25 },
  { id: 5106, city_name: 'Vitória Brasil', state_id: 25 },
  { id: 5107, city_name: 'Votorantim', state_id: 25 },
  { id: 5108, city_name: 'Votuporanga', state_id: 25 },
  { id: 5109, city_name: 'Zacarias', state_id: 25 },
  { id: 5110, city_name: 'Amparo do São Francisco', state_id: 26 },
  { id: 5111, city_name: 'Aquidabã', state_id: 26 },
  { id: 5112, city_name: 'Aracaju', state_id: 26 },
  { id: 5113, city_name: 'Arauá', state_id: 26 },
  { id: 5114, city_name: 'Barra dos Coqueiros', state_id: 26 },
  { id: 5115, city_name: 'Boquim', state_id: 26 },
  { id: 5116, city_name: 'Brejo Grande', state_id: 26 },
  { id: 5117, city_name: 'Campo do Brito', state_id: 26 },
  { id: 5118, city_name: 'Canhoba', state_id: 26 },
  { id: 5119, city_name: 'Canindé de São Francisco', state_id: 26 },
  { id: 5120, city_name: 'Carira', state_id: 26 },
  { id: 5121, city_name: 'Carmópolis', state_id: 26 },
  { id: 5122, city_name: 'Cedro de São João', state_id: 26 },
  { id: 5123, city_name: 'Cristinápolis', state_id: 26 },
  { id: 5124, city_name: 'Cumbe', state_id: 26 },
  { id: 5125, city_name: 'Divina Pastora', state_id: 26 },
  { id: 5126, city_name: 'Estância', state_id: 26 },
  { id: 5127, city_name: 'Frei Paulo', state_id: 26 },
  { id: 5128, city_name: 'Gararu', state_id: 26 },
  { id: 5129, city_name: 'General Maynard', state_id: 26 },
  { id: 5130, city_name: 'Gracho Cardoso', state_id: 26 },
  { id: 5131, city_name: 'Ilha das Flores', state_id: 26 },
  { id: 5132, city_name: 'Indiaroba', state_id: 26 },
  { id: 5133, city_name: 'Itabaianinha', state_id: 26 },
  { id: 5134, city_name: 'Itabi', state_id: 26 },
  { id: 5135, city_name: "Itaporanga d'Ajuda", state_id: 26 },
  { id: 5136, city_name: 'Japaratuba', state_id: 26 },
  { id: 5137, city_name: 'Japoatã', state_id: 26 },
  { id: 5138, city_name: 'Lagarto', state_id: 26 },
  { id: 5139, city_name: 'Laranjeiras', state_id: 26 },
  { id: 5140, city_name: 'Macambira', state_id: 26 },
  { id: 5141, city_name: 'Malhada dos Bois', state_id: 26 },
  { id: 5142, city_name: 'Malhador', state_id: 26 },
  { id: 5143, city_name: 'Maruim', state_id: 26 },
  { id: 5144, city_name: 'Moita Bonita', state_id: 26 },
  { id: 5145, city_name: 'Monte Alegre de Sergipe', state_id: 26 },
  { id: 5146, city_name: 'Muribeca', state_id: 26 },
  { id: 5147, city_name: 'Neópolis', state_id: 26 },
  { id: 5148, city_name: 'Nossa Senhora Aparecida', state_id: 26 },
  { id: 5149, city_name: 'Nossa Senhora da Glória', state_id: 26 },
  { id: 5150, city_name: 'Nossa Senhora das Dores', state_id: 26 },
  { id: 5151, city_name: 'Nossa Senhora de Lourdes', state_id: 26 },
  { id: 5152, city_name: 'Nossa Senhora do Socorro', state_id: 26 },
  { id: 5153, city_name: 'Pedra Mole', state_id: 26 },
  { id: 5154, city_name: 'Pedrinhas', state_id: 26 },
  { id: 5155, city_name: 'Pirambu', state_id: 26 },
  { id: 5156, city_name: 'Poço Redondo', state_id: 26 },
  { id: 5157, city_name: 'Poço Verde', state_id: 26 },
  { id: 5158, city_name: 'Porto da Folha', state_id: 26 },
  { id: 5159, city_name: 'Propriá', state_id: 26 },
  { id: 5160, city_name: 'Riachão do Dantas', state_id: 26 },
  { id: 5161, city_name: 'Ribeirópolis', state_id: 26 },
  { id: 5162, city_name: 'Rosário do Catete', state_id: 26 },
  { id: 5163, city_name: 'Salgado', state_id: 26 },
  { id: 5164, city_name: 'Santa Luzia do Itanhy', state_id: 26 },
  { id: 5165, city_name: 'Santana do São Francisco', state_id: 26 },
  { id: 5166, city_name: 'Santo Amaro das Brotas', state_id: 26 },
  { id: 5167, city_name: 'São Cristóvão', state_id: 26 },
  { id: 5168, city_name: 'São Miguel do Aleixo', state_id: 26 },
  { id: 5169, city_name: 'Simão Dias', state_id: 26 },
  { id: 5170, city_name: 'Siriri', state_id: 26 },
  { id: 5171, city_name: 'Telha', state_id: 26 },
  { id: 5172, city_name: 'Tobias Barreto', state_id: 26 },
  { id: 5173, city_name: 'Tomar do Geru', state_id: 26 },
  { id: 5174, city_name: 'Umbaúba', state_id: 26 },
  { id: 5175, city_name: 'Abreulândia', state_id: 27 },
  { id: 5176, city_name: 'Aguiarnópolis', state_id: 27 },
  { id: 5177, city_name: 'Aliança do Tocantins', state_id: 27 },
  { id: 5178, city_name: 'Almas', state_id: 27 },
  { id: 5179, city_name: 'Ananás', state_id: 27 },
  { id: 5180, city_name: 'Angico', state_id: 27 },
  { id: 5181, city_name: 'Aparecida do Rio Negro', state_id: 27 },
  { id: 5182, city_name: 'Aragominas', state_id: 27 },
  { id: 5183, city_name: 'Araguacema', state_id: 27 },
  { id: 5184, city_name: 'Araguaçu', state_id: 27 },
  { id: 5185, city_name: 'Araguaína', state_id: 27 },
  { id: 5186, city_name: 'Araguatins', state_id: 27 },
  { id: 5187, city_name: 'Arapoema', state_id: 27 },
  { id: 5188, city_name: 'Arraias', state_id: 27 },
  { id: 5189, city_name: 'Augustinópolis', state_id: 27 },
  { id: 5190, city_name: 'Aurora do Tocantins', state_id: 27 },
  { id: 5191, city_name: 'Axixá do Tocantins', state_id: 27 },
  { id: 5192, city_name: 'Babaçulândia', state_id: 27 },
  { id: 5193, city_name: 'Bandeirantes do Tocantins', state_id: 27 },
  { id: 5194, city_name: 'Barra do Ouro', state_id: 27 },
  { id: 5195, city_name: 'Barrolândia', state_id: 27 },
  { id: 5196, city_name: 'Bernardo Sayão', state_id: 27 },
  { id: 5197, city_name: 'Brasilândia do Tocantins', state_id: 27 },
  { id: 5198, city_name: 'Brejinho de Nazaré', state_id: 27 },
  { id: 5199, city_name: 'Buriti do Tocantins', state_id: 27 },
  { id: 5200, city_name: 'Campos Lindos', state_id: 27 },
  { id: 5201, city_name: 'Cariri do Tocantins', state_id: 27 },
  { id: 5202, city_name: 'Carmolândia', state_id: 27 },
  { id: 5203, city_name: 'Carrasco Bonito', state_id: 27 },
  { id: 5204, city_name: 'Caseara', state_id: 27 },
  { id: 5205, city_name: 'Chapada da Natividade', state_id: 27 },
  { id: 5206, city_name: 'Chapada de Areia', state_id: 27 },
  { id: 5207, city_name: 'Colinas do Tocantins', state_id: 27 },
  { id: 5208, city_name: 'Colméia', state_id: 27 },
  { id: 5209, city_name: 'Combinado', state_id: 27 },
  { id: 5210, city_name: 'Conceição do Tocantins', state_id: 27 },
  { id: 5211, city_name: 'Couto Magalhães', state_id: 27 },
  { id: 5212, city_name: 'Cristalândia', state_id: 27 },
  { id: 5213, city_name: 'Crixás do Tocantins', state_id: 27 },
  { id: 5214, city_name: 'Darcinópolis', state_id: 27 },
  { id: 5215, city_name: 'Dianópolis', state_id: 27 },
  { id: 5216, city_name: 'Divinópolis do Tocantins', state_id: 27 },
  { id: 5217, city_name: 'Dois Irmãos do Tocantins', state_id: 27 },
  { id: 5218, city_name: 'Dueré', state_id: 27 },
  { id: 5219, city_name: 'Figueirópolis', state_id: 27 },
  { id: 5220, city_name: 'Formoso do Araguaia', state_id: 27 },
  { id: 5221, city_name: 'Goianorte', state_id: 27 },
  { id: 5222, city_name: 'Goiatins', state_id: 27 },
  { id: 5223, city_name: 'Guaraí', state_id: 27 },
  { id: 5224, city_name: 'Gurupi', state_id: 27 },
  { id: 5225, city_name: 'Itacajá', state_id: 27 },
  { id: 5226, city_name: 'Itaguatins', state_id: 27 },
  { id: 5227, city_name: 'Itapiratins', state_id: 27 },
  { id: 5228, city_name: 'Itaporã do Tocantins', state_id: 27 },
  { id: 5229, city_name: 'Jaú do Tocantins', state_id: 27 },
  { id: 5230, city_name: 'Juarina', state_id: 27 },
  { id: 5231, city_name: 'Lagoa da Confusão', state_id: 27 },
  { id: 5232, city_name: 'Lagoa do Tocantins', state_id: 27 },
  { id: 5233, city_name: 'Lavandeira', state_id: 27 },
  { id: 5234, city_name: 'Lizarda', state_id: 27 },
  { id: 5235, city_name: 'Luzinópolis', state_id: 27 },
  { id: 5236, city_name: 'Marianópolis do Tocantins', state_id: 27 },
  { id: 5237, city_name: 'Mateiros', state_id: 27 },
  { id: 5238, city_name: 'Maurilândia do Tocantins', state_id: 27 },
  { id: 5239, city_name: 'Miracema do Tocantins', state_id: 27 },
  { id: 5240, city_name: 'Miranorte', state_id: 27 },
  { id: 5241, city_name: 'Monte do Carmo', state_id: 27 },
  { id: 5242, city_name: 'Monte Santo do Tocantins', state_id: 27 },
  { id: 5243, city_name: 'Muricilândia', state_id: 27 },
  { id: 5244, city_name: 'Nova Rosalândia', state_id: 27 },
  { id: 5245, city_name: 'Novo Acordo', state_id: 27 },
  { id: 5246, city_name: 'Novo Alegre', state_id: 27 },
  { id: 5247, city_name: 'Novo Jardim', state_id: 27 },
  { id: 5248, city_name: 'Oliveira de Fátima', state_id: 27 },
  { id: 5249, city_name: 'Palmeirante', state_id: 27 },
  { id: 5250, city_name: 'Palmeiras do Tocantins', state_id: 27 },
  { id: 5251, city_name: 'Palmeirópolis', state_id: 27 },
  { id: 5252, city_name: 'Paraíso do Tocantins', state_id: 27 },
  { id: 5253, city_name: 'Paranã', state_id: 27 },
  { id: 5254, city_name: 'Pedro Afonso', state_id: 27 },
  { id: 5255, city_name: 'Peixe', state_id: 27 },
  { id: 5256, city_name: 'Pequizeiro', state_id: 27 },
  { id: 5257, city_name: 'Pindorama do Tocantins', state_id: 27 },
  { id: 5258, city_name: 'Piraquê', state_id: 27 },
  { id: 5259, city_name: 'Pium', state_id: 27 },
  { id: 5260, city_name: 'Ponte Alta do Bom Jesus', state_id: 27 },
  { id: 5261, city_name: 'Ponte Alta do Tocantins', state_id: 27 },
  { id: 5262, city_name: 'Porto Alegre do Tocantins', state_id: 27 },
  { id: 5263, city_name: 'Porto Nacional', state_id: 27 },
  { id: 5264, city_name: 'Praia Norte', state_id: 27 },
  { id: 5265, city_name: 'Pugmil', state_id: 27 },
  { id: 5266, city_name: 'Recursolândia', state_id: 27 },
  { id: 5267, city_name: 'Rio da Conceição', state_id: 27 },
  { id: 5268, city_name: 'Rio dos Bois', state_id: 27 },
  { id: 5269, city_name: 'Rio Sono', state_id: 27 },
  { id: 5270, city_name: 'Sampaio', state_id: 27 },
  { id: 5271, city_name: 'Sandolândia', state_id: 27 },
  { id: 5272, city_name: 'Santa Fé do Araguaia', state_id: 27 },
  { id: 5273, city_name: 'Santa Maria do Tocantins', state_id: 27 },
  { id: 5274, city_name: 'Santa Rita do Tocantins', state_id: 27 },
  { id: 5275, city_name: 'Santa Rosa do Tocantins', state_id: 27 },
  { id: 5276, city_name: 'Santa Tereza do Tocantins', state_id: 27 },
  { id: 5277, city_name: 'Santa Terezinha do Tocantins', state_id: 27 },
  { id: 5278, city_name: 'São Bento do Tocantins', state_id: 27 },
  { id: 5279, city_name: 'São Félix do Tocantins', state_id: 27 },
  { id: 5280, city_name: 'São Miguel do Tocantins', state_id: 27 },
  { id: 5281, city_name: 'São Salvador do Tocantins', state_id: 27 },
  { id: 5282, city_name: 'São Sebastião do Tocantins', state_id: 27 },
  { id: 5283, city_name: 'São Valério', state_id: 27 },
  { id: 5284, city_name: 'Silvanópolis', state_id: 27 },
  { id: 5285, city_name: 'Sítio Novo do Tocantins', state_id: 27 },
  { id: 5286, city_name: 'Sucupira', state_id: 27 },
  { id: 5287, city_name: 'Tabocão', state_id: 27 },
  { id: 5288, city_name: 'Taguatinga', state_id: 27 },
  { id: 5289, city_name: 'Taipas do Tocantins', state_id: 27 },
  { id: 5290, city_name: 'Talismã', state_id: 27 },
  { id: 5291, city_name: 'Tocantínia', state_id: 27 },
  { id: 5292, city_name: 'Tocantinópolis', state_id: 27 },
  { id: 5293, city_name: 'Tupirama', state_id: 27 },
  { id: 5294, city_name: 'Tupiratins', state_id: 27 },
  { id: 5295, city_name: 'Wanderlândia', state_id: 27 },
  { id: 5296, city_name: 'Xambioá', state_id: 27 }
]
