import React, { lazy, Suspense, useState } from 'react'
import LoginHeader from '../components/loginHeader'
import Loading from '../../../component/Loading'
import { FormattedMessage } from 'react-intl'
const ForgotPasswordForm = lazy(() => import('./ForgotPassword'))

function ForgotPassword (props) {
  const [title, setTitle] = useState(<FormattedMessage id='Forgot_Password' />)

  return (
    <div className="auth-box">
      <LoginHeader backURL='/login' title={title} />
      <Suspense fallback={<Loading />}>
        <ForgotPasswordForm {...props} setTitle={setTitle} />
      </Suspense>
    </div>
  )
}

export default ForgotPassword
