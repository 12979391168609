import React, { useEffect, useState, Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Button, CarouselControl, Carousel, CarouselItem } from 'reactstrap'
import { isUpperCase, allSportsRoles, convertFloat } from '../../../helper'
// import { useQueryState } from 'react-router-use-location-state'
import Loading from '../../../component/Loading'
import PlayerImage from '../../../assests/images/PlayerImage.png'
import TransparentLogo from '../../../assests/images/vrf_transparent_logo.svg'
import TeamList from 'Common/src/components/SportsLeagueList/TeamList'
import qs from 'query-string'
import { useSelector } from 'react-redux'
import homeIcon from '../../../assests/images/homeIconWhite.svg'
const classNames = require('classnames')

function MyTeamsPreview (props) {
  const { playerRoles, url, token, match, history, teamList, teamPlayerList, teamPreview, userTeam, loading, getUserTeam, setLoading, matchDetails, location } = props
  const [allTeams, setAllTeams] = useState([])
  const [team, setTeam] = useState({})
  // const [viewTeam, setViewTeam] = useState([])
  const [totalCredit, setTotalCredit] = useState(0)
  const [totalScorePoints, setTotalScorePoints] = useState(0)
  const [teamView, setTeamView] = useState(0)
  const [TeamName, setTeamName] = useState('')
  const [matchType, setMatchType] = useState('')
  const [animating, setAnimating] = useState(false)
  // const [userTeamId, setUserTeamId] = useState('')
  const obj = qs.parse(location.search)
  const activeSports = useSelector(state => state.activeSports.activeSports)
  const activeSport = activeSports && activeSports.length > 0 && activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => data)
  const previousProps = useRef({
    userTeam, team, teamList, allTeams
  }).current
  const sport = match.params.sportsType

  useEffect(() => {
    if (obj.index) {
      // setCurrentIndex(parseInt(obj.index))
      setTeamView(parseInt(obj.index))
    }
    if (match?.params?.sportsType && history?.location?.pathname?.includes('tournament')) {
      isUpperCase(sport) && history.push(`/tournament/my-teams-preview/${sport.toLowerCase()}/${match.params.matchID}/${match?.params?.userTeamID}`)
    } else if (match?.params?.sportsType) {
      isUpperCase(sport) && history.push(`/my-teams-preview/${sport.toLowerCase()}/${match.params.matchID}/${match?.params?.userTeamID}`)
    }
    if (match?.params?.userTeamID && token) {
      // setUserTeamId(match.params.userTeamID)
      getUserTeam(match.params.userTeamID)
      setLoading(true)
    }
    // if (match && match.params && match.params.matchID && !matchPlayer) {
    //   getMatchPlayerList(match && match.params && match.params.matchID)
    // }
  }, [token, match.params])

  useEffect(() => {
    if (location?.state?.teamCreationPage) {
      setAllTeams(location?.state?.allTeams)
    } else if (teamList?.length > 0) {
      setAllTeams(teamList)
      setTeamView(teamList?.findIndex(data => data._id === match.params.userTeamID))
    }
  }, [teamList, match.params, location.state])

  const next = () => {
    if (animating) return
    const nextIndex = teamView === allTeams.length - 1 ? 0 : teamView + 1
    // setTeamView(nextIndex)
    // setCurrentIndex(nextIndex)
    // changeTeam(nextIndex)
    const userTeamId = allTeams[nextIndex]?._id
    history.replace({
      pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/my-teams-preview/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${userTeamId}` : `/my-teams-preview/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${userTeamId}`,
      search: `?${qs.stringify({
          homePage: obj?.homePage ? 'yes' : undefined
        })}`
    })
  }

  const previous = () => {
    if (animating) return
    const nextIndex = teamView === 0 ? allTeams.length - 1 : teamView - 1
    // setTeamView(nextIndex)
    // setCurrentIndex(nextIndex)
    // changeTeam(nextIndex)
    const userTeamId = allTeams[nextIndex]?._id
    history.replace({
      pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/my-teams-preview/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${userTeamId}` : `/my-teams-preview/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${userTeamId}`,
      search: `?${qs.stringify({
          homePage: obj?.homePage ? 'yes' : undefined
        })}`
    })
  }

  useEffect(() => {
    if (allTeams && teamPlayerList?.length !== 0 && playerRoles) {
      let TotalCredit = 0
      let TotalScorePoints = 0
      if (allTeams && allTeams.length !== 0 && teamPlayerList) {
        const playerRole = playerRoles?.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).sort((a, b) => a?.nPosition?.toString().localeCompare(b?.nPosition?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map(Role => Role.sName)
        const players = Object.assign({}, ...playerRole?.map(key => ({ [key]: [] })))
        allTeams?.length > 0 && allTeams[teamView]?.aPlayers?.length > 0 && allTeams[teamView]?.aPlayers?.map((Player) => {
          const PlayerDetails = teamPlayerList?.length > 0 && teamPlayerList.find(player => player._id === Player)
          Object.entries(players).map(([key, value]) => {
            return (key === PlayerDetails?.eRole) && players[PlayerDetails?.eRole]?.push(PlayerDetails)
          })
          TotalCredit = TotalCredit + PlayerDetails?.nFantasyCredit
          setTotalCredit(TotalCredit)
          if (PlayerDetails && PlayerDetails.nScoredPoints) {
            if (Player === allTeams[teamView].iCaptainId) {
              const newPoints = (Number(PlayerDetails.nScoredPoints) * 2)
              TotalScorePoints = TotalScorePoints + newPoints
            } else if (Player === allTeams[teamView].iViceCaptainId) {
              const newPoints = (Number(PlayerDetails.nScoredPoints) * 1.5)
              TotalScorePoints = TotalScorePoints + newPoints
            } else { TotalScorePoints = TotalScorePoints + Number(PlayerDetails.nScoredPoints) }
          }
          setTotalScorePoints(TotalScorePoints)
          return players
        })
        const tempData = []
        Object.entries(players).map(([key, value]) => value && value.length > 0 && value.sort((a, b) => a.sName > b.sName ? 1 : -1).map(playerInfo => tempData.push(playerInfo)))
        setTeam(players)
      }
    }
    return () => {
      previousProps.allTeams = allTeams
    }
  }, [allTeams, userTeam, teamPlayerList, playerRoles])

  // useEffect(() => {
  //   if (teamView >= 0) {
  //     let TotalCredits = 0
  //     let TotalScorePoints = 0

  //     if (allTeams && allTeams.length !== 0) {
  //       const playerRole = playerRoles?.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).sort((a, b) => a?.nPosition?.toString().localeCompare(b?.nPosition?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map(Role => Role.sName)
  //       const players = Object.assign(...playerRole?.map(key => ({ [key]: [] })))
  //       allTeams && allTeams.length !== 0 && allTeams.map((Player) => {
  //         const PlayerDetails = teamPlayerList && teamPlayerList.length !== 0 && teamPlayerList.find(player => player._id === Player)
  //         Object.entries(players).map(([key, value]) => {
  //           return key === PlayerDetails?.eRole && players[PlayerDetails?.eRole].push(PlayerDetails)
  //         })
  //         TotalCredits = TotalCredits + PlayerDetails?.nFantasyCredit
  //         setTotalCredit(TotalCredits)
  //         if (PlayerDetails && PlayerDetails.nScoredPoints) {
  //           if (Player === allTeams[teamView].iCaptainId) {
  //             const newPoints = (Number(PlayerDetails.nScoredPoints) * 2)
  //             TotalScorePoints = TotalScorePoints + newPoints
  //           } else if (Player === allTeams[teamView].iViceCaptainId) {
  //             const newPoints = (Number(PlayerDetails.nScoredPoints) * 1.5)
  //             TotalScorePoints = TotalScorePoints + newPoints
  //           } else { TotalScorePoints = TotalScorePoints + Number(PlayerDetails.nScoredPoints) }
  //         }
  //         setTotalScorePoints(TotalScorePoints)
  //         return TotalCredits
  //       })
  //       setTeam(players)
  //     }
  //   }
  // }, [teamView])

  function PlayerInfoFun (playerId) {
    // setPlayerId(playerId)
    if (location?.state?.teamCreationPage) {
      history.push({
        pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/create-team/view-player-league-info/${match.params.sportsType}/${match.params.matchID}/${playerId}` : `/create-team/view-player-league-info/${match.params.sportsType}/${match.params.matchID}/${playerId}`,
        state: { teamCreationPage: true }
      })
    } else if (matchDetails && matchDetails.eStatus === 'U') {
      history.push({
        pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/view-player-leagueInfo/${(match.params.sportsType).toLowerCase()}/${match.params.matchID}/${match.params.userTeamID}/${playerId}` : `/view-player-leagueInfo/${(match.params.sportsType).toLowerCase()}/${match.params.matchID}/${match.params.userTeamID}/${playerId}`,
        search: `?${qs.stringify({
            homePage: obj?.homePage ? 'yes' : undefined,
            playerLeagueInfo: 'y'
          })}`
      })
    } else {
      history.push({
        pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/view-player-Info/${(match.params.sportsType).toLowerCase()}/${match.params.matchID}/${match?.params?.userTeamID}/${playerId}` : `/view-player-Info/${(match.params.sportsType).toLowerCase()}/${match.params.matchID}/${match?.params?.userTeamID}/${playerId}`,
        search: `?${qs.stringify({
            homePage: obj?.homePage ? 'yes' : undefined,
            userLeague: match?.params?.userLeagueID || undefined
        })}`
      })
    }
  }

  useEffect(() => {
    if (matchDetails) {
      matchDetails.oHomeTeam && matchDetails.oHomeTeam.sName && setTeamName(matchDetails.oHomeTeam.sName)
      matchDetails.eStatus === 'U' && setMatchType('upcoming')
    }
  }, [matchDetails])

  const slides = allTeams && allTeams.length !== 0 && allTeams.map((item) => {
    return (
      <CarouselItem
        key={item.id}
        className="custom-tag"
        onExited={() => setAnimating(false)}
        onExiting={() => setAnimating(true)}
        tag="div"
      >
        <div className="ground w-100">
          <Fragment>
            {Object.entries(team).map(([key, value]) => {
              return (
                <div key={value._id || value.iMatchPlayerId} className="ground-c w-100">
                  <h3>
                    {allSportsRoles(key)}
                    {/* {key === 'BATS' ? (<FormattedMessage id="Team_batsman" />) : key === 'WK' ? (<FormattedMessage id="Team_wk" />) : key === 'BWL' ? (<FormattedMessage id="Team_bowler" />) : key === 'ALLR' ? (<FormattedMessage id="Team_allRounder" />) : key} */}
                  </h3>
                  <div className="player-list d-flex align-items-center w-100 justify-content-center">
                    {
                              value && value.length !== 0 && value.map((playerInfo) => {
                                const playerName = playerInfo?.sName?.split(' ')
                                return (
                                  <div key={playerInfo._id || playerInfo.iMatchPlayerId} className="pbg" onClick={() => { PlayerInfoFun(playerInfo._id || playerInfo?.iMatchPlayerId) }}>
                                    <div className="pg-img">
                                      {playerInfo?.sImage && url && <img key={`inside${playerInfo?.eRole}`} alt="" src={playerInfo?.sImage && url ? `${url}${playerInfo?.sImage}` : PlayerImage} />}
                                      { playerInfo?.bShow && matchType === 'upcoming' &&
                                        <span className="tag2" />
                                      }
                                      {
                                        (playerInfo?._id === userTeam?.iCaptainId)
                                          ? <span className="tag"><FormattedMessage id="Team_C" /></span>
                                          : (playerInfo?._id === userTeam?.iViceCaptainId)
                                              ? <span className="tag"><FormattedMessage id="Team_VC" /></span>
                                              : ''
                                      }
                                    </div>
                                    <p className={classNames(history?.location?.pathname?.includes('tournament') ? 'backDarkBlue' : ({ backDarkBlue: TeamName === playerInfo.sTeamName, backWhitePlayer: TeamName !== playerInfo.sTeamName }))}>
                                      {playerInfo?.sName?.indexOf(' ') >= 0 ? playerName[0][0] + ' ' + playerName[playerName.length - 1] : playerName}
                                    </p>
                                    {
                                      !teamPreview
                                        ? (
                                          <span>
                                            {matchType === 'upcoming'
                                              ? playerInfo && playerInfo.nFantasyCredit > 0
                                                ? Number.isInteger(playerInfo.nFantasyCredit) ? playerInfo.nFantasyCredit : playerInfo.nFantasyCredit.toFixed(2) + ' Cr '
                                                : (<FormattedMessage id="Team_0CR" />)
                                              : (playerInfo?.iMatchPlayerId === allTeams[teamView]?.iCaptainId) || (playerInfo._id === allTeams[teamView]?.iCaptainId)
                                                  ? convertFloat(playerInfo.nScoredPoints * 2) + ' Pts '
                                                  : (playerInfo?.iMatchPlayerId === allTeams[teamView]?.iViceCaptainId) || (playerInfo._id === allTeams[teamView]?.iViceCaptainId)
                                                      ? convertFloat(playerInfo.nScoredPoints * 1.5) + ' Pts '
                                                      : parseFloat(Number((playerInfo.nScoredPoints)).toFixed(2)) + ' Pts '
                                              }
                                          </span>
                                          )
                                          // (
                                          // <span>{matchType === 'upcoming' ? playerInfo && playerInfo.nFantasyCredit ? playerInfo.nFantasyCredit + ' Cr ' : (<FormattedMessage id="Team_0CR" />) : playerInfo && playerInfo.nScoredPoints ? parseFloat(Number((playerInfo.nScoredPoints)).toFixed(2)) + ' Pts ' : (<FormattedMessage id="Team_0Pts" />)}</span>
                                          // )
                                        : (
                                          <span>
                                            {
                                            playerInfo && playerInfo.nFantasyCredit > 0
                                              ? Number.isInteger(playerInfo.nFantasyCredit) ? playerInfo.nFantasyCredit : playerInfo.nFantasyCredit.toFixed(2) + ' Cr '
                                              : (<FormattedMessage id="Team_0CR" />)
                                            }
                                          </span>
                                          )
                                    }
                                  </div>
                                )
                              })
                            }
                  </div>
                </div>
              )
            })
                  }
          </Fragment>
        </div>
      </CarouselItem>
    )
  })

  // function changeTeam (index) {
  //   const playerRoles = playerRoles?.aPlayerRole?.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).sort((a, b) => a?.nPosition?.toString().localeCompare(b?.nPosition?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map(Role => Role.sName)
  //   const players = Object.assign(...playerRoles?.map(key => ({ [key]: [] })))
  //   allTeams[index].aPlayers && allTeams[index].aPlayers.length !== 0 && allTeams[index].aPlayers.map((Player) => {
  //     const PlayerDetails = teamPlayerList && teamPlayerList.length !== 0 && teamPlayerList.find(player => player._id === Player)
  //     Object.entries(players).map(([key, value]) => {
  //       return key === PlayerDetails?.eRole && players[PlayerDetails?.eRole].push(PlayerDetails)
  //     })
  //     return players
  //   })
  //   setTeam(players)
  // }

  return (
    <>
      <div className="p-logo">
        <img alt={<FormattedMessage id='Transparent_Logo' />} src={TransparentLogo} />
      </div>
      {loading
        ? <Loading />
        : (
          <Fragment>
            <div className={classNames('preview d-flex align-items-center justify-content-center', { 'p-cricket': sport === 'cricket', 'p-football': sport === 'football', 'p-basketball': sport === 'basketball', 'p-baseball': sport === 'baseball', 'p-kabaddi': sport === 'kabaddi' })}>
              <div className="p-header d-flex align-items-center justify-content-between zIndex">
                <div className="d-flex align-items-center header-i">
                  <button className="bg-transparent icon-left-arrow"
                    onClick={() => {
                      if (location?.state?.teamCreationPage) {
                        history.push({
                          pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/create-team/${match.params.sportsType}/${match.params.matchID}` : `/create-team/${match.params.sportsType}/${match.params.matchID}`,
                          search: `?${qs.stringify({
                          homePage: obj?.homePage ? 'yes' : undefined
                        })}`,
                          state: { ...location.state, allTeams, captainViceCaptainPage: location?.state?.captainViceCaptainPage ? 'yes' : '' }
                        })
                      } else if (matchDetails) {
                        if (matchDetails.eStatus === 'U') {
                          history.push({
                            pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/leagues/${match.params.sportsType}/${match.params.matchID}` : `/leagues/${match.params.sportsType}/${match.params.matchID}`,
                            search: `?${qs.stringify({
                            homePage: obj?.homePage ? 'yes' : undefined
                          })}`
                          })
                        } else if (matchDetails.eStatus === 'L') {
                          if (history?.location?.pathname?.includes('tournament')) {
                            history.push(`/tournament/liveleague/${match.params.matchID}/${match.params.sportsType}`)
                          } else {
                            history.push(`/liveleague/${match.params.matchID}/${match.params.sportsType}`)
                          }
                        } else if (matchDetails.eStatus === 'I' || matchDetails.eStatus === 'CMP') {
                          if (history?.location?.pathname?.includes('tournament')) {
                            history.push(`/tournament/completedleagues/participated/${match.params.matchID}/${match.params.sportsType}?activeState=2`)
                          } else {
                            history.push(`/completedleagues/participated/${match.params.matchID}/${match.params.sportsType}?activeState=2`)
                          }
                        }
                      }
                    }}
                  />
                  <Button className='button-link bg-transparent py-2' tag={Link} to={`/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}`}><img src={homeIcon} /></Button>
                </div>
                {/* <div>
                  {
                    !(matchType === 'upcoming') && locationMatch.path !== '/completedleagues/participated/:id/:sportsType'
                      ? <button className="bg-transparent mr-3 icon-refresh"
                        onClick={(e) => refreshContent(e)}
                        ></button>
                      : ''
                  }
                </div> */}
              </div>
              <Fragment>
                <Carousel
                  activeIndex={teamView}
                  className="w-100"
                  slide={false}
                >
                  {slides}
                  {
                    allTeams && allTeams.length > 1 && (
                      <Fragment>
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                      </Fragment>
                    )
                  }
                </Carousel>
              </Fragment>
              {allTeams.length >= 1 && (
              <div className="np-bottom">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="txt">{allTeams && allTeams.length > 0 && allTeams[teamView]?.sName}</p>
                  {allTeams && allTeams.length > 1 && <p className="txt">{(teamView + 1) + '/' + allTeams?.length}</p>}
                  <p className="txt">
                    {matchType === 'upcoming' ? <FormattedMessage id="Common_TotalCredits" /> : <FormattedMessage id="Common_TotalPoints" />}
                    :
                    {' '}
                    {matchType === 'upcoming' ? totalCredit.toFixed(2) : convertFloat(totalScorePoints) }
                  </p>
                </div>
              </div>
              )}
            </div>
          </Fragment>
          )
      }
    </>
  )
}

MyTeamsPreview.propTypes = {
  data: PropTypes.shape({
    aPlayers: PropTypes.func,
    sName: PropTypes.string
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.object,
    teamCreationPage: PropTypes.bool,
    allTeams: PropTypes.object
  }),
  token: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      matchID: PropTypes.string,
      sportsType: PropTypes.string,
      userTeamID: PropTypes.string,
      userLeagueID: PropTypes.string
    }),
    path: PropTypes.string
  }),
  url: PropTypes.string,
  onBackClick: PropTypes.func,
  userTeam: PropTypes.object,
  matchDetails: PropTypes.shape({
    eStatus: PropTypes.string,
    oHomeTeam: PropTypes.shape({
      sName: PropTypes.string
    })
  }),
  getMatchPlayerList: PropTypes.func,
  getUserTeam: PropTypes.func,
  teamPreview: PropTypes.bool,
  setLoading: PropTypes.bool,
  loading: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
    replace: PropTypes.func,
    location: PropTypes.object
  }),
  teamList: PropTypes.array,
  teamPlayerList: PropTypes.array,
  playerRoles: PropTypes.object
}

export default TeamList(MyTeamsPreview)
