import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import UserHeader from '../../User/components/UserHeader'
// import { callEvent } from '../../../Analytics.js'
import Loading from '../../../component/Loading'
import { FormattedMessage } from 'react-intl'
import qs from 'query-string'
const CreateContestPage = lazy(() => import('./CreateContest'))

function CreateContest (props) {
  const { match, location } = props
  const obj = qs.parse(location.search)
  // useEffect(() => {
  //   callEvent('create_private_contest', 'Create a Private Contest', location.pathname)
  // }, [])

  function goBackFunc () {
    if (history?.location?.pathname?.includes('tournament')) {
      return `/tournament/leagues/${match.params.sportsType}/${match.params.id}`
    } else if (obj?.homePage) {
      return `/leagues/${match.params.sportsType}/${match.params.id}?homePage=yes`
    } else {
      return `/leagues/${match.params.sportsType}/${match.params.id}`
    }
  }
  return (
    <>
    <UserHeader {...props} title={<FormattedMessage id='Create_contest' />} backURL={goBackFunc()} createContest/>
      <Suspense fallback={<Loading />}>
        <CreateContestPage {...props}/>
      </Suspense>
    </>
  )
}

CreateContest.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      sportsType: PropTypes.string,
      id: PropTypes.string
    })
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  })
}

export default CreateContest
