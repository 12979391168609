/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import qs from 'query-string'
import { useQueryState } from 'react-router-use-location-state'
import LeagueHeader from '../components/LeagueHeader'
import LeaguesCompletedPage from './LeaguesCompleted'

function LeaguesCompleted (props) {
  const { location, match, history } = props
  const [activeState, setActiveState] = useQueryState('activeState', '1')
  const [VideoStream, setVideoStream] = useState(false)
  const [activeTab, setActiveTab] = useState('1')
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  useEffect(() => {
    const obj = qs.parse(location.search)
    if (obj) {
      if (obj.activeState) {
        const active = obj.activeState
        setActiveState(active)
        toggle(active)
      }
    }
  }, [])

  return (
    <>
      <LeagueHeader
        {...props}
        VideoStream={VideoStream}
        backTab={match?.path?.includes('/liveleague/:id/:sportsType') ? '2' : match?.path?.includes('/completedleagues/participated/:id/:sportsType') ? '3' : '1'}
        completed
        goToBack={`/matches/${match.params.sportsType}`}
        search={location?.state?.matchType ? `matchType=${location?.state?.matchType}` : undefined}
        setVideoStream={setVideoStream}
      />
      <LeaguesCompletedPage {...props} activeTab={activeTab} setActiveState={setActiveState} toggle={toggle}/>
    </>
  )
}

LeaguesCompleted.propTypes = {
  match: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object
  }),
  history: PropTypes.object
}

export default LeaguesCompleted
