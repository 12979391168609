import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMatchList } from '../../redux/actions/match'
import { getMatchLeagueDetails } from '../../redux/actions/league'
import { GetUserProfile } from '../../redux/actions/profile'

export const CommonInviteFriend = (Component) => {
  return (props) => {
      const { matchId } = props
    const dispatch = useDispatch()
    const matchList = useSelector(state => state.match.matchList)
    const token = useSelector(state => state.auth.token)
    const getUrlLink = useSelector(state => state.url.getUrl)
    const profileData = useSelector(state => state.profile.userInfo)
    const [matchDetails, setMatchDetails] = useState({})
    const [url, setUrl] = useState('')
    const previousProps = useRef({
      getUrlLink
    }).current
    // handle the response
    useEffect(() => {
        if(matchId) {
            if(matchList && matchList.length > 0){
                const match = matchList.filter(data => data._id === matchId)
                if(match && match.length > 0) {
                    setMatchDetails(match[0])
                }
            }
        }
        if (token) {
          dispatch(GetUserProfile(token))
        }
    }, [token])

    useEffect(() => { // handle the response
      if (getUrlLink) {
        setUrl(getUrlLink)
      }
    }, [getUrlLink])
    // call the details
    function getDetails(ID2, sportsType) {
      if(token) {
        dispatch(getMatchList(sportsType))
        dispatch(getMatchLeagueDetails(ID2, token))
      }
    }

    return(
      <Component
        {...props}
        matchDetails={matchDetails}
        getDetails={getDetails}
        token={token}
        url={url}
        profileData={profileData}
      />
    )
  }
}

export default CommonInviteFriend
