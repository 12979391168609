import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import CompareTeam from 'Common/src/components/SportsLeagueList/CompareTeam'
import { getRandomColor, createImageFromInitials } from '../components/LetterImage'
import Loading from '../../../component/Loading'
import { isUpperCase } from '../../../helper'
import PlayerImage from '../../../assests/images/PlayerImage.png'
const classNames = require('classnames')

function TeamComparePage (props) {
  const { location, opponentTeam, myTeam, team1, team2, team1ScorePoint, team2ScorePoint, url, loading, match, history } = props
  const [sortedOpponentTeam, setData] = useState([])
  const [firstRank, setFirstRank] = useState('')
  const [secondRank, setSecondRank] = useState('')
  useEffect(() => { // handle the response
    if (match?.params?.sportsType) {
      const sport = match.params.sportsType
      isUpperCase(sport) && history.push(`/team-compare/${sport.toLowerCase()}/${match.params.id1}/${match.params.id2}`)
    }
    if (location && location.state) {
      setFirstRank(location?.state?.firstRank)
      setSecondRank(location?.state?.secondRank)
    }
  }, [])
  useEffect(() => { // handle the response
    if (opponentTeam && opponentTeam.notCommon) {
      const data = opponentTeam && opponentTeam.notCommon && opponentTeam.notCommon.length !== 0 && opponentTeam.notCommon.sort((a, b) => (a.eRole > b.eRole) ? 1 : -1)
      setData(data)
    }
  }, [opponentTeam])
  return (
    <>
      {loading && <Loading />}
      <div className="user-container no-footer">
        <div className="team-compare-name d-flex bg-white">
          <div className="tc-box">
            <div className="tc-img">
              <img alt="" className='fixImgHeiWid' src={team1.sProPic ? url + 'thumb/' + team1.sProPic : createImageFromInitials(500, team1.sUserName, getRandomColor())} />
            </div>
            <h2>
              {team1.sUserName}
              {' '}
              (
              {team1.sName}
              )
            </h2>
            <p>
              <FormattedMessage id="Welcome_Hash" />
              {' '}
              {firstRank || (team1.nRank ? team1.nRank : '-')}
            </p>
          </div>
          <div className="t-points text-center">
            <p className="m-0"><FormattedMessage id="Common_TotalPoints" /></p>
            <div className="points-box d-flex">
              <i className="icon-compare" />
              <span className={classNames({ active: team1ScorePoint > team2ScorePoint })}>{team1ScorePoint}</span>
              <span className={classNames({ active: team2ScorePoint > team1ScorePoint })}>{team2ScorePoint}</span>
            </div>
          </div>
          <div className="tc-box text-right">
            <div className="tc-img">
              <img alt="" className='fixImgHeiWid' src={team2.sProPic ? url + 'thumb/' + team2.sProPic : createImageFromInitials(500, team2.sUserName, getRandomColor())} />
            </div>
            <h2>
              {team2.sUserName}
              {' '}
              (
              {team2.sName}
              )
            </h2>
            <p>
              <FormattedMessage id="Welcome_Hash" />
              {' '}
              {secondRank || (team2.nRank ? team2.nRank : '-')}
            </p>
          </div>
        </div>
        <div className="twon-txt">
          {
            (team1.nTotalPoints > team2.nTotalPoints)
              ? (
                <p>
                  {team1.sUserName}
                  {' '}
                  (
                  {team1.sName}
                  )
                  {' '}
                  <span className='won-by-color'><FormattedMessage id="User_WON_BY" /></span>
                  {' '}
                  <span>{(team1.nTotalPoints - team2.nTotalPoints).toFixed(2)}</span>
                  {' '}
                  <FormattedMessage id="Player_points" />
                  {' '}
                </p>
                )
              : (team1.nTotalPoints < team2.nTotalPoints)
                  ? (
                    <p>
                      {team2.sUserName}
                      {' '}
                      (
                      {team2.sName}
                      )
                      {' '}
                      <span className='won-by-color'><FormattedMessage id="User_WON_BY" /></span>
                      {' '}
                      <span>{(team2.nTotalPoints - team1.nTotalPoints).toFixed(2)}</span>
                      {' '}
                      <FormattedMessage id="Player_points" />
                      {' '}
                    </p>
                    )
                  : (team1.nTotalPoints === team2.nTotalPoints) && (
                  <p>
                    {' '}
                    <FormattedMessage id="User_BOTH_PLAYER" />
                    {' '}
                  </p>
                    )
          }
        </div>
        <div className="compare-player bg-white">
          {
            myTeam.nCapPoints > opponentTeam.nCapPoints
              ? (
                <div className="point-title">
                  {' '}
                  <span className='point-title-span-color'>
                    {`${team1?.sUserName}(${team1?.sName})'s `}
                    <FormattedMessage id="League_myCVCLeadBy" />
                  </span>
                  {' '}
                  <span>{myTeam && myTeam.nCapPoints && opponentTeam.nCapPoints && ((myTeam?.nCapPoints)?.toFixed(2) - (opponentTeam?.nCapPoints)?.toFixed(2)).toFixed(2)}</span>
                  {' '}
                  <FormattedMessage id="Team_Pts" />
                  {' '}
                </div>
                )
              : (myTeam.nCapPoints === opponentTeam.nCapPoints)
                  ? (
                    <div className="point-title">
                      {' '}
                      <span className='point-title-span-color'><FormattedMessage id="League_bothCVCSame" /></span>
                      {' '}
                      <span>{myTeam && (myTeam?.nCapPoints)?.toFixed(2)}</span>
                      {' '}
                      <FormattedMessage id="Team_Pts" />
                      {' '}
                    </div>
                    )
                  : (
                    <div className="point-title">
                      {' '}
                      <span className='point-title-span-color'>
                        {`${team2?.sUserName}(${team2?.sName})'s `}
                        <FormattedMessage id="League_opponentCVCLeadBy" />
                      </span>
                      {' '}
                      <span>{opponentTeam && opponentTeam.nCapPoints && ((opponentTeam?.nCapPoints)?.toFixed(2) - (myTeam?.nCapPoints)?.toFixed(2)).toFixed(2)}</span>
                      {' '}
                      <FormattedMessage id="Team_Pts" />
                      {' '}
                    </div>
                    )
          }
          <div className="p-c-box d-flex align-items-center">
            <div className="p-box d-flex align-items-center">
              <div className="img">
                <img alt="" src={myTeam && myTeam.captain && myTeam.captain.sImage ? `${url}${myTeam.captain.sImage}` : PlayerImage} />
                <span className="p">
                  {' '}
                  <FormattedMessage id="Team_C" />
                  {' '}
                </span>
              </div>
              <div className="p-name">
                <h3>{myTeam && myTeam.captain && myTeam.captain.sName}</h3>
                <p>
                  <span>{myTeam?.captain?.oTeam?.sName}</span>
                  {' '}
                  {myTeam && myTeam.captain && myTeam.captain.eRole}
                </p>
              </div>
            </div>
            <div className="p-c-p-box justify-content-center d-flex">
              <span className={classNames({ active: myTeam && myTeam.captain && opponentTeam && opponentTeam.captain && (myTeam.captain.nScoredPoints > opponentTeam.captain.nScoredPoints) }) }>{myTeam && myTeam.captain && (myTeam?.captain?.nScoredPoints * 2)?.toFixed(2)}</span>
              <span className={classNames({ active: myTeam && myTeam.captain && opponentTeam && opponentTeam.captain && (myTeam.captain.nScoredPoints < opponentTeam.captain.nScoredPoints) }) }>{opponentTeam && opponentTeam.captain && (opponentTeam?.captain?.nScoredPoints * 2).toFixed(2)}</span>
            </div>
            <div className="p-box d-flex align-items-center">
              <div className="p-name text-right">
                <h3>{opponentTeam && opponentTeam.captain && opponentTeam.captain.sName}</h3>
                <p>
                  {opponentTeam && opponentTeam.captain && opponentTeam.captain.eRole}
                  {' '}
                  <span>{opponentTeam?.captain?.oTeam?.sName}</span>
                </p>
              </div>
              <div className="img">
                <img alt="" src={opponentTeam && opponentTeam.captain && opponentTeam.captain.sImage ? `${url}${opponentTeam.captain.sImage}` : PlayerImage} />
                <span className="p">
                  {' '}
                  <FormattedMessage id="Team_C" />
                </span>
              </div>
            </div>
          </div>
          <div className="p-c-box d-flex align-items-center">
            <div className="p-box d-flex align-items-center">
              <div className="img">
                <img alt="" src={myTeam && myTeam.captain && myTeam.viceCaptain.sImage ? `${url}${myTeam.viceCaptain.sImage}` : PlayerImage} />
                <span className="p"><FormattedMessage id="Team_VC" /></span>
              </div>
              <div className="p-name">
                <h3>{myTeam && myTeam.viceCaptain && myTeam.viceCaptain.sName}</h3>
                <p>
                  <span>{myTeam?.viceCaptain?.oTeam?.sName}</span>
                  {' '}
                  {myTeam && myTeam.viceCaptain && myTeam.viceCaptain.eRole}
                </p>
              </div>
            </div>
            <div className="p-c-p-box justify-content-center d-flex">
              <span className={classNames({ active: myTeam && myTeam.viceCaptain && opponentTeam && opponentTeam.viceCaptain && (myTeam.viceCaptain.nScoredPoints > opponentTeam.viceCaptain.nScoredPoints) })}>{myTeam && myTeam.viceCaptain && (myTeam?.viceCaptain?.nScoredPoints * 1.5)?.toFixed(2)}</span>
              <span className={classNames({ active: myTeam && myTeam.viceCaptain && opponentTeam && opponentTeam.viceCaptain && (myTeam.viceCaptain.nScoredPoints < opponentTeam.viceCaptain.nScoredPoints) })}>{opponentTeam && opponentTeam.viceCaptain && (opponentTeam?.viceCaptain?.nScoredPoints * 1.5)?.toFixed(2)}</span>
            </div>
            <div className="p-box d-flex align-items-center">
              <div className="p-name text-right">
                <h3>{opponentTeam && opponentTeam.viceCaptain && opponentTeam.viceCaptain.sName}</h3>
                <p>
                  {opponentTeam && opponentTeam.viceCaptain && opponentTeam.viceCaptain.eRole}
                  {' '}
                  <span>{opponentTeam?.viceCaptain?.oTeam?.sName}</span>
                </p>
              </div>
              <div className="img">
                <img alt="" src={opponentTeam && opponentTeam.viceCaptain && opponentTeam.viceCaptain.sImage ? `${url}${opponentTeam.viceCaptain.sImage}` : PlayerImage} />
                <span className="p"><FormattedMessage id="Team_VC" /></span>
              </div>
            </div>
          </div>
        </div>
        <div className="compare-player bg-white">
          {
            myTeam.nDifferentPoints > opponentTeam.nDifferentPoints
              ? (
                <div className="point-title">
                  {' '}
                  <span className='point-title-span-color'>
                    {`${team1?.sUserName}(${team1?.sName})'s `}
                    <FormattedMessage id="League_myPlayersLeadBy" />
                  </span>
                  {' '}
                  <span>
                    {' '}
                    {myTeam && myTeam.nDifferentPoints && opponentTeam.nDifferentPoints && ((myTeam?.nDifferentPoints)?.toFixed(2) - (opponentTeam?.nDifferentPoints)?.toFixed(2)).toFixed(2)}
                    {' '}
                  </span>
                  {' '}
                  <FormattedMessage id="Team_Pts" />
                  {' '}
                </div>
                )
              : (myTeam.nDifferentPoints === opponentTeam.nDifferentPoints)
                  ? (
                    <div className="point-title">
                      {' '}
                      <span className='point-title-span-color'><FormattedMessage id="League_bothPlayersLeadBy" /></span>
                      {' '}
                      <span>{myTeam && (myTeam?.nDifferentPoints)?.toFixed(2)}</span>
                      {' '}
                      <FormattedMessage id="Team_Pts" />
                      {' '}
                    </div>
                    )
                  : (
                    <div className="point-title">
                      {' '}
                      <span className='point-title-span-color'>
                        {`${team2?.sUserName}(${team2?.sName})'s `}
                        <FormattedMessage id="League_opponentPlayersLeadBy" />
                      </span>
                      {' '}
                      <span>{myTeam && myTeam.nDifferentPoints && opponentTeam.nDifferentPoints && ((opponentTeam?.nDifferentPoints)?.toFixed(2) - (myTeam?.nDifferentPoints)?.toFixed(2)).toFixed(2)}</span>
                      {' '}
                      <FormattedMessage id="Team_Pts" />
                      {' '}
                    </div>
                    )
          }
          {
            myTeam && myTeam.notCommon && myTeam.notCommon.length !== 0 && myTeam.notCommon.sort((a, b) => (a.eRole > b.eRole) ? 1 : -1).map((teamPlayer, index) => {
              return (
                <Fragment key={index}>
                  <div className="p-c-box d-flex align-items-center">
                    <div className="p-box d-flex align-items-center">
                      <div className="img">
                        <img alt="" src={teamPlayer?.sImage ? `${url}${teamPlayer.sImage}` : PlayerImage} />
                      </div>
                      <div className="p-name">
                        <h3>{teamPlayer.sName}</h3>
                        <p>
                          <span>
                            {teamPlayer?.oTeam?.sName}
                            {' '}
                          </span>
                          {teamPlayer.eRole}
                        </p>
                      </div>
                    </div>
                    <div className="p-c-p-box justify-content-center d-flex">
                      <span className={classNames({ active: sortedOpponentTeam?.length > 0 && teamPlayer.nScoredPoints > sortedOpponentTeam[index].nScoredPoints })}>{teamPlayer.nScoredPoints}</span>
                      <span className={classNames({ active: sortedOpponentTeam?.length > 0 && teamPlayer.nScoredPoints < sortedOpponentTeam[index].nScoredPoints })}>{sortedOpponentTeam?.length > 0 && sortedOpponentTeam[index].nScoredPoints}</span>
                    </div>
                    <div className="p-box d-flex align-items-center">
                      <div className="p-name text-right">
                        <h3>{sortedOpponentTeam?.length > 0 && sortedOpponentTeam[index].sName}</h3>
                        <p>
                          {sortedOpponentTeam?.length > 0 && sortedOpponentTeam[index].eRole}
                          {' '}
                          <span>{sortedOpponentTeam[index]?.oTeam?.sName}</span>
                        </p>
                      </div>
                      <div className="img">
                        <img alt="" src={sortedOpponentTeam?.length > 0 && sortedOpponentTeam[index].sImage ? `${url}${sortedOpponentTeam[index].sImage}` : PlayerImage} />
                      </div>
                    </div>
                  </div>
                </Fragment>
              )
            })
          }
        </div>
        <div className="compare-player bg-white">
          <div className="point-title">
            <span className='point-title-span-color'><FormattedMessage id="League_CommonPlayers" /></span>
            {' '}
            <span>
              {' '}
              {myTeam && (myTeam?.nCommonPoints)?.toFixed(2)}
              {' '}
            </span>
            <FormattedMessage id="Team_Pts" />
          </div>

          {
            myTeam && myTeam.common && myTeam.common.length !== 0 && myTeam.common.sort((a, b) => (a.eRole > b.eRole) ? 1 : -1).map((teamPlayer, index) => {
              return (
                <Fragment key={index}>
                  <div className="p-c-box d-flex align-items-center">
                    <div className="p-box d-flex align-items-center">
                      <div className="img">
                        <img alt="" src={teamPlayer.sImage ? `${url}${teamPlayer.sImage}` : PlayerImage} />
                      </div>
                      <div className="p-name">
                        <h3>{teamPlayer.sName}</h3>
                        <p>
                          <span>{teamPlayer?.oTeam?.sName}</span>
                          {' '}
                          {teamPlayer.eRole}
                        </p>
                      </div>
                    </div>
                    <div className="p-c-p-box justify-content-center d-flex">
                      <span>{teamPlayer.nScoredPoints}</span>
                    </div>
                    <div className="p-box d-flex align-items-center">
                      <div className="p-name text-right">
                        <h3>{teamPlayer.sName}</h3>
                        <p>
                          {teamPlayer.eRole}
                          {' '}
                          <span>{teamPlayer?.oTeam?.sName}</span>
                        </p>
                      </div>
                      <div className="img">
                        <img alt="" src={teamPlayer.sImage ? `${url}${teamPlayer.sImage}` : PlayerImage} />
                      </div>
                    </div>
                  </div>
                </Fragment>
              )
            })
          }
        </div>
      </div>
    </>
  )
}
TeamComparePage.propTypes = {
  onBackClick: PropTypes.string,
  url: PropTypes.string,
  loading: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      firstRank: PropTypes.object,
      secondRank: PropTypes.bool
    })
  }),
  myTeam: PropTypes.shape({
    common: PropTypes.shape([{
      length: PropTypes.string
    }]),
    notCommon: PropTypes.shape({
      length: PropTypes.string,
      map: PropTypes.func,
      sort: PropTypes.func
    }),
    nDifferentPoints: PropTypes.number,
    nCapPoints: PropTypes.number,
    nCommonPoints: PropTypes.number,
    viceCaptain: PropTypes.string,
    captain: PropTypes.shape([{
      sName: PropTypes.string,
      eRole: PropTypes.string
    }
    ])
  }),
  match: PropTypes.object,
  opponentTeam: PropTypes.object,
  team1: PropTypes.object,
  team1ScorePoint: PropTypes.number,
  team2ScorePoint: PropTypes.number,
  history: PropTypes.object,
  team2: PropTypes.object
}
export default CompareTeam(TeamComparePage)
