import React from 'react'
import PropTypes from 'prop-types'
import PaymentSlide from '../../../component/PaymentSlide'
import TournamentList from 'Common/src/components/User/TournamentList'
import Tournament from '../components/Tournament'
import Loading from '../../../component/Loading'
import { FormattedMessage } from 'react-intl'
import NoDataFound from '../../../assests/images/ic_no_data_found.svg'
import LoginMessage from '../../Auth/MessageScreen/LoginMessage'

function Tournaments (props) {
  const { paymentSlide, setPaymentSlide, list, loading, subIndex, history, activeState } = props

  return (
    <>
      {loading && <Loading />}
      {list && list.length !== 0 && list.sort(function (a, b) {
        return new Date(a.dStartDate) - new Date(b.dStartDate)
      }).map((data, i) => {
        return (
          <Tournament {...props} data={data} key={data._id} subIndex={subIndex} activeState={activeState} upcoming={subIndex === 1} live={subIndex === 2} completed={subIndex === 3} />
        )
      })}
       {list?.length <= 0 && ((!(history?.location?.pathname?.includes('/v1')) && (subIndex === 2 || subIndex === 3)) || subIndex === 1) && (
        <div className="no-team d-flex align-items-center justify-content-center fixing-width">
          <div className="nodatafound-width">
            <img src={NoDataFound}></img>
            <h6>
              {subIndex === 1 ? <FormattedMessage id="Teams_noData" /> : (subIndex === 2 || subIndex === 3) ? <FormattedMessage id="No_tournament_found" /> : ''}
            </h6>
          </div>
        </div>)}
        {history?.location?.pathname?.includes('/v1') && (subIndex === 2 || subIndex === 3) && <LoginMessage {...props}/>}
      {paymentSlide
        ? <PaymentSlide setPaymentSlide={setPaymentSlide} ></PaymentSlide>
        : ''}
    </>
  )
}

Tournaments.propTypes = {
  paymentSlide: PropTypes.bool,
  setPaymentSlide: PropTypes.func,
  history: PropTypes.object,
  list: PropTypes.object,
  loading: PropTypes.bool,
  subIndex: PropTypes.number,
  activeState: PropTypes.string
}

export default TournamentList(Tournaments)
