import axios from 'axios'
import { store } from '../redux/store'
import { version } from '../../../web/package.json'

const instanceAxios = axios.create({
    baseURL: process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_AXIOS_BASE_URL_PROD : process.env.REACT_APP_ENVIRONMENT === 'staging' ? process.env.REACT_APP_AXIOS_BASE_URL_STAG : process.env.REACT_APP_AXIOS_BASE_URL_DEV,
    headers: { Platform : 'W',  app_version: version, Language: 'en-US' }
})

instanceAxios.interceptors.response.use(response => response, (error) => {
    if (error.response && (error.response.status === 401)) {
        localStorage.removeItem('Token')
        store.dispatch({
            type: 'TOKEN_LOGIN',
            payload: {
                token: null
            }
        })
    }
    return Promise.reject(error)
})

export default instanceAxios