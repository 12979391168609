/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState, useRef } from 'react'
import PlayerDetails from 'Common/src/components/SportsLeagueList/PlayerDetails'
import Loading from '../../../component/SkeletonTable'
import { Link } from 'react-router-dom'
import { Table, Button, Nav, NavItem, NavLink, TabPane } from 'reactstrap'
import PropTypes from 'prop-types'
import { cricketStaticImage, footballStaticImage, roleFullForm, rolesShortForm } from '../../../helper'
import { FormattedMessage } from 'react-intl'
import PlayerImage from '../../../../src/assests/images/PlayerImage.png'
import HomeImage from '../../../assests/images/homeIconWhite.svg'
import left from '../../../assests/images/left-arrow-white.svg'
import right from '../../../assests/images/right-arrow-white.svg'
import { useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'
import TeamList from 'Common/src/components/SportsLeagueList/TeamList'
import qs from 'query-string'
import HomeTeam from '../../../assests/images/Team1.png'
import AwayTeam from '../../../assests/images/Team2.png'
import redHat from '../../../assests/images/red-hat.svg'
import greenHat from '../../../assests/images/green-hat.svg'
import { useQueryState } from 'react-router-use-location-state'
import { getPlayerScorePointEventData } from 'Common/src/redux/actions/player'
import moment from 'moment'
import NoDataFound from '../../../assests/images/ic_no_data_found.svg'
import SkeletonPlayerStatus from '../../../component/SkeletonPlayerStatus'
const classnames = require('classnames')

function PlayerInfo (props) {
  const {
    location, seasonMatch, playerRoles, teamPlayerList, getMatchPlayerList, teamList, getUserTeam, userTeam, playerSeasonNames, matchPlayer, nScoredPoints, playerData, pointBreakUp, token, loading, playerScorePoints, match, history,
    matchDetails
  } = props
  const dispatch = useDispatch()
  const url = useSelector(state => state.url.getUrl)
  const [index, setIndex] = useState('')
  const [team, setTeam] = useState([])
  const [scorePointEventData, setScorePointEventData] = useState({})
  const activeSports = useSelector(state => state.activeSports.activeSports)
  const eventDataArr = useSelector(state => state.player.eventData)
  const activeSport = activeSports && activeSports.length > 0 && activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => data)
  const obj = qs.parse(location.search)
  const [activeTab, setActiveState] = useQueryState('activeTab', 1)
  const previousProps = useRef({ userTeam, teamList, eventDataArr }).current

  const toggle = tab => {
    if (activeTab !== tab) setActiveState(tab)
  }

  useEffect(() => {
    if (obj?.activeTab) setActiveState(obj?.activeTab)
  }, [])

  useEffect(() => {
    if (activeTab === 2 && token) {
      dispatch(getPlayerScorePointEventData(location?.state?.matchPlayerId || match?.params?.playerId, token))
    }
  }, [activeTab, token])

  useEffect(() => {
    if (previousProps.eventDataArr !== eventDataArr) {
      if (eventDataArr?.eventData?.length > 0) {
        const filteredEvent = {}
        eventDataArr?.eventData?.sort((a, b) => {
          return moment(b?.dCreatedAt).diff(moment(a?.dCreatedAt))
        }).forEach(data => {
          const title = data?.sGameTime || ''
          filteredEvent[title] = filteredEvent[title] || []
          filteredEvent[title].push(data)
        })
        setScorePointEventData(filteredEvent)
      }
    }
    return () => {
      previousProps.eventDataArr = eventDataArr
    }
  }, [eventDataArr])

  useEffect(() => {
    if (match?.params?.matchID && token) {
      getMatchPlayerList(match?.params?.matchID)
    }
    if (match?.params?.userTeamID && token) {
      getUserTeam(match?.params?.userTeamID)
    }
    if (match?.params?.playerId && token) {
      playerSeasonNames(match?.params?.playerId)
    }
  }, [token, match.params])

  useEffect(() => {
    if (location && location.state && location.state.matchPlayerId) {
      playerScorePoints(location.state.matchPlayerId)
    }
  }, [location.state])

  useEffect(() => {
    if (obj && obj?.sortBy !== 'undefined') {
      const value = obj?.sortBy
      const sorted = obj?.sort
      let listedItem
      if (matchPlayer?.length > 0) {
        if (sorted === 'true') {
          if (value === 'players') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? 1 : -1)
            setTeam(listedItem)
          } else if (value === 'points') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => Number(a.nScoredPoints) > Number(b.nScoredPoints) ? -1 : 1)
            setTeam(listedItem)
          } else if (value === 'selBy') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => a.nSetBy > b.nSetBy ? -1 : 1)
            setTeam(listedItem)
          } else if (value === 'C') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => a.nCaptainBy > b.nCaptainBy ? -1 : 1)
            setTeam(listedItem)
          } else if (value === 'VC') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => a.nViceCaptainBy > b.nViceCaptainBy ? -1 : 1)
            setTeam(listedItem)
          }
        } else {
          if (value === 'players') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? -1 : 1)
            setTeam(listedItem)
          } else if (value === 'points') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => Number(a.nScoredPoints) > Number(b.nScoredPoints) ? 1 : -1)
            setTeam(listedItem)
          } else if (value === 'selBy') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => a.nSetBy > b.nSetBy ? 1 : -1)
            setTeam(listedItem)
          } else if (value === 'C') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => a.nCaptainBy > b.nCaptainBy ? 1 : -1)
            setTeam(listedItem)
          } else if (value === 'VC') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => a.nViceCaptainBy > b.nViceCaptainBy ? 1 : -1)
            setTeam(listedItem)
          }
        }
      }
      listedItem?.map((player, index) => {
        if (player._id === match?.params?.playerId) {
          setIndex(parseInt(index))
        }
        return index
      })
    }
  }, [matchPlayer])

  useEffect(() => {
    team?.length > 0 && team?.filter((player, index) => {
      if (location?.state?.matchPlayerId) {
        if (player._id === location?.state?.matchPlayerId) {
          setIndex(parseInt(index))
        }
      } else {
        if (player._id === match?.params?.playerId) {
          setIndex(parseInt(index))
        }
      }
      return player._id === match?.params?.playerId
    })
  }, [team])

  useEffect(() => {
    if (obj?.playerLeagueInfo || location?.state?.teamCreationPage) {
      setTeam(seasonMatch)
    } else {
      if ((previousProps.userTeam !== userTeam) || teamPlayerList?.length !== 0) {
        if (match?.params?.userTeamID && userTeam && userTeam.length !== 0 && teamPlayerList) {
          const playerRole = playerRoles?.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).sort((a, b) => a?.nPosition?.toString().localeCompare(b?.nPosition?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map(Role => Role.sName)
          const players = Object.assign({}, ...playerRole?.map(key => ({ [key]: [] })))
          userTeam && userTeam.length !== 0 && userTeam?.aPlayers?.length !== 0 && userTeam?.aPlayers?.map((Player) => {
            const PlayerDetails = teamPlayerList && teamPlayerList.length !== 0 && teamPlayerList.find(player => player._id === Player.iMatchPlayerId)
            Object.entries(players).map(([key, value]) => {
              return (key === PlayerDetails?.eRole) && players[PlayerDetails?.eRole].push(PlayerDetails)
            })
            return players
          })
          const tempData = []
          Object.entries(players).map(([key, value]) => value && value.length > 0 && value.sort((a, b) => a.sName > b.sName ? 1 : -1).map(playerInfo => tempData.push(playerInfo)))
          setTeam(tempData)
        }
      }
    }
    return () => {
      previousProps.userTeam = userTeam
    }
  }, [userTeam, teamPlayerList, seasonMatch])

  const previous = () => {
    const nextIndex = index === 0 ? team.length - 1 : index - 1
    const matchPlayerId = team[nextIndex]?._id
    if (location?.state?.playerStat) {
      history.replace({
        pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/view-playerstat-info/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${matchPlayerId}` : `/view-playerstat-info/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${matchPlayerId}`,
        search: `?${qs.stringify({
            sortBy: obj?.sortBy || undefined,
            sort: obj?.sort || undefined,
            playerStat: true || undefined
          })}`,
        state: { playerStat: true }
      })
    } else if (location?.state?.teamCreationPage) {
      history.replace({
        pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/create-team/view-player-info/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${matchPlayerId}` : `/create-team/view-player-info/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${matchPlayerId}`,
        search: `?${qs.stringify({
          homePage: obj?.homePage ? 'yes' : undefined,
          playerLeagueInfo: obj?.playerLeagueInfo ? 'y' : undefined
          })}`,
        state: { teamCreationPage: true, matchPlayerId }
      })
    } else {
      history.replace({
        pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/view-player-Info/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${match.params.userTeamID}/${matchPlayerId}` : `/view-player-Info/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${match.params.userTeamID}/${matchPlayerId}`,
        search: `?${qs.stringify({
            homePage: obj?.homePage ? 'yes' : undefined,
            playerLeagueInfo: obj?.playerLeagueInfo ? 'y' : undefined
          })}`,
        state: { matchPlayerId }
      })
    }
  }

  const next = () => {
    const nextIndex = index === team.length - 1 ? 0 : index + 1
    const matchPlayerId = team[nextIndex]?._id
    if (location?.state?.playerStat) {
      history.replace({
        pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/view-playerstat-info/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${matchPlayerId}` : `/view-playerstat-info/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${matchPlayerId}`,
        search: `?${qs.stringify({
            sortBy: obj?.sortBy || undefined,
            sort: obj?.sort || undefined,
            playerStat: true || undefined
          })}`,
        state: { playerStat: true }
      })
    } else if (location?.state?.teamCreationPage) {
      history.replace({
        pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/create-team/view-player-info/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${matchPlayerId}` : `/create-team/view-player-info/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${matchPlayerId}`,
        search: `?${qs.stringify({
          homePage: obj?.homePage ? 'yes' : undefined,
          playerLeagueInfo: obj?.playerLeagueInfo ? 'y' : undefined
          })}`,
        state: { teamCreationPage: true, matchPlayerId }
      })
    } else {
      history.replace({
        pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/view-player-Info/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${match.params.userTeamID}/${matchPlayerId}` : `/view-player-Info/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${match.params.userTeamID}/${matchPlayerId}`,
        search: `?${qs.stringify({
            homePage: obj?.homePage ? 'yes' : undefined,
            playerLeagueInfo: obj?.playerLeagueInfo ? 'y' : undefined
          })}`,
        state: { matchPlayerId }
      })
    }
  }

  return (
    <>
      {loading && <Loading Lines={7} series/>}
      <div className="player-info">
        <div className="league-header u-header">
          <div className="d-flex align-items-center header-i">
            <button className="btn-link icon-left-arrow"
              onClick={() => {
                history.goBack()
              // if (modalOpen) {
              //   onBackClick()
              // } else if (obj?.playerLeagueInfo) {
              //   props.history.push({
              //     pathname: `/view-player-leagueInfo/${(match.params.sportsType).toLowerCase()}/${match.params.matchID}/${match.params.userTeamID}/${match?.params?.playerId}`,
              //     search: `?${qs.stringify({
              //       homePage: obj?.homePage ? 'yes' : undefined,
              //       playerLeagueInfo: obj?.playerLeagueInfo ? 'y' : undefined
              //     })}`
              //   })
              // } else if (obj?.userLeague && obj?.index) {
              //   props.history.push(`/team-preview/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${obj?.userLeague}/${match.params.userTeamID}/${obj?.index}`)
              // } else if (obj?.playerStat && matchDetails?.eStatus === 'L') {
              //   props.history.push(`/liveleague/${match.params.matchID}/${match.params.sportsType.toLowerCase()}`)
              // } else if (obj?.playerStat && (matchDetails?.eStatus === 'CMP' || matchDetails?.eStatus === 'I')) {
              //   props.history.push(`/completedleagues/participated/${match.params.matchID}/${match.params.sportsType.toLowerCase()}?activeState=3`)
              // } else {
              //   props.history.push(`/my-teams-preview/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${match.params.userTeamID}`)
              // }
              }}
            />
            <Button className='button-link bg-transparent py-2' tag={Link} to={activeSport && `/home/${(activeSport[0]?.sKey).toLowerCase()}`}><img src={HomeImage} /></Button>
            <div>
              <h1 className="text-uppercase">{team && team[index]?.sName}</h1>
              {obj?.playerLeagueInfo === 'y'
                ? <p>{team && team[index]?.oMatch?.sName}</p>
                : (
                  <p>
                    {team && team[index]?.sTeamName}
                    {', '}
                    {team && rolesShortForm(team[index]?.eRole)}
                  </p>
                  )}
            </div>
          </div>
        </div>
        <div className='height-103'>
          <div className="player-detail no-pad">
            <div className='player-info-data d-flex justify-content-start'>
              <div className="p-i d-flex align-items-end">
                <img alt="" className='fullBorderRadius' src={playerData?.sImage && url ? `${url}${playerData?.sImage}` : match && match.params && match.params.sportsType === 'cricket' ? cricketStaticImage(playerData && playerData.eRole && playerData.eRole) : match && match.params && match.params.sportsType === 'football' ? footballStaticImage(playerData && playerData.eRole && playerData.eRole) : PlayerImage} />
              </div>
              <div className='player-d'>
                {!(obj?.playerLeagueInfo === 'y') && <button className='player-role-box'>{team && roleFullForm(team[index]?.eRole)}</button>}
                <div className='my-3'>
                  <p className='player-name'>{team && team[index]?.sName}</p>
                  <p className='player-team'>{team && team[index]?.sTeamName}</p>
                  <div className='stroke' />
                </div>
                <button className='player-points-box'>
                  <p><FormattedMessage id='League_Points' /></p>
                  <p className='p-point'>{nScoredPoints}</p>
                </button>
              </div>
            </div>
            {!location.pathname.includes('tournament') && (
            <div className='player-team-detail container'>
              <div className='row'>
                <div className={`col-5 team-name d-flex justify-content-end align-items-center  ${document.dir === 'rtl' ? 'text-start' : 'text-end'}`}>
                  <p className={document.dir === 'rtl' ? 'pl-2' : 'pr-2'}>{(team && team[index]?.oMatch?.oHomeTeam?.sShortName) || matchDetails?.oHomeTeam?.sShortName}</p>
                  <img src={team?.[index]?.oMatch?.oHomeTeam?.sImage ? url + team[index]?.oMatch?.oHomeTeam?.sImage : matchDetails?.oHomeTeam?.sImage ? url + matchDetails?.oHomeTeam?.sImage : HomeTeam} />
                </div>
                <div className={`col-2 score-card d-flex justify-content-around align-items-center  ${document.dir === 'rtl' ? 'text-start' : 'text-end'}`}>
                  <div>{team?.[index]?.oMatch?.oHomeTeam?.nScore || matchDetails?.oHomeTeam?.nScore}</div>
                  <div>{team?.[index]?.oMatch?.oAwayTeam?.nScore || matchDetails?.oAwayTeam?.nScore}</div>
                </div>
                <div className={`col-5 team-name d-flex justify-content-start align-items-center ${document.dir === 'rtl' ? 'text-end' : 'text-start'}`}>
                  <img src={team?.[index]?.oMatch?.oAwayTeam?.sImage ? url + team[index]?.oMatch?.oAwayTeam?.sImage : matchDetails?.oAwayTeam?.sImage ? url + matchDetails?.oAwayTeam?.sImage : AwayTeam} />
                  <p className={document.dir === 'rtl' ? 'pr-2' : 'pl-2'}>{(team && team[index]?.oMatch?.oAwayTeam?.sShortName) || matchDetails?.oAwayTeam?.sShortName}</p>
                </div>
              </div>
            </div>
            )}
            <div className="player-points-selection d-flex justify-content-between align-items-center">
              <div className="item">
                <p className='title'><FormattedMessage id="Player_credits" /></p>
                <p className='data'>{team && team[index]?.nFantasyCredit ? Number.isInteger(team[index].nFantasyCredit) ? team[index].nFantasyCredit : team[index].nFantasyCredit.toFixed(2) : 0}</p>
              </div>
              <div className='player-points-sel-partition' />
              <div className="item">
                <p className='title'><FormattedMessage id="Player_selectedBy" /></p>
                <p className='data'>
                  {team && team[index]?.nSetBy ? team[index].nSetBy : 0}
                  <FormattedMessage id="Common_perc" />
                </p>
              </div>
              <div className='player-points-sel-partition' />
              <div className="item">
                <p className='title'><FormattedMessage id="Player_c" /></p>
                <p className='data'>
                  {team && team[index]?.nCaptainBy ? team[index].nCaptainBy : 0}
                  <FormattedMessage id="Common_perc" />
                </p>
              </div>
              <div className='player-points-sel-partition' />
              <div className="item">
                <p className='title'><FormattedMessage id="Player_vc" /></p>
                <p className='data'>
                  {team && team[index]?.nViceCaptainBy ? team[index].nViceCaptainBy : 0}
                  <FormattedMessage id="Common_perc" />
                </p>
              </div>
            </div>
          </div>
          <Nav className={`live-tabs score-info justify-content-around ${matchDetails && matchDetails.sFantasyPost && 'four-tabs'}`}>
            <NavItem className='text-center'>
              <NavLink className={classnames({ active: activeTab === 1 })}
                onClick={() => {
                  toggle(1)
                  // setActiveState(1)
                }}
              >
                <FormattedMessage id="General" />

              </NavLink>
            </NavItem>
            <NavItem className='text-center'>
              <NavLink className={classnames({ active: activeTab === 2 })}
                onClick={() => {
                  toggle(2)
                  // setActiveState(2)
                }}
              >
                <FormattedMessage id="More_info" />
              </NavLink>
            </NavItem>
          </Nav>
          {activeTab === 1 && (
          <TabPane tabId={1}>
            <Table className="player-d-t">
              <thead>
                <tr>
                  <th><FormattedMessage id="Player_event" /></th>
                  <th><FormattedMessage id="Player_action" /></th>
                  <th><FormattedMessage id="Player_points" /></th>
                  <th><FormattedMessage id="Common_Total" /></th>
                </tr>
              </thead>
              <tbody>
                {
              loading
                ? <Loading Lines={7} series={true}/>
                : pointBreakUp?.length > 0
                  ? pointBreakUp.map((points) => (
                    points?.nPoint
                      ? (
                        <>
                          <tr key={points._id}>
                            <td>{points && points.sName && points.sName}</td>
                            <td>{points && points.nPoint && points.nScoredPoints ? parseFloat(Number((points.nScoredPoints / points.nPoint)).toFixed(2)) : !points.nPoint ? (points?.nScoredPoints !== 0 ? 'Yes' : 'No') : 0}</td>
                            <td>{points?.nPoint || 0}</td>
                            <td>
                              {points && points.nScoredPoints && points.nScoredPoints}
                              {' '}
                            </td>
                          </tr>
                        </>
                        )
                      : ''
                  ))
                  : (
                    <Fragment>
                      <tr>
                        <td colSpan='3'>
                          <FormattedMessage id="NO_PointBreakup_Avail" />
                        </td>
                      </tr>
                    </Fragment>
                    )}
                <tr>
                  <td colSpan="3"><FormattedMessage id="Common_Total" /></td>
                  <td>{nScoredPoints}</td>
                </tr>
              </tbody>
            </Table>
          </TabPane>
          )}
          {activeTab === 2 && (
            <TabPane tabId={2}>
              {scorePointEventData && Object.keys(scorePointEventData)?.length > 0
                ? Object.entries(scorePointEventData).map(([key, value]) => (
                  <>
                    {key === '1°T' && <header className='player-more-info-heading'><FormattedMessage id='First_Inning' /></header>}
                    {key === '2°T' && <header className='player-more-info-heading'><FormattedMessage id='Second_Inning' /></header>}
                    {key === 'Pen' && <header className='player-more-info-heading'><FormattedMessage id='Penalty_dispute' /></header>}
                    {key === 'Canc' && <header className='player-more-info-heading'><FormattedMessage id='Match_cancelled' /></header>}
                    <div className='table-container mb-4'>
                      <Table className="player-more-info">
                        <thead>
                          <tr>
                            <th />
                            <th><FormattedMessage id="Tempo" /></th>
                            <th><FormattedMessage id="Event" /></th>
                            <th><FormattedMessage id="Team_Pts" /></th>
                            <th><FormattedMessage id="Common_Total" /></th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            {value && value.length > 0 && value?.map((eData, index) => (
                              <tr key={index}>
                                <td><img src={eData?.nPoint >= 0 ? greenHat : redHat} /></td>
                                <td>
                                  {key}
                                  {' '}
                                  {eData?.nMinute}
                                  :
                                  {eData?.nSecond % 60}
                                </td>
                                <td>{eData?.sEventName}</td>
                                <td className={eData?.nPoint >= 0 ? 'green' : 'red'}>{eData?.nPoint}</td>
                                <td className={eData?.nPoint >= 0 ? 'green' : 'red'}>{eData?.nTotal}</td>
                              </tr>
                            ))}
                          </>
                        </tbody>
                      </Table>
                    </div>
                  </>
                )
                )
                : (
                  <div className="d-flex align-items-center justify-content-center more-info-h">
                    <div>
                      <img src={NoDataFound} />
                      <h4><FormattedMessage id="Data_not_available" /></h4>
                    </div>
                  </div>
                  )}
            </TabPane>
          )}
        </div>
        <div className="np-bottom">
          <div className="d-flex align-items-center justify-content-between">
            <div className='custom-btn pl-2'
              disabled={index === 0}
              onClick={() => previous()}
            >
              <img className='pr-2' src={left} />
              <FormattedMessage id="other_Prev" />
            </div>
            <div className='team-data'>{team && index + 1 + '/' + team?.length}</div>
            <div className='custom-btn pr-2 text-right'
              disabled={team?.length ? index === team.length - 1 : index === matchPlayer?.length - 1}
              onClick={() => next()}
            >
              <FormattedMessage id="other_Next" />
              <img className='pl-2' src={right} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

PlayerInfo.propTypes = {
  playerScorePoints: PropTypes.func,
  playerData: PropTypes.shape({
    nFantasyCredit: PropTypes.number,
    nSetBy: PropTypes.number,
    nScoredPoints: PropTypes.number,
    nCaptainBy: PropTypes.number,
    nViceCaptainBy: PropTypes.number,
    eRole: PropTypes.string,
    sName: PropTypes.string,
    sImage: PropTypes.string
  }),
  match: PropTypes.object,
  history: PropTypes.object,
  pointBreakUp: PropTypes.array,
  nScoredPoints: PropTypes.number,
  loading: PropTypes.bool,
  token: PropTypes.string,
  matchPlayer: PropTypes.array,
  location: PropTypes.object,
  playerSeasonNames: PropTypes.func,
  userTeam: PropTypes.object,
  getUserTeam: PropTypes.func,
  getMatchPlayerList: PropTypes.func,
  teamList: PropTypes.array,
  teamPlayerList: PropTypes.object,
  matchDetails: PropTypes.object,
  playerRoles: PropTypes.object,
  seasonMatch: PropTypes.object
}

export default compose(TeamList, PlayerDetails)(PlayerInfo)
