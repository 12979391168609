import React, { useState } from 'react'
import LeagueHeader from '../components/LeagueHeader'
import LeaguesPage from './Leagues'
import qs from 'query-string'
import PropTypes from 'prop-types'

function Leagues (props) {
  const { match } = props
  const obj = qs.parse(props.location.search)
  const [VideoStream, setVideoStream] = useState(false)
  const [paymentSlide, setPaymentSlide] = useState(false)

  return (
    <>
      <LeagueHeader
        {...props}
        goToBack={obj?.homePage ? `/home/${match && match.params && match.params.sportsType}` : `/matches/${match && match.params && match.params.sportsType}`}
        setVideoStream={setVideoStream}
        VideoStream={VideoStream}
        paymentSlide={paymentSlide}
        setPaymentSlide={setPaymentSlide}
        showBalance
      />
      <LeaguesPage {...props} VideoStream={VideoStream} paymentSlide={paymentSlide}
        setPaymentSlide={setPaymentSlide}/>
    </>
  )
}

Leagues.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object
}

export default Leagues
