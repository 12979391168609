import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getMatchDetails } from 'Common/src/redux/actions/match'
import { getMatchLeagueDetails } from 'Common/src/redux/actions/league'
import { GetAdsList } from 'Common/src/redux/actions/ads'
import { store } from 'Common/src/redux/store'

function Ads (props) {
  const { url, showingImage, bannerImg, setShowingImage, setBannerImg } = props
  const history = useHistory()
  const AdsList = useSelector(state => state.ads.adsList)
  const matchDetails = useSelector(state => state.match.matchDetails)
  const matchLeagueDetails = useSelector(state => state.league.matchLeagueDetails)

  const token = localStorage.getItem('Token')
  const [adsData, setAdsData] = useState({})
  const [redirect, setRedirect] = useState(false)
  const [redirectFlag, setRedirectFlag] = useState(false)

  async function popupClick () {
    if (adsData && adsData.eType === 'E') {
      const newWindow = window.open(adsData.sLink, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
      setShowingImage(false)
    } else if (adsData && adsData.eType === 'I' && token) {
      await store.dispatch(getMatchLeagueDetails(adsData && adsData.iMatchLeagueId, '', token))
      await store.dispatch(getMatchDetails(adsData && adsData.iMatchId, '', token))
      setShowingImage(false)
    }
    setRedirectFlag(true)
  }

  useEffect(() => {
    if (token) {
      store.dispatch(GetAdsList())
    }
  }, [])

  useEffect(() => {
    if (matchLeagueDetails && matchDetails && redirectFlag) {
      setRedirect(true)
    }
  }, [matchLeagueDetails, matchDetails, redirectFlag])

  useEffect(() => {
    if (redirect) {
      if (matchDetails && matchDetails._id && matchDetails.eStatus === 'U') {
        if (adsData && adsData.iMatchId && adsData.iMatchLeagueId && matchLeagueDetails && !matchLeagueDetails.bCancelled) {
          history.push(`/leagues-detail/${(adsData.eCategory).toLowerCase()}/${adsData.iMatchId}/${adsData.iMatchLeagueId}`)
        } else if (adsData && adsData.iMatchId) {
          history.push(`/leagues/${(adsData.eCategory).toLowerCase()}/${adsData.iMatchId}`)
        }
      } else if (matchDetails && matchDetails._id && matchDetails.eStatus === 'L') {
        if (adsData && adsData.iMatchId && adsData.iMatchLeagueId && adsData.eTransactionType !== 'Play-Return' && matchLeagueDetails && !matchLeagueDetails.bCancelled) {
          history.push(`/leagues-details/${(adsData.eCategory).toLowerCase()}/${adsData.iMatchId}/${adsData.iMatchLeagueId}/participated`)
        } else if (adsData && adsData.iMatchId) {
          history.push(`/liveleague/${adsData.iMatchId}/${(adsData.eCategory).toLowerCase()}`)
        }
      } else if (matchDetails && matchDetails._id && (matchDetails.eStatus === 'CMP' || matchDetails.eStatus === 'I')) {
        if (adsData && adsData.iMatchId && adsData.iMatchLeagueId && adsData.eTransactionType !== 'Play-Return' && matchLeagueDetails && !matchLeagueDetails.bCancelled) {
          history.push(`/leagues-details/${(adsData.eCategory).toLowerCase()}/${adsData.iMatchId}/${adsData.iMatchLeagueId}/participated`)
        } else if (adsData && adsData.iMatchId) {
          history.push(`/completedleagues/participated/${adsData.iMatchId}/${(adsData.eCategory).toLowerCase()}`)
        }
      }
      setRedirect(false)
      setRedirectFlag(false)
    }
  }, [matchDetails, matchLeagueDetails, redirect])

  useEffect(() => {
    if (AdsList?.length > 0) {
      const AdIndex = localStorage.getItem('AdIndex')
      const data = AdsList.filter(data => data.ePlatform === 'W' || data.ePlatform === 'ALL')
      if (AdIndex && parseInt(AdIndex) < data.length) {
        if ((parseInt(AdIndex) + 1) >= data.length) {
          localStorage.setItem('AdIndex', 0)
          setBannerImg(data[0].sImage)
          setAdsData(data[0])
        } else {
          localStorage.setItem('AdIndex', parseInt(AdIndex) + 1)
          setBannerImg(data[parseInt(AdIndex) + 1].sImage)
          setAdsData(data[parseInt(AdIndex) + 1])
        }
      } else {
        localStorage.setItem('AdIndex', 0)
        setBannerImg(data && data.length && data[0].sImage)
        setAdsData(data && data.length && data[0])
      }
    }
  }, [AdsList])

  return (
    <div>
      {
        showingImage && url && bannerImg
          ? (
            <div className="AdAlert alert-center"
              onClick={() => popupClick()}
              style={{
                backgroundImage: `url(${url + bannerImg})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '250px',
                width: '250px'
              }}
            >
              {/* <img src={url + bannerImg} onClick={() => imageShawing()}></popupClick> */}
              <span className="tag"
                onClick={(e) => {
                  setShowingImage(false)
                  e.stopPropagation()
                }}
              >
                X
              </span>
            </div>
            )
          : ''
      }
    </div>
  )
}

Ads.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  url: PropTypes.string,
  showingImage: PropTypes.bool,
  bannerImg: PropTypes.string,
  setShowingImage: PropTypes.func,
  setBannerImg: PropTypes.func
}

export default Ads
