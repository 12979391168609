import React from 'react'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import home from '../../../assests/images/homeIcon.svg'
import homeFill from '../../../assests/images/homeIconFill.svg'
import mymatches from '../../../assests/images/mymatches.svg'
import mymatchesFill from '../../../assests/images/mymatchesFill.svg'
import profile from '../../../assests/images/profileFooter.svg'
import profileFill from '../../../assests/images/profileFooterFill.svg'
import leaderboard from '../../../assests/images/leaderboard.svg'
import leaderboardFill from '../../../assests/images/leaderboardFill.svg'
import more from '../../../assests/images/more.svg'
import moreFill from '../../../assests/images/moreFill.svg'
const classNames = require('classnames')

function HomeFooter (props) {
  const { match, isPublic } = props
  const homeClass = classNames({ active: match && (match.path === '/home/:sportsType' || match.path === '/live-stream/:type') })
  const matchClass = classNames({ active: match && match.path === '/matches/:sportsType' })
  const activeSports = useSelector(state => state.activeSports.activeSports)
  const activeSport = activeSports && activeSports.length > 0 && activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => data)
  // const profileclass = classNames({ active: match && (match.path === '/profile' || match.path === '/profile/v1') })
  // const gameLeaderboard = classNames({ active: match && (match.path === '/game/leaderboard' || match.path === '/game/leader-board/v1') })
  // const More = classNames({ active: match && (match.path === '/more' || match.path === '/more/v1') })

  return (
    <ul className="d-flex align-items-center home-footer m-0">
      <li>
        <NavLink to={match && match.params && match.params.sportsType ? (isPublic ? `/home/${match?.params?.sportsType}/v1` : `/home/${match?.params?.sportsType}`) : (isPublic ? `/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}/v1` : `/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}`)} exact={false} className={homeClass}>
          {match?.path?.includes('/home') || match?.path?.includes('/live-stream') ? <img src={homeFill} /> : <img src={home} />}
          <div><FormattedMessage id="Footer_Home" /></div>
        </NavLink>
      </li>
      <li>
        <NavLink to={match && match.params && match.params.sportsType ? (isPublic ? `/matches/${match?.params?.sportsType}/v1` : `/matches/${match?.params?.sportsType}`) : (isPublic ? `/matches/${activeSport && (activeSport[0]?.sKey).toLowerCase()}/v1` : `/matches/${activeSport && (activeSport[0]?.sKey).toLowerCase()}`)} className={matchClass}>
          {match?.path?.includes('/matches') ? <img src={mymatchesFill} /> : <img src={mymatches} />}
          <div><FormattedMessage id="Footer_Matches" /></div>
        </NavLink>
      </li>
      <li>
        <NavLink to={isPublic ? '/profile/v1' : '/profile'}>
          {match?.path?.includes('/profile') ? <img src={profileFill} /> : <img src={profile} />}
          <div><FormattedMessage id="Footer_Profile" /></div>
        </NavLink>
      </li>
      <li>
        <NavLink to={isPublic ? '/game/leader-board/v1' : '/game/leaderboard'}>
          {(match.path.includes('/game/leaderboard') || match.path === '/game/leader-board/v1') ? <img src={leaderboardFill} /> : <img src={leaderboard} />}
          <div><FormattedMessage id="League_leaderboard" /></div>
        </NavLink>
      </li>
      <li>
        <NavLink to={isPublic ? '/more/v1' : '/more'}>
          {match.path.includes('/more') ? <img src={moreFill} /> : <img src={more} />}
          <div><FormattedMessage id="Footer_More" /></div>
        </NavLink>
      </li>
    </ul>
  )
}

HomeFooter.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      sportsType: PropTypes.string
    }),
    url: PropTypes.string,
    path: PropTypes.string
  }),
  isPublic: PropTypes.bool
}

export default HomeFooter
