import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sendOTP, VerifyOTP, Registration, CheckExist, validateCpf } from '../../redux/actions/auth.js'
import storage from '../../localStorage/localStorage'

export const RegisterPage = (Component) => {
  return (props) => {
    const dispatch = useDispatch()
    const [message, setMessage] = useState('')
    const [modalMessage, setModalMessage] = useState(false)
    const [loading, setLoading] = useState(false)
    const [sendedOTP, setSendedOTP] = useState(false)
    const [registerData, setRegisterData] = useState({})


    const userInfo = useSelector(state => state.auth.userInfo)
    const resStatus = useSelector(state => state.auth.resStatus)
    const resMessage = useSelector(state => state.auth.resMessage)
    const messageType = useSelector(state => state.auth.messageType)
    const FirebaseToken = useSelector(state => state.auth.FirebaseToken)
    const otpVerified = useSelector(state => state.auth.otpVerified)
    const registerSuccess = useSelector(state => state.auth.registerSuccess)
    const sendOtp = useSelector(state => state.auth.sendOtp)
    const previousProps = useRef({ resMessage, resStatus, sendOtp, registerSuccess, otpVerified  }).current
    useEffect(() => { // Handlle loader
      if (previousProps.sendOtp !== sendOtp) {
        if (sendOtp) {
          setLoading(false)
        } 
        setSendedOTP(sendOtp)
      }
      return () => {
        previousProps.sendOtp = sendOtp
      }
    }, [sendOtp])

    useEffect(() => { // Handlle loader
      if (previousProps.registerSuccess !== registerSuccess) {
        if (registerSuccess) {
          setLoading(false)
        } else {
          setMessage(resMessage)
          setModalMessage(true)
          setLoading(false)
        }
      }
      return () => {
        previousProps.registerSuccess = registerSuccess
      }
    }, [registerSuccess])

    useEffect(() => { // handle Api response
      if (previousProps.otpVerified !== otpVerified && otpVerified !== null) {
        if (otpVerified) {
          if (registerData) {
            const deviceId = storage.getItem('ID')
            Registrationfun(registerData.Platform, registerData.userName, registerData.name, registerData.email, registerData.mobileNumber, registerData.Password, registerData.otp, registerData.referralCode, deviceId, registerData.socialToken, registerData.cpf)
            setLoading(true)
          }
        } else {
          setMessage(resMessage)
          setModalMessage(true)
          setLoading(false)
        }
      }
      return () => {
        previousProps.otpVerified = otpVerified
      }
    }, [otpVerified])

    useEffect(() => { // handle response
      if (previousProps.resMessage !== resMessage) {
        if (resMessage) {
          if (messageType) {
            setMessage('')
            setModalMessage(false)
            setLoading(false)
          } else {
            setMessage(resMessage)
            if(!resStatus) {
              setModalMessage(true)
              // setLoading(false)
            }
          }
        }
      }
      return () => {
        previousProps.resMessage = resMessage
      }
    }, [resStatus, resMessage])

    useEffect(() => {
      if (modalMessage) {
        setTimeout(() => {
          setModalMessage(false)
          setLoading(false)
        }, 3000)
      }
    }, [modalMessage])

    // send otp
    function SendOTPFun(mobileNumber, Auth) {
      dispatch(sendOTP(mobileNumber, 'M', Auth))
      setSendedOTP(true)
      setLoading(true)
    }
    // verify otp
    function VerifyOTPFun(mobileNumber, Auth, sCode, data) {
      setRegisterData(data)
      const deviceId = storage.getItem('ID')
      dispatch(VerifyOTP(mobileNumber, 'M', Auth, sCode, deviceId, FirebaseToken))
      setLoading(true)
    }
    // Register
    function Registrationfun(Platform, userName, name, email, mobileNumber, Password, sCode, referralCode, loginID, socialToken, cpf) {
      dispatch(Registration(FirebaseToken, Platform, userName, name, email, mobileNumber, Password, sCode, referralCode, loginID, socialToken, cpf))
      setLoading(true)
    }
    function CheckExistFunc(sEmail, sUsername, sMobNum ) { // check exist
      // if (type && value && value.length > 0)
       {
        dispatch(CheckExist(sEmail, sUsername, sMobNum ))
      }
    }

    function validateCpfFunc (CPF) {
      dispatch(validateCpf(CPF))
    }
  
    return (
      <Component
        {...props}
        resStatus={resStatus}
        resMessage={resMessage}
        messageType={messageType}
        registerSuccess={registerSuccess}
        otpVerified={otpVerified}
        sendOtp={sendOtp}
        CheckExistFunc={CheckExistFunc}
        Registration={Registrationfun}
        SendOTP={SendOTPFun}
        sendedOTP={sendedOTP}
        modalMessage={modalMessage}
        message={message}
        loading={loading}
        setLoading={setLoading}
        setModalMessage={setModalMessage}
        VerifyOTP={VerifyOTPFun}
        setMessage={setMessage}
        validateCpfFunc={validateCpfFunc}
        userInfo={userInfo}
      />
    )
  }
}

export default RegisterPage
