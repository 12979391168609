import React from 'react'
import { FormattedMessage } from 'react-intl'
import UserHeader from '../../User/components/UserHeader'
import NotificationPage from './Notification'
function Notification (props) {
  return (
    <>
      <UserHeader title={<FormattedMessage id='Notifications' />} {...props}/>
      <NotificationPage {...props} fetchList/>
    </>
  )
}
export default Notification
