import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import { Table } from 'reactstrap'

function SkeletonGameLeaderBoard (props) {
  const { length } = props
  return (
    <div className="player-cat bg-white">
      <div className="player-header d-flex justify-content-between bg-white">
        <span>
          <Skeleton height={10} width={150} />
        </span>
        <span>
          <Skeleton height={10} width={150} />
        </span>
      </div>
      <Table className="bg-white player-list m-0">
        <Fragment>
          {Array(length || 3)
            .fill()
            .map((index, i) => (
              <tbody key={i}>
                <tr>
                  <td>
                    <div className="p-img">
                      <Skeleton circle={true} height={50} width={50} />
                    </div>
                  </td>
                  <td>
                    <h4 className="p-name">
                      <Skeleton height={10} width={150} />
                    </h4>
                    <p className="c-name">
                      <Skeleton height={10} width={150} />
                    </p>
                  </td>
                  <td className='align_right'>
                    <b>
                      <Skeleton height={10} width={150} /></b>
                  </td>
                </tr>
              </tbody>
            ))}
        </Fragment>
      </Table>
    </div>
  )
}

SkeletonGameLeaderBoard.propTypes = {
  leagueDetails: PropTypes.bool,
  length: PropTypes.number
}

export default SkeletonGameLeaderBoard
