import React from 'react'
import PlayerLeagueInfo from './ViewPlayerLeagueInfo'
import PropTypes from 'prop-types'

function PlayerLeagueInfoIndex (props) {
  const { match } = props

  return (
    <>
      <PlayerLeagueInfo {...props} pId={match?.params?.playerId} isSeasonPoint />
    </>
  )
}

PlayerLeagueInfoIndex.propTypes = {
  match: PropTypes.object
}

export default PlayerLeagueInfoIndex
