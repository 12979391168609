import React from 'react'
import LoginHeader from '../components/loginHeader'
import ConfirmPasswordForm from './ConfirmPassword'
function ConfirmPassword (props) {
  return (
    <div className="auth-box">
      <LoginHeader backURL='/sign-up' {...props} />
      <ConfirmPasswordForm {...props} />
    </div>
  )
}

export default ConfirmPassword
