import React, { useEffect, useState, useRef, Fragment } from 'react'
import { Form, FormGroup, Label, Input, Alert, Button } from 'reactstrap'
import UserDetailsChange from 'Common/src/components/User/UserDetailsChange'
import { isNumber, verifyEmail, verifyLength, verifyMobileNumber } from '../../../helper'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import Loading from '../../../component/Loading'
const classNames = require('classnames')
function ChangeProfileDetails (props) {
  const { resMessage, isCheckExist, setSendedOTP, updateProfile, messageType, loading, modalMessage, userInfo, onSendOTP, isChecked, onVerifyOTP, otpVerified, sendOtp, match } = props
  const [otp, setOtp] = useState('')
  const [errOtp, setErrOtp] = useState('')
  const [email, setEmail] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [errEmail, setErrEmail] = useState('')
  const [durationTime, setDurationTime] = useState('')
  const [intervalRef, setIntervalRef] = useState(null)
  const [errNumber, setErrNumber] = useState('')
  const [sendedOtp, setSendedOtp] = useState(false)
  const previousProps = useRef({ otpVerified, isChecked }).current

  useEffect(() => {
    setErrEmail('')
    setErrNumber('')
  }, [])

  useEffect(() => { // handle the response
    if (userInfo) {
      setEmail(userInfo.sEmail)
      setMobileNumber(userInfo.sMobNum)
    }
  }, [userInfo])

  useEffect(() => { // handle the response
    if (sendedOtp) {
      changeClock()
      setTimeout(() => {
        setSendedOtp(false)
      }, 30000)
    }
  }, [sendedOtp])

  useEffect(() => { // handle the response
    if (previousProps.otpVerified !== otpVerified) {
      if (otpVerified) {
        updateProfile('w', 'data', { sEmail: email, sMobNum: mobileNumber })
        props.history.push('/profile-info', { message: resMessage })
        setSendedOTP(false)
      }
    }
    return () => {
      previousProps.otpVerified = otpVerified
    }
  }, [otpVerified])

  useEffect(() => { // handle the response
    if (previousProps.isChecked !== isChecked) {
      if (isChecked && !sendedOtp) {
        onSendOTP(match && match.params && match.params.type === 'Email' ? email : match && match.params && match.params.type === 'Mobile' && mobileNumber, match && match.params && match.params.type === 'Email' ? 'E' : match && match.params && match.params.type === 'Mobile' && 'M', 'V')
        setSendedOtp(true)
      }
    }
    return () => {
      previousProps.isChecked = isChecked
    }
  }, [isChecked])

  useEffect(() => { // handle the response
    if (messageType === 'M') {
      setErrNumber(<FormattedMessage id="Common_mobileError" />)
    }
    if (messageType === 'E') {
      setErrEmail(<FormattedMessage id="Common_emailError" />)
    }
  }, [messageType])

  // set the values
  function handleChange (event, type) {
    switch (type) {
      case 'OTP':
        if (!isNaN(event.target.value)) {
          if (event.target.value.length === 4) {
            setErrOtp('')
          } else {
            if (event.target.value.length !== 4) {
              setErrOtp(<FormattedMessage id="Otp_Length_Number" />)
            } else {
              setErrOtp(<FormattedMessage id="Common_NumberOTP" />)
            }
          }
          setOtp(event.target.value)
        }
        break
      case 'Email':
        if (verifyLength(event.target.value, 1) && verifyEmail(event.target.value)) {
          setErrEmail('')
        } else {
          if (event.target.value === '') {
            setErrEmail(<FormattedMessage id="Required_Email" />)
          } else {
            setErrEmail(<FormattedMessage id="Common_EmailError" />)
          }
        }
        setEmail(event.target.value)
        break
      case 'PhoneNumber':
        if (isNumber(event.target.value) || !event.target.value) {
          if (event.target.value && verifyMobileNumber(event.target.value)) {
            setErrNumber('')
          } else {
            setErrNumber(<FormattedMessage id="Required_Mobile" />)
          }
          setMobileNumber(event.target.value)
        }
        break
      default:
        break
    }
  }

  // check the validation apis
  function BlurFunction (value, type) {
    switch (type) {
      case 'Email':
        if (verifyLength(value, 1) && verifyEmail(value) && userInfo && (userInfo.sEmail !== value)) {
          isCheckExist('E', value)
        }
        break
      case 'PhoneNumber':
        if (verifyMobileNumber(value) && userInfo && (userInfo.sMobNum !== value)) {
          isCheckExist('M', value)
        }
        break
      default:
        break
    }
  }

  const handleSubmit = (e) => { // Check is exist or not
    e.preventDefault()
    if (match && match.params && match.params.type === 'Email') {
      isCheckExist('E', email)
    } else {
      isCheckExist('M', mobileNumber)
    }
  }

  const VerifiedOTP = (e) => {
    e.preventDefault()
    onVerifyOTP(match && match.params && match.params.type === 'Email' ? email : match && match.params && match.params.type === 'Mobile' && mobileNumber, match && match.params && match.params.type === 'Email' ? 'E' : match && match.params && match.params.type === 'Mobile' && 'M', 'V', otp)
  }

  const changeClock = () => {
    let duration = 30
    if (duration > 0) {
      setIntervalRef(
        setInterval(() => {
          if (duration >= 0) {
            setDurationTime(duration)
            duration = duration - 1
          }
        }, 1000)
      )
    }
    return () => {
      clearInterval(intervalRef)
    }
  }
  return (<>
    {loading && <Loading />}
    {modalMessage ? <Alert isOpen={modalMessage} color="primary">{resMessage}</Alert> : ''}
    <div className="user-container bg-white">
      <Form className="form sign-up pb-0">
        {
          match && match.params && match.params.type === 'Email'
            ? <Fragment>
              <FormGroup className="c-input">
                <Input className={classNames('bg-white', { 'hash-contain': email, error: errEmail })} autoComplete='off' name="sEmail" type="email" id="Email" value={email} onChange={(e) => { handleChange(e, 'Email') }} onBlur={(e) => { BlurFunction(e, 'Email') }}/>
                <Label className="no-change label m-0" for="Email"><FormattedMessage id="profileInfo_Email" /></Label>
                <p className="error-text">{errEmail}</p>
              </FormGroup>
            </Fragment>
            : <Fragment>
              <FormGroup className="c-input">
                <Input maxLength={10} className={classNames('bg-white', 'hidden-border', { 'hash-contain': mobileNumber, error: errNumber })} autoComplete='off' name="sMobNum" type="text" id="MobileNumber" value={mobileNumber} onChange={(e) => { handleChange(e, 'PhoneNumber') }} onBlur={(e) => { BlurFunction(e, 'PhoneNumber') }}/>
                <Label className="no-change label m-0" for="MobileNumber"><FormattedMessage id="profileInfo_MobileNo" /></Label>
                <p className="error-text">{errNumber}</p>
              </FormGroup>
            </Fragment>
        }
        {sendOtp && !otpVerified && <Fragment>
          <FormGroup className="c-input">
            <Input maxLength={4} autoFocus autoComplete='off' id="otp" value={otp} className={classNames({ 'hash-contain': otp, error: errOtp }) } disabled={otpVerified} onChange={(e) => { handleChange(e, 'OTP') }} />
            <p className="error-text">{errOtp}</p>
            <Label className="label m-0" for="otp"><FormattedMessage id="Login_Otp" /></Label>
            <div className="text-right ">
              <Button color="link" onClick={handleSubmit} disabled={!!sendedOtp} className="forgot-link bluecolor"> <FormattedMessage id="Login_ResendOTP" /> {(durationTime > 0) ? <span><FormattedMessage id="Change_IN" /> {`${durationTime}`} <FormattedMessage id="Change_SEC" /></span> : ''}</Button>
            </div>
          </FormGroup>
          <Button color="primary" block disabled={!otp || errOtp} hidden={otpVerified} onClick={VerifiedOTP}><FormattedMessage id="SignUp_VerifyOtp" /></Button>
        </Fragment>
        }
        {
          <div className="f-bottom w-padding text-center">
            <Button color="primary-two" type="submit" block
              hidden={sendOtp && !otpVerified}
              disabled={errEmail || errNumber}
              onClick={handleSubmit}
            >
              <FormattedMessage id="SignUp_GetOtp" />
            </Button>
          </div>
        }
      </Form>
    </div>
  </>)
}
ChangeProfileDetails.propTypes = {
  UpdateProfile: PropTypes.func,
  resMessage: PropTypes.string,
  token: PropTypes.string,
  resStatus: PropTypes.bool,
  userInfo: PropTypes.shape({
    sEmail: PropTypes.string,
    sMobNum: PropTypes.string
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string
    })
  }),
  loading: PropTypes.bool,
  sendOtp: PropTypes.bool,
  otpVerified: PropTypes.bool,
  isChecked: PropTypes.bool,
  modalMessage: PropTypes.func,
  getUserProfile: PropTypes.func,
  updateProfile: PropTypes.func,
  setLoading: PropTypes.func,
  onSendOTP: PropTypes.func,
  isCheckExist: PropTypes.func,
  messageType: PropTypes.string,
  onVerifyOTP: PropTypes.func,
  setSendedOTP: PropTypes.func
}
export default UserDetailsChange(ChangeProfileDetails)
