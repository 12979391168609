import React, { lazy, Suspense } from 'react'
import UserHeader from '../../User/components/UserHeader'
import Loading from '../../../component/Loading'
import { FormattedMessage } from 'react-intl'
const OfferDetailPage = lazy(() => import('./OfferDetail'))

function OfferDetail (props) {
  return (
    <>
      <UserHeader {...props} title={<FormattedMessage id='OFFER_TITLE' />} backURL='/offers'/>
      <Suspense fallback={<Loading />}>
        <OfferDetailPage {...props} />
      </Suspense>
    </>
  )
}

export default OfferDetail
