import ALLR from '../src/assests/images/ALLR.jpg'
import BATS from '../src/assests/images/BATS.jpg'
import BWL from '../src/assests/images/BWL.jpg'
import WK from '../src/assests/images/WK.jpg'
import DEF from '../src/assests/images/DEF.jpg'
import FWD from '../src/assests/images/FWD.jpg'
import GK from '../src/assests/images/GK.jpg'
import MID from '../src/assests/images/MID.jpg'
import PlayerImage from '../src/assests/images/PlayerImage.png'

export function verifyCPF (value) {
  const mobRex = /^[0-9]{12}$/
  if (!mobRex.test(value)) {
    return true
  }
  return false
}

export function verifyEmail (value) {
  const emailRex = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (emailRex.test(value)) {
    return true
  }
  return false
}

export function verifyPassword (value) {
  const passwordRex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
  if (passwordRex.test(value)) {
    return true
  }
  return false
}

export function withoutSpace (value) {
  const SpacelessRex = /\s/g
  if (SpacelessRex.test(value)) {
    return true
  }
  return false
}

export function verifySpecialCharacter (value) {
  // const regex = /^[0-9a-zA-Z]+$/
  const regex = /^\d*[a-zA-Z][a-zA-Z0-9]*$/
  if (regex.test(value)) {
    return true
  }
  return false
}

export function verifySpecCharWithSpace (value) {
  const regex = /^[A-Za-z0-9 ]+$/
  if (regex.test(value)) {
    return true
  }
  return false
}

export function verifyOnlyAlphabets (value) {
  const regex = /^[A-Za-z ]+$/
  if (regex.test(value)) {
    return true
  }
  return false
}

export function verifyLength (value, length) {
  if (value?.length >= length) {
    return true
  }
  return false
}

export function verifyMobileNumber (value) {
  const mobRex = /^[0-9]{10,11}$/
  if (mobRex.test(value)) {
    return true
  }
  return false
}

export function maxValue (value1, value2) {
  if (value1 > value2) {
    return value1
  } else {
    return value2
  }
}

export function isNumber (value) {
  const numRex = /^[0-9\b]+$/
  if (numRex.test(value)) {
    return true
  }
  return false
}

export function isPositive (value) {
  if (value > 0) {
    return true
  }
  return false
}

export function isUpperCase (value) {
  if (value === value.toUpperCase()) {
    return true
  }
  return false
}

export function panCardNumber (value) {
  const panRex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
  if (!panRex.test(value)) {
    return true
  }
  return false
}
export function ifscCode (value) {
  const ifscRex = /^[A-Z]{4}0[A-Z0-9]{6}$/
  if (!ifscRex.test(value)) {
    return true
  }
  return false
}
export function validPinCode (value) {
  const ifscRex = /^[1-9]{1}[0-9]{5}$/
  if (!ifscRex.test(value)) {
    return true
  }
  return false
}
export function cricketStaticImage (Role) {
  if (Role === 'WK') {
    return WK
  } else if (Role === 'ALLR') {
    return ALLR
  } else if (Role === 'BATS') {
    return BATS
  } else if (Role === 'BWL') {
    return BWL
  } else {
    return PlayerImage
  }
}

export function footballStaticImage (Role) {
  if (Role === 'MID') {
    return MID
  } else if (Role === 'GK') {
    return GK
  } else if (Role === 'FWD') {
    return FWD
  } else if (Role === 'DEF') {
    return DEF
  } else {
    return PlayerImage
  }
}

export function allSportsRoles (Role) {
  // cricket sports
  if (Role === 'WK') {
    return 'Wicket Keeper'
  }
  if (Role === 'ALLR') {
    return 'All Rounders'
  }
  if (Role === 'BATS') {
    return 'Batsmen'
  }
  if (Role === 'BWL') {
    return 'Bowlers'
  }
  // Football sports
  if (Role === 'GK') {
    return 'Goleiros'
  }
  if (Role === 'DEF') {
    return 'Zagueiros'
  }
  if (Role === 'MID') {
    return 'MEIO-CAMPISTAS'
  }
  if (Role === 'FWD') {
    return 'Atacantes'
  }
  // Basketball sports
  if (Role === 'C') {
    return 'Center'
  }
  if (Role === 'PF') {
    return 'Power Forward'
  }
  if (Role === 'PG') {
    return 'Point Guard'
  }
  if (Role === 'SF') {
    return 'Small Forward'
  }
  if (Role === 'SG') {
    return 'Shooting Guard'
  }
  // Baseball sports
  if (Role === 'P') {
    return 'Pitcher'
  }
  if (Role === 'IF') {
    return 'Infielders'
  }
  if (Role === 'CT') {
    return 'Catcher'
  }
  if (Role === 'OF') {
    return 'Outfielders'
  }
  // Kabaddi sports
  if (Role === 'RAID') {
    return 'Raiders'
  }
  // if (Role === 'DEF') {
  //   return 'Defenders'
  // }
  // if (Role === 'ALLR') {
  //   return 'All Rounders'
  // }
}

export function rolesShortForm (Role) {
  // Football sports
  if (Role === 'GK') {
    return 'GOL'
  }
  if (Role === 'DEF') {
    return 'ZAG'
  }
  if (Role === 'MID') {
    return 'MEI'
  }
  if (Role === 'FWD') {
    return 'ATA'
  }
}

export function roleFullForm (Role) {
  if (Role === 'GK') {
    return 'Goleiro'
  }
  if (Role === 'DEF') {
    return 'Zagueiros'
  }
  if (Role === 'MID') {
    return 'Meio-campistas'
  }
  if (Role === 'FWD') {
    return 'Atacantes'
  }
}

export function blockInvalidChar (e) {
  if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 8) {
    e.preventDefault()
    return false
  }
}

export function handleInputValue (value) {
  const phoneRegex = /^\(?([0-9]{2})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{3,4})$/
  return (
    value.replace(phoneRegex, '$1 $2-$3')
  )
}

export function currencyConverter (value) {
  return new Intl.NumberFormat('pt-BR').format(value)
}

export function convertFloat (num) {
  if (num) {
    if (typeof num === 'number' && !Number.isNaN(num) && !Number.isInteger(num)) {
      return num.toFixed(2)
    } else {
      return num
    }
  } else {
    return 0
  }
}
