import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import UserHeader from '../../../User/components/UserHeader'
import Loading from '../../../../component/Loading'
import { FormattedMessage } from 'react-intl'
import qs from 'query-string'
const PrizeBreakupsPage = lazy(() => import('./PrizeBreakups'))

function PrizeBreakups (props) {
  const { match, location } = props
  const obj = qs.parse(location.search)

  return (
    <>
      <UserHeader {...props} title={<FormattedMessage id='Winning_Breakup'/>} backURL={obj.homePage ? `/create-contest/${match.params.sportsType}/${match.params.id}?homePage=yes` : `/create-contest/${match.params.sportsType}/${match.params.id}`} />
      <Suspense fallback={<Loading />}>
        <PrizeBreakupsPage {...props}/>
      </Suspense>
    </>
  )
}

PrizeBreakups.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      sportsType: PropTypes.string,
      id: PropTypes.string
    })
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  })
}

export default PrizeBreakups
