import React from 'react'
import UserHeader from '../components/UserHeader'
import VerifiedPage from './VerifiedPage'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
function Verified (props) {
  const { match } = props
  return (
    <>
      <UserHeader {...props} title={match.path === '/verification/Email' ? <FormattedMessage id='Verify_Email' /> : <FormattedMessage id='Verify_Mobile' />}/>
      <VerifiedPage {...props}/>
    </>
  )
}
Verified.propTypes = {
  match: PropTypes.object
}

export default Verified
