/* eslint-disable multiline-ternary */
import React, { useState, Fragment, lazy, useRef, Suspense, useEffect } from 'react'
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Scorecard from '../components/Scorecard'
import ScoreCards from '../components/ScoreCards'
import PlayerInfo2 from '../components/PlayerInfo2'
import SkeletonTeam from '../../../component/SkeletonTeam'
import SkeletonLeague from '../../../component/SkeletonLeague'
import Loading from '../../../component/Loading'
import { currencyConverter, isUpperCase } from '../../../helper'
import LeagueCompletedComponent from 'Common/src/components/SportsLeagueList/LeagueCompleted'
import Trophy from '../../../assests/images/trophy.svg'
import TournamentScore from '../components/TournamentScore'
// import arrow from '../../../assests/images/right-arrow-yellow.svg'
// import { Link } from 'react-router-dom'
const PlayerStats = lazy(() => import('../components/PlayerStats'))
const League = lazy(() => import('../components/League'))
const MyTeam = lazy(() => import('../components/MyTeam'))
const classNames = require('classnames')

function LeaguesCompletedPage (props) {
  const { history, setActiveState, match, joinedLoading, matchDetails, loadingScorecard, currencyLogo, teamList, joinedLeagueList, matchPlayerList, playerScorePoints, loading, participate, activeTab, toggle, uniquePlayerList, url, userJoinLeaguesFunc, playerRoles } = props
  const [playerId, setPlayerId] = useState('')
  const [matchType, setMatchType] = useState('')
  const [playerInfo, setPlayerInfo] = useState(false)
  const [finalData, setFinalData] = useState(false)
  const [winCount, setWinCount] = useState([])
  const [showTabs, setShowTabs] = useState(false)
  const previousProps = useRef({
    joinedLeagueList
  }).current

  useEffect(() => {
    setWinCount([]) // handle the response
    if (match?.params?.sportsType) {
      const sport = match.params.sportsType
      if (isUpperCase(sport)) {
        if (match.path === '/completedleagues/participated/:id/:sportsType') {
          setMatchType('completed')
          history.push(`/completedleagues/participated/${match.params.id}/${sport.toLowerCase()}`)
        } else {
          setMatchType('live')
          history.push(`/liveleague/${match.params.id}/${sport.toLowerCase()}`)
        }
      }
    }
  }, [])

  useEffect(() => { // handle the response
    if (previousProps.joinedLeagueList !== joinedLeagueList) {
      if (joinedLeagueList && joinedLeagueList.length > 0) {
        const count = joinedLeagueList && joinedLeagueList.filter(data => data.bWinningZone)
        setWinCount(count)
        count && count.length > 0 && count.map(data => {
          let finaldata1 = 0
          finaldata1 = finaldata1 + data.nTotalWinningInContest
          return setFinalData(finaldata1)
        })
      }
    }
    return () => {
      previousProps.joinedLeagueList = joinedLeagueList
    }
  }, [joinedLeagueList])

  const scoreHeight = history?.location?.pathname?.includes('tournament') ? document.querySelector('.tournament-score') : document.querySelector('.scorecard')
  const scoreDivHeight = scoreHeight?.clientHeight

  return (
    <>
      {/* {matchDetails && matchDetails.bScorecardShow
        ? <Fragment>
          <Link to={`/score-card/${match.params.id}`}><h4 className='scorecard-title d-flex justify-content-center'><FormattedMessage id="ScoreCard" /><img src={arrow} /></h4></Link>
        </Fragment> : <h4 className='score-card-title'><FormattedMessage id="ScoreCard" /></h4>
        } */}
      {history?.location?.pathname?.includes('/tournament') && matchDetails?.aMatch?.length > 0
        ? (
          <TournamentScore
            {...props}
            matchDetails={matchDetails}
            setShowTabs={setShowTabs}
            showTabs={showTabs}
            url={url}
            userJoinLeaguesFunc={userJoinLeaguesFunc}
          />
          )
        : null}
      {['tournament', 'liveleague'].every(d => !history?.location?.pathname?.includes(d)) &&
        <Scorecard loadingScorecard={loadingScorecard} matchDetails={matchDetails} matchSport={matchDetails?.eCategory} url={url} />}
      {!showTabs && (
      <Nav className="live-tabs justify-content-between bg-white b-bottom flex-nowrap text-nowrap">
        <NavItem className={`text-center ${matchDetails?.bScorecardShow ? 'scorecards' : ''}`}>
          <NavLink className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              setActiveState('1')
              toggle('1')
            }}
          >
            <FormattedMessage id="League_myContest" />

          </NavLink>
        </NavItem>
        <NavItem className={`text-center ${matchDetails?.bScorecardShow ? 'scorecards' : ''}`}>
          <NavLink className={classnames({ active: activeTab === '2' })}
            onClick={() => {
              setActiveState('2')
              toggle('2')
            }}
          >
            <FormattedMessage id="League_myTeams" />

          </NavLink>
        </NavItem>
        <NavItem className={`text-center ${matchDetails?.bScorecardShow ? 'scorecards' : ''}`}>
          <NavLink className={classnames({ active: activeTab === '3' })}
            onClick={() => {
              setActiveState('3')
              toggle('3')
            }}
          >
            <FormattedMessage id="League_playerStats" />

          </NavLink>
        </NavItem>
        {/* {
          matchDetails && matchDetails.bScorecardShow && (
            <NavItem className={`text-center ${matchDetails?.bScorecardShow ? 'scorecards' : ''}`}>
              <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => {
                setActiveState('4')
                toggle('4')
              }} ><FormattedMessage id="League_scorecard" /></NavLink>
            </NavItem>
          )
        } */}
      </Nav>
      )}
      {
        winCount && winCount.length && finalData && matchDetails && matchDetails.eStatus === 'CMP'
          ? (
            <div>
              <div className="mt-footer win-balance d-flex align-items-center justify-content-around">
                <span className='greenText'>
                  {' '}
                  <b>
                    {' '}
                    <FormattedMessage id="Congrats_you_win" />
                    {' '}
                    {winCount && winCount.length}
                    {' '}
                    <FormattedMessage id="Win_contest" />
                    {' '}
                  </b>
                  <br/>
                  <b className='blackText'>
                    {' '}
                    <img src={Trophy} />
                    {' '}
                    {currencyLogo}
                    {currencyConverter(finalData) || '-'}
                    {' '}
                  </b>
                </span>
              </div>
            </div>
            )
          : ' '
      }
      {
        !playerInfo && (
        <div className={classNames(
          'league-container',
          { 'league-completed-tournament-score-card': (history?.location?.pathname?.includes('tournament') || history?.location?.pathname?.includes('liveleague')) && showTabs },
          { 'league-completed-tournament': (history?.location?.pathname?.includes('tournament') || history?.location?.pathname?.includes('liveleague')) && !showTabs },
          { 'league-completed-completed-league': history?.location?.pathname?.includes('completedleagues') && matchDetails && matchDetails.eStatus === 'CMP' },
          { 'league-completed': (!props.showScored) },
          { 'shaw-won': (winCount && winCount.length && finalData && matchDetails && matchDetails.eStatus === 'CMP') }
        )}
          style={{
            height: (history?.location?.pathname?.includes('tournament') && !showTabs)
              ? `calc(100% - ${scoreDivHeight}px - 96px)`
              : (history?.location?.pathname?.includes('tournament') && showTabs)
                  ? '0px'
                  : (history?.location?.pathname?.includes('liveleague') || history?.location?.pathname?.includes('completedleagues')) && matchDetails?.eType === 'MATCH'
                      ? `calc(100% - ${scoreDivHeight}px - 93px)`
                      : 'calc(100% - 96px)'
          }}
        >
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {loading || joinedLoading
                ? <SkeletonLeague leagueDetails length={4} />
                : (
                  <Card className="leagues-card border-0 bg-transparent">
                    {joinedLeagueList && joinedLeagueList.length > 0
                      ? joinedLeagueList.map(LeagueData => {
                        return (
                          <Fragment key={`${LeagueData._id}`}>
                            <Suspense fallback={<Loading />}>
                              <League {...props} key={LeagueData._id} data={LeagueData} matchType='completed' participated={participate} />
                            </Suspense>
                          </Fragment>
                        )
                      }
                      )
                      : (
                        <div className="text-center">
                          <h3 className='mt-5'>
                            <FormattedMessage id="No_Contest" />
                          </h3>
                        </div>
                        )
                    }
                  </Card>
                  )}
            </TabPane>
            <TabPane tabId="2">
              {loading
                ? <SkeletonTeam length={3} />
                : teamList && teamList.length > 0
                  ? teamList.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map((teamDetailsData, index) => {
                    return (
                      <Suspense key={teamDetailsData._id} fallback={<Loading />}>
                        <MyTeam matchType={matchType} {...props} key={teamDetailsData._id} allTeams={teamList} index={index} name="Cricket" onPreviewTeam playerScorePoints={(id) => playerScorePoints(id)} points={true} teamDetails={teamDetailsData} />
                      </Suspense>
                    )
                  })
                  : (
                    <div className="text-center">
                      <h3 className='mt-5'>
                        <FormattedMessage id="No_Team" />
                      </h3>
                    </div>
                    )
              }
            </TabPane>
            <TabPane tabId="3">
              <Suspense fallback={<Loading />}>
                <PlayerStats {...props}
                  completed
                  matchDetails={matchDetails}
                  matchPlayerList={matchPlayerList}
                  playerRoles={playerRoles}
                  uniqueList={uniquePlayerList}
                />
              </Suspense>
            </TabPane>
            <TabPane tabId="4">
              <Suspense fallback={<Loading />}>
                <ScoreCards {...props} />
              </Suspense>
            </TabPane>
          </TabContent>
        </div>
        )
      }
      {playerInfo
        ? (
          <Fragment>
            <PlayerInfo2 {...props}
              matchPlayerList={matchPlayerList}
              onBackClick={() => setPlayerInfo(false)}
              pId={playerId}
              setPlayerId={setPlayerId}
            />
          </Fragment>
          ) : '' }
    </>
  )
}

LeaguesCompletedPage.propTypes = {
  matchDetails: PropTypes.object,
  teamList: PropTypes.array,
  joinedLeagueList: PropTypes.array,
  matchPlayerList: PropTypes.array,
  uniquePlayerList: PropTypes.array,
  playerScorePoints: PropTypes.func,
  showScored: PropTypes.bool,
  loading: PropTypes.bool,
  participate: PropTypes.bool,
  joinedLoading: PropTypes.bool,
  loadingScorecard: PropTypes.bool,
  activeTab: PropTypes.string,
  toggle: PropTypes.func,
  onUniquePlayers: PropTypes.func,
  setActiveState: PropTypes.func,
  currencyLogo: PropTypes.string,
  match: PropTypes.shape({
    url: PropTypes.string,
    path: PropTypes.string,
    params: PropTypes.object
  }),
  history: PropTypes.shape({
    push: PropTypes.object,
    location: PropTypes.object
  }),
  url: PropTypes.string,
  userJoinLeaguesFunc: PropTypes.func,
  playerRoles: PropTypes.array
}

export default LeagueCompletedComponent(LeaguesCompletedPage)
