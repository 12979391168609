import React from 'react'
import PropTypes from 'prop-types'
import { Button, Card, CardBody, CardFooter, CardHeader, Table } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import UserHome from 'Common/src/components/User/UserHome'
import { currencyConverter } from '../helper'

function PaymentSlide (props) {
  const { setPaymentSlide, currencyLogo, userInfo } = props
  return (
    <>
      <div className="s-team-bg" onClick={() => setPaymentSlide(false)}></div>
      <Card className='filter-card' >
        <CardHeader className='d-flex align-items-center justify-content-between'>
          <button onClick={() => { setPaymentSlide(false) }}><FormattedMessage id='Wallet_Details' /></button>
          <button onClick={() => setPaymentSlide(false)}><FormattedMessage id='League_Close' /></button>
        </CardHeader>
        <CardBody className='payment-box'>

        <Table className="m-0 bg-white payment">
          <thead>
            <tr className='text-center'> <th colSpan='2'><FormattedMessage id="PROFILE_TOTAL_BALANCE" /></th> </tr>
            <tr className='text-center'> <th colSpan='2'>{currencyLogo}{userInfo?.nCurrentTotalBalance ? currencyConverter(userInfo.nCurrentTotalBalance) : 0} </th> </tr>
          </thead>
          <tbody>
            <tr>
              <td><FormattedMessage id="PROFILE_DEP_BALANCE" /></td>
              <td className='rightAlign'>{currencyLogo}{userInfo && userInfo.nCurrentDepositBalance ? currencyConverter(userInfo.nCurrentDepositBalance) : 0}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="PROFILE_WIN_BALANCE" /></td>
              <td className='rightAlign'>{currencyLogo}{userInfo && userInfo.nCurrentWinningBalance ? currencyConverter(userInfo.nCurrentWinningBalance) : 0}</td>
            </tr>
            <tr>
              <td><FormattedMessage id="PROFILE_CASH_BONUS" /></td>
              <td className='rightAlign'>{currencyLogo}{userInfo && userInfo.nCurrentBonus ? currencyConverter(userInfo.nCurrentBonus) : 0}</td>
            </tr>
          </tbody>
        </Table>
        </CardBody>
        <CardFooter className='border-0 bg-white p-0'>
          <Button color='primary-two' onClick={() => history.push('/deposit')} className='w-100'><FormattedMessage id="PROFILE_ADD_CASH" /></Button>
        </CardFooter>
      </Card>
    </>
  )
}

PaymentSlide.propTypes = {
  setPaymentSlide: PropTypes.func,
  history: PropTypes.object,
  currencyLogo: PropTypes.string,
  userInfo: PropTypes.object
}

export default UserHome(PaymentSlide)
