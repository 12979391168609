import React from 'react'
import UserHeader from '../components/UserHeader'
import ChangeProfileDetails from './ChangeProfileDetails'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
function ChangeProfile (props) {
  const { match } = props

  return (
    <>
      <UserHeader {...props} backURL='/profile-info' title={match.params.type === 'Email' ? <FormattedMessage id='Enter_New_Email' /> : match.params.type === 'Mobile' && <FormattedMessage id='Enter_New_Mobile' />}/>
      <ChangeProfileDetails {...props} Type={match.params.type}/>
    </>
  )
}
ChangeProfile.propTypes = {
  match: PropTypes.object
}

export default ChangeProfile
