import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { UncontrolledCollapse } from 'reactstrap'
import PropTypes from 'prop-types'
import { version } from '../../../../package.json'
import Loading from '../../../component/SkeletonMoreData'
import MorePage from '../../../../../common/src/components/More/MorePage'

function More (props) {
  const { data, loading, token } = props

  useEffect(() => {
    const element = document.getElementById('chat-widget-container')
    if (!element && token) {
      const script = document.createElement('script')
      script.innerHTML = `window.__lc = window.__lc || {};
      window.__lc.license = 15446526;
      ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))`
      script.async = true
      document.body.appendChild(script)
    } else if (element && token) {
      element.hidden = false
    }
    return () => {
      const e = document.getElementById('chat-widget-container')
      if (e) {
        e.async = true
        e.hidden = true
      }
    }
  }, [token])

  return (
    <>
      {(loading)
        ? <Loading />
        : (
          <div className="user-container">
            <ul className="p-links m-3">
            <li><Link to={props.match?.path === '/more/v1' ? '/point-system/v1' : '/point-system'} ><FormattedMessage id="More_PSystem" /><i className="icon-right"></i></Link></li>
              <li><Link to={props.match?.path === '/more/v1' ? '/offers/v1' : '/offers'} ><FormattedMessage id="More_offers" /><i className="icon-right"></i></Link></li>
              {
                data && data.length !== 0 && Object.entries(data).map(([key, value]) => {
                  return (
                    <Fragment key={value[0]._id}>
                      {
                        value && value.length !== 0 && value.length > 1
                          ? (
                            <li><button id={key} title={key}>{key}<i className="icon-right"></i></button>
                              <UncontrolledCollapse toggler={`#${key}`}>
                                <ul className="p-links">
                                  {
                                    value.map((data) => {
                                      return (
                                        <li key={data._id}><Link to={props.match?.path === '/more/v1' ? `/more/${data.sSlug}/v1` : `/more/${data.sSlug}`}>{data.sTitle}<i className="icon-right"></i></Link></li>
                                      )
                                    })
                                  }
                                </ul>
                              </UncontrolledCollapse>
                            </li>
                            )
                          : (
                            <li><Link to={props.match?.path === '/more/v1' ? `/more/${value[0].sSlug}/v1` : `/more/${value[0].sSlug}`}>{key}<i className="icon-right"></i></Link></li>
                            )
                      }
                    </Fragment>
                  )
                })
              }
              {token && <li><Link to="/complaints" ><FormattedMessage id="More_Feedback" /><i className="icon-right"></i></Link></li>}
            </ul>
            <p className='mt-3 moreVersion'><FormattedMessage id="Version" /> - ({version})</p>
          </div>
          )}
    </>
  )
}

More.propTypes = {
  data: PropTypes.shape(
    {
      length: PropTypes.number,
      _id: PropTypes.string,
      sSlug: PropTypes.string,
      sTitle: PropTypes.string
    }
  ),
  loading: PropTypes.bool,
  match: PropTypes.object,
  token: PropTypes.string
}

export default MorePage(More)
