import React, { Fragment, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { verifyPassword } from '../../../helper'
import { Button, Form, FormGroup, Input, Label, Alert } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import Loading from '../../../component/Loading'
import Profile from 'Common/src/components/Auth/Profile'
const classNames = require('classnames')

function ChangePasswordPage (props) {
  const { modalMessage, message, loading, ChangePassword, nChangedPassword, resMessage } = props
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errOldPassword, setErrOldPassword] = useState('')
  const [errNewPassword, setErrNewPassword] = useState('')
  const [errConfirmPassword, setErrConfirmPassword] = useState('')
  const previousProps = useRef({ nChangedPassword }).current

  useEffect(() => { // handle the response
    if (previousProps.nChangedPassword !== nChangedPassword) {
      if (nChangedPassword) {
        props.history.push('/profile', { message: resMessage })
      }
    }
    return () => {
      previousProps.nChangedPassword = nChangedPassword
    }
  }, [nChangedPassword])

  function handleChange (event, type) { // set the values
    switch (type) {
      case 'OLDPASSWORD':
        if (verifyPassword(event.target.value)) {
          setErrOldPassword('')
        } else {
          setErrOldPassword(<FormattedMessage id="Common_PassLength" />)
        }
        setOldPassword(event.target.value)
        break
      case 'NEWPASSWORD':
        if (verifyPassword(event.target.value)) {
          setErrNewPassword('')
        } else {
          setErrNewPassword(<FormattedMessage id="Common_PassLength" />)
        }
        setNewPassword(event.target.value)
        break
      case 'CONFIRMPASSWORD':
        if (event.target.value === newPassword) {
          setErrConfirmPassword('')
        } else {
          setErrConfirmPassword(<FormattedMessage id="Common_ConfirmPasswordNotFound" />)
        }
        setConfirmPassword(event.target.value)
        break
      default:
        break
    }
  }

  function ChangePasswordClick (e) { // change password
    e.preventDefault()
    if (verifyPassword(oldPassword) && verifyPassword(newPassword) && (newPassword === confirmPassword)) {
      ChangePassword(oldPassword, newPassword)
    } else {
      if (!(newPassword === confirmPassword)) {
        setErrConfirmPassword(<FormattedMessage id="Common_ConfirmPasswordNotFound" />)
      }
      if (!verifyPassword(oldPassword)) {
        setErrOldPassword(<FormattedMessage id="Common_ReqOld" />)
      }
      if (!verifyPassword(newPassword)) {
        setErrNewPassword(<FormattedMessage id="Common_ReqNew" />)
      }
      if (!verifyPassword(confirmPassword)) {
        setErrConfirmPassword(<FormattedMessage id="Common_ReqConfirm" />)
      }
    }
  }

  return (
    <>
      {
        modalMessage
          ? <Fragment>
            <Alert isOpen={modalMessage} color="primary">{message}</Alert>
          </Fragment>
          : ''
      }
      {loading && <Loading />}
      <div className="user-container bg-white no-footer">
      <Form className="form">
        <FormGroup className="c-input">
          <Input type="password" autoComplete='off' id="Old" className={classNames({ 'hash-contain': oldPassword })} onChange={(e) => { handleChange(e, 'OLDPASSWORD') }} />
          <Label className="label m-0" for="Old"><FormattedMessage id="Common_OldPassword" /></Label>
          <p className="error-text">{errOldPassword}</p>
        </FormGroup>
        <FormGroup className="c-input">
          <Input type="password" autoComplete='off' id="New" className={classNames({ 'hash-contain': newPassword })} onChange={(e) => { handleChange(e, 'NEWPASSWORD') }} />
          <Label className="label m-0" for="New"><FormattedMessage id="Common_NewPassword" /></Label>
          <p className="error-text">{errNewPassword}</p>
        </FormGroup>
        <FormGroup className="c-input">
          <Input type="password" autoComplete='off' id="Confirm" className={classNames({ 'hash-contain': confirmPassword })} onChange={(e) => { handleChange(e, 'CONFIRMPASSWORD') }} />
          <Label className="label m-0" for="Confirm"><FormattedMessage id="Common_ConfirmPassword" /></Label>
          <p className="error-text">{errConfirmPassword}</p>
        </FormGroup>
        <div className="btn-bottom text-center position-relative my-3">
          <Button color="primary" type="submit" className="w-100 d-block" onClick={ChangePasswordClick}><FormattedMessage id="Common_Submit" /></Button>
        </div>
      </Form>
    </div>
  </>
  )
}
ChangePasswordPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  ChangePassword: PropTypes.func,
  resMessage: PropTypes.string,
  resStatus: PropTypes.bool,
  nChangedPassword: PropTypes.bool,
  oldPassword: PropTypes.string,
  newPassword: PropTypes.string,
  confirmPassword: PropTypes.string,
  modalMessage: PropTypes.bool,
  message: PropTypes.string,
  errOldPassword: PropTypes.string,
  errNewPassword: PropTypes.string,
  errConfirmPassword: PropTypes.string,
  loading: PropTypes.bool,
  handleChange: PropTypes.func,
  ChangePasswordClick: PropTypes.func
}
export default Profile(ChangePasswordPage)
