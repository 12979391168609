import React from 'react'
import PropTypes from 'prop-types'
import HomeHeader from '../../Home/components/HomeHeader'
import HomeFooter from '../../Home/components/HomeFooter'
import MorePage from './More'
function More (props) {
  return (
    <>
      <HomeHeader active={true} />
      <MorePage {...props}/>
      <HomeFooter {...props} isPublic={props.match?.path === '/more/v1'}/>
    </>
  )
}

More.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string
  })
}

export default More
