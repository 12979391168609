import React from 'react'
import { FormattedMessage } from 'react-intl'
import UserHeader from '../components/UserHeader'
import TransactionsPage from './Transactions'
function Transactions (props) {
  return (
    <>
      <UserHeader {...props} title={<FormattedMessage id='User_transaction' />} backURL="/profile" />
      <TransactionsPage {...props}/>
    </>
  )
}

export default Transactions
