import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getLeadershipBoard } from '../../redux/actions/leaderBoard'

function Leadershipboard(Component) {
  return (props) => {
    const dispatch = useDispatch()
    const [url, setUrl] = useState('')
    const leadershipBoard = useSelector(state => state.leaderBoard.leadershipBoard)
    const token = useSelector(state => state.auth.token)
    const getUrlLink = useSelector(state => state.url.getUrl)
    const [loading, setLoading] = useState(false)
    const previousProps = useRef({ leadershipBoard }).current

    useEffect(() => {
      getLeadershipList()
    }, [token])

    // handle the response
    useEffect(() => {
      if (getUrlLink && token) {
        setUrl(getUrlLink)
      }
    }, [getUrlLink])
    
    // call the leadership list
    function getLeadershipList() {
      if(token) {
        dispatch(getLeadershipBoard(token))
        setLoading(true)
      }
    }
    useEffect(() => { // handle the response
      if (previousProps.leadershipBoard !== leadershipBoard) {
        setLoading(false)
      }
      return () => {
        previousProps.leadershipBoard = leadershipBoard
      }
    }, [leadershipBoard])

    return(
      <Component
        {...props}
        url={url}
        loading={loading}
        leadershipBoardList={leadershipBoard}
        getLeadershipList={getLeadershipList}
      />
    )
  }
}

export default Leadershipboard


