import UserDeposit from 'Common/src/components/User/UserDeposit'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Alert, Button, Input, Modal, ModalBody } from 'reactstrap'
import copy from '../../../../assests/images/copy_img.svg'
import success from '../../../../assests/images/DepositSuccess.svg'
import failure from '../../../../assests/images/DepositFailure.svg'
import Loading from '../../../../component/Loading'
import { currencyConverter } from '../../../../helper'

function PaymentScreen (props) {
  const { location, token, checkDepositStatus, statusData, setMessage, message, modalMessage, setModalMessage, history, currencyLogo } = props

  const [amount, setAmount] = useState(0)
  const [QRLink, setQRLink] = useState('')
  const [QRCode, setQRCode] = useState('')
  const [depositId, setDepositId] = useState('')
  const [loading, setLoading] = useState(true)
  const [copied, setCopied] = useState(false)
  const [isPending, setIsPending] = useState(false)
  const [alertMsg, setAlertMsg] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState('')
  const previousProps = useRef({ statusData }).current

  useEffect(() => {
    if (location?.state?.amount) {
      setAmount(location?.state?.amount)
    }
    if (location?.state?.paymentData) {
      const paymentData = location?.state?.paymentData
      setQRLink(paymentData?.sQRCode)
      const qrImg = paymentData?.sQRCodeImage?.split(',')
      setQRCode(qrImg && qrImg[qrImg?.length - 1])
      setDepositId(paymentData?.iDepositId)
      setLoading(false)
    }
  }, [location])

  useEffect(() => {
    const interval = setInterval(() => {
      if (token) {
        checkDepositStatus(depositId, token)
      }
    }, 5000)
    return () => clearInterval(interval)
  }, [depositId, token, statusData])

  useEffect(() => {
    if (previousProps.statusData !== statusData) {
      if (Object.keys(statusData).length > 0) {
        if (statusData?.ePaymentStatus === 'S') {
          setMessage(<FormattedMessage id='Deposit_success_msg' />)
          setModalMessage(true)
          setPaymentStatus('S')
        } else if (statusData?.ePaymentStatus === 'R') {
          setMessage(<FormattedMessage id='Deposit_refund_msg' />)
          setModalMessage(true)
          setPaymentStatus('R')
        } else if (statusData?.ePaymentStatus === 'C') {
          setMessage(<FormattedMessage id='Deposit_cancel_msg' />)
          setModalMessage(true)
          setPaymentStatus('C')
        } else if (statusData?.ePaymentStatus === 'P' && isPending) {
          setMessage(<FormattedMessage id='Deposit_pending_msg' />)
          setAlertMsg(true)
          setPaymentStatus('P')
        }
      }
    }
    return () => {
      previousProps.statusData = statusData
    }
  }, [statusData])

  useEffect(() => {
    if (alertMsg) {
      setTimeout(() => {
        setAlertMsg(false)
        setLoading(false)
      }, 3000)
    }
  }, [alertMsg])

  function depositStatusCheck () {
    setIsPending(true)
    checkDepositStatus(depositId, token)
  }

  function handleOnClick () {
    if (statusData?.ePaymentStatus === 'S' || statusData?.ePaymentStatus === 'C') {
      history.push('/profile')
    } else {
      setModalMessage(false)
    }
    setMessage('')
  }

  function copyToClipboard (textToCopy) {
    if (QRLink) {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(textToCopy).then(() => {
          setCopied(true)
          setTimeout(() => setCopied(false), 2000)
        })
      } else {
        const textArea = document.createElement('textarea')
        textArea.value = textToCopy
        textArea.style.position = 'fixed'
        textArea.style.left = '-999999px'
        textArea.style.top = '-999999px'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        return new Promise(function () {
          if (document.execCommand('copy')) {
            setCopied(true)
            setTimeout(() => setCopied(false), 2000)
          }
          textArea.remove()
        })
      }
    }
  }

  return (<>
    { alertMsg ? <Alert isOpen={alertMsg} color="primary">{message}</Alert> : ''}
    { copied && <Alert color="primary">{<FormattedMessage id="ShareContest_ccc" />}</Alert>}
    {loading && <Loading />}
    <div className='home-container deposit-banner'>
      <div className='payment-screen'>
        <div className='pix-code-card'>
          <p className='title'><FormattedMessage id='Copy_PIX_Code' /></p>
          <div className='d-flex justify-content-start'>
            <Input className='qr-text' value={QRLink} readOnly></Input>
            <img src={copy} onClick={() => copyToClipboard(QRLink)}></img>
          </div>
        </div>

        <div className='qr-code-card'>
          <p className='title'><FormattedMessage id='QR_Code' /></p>
          <div className="ul-p-bar"></div>
          <div className='qr-class'><img src={'data:image/png;base64,' + QRCode} alt='Img' ></img></div>
          <div className="btn-bottom position-relative my-3">
            <Button color="primary" onClick={depositStatusCheck} className="w-100 d-block"><FormattedMessage id="Already_Paid" /></Button>
          </div>
        </div>

      </div>
      <Modal isOpen={modalMessage} className='payment-modal'>
        <ModalBody className='payment-modal-body d-flex flex-column justify-content-center align-items-center'>
          {paymentStatus === 'S' ? <img src={success} alt='Success'></img> : paymentStatus === 'C' ? <img src={failure} alt='Canceled'></img> : ''}
          <h2>{message}</h2>
          {paymentStatus === 'S'
            ? <p><span>{currencyLogo + currencyConverter(amount)}</span><FormattedMessage id='Deposited'/></p>
            : paymentStatus === 'C' ? <p><FormattedMessage id='Your_deposit' /><span>{currencyLogo + currencyConverter(amount)}</span><FormattedMessage id='Deposit_failed' /></p> : ''
            }
          <Button onClick={handleOnClick}><FormattedMessage id='Okay'/></Button>
        </ModalBody>
      </Modal>
    </div>
    </>)
}

PaymentScreen.propTypes = {
  paymentData: PropTypes.object,
  location: PropTypes.object,
  token: PropTypes.string,
  checkDepositStatus: PropTypes.func,
  statusData: PropTypes.object,
  resStatus: PropTypes.bool,
  modalMessage: PropTypes.bool,
  setModalMessage: PropTypes.func,
  message: PropTypes.string,
  setMessage: PropTypes.func,
  history: PropTypes.object,
  currencyLogo: PropTypes.string
}

export default UserDeposit(PaymentScreen)
