import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import UserHeader from '../../User/components/UserHeader'
import Loading from '../../../component/Loading'
import { FormattedMessage } from 'react-intl'
const OffersPage = lazy(() => import('./Offers'))

function Offers (props) {
  return (
    <>
      {/* <UserHeader {...props} title={<FormattedMessage id='More_offers' />} backURL='/more'/> */}
      <UserHeader {...props} title={<FormattedMessage id='More_offers' />} backURL={props?.match?.path === '/offers/v1' ? '/more/v1' : '/more'}/>
      <Suspense fallback={<Loading />}>
        <OffersPage {...props} />
      </Suspense>
    </>
  )
}

Offers.propTypes = {
  match: PropTypes.object
}

export default Offers
