/* eslint-disable no-unneeded-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import homeIcon from '../../../assests/images/homeIconWhite.svg'

function UserHeader (props) {
  const { location, backURL, setActiveTab, activeTab, history, createContest, match } = props
  const activeSports = useSelector(state => state.activeSports.activeSports)
  const activeSport = activeSports && activeSports.length > 0 && activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => data)

  function backClick () {
    if (activeTab === false) {
      setActiveTab(true)
    } else {
      if (backURL) {
        history.push(backURL,
          createContest ? { ContestName: props?.location?.state?.ContestName, ContestPrize: props?.location?.state?.ContestPrize, ContestSize: props?.location?.state?.ContestSize, multipleTeam: props?.location?.state?.multipleTeam, poolPrice: props?.location?.state?.poolPrice, entryFee: props?.location?.state?.entryFee } : location?.state
        )
      } else {
        history.goBack({}, location.state)
      }
    }
  }

  return (
    <div className="league-header u-header">
      <div className="d-flex align-items-center header-i">
        <button onClick={backClick} className="btn-link icon-left-arrow"></button>
        {location?.pathname !== '/notifications' && <Link className='btn-link bg-transparent' to={match.path.includes('/v1') ? `/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}/v1` : `/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}`}><img src={homeIcon} /></Link>}
        <div>
          <h1>{props.title}</h1>
        </div>
      </div>
    </div>
  )
}

UserHeader.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  backURL: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func
  }),
  activeTab: PropTypes.bool,
  setActiveTab: PropTypes.func,
  location: PropTypes.object,
  createContest: PropTypes.bool,
  match: PropTypes.object
}

export default UserHeader
