import React, { lazy, Suspense } from 'react'
import UserHeader from '../../User/components/UserHeader'
import Loading from '../../../component/Loading'
import { FormattedMessage } from 'react-intl'
const ContactUsForm = lazy(() => import('./ContactUs'))

function ContactUS (props) {
  return (
    <>
      <UserHeader {...props} title={<FormattedMessage id='More_Feedback' />} backURL='/complaints'/>
      <Suspense fallback={<Loading />}>
        <ContactUsForm {...props}/>
      </Suspense>
    </>
  )
}

export default ContactUS
