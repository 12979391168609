import React, { useEffect, useState, useRef, Fragment } from 'react'
import { Form, FormGroup, Label, Input, Alert, Button } from 'reactstrap'
import Verification from '../../../../../common/src/components/User/Verification'
import { isNumber } from '../../../helper'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import Loading from '../../../component/Loading'
function VerifiedPage (props) {
  const { resMessage, loading, modalMessage, userInfo, onSendOTP, onVerifyOTP, otpVerified, sendOtp } = props
  const [otp, setOtp] = useState('')
  const [errOtp, setErrOtp] = useState('')
  const [sendedOtp, setSendedOtp] = useState(false)
  const [durationTime, setDurationTime] = useState('')
  const [intervalRef, setIntervalRef] = useState(null)
  const [userData, setUserData] = useState({ sEmail: '', sMobNum: '', otp: '' })
  const previousProps = useRef({ otpVerified }).current

  useEffect(() => {
    if (userInfo) {
      setUserData({ ...userData, sEmail: userInfo.sEmail, sMobNum: userInfo.sMobNum })
    }
  }, [userInfo])

  useEffect(() => {
    if (sendedOtp) {
      changeClock()
      setTimeout(() => {
        setSendedOtp(false)
      }, 30000)
    }
  }, [sendedOtp])

  useEffect(() => {
    if (previousProps.otpVerified !== otpVerified) { // handle the response
      if (otpVerified) {
        props.history.push('/profile', { message: resMessage })
      }
    }
    return () => {
      previousProps.otpVerified = otpVerified
    }
  }, [otpVerified])

  function handleChange (event, type) { // set the value
    switch (type) {
      case 'OTP':
        if (isNumber(event.target.value) && event.target.value.length === 4) {
          setErrOtp('')
        } else {
          if (event.target.value.length !== 4) {
            setErrOtp(<FormattedMessage id="Otp_Length_Number" />)
          } else {
            setErrOtp(<FormattedMessage id="Common_NumberOTP" />)
          }
        }
        setOtp(event.target.value)
        break
      default:
        break
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSendOTP(props.match.path === '/verification/Email' ? userData.sEmail : userData.sMobNum, props.match.path === '/verification/Email' ? 'E' : 'M', 'V')
    setSendedOtp(true)
  }

  const VerifiedOTP = (e) => {
    e.preventDefault()
    onVerifyOTP(props.match.path === '/verification/Email' ? userData.sEmail : userData.sMobNum, props.match.path === '/verification/Email' ? 'E' : 'M', 'V', otp)
  }

  const changeClock = () => {
    let duration = 30
    if (duration > 0) {
      setIntervalRef(
        setInterval(() => {
          if (duration >= 0) {
            setDurationTime(duration)
            duration = duration - 1
          }
        }, 1000)
      )
    }
    return () => {
      clearInterval(intervalRef)
    }
  }
  return (<>
    {loading && <Loading />}
    {modalMessage ? <Alert isOpen={modalMessage} color="primary">{resMessage}</Alert> : ''}
    <div className="user-container bg-white">
      <Form className="form sign-up pb-0">
        {
          props.match.path === '/verification/Email'
            ? <Fragment>
              <p className="m-msg text-left"><FormattedMessage id='Will_received_OTP_on_email' /></p>
              <FormGroup className="c-input">
                <Input disabled autoComplete='off' className={`bg-white ${userData.sEmail ? 'hash-contain' : ' '}`} name="sEmail" type="email" id="Email" defaultValue={userData.sEmail} />
                <Label className="no-change label m-0" for="Email"><FormattedMessage id="profileInfo_Email" /></Label>
              </FormGroup>
            </Fragment>
            : <Fragment>
              <FormGroup className="c-input">
                <Input disabled autoComplete='off' className={`bg-white hidden-border ${userData.sMobNum ? 'hash-contain' : ' '}`} name="sMobNum" type="text" id="MobileNumber" defaultValue={userData.sMobNum} />
                <Label className="no-change label m-0" for="MobileNumber"><FormattedMessage id="profileInfo_MobileNo" /></Label>
              </FormGroup>
            </Fragment>
        }
        {sendOtp && !otpVerified && <Fragment>
          <FormGroup className="c-input">
            <Input type="number" id="otp" autoFocus autoComplete='off' value={otp} className={otp ? 'hash-contain' : ' '} disabled={otpVerified} onChange={(e) => { handleChange(e, 'OTP') }} />
            <p className="error-text">{errOtp}</p>
            <Label className="label m-0" for="otp"><FormattedMessage id="Login_Otp" /></Label>
            <div className="text-right">
              <Button color="link" onClick={handleSubmit} disabled={!!sendedOtp} className="forgot-link bluecolor"><FormattedMessage id="Login_ResendOTP" /> {(durationTime > 0) ? <span><FormattedMessage id="Change_IN" /> {`${durationTime}`} <FormattedMessage id="Change_SEC" /></span> : ''} </Button>
            </div>
          </FormGroup>
          <Button color="primary" block disabled={!otp || errOtp} hidden={otpVerified} onClick={VerifiedOTP}><FormattedMessage id="SignUp_VerifyOtp" /></Button>
        </Fragment>
        }
        {
          <div className="f-bottom w-padding text-center">
            <Button color="primary" type="submit" block
              hidden={sendOtp && !otpVerified}
              onClick={handleSubmit}
            >{
                props.match.path === '/verification/Email' ? <FormattedMessage id="Verify_Email" /> : <FormattedMessage id="Verify_Mobile" />
              }
            </Button>
          </div>
        }
      </Form>
    </div>
  </>)
}
VerifiedPage.propTypes = {
  UpdateProfile: PropTypes.func,
  resMessage: PropTypes.string,
  token: PropTypes.string,
  resStatus: PropTypes.bool,
  userInfo: PropTypes.shape({
    sEmail: PropTypes.string,
    sMobNum: PropTypes.string
  }),
  userData: PropTypes.shape({
    push: PropTypes.func
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      sportsType: PropTypes.string
    }),
    path: PropTypes.string
  }),
  loading: PropTypes.bool,
  sendOtp: PropTypes.bool,
  otpVerified: PropTypes.bool,
  modalMessage: PropTypes.func,
  setUserData: PropTypes.func,
  getUserProfile: PropTypes.func,
  setLoading: PropTypes.func,
  onSendOTP: PropTypes.func,
  onVerifyOTP: PropTypes.func
}
export default Verification(VerifiedPage)
