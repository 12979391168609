import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listofjoinedLeague, getMatchLeagueDetails } from '../../redux/actions/league'
import { getMyTeamList, getMyTeamPlayerList, getTournamentMatchPlayer } from '../../redux/actions/team'
import { getMatchDetails } from '../../redux/actions/match'
import { getPlayerScorePoints, getPlayerSeasonNames, getUniquePlayers } from '../../redux/actions/player'
import { getMyTeamLeaderBoardList, getAllTeamLeaderBoardList } from '../../redux/actions/leaderBoard'
import { getFetchLiveInnings } from '../../redux/actions/scoreCard'

function LeagueCompleted(Component) {
  return (props) => {
    const { match, activeTab, leaguesInfo, history } = props
    const [loading, setLoading] = useState(false)
    const [joinedLoading, setJoinedLoading] = useState(false)
    const [loadingScorecard, setLoadingScorecard] = useState(false)
    const [url, setUrl] = useState('')
    const [allTeam, setAllTeam] = useState([])
    const [joinedData, setJoinedData] = useState([])
    const [Details, setDetailss] = useState({})
    const [selectedTeamCompare, setSelectedTeamCompare] = useState([])
    const [firstTeam, setFirstTeam] = useState({})
    const [secondTeam, setSecondTeam] = useState({})
    const [limit] = useState(50)
    const [offset] = useState(0)

    const dispatch = useDispatch()
    const matchLeagueDetails = useSelector(state => state.league.matchLeagueDetails)
    const matchDetails = useSelector(state => state.match.matchDetails)
    const joinedLeagueList = useSelector(state => state.league.joinedLeagueList)
    const teamList = useSelector(state => state.team.teamList)
    const allLeaderBoardList = useSelector(state => state.leaderBoard.allLeaderBoardList)
    const myTeamsLeaderBoardList = useSelector(state => state.leaderBoard.myTeamsLeaderBoardList)
    const teamPlayerList = useSelector(state => state.team.teamPlayerList)
    const playerRoles = useSelector(state => state.team.playerRoles)
    const matchPlayerMatchId = useSelector(state => state.team.matchPlayerMatchId)
    const playerMatchPlayerMatchId = useSelector(state => state.player.matchPlayerMatchId)
    const bCached = useSelector(state => state.leaderBoard.bCached)
    const bFullResponse = useSelector(state => state.leaderBoard.bFullResponse)
    const nPutTime = useSelector(state => state.leaderBoard.nPutTime)
    // const matchPlayerList = useSelector(state => state.player.matchPlayer)
    const pointBreakUp = useSelector(state => state.player.pointBreakUp)
    const getUrlLink = useSelector(state => state.url.getUrl)
    const nScoredPoints = useSelector(state => state.player.nScoredPoints)
    const playerData = useSelector(state => state.player.playerData)
    const uniquePlayerLeagueList = useSelector(state => state.player.uniquePlayerLeagueList)
    const uniquePlayerList = useSelector(state => state.player.uniquePlayerList)
    const currencyLogo = useSelector(state => state.settings.currencyLogo)
    const userData = useSelector(state => state.auth.userData)
    const token = useSelector(state => state.auth.token) || localStorage.getItem("Token")
    const fullLiveInning = useSelector(state => state.scoreCard.fullLiveInning)
    const isFetchLiveInning = useSelector(state => state.scoreCard.isFetchLiveInning)
    const previousProps = useRef({
      isFetchLiveInning, teamPlayerList, matchDetails, getUrlLink, joinedLeagueList, matchLeagueDetails, activeTab, myTeamsLeaderBoardList, teamList, allLeaderBoardList, token
    }).current

    useEffect(() => { // call initative apis
      if (match && match.params && match.params.id2 && token) {
        dispatch(getMatchLeagueDetails(match.params.id2, token))
      }
      if (!matchDetails || (matchDetails && matchDetails._id && match.params.id !== matchDetails._id) && token ) {
        getMatchDetailsFunc(match.params.id, match.params.sportsType, token)
        setLoadingScorecard(true)
      }
      // if ((token && matchPlayerMatchId !== match.params.id) && history.location.pathname.includes('tournament') || !teamPlayerList ) {
      //   dispatch(getTournamentMatchPlayer(match.params.id, token))
      // } else if ((token && matchPlayerMatchId !== match.params.id) && (!history.location.pathname.includes('tournament')) || !teamPlayerList ) {
      //   dispatch(getMyTeamPlayerList(match.params.id, token))
      // }
      if ((token && (matchPlayerMatchId !== match.params.id || playerMatchPlayerMatchId !== match.params.id)) && history && history.location && history.location.pathname && history.location.pathname.includes('tournament')) {
        getMatchPlayersFunc(match.params.id, token)
      } else if (token && (matchPlayerMatchId !== match.params.id || playerMatchPlayerMatchId !== match.params.id) || !teamPlayerList ) {
        dispatch(getMyTeamPlayerList(match.params.id, token))
      }
    }, [token])

    useEffect(() => {
      if (previousProps.isFetchLiveInning !== isFetchLiveInning) { // handle the response
        if (isFetchLiveInning) {
          setJoinedLoading(false)
        }
      }
      return () => {
        previousProps.isFetchLiveInning = isFetchLiveInning
      }
    }, [isFetchLiveInning])


    useEffect(() => {
      if (previousProps.matchDetails !== matchDetails) { // handle the response
        if (matchDetails && matchDetails._id) {
          setLoadingScorecard(false)
        }
      }
      return () => {
        previousProps.matchDetails = matchDetails
      }
    }, [matchDetails])

    useEffect(() => {
      if (getUrlLink) {
        setUrl(getUrlLink)
      }
    }, [getUrlLink])

    function addCompare(Team) {
      if (!selectedTeamCompare.includes(Team._id) && selectedTeamCompare.length !== 2) { // handle the response
        if (selectedTeamCompare && selectedTeamCompare.length !== 0) {
          if (firstTeam && firstTeam._id) {
            setSecondTeam(Team)
          } else if (secondTeam && secondTeam._id) {
            setFirstTeam(Team)
          }
        } else {
          setFirstTeam(Team)
        }
        setSelectedTeamCompare([...selectedTeamCompare, Team._id])
      } else {
        if (selectedTeamCompare.includes(Team._id)) {
          const FilterData = selectedTeamCompare && selectedTeamCompare.length !== 0 && selectedTeamCompare.filter(data => data !== Team._id)
          setSelectedTeamCompare(FilterData)
          if (firstTeam._id === Team._id) {
            setFirstTeam({})
          } else if (secondTeam._id === Team._id) {
            setSecondTeam({})
          }
        }
      }
    }

    function clearTeamSelected() {
      setSelectedTeamCompare([])
      setFirstTeam({})
      setSecondTeam({})
    }

    useEffect(() => {
      if (previousProps.allLeaderBoardList !== allLeaderBoardList) { // handle the loader
        if (allLeaderBoardList && allLeaderBoardList.length !== 0 && userData) {
          const data = allLeaderBoardList.sort((a, b) => b.nTotalPoints - a.nTotalPoints).filter((teamLeaderboard) => teamLeaderboard.iUserId !== userData._id)
          setAllTeam(data)
          setLoading(false)
        }
      }
      return () => {
        previousProps.allLeaderBoardList = allLeaderBoardList
      }
    }, [allLeaderBoardList])

    useEffect(() => {
      if (teamPlayerList && teamPlayerList.length !== 0) { // handle the loader
        setLoading(false)
      }
      return () => {
        previousProps.teamPlayerList = teamPlayerList
      }
    }, [teamPlayerList])

    useEffect(() => {
      if (previousProps.activeTab !== activeTab) { // handle the apis calling when change the tabs
        if (match && match.params.id && !leaguesInfo) {
          if (activeTab === '1' && token) {
            userJoinLeaguesFunc(match.params.id, token)
            setJoinedLoading(true)
          } else if (activeTab === '2' && token) {
            dispatch(getMyTeamList(match.params.id, token))
          } else if (activeTab === '3' && token) {
            history && history.location && history.location.pathname && history.location.pathname.includes('tournament') ? getMatchPlayersFunc(match.params.id, token) : dispatch(getMyTeamPlayerList(match.params.id, token))
            dispatch(getUniquePlayers(match.params.id, token))
          } else if (activeTab === '4') {
            dispatch(getFetchLiveInnings(match.params.id, ''))
            setJoinedLoading(true)
          }
          setLoading(true)
        } else {
          if (activeTab === '2' && match && match.params && match.params.id2 && token) {
            dispatch(getMyTeamLeaderBoardList(match.params.id2, token))
            dispatch(getAllTeamLeaderBoardList(limit, offset, match.params.id2, token, nPutTime))
            dispatch(getMyTeamList(match.params.id, token))
            setLoading(true)
          } else if (activeTab === '3' && token) {
            history && history.location && history.location.pathname && history.location.pathname.includes('tournament') ? getMatchPlayersFunc(match.params.id, token) : dispatch(getMyTeamPlayerList(match.params.id, token))
            dispatch(getUniquePlayers(match.params.id, token))
            setLoading(true)
          } else if (activeTab === '4') {
            dispatch(getFetchLiveInnings(match.params.id, ''))
            setJoinedLoading(true)
          }
        }
      } else {
        if (match && match.params.id && !leaguesInfo) {
          if (activeTab === '1' && token) {
            userJoinLeaguesFunc(match.params.id, token)
            setJoinedLoading(true)
          } else if (activeTab === '2' && token) {
            dispatch(getMyTeamList(match.params.id, token))
            !leaguesInfo && token && userJoinLeaguesFunc(match.params.id, token)
          } else if (activeTab === '3' && token) {
            history && history.location && history.location.pathname && history.location.pathname.includes('tournament') ? getMatchPlayersFunc(match.params.id, token) : dispatch(getMyTeamPlayerList(match.params.id, token))
            dispatch(getUniquePlayers(match.params.id, token))
          } else if (activeTab === '4') {
            dispatch(getFetchLiveInnings(match.params.id, ''))
            setJoinedLoading(true)
          }
          setLoading(true)
        } else {
          if ((activeTab === '2' || activeTab === 2) && match && match.params && match.params.id2 && token) {
            dispatch(getMyTeamLeaderBoardList(match.params.id2, token))
            dispatch(getAllTeamLeaderBoardList(limit, offset, match.params.id2, token, nPutTime))
            dispatch(getMyTeamList(match.params.id, token))
            !leaguesInfo && userJoinLeaguesFunc(match.params.id, token)
            setLoading(true)
          } else if (activeTab === '3' && token) {
            history && history.location && history.location.pathname && history.location.pathname.includes('tournament') ? getMatchPlayersFunc(match.params.id, token) : dispatch(getMyTeamPlayerList(match.params.id, token))
            dispatch(getUniquePlayers(match.params.id, token))
            setLoading(true)
          } else if (activeTab === '1' && !leaguesInfo && token) {
            userJoinLeaguesFunc(match.params.id, token)
            setJoinedLoading(true)
          } else if (activeTab === '4') {
            dispatch(getFetchLiveInnings(match.params.id, ''))
            setJoinedLoading(true)
          }
        }
      }
      return () => {
        previousProps.activeTab = activeTab
      }
    }, [activeTab, token])

    useEffect(() => {
      if (previousProps.joinedLeagueList !== joinedLeagueList) { // handle the response
        if (joinedLeagueList) {
          setJoinedData(joinedLeagueList)
          setJoinedLoading(false)
          setLoading(false)
        }
      }
      return () => {
        previousProps.joinedLeagueList = joinedLeagueList
      }
    }, [joinedLeagueList])

    useEffect(() => {
      if (previousProps.teamList !== teamList) { // handle the loader
        if (teamList) {
          setLoading(false)
        }
      }
      return () => {
        previousProps.teamList = teamList
      }
    }, [teamList])

    useEffect(() => {
      if (previousProps.matchLeagueDetails !== matchLeagueDetails) { // handle the response
        if (matchLeagueDetails) {
          setDetailss(matchLeagueDetails)
        }
      }
      return () => {
        previousProps.matchLeagueDetails = matchLeagueDetails
      }
    }, [matchLeagueDetails])

    function playerScorePoints(pID) {
      token && dispatch(getPlayerScorePoints(pID, token))
      token && dispatch(getPlayerSeasonNames(pID, token))
    }

    function gatTeamList() {
      if (match && match.params && match.params.id) {
        token && dispatch(getMyTeamList(match.params.id, token))
      }
    }

    function getMyTeamLeaderBoardListFunc() {
      dispatch(getMyTeamLeaderBoardList(match.params.id2, token))
    }

    function onUniquePlayers() {
      if (match && match.params && match.params.id) {
        token && dispatch(getUniquePlayers(match.params.id, token))
      }
    }
    
    function myAllTeamPagination(limit, offset) {
      const leagueId = match && match.params && match.params.id2
      token && dispatch(getAllTeamLeaderBoardList(limit, offset, leagueId, token, nPutTime))
    }

    function getMatchDetailsFunc (matchId, sportsType, token) {
      dispatch(getMatchDetails(matchId, sportsType, token))
    }

    function getMatchPlayersFunc (matchId, token) {
      dispatch(getTournamentMatchPlayer(matchId, token))
    }

    function userJoinLeaguesFunc(matchId, token) {
      dispatch(listofjoinedLeague(matchId, token))
    }

    return (
      <Component
        {...props}
        matchDetails={matchDetails}
        loading={loading}
        firstTeam={firstTeam}
        secondTeam={secondTeam}
        selectedTeamCompare={selectedTeamCompare}
        clearTeamSelected={clearTeamSelected}
        addCompare={addCompare}
        participate={true}
        leagueDetails={Details}
        joinedLeagueList={joinedData}
        playerScorePoints={playerScorePoints}
        playerData={playerData}
        nScoredPoints={nScoredPoints}
        pointBreakUp={pointBreakUp}
        teamList={teamList}
        userData={userData}
        matchPlayerList={teamPlayerList}
        allLeaderBoardList={allTeam}
        myTeamsLeaderBoardList={myTeamsLeaderBoardList}
        setLoading={setLoading}
        url={url}
        getMyTeamList={gatTeamList}
        myAllTeamPagination={myAllTeamPagination}
        getMyTeamLeaderBoardListFunc={getMyTeamLeaderBoardListFunc}
        uniquePlayerLeagueList={uniquePlayerLeagueList}
        uniquePlayerList={uniquePlayerList}
        token={token}
        otherTeamJoinList={allLeaderBoardList}
        loadingScorecard={loadingScorecard}
        currencyLogo={currencyLogo}
        onUniquePlayers={onUniquePlayers}
        joinedLoading={joinedLoading}
        bCached={bCached}
        teamPlayerList={teamPlayerList}
        fullLiveInning={fullLiveInning}
        bFullResponse={bFullResponse}
        userJoinLeaguesFunc={userJoinLeaguesFunc}
        playerRoles={playerRoles}
      />
    )
  }
}

export default LeagueCompleted
