/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import Contests from '../../../assests/images/Contests.svg'
import PlayerImage from '../../../assests/images/User.png'
import LoginPage from 'Common/src/components/Auth/LoginPage'
import Bonus from '../../../assests/images/Bonus.svg'
import Loyalty from '../../../assests/images/ic_Loyaly_colored_blue.svg'
import Commission from '../../../assests/images/ic_commision.svg'
import Percentage from '../../../assests/images/ic_percentage_bg.svg'
import Wins from '../../../assests/images/Wins.svg'
import gotoBlue from '../../../assests/images/goto_blue.svg'
import goto from '../../../assests/images/goto.svg'
import infoIconGray from '../../../assests/images/info-icon-gray.svg'
import commissionTwo from '../../../assests/images/commission_photo-two.svg'
import config from '../../../config'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
  TelegramShareButton, LinkedinShareButton, LinkedinIcon, TelegramIcon, WhatsappShareButton, WhatsappIcon, FacebookShareButton, FacebookIcon, FacebookMessengerShareButton, FacebookMessengerIcon, TwitterShareButton, TwitterIcon, EmailShareButton, EmailIcon
} from 'react-share'
import { Link } from 'react-router-dom'
import { Alert, Container, Row, Col, Button, UncontrolledPopover, PopoverBody } from 'reactstrap'
import Loading from '../../../component/SkeletonProfile'
import share from '../../../assests/images/share.svg'
import { currencyConverter } from '../../../helper'

function ProfilePage (props) {
  const { intl: { formatMessage }, statisticsData } = props
  const { updatedProfilePic, Logout, currencyLogo, userData, profileModelMessage, profileData, url, userInformation, updateProfilePic, loading, location, setModalMessage } = props
  const [profileImg, setProfileImg] = useState({ img: '', file: '' })
  const [message, setMessage] = useState('')
  const [alert, setAlert] = useState(false)
  const previousProps = useRef({ updatedProfilePic }).current

  const handleChangeProfile = (e) => {
    if ((e.target.files[0]?.size / 1024 / 1024).toFixed(2) > 5) {
      setMessage(<FormattedMessage id='Profile_pic_validation' />)
      setModalMessage(true)
      setTimeout(() => setModalMessage(false), 3000)
    } else if (e.target.files[0] && e.target.files[0].type.includes('image') && (e.target.files[0]?.size / 1024 / 1024).toFixed(2) < 5) {
      setProfileImg({ ...profileImg, img: URL.createObjectURL(e.target.files[0]), file: e.target.files[0] })
      updateProfilePic('Web', 'img', e.target.files[0])
    }
  }

  useEffect(() => {
    if (location && location.state && location.state.message) {
      setMessage(location.state.message)
      setAlert(true)
    }
  }, [location && location.state])

  useEffect(() => { // Handle user data
    if (profileData) {
      setProfileImg({ ...profileImg, img: profileData.sProPic ? profileData.sProPic : '' })
    }
  }, [profileData])

  useEffect(() => { // Handle user data
    if (previousProps.updatedProfilePic !== updatedProfilePic) {
      if (updatedProfilePic) {
        setAlert(true)
        setTimeout(() => {
          setAlert(false)
        }, 2000)
      }
    }
    return () => {
      previousProps.updatedProfilePic = updatedProfilePic
    }
  }, [updatedProfilePic])
  useEffect(() => {
    setTimeout(() => {
      setAlert(false)
    }, 2000)
  }, [alert])

  async function handleShare ({ text, url }) {
    const shareData = {
      // title: 'Title',
      text: text,
      url: url
    }
    if (navigator.canShare) {
      try {
        await navigator.share(shareData)
      } catch (error) {
        setMessage(error?.message)
        setModalMessage(true)
        setTimeout(() => setModalMessage(false), 3000)
      }
    }
  }

  return (
    <>
      { loading
        ? <Loading />
        : (
          <>
            <div className="user-container">
              {alert && ((message && message.length) || profileModelMessage) ? <Alert color="primary" isOpen={alert}>{message || profileModelMessage}</Alert> : ''}
              <div className='orangebg' />
              <div className="userprofile">
                <div className="userprofile_container">
                  <div className='d-flex justify-content-start align-items-center'>
                    <div className="u-img">
                      <img alt={<FormattedMessage id='Player_Image' />} src={profileImg?.img && url ? `${url}${profileImg.img}` : PlayerImage} />
                      <input accept="image/png, image/jpg, image/jpeg" className="d-none" id="profile" onChange={handleChangeProfile} type="file"/>
                      <label className="icon-camera" htmlFor="profile" />
                    </div>
                    <div className='class-user-name'>
                      <h2 className="u-name">{profileData?.sName || profileData?.sUsername}</h2>
                      <h2 className="u-name">
                        <Button className='outline btn-lineups2'>
                          <img className='mr-1' src={Loyalty}/>
                          <FormattedMessage id="Player_points" />
                          :
                          <span className='ml-1'>{profileData && profileData.nLoyaltyPoints}</span>
                        </Button>
                      </h2>
                    </div>
                  </div>
                  <div className="balance d-flex align-items-center justify-content-around">
                    <Container>
                      <Row className="justify-content-md-center">
                        <Col className='center'>
                          <div className="b-box">
                            <div><FormattedMessage id="PROFILE_AVL_BALANCE" /></div>
                            <b>
                              {currencyLogo}
                              {userInformation && userInformation.nCurrentTotalBalance ? currencyConverter(userInformation.nCurrentTotalBalance) : 0}
                            </b>
                          </div>
                        </Col>
                      </Row>
                      <Row className="justify-content-md-center">
                        <Col className='center'>
                          <div className="b-box">
                            <div className='box'>
                              <div className='d-flex justify-content-center align-items-center'>
                                <p>
                                  {' '}
                                  <FormattedMessage id="PROFILE_DEP_BALANCE" />
                                  {' '}
                                </p>
                                <Fragment>
                                  <button className="bg-transparent i-button match-i ml-2" id='deposite-info' type="button"><img src={infoIconGray} width={16} /></button>
                                  <UncontrolledPopover placement="bottom" target='deposite-info' trigger="legacy">
                                    <PopoverBody><FormattedMessage id="Profile_deposite_info_msg" /></PopoverBody>
                                  </UncontrolledPopover>
                                </Fragment>
                              </div>
                              <b className='balance-text'>
                                {currencyLogo}
                                {userInformation && userInformation.nCurrentDepositBalance ? currencyConverter(userInformation.nCurrentDepositBalance) : 0}
                              </b>
                            </div>
                            <Link className="btn" to="/deposit" >
                              <FormattedMessage id="Deposit" />
                              <img alt={<FormattedMessage id='Goto_Icon' />} src={gotoBlue} />
                            </Link>
                          </div>
                        </Col>
                        <Col className='center'>
                          <div className="b-box">
                            <div className='box'>
                              <div className='d-flex justify-content-center align-items-center'>
                                <p>
                                  {' '}
                                  <FormattedMessage id="PROFILE_WIN_BALANCE" />
                                  {' '}
                                </p>
                                <Fragment>
                                  <button className="bg-transparent i-button match-i ml-2" id='balance-info' type="button"><img src={infoIconGray} width={16} /></button>
                                  <UncontrolledPopover placement="bottom" target='balance-info' trigger="legacy">
                                    <PopoverBody><FormattedMessage id="Profile_balance_info_msg" /></PopoverBody>
                                  </UncontrolledPopover>
                                </Fragment>
                              </div>
                              <b className='balance-text'>
                                {currencyLogo}
                                {userInformation && userInformation.nCurrentWinningBalance ? currencyConverter(userInformation.nCurrentWinningBalance) : 0}
                              </b>
                            </div>
                            <Link className="btn" to={{ pathname: '/withdraw', state: { userInformation } }} >
                              <FormattedMessage id="User_withdrawal" />
                              <img alt={<FormattedMessage id='Goto_Icon' />} src={gotoBlue} />
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <div className="transaction-box d-flex justify-content-between" onClick={() => props.history.push('/transactions')} >
                    <FormattedMessage id="User_transaction" />
                    <img alt={<FormattedMessage id='Goto_Icon' />} src={goto} />
                  </div>
                </div>

                <div className="userprofile_second_container">
                  <div className="kyc-btn w-100 mb-0 btn btn-blue d-flex justify-content-between align-items-center" >
                    <span>
                      <img className="percentage" src={Percentage} />
                      <img src={Commission} />
                      <FormattedMessage id="User_commission_Private_Contest" />
                    </span>
                    <span>
                      <img className="commissionTwo" src={commissionTwo} />
                      {userInformation?.nLeagueCreatorCom || 0}
                      {' '}
                      %
                    </span>
                  </div>
                  <div className="u-matchinfo d-flex align-items-center justify-content-center">
                    <div className="um-box">
                      <img alt={<FormattedMessage id='Contests' />} src={Contests} width="40px" />
                      <p>{statisticsData && statisticsData.nTotalJoinLeague ? statisticsData.nTotalJoinLeague : 0}</p>
                      <span><FormattedMessage id="League_contest" /></span>
                    </div>
                    <div className="um-box">
                      <img alt={<FormattedMessage id='League_Wins' />} src={Wins} width="40px" />
                      <p>
                        {currencyLogo}
                        {statisticsData && statisticsData.nTotalWinnings ? currencyConverter(parseFloat(statisticsData.nTotalWinnings).toFixed(2)) : 0}
                      </p>
                      <span><FormattedMessage id="Total_Win" /></span>
                    </div>
                    <div className="um-box">
                      <img alt={<FormattedMessage id='Matches' />} src={Bonus} width="30px" />
                      <p>
                        {currencyLogo}
                        {userInformation && userInformation.nCurrentBonus ? currencyConverter(userInformation.nCurrentBonus) : 0}
                      </p>
                      <span><FormattedMessage id="PROFILE_BONUS_BALANCE" /></span>
                    </div>
                  </div>
                  <div className="share-box text-center">
                    <h2>{profileData && profileData.sReferCode}</h2>
                    <p><FormattedMessage id="Share_Code" /></p>
                    {navigator.canShare
                      ? (
                        <div className="native-share-box">
                          <Button color='link'
                            onClick={() => handleShare(
                              {
                                text: `${formatMessage({ id: 'Static_Share_ReferCode' })} ${profileData && profileData.sReferCode && profileData.sReferCode} ${formatMessage({ id: 'Static_In_Cartola_Pix' })} `,
                                url: `${profileData && profileData.sReferLink && profileData.sReferLink}`
                              }
                            )}
                          >
                            <img src={share} />
                            <FormattedMessage id="Share" />
                          </Button>
                        </div>
                        )
                      : (
                        <div>
                          <WhatsappShareButton
                            className="Demo__some-network__share-button mr-2 mt-2"
                            image={PlayerImage}
                            separator=":"
                            title={`${formatMessage({ id: 'Static_Share_ReferCode' })} ${profileData && profileData.sReferCode && profileData.sReferCode} ${formatMessage({ id: 'Static_In_Cartola_Pix' })} `}
                            url={`${profileData && profileData.sReferLink && profileData.sReferLink}`}
                          >
                            <WhatsappIcon round size={30} />
                          </WhatsappShareButton>
                          <FacebookShareButton
                            className="Demo__some-network__share-button mr-2 mt-2"
                            quote={<FormattedMessage id="Static_Facebook" />}
                            url={`${profileData && profileData.sReferLink && profileData.sReferLink}`}
                          >
                            <FacebookIcon round size={30} />
                          </FacebookShareButton>
                          <TwitterShareButton
                            className="Demo__some-network__share-button mr-2 mt-2"
                            title={`${formatMessage({ id: 'Static_Share_ReferCode' })} ${profileData && profileData.sReferCode && profileData.sReferCode} ${formatMessage({ id: 'Static_In_Cartola_Pix' })} `}
                            url={`${profileData && profileData.sReferLink && profileData.sReferLink}`}
                          >
                            <TwitterIcon round size={30} />
                          </TwitterShareButton>
                          <LinkedinShareButton
                            className="Demo__some-network__share-button mr-2 mt-2"
                            title={`${formatMessage({ id: 'Static_Share_ReferCode' })} ${profileData && profileData.sReferCode && profileData.sReferCode} ${formatMessage({ id: 'Static_In_Cartola_Pix' })} `}
                            url={`${profileData && profileData.sReferLink && profileData.sReferLink}`}
                          >
                            <LinkedinIcon round size={30} />
                          </LinkedinShareButton>
                          <FacebookMessengerShareButton
                            appId={config.facebookAppID}
                            className="Demo__some-network__share-button mr-2 mt-2"
                            title={`${formatMessage({ id: 'Static_Share_ReferCode' })} ${profileData && profileData.sReferCode && profileData.sReferCode} ${formatMessage({ id: 'Static_In_Cartola_Pix' })} `}
                            url={`${profileData && profileData.sReferLink && profileData.sReferLink}`}
                          >
                            <FacebookMessengerIcon round size={30} />
                          </FacebookMessengerShareButton>
                          <TelegramShareButton
                            className="Demo__some-network__share-button mr-2 mt-2"
                            title={`${formatMessage({ id: 'Static_Share_ReferCode' })} ${profileData && profileData.sReferCode && profileData.sReferCode} ${formatMessage({ id: 'Static_In_Cartola_Pix' })} `}
                            url={`${profileData && profileData.sReferLink && profileData.sReferLink}`}
                          >
                            <TelegramIcon round size={30} />
                          </TelegramShareButton>
                          <EmailShareButton
                            className="Demo__some-network__share-button mr-2 mt-2"
                            onClick={ (_, link) => {
                              window.open(link, '_blank')
                            }}
                            onShareWindowClose={true}
                            openShareDialogOnClick={true}
                            separator=' '
                            subject={`${formatMessage({ id: 'My_Refer_Code' })} ${profileData && profileData.sReferCode && profileData.sReferCode} `}
                            url={`${profileData && profileData.sReferLink && profileData.sReferLink}`}
                    // title={`${formatMessage({ id: 'Static_Share_ReferCode' })} ${profileData && profileData.sReferCode && profileData.sReferCode} ${formatMessage({ id: 'Static_In_Cartola_Pix' })} `}
                            body={`${formatMessage({ id: 'Static_Share_ReferCode' })} ${profileData && profileData.sReferCode && profileData.sReferCode} ${formatMessage({ id: 'Static_In_Cartola_Pix' })} `}
                          >
                            <EmailIcon round size={30} />
                          </EmailShareButton>
                        </div>
                        )}
                  </div>
                  <ul className="p-links hide-hover my-4">
                    {/* <li><Link to="/kyc-bank-verification" ><FormattedMessage id="User_KycVerification" /><i className="icon-right"></i></Link></li> */}
                    <li>
                      <Link to="/profile-info" >
                        <FormattedMessage id="User_profileInfo" />
                        <i className="icon-right" />
                      </Link>
                    </li>
                    {/* <li><Link to="/preference" ><FormattedMessage id="Profile_Preference" /><i className="icon-right"></i></Link></li> */}
                    <li>
                      <Link to="/change-password">
                        <FormattedMessage id="User_changePassword" />
                        <i className="icon-right" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/leaderboard" >
                        <FormattedMessage id="User_leaderBoard" />
                        <i className="icon-right" />
                      </Link>
                    </li>
                    <li onClick={Logout}><Button><FormattedMessage id="User_logout" /></Button></li>
                  </ul>
                </div>
              </div>
            </div>
          </>
          )
    }
    </>
  )
}

ProfilePage.propTypes = {
  onLogout: PropTypes.func,
  UpdateProfile: PropTypes.func,
  Logout: PropTypes.func,
  handleChangeProfile: PropTypes.func,
  updateProfilePic: PropTypes.func,
  userData: PropTypes.shape({
    sUsername: PropTypes.string,
    nLoyaltyPoints: PropTypes.number
  }),
  intl: PropTypes.object,
  location: PropTypes.shape({
    state: PropTypes.shape({
      activeTab: PropTypes.number,
      tab: PropTypes.string,
      message: PropTypes.string
    }),
    search: PropTypes.string
  }),
  profileData: PropTypes.shape({
    sReferCode: PropTypes.string,
    sReferLink: PropTypes.string,
    sProPic: PropTypes.string,
    nLoyaltyPoints: PropTypes.number,
    sName: PropTypes.string,
    sUsername: PropTypes.string
  }),
  profileMessage: PropTypes.string,
  modalMessage: PropTypes.bool,
  url: PropTypes.string,
  profileModelMessage: PropTypes.string,
  profileStatus: PropTypes.bool,
  loading: PropTypes.bool,
  addedDeposit: PropTypes.bool,
  updatedProfilePic: PropTypes.bool,
  currencyLogo: PropTypes.string,
  userInformation: PropTypes.shape({
    nCurrentBonus: PropTypes.number,
    nLeagueCreatorCom: PropTypes.number,
    nCurrentDepositBalance: PropTypes.number,
    nCurrentTotalBalance: PropTypes.number,
    nWinnings: PropTypes.number,
    nTotalMatch: PropTypes.number,
    nTotalJoinLeague: PropTypes.number,
    nCurrentWinningBalance: PropTypes.number
  }),
  statisticsData: PropTypes.shape({
    nTotalJoinLeague: PropTypes.number,
    nTotalMatch: PropTypes.number,
    nTotalWinnings: PropTypes.number
  }),
  history: PropTypes.object,
  setModalMessage: PropTypes.func
}

export default injectIntl(LoginPage(ProfilePage))
