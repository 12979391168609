import React, { lazy, Suspense } from 'react'
import LoginHeader from '../../components/loginHeader'
import Loading from '../../../../component/Loading'
const ValidateDOBForm = lazy(() => import('./ValidateDOB'))

function ValidateDOB (props) {
  return (
    <div className="auth-box">
      <LoginHeader backURL='/signup-validate-cpf'/>
      <Suspense fallback={<Loading />}>
        <ValidateDOBForm {...props}/>
      </Suspense>
    </div>
  )
}

export default ValidateDOB
