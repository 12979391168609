import React from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import Welcome from '../views/Auth/Welcome'
import NotFound from '../component/NotFound'
import Maintenance from '../component/Maintenance'
import Login from '../views/Auth/Login/index'
import SignUp from '../views/Auth/SignUp/index'
import ConfirmPassword from '../views/Auth/ConfirmPassword/index'
import ForgotPassword from '../views/Auth/ForgotPassword/index'
import Verification from '../views/Auth/Verification/index'
import Home from '../views/Home/Home/index'
// import LiveStream from '../views/Home/LiveStream/index'
import Matches from '../views/Home/Matches/index'
import Leagues from '../views/Home/Leagues/index'
import MatchTips from '../views/Cms/Tips/index'
// import CreateTeam from '../views/Home/CreateTeam/index'
import CreateTeam from '../views/Home/CreateTeam'
import Profile from '../views/User/Profile'
import Preference from '../views/User/Preference'
import ProfileInfo from '../views/User/ProfileInfo'
import Verified from '../views/User/Verified/index'
import ChangeProfile from '../views/User/ChangeProfile/index'
import Transactions from '../views/User/Transactions'
import Deposit from '../views/User/Deposit'
import Withdraw from '../views/User/Withdraw'
// import BankDetails from '../views/User/BankDetails'
import Leaderboard from '../views/User/Leaderboard'
import ChangePassword from '../views/User/ChangePassword'
// import KycBankVerification from '../views/User/KycBankVerification'
import More from '../views/Cms/More'
// import ScoreCard from '../views/Home/ScoreCard/index'
import Offers from '../views/Cms/Offers'
import LeaguesDetail from '../views/Home/LeaguesDetail'
import Chats from '../views/Home/Chats'
import JoinContest from '../views/Home/JoinContest'
import CreateContest from '../views/Home/CreateContest'
import PointSystem from '../views/Cms/PointSystem'
import Complaints from '../views/Cms/Complaints/index'
import ComplaintDetail from '../views/Cms/ComplaintDetail/index'
import ContactUs from '../views/Cms/ContactUs/index'
import Notification from '../views/Home/Notification'
import LeaguesCompletedPage from '../views/Home/LeaguesCompleted/index'
import LeaguesDetailCompleted from '../views/Home/LeaguesDetailCompleted'
import TeamCompare from '../views/Home/TeamCompare/index'
import TeamPreview from '../views/Home/TeamPreview/index'
import MyTeamsPreview from '../views/Home/MyTeamsPreview/index'
import ViewPlayerLeagueInfo from '../views/Home/TeamPlayerLeagueInfo/index'
import ViewPlayerInfo from '../views/Home/TeamPlayerInfo/index'
import OfferDetail from '../views/Cms/OfferDetail'
import CmsContent from '../views/Cms/CmsContent'
import Page from '../views/Cms/Page'
import ShareContest from '../views/Home/ShareContest'

import PublicRoutes from './PublicRoutes'
import PrivateRoutes from './PrivateRoutes'
import GameLeaderboard from '../views/Home/GameLeaderboard'
import GameLeaderboardDetail from '../views/Home/GameLeaderboardDetail'
import MessageLogin from '../views/Auth/MessageScreen/index'
import PrizeBreakups from '../views/Home/CreateContest/PrizeBreakup'
import ValidateCPF from '../views/Auth/SignUp/ValidateCPF'
import ValidateDOB from '../views/Auth/SignUp/ValidateDOB'
import Payment from '../views/User/Deposit/Payment'
// import TournamentIndex from '../views/Home/Tournament'

const Routes = () => {
  return (
    <Switch>
      <PublicRoutes component={Welcome} exact path="/" />
      <PublicRoutes component={Login} exact path="/login" />
      <PublicRoutes component={ValidateCPF} exact path="/signup-validate-cpf" />
      <PublicRoutes component={ValidateDOB} exact path="/signup-validate-dob" />
      <PublicRoutes component={SignUp} exact path="/sign-up" />
      <PublicRoutes component={ConfirmPassword} exact path="/confirm-password" />
      <PublicRoutes component={ForgotPassword} exact path="/forgot-password" />
      <PublicRoutes component={Verification} exact path="/verification" />
      {/* Home module */}
      <PrivateRoutes component={Home} exact path="/home/:sportsType" />
      <PublicRoutes component={Home} exact path="/home/:sportsType/v1" />
      {/* <PrivateRoutes path="/tournaments/:sportsType" component={TournamentIndex} exact />
        <PublicRoutes path="/tournaments/:sportsType/v1" component={TournamentIndex} exact /> */}
      {/* <PrivateRoutes path="/live-stream/:type" component={LiveStream} exact /> */}
      <PrivateRoutes component={Matches} exact path="/matches/:sportsType" />
      <PublicRoutes component={MessageLogin} exact path="/matches/:sportsType/v1" />
      <PrivateRoutes component={GameLeaderboard} exact path="/game/leaderboard" />
      <PrivateRoutes component={GameLeaderboardDetail} exact path="/game/leaderboard/:id" />
      <PrivateRoutes component={GameLeaderboardDetail} exact path="/game/leaderboard/details/:detailsId" />
      <PublicRoutes component={GameLeaderboard} exact path="/game/leader-board/v1" />
      <PublicRoutes component={GameLeaderboardDetail} exact path="/game/leaderboard/:id/v1" />
      <PublicRoutes component={GameLeaderboardDetail} exact path="/game/leaderboard/details/:detailsId/v1" />
      <PrivateRoutes component={Leagues} exact path="/leagues/:sportsType/:id" />
      <PrivateRoutes component={Leagues} exact path="/tournament/leagues/:sportsType/:id" />
      <PublicRoutes component={MessageLogin} exact path="/leagues/:sportsType/:id/v1" />
      <PrivateRoutes component={LeaguesCompletedPage} exact path="/completedleagues/participated/:id/:sportsType" />
      <PrivateRoutes component={LeaguesCompletedPage} exact path="/tournament/completedleagues/participated/:id/:sportsType" />
      <PrivateRoutes component={LeaguesCompletedPage} exact path="/liveleague/:id/:sportsType" />
      <PrivateRoutes component={LeaguesCompletedPage} exact path="/tournament/liveleague/:id/:sportsType" />
      <PrivateRoutes component={LeaguesDetail} exact path="/leagues-detail/:sportsType/:id/:id2" />
      <PrivateRoutes component={LeaguesDetail} exact path="/tournament/leagues-detail/:sportsType/:id/:id2" />
      <PrivateRoutes component={LeaguesDetailCompleted} exact path="/leagues-details/:sportsType/:id/:id2/participated" />
      <PrivateRoutes component={LeaguesDetailCompleted} exact path="/tournament/leagues-details/:sportsType/:id/:id2/participated" />
      <PrivateRoutes component={CreateTeam} exact path="/create-team/:sportsType/:id" />
      <PrivateRoutes component={CreateTeam} exact path="/create-team/:sportsType/:id/modal" />
      <PrivateRoutes component={CreateTeam} exact path="/tournament/create-team/:sportsType/:id" />
      <PrivateRoutes component={CreateTeam} exact path="/create-team/:sportsType/:id/join/:joinLeague" />
      <PrivateRoutes component={CreateTeam} exact path="/tournament/create-team/:sportsType/:id/join/:joinLeague" />
      <PrivateRoutes component={CreateTeam} exact path="/create-team/:sportsType/:id/join/:joinLeague/private/:sShareCode" />
      <PrivateRoutes component={CreateTeam} exact path="/tournament/create-team/:sportsType/:id/join/:joinLeague/private/:sShareCode" />
      <PrivateRoutes component={CreateTeam} exact path="/create-team/:sportsType/:id/:id2" />
      <PrivateRoutes component={CreateTeam} exact path="/tournament/create-team/:sportsType/:id/:id2" />
      <PrivateRoutes component={CreateTeam} exact path="/copy-team/:sportsType/:id/:id2/:content" />
      <PrivateRoutes component={CreateTeam} exact path="/tournament/copy-team/:sportsType/:id/:id2/:content" />
      <PrivateRoutes component={Chats} exact path="/chats" />
      {/* <Route path="/score-card/:id" component={ScoreCard} exact /> */}
      <PrivateRoutes component={JoinContest} exact path="/join-contest/:sportsType/:id" />
      <PrivateRoutes component={JoinContest} exact path="/tournament/join-contest/:sportsType/:id" />
      <PrivateRoutes component={JoinContest} exact path="/join-contest" />
      <PrivateRoutes component={CreateContest} exact path="/create-contest/:sportsType/:id" />
      <PrivateRoutes component={CreateContest} exact path="/tournament/create-contest/:sportsType/:id" />
      <PrivateRoutes component={PrizeBreakups} exact path="/create-contest/:sportsType/:id/prize-breakups" />
      <PrivateRoutes component={PrizeBreakups} exact path="/tournament/create-contest/:sportsType/:id/prize-breakups" />
      <PrivateRoutes component={ShareContest} exact path="/create-contest/:sportsType/:id/:id2/invite" />
      <PrivateRoutes component={ShareContest} exact path="/tournament/create-contest/:sportsType/:id/:id2/invite" />
      <PrivateRoutes component={Notification} exact path="/notifications" />
      <PrivateRoutes component={TeamCompare} exact path="/team-compare/:sportsType/:id1/:id2" />
      <PrivateRoutes component={TeamCompare} exact path="/tournament/team-compare/:sportsType/:id1/:id2" />
      <PrivateRoutes component={MyTeamsPreview} exact path="/my-teams-preview/:sportsType/:matchID/:userTeamID" />
      <PrivateRoutes component={MyTeamsPreview} exact path="/tournament/my-teams-preview/:sportsType/:matchID/:userTeamID" />
      <PrivateRoutes component={MyTeamsPreview} exact path="/create-team-preview/:sportsType/:matchID" />
      <PrivateRoutes component={MyTeamsPreview} exact path="/tournament/create-team-preview/:sportsType/:matchID" />
      <PrivateRoutes component={TeamPreview} exact path="/team-preview/:sportsType/:matchID/:userLeagueID/:userTeamID/:index" />
      <PrivateRoutes component={TeamPreview} exact path="/tournament/team-preview/:sportsType/:matchID/:userLeagueID/:userTeamID/:index" />
      <PrivateRoutes component={ViewPlayerLeagueInfo} exact path="/view-player-leagueInfo/:sportsType/:matchID/:userTeamID/:playerId" />
      <PrivateRoutes component={ViewPlayerLeagueInfo} exact path="/tournament/view-player-leagueInfo/:sportsType/:matchID/:userTeamID/:playerId" />
      <PrivateRoutes component={ViewPlayerLeagueInfo} exact path="/create-team/view-player-league-info/:sportsType/:matchID/:playerId" />
      <PrivateRoutes component={ViewPlayerLeagueInfo} exact path="/tournament/create-team/view-player-league-info/:sportsType/:matchID/:playerId" />
      <PrivateRoutes component={ViewPlayerInfo} exact path="/view-player-Info/:sportsType/:matchID/:userTeamID/:playerId" />
      <PrivateRoutes component={ViewPlayerInfo} exact path="/tournament/view-player-Info/:sportsType/:matchID/:userTeamID/:playerId" />
      <PrivateRoutes component={ViewPlayerInfo} exact path="/create-team/view-player-info/:sportsType/:matchID/:playerId" />
      <PrivateRoutes component={ViewPlayerInfo} exact path="/tournament/create-team/view-player-info/:sportsType/:matchID/:playerId" />
      <PrivateRoutes component={ViewPlayerInfo} exact path="/view-playerstat-info/:sportsType/:matchID/:playerId" />
      <PrivateRoutes component={ViewPlayerInfo} exact path="/tournament/view-playerstat-info/:sportsType/:matchID/:playerId" />
      {/* user module */}
      <PrivateRoutes component={Profile} exact path="/profile" />
      <PublicRoutes component={MessageLogin} exact path="/profile/v1" />
      <PrivateRoutes component={Preference} exact path="/preference" />
      <PrivateRoutes component={ProfileInfo} exact path="/profile-info" />
      <PrivateRoutes component={Verified} exact path="/verification/Email" />
      <PrivateRoutes component={Verified} exact path="/verification/Phone" />
      <PrivateRoutes component={ChangeProfile} exact path="/change/:type" />
      <PrivateRoutes component={Transactions} exact path="/transactions" />
      <PrivateRoutes component={Deposit} exact path="/deposit" />
      <PrivateRoutes component={Payment} exact path="/payment" />
      <PrivateRoutes component={Withdraw} exact path="/withdraw" />
      {/* <PrivateRoutes path="/bankdetails" component={BankDetails} exact /> */}
      <PrivateRoutes component={Leaderboard} exact path="/leaderboard" />
      <PrivateRoutes component={ChangePassword} exact path="/change-password" />
      {/* <PrivateRoutes path="/kyc-bank-verification" component={KycBankVerification} exact /> */}
      {/* cms module */}
      <PrivateRoutes component={More} exact path="/more" />
      <PublicRoutes component={More} exact path="/more/v1" />
      <PrivateRoutes component={CmsContent} exact path="/more/:slug" />
      <PublicRoutes component={CmsContent} exact path="/more/:slug/v1" />
      <PrivateRoutes component={MatchTips} exact path="/tips/:slug" />
      <PrivateRoutes component={Page} exact path="/page" />
      <PrivateRoutes component={Offers} exact path="/offers" />
      <PrivateRoutes component={OfferDetail} exact path="/offer/:id" />
      <PublicRoutes component={Offers} exact path="/offers/v1" />
      <PublicRoutes component={OfferDetail} exact path="/offer/:id/v1" />
      <PrivateRoutes component={PointSystem} exact path="/point-system" />
      <PublicRoutes component={PointSystem} exact path="/point-system/v1" />
      <PrivateRoutes component={Complaints} exact path="/complaints" />
      <PrivateRoutes component={ComplaintDetail} exact path="/complaints/:id" />
      <PrivateRoutes component={ContactUs} exact path="/contact-us" />
      <PublicRoutes component={CmsContent} exact path="/more-details/:slug" />
      <Route component={Maintenance} exact path="/maintenance-mode" />
      {/* <PrivateRoutes path="/privacy-policy" component={PrivacyPolicy} exact /> */}
      <Redirect exact path="/home" to="/home/cricket"/>
      <Route component={NotFound} path="*" />
    </Switch>
  )
}

export default Routes
