import React from 'react'
import { FormattedMessage } from 'react-intl'
import UserHeader from '../components/UserHeader'
import ChangePasswordPage from './ChangePassword'
function ChangePassword (props) {
  return (
    <>
      <UserHeader {...props} title={<FormattedMessage id='User_changePassword' />} backURL="/profile"/>
      <ChangePasswordPage {...props} />
    </>
  )
}

export default ChangePassword
