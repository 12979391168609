import axios from '../../axios/instanceAxios'
import {
  TRANSACTION_LIST,
  CLEAR_TRANSACTION_LIST,
  CANCEL_WITHDRAW
} from "../../constants";
import { catchBlankData } from '../../helper';

const errMsg = 'Server is unavailable.'

export const getTransactionList = (limit, offset, Type, startDate, lastDate,  token) => (dispatch) => { // transaction list
  axios.get(`/passbook/list/v1?eType=${Type}&nLimit=${limit}&nOffset=${offset}&dStartDate=${startDate}&dEndDate=${lastDate}`,  { headers: { Authorization: token } }).then((response) => {
    dispatch({
      type: TRANSACTION_LIST,
      payload: { 
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(TRANSACTION_LIST))
  })
}

// cancel withdraw
export const CancelWithdraw = (ID, token) => (dispatch) => {
  dispatch({ type: CLEAR_TRANSACTION_LIST })
  axios.get(`/withdraw/cancel/${ID}/v2`, { headers: { Authorization: token } }).then((response) => {
    dispatch({
      type: CANCEL_WITHDRAW,
      payload: { 
        resMessage: response.data.message,
        resStatus: true,
        cancelWithdraw: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(CANCEL_WITHDRAW))
  })
}