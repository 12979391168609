import React from 'react'
import LoginHeader from '../components/loginHeader'
import LoginForm from './Login'
function Login (props) {
  return (
    <div className="auth-box">
      <LoginHeader />
      <LoginForm {...props}/>
    </div>
  )
}

export default Login
