import React, { useState, useEffect, useRef } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Collapse, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import SkeletonPlayerStatus from '../../../component/SkeletonPlayerStatus'
import PlayerImage from '../../../assests/images/User2.png'
import filter from '../../../assests/images/playerStatFilter.svg'
import search from '../../../assests/images/searchIconPlayerStat.svg'
import rightArrow from '../../../assests/images/rightArrowBlue.svg'
import downArrow from '../../../assests/images/downArrowBlue.svg'
import filterClose from '../../../assests/images/playerFilterClose.svg'
import versus from '../../../assests/images/versus.svg'
import qs from 'query-string'
import { useQueryState } from 'react-router-use-location-state'
import { roleFullForm, rolesShortForm } from '../../../helper'
import noDataFound from '../../../assests/images/ic_no_data_found.svg'
const classNames = require('classnames')

function PlayerStats (props) {
  const { match, url, history, completed, matchPlayerList, matchDetails, playerRoles } = props
  const [loading, setLoading] = useState(false)
  const [SortingFrom] = useState('points')
  const [sorted] = useState(false)
  const [listed, setListed] = useState([])
  const [playerName, setPlayerName] = useQueryState('playerName', '')
  const [isOpenPlayerData, setIsOpenPlayerData] = useState(false)
  const [playerFilterSlide, setPlayerFilterSlide] = useState(false)
  const [playerId, setPlayerId] = useState(0)
  const [playerRolesFilter, setPlayerRolesFilter] = useState([])
  const [teamsFilter, setTeamsFilter] = useState([])
  const tournamentScoreHeight = document.querySelector('.tournament-score')
  const scoreHeight = document.querySelector('.scorecard')
  const tournamentScoreDivHeight = tournamentScoreHeight?.clientHeight
  const scoreDivHeight = scoreHeight?.clientHeight
  const obj = qs.parse(location.search)
  const previousProps = useRef({
    matchPlayerList, playerName
  }).current

  useEffect(() => {
    if (previousProps.matchPlayerList !== matchPlayerList) {
      setLoading(false)
      if (matchPlayerList?.length > 0) {
        if (SortingFrom === 'points') {
          let list
          if (sorted) {
            list = matchPlayerList && matchPlayerList.length > 0 && matchPlayerList.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => Number(a.nScoredPoints) > Number(b.nScoredPoints) ? 1 : -1)
          } else {
            list = matchPlayerList && matchPlayerList.length > 0 && matchPlayerList.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => Number(a.nScoredPoints) > Number(b.nScoredPoints) ? -1 : 1)
          }
          const filterList = obj?.playerName ? list?.filter(data => (data?.sName)?.toLowerCase().includes(obj?.playerName?.toLowerCase())) : list
          setListed(filterList)
        }
      }
    }
    return () => {
      previousProps.matchPlayerList = matchPlayerList
    }
  }, [matchPlayerList])

  useEffect(() => {
    const callSearchService = () => {
      const list = matchPlayerList && matchPlayerList.length > 0 && matchPlayerList.filter(data => {
        return (data?.sName)?.toLowerCase().includes(playerName?.toLowerCase())
      })
      setListed(list)
    }
    if (previousProps?.playerName !== playerName) {
      const debouncer = setTimeout(() => {
        callSearchService()
      }, 500)
      return () => {
        clearTimeout(debouncer)
        previousProps.playerName = playerName
      }
    }
    return () => {
      previousProps.playerName = playerName
    }
  }, [playerName])

  function handleToggle (e, player) {
    if (playerId === player?._id) {
      setPlayerId(0)
    } else {
      setPlayerId(player?._id)
      setIsOpenPlayerData(true)
    }
  }

  function handleChange (e, type, value) {
    if (type === 'T') {
      setTeamsFilter(prevState => {
        if (prevState.includes(value)) {
          return prevState.filter(data => data !== value)
        }
        return [...prevState, value]
      })
    } else if (type === 'R') {
      setPlayerRolesFilter(prevState => {
        if (prevState.includes(value)) {
          return prevState.filter(data => data !== value)
        }
        return [...prevState, value]
      })
    }
  }

  function handleFilterChange () {
    let filterList
    const list = [...matchPlayerList]
    if (teamsFilter?.length > 0 && playerRolesFilter?.length > 0) {
      filterList = list?.filter(data => teamsFilter.includes(data?.oTeam?._id))?.filter(data => playerRolesFilter.includes(data?.eRole))
    } else if (teamsFilter?.length === 0 && playerRolesFilter?.length > 0) {
      filterList = list.filter(data => playerRolesFilter.includes(data?.eRole))
    } else if (teamsFilter?.length > 0 && playerRolesFilter?.length === 0) {
      filterList = list.filter(data => teamsFilter.includes(data?.oTeam?._id))
    } else {
      filterList = list
    }
    setListed(filterList)
    setPlayerFilterSlide(false)
  }

  function clearFilters () {
    setTeamsFilter([])
    setPlayerRolesFilter([])
    setListed(matchPlayerList && matchPlayerList.length > 0 && matchPlayerList.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => Number(a.nScoredPoints) > Number(b.nScoredPoints) ? -1 : 1))
    setPlayerFilterSlide(false)
  }

  return (
    <>
      { loading
        ? <SkeletonPlayerStatus numberOfColumns={10} />
        : (
          <div className='playerStates'>
            <Form className='player-stat-form'>
              <FormGroup className="c-input d-flex justify-content-start player-search m-0">
                <div className="fake-input px-2" >
                  <Input autoComplete='off' className={classNames(playerName ? 'hash-contain' : ' ')} id="PlayerName" maxLength={100} name="playerName" onChange={(event) => setPlayerName(event.target.value)} type="text" value={playerName} />
                  <img className='search' src={search} />
                  <Label className="no-change label m-0" for="PlayerName"><FormattedMessage id="Search_player" /></Label>
                </div>
                <div><img onClick={() => setPlayerFilterSlide(true)} src={filter} /></div>
              </FormGroup>
            </Form>

            <div className='player-container'>
              {listed?.length > 0
                ? listed.map((data, index) => {
                  const playerName = data?.sName?.split(' ')
                  return (
                    <Row key={data._id} className='player-row'>
                      <Col className="d-flex align-items-center justify-content-between" xs={8}>
                        <div
                          className='d-flex justify-content-start align-items-center'
                          onClick={() =>
                            history.push({
                              pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/view-playerstat-info/${match.params.sportsType}/${match.params.id}/${data._id}` : `/view-playerstat-info/${match.params.sportsType}/${match.params.id}/${data._id}`,
                              search: `?${qs.stringify({
                              sortBy: SortingFrom || undefined,
                              // sort: !sorted || undefined,
                              playerStat: true || undefined
                            })}`,
                              state: { playerStat: true }
                            })}
                        >
                          <div className="p-img"><img alt="" src={data && data.sImage && url ? `${url}${data.sImage}` : PlayerImage} /></div>
                          <div>
                            <div className="p-name">
                              {data?.sName?.indexOf(' ') >= 0 ? playerName[0][0] + ' ' + playerName[playerName.length - 1] : playerName}
                            </div>
                            <div className="c-name">
                              {data?.oTeam?.sName}
                              {' '}
                              -
                              {' '}
                              <span className="role">{rolesShortForm(data?.eRole)}</span>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col className='d-flex justify-content-end align-items-center' xs={4}>
                        <div className='d-flex'>
                          {completed && (
                          <div className='d-flex justify-content-center'
                            onClick={() =>
                              history.push({
                                pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/view-playerstat-info/${match.params.sportsType}/${match.params.id}/${data._id}` : `/view-playerstat-info/${match.params.sportsType}/${match.params.id}/${data._id}`,
                                search: `?${qs.stringify({
                              sortBy: SortingFrom || undefined,
                              // sort: !sorted || undefined,
                              playerStat: true || undefined
                            })}`,
                                state: { playerStat: true }
                              })}
                          >
                            <button className='points-btn'>
                              {data?.nScoredPoints && parseFloat(Number((data?.nScoredPoints)).toFixed(2))}
                              {'  '}
                              <FormattedMessage id='Team_Pts' />
                            </button>
                          </div>
                          )}
                          <div className='d-flex align-items-center' onClick={(e) => handleToggle(e, data)}>
                            <img src={(isOpenPlayerData && playerId === data?._id) ? downArrow : rightArrow} />
                          </div>
                        </div>
                      </Col>
                      <Collapse className='col-12 player-data-container' isOpen={isOpenPlayerData && playerId === data?._id}>
                        <div className='player-data d-flex justify-content-between align-items-center'>
                          <div className='heading-text'><FormattedMessage id='Player_selectedBy' /></div>
                          <div className='data'>
                            {data?.nSetBy && parseFloat(Number((data.nSetBy)).toFixed(2))}
                            %
                          </div>
                        </div>
                        <div className='player-data d-flex justify-content-between align-items-center'>
                          <div className='heading-text'>%C</div>
                          <div className='data'>
                            {data?.nCaptainBy}
                            %
                          </div>
                        </div>
                        <div className='player-data d-flex justify-content-between align-items-center'>
                          <div className='heading-text'>%VC</div>
                          <div className='data'>
                            {data.nViceCaptainBy}
                            %
                          </div>
                        </div>
                      </Collapse>
                    </Row>
                  )
                })
                : (
                  <div className='blank-page d-flex justify-content-center align-items-center'
                    style={{ height: `calc(100vh - ${tournamentScoreDivHeight || scoreDivHeight}px - 190px` }}
                  >
                    <div>
                      <img src={noDataFound} />
                      <p className='empty-page'><FormattedMessage id='Players_not_found'/></p>
                    </div>
                  </div>
                  )
              }
            </div>
          </div>
          )}

      {playerFilterSlide && (
      <div className="s-team-bg">
        <Card className='filter-card' >
          <CardHeader className='d-flex align-items-center justify-content-between'>
            <button><FormattedMessage id='League_filterBy' /></button>
            <img onClick={() => setPlayerFilterSlide(false)} src={filterClose} />
          </CardHeader>
          <CardBody className='payment-box'>
            <div className='heading'><FormattedMessage id='Teams' /></div>
            <div className='filter-match-box'>
              {matchDetails?.aMatch?.length > 0
                ? matchDetails?.aMatch?.map((data, index) => (
                  <div key={index}
                    className='d-flex justify-content-around filter-matches row my-3 mx-0'
                  >
                    <div
                      className={classNames({ active: teamsFilter.includes(data?.oHomeTeam?.iTeamId) }, 'col-5 team d-flex justify-content-end align-items-center')}
                      onClick={(e) => handleChange(e, 'T', data?.oHomeTeam?.iTeamId)}
                    >
                      <div className={classNames({ active: teamsFilter.includes(data?.oHomeTeam?.iTeamId) }, 'team-name mr-2')}>{data?.oHomeTeam?.sName}</div>
                      <img src={url + data?.oHomeTeam?.sImage} />
                    </div>
                    <div className='col-2 vs d-flex align-items-center justify-content-center'><img src={versus} /></div>
                    <div
                      className={classNames({ active: teamsFilter.includes(data?.oAwayTeam?.iTeamId) }, 'col-5 team d-flex justify-content-start align-items-center')}
                      onClick={(e) => handleChange(e, 'T', data?.oAwayTeam?.iTeamId)}
                    >
                      <img src={url + data?.oAwayTeam?.sImage} />
                      <div className={classNames({ active: teamsFilter.includes(data?.oAwayTeam?.iTeamId) }, 'team-name ml-2')}>{data?.oAwayTeam?.sName}</div>
                    </div>
                  </div>
                )
                )
                : (
                  <div className='d-flex justify-content-around filter-matches row my-3 mx-0'>
                    <div
                      className={classNames({ active: teamsFilter.includes(matchDetails?.oHomeTeam?.iTeamId) }, 'col-5 team d-flex justify-content-end align-items-center')}
                      onClick={(e) => handleChange(e, 'T', matchDetails?.oHomeTeam?.iTeamId)}
                    >
                      <div className={classNames({ active: teamsFilter.includes(matchDetails?.oHomeTeam?.iTeamId) }, 'team-name mr-2')}>{matchDetails?.oHomeTeam?.sName}</div>
                      <img src={url + matchDetails?.oHomeTeam?.sImage} />
                    </div>
                    <div className='col-2 vs d-flex align-items-center justify-content-center'><img src={versus} /></div>
                    <div
                      className={classNames({ active: teamsFilter.includes(matchDetails?.oAwayTeam?.iTeamId) }, 'col-5 team d-flex justify-content-start align-items-center')}
                      onClick={(e) => handleChange(e, 'T', matchDetails?.oAwayTeam?.iTeamId)}
                    >
                      <img src={url + matchDetails?.oHomeTeam?.sImage} />
                      <div className={classNames({ active: teamsFilter.includes(matchDetails?.oAwayTeam?.iTeamId) }, 'team-name ml-2')}>{matchDetails?.oAwayTeam?.sName}</div>
                    </div>
                  </div>
                  )
            }
            </div>
            <div className='player-roles-div'>
              <div className='filter-heading'><FormattedMessage id='Position' /></div>
              {playerRoles?.map((data, index) => (
                <div key={data?._id + index} className='role-row d-flex justify-content-between'>
                  <FormGroup className='player-role-form-check'>
                    <Label className='role-check-label'>{roleFullForm(data?.sName)}</Label>
                    <Input checked={playerRolesFilter.includes(data?.sName)} className='custom-check-box' onChange={(e) => handleChange(e, 'R', data?.sName)} type="checkbox" />
                  </FormGroup>
                </div>
              )
              )}
            </div>
          </CardBody>
          <Button color='player-filter-btn' onClick={(e) => handleFilterChange(e)}><FormattedMessage id="Confirm" /></Button>
          <div className='clear-player-filter' onClick={() => clearFilters()}><FormattedMessage id='Clear_Filters' /></div>
        </Card>
      </div>
      )}
    </>
  )
}

PlayerStats.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      sportsType: PropTypes.string
    })
  }),
  matchPlayerList: PropTypes.array,
  completed: PropTypes.bool,
  url: PropTypes.string,
  history: PropTypes.object,
  matchDetails: PropTypes.object,
  playerRoles: PropTypes.array
}

export default PlayerStats
