import React, { Fragment } from 'react'
import Match from './Match'
import { FormattedMessage } from 'react-intl'
// import ReactPullToRefresh from 'react-pull-to-refresh'
// import PullToRefresh from 'react-simple-pull-to-refresh'
import SkeletonUpcoming from '../../../component/SkeletonUpcoming'
import SportsLeagueList from 'Common/src/components/SportsLeagueList/SportsLeagueList'
import PropTypes from 'prop-types'
import NoDataFound from '../../../assests/images/ic_no_data_found.svg'
import Tournament from './Tournament'

function MyUpcomingMatch (props) {
  const {
    list, loading,
    // FetchingList, mainIndex,
    subIndex, tournamentInfoList
  } = props
  // function handleRefresh () {
  //   FetchingList(mainIndex, subIndex)
  // }
  return (
  // <PullToRefresh
  //   onRefresh={handleRefresh}
  //   pullDownThreshold={15}
  //   refreshingContent={true}
  // >
      <div>
        {
          loading
            ? <SkeletonUpcoming numberOfColumns={5} />
            : (
              <Fragment>
                {list && list.length !== 0 && list.sort(function (a, b) {
                  if (subIndex === 3 || subIndex === 2) {
                    return new Date(b.dStartDate) - new Date(a.dStartDate)
                  } else {
                    return new Date(a.dStartDate) - new Date(b.dStartDate)
                  }
                }).map((data, i) => {
                  return (
                    data.eType === 'MATCH'
                      ? <Match {...props} data={data} key={i} live={subIndex === 2} completed={subIndex === 3}/>
                      : <Tournament {...props} data={data} key={data._id} subIndex={subIndex} upcoming={subIndex === 1} tournamentInfoList={tournamentInfoList} live={subIndex === 2} completed={subIndex === 3} />
                  )
                })}
                {
                  list && !list.length && (
                  // <div className="text-center">
                  //   <h3 className='mt-5'>
                  //     <FormattedMessage id="Home_No_Match" />
                  //   </h3>
                  // </div>
                  <div className="no-team d-flex align-items-center justify-content-center fixing-width">
                    <div className="">
                      {/* <i className="icon-trophy"></i> */}
                      <img src={NoDataFound}></img>
                      <h6>
                        <FormattedMessage id="Home_No_Match" />
                      </h6>
                    </div>
                  </div>
                  )
                }
              </Fragment>
              )
        }
      </div>
  //  </PullToRefresh>
  )
}

MyUpcomingMatch.propTypes = {
  list: PropTypes.array,
  points: PropTypes.string,
  teamDetails: PropTypes.object,
  mainIndex: PropTypes.number,
  subIndex: PropTypes.number,
  loading: PropTypes.bool,
  FetchingList: PropTypes.func,
  tournamentInfoList: PropTypes.object
}

export default SportsLeagueList(MyUpcomingMatch, 'cricket', 'Upcoming')
