import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import { Card } from 'reactstrap'

function SkeletonLeague (props) {
  const { leagueDetails, length } = props
  return (
    <Fragment>{
      leagueDetails
        ? <Fragment>
        {Array(length)
          .fill()
          .map((index, i) => (
            <Card key={i} className='mt-1 ml-2 mr-2 leagues-card'>
              <div className="match-box mr-3" key={index}>
                <div className='d-flex justify-content-between my-2'>
                  <Skeleton height={20} width={150} />
                  <Skeleton height={20} width={150} />
                </div>
                <div className='d-flex justify-content-between my-3'>
                  <Skeleton height={20} width={200} />
                  <Skeleton height={20} width={100} />
                </div>
                <center>
                  <Skeleton className='mt-2' height={10} width={390} />
                </center>
                <div className='d-flex justify-content-between my-3'>
                  <Skeleton height={20} width={100} />
                  <Skeleton height={20} width={80} />
                </div>
                <div className='d-flex justify-content-between my-3'>
                  <div>
                    <Skeleton height={20} width={20} className='mr-2' />
                    <Skeleton height={20} width={20} className='mr-2' />
                    <Skeleton height={20} width={20} />
                  </div>
                  <Skeleton height={20} width={50} />
                </div>
                <div className='d-flex justify-content-between my-3'>
                  <Skeleton height={20} width={40} className='mr-2' />
                  <Skeleton height={20} width={40} className='mr-2' />
                  <Skeleton height={20} width={40} className='mr-2' />
                  <Skeleton height={20} width={40} className='mr-2' />
                  <Skeleton height={20} width={40} />
                </div>
              </div>
            </Card>
          ))}
      </Fragment>
        : <Fragment>
        {Array(3)
          .fill()
          .map((index, i) => (
            <Fragment key={i}>
              <Card className='mt-3 ml-2 mr-2 leagues-card justify-content-between'>
                <div>
                  <Skeleton className='mb-2 ml-2' circle={true} height={20} width={20} />
                  <Skeleton className='mb-2 ml-2 mt-2' height={20} width={300}/>
                </div>
              </Card>
              {
                Array(2)
                  .fill()
                  .map((index, i) => (
                    <Card key={i} className='mt-3 ml-2 mr-2 leagues-card'>
                      <div className="match-box mr-3" key={`div+${index}`}>
                        <div className='d-flex justify-content-between'>
                          <Skeleton height={20} width={150} />
                          <Skeleton height={20} width={150} />
                        </div>
                        <div className='d-flex justify-content-between'>
                          <Skeleton height={20} width={200} />
                          <Skeleton height={20} width={100} />
                        </div>
                        <center>
                          <Skeleton className='mt-2' height={10} width={400} />
                        </center>
                        <div className='d-flex justify-content-between'>
                          <div>
                            <Skeleton height={20} width={20} className='mr-2' />
                            <Skeleton height={20} width={20} className='mr-2' />
                            <Skeleton height={20} width={20} />
                          </div>
                          <Skeleton height={20} width={50} />
                        </div>
                      </div>
                    </Card>
                  ))
              }
            </Fragment>
          ))}
      </Fragment>
      }
    </Fragment>
  )
}

SkeletonLeague.propTypes = {
  leagueDetails: PropTypes.bool,
  length: PropTypes.number
}

export default SkeletonLeague
