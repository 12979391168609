const errMsg = 'Server is unavailable.'
// export function getUrl () {
//   const url = 'https://yudiz-fantasy-media.s3.ap-south-1.amazonaws.com'
//   return url
// }

export function catchError(type, error) {
  return {
    type,
    payload: {
      resStatus: false,
      resMessage: error && error.response ? error.response.data.message : errMsg
    }
  }
}

export function catchBlankData(type) {
  return {
    type,
    payload: {
      resStatus: false,
      data: []
    }
  }
}

export function catchBlankDataObj(type) {
  return {
    type,
    payload: {
      resStatus: false,
      data: {}
    }
  }
}
