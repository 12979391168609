/* eslint-disable no-unused-vars */
import React, { useState, Fragment, useEffect, useRef, lazy, Suspense } from 'react'
import { useSelector } from 'react-redux'
import Diamond from '../../../assests/images/diamond.svg'
import DiamondDisable from '../../../assests/images/diamond_disable.svg'
import Multi from '../../../assests/images/multi.svg'
import MultiDisable from '../../../assests/images/multi_disable.svg'
import Copy from '../../../assests/images/copy.svg'
import CopyDisable from '../../../assests/images/copy_disable.svg'
import Loyalty from '../../../assests/images/ic_Loyaly_colored_red.svg'
import LoyaltyDisable from '../../../assests/images/ic_Loyaly_colored_disable.svg'
import Trophy from '../../../assests/images/trophy.svg'
import Medal from '../../../assests/images/medal.svg'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardFooter, CardHeader, Badge, Table, Input, Label, FormGroup, Alert } from 'reactstrap'
import offerIcon from '../../../assests/images/ic_offer.svg'
import close from '../../../assests/images/close.svg'
import JoinContest from 'Common/src/components/SportsLeagueList/JoinContest'
import Loading from '../../../component/Loading'
import PromocodeLoading from '../../../component/PromocodeLoading'
import { viewContest, joinTeam } from '../../../Analytics.js'
import createteam from '../../../assests/images/create-team.svg'
import createContest from '../../../assests/images/create-contest.svg'
import rightGreenArrow from '../../../assests/images/right-green-arrow.svg'
import qs from 'query-string'
import { convertFloat, currencyConverter } from '../../../helper'
const MyTeam = lazy(() => import('./MyTeam'))
const classNames = require('classnames')

function League (props) {
  const {
    data,
    joinContest,
    match,
    teamList,
    contestJoinList,
    participated,
    toggleMessage,
    modalMessage,
    applyPromocodeData,
    onGetUserProfile,
    setModalMessage,
    joined,
    applyPromo,
    userInfo,
    loading,
    matchID,
    tab,
    firstPrize,
    firstRankType,
    getMyTeamList,
    matchType,
    activeTab,
    location,
    appliedPromocode,
    onGetPromocodeList,
    matchPromoCodeList,
    promocodeLoading,
    currencyLogo,
    showInformation,
    amountData,
    MatchLeagueId,
    joinDetails,
    insideleagueDetailsPage,
    setLoading,
    history,
    getMatchPlayersFunc,
    token
  } = props

  const [noOfJoin, setNoOfJoin] = useState('')
  const [userTeams, setUserTeams] = useState([])
  const [promoData, setPromoData] = useState('')
  const [alert, setAlert] = useState(false)
  const [applied, setApplied] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [discount, setDiscount] = useState(0)
  const [finalPromocode, setFinalPromocode] = useState('')
  const [PromoCodes, setPromoCodes] = useState(false)
  const [selectedAll, setSelectedAll] = useState(false)
  const [updatedFilterData, setUpdatedFilterData] = useState([])
  const [IsJoinLeague, setIsJoinLeague] = useState(false)
  const [modalMessage2, setModalMessage2] = useState(false)
  const [totalPay, setTotalPay] = useState(0)
  // const [totalPayFinal, setTotalPayFinal] = useState(0)
  const [fromWallet, setFromWallet] = useState(0)
  const [fromBonus, setFromBonus] = useState(0)
  const [UpdatedTeamList, setUpdatedTeamList] = useState([])
  const matchDetails = useSelector(state => state.match.matchDetails)
  const previousProps = useRef({
    teamList, userInfo, applyPromocodeData, amountData
  }).current
  const obj = qs.parse(location.search)

  useEffect(() => {
    setSelectedAll(false)
  }, [])

  useEffect(() => {
    if (teamList !== previousProps.teamList && IsJoinLeague) {
      if (IsJoinLeague && teamList?.length > 0) {
        data && data._id && joint(data._id)
      }
    }
    return () => {
      previousProps.teamList = teamList
    }
  }, [teamList])

  useEffect(() => {
    if (IsJoinLeague) {
      if (!teamList || (teamList?.length > 0 && data?.iMatchId && teamList[0]?.iMatchId !== data.iMatchId)) {
        getMyTeamList()
      } else {
        data && data._id && joint(data._id)
      }
    }
  }, [IsJoinLeague])

  useEffect(() => {
    // if (userInfo !== previousProps.userInfo) {
    //   if (userInfo) {
    //     const promocodeData = applyPromocodeData && applyPromocodeData.nDiscount ? applyPromocodeData.nDiscount : 0
    //     const updatedFilterDataFinal = updatedFilterData?.length === 0 ? 1 : updatedFilterData?.length
    //     const value = data && updatedFilterData && ((data.nPrice * updatedFilterDataFinal) - promocodeData - (userInfo && userInfo.nCurrentTotalBalance))
    //     setTotalPay(value > 0 ? value.toFixed(2) : 0)
    //     const value2 = (data?.nPrice * updatedFilterDataFinal) - promocodeData
    //     setTotalPayFinal(value2)
    //     setLoading(false)
    //   }
    // }
    if (userInfo && updatedFilterData?.length > 0) {
      const nPromoDiscount = applyPromocodeData?.nDiscount || 0
      const updatedFilterDataFinal = updatedFilterData?.length === 0 ? 1 : updatedFilterData?.length
      let nPrice = data?.nPrice * updatedFilterDataFinal
      nPrice = (nPromoDiscount) ? nPrice - nPromoDiscount : nPrice
      const nBonus = (data?.nBonusUtil * nPrice) / 100
      let value = 0
      let nActualBonus = 0
      let nActualCash = 0
      if (nPrice === 0) {
        setFromWallet(0)
        setFromBonus(0)
      } else if (nPrice > 0 && data?.nBonusUtil > 0) {
        if (userInfo?.nCurrentBonus - nBonus >= 0) {
          nActualBonus = nBonus
          if (userInfo?.nCurrentTotalBalance < nPrice - nBonus) {
            value = nPrice - nBonus - userInfo?.nCurrentTotalBalance
            nActualCash = userInfo?.nCurrentTotalBalance
          } else {
            nActualCash = nPrice - nBonus
          }
        } else {
          nActualBonus = userInfo?.nCurrentBonus
          if (userInfo?.nCurrentTotalBalance < nPrice - userInfo?.nCurrentBonus) {
            value = nPrice - userInfo?.nCurrentBonus - userInfo?.nCurrentTotalBalance
            nActualCash = userInfo?.nCurrentTotalBalance
          } else {
            nActualCash = nPrice - nActualBonus
          }
        }
      } else {
        if (userInfo.nCurrentTotalBalance <= nPrice) {
          value = nPrice - userInfo.nCurrentTotalBalance
          nActualCash = userInfo?.nCurrentTotalBalance
        } else {
          nActualCash = nPrice
        }
      }
      setFromWallet(Math.abs(nActualCash).toFixed(2))
      setFromBonus(Math.abs(nActualBonus).toFixed(2))
      setTotalPay(Math.abs(value).toFixed(2))
    }
    return () => {
      previousProps.userInfo = userInfo
    }
  }, [userInfo, updatedFilterData])

  useEffect(() => {
    if (selectedAll) {
      let updatedSelectedTeam = []
      if (UpdatedTeamList && UpdatedTeamList.length > 0) {
        updatedSelectedTeam = UpdatedTeamList.map(data => data._id)
        setUserTeams(updatedSelectedTeam)
      } else if (teamList && teamList.length > 0) {
        updatedSelectedTeam = teamList.map(data => data._id)
        setUserTeams(updatedSelectedTeam)
      }
    } else {
      setUserTeams([])
    }
  }, [selectedAll])

  useEffect(() => {
    if (applyPromocodeData !== previousProps.applyPromocodeData) {
      if ((applyPromocodeData && applyPromocodeData.nDiscount && appliedPromocode) || !applyPromocodeData) {
        setFinalPromocode(applyPromocodeData && applyPromocodeData.sCode)
        // const nPromoDiscount = applyPromocodeData?.nDiscount || 0
        // setDiscount((nPromoDiscount).toFixed(2))
        // const updatedFilterDataFinal = updatedFilterData?.length === 0 ? 1 : updatedFilterData?.length
        // const value = data && updatedFilterData && ((data.nPrice * updatedFilterDataFinal) - nPromoDiscount - (userInfo && userInfo.nCurrentTotalBalance))
        // setTotalPay(value > 0 ? value.toFixed(2) : 0)
        // const value2 = (data?.nPrice * updatedFilterDataFinal) - nPromoDiscount
        // setTotalPayFinal(value2)
        const nPromoDiscount = applyPromocodeData?.nDiscount || 0
        setDiscount(nPromoDiscount)
        const updatedFilterDataFinal = updatedFilterData?.length === 0 ? 1 : updatedFilterData?.length
        let nPrice = data?.nPrice * updatedFilterDataFinal
        nPrice = (nPromoDiscount) ? nPrice - nPromoDiscount : nPrice
        const nBonus = (data?.nBonusUtil * nPrice) / 100
        let value = 0
        let nActualBonus = 0
        let nActualCash = 0
        if (nPrice === 0) {
          setFromWallet(0)
          setFromBonus(0)
        } else if (nPrice > 0 && data?.nBonusUtil > 0) {
          if (userInfo?.nCurrentBonus - nBonus >= 0) {
            nActualBonus = nBonus
            if (userInfo?.nCurrentTotalBalance < nPrice - nBonus) {
              value = nPrice - nBonus - userInfo?.nCurrentTotalBalance
              nActualCash = userInfo?.nCurrentTotalBalance
            } else {
              nActualCash = nPrice - nBonus
            }
          } else {
            nActualBonus = userInfo?.nCurrentBonus
            if (userInfo?.nCurrentTotalBalance < nPrice - userInfo?.nCurrentBonus) {
              value = nPrice - userInfo?.nCurrentBonus - userInfo?.nCurrentTotalBalance
              nActualCash = userInfo?.nCurrentTotalBalance
            } else {
              nActualCash = nPrice - nActualBonus
            }
          }
        } else {
          if (userInfo.nCurrentTotalBalance <= nPrice) {
            value = nPrice - userInfo.nCurrentTotalBalance
            nActualCash = userInfo?.nCurrentTotalBalance
          } else {
            nActualCash = nPrice
          }
        }
        setFromWallet(Math.abs(nActualCash).toFixed(2))
        setFromBonus(Math.abs(nActualBonus).toFixed(2))
        setTotalPay(Math.abs(value).toFixed(2))
      }
    }
    return () => {
      previousProps.applyPromocodeData = applyPromocodeData
    }
  }, [applyPromocodeData])

  useEffect(() => {
    if (previousProps.appliedPromocode !== appliedPromocode) { // handle the loader
      setApplied(appliedPromocode)
    }
    return () => {
      previousProps.appliedPromocode = appliedPromocode
    }
  }, [appliedPromocode])

  function joint (ID) {
    const contestData = insideleagueDetailsPage
      ? joinDetails
      : contestJoinList?.length > 0 && contestJoinList.find(contest => contest?.iMatchLeagueId === data._id)
    if (contestData) {
      const FilterTeam = contestData && contestData.aUserTeams && contestData.aUserTeams.length > 0
        ? teamList && teamList.length !== 0 && teamList.filter(team => contestData && contestData.aUserTeams && !contestData.aUserTeams.includes(team._id))
        : teamList
      if (FilterTeam && FilterTeam.length !== 0) {
        setUpdatedTeamList(FilterTeam)
        getMatchPlayersFunc(match.params.id, token)
        toggleMessage()
        setNoOfJoin(data.bPrivateLeague && data.bMultipleEntry ? data.nMax - data.nJoined : contestData && contestData.nJoinedCount ? (data.nTeamJoinLimit - contestData.nJoinedCount) : data.nTeamJoinLimit)
      } else {
        if (data && data.bPrivateLeague && data.sShareCode) {
          history.push({ pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${ID}/private/${data.sShareCode}` : `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${ID}/private/${data.sShareCode}`, state: { activeTab: activeTab } })
        } else {
          history.push({ pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${ID}` : `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${ID}`, state: { activeTab: activeTab } })
        }
      }
    } else {
      if (teamList?.length > 0) {
        toggleMessage()
        getMatchPlayersFunc(match.params.id, token)
        setNoOfJoin(data.bPrivateLeague && data.bMultipleEntry ? data.nMax - data.nJoined : data.nTeamJoinLimit)
      } else {
        if (data && data.bPrivateLeague && data.sShareCode) {
          history.push({ pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${ID}/private/${data.sShareCode}` : `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${ID}/private/${data.sShareCode}`, state: { activeTab: activeTab } })
        } else {
          history.push({ pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${ID}` : `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${ID}`, state: { activeTab: activeTab } })
        }
      }
    }
    setIsJoinLeague(false)
  }

  function LeagueJoin (userTeams) {
    setModalMessage2(false)
    if (totalPay > 0) {
      props.history.push({
        pathname: '/deposit',
        state: {
          amountData: { nAmount: Number(totalPay) },
          message: 'Insufficient Balance'
        }
      })
    } else {
      applied && finalPromocode ? joinContest(userTeams, finalPromocode) : joinContest(userTeams, '')
      callJoinTeamEvent()
    }
  }

  const applePromoCode = (promo) => {
    if (data && data._id && updatedFilterData && updatedFilterData.length > 0 && promo) {
      applyPromo({ iMatchLeagueId: data._id, nTeamCount: updatedFilterData.length, sPromo: promo })
      setPromoCodes(false)
      setPromoData('')
      setModalMessage2(true)
      setFinalPromocode('')
    }
  }
  function callViewContestEvent () {
    if (data && data.sName && data._id && location.pathname) {
      viewContest(data.sName, data._id, location.pathname)
    } else {
      data && data.sName && data._id && viewContest(data.sName, data._id, '')
    }
    if (PromoCodes) setPromoCodes(false)
  }

  function callJoinTeamEvent () {
    if (userTeams && match && match.params && match.params.id && location.pathname) {
      joinTeam(userTeams, match.params.id, location.pathname)
    } else {
      userTeams && match && match.params && match.params.id && joinTeam(userTeams, match.params.id, '')
    }
  }

  function getPromocodeList () {
    setPromoCodes(true)
    setModalMessage(false)
    setModalMessage2(false)
    data && data._id && onGetPromocodeList(data._id)
  }

  function clearPromo () {
    // setTotalPayFinal(updatedFilterData?.length > 0 ? data?.nPrice * updatedFilterData?.length : data?.nPrice)
    if (totalPay > 0) {
      setTotalPay(parseInt(totalPay) + parseInt(applyPromocodeData?.nDiscount))
    } else if (data?.nBonusUtil === 100) {
      setFromBonus(parseInt(fromBonus) + parseInt(applyPromocodeData?.nDiscount))
    } else {
      setFromWallet(parseInt(fromWallet) + parseInt(applyPromocodeData?.nDiscount))
    }
    setDiscount(0)
    setApplied(false)
    setFinalPromocode('')
  }

  return (
    <>
      {loading && <Loading />}
      {alert && alertMessage ? <Alert className='select-all' color="primary" isOpen={alert}>{alertMessage}</Alert> : ''}
      <Fragment>
        {
          data && data.bPrivateLeague && (
            <CardHeader className="border-0 my-contest mt-3 d-flex justify-content-between">
              {data && data.sName}
              {
                matchType === 'upcoming' && ((data.nJoined < data.nMax) || data.bUnlimitedJoin) && (
                  <button className="bg-transparent icon-share"
                    onClick={() => history.push({
                      pathname: history.location.pathname.includes('tournament') ? `/tournament/create-contest/${(match.params.sportsType).toLowerCase()}/${data.iMatchId}/${data._id}/invite` : `/create-contest/${(match.params.sportsType).toLowerCase()}/${data.iMatchId}/${data._id}/invite`,
                      search: `?${qs.stringify({
                    homePage: obj?.homePage ? 'yes' : undefined
                  })}`,
                      state: { matchDetails: matchDetails, matchLeagueDetails: data }
                    })}
                  />
                )
              }
            </CardHeader>
          )
        }
        <div className="card border-0 bg-transparent" onClick={() => callViewContestEvent()}>
          <CardBody className="bg-white">
            <Link to={!participated
              ? {
                  pathname: history.location.pathname.includes('tournament') ? `/tournament/leagues-detail/${(match.params.sportsType).toLowerCase()}/${matchID}/${data && data._id}` : `/leagues-detail/${(match.params.sportsType).toLowerCase()}/${matchID}/${data && data._id}`,
                  search: `?${qs.stringify({
                      homePage: obj?.homePage ? 'yes' : undefined
                    })}`,
                  state: { tab: tab, referUrl: history.location.pathname.includes('tournament') ? `/tournament/leagues/${(match.params.sportsType).toLowerCase()}/${matchID}` : `/leagues/${(match.params.sportsType).toLowerCase()}/${matchID}`, teamList }
                }
              : history.location.pathname.includes('tournament') ? `/tournament/leagues-details/${(match.params.sportsType).toLowerCase()}/${match && match.params.id}/${data && data._id}/participated` : `/leagues-details/${(match.params.sportsType).toLowerCase()}/${match && match.params.id}/${data && data._id}/participated`}
            >
              <ul className="m-0 d-flex align-items-center flex-wrap">
                <li>
                  <FormattedMessage id="League_prizePool" />
                  <strong>
                    {' '}
                    {currencyLogo}
                    {data && data.nTotalPayout && currencyConverter(data.nTotalPayout)
                  // data && data.bUnlimitedJoin ? (Number(((data.nTotalPayout / data.nMax) * data.nJoined) - (((data.nJoined * data.nPrice) * (data.nBonusUtil || 0)) / 100)).toFixed(2)) : data && data.nTotalPayout ? data.nTotalPayout : ''
                }
                    {/* data && data.bPoolPrize && data.nDeductPercent !== null && (!data.bPrivateLeague) && data.eMatchStatus === 'U'
                    ? Math.floor(Number(((data.nPrice * maxValue(data.nMin, data.nJoined) * 100) / (((data && data.nDeductPercent) || 0) + 100))))
                    : data && data.bPoolPrize && data.nDeductPercent !== null && (!data.bPrivateLeague) && data.eMatchStatus !== 'U'
                      ? Math.floor(Number(((data.nPrice * data.nJoined * 100) / (((data && data.nDeductPercent) || 0) + 100))))
                      : data && data.nTotalPayout && data.nTotalPayout */}
                  </strong>

                </li>
                <li>
                  {
                    data && data.nLoyaltyPoint && data.nLoyaltyPoint >= 1
                      ? (
                        <Fragment>
                          <i className="icon-ic_Loyalty_grey" />
                          <span className='mr-2'>
                            {' '}
                            {data && data.nLoyaltyPoint && data.nLoyaltyPoint >= 1 ? data.nLoyaltyPoint : '0'}
                            {' '}
                            <FormattedMessage id="League_Points" />
                            {' '}
                          </span>
                        </Fragment>
                        )
                      : ''
                  }
                  {
                    data && !data.bPrivateLeague && (
                      <Fragment>
                        <i className="icon-tickets" />
                        {data && data.nTeamJoinLimit
                          ? data.nTeamJoinLimit === 1
                            ? <FormattedMessage id="League_single_entry" />
                            : (
                              <Fragment>
                                <FormattedMessage id="League_Upto" />
                                {' '}
                                {data.nTeamJoinLimit}
                                {' '}
                                <FormattedMessage id="League_Entries" />
                              </Fragment>
                              )
                          : ''}
                      </Fragment>
                    )
                  }
                </li>
                <li>
                  <img alt={<FormattedMessage id='Medal' />} src={Medal} />
                  <FormattedMessage id="League_1stPrize" />
                  :
                  {' '}
                  <b>
                    {firstRankType !== 'E' && currencyLogo}
                    {firstRankType === 'E'
                      ? firstPrize
                      : firstRankType !== 'E'
                        ? currencyConverter(parseFloat(Number((firstPrize)).toFixed(2)))
                        : currencyConverter(firstPrize)}
                    {' '}
                    {firstRankType === 'B' && 'Bonus'}
                    {' '}

                  </b>

                </li>
                <li>
                  <img alt={<FormattedMessage id='Trophy' />} src={Trophy} />
                  <FormattedMessage id="League_winners" />
                  :
                  {' '}
                  <b>
                    {data && data.nMax && data.nWinnersCount ? (parseInt((data.nWinnersCount || 0) / data.nMax * 100)) : 0}
                    <FormattedMessage id="Common_perc" />
                  </b>
                </li>
              </ul>
              {
                data && data.nJoined >= 0
                  ? (
                    <Fragment>
                      {(data && data.bUnlimitedJoin)
                        ? <div className="ul-p-bar" />
                        : <div className="p-bar"><span style={{ width: data && data.nMax >= 0 && data.nJoined >= 0 && `${((100 * data.nJoined) / data.nMax)}%` }} /></div>}
                      <div className="t-info d-flex align-items-center justify-content-between">
                        <span>
                          {
                          (data?.nJoined > data.nMax) && (!data && data.bUnlimitedJoin)
                            ? (
                              <Fragment>
                                0
                                {' '}
                                <FormattedMessage id="League_left" />
                              </Fragment>
                              )
                            : (
                              <Fragment>
                                {data && data.bUnlimitedJoin ? 'ထ ' : amountData && amountData.sKey === 'SUCCESS' && data._id === MatchLeagueId ? (data.nMax - (amountData?.oValue?.nJoined)) : data && data.nJoined >= 0 && data.nMax >= 0 && (data.nMax - data.nJoined) >= 0 ? (data.nMax - data.nJoined) : 0}
                                {' '}
                                <FormattedMessage id="League_left" />
                              </Fragment>
                              )
                        }
                        </span>
                        <span className='blackColor'>
                          {
                          (data?.nJoined > data.nMax)
                            ? (
                              <Fragment>
                                {data && data.bUnlimitedJoin ? data?.nJoined + '/ထ' : amountData && amountData.sKey === 'SUCCESS' && data._id === MatchLeagueId ? amountData?.oValue?.nJoined : data && data.nJoined === 0 ? 0 : data.nJoined}
                                {' '}
                                <span className='blackColor'><FormattedMessage id="League_joined" /></span>
                              </Fragment>
                              )
                            : (
                              <Fragment>
                                {
                                amountData && amountData.sKey === 'SUCCESS' && data._id === MatchLeagueId
                                  ? amountData?.oValue?.nJoined
                                  : data && data.nJoined === 0 ? 0 : data.nJoined}
                                /
                                {data && data.bUnlimitedJoin ? 'ထ' : data && (data.nMax > data.nJoined) ? data.nMax : data.nJoined}
                                {' '}
                                <span className='blackColor'><FormattedMessage id="League_joined" /></span>
                              </Fragment>
                              )
                        }
                        </span>
                      </div>
                    </Fragment>
                    )
                  : (
                    <Fragment>
                      <div className="p-bar">
                        <span style={{ width: data && data.nMax >= 0 && data.nJoined >= 0 ? ((100 * data.nJoined) / data.nMax) : '' }}> </span>
                        {' '}
                      </div>
                      <div className="t-info d-flex align-items-center justify-content-between">
                        <span>
                          {' '}
                          <FormattedMessage id="League_left" />
                          {' '}
                        </span>
                        <span>
                          {' '}
                          <FormattedMessage id="Teams" />
                          {' '}
                        </span>
                      </div>
                    </Fragment>
                    )
              }
            </Link>
            <CardFooter className="bg-white d-flex align-items-center justify-content-between">
              <div className="f-w">
                <Fragment>
                  {data && data.nBonusUtil && (!data.bPrivateLeague) ? <img alt={<FormattedMessage id='Diamond' />} src={Diamond} /> : <img alt={<FormattedMessage id='Diamond_Disable' />} src={DiamondDisable} />}
                  {data && data.bMultipleEntry ? <img alt={<FormattedMessage id='Multi' />} src={Multi} /> : <img alt={<FormattedMessage id='Multi_Disable' />} src={MultiDisable} />}
                  {data && data.bConfirmLeague && (!data.bPrivateLeague) ? <img alt={<FormattedMessage id='Copy' />} src={Copy} /> : <img alt={<FormattedMessage id='Copy_Disable' />} src={CopyDisable} />}
                  {data && data.nLoyaltyPoint && (!data.bPrivateLeague) ? <img alt={<FormattedMessage id='Loyalty' />} src={Loyalty} /> : <img alt={<FormattedMessage id='Loyalty_Disable' />} src={LoyaltyDisable} />}
                </Fragment>
              </div>
              {
                data && data.nBonusUtil
                  ? (
                    <div className="f-w text-center">
                      <FormattedMessage id="League_bonus" />
                      :
                      <b>
                        {' '}
                        {data && data.nBonusUtil ? data.nBonusUtil : <FormattedMessage id="Team_0" />}
                        <FormattedMessage id="Common_perc" />
                      </b>
                    </div>
                    )
                  : ''
              }
              <div className="f-w text-right">
                <FormattedMessage id="League_entry" />
                <Button className='btn-blue' disabled={participated || (Object.keys(joined).length > 0 && data && data.bPrivateLeague && !data.bMultipleEntry) || (joined && data && !data.bPrivateLeague && (joined.nJoinedCount === data.nTeamJoinLimit)) || (data && data.nJoined && data.nMax && !data.bUnlimitedJoin && data.nJoined === data.nMax) || (data && joinDetails && !data.bPrivateLeague && (joinDetails.nJoinedCount === data.nTeamJoinLimit))} onClick={() => setIsJoinLeague(true)}>
                  {currencyLogo}
                  {data && data.nPrice
                    ? currencyConverter(data.nPrice)
                    : <FormattedMessage id="Team_0" />}

                </Button>
              </div>
            </CardFooter>
            {
              data && data.nCashbackAmount && data.nMinCashbackTeam
                ? (
                  <div className="bg-white d-flex">
                    <Badge className='cashback' color="info" pill >
                      <img className='img' src={offerIcon} />
                      <span>
                        {currencyLogo}
                        {data && currencyConverter(data.nCashbackAmount)}
                        {' '}
                        {data && data.eCashbackType === 'B' ? <FormattedMessage id="Bonus_On" /> : <FormattedMessage id="Cashback_On" /> }
                        {
                      data && data.nMinCashbackTeam >= 1
                        ? (
                          <Fragment>
                            { data && ` ${data.nMinCashbackTeam}` }
                            {' '}
                            { data && data.nMinCashbackTeam > 1 ? <FormattedMessage id="League_Entries" /> : <FormattedMessage id="League_Entry" />}
                          </Fragment>
                          )
                        : ' '
                    }
                      </span>
                    </Badge>
                  </div>
                  )
                : ''
            }
            {
              showInformation && (
                <>
                  <hr className='league-hr' />
                  <div className="mt-footer ft-12 d-flex align-items-center justify-content-around">
                    <span className='gray'>
                      <b>
                        <img alt={<FormattedMessage id='Diamond' />} src={Diamond} />
                        <FormattedMessage id="League_bonus" />
                      </b>
                    </span>
                    <span className='gray'>
                      <b>
                        <img alt={<FormattedMessage id='Multi' />} src={Multi} />
                        <FormattedMessage id="League_multiple_entry" />
                      </b>
                    </span>
                    <span className='gray'>
                      <b>
                        <img alt={<FormattedMessage id='Copy' />} src={Copy} />
                        <FormattedMessage id="League_Confirmed" />
                      </b>
                    </span>
                    <span className='gray'>
                      <b>
                        <img alt={<FormattedMessage id='Loyalty' />} src={Loyalty} />
                        <FormattedMessage id="League_LoyaltyPoints" />
                      </b>
                    </span>
                  </div>
                </>
              )
            }
            {
              data && data.userJoined && (matchDetails?.eStatus === 'L' || matchDetails?.eStatus === 'I' || matchDetails?.eStatus === 'CMP')
                ? data?.userJoined?.sort((a, b) => a.nRank - b.nRank).map((team, index) => {
                  return (
                    index <= 2
                      ? (
                        <Fragment key={index}>
                          <div>
                            <div className={`mt-footer footerPrizeBreakup ${team.bTeamWinningZone || team.nPrice || team.nBonusWin || team.aExtraWin?.length > 0 ? 'backGreen' : 'backRed'}`}>
                              <div className='d-flex align-items-center justify-content-around'>
                                <span>
                                  <b>
                                    {' '}
                                    {team.sTeamName ? team.sTeamName : ''}
                                    {' '}
                                  </b>
                                </span>
                                <span>
                                  <b>
                                    {' '}
                                    {convertFloat(team.nTotalPoints)}
                                    {' '}
                                  </b>
                                </span>
                                <span>
                                  <b>
                                    {' '}
                                    #
                                    {team.nRank ? team.nRank : '-'}
                                    {' '}
                                  </b>
                                </span>
                              </div>
                              <div>
                                {
                              team.bTeamWinningZone && (
                                <Fragment>
                                  <div className='greenText green-color'>
                                    <img className='mr-1' src={Trophy} />
                                    <span className='mt-2 winning-text'>
                                      <FormattedMessage id="Match_Winning_Zone" />
                                    </span>
                                  </div>
                                    {/* {
                                    team?.nPrice >= 1 && !team.nBonusWin >= 1 && team.aExtraWin?.length === 0 && (
                                      <b className='greenText green-color'>
                                          <img src={Trophy} className='mr-1'></img>
                                          <span className='mt-2 winning-text'>
                                            <FormattedMessage id="Match_Winning_Zone" />
                                          </span>
                                      </b>
                                    )
                                    }
                                    {
                                    team && !team.nPrice >= 1 && team.nBonusWin >= 1 && team.aExtraWin?.length === 0 &&
                                      (
                                      <b className='greenText green-color'>
                                          <img src={Trophy} className='mr-1'></img>
                                          <span className='mt-2 winning-text'>
                                            <FormattedMessage id="Match_Winning_Zone" />
                                          </span>
                                      </b>
                                      )
                                    }
                                    {
                                    team && team.nPrice === 0 && team.nBonusWin === 0 && team.aExtraWin && team.aExtraWin?.length === 1 &&
                                      (
                                      <b className='greenText green-color'>
                                          <img src={Trophy} className='mr-1'></img>
                                          <span className='mt-2 winning-text'>
                                            <FormattedMessage id="Match_Winning_Zone" />
                                          </span>
                                      </b>
                                      )
                                    }
                                    {
                                    team && !team.nPrice >= 1 && !team.nBonusWin >= 1 && team.aExtraWin?.length >= 2 &&
                                      (
                                      <b className='greenText green-color'>
                                          <img src={Trophy} className='mr-1'></img>
                                          <span className='mt-2 winning-text'>
                                            <FormattedMessage id="Match_Winning_Zone" />
                                          </span>
                                      </b>
                                      )
                                    }
                                    {
                                    team && ((team.nPrice >= 1 && team.nBonusWin >= 1) || (team.nPrice >= 1 && team.aExtraWin?.length >= 1) || (team.aExtraWin?.length >= 1 && team.nBonusWin >= 1) || (team.aExtraWin?.length >= 1 && team.nBonusWin >= 1 && team.nPrice >= 1)) &&
                                      (
                                      <b className='greenText green-color'>
                                          <img src={Trophy} className='mr-1'></img>
                                          <span className='mt-2 winning-text'>
                                            <FormattedMessage id="Match_Winning_Zone" />
                                          </span>
                                      </b>
                                      )
                                    } */}
                                </Fragment>
                              )
                            }
                                {matchDetails?.eStatus === 'CMP' && (
                                <Fragment>
                                  {team && team.nPrice >= 1 && !team.nBonusWin >= 1 && team.aExtraWin?.length === 0
                                    ? (
                                      <p className='greenText green-color'>
                                        <FormattedMessage id="Team_You_Won"/>
                                        {' '}
                                        {currencyConverter(parseFloat(team.nPrice.toFixed(2)))}
                                      </p>
                                      )
                                    : team && !team.nPrice >= 1 && team.nBonusWin >= 1 && team.aExtraWin?.length === 0
                                      ? (
                                        <p className='greenText green-color'>
                                          <FormattedMessage id="Team_You_Won"/>
                                          {' '}
                                          {currencyConverter(parseFloat(team.nBonusWin.toFixed(2)))}
                                          {' '}
                                          Bonus
                                          {' '}
                                        </p>
                                        )
                                      : team && !team.nPrice >= 1 && !team.nBonusWin >= 1 && team.aExtraWin?.length === 1
                                        ? (
                                          <p className='greenText green-color'>
                                            <FormattedMessage id="Team_You_Won"/>
                                            {' '}
                                            {team.aExtraWin[0]?.sInfo}
                                            {' '}
                                          </p>
                                          )
                                        : team && !team.nPrice >= 1 && !team.nBonusWin >= 1 && team.aExtraWin?.length >= 2
                                          ? (
                                            <p className='greenText green-color'>
                                              {' '}
                                              <FormattedMessage id="ONLY_WON_Gadget"/>
                                              {' '}
                                            </p>
                                            )
                                          : team && !team.nPrice >= 1 && !team.nBonusWin >= 1 && team.aExtraWin?.length === 0
                                            ? ''
                                            : (
                                              <p className='greenText green-color'>
                                                <FormattedMessage id="WON_Multiple_Prizes"/>
                                                {' '}
                                              </p>
                                              )
                            }
                                </Fragment>
                                )}
                              </div>
                            </div>
                          </div>
                        </Fragment>
                        )
                      : index === 3 && (
                        <Fragment key={index}>
                          <Link to={!participated
                            ? {
                                pathname: history.location.pathname.includes('tournament') ? `/tournament/leagues-detail/${(match.params.sportsType).toLowerCase()}/${matchID}/${data && data._id}` : `/leagues-detail/${(match.params.sportsType).toLowerCase()}/${matchID}/${data && data._id}`,
                                search: `?${qs.stringify({
                      homePage: obj?.homePage ? 'yes' : undefined
                    })}`,
                                state: { tab: tab, referUrl: `/leagues/${(match.params.sportsType).toLowerCase()}/${matchID}`, teamList }
                              }
                            : { pathname: history.location.pathname.includes('tournament') ? `/tournament/leagues-details/${(match.params.sportsType).toLowerCase()}/${match && match.params.id}/${data && data._id}/participated` : `/leagues-details/${(match.params.sportsType).toLowerCase()}/${match && match.params.id}/${data && data._id}/participated` }}
                          >
                            <div className="mt-footer footerPrizeBreakup d-flex align-items-center justify-content-around">
                              <span>
                                +
                                {' '}
                                {data.userJoined?.length - 3}
                                {' '}
                                more
                              </span>
                            </div>
                          </Link>
                        </Fragment>
                      )
                  )
                })
                : ''
            }
          </CardBody>
        </div>
        {
          modalMessage
            ? (
              <>
                <div className="s-team-bg"
                  onClick={() => {
                    setModalMessage(false)
                    setSelectedAll(false)
                  }}
                />
                <Card className="filter-card select-team promo-card">
                  <CardHeader className='d-flex align-items-center justify-content-between m-0'>
                    <button><FormattedMessage id="Team_SelectTeam" /></button>
                    <button onClick={() => {
                      setSelectedAll(false)
                      setModalMessage(false)
                    }}
                    >
                      <img src={close} />
                    </button>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="two-button border-0 bg-white m-0 d-flex justify-content-between card-footer">
                      <Button className='create-team-button'
                        onClick={() => {
                          if (data && data.bPrivateLeague && data.sShareCode) {
                            if (history?.location?.pathname?.includes('tournament')) {
                              history.push({ pathname: `/tournament/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${data._id}/private/${data.sShareCode}`, state: { activeTab: activeTab } })
                            } else {
                              history.push({ pathname: `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${data._id}/private/${data.sShareCode}`, state: { activeTab: activeTab } })
                            }
                          } else {
                            if (history?.location?.pathname?.includes('tournament')) {
                              history.push({ pathname: `/tournament/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${data._id}`, state: { activeTab: activeTab } })
                            } else {
                              history.push({ pathname: `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${data._id}`, state: { activeTab: activeTab } })
                            }
                          }
                        }}
                        type="submit"
                      >
                        <img alt={<FormattedMessage id='Team_CreateTeam' />} className='mr-2' src={createteam} width={20} />
                        <FormattedMessage id="Team_CreateTeam" />
                      </Button>
                      <Button className='create-contest-button'
                        onClick={() => history.push({
                          pathname: history.location.pathname.includes('tournament') ? `/tournament/create-contest/${(match.params.sportsType).toLowerCase()}/${match.params.id}` : `/create-contest/${(match.params.sportsType).toLowerCase()}/${match.params.id}`,
                          search: `?${qs.stringify({
                      homePage: obj?.homePage ? 'yes' : undefined
                    })}`
                        })}
                        type="submit"
                      >
                        <img alt={<FormattedMessage id='Team_CreatePrivateLeague' />} className='mr-2' src={createContest} width={20} />
                        <FormattedMessage id="Team_CreatePrivateLeague" />
                      </Button>
                    </div>
                    {data && data.bMultipleEntry && ((UpdatedTeamList && UpdatedTeamList.length !== 1) && (teamList && teamList.length !== 1)) && (
                    <div className='SelectAll d-flex align-items-center'>
                      <input checked={selectedAll}
                        id='name'
                        name="gender"
                        onClick={() => setSelectedAll(!selectedAll)}
                        type="radio"
                      />
                      <label htmlFor='name'>
                        <FormattedMessage id="Team_SelectAll" />
                        {' '}
                      </label>
                    </div>
                    )}
                    {
                    teamList && teamList.length !== 0 &&
                      teamList.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map((data1, index) => {
                        return (
                          <Suspense key={data1._id} fallback={<Loading />}>
                            <div className='d-flex justify-content-betwee'>
                              <MyTeam {...props}
                                key={data1._id}
                                UserTeamChoice
                                allTeams={teamList}
                                disabledRadio={UpdatedTeamList?.find((item) => item._id === data1._id)?._id}
                                disabledRadioFlag={UpdatedTeamList?.length !== 0}
                                index={index}
                                join
                                leagueData={data}
                                noOfJoin={noOfJoin}
                                params={match && match.params.id}
                                setUserTeams={(Id) => setUserTeams(Id)}
                                teamDetails={data1}
                                teamId={data1._id}
                                upcoming
                                userTeams={userTeams}
                              />
                            </div>
                          </Suspense>
                        )
                      })
                  }
                  </CardBody>
                  <CardFooter className='p-0 border-0 bg-trnsparent m-0 d-flex justify-content-between'>
                    <Button className="w-100"
                      color='primary-two'
                      disabled={userTeams && userTeams.length === 0}
                      onClick={() => {
                        if (userTeams.length > (data.nMax - data.nJoined) && (!data.bUnlimitedJoin)) {
                          setAlert(true)
                          setAlertMessage(
                            <p>
                              <FormattedMessage id="Team_Title1" />
                              {' '}
                              {data.nMax - data.nJoined || '-'}
                              {' '}
                              <FormattedMessage id="Team_Title2" />
                            </p>)
                          setTimeout(() => {
                            setAlertMessage('')
                            setAlert(false)
                          }, 2000)
                        } else if (noOfJoin >= userTeams.length) {
                          const filterData = teamList.filter(data => userTeams.includes(data._id))
                          onGetUserProfile()
                          setUpdatedFilterData(filterData)
                          setModalMessage2(true)
                          setModalMessage(false)
                          // setLoading(true)
                          setFinalPromocode('')
                        } else {
                          setAlert(true)
                          setAlertMessage(
                            <p>
                              <FormattedMessage id="Team_Title1" />
                              {' '}
                              {noOfJoin || '-'}
                              {' '}
                              <FormattedMessage id="Team_Title2" />
                            </p>)
                          setTimeout(() => {
                            setAlertMessage('')
                            setAlert(false)
                          }, 2000)
                        }
                        // setSelectedAll(false)
                        // }}><FormattedMessage id="League_join" /> (<FormattedMessage id='Pay'/> <FormattedMessage id='Team_Rupee'/>0)</Button>
                      }}
                      type="submit"
                    >
                      <FormattedMessage id='other_Next' />
                    </Button>
                  </CardFooter>
                </Card>
              </>
              )
            : modalMessage2
              ? (
                <>
                  {loading && <Loading />}
                  <div className="s-team-bg" onClick={() => setModalMessage2(false)} />
                  <Card className="filter-card select-team promo-card">
                    <CardHeader className='d-flex align-items-center justify-content-between m-0'>
                      <button><FormattedMessage id="League_Payment" /></button>
                      <button className="icon-close"
                        onClick={() => {
                          setModalMessage2(false)
                          setDiscount(0)
                        }}
                      />
                    </CardHeader>
                    <CardBody className="p-0 teamXShawing">
                      <div className='teamJoin'>
                        {
                        updatedFilterData && updatedFilterData.length && (
                          <h3>
                            {updatedFilterData.length}
                            {' '}
                            <FormattedMessage id="League_Teams_Selected" />
                          </h3>
                        )
                      }
                      </div>
                      <div className='selectedTeamList'>
                        {
                        updatedFilterData && updatedFilterData.length !== 0
                          ? updatedFilterData.sort((a, b) => a?.sTeamName?.toString().localeCompare(b?.sTeamName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map((data1, index) => {
                            return (
                              <Suspense key={data1._id} fallback={<Loading />}>
                                <MyTeam {...props}
                                  key={data1._id}
                                  UserTeamChoice
                                  allTeams={updatedFilterData}
                                  index={index}
                                  leagueData={data}
                                  noOfJoin={noOfJoin}
                                  params={match && match.params.id}
                                  teamDetails={data1}
                                  upcoming
                                  viewOnly
                                />
                              </Suspense>
                            )
                          })
                          : ''
                      }
                      </div>
                      <Table className="m-0 bg-white promocode">
                        <thead>
                          <tr>
                            <th><FormattedMessage id="League_Total_Entry" /></th>
                            <th className='rightAlign'>
                              {currencyLogo}
                              {data && updatedFilterData && currencyConverter(data.nPrice * updatedFilterData.length)}
                              {' '}
                              (
                              {data && updatedFilterData && `${currencyConverter(data.nPrice)} X ${currencyConverter(updatedFilterData.length)}`}
                              {' '}
                              )
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {(!data.bPrivateLeague) && (
                          <Fragment>
                            <tr>
                              <td className='green' colSpan='2'>
                                {finalPromocode
                                  ? (
                                    <div className='d-flex align-items-center'>
                                      <h3 className='promocode-text m-0'>
                                        {finalPromocode}
                                        {' '}
                                        <FormattedMessage id='Promo_Applied' />
                                      </h3>
                                      <button className="icon-close ml-2" onClick={clearPromo} />
                                    </div>
                                    )
                                  : (
                                    <Button className='p-0 d-flex align-items-center' color='link' onClick={() => getPromocodeList()}>
                                      <h3 className='promocode-text m-0'><FormattedMessage id="League_Apply_Promocode" /></h3>
                                      <img className='ml-1' src={rightGreenArrow} />
                                    </Button>
                                    )}
                              </td>
                            </tr>
                            {/* <tr>
                          <td colSpan='2' className='green'>
                            <FormGroup className="c-input mt-2 mb-0">
                              <Input onClick={() => getPromocodeList()} type="text" className={classNames({ 'hash-contain': finalPromocode }) } id="Promocode" value={finalPromocode} autoComplete='off' required onChange={(e) => setPromoData(e.target.value)} />
                              <Label className="no-change label m-0" for="Promocode"><FormattedMessage id="User_Promocode" /></Label>
                              {applied && finalPromocode
                                ? <button className="i-icon" onClick={clearPromo}>Remove</button>
                                : <button className="i-icon" onClick={() => applePromoCode(promoData)}><FormattedMessage id="User_Apply" /></button>}
                            </FormGroup>
                          </td>
                        </tr> */}
                            {finalPromocode && (
                            <tr>
                              <td><FormattedMessage id="League_Discount" /></td>
                              <td className='rightAlign'>
                                {currencyLogo}
                                {currencyConverter(discount)}
                              </td>
                            </tr>
                            )}
                          </Fragment>
                          )}
                          <tr>
                            <td><FormattedMessage id="League_In_Wallet" /></td>
                            <td className='rightAlign'>
                              {currencyLogo}
                              {currencyConverter(fromWallet)}
                            </td>
                          </tr>
                          {fromBonus > 0 && (
                          <tr>
                            <td><FormattedMessage id="From_bonus" /></td>
                            <td className='rightAlign'>
                              {currencyLogo}
                              {currencyConverter(fromBonus)}
                            </td>
                          </tr>
                          )}
                        </tbody>
                        {/* {
                        totalPay && currencyLogo
                          ? (
                          <tfoot>
                            <tr>
                              <td><h1><FormattedMessage id="League_To_Pay" /></h1></td>
                              <td className='rightAlign'>{currencyLogo}
                                {currencyConverter(totalPay)}</td>
                            </tr>
                          </tfoot>
                            )
                          : ''
                      } */}
                      </Table>
                    </CardBody>
                    <CardFooter className='p-0 border-0 bg-trnsparent m-0 d-flex justify-content-between'>
                      <Button className="w-100"
                        color='primary-two'
                        disabled={userTeams && userTeams.length === 0}
                        onClick={() => LeagueJoin(userTeams)}
                        type="submit"
                      >
                        <>
                          {totalPay > 0 ? <FormattedMessage id='Add'/> : <FormattedMessage id="League_join" />}
                          {' '}
                          (
                          <FormattedMessage id='Pay'/>
                          {' '}
                          {' ' + currencyLogo}
                          {currencyConverter((data?.nPrice * updatedFilterData?.length) - fromBonus - discount)}
                          )
                        </>
                      </Button>
                    </CardFooter>
                  </Card>
                </>
                )
              : ''
        }
        {PromoCodes
          ? (
            <>
              <div className="s-team-bg" onClick={() => setPromoCodes(false)} />
              <Card className="filter-card show select-team promo-card">
                <CardHeader className='d-flex align-items-center justify-content-between m-0'>
                  <button><FormattedMessage id="League_Promocode" /></button>
                  <button className="icon-close"
                    onClick={() => {
                      setPromoCodes(false)
                      setModalMessage2(true)
                    }}
                  />
                </CardHeader>
                <CardBody className='p-10'>
                  {promocodeLoading && <PromocodeLoading />}
                  <div className="p-title-2"><FormattedMessage id="User_promocodeForU" /></div>
                  {matchPromoCodeList !== {} && matchPromoCodeList && matchPromoCodeList.length > 0
                    ? matchPromoCodeList.map(matchPromo => {
                      return (
                        <div key={matchPromo._id} className="d-flex align-items-center justify-content-between promo-box">
                          <div>
                            <b>{matchPromo.sCode}</b>
                            <p>{matchPromo.sInfo}</p>
                          </div>
                          <Button color="white" onClick={() => applePromoCode(matchPromo.sCode)}><FormattedMessage id="User_Apply" /></Button>
                        </div>
                      )
                    })
                    : (
                      <Fragment>
                        <center>
                          <h2>
                            {' '}
                            <FormattedMessage id="Blank_Promocode_List" />
                            {' '}
                          </h2>
                        </center>
                      </Fragment>
                      )
                }
                </CardBody>
                <CardFooter className='p-0 border-0 bg-transparent m-0 d-flex justify-content-between' />
              </Card>
            </>
            )
          : ''
        }
      </Fragment>
    </>
  )
}

League.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      sportsType: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
    location: PropTypes.object
  }),
  data: PropTypes.shape({
    aLeaguePrize: PropTypes.array,
    userJoined: PropTypes.shape([{
      nRank: PropTypes.number,
      nPrice: PropTypes.number,
      nTotalPoints: PropTypes.number,
      bTeamWinningZone: PropTypes.bool,
      sTeamName: PropTypes.string
    }]),
    sName: PropTypes.string,
    eMatchStatus: PropTypes.string,
    bPoolPrize: PropTypes.bool,
    nBonusUtil: PropTypes.number,
    nWinnersCount: PropTypes.number,
    nMax: PropTypes.number,
    nMin: PropTypes.number,
    nPrice: PropTypes.number,
    nLoyaltyPoint: PropTypes.number,
    nJoined: PropTypes.number,
    nTotalPayout: PropTypes.number,
    nTeamJoinLimit: PropTypes.number,
    _id: PropTypes.string,
    bConfirmLeague: PropTypes.bool,
    bMultipleEntry: PropTypes.bool,
    nJoinedCount: PropTypes.bool,
    bPrivateLeague: PropTypes.bool,
    iMatchId: PropTypes.string,
    sShareCode: PropTypes.string,
    eCashbackType: PropTypes.string,
    bUnlimitedJoin: PropTypes.bool,
    nCashbackAmount: PropTypes.number,
    nMinCashbackTeam: PropTypes.number,
    nDeductPercent: PropTypes.number
  }),
  applyPromocodeData: PropTypes.shape({
    nDiscount: PropTypes.string,
    sCode: PropTypes.string
  }),
  userInfo: PropTypes.shape({
    nCurrentTotalBalance: PropTypes.number,
    nCurrentBonus: PropTypes.number
  }),
  home: PropTypes.bool,
  joinContest: PropTypes.func,
  firstPrize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  firstRankType: PropTypes.string,
  lastPrize: PropTypes.number,
  MatchLeagueId: PropTypes.string,
  amountData: PropTypes.object,
  contestList: PropTypes.array,
  participated: PropTypes.bool,
  teamList: PropTypes.arrayOf(PropTypes.shape({
    iMatchId: PropTypes.string
  })),
  matchType: PropTypes.string,
  activeTab: PropTypes.number,
  contestJoinList: PropTypes.array,
  joinDetails: PropTypes.object,
  insideleagueDetailsPage: PropTypes.bool,
  toggleMessage: PropTypes.func,
  applyPromo: PropTypes.func,
  onGetUserProfile: PropTypes.func,
  modalMessage: PropTypes.bool,
  setModalMessage: PropTypes.func,
  joined: PropTypes.array,
  getMyTeamList: PropTypes.func,
  onGetPromocodeList: PropTypes.func,
  loading: PropTypes.bool,
  promocodeLoading: PropTypes.bool,
  appliedPromocode: PropTypes.bool,
  upComing: PropTypes.bool,
  showInformation: PropTypes.bool,
  matchID: PropTypes.string,
  currencyLogo: PropTypes.string,
  matchPromoCodeList: PropTypes.shape([{
    sCode: PropTypes.string,
    sInfo: PropTypes.string,
    _id: PropTypes.string
  }]),
  tab: PropTypes.number,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }),
  homePage: PropTypes.string,
  setLoading: PropTypes.func,
  getMatchPlayersFunc: PropTypes.func,
  token: PropTypes.string
}

export default JoinContest(League)
