import React from 'react'
import { FormattedMessage } from 'react-intl'
import UserHeader from '../components/UserHeader'
import ProfileInfoPage from './ProfileInfo'
function ProfileInfo (props) {
  return (
    <>
      <UserHeader {...props} title={<FormattedMessage id='User_profileInfo' />} backURL="/profile"/>
      <ProfileInfoPage {...props}/>
    </>
  )
}

export default ProfileInfo
