/* eslint-disable no-unused-vars */
import React, { useEffect, Fragment, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Nav, NavItem, Input, NavLink, Row, Col, Button, Alert, Spinner, Modal, Form, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Collapse, Card, CardBody, CardFooter, CardHeader } from 'reactstrap'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import SkeletonTransaction from '../../../component/Loading'
import Increace from '../../../assests/images/credit.svg'
import decr from '../../../assests/images/debit.svg'
import filter from '../../../assests/images/icon-settings.svg'
import Transaction from 'Common/src/components/User/Transaction'
import { useQueryState } from 'react-router-use-location-state'
import qs from 'query-string'
import { currencyConverter } from '../../../helper'
const classNames = require('classnames')

function TransactionsPage (props) {
  const { token, transactionList, currencyLogo, history, matchDetails, onGetMatchDetails, onGetLeagueDetails, matchLeagueDetails, transactionGivenList, toggle, GetTransactionListFun, paginationLoading, Tab, modalOpen, userWithdraw, withdrawPending, loading, onCancelWithdraw, resMessage } = props
  const [QueryTab, setQueryTab] = useQueryState('Tab', 'all')
  const [skip, setSkip] = useState(0)
  const [matchData, setMatchData] = useState({})
  const [modal, setModal] = useState(false)
  const toggle2 = () => setModal(!modal)
  const [transactionID, setTransactionID] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const toggle3 = () => setIsOpen(!isOpen)
  const [limit] = React.useState(20)
  const scrollRef = React.createRef()
  const [FilterBy, setFilterBy] = useState('')
  const [startDate, setStartDate] = useState('')
  const [lastDate, setLastDate] = useState('')
  const [finalFilterBy, setFinalFilterBy] = useState('')
  const [finalStartDate, setFinalStartDate] = useState('')
  const [finalLastDate, setFinalLastDate] = useState('')
  const [hasMoreMatches, setHasMoreMatches] = React.useState(true)
  const [filterSlide, setFilterSlide] = useState(false)
  const [TransactionList, setTransactionList] = React.useState([])
  const [cancelWithdrawModal, setCancelWithdrawModal] = useState(false)
  const previousProps = useRef({ skip, Tab, matchDetails }).current
  useEffect(() => {
    const obj = qs.parse(location.search)
    if (obj) {
      if (obj.Tab) {
        toggle(obj.Tab)
        setQueryTab(obj.Tab)
      }
    }
    if (!obj.Tab) {
      GetTransactionListFun(limit, 0, Tab, '', '')
    }
  }, [token])

  function handleScroll () { // scroll the bottom
    if (loading || !hasMoreMatches) {
      return
    }
    const wrappedElement = scrollRef.current
    if (wrappedElement.scrollHeight - wrappedElement.scrollTop - wrappedElement.clientHeight <= 0) {
      setSkip(skip => skip + limit)
    }
  }

  useEffect(() => {
    if (previousProps.skip !== skip) {
      if (skip) {
        const startDate = moment(finalStartDate).startOf('day')
        const endDate = moment(finalLastDate).endOf('day')
        GetTransactionListFun(limit, skip, Tab, finalStartDate ? parseInt((new Date(startDate).getTime()) / 1000) : '', finalLastDate ? parseInt((new Date(endDate).getTime()) / 1000) : '')
      }
    }
    return () => {
      previousProps.skip = skip
    }
  }, [skip])

  useEffect(() => { // handle the response
    if (previousProps.matchDetails !== matchDetails) {
      if (matchDetails && matchDetails._id && matchDetails.eStatus === 'U') {
        if (matchData && matchData.iMatchId && matchData.iMatchLeagueId && matchLeagueDetails && !matchLeagueDetails.bCancelled) {
          history.push(`/leagues-detail/${(matchDetails.eCategory).toLowerCase()}/${matchData.iMatchId}/${matchData.iMatchLeagueId}`)
        } else if (matchData && matchData.iMatchId) {
          history.push(`/leagues/${(matchDetails.eCategory).toLowerCase()}/${matchData.iMatchId}`)
        }
      } else if (matchDetails && matchDetails._id && matchDetails.eStatus === 'L') {
        if (matchData && matchData.iMatchId && matchData.iMatchLeagueId && matchData.eTransactionType !== 'Play-Return' && matchLeagueDetails && !matchLeagueDetails.bCancelled) {
          history.push(`/leagues-details/${(matchDetails.eCategory).toLowerCase()}/${matchData.iMatchId}/${matchData.iMatchLeagueId}/participated`)
        } else if (matchData && matchData.iMatchId) {
          history.push(`/liveleague/${matchData.iMatchId}/${(matchDetails.eCategory).toLowerCase()}`)
        }
      } else if (matchDetails && matchDetails._id && (matchDetails.eStatus === 'CMP' || matchDetails.eStatus === 'I')) {
        if (matchData && matchData.iMatchId && matchData.iMatchLeagueId && matchData.eTransactionType !== 'Play-Return' && matchLeagueDetails && !matchLeagueDetails.bCancelled) {
          history.push(`/leagues-details/${(matchDetails.eCategory).toLowerCase()}/${matchData.iMatchId}/${matchData.iMatchLeagueId}/participated`)
        } else if (matchData && matchData.iMatchId) {
          history.push(`/completedleagues/participated/${matchData.iMatchId}/${(matchDetails.eCategory).toLowerCase()}`)
        }
      }
    }
    return () => {
      previousProps.matchDetails = matchDetails
    }
  }, [matchDetails])

  useEffect(() => { // handle the scroll
    if (previousProps.Tab !== Tab) {
      if (Tab) {
        const startDate = moment(finalStartDate).startOf('day')
        const endDate = moment(finalLastDate).endOf('day')
        setTransactionList([])
        setSkip(0)
        setHasMoreMatches(true)
        GetTransactionListFun(limit, 0, Tab, finalStartDate ? parseInt((new Date(startDate).getTime()) / 1000) : '', finalLastDate ? parseInt((new Date(endDate).getTime()) / 1000) : '')
      }
    }
    return () => {
      previousProps.Tab = Tab
    }
  }, [Tab])

  useEffect(() => { // handle the list
    if (transactionList && transactionList.length > 0) {
      setTransactionList((TransactionList) => [...TransactionList, ...transactionList])
      if (transactionGivenList.length < limit) {
        setHasMoreMatches(false)
      }
    }
  }, [transactionList, limit])

  function changeTab (Type) {
    setQueryTab(Type)
    toggle(Type)
  }

  async function checkMatchDetails (data) {
    setMatchData(data)
    if (data && data.iMatchId && data.iMatchLeagueId) {
      await onGetLeagueDetails(data && data.iMatchLeagueId)
      await onGetMatchDetails(data && data.iMatchId)
    } else if (data && data.iMatchId) {
      onGetMatchDetails(data && data.iMatchId)
    } else {
      if (data.id === transactionID) {
        toggle3()
      } else {
        setTransactionID(data.id)
        setIsOpen(true)
      }
    }
  }
  function ApplyNow (FBy, sDate, lDate) {
    setFinalFilterBy(FBy)
    setFinalStartDate(sDate)
    setFinalLastDate(lDate)
    setFilterSlide(false)
    setTransactionList([])
    setSkip(0)
    setHasMoreMatches(true)
    const startDate = moment(sDate).startOf('day')
    const endDate = moment(lDate).endOf('day')
    GetTransactionListFun(20, 0, Tab, sDate ? parseInt((new Date(startDate).getTime()) / 1000) : '', lDate ? parseInt((new Date(endDate).getTime()) / 1000) : '')
  }
  return (
    <div className="user-container bg-white no-footer point-system-container-third" id="scrollBar" ref={scrollRef} onScroll={handleScroll}>
      {modalOpen ? <Alert isOpen={modalOpen} color="primary">{resMessage}</Alert> : ''}
      {
        loading
          ? <SkeletonTransaction />
          : <Fragment>
            {
              withdrawPending && (
                <Fragment >
                  <h2 className='center15'>
                    <FormattedMessage id="Transaction_Pending_Req_OF" />
                    {currencyLogo}{` ${userWithdraw && currencyConverter(userWithdraw.nAmount)}.`} <FormattedMessage id="Transaction_Cancel_it_wish" /></h2>
                  <center>
                    <Button onClick={() => setCancelWithdrawModal(true)} color="primary" size="lg" className='cancelWithdrawBtn'><FormattedMessage id="Transaction_Cancel_WT_Req" /></Button>
                  </center>
                </Fragment>
              )
            }
            <p className='filter-title'><FormattedMessage id='League_filterBy' />:</p>
            <Nav className="d-flex flex-nowrap align-items-center live-tabs">
              <NavItem><NavLink className={classnames({ active: Tab === 'all' })} onClick={() => {
                changeTab('all')
              }} ><FormattedMessage id="Transaction_All" /></NavLink></NavItem>
              <NavItem><NavLink className={classnames({ active: Tab === 'cash' })} onClick={() => {
                changeTab('cash')
              }} ><FormattedMessage id="Transaction_Cash" /></NavLink></NavItem>
              <NavItem><NavLink className={classnames({ active: Tab === 'bonus' })} onClick={() => {
                changeTab('bonus')
              }} ><FormattedMessage id="Transaction_Bonus" /></NavLink></NavItem>
            </Nav>
            <div>
              {
                TransactionList && TransactionList.length !== 0 && (
                  TransactionList.map((offer, index) => {
                    return (
                      <Fragment key={index} >
                        <div className="transactions-box d-flex align-items-center justify-content-between" onClick={() => checkMatchDetails(offer)}>
                          <div className="d-flex align-items-center width80">
                            {
                              offer.eType === 'Cr'
                                ? <img src={Increace}></img>
                                : offer.eType === 'Dr' && <img src={decr}></img>
                            }
                            <div>
                              <b className="ammount"> {currencyLogo}{
                                Tab === 'all'
                                  ? currencyConverter(offer.nAmount)
                                  : Tab === 'cash'
                                    ? currencyConverter(offer.nCash)
                                    : Tab === 'bonus' &&
                                    currencyConverter(offer.nBonus)
                              } </b>
                              {/* <span>{offer.eTransactionType ? offer.eTransactionType : '-'}</span> */}
                              <span> {offer.sRemarks ? `${offer.sRemarks}` : ''} </span>
                            </div>
                          </div>
                          <span className='width20Center'>{moment(offer.dActivityDate).format('MMM. D, YYYY')}</span>
                        </div>
                        <Collapse isOpen={isOpen && offer.id === transactionID}>
                          <div className="transactions-box d-flex align-items-center justify-content-between" onClick={() => checkMatchDetails(offer)}>
                            <div className="d-flex align-items-center">
                              <div>
                                <span><FormattedMessage id="Transaction_ID" /></span>
                              </div>
                            </div>
                            <span className='center'>{matchData && matchData.iTransactionId ? matchData.iTransactionId : '-'}</span>
                          </div>
                        </Collapse>
                      </Fragment>
                    )
                  })
                )
              }
              {
                paginationLoading && (
                  <Fragment>
                    <center>
                      <Spinner size="70px" className='mt-2 mb-2' color="primary" />
                    </center>
                  </Fragment>
                )
              }
            </div>
          </Fragment>
      }
      {
        TransactionList && TransactionList.length === 0 && (
          <div className='text-center'>
            <h1><FormattedMessage id="No_Transaction" /></h1>
          </div>
        )
      }
      <Modal isOpen={modal} toggle={toggle2}>
        <ModalHeader toggle={toggle2}><h1><FormattedMessage id="Transaction_Details" /></h1></ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleEmail"><FormattedMessage id="Transaction_ID" /></Label>
              <Input type="email" name="email" id="exampleEmail" placeholder="with a placeholder" defaultValue={matchData.id} />
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword"><FormattedMessage id="Transaction_Date" /></Label>
              <Input type="text" name="password" id="examplePassword" placeholder="password placeholder" defaultValue={moment(matchData.dActivityDate ? matchData.dActivityDate : matchData.dCreatedAt).format('MMM. D, YYYY')} />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle2}><FormattedMessage id="Match_Cancel" /></Button>
        </ModalFooter>
      </Modal>
      {filterSlide
        ? <>
          <div className="s-team-bg" onClick={() => setFilterSlide(false)}></div>
          <Card className={classNames('filter-card', { show: filterSlide }) }>
            <CardHeader className='d-flex align-items-center justify-content-between'>
              <button onClick={() => { setFilterSlide(false) }}><i className='icon-left'></i><FormattedMessage id="League_filterBy" /></button>
              <button onClick={() => {
                setStartDate('')
                setLastDate('')
                setFilterBy('')
                ApplyNow()
              }}><FormattedMessage id="League_clear_all" /></button>
            </CardHeader>
            <CardBody className='filter-box noBorder'>
              <Row>
                <Col>
                  <h3><FormattedMessage id="pointSystem_From" /></h3>
                  <ul className='m-0 d-flex align-item-center flex-wrap'>
                    <li style={{ width: '100%' }}>
                      <Input type='date' max={lastDate ? moment(lastDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')} id='StartDate' name='filter' value={startDate} onChange={(e) => {
                        setStartDate(e.target.value)
                        setFilterBy('')
                      }} block />
                    </li>
                  </ul>
                </Col>
                <Col>
                  <h3><FormattedMessage id="pointSystem_To" /></h3>
                  <ul className='m-0 d-flex align-item-center flex-wrap'>
                    <li>
                      <Input type='date' min={startDate && moment(startDate).format('YYYY-MM-DD')} max={moment(new Date()).format('YYYY-MM-DD')} id='EndDate' name='filter' value={lastDate} onChange={(e) => {
                        setLastDate(e.target.value)
                        setFilterBy('')
                      }} block />
                    </li>
                  </ul>
                </Col>
              </Row>
              </CardBody>
              <CardBody className='filter-box'>
                <h3><FormattedMessage id="League_filterBy" /></h3>
                <ul className='m-0 d-flex align-item-center flex-wrap'>
                <ul className='m-0 d-flex align-item-center flex-wrap'>
                  <li>
                    <input type='checkbox' id='weekFilter' name='filter' className='d-none' checked={FilterBy === 'week'} />
                    <label htmlFor='weekFilter' onClick={() => {
                      FilterBy === 'week' ? setFilterBy('') : setFilterBy('week')
                      setStartDate('')
                      setLastDate('')
                    }}> <FormattedMessage id="pointSystem_This_Week" /></label>
                  </li>
                  <li>
                    <input type='checkbox' id='monthFilter' name='filter' className='d-none' checked={FilterBy === 'month'} />
                    <label htmlFor='monthFilter' onClick={() => {
                      FilterBy === 'month' ? setFilterBy('') : setFilterBy('month')
                      setStartDate('')
                      setLastDate('')
                    }}><FormattedMessage id="pointSystem_This_Month" /></label>
                  </li>
                  <li>
                    <input type='checkbox' id='yearFilter' name='filter' className='d-none' checked={FilterBy === 'year'} />
                    <label htmlFor='yearFilter' onClick={() => {
                      FilterBy === 'year' ? setFilterBy('') : setFilterBy('year')
                      setStartDate('')
                      setLastDate('')
                    }}><FormattedMessage id="pointSystem_This_Year" /></label>
                  </li>
                </ul>
                </ul>
              </CardBody>
            <CardFooter className='p-0 border-0 bg-trnsparent'>
              <Button color='primary-two' className='w-100' onClick={() => {
                if (FilterBy) {
                  const curr = new Date()
                  let startedDate, endedDate
                  if (FilterBy === 'week') {
                    const first = curr.getDate() - curr.getDay() // First day is the day of the month - the day of the week
                    const last = first + 6 // last day is the first day + 6
                    startedDate = new Date(curr.setDate(first)).toUTCString()
                    endedDate = new Date(curr.setDate(last)).toUTCString()
                  } else if (FilterBy === 'month') {
                    startedDate = new Date(curr.getFullYear(), curr.getMonth(), 1).toUTCString()
                    endedDate = new Date(curr.getFullYear(), curr.getMonth() + 1, 0).toUTCString()
                  } else if (FilterBy === 'year') {
                    startedDate = new Date(new Date().getFullYear(), 0, 1)
                    endedDate = new Date(curr).toUTCString()
                  }
                  ApplyNow(FilterBy, startedDate, endedDate)
                } else {
                  ApplyNow(FilterBy, startDate, lastDate)
                }
              }}><FormattedMessage id="User_Apply" /></Button>
            </CardFooter>
          </Card>
          </>
        : ''
        }
      <button onClick={() => {
        setFilterSlide(true)
        setFilterBy(finalFilterBy)
        setStartDate(finalStartDate)
        setLastDate(finalLastDate)
      }} className='bottomRight-btn'><img src={filter}></img>{FilterBy && <div className='active'></div>}</button>
      <Modal isOpen={cancelWithdrawModal} toggle={() => setCancelWithdrawModal(false)} className='cancel-withdraw-modal'>
        <ModalBody className='cancel-withdraw-modal-body d-flex flex-column justify-content-center align-items-center'>
          <div className="first">
            <h2><FormattedMessage id='Confirmation' /></h2>
            <p><FormattedMessage id='Cancel_Withdraw_first' /><span>{currencyLogo}{currencyConverter(userWithdraw?.nAmount)}</span><FormattedMessage id='Cancel_Withdraw_second' /></p>
            <hr />
            <button
              onClick={() => {
                onCancelWithdraw(userWithdraw.id)
                setCancelWithdrawModal(false)
              }}
            >
              <FormattedMessage id='Extra_Yes' />
            </button>
          </div>
          <div className="second">
            <button onClick={() => setCancelWithdrawModal(false)}><FormattedMessage id='Match_Cancel' /></button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

TransactionsPage.propTypes = {
  transactionList: PropTypes.array,
  transactionGivenList: PropTypes.array,
  onCancelWithdraw: PropTypes.func,
  GetTransactionListFun: PropTypes.func,
  toggle: PropTypes.func,
  onGetMatchDetails: PropTypes.func,
  currencyLogo: PropTypes.string,
  onGetLeagueDetails: PropTypes.func,
  onCheckWithdrawLimit: PropTypes.func,
  userWithdraw: PropTypes.shape({
    id: PropTypes.string,
    nAmount: PropTypes.number
  }),
  matchDetails: PropTypes.shape({
    eStatus: PropTypes.string,
    eCategory: PropTypes.string,
    _id: PropTypes.string
  }),
  matchLeagueDetails: PropTypes.shape({
    bCancelled: PropTypes.bool
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  token: PropTypes.string,
  resMessage: PropTypes.string,
  Tab: PropTypes.string,
  loading: PropTypes.bool,
  modalOpen: PropTypes.bool,
  paginationLoading: PropTypes.bool,
  withdrawPending: PropTypes.bool
}

export default Transaction(TransactionsPage)
