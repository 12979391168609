import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
// import infoIcon from '../../../assests/images/info-icon-gray.svg'
import infoIcon from '../../../assests/images/info-icon-green.svg'
import moment from 'moment'
import 'moment/locale/pt-br'
import { FormattedMessage } from 'react-intl'
import { Card, CardBody, CardHeader } from 'reactstrap'
import HomeTeam from '../../../assests/images/Team1.png'
// import { aMatch } from './dummyData'
import AwayTeam from '../../../assests/images/Team2.png'
import versus from '../../../assests/images/versus.svg'
import DefaultMultyFantasyImage from '../../../assests/images/multyFantasyDefaultImage.png'
import qs from 'query-string'
import { currencyConverter } from '../../../helper'

function Tournament (props) {
  const { data, url, history, match, upcoming, live, completed, subIndex, activeState, tournamentInfoList, currencyLogo } = props
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [intervalRef, setIntervalRef] = useState('')
  const [tournamentMatches, setTournamentMatches] = useState(false)
  const [countDown, setCountDown] = useState(false)
  const [flags, setFlags] = useState([])
  const scoreRef = useRef(null)

  useEffect(() => {
    if (data && data.dStartDate) {
      if ((new Date(data.dStartDate) > Date.now() + 86400000) || (new Date(data.dStartDate) < new Date(Date.now()))) {
        setStartDate(moment(data.dStartDate).format('MMM. D, YYYY'))
        setEndDate(moment(data.dEndDate).format('MMM. D, YYYY'))
      } else {
        setIntervalRef(setInterval(() => {
          if (data && (data.eStatus === 'U') && moment(data.dStartDate) < moment(new Date())) {
            setStartDate(moment(data.dStartDate).format('MMM. D, YYYY'))
            setEndDate(moment(data.dEndDate).format('MMM. D, YYYY'))
          } else {
            const duration = moment.duration(moment(data.dStartDate).diff(moment(new Date())))
            setStartDate(`${duration.hours()}h ${duration.minutes()}m  ${duration.seconds()}s `)
            setCountDown(true)
          }
        }, 1000))
      }
      uniqueFlags()
    }
    return () => {
      clearInterval(intervalRef)
    }
  }, [])

  function uniqueFlags () {
    const arr = []
    data?.aMatch?.map((item) => {
      if (item.oHomeTeam) {
        arr.push(item.oHomeTeam.sImage)
      }
      if (item.oAwayTeam) {
        arr.push(item.oAwayTeam.sImage)
      }
      return item
    })
    const uniqueArr = Array.from(new Set(arr))
    setFlags(uniqueArr)
  }

  function handleNavigation () {
    if (upcoming || data.eStatus === 'U') {
      history.push({
        pathname: `/tournament/leagues/${match?.params?.sportsType}/${data?._id}`,
        search: `?${qs.stringify({
      tournamentPage: 'yes',
      homePage: location?.pathname?.includes('/home/') ? 'yes' : undefined
    }
  )}`
      })
    } else if (live || data.eStatus === 'L') {
      history.push({
        pathname: `/tournament/liveleague/${data.iMatchId}/${data?.eCategory?.toLowerCase()}`,
        search: `?${qs.stringify({
      tournamentPage: 'yes'
    })}`,
        state: { tab: subIndex, referUrl: `/matches/${match && match.params && match.params.sportsType}`, matchType: activeState || undefined }
      })
    } else if (completed || data.eStatus === 'CMP' || data.eStatus === 'I') {
      history.push({
        pathname: `/tournament/completedleagues/participated/${data.iMatchId}/${data?.eCategory?.toLowerCase()}`,
        search: `?${qs.stringify({
      tournamentPage: 'yes',
      matchType: activeState || undefined
    })}`,
        state: { tab: subIndex, referUrl: `/matches/${match && match.params && match.params.sportsType}`, matchType: activeState || undefined }
      })
    }
  }

  return (
    <>
      <div className='notification-box tournamet-notification-box' >
        <div className='d-flex justify-content-between tournament-name'
          onClick={() => handleNavigation()}
        >
          <h3>{data?.sName}</h3>
          <div className='tournament-info'
            onClick={(e) => {
              setTournamentMatches(true)
              e.stopPropagation()
            }}
          >
            <img src={infoIcon}/>

          </div>
        </div>

        <div className='row custom-date'
          onClick={() => handleNavigation()}
        >
          <div className='col-12'>
            <div className='row'>
              <div className='col-4'>
                {countDown
                  ? <div className='countDown'>{startDate}</div>
                  : (
                    <>
                      <div className='title'><FormattedMessage id='StartDate' /></div>
                      <div className='date'>{startDate}</div>
                    </>
                    )}
              </div>
              {endDate && (
              <div className='col-5'>
                <div className='title'><FormattedMessage id='EndDate' /></div>
                <div className='date'>{endDate}</div>
              </div>
              )}
            </div>
          </div>
        </div>
        {tournamentInfoList?.length > 0 && tournamentInfoList?.map(tournamentInfo =>
          tournamentInfo?._id === data?._id && (
            <>
              <hr />
              <div key={data._id}
                className='row text-left tournament-details'
                onClick={() => handleNavigation()}
              >
                <div className='col-4'>
                  <div className='tournament-info-title'><FormattedMessage id='Sum_of_prizes' /></div>
                  <div className='tournament-info-data'>
                    {currencyLogo}
                    {' '}
                    {currencyConverter(tournamentInfo?.nTotalPayout)}
                  </div>
                </div>
                <div className='col-4'>
                  <div className='tournament-info-title'><FormattedMessage id='Tournaments' /></div>
                  <div className='tournament-info-data'><FormattedMessage id='Tournaments'>{msg => tournamentInfo?.nTotal + ' ' + msg}</FormattedMessage></div>
                </div>
                <div className='col-4'>
                  <div className='tournament-info-title'><FormattedMessage id='Teams' /></div>
                  <div className='tournament-info-data'>{tournamentInfo?.nTotalJoined}</div>
                </div>
              </div>
            </>
          ))}
        <hr />
        <div className='d-flex justify-content-between tournament-flags-div'>
          <div className="tournament-flags"
            style={{ width: `calc(100% - ${scoreRef?.current?.clientWidth}px)` }}
          >
            {flags.map((item, index) => {
              return item
                ? <img key={index} className='mr-2' src={`${url}${item}`} width={20} />
                : <img key={index} className='mr-2' src={DefaultMultyFantasyImage} width={20} />
            }
            )}
          </div>
          {data && (data.eStatus === 'CMP') && data.nWinnings >= 1 && (
          <div ref={scoreRef}
            className='tournament-win-amount'
          >
            <b className="text-success d-flex">
              {data && data.nWinnings >= 1 && !data.nBonusWin >= 1 && data.aExtraWin?.length === 0
                ? (
                  <Fragment>
                    {' '}
                    <FormattedMessage id="WON">{txt => txt}</FormattedMessage>
                    {parseFloat(data.nWinnings.toFixed(2))}
                    {' '}

                  </Fragment>
                  )
                : data && !data.nWinnings >= 1 && data.nBonusWin >= 1 && data.aExtraWin?.length === 0
                  ? (
                    <Fragment>
                      {' '}
                      <FormattedMessage id="WON">{txt => txt}</FormattedMessage>
                      {parseFloat(data.nBonusWin.toFixed(2))}
                      {' '}
                      Bonus
                      {' '}
                    </Fragment>
                    )
                  : data && !data.nWinnings >= 1 && !data.nBonusWin >= 1 && data.aExtraWin?.length === 1
                    ? (
                      <Fragment>
                        {' '}
                        <FormattedMessage id="ONLY_WON">{txt => txt}</FormattedMessage>
                        {' '}
                        {data.aExtraWin[0]?.sInfo}
                        {' '}
                      </Fragment>
                      )
                    : data && !data.nWinnings >= 1 && !data.nBonusWin >= 1 && data.aExtraWin?.length >= 2
                      ? (
                        <Fragment>
                          {' '}
                          <FormattedMessage id="ONLY_WON_Gadget">{txt => txt}</FormattedMessage>
                          {' '}
                        </Fragment>
                        )
                      : data && !data.nWinnings >= 1 && !data.nBonusWin >= 1 && data.aExtraWin?.length === 0
                        ? ''
                        : (
                          <Fragment>
                            {' '}
                            <FormattedMessage id="WON_Multiple_Prizes">{txt => txt}</FormattedMessage>
                            {' '}
                          </Fragment>
                          )}
            </b>
          </div>
          )}
        </div>
      </div>
      {tournamentMatches
        ? (
          <>
            <div className="s-team-bg" onClick={() => setTournamentMatches(false)} />
            <Card className='filter-card lineupsCard'>
              <CardHeader className='d-flex align-items-center justify-content-between'>
                <button>{data?.sName}</button>
                <button className="icon-close"
                  onClick={() => {
                    setTournamentMatches(false)
                  }}
                />
              </CardHeader>
              <CardBody className='filter-box'>
                {
                data && data.aMatch && data.aMatch?.map((data, index) => {
                  return (
                    <Fragment key={index}>
                      <div className="compare-player bg-white">
                        <div className='p-c-box'>
                          <div className="d-flex align-items-center justify-content-between">
                            <div className='tournament-match-box d-flex align-items-center'>
                              <div className="img">
                                <img alt="" src={data?.oHomeTeam?.sImage !== '' && url ? `${url}${data?.oHomeTeam?.sImage}` : HomeTeam} />
                              </div>
                              <div className="name pl-2">
                                <div className='teamName'>{data && data.oHomeTeam && data.oHomeTeam.sShortName ? data.oHomeTeam.sShortName : data?.oHomeTeam?.sName && data.oHomeTeam.sName.substr(0, 3)}</div>
                              </div>
                            </div>

                            <div>
                              <img className='btn-width-80' src={versus} />
                            </div>

                            <div className='tournament-match-box d-flex align-items-center justify-content-end'>
                              <div className="name pr-2">
                                <div className='teamName'>{data && data.oAwayTeam && data.oAwayTeam.sShortName ? data.oAwayTeam.sShortName : data?.oAwayTeam?.sName && data.oAwayTeam.sName.substr(0, 3)}</div>
                              </div>
                              <div className="img">
                                <img alt="" src={data?.oAwayTeam?.sImage !== '' && url ? `${url}${data?.oAwayTeam?.sImage}` : AwayTeam} />
                              </div>
                            </div>
                          </div>
                          <div className='custom-date text-center pb-2'>{moment(data.dStartDate).format('MMM. D, YYYY h:mm A')}</div>
                        </div>
                      </div>
                    </Fragment>
                  )
                })
              }
              </CardBody>
            </Card>
          </>
          )
        : ''}
    </>
  )
}

Tournament.propTypes = {
  data: PropTypes.object,
  url: PropTypes.string,
  match: PropTypes.object,
  history: PropTypes.object,
  subIndex: PropTypes.number,
  upcoming: PropTypes.bool,
  live: PropTypes.bool,
  completed: PropTypes.bool,
  activeState: PropTypes.string,
  tournamentInfoList: PropTypes.object,
  currencyLogo: PropTypes.string
}

export default Tournament
