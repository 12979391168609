import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import thumbsUp from '../assests/images/thumbsup-teamjoin.svg'
import close from '../assests/images/close-teamjoin.svg'
import cartolaPix from '../assests/images/cartolapix-teamjoin.svg'
import { Alert } from 'reactstrap'

function TeamJoinToast (props) {
  const { message, setModalMessage } = props

  return (
    <div className='custom-alert-wrapper'>
      <Alert className='custom-alert' color="primary">
        <img className='cartola-pix-img' src={cartolaPix} />
        <div className='team-join-toast d-flex'>
          <div>
            <img alt='thumbsUp' src={thumbsUp} />
          </div>
          <div className='message'>
            <p className='title'><FormattedMessage id='Good_luck' /></p>
            <p className='m-0 alert-msg'>{message}</p>
          </div>
          <div className='modal-close-img' onClick={() => setModalMessage(false)}>
            <img src={close} />
          </div>
        </div>
      </Alert>
    </div>
  )
}

TeamJoinToast.propTypes = {
  message: PropTypes.string,
  setModalMessage: PropTypes.func
}

export default TeamJoinToast
