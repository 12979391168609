import React from 'react'
import { IntlProvider, addLocaleData } from 'react-intl'
import en from 'react-intl/locale-data/en'
import pt from 'react-intl/locale-data/pt'
import PropTypes from 'prop-types'
import messages from 'Common/src/intl/messages'
import Setting from 'Common/src/components/SportsLeagueList/Setting'
addLocaleData([...en, ...pt])

const Intl = (props) => {
  const { language, children } = props
  return (
    <IntlProvider locale={language} messages={messages[language]} >
      {children}
    </IntlProvider>
  )
}

Intl.propTypes = {
  language: PropTypes.string,
  children: PropTypes.object
}
export default Setting(Intl)
