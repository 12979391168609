import RegisterPage from 'Common/src/components/Auth/RegisterPage'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Alert, Button, Form, FormGroup, Input, Label } from 'reactstrap'
import { verifyEmail, verifyLength, verifyMobileNumber, verifyPassword } from '../../../helper'
import PropTypes from 'prop-types'
import wrongIcon from '../../../assests/images/wrongIcon.svg'
import rightIcon from '../../../assests/images/rightIcon.svg'
import classNames from 'classnames'
import Loading from '../../../component/Loading'

function ConfirmPassword (props) {
  const {
    SendOTP,
    setModalMessage,
    VerifyOTP,
    resStatus,
    resMessage,
    registerSuccess,
    modalMessage,
    message,
    setMessage,
    setLoading,
    loading,
    location,
    history
  } = props
  const [sendedOtp, setSendedOtp] = useState(false)
  // const [otp, setOTP] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordErr, setPasswordErr] = useState('')
  const [confirmPasswordErr, setConfirmPasswordErr] = useState('')
  const [socialToken, setSocialToken] = useState('')
  const [intervalRef, setIntervalRef] = useState(null)
  // const data = JSON.parse(sessionStorage.getItem('userData'))
  const data = props?.location?.state
  const previousProps = useRef({ resMessage, resStatus, registerSuccess }).current

  useEffect(() => {
    if (data?.mobileNumber && data?.userName && data?.email) {
      if (verifyMobileNumber(data?.mobileNumber) && verifyLength(data?.userName, 3) && verifyEmail(data?.email)) {
        SendOTP(data?.mobileNumber, 'R')
        setSendedOtp(true)
      }
    } else if (data === undefined) {
      history.push('/signup-validate-cpf')
    }
    if (location && location.state && location.state.socialRegisterData) {
      setSocialToken(location.state.token)
    }
  }, [])

  useEffect(() => {
    if (previousProps.registerSuccess !== registerSuccess) {
      if (resMessage && registerSuccess) {
        localStorage.removeItem('cpf')
        history.push('/', { message: resMessage })
      }
    }
    return () => {
      previousProps.registerSuccess = registerSuccess
    }
  }, [registerSuccess])

  useEffect(() => {
    if (sendedOtp) {
      changeClock()
      setTimeout(() => {
        setSendedOtp(false)
      }, 30000)
    }
  }, [sendedOtp])

  // function sendOtp () {
  //   if (data?.mobileNumber && data?.userName && data?.email) {
  //     if (verifyMobileNumber(data?.mobileNumber) && verifyLength(data?.userName, 3) && verifyEmail(data?.email)) {
  //       SendOTP(data?.mobileNumber, 'R')
  //       setSendedOtp(true)
  //     }
  //   }
  // }

  function VerifiedOTP (e) {
    e.preventDefault()
    if ((password ? verifyPassword(password) : false) && (confirmPassword ? (password === confirmPassword) : false)) {
      setLoading(true)
      VerifyOTP(data?.mobileNumber, 'R', 1234, { userName: data?.userName, name: data?.name, email: data?.email, mobileNumber: data?.mobileNumber, Password: password, otp: 1234, referralCode: data?.referralCode, socialToken, Platform: 'W', cpf: data?.cpf })
      // sessionStorage.removeItem('userData')
    } else {
      if (password && confirmPassword) {
        if (password && !verifyPassword(password)) {
          setPasswordErr(<FormattedMessage id="Common_PassLength" />)
        } else if (confirmPassword && !verifyPassword(confirmPassword)) {
          setConfirmPasswordErr(<FormattedMessage id="Common_PassLength" />)
        } else if (password !== confirmPassword) {
          setMessage(<FormattedMessage id="Common_NotMatchPass" />)
        }
      }
      setModalMessage(true)
      setTimeout(() => {
        setModalMessage(false)
      }, 2000)
    }
  }

  const changeClock = () => {
    let duration = 30
    if (duration > 0) {
      setIntervalRef(
        setInterval(() => {
          if (duration >= 0) {
            duration = duration - 1
          }
        }, 1000)
      )
    }
    return () => {
      clearInterval(intervalRef)
    }
  }

  function handleChange (event, type) {
    setModalMessage(false)
    switch (type) {
      case 'PASSWORD':
        // if (confirmPassword === event.target.value) {
        //   setPasswordErr('')
        //   setConfirmPasswordErr('')
        // } else if (verifyPassword(event.target.value)) {
        //   setPasswordErr('')
        // } else {
        //   setPasswordErr(<FormattedMessage id="Common_PassLength" />)
        // }
        setPasswordErr('')
        setPassword(event.target.value)
        break
      case 'CONFIRM_PASSWORD':
        // if (password !== event.target.value) {
        //   setConfirmPasswordErr(<FormattedMessage id="Common_NotMatchPass" />)
        // } else if (verifyPassword(event.target.value)) {
        //   setConfirmPasswordErr('')
        // } else {
        //   setConfirmPasswordErr(<FormattedMessage id="Common_PassLength" />)
        // }
        setConfirmPasswordErr('')

        setConfirmPassword(event.target.value)
        break
        // case 'OTP':
        //   if (!isNaN(event.target.value)) {
        //     if (event.target.value.length === 4) {
        //       setOtpErr('')
        //     } else {
        //       if (event.target.value.length !== 4) {
        //         setOtpErr(<FormattedMessage id="Otp_Length_Number" />)
        //       } else {
        //         setOtpErr(<FormattedMessage id="Common_NumberOTP" />)
        //       }
        //     }
        // setOTP(event.target.value)
        // }
        // break
      default:
        break
    }
  }

  return (
    <>
      {modalMessage
        ? <Fragment>
              <Alert isOpen={modalMessage} color="primary">{message}</Alert>
            </Fragment>
        : ''}
      {loading && <Loading />}
      <Form className="form sign-up pt-4">
        {/* {data?.mobileNumber && <p className="m-msg"><FormattedMessage id="ConfirmPass_digitOtp" />+55 {handleInputValue(data?.mobileNumber)}.</p>} */}
        {/* <FormGroup className="c-input">
          <Input maxLength={4} id="otp" autoFocus value={otp} className={classNames({ 'hash-contain': otp, 'mt-4': true })} onChange={(e) => { handleChange(e, 'OTP') }} required />
          <Label className="label m-0" for="otp"><FormattedMessage id="Login_Otp" /></Label>
            {otp && (!otpErr) && <img src={rightIcon} className='success-img' alt={<FormattedMessage id='Success' />}></img>}
            {otpErr && <img src={wrongIcon} className='error-img' alt={<FormattedMessage id='Error' />}></img>}
          <p className="error-text">{otpErr}</p>
        </FormGroup> */}
        <FormGroup className="c-input">
          <Input autoComplete="off" type='password' value={password} id="password" className={classNames({ 'hash-contain': password, error: passwordErr })} onChange={(e) => { handleChange(e, 'PASSWORD') }} required />
          <Label className="label m-0" for="password"><FormattedMessage id="Login_Password" /></Label>
            {password && verifyPassword(password) && <img src={rightIcon} className='success-img' alt={<FormattedMessage id='Success' />}></img>}
            {password && !verifyPassword(password) && <img src={wrongIcon} className='error-img' alt={<FormattedMessage id='Error' />}></img>}
          <p className="error-text">{passwordErr}</p>
        </FormGroup>
        <FormGroup className="c-input">
          <Input autoComplete="off" type="password" value={confirmPassword} id="confirmPassword" className={classNames({ 'hash-contain': confirmPassword, error: confirmPasswordErr })} onChange={(e) => { handleChange(e, 'CONFIRM_PASSWORD') }} required />
          <Label className="label m-0" for="confirmPassword"><FormattedMessage id="Common_ConfirmPassword" /></Label>
          {confirmPassword && (!confirmPasswordErr) && <img src={rightIcon} className='success-img' alt={<FormattedMessage id='Success' />}></img>}
          {confirmPasswordErr && <img src={wrongIcon} className='error-img' alt={<FormattedMessage id='Error' />}></img>}
          <p className="error-text">{confirmPasswordErr}</p>
        </FormGroup>
        {/* <div className="f-bottom text-center"> */}
          <Button color="primary" type="submit" block title="Get Started" disabled={!password || passwordErr || !confirmPassword || confirmPasswordErr} onClick={VerifiedOTP}><FormattedMessage id="ConfirmPass_getStarted" /></Button>
          {/* <div className="b-link"><Button color='link' onClick={sendOtp} disabled={sendedOtp} className='signup-text' title="ConfirmPass_notmatchCode"><FormattedMessage id="ConfirmPass_notmatchCode" /></Button></div> */}
        {/* </div> */}
      </Form>
    </>
  )
}

ConfirmPassword.propTypes = {
  resMessage: PropTypes.string,
  referralCode: PropTypes.string,
  SendOTP: PropTypes.func,
  VerifyOTP: PropTypes.func,
  Registration: PropTypes.func,
  resStatus: PropTypes.bool,
  sendOtp: PropTypes.bool,
  registerSuccess: PropTypes.bool,
  sendedOTP: PropTypes.bool,
  loading: PropTypes.bool,
  setModalMessage: PropTypes.func,
  history: PropTypes.object,
  modalMessage: PropTypes.bool,
  message: PropTypes.string,
  setMessage: PropTypes.func,
  setLoading: PropTypes.func,
  location: PropTypes.object
}

export default RegisterPage(ConfirmPassword)
