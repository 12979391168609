import React from 'react'
import { connect, useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { history } from '../App'

export const PublicRoute = ({ component: Component, ...rest }) => {
  const tokenId = useSelector(state => state.auth.token) || localStorage.getItem('Token')
  const maintenanceMode = useSelector(state => state.settings.maintenanceMode)
  const activeSports = useSelector(state => state.activeSports.activeSports)
  const activeSport = activeSports && activeSports.length > 0 && activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => data)

  return (
    <Route
      {...rest}
      render={props => maintenanceMode?.bIsMaintenanceMode
        ? <Redirect to={{ pathname: '/maintenance-mode' }} />
        : (tokenId
            ? <Redirect to={
          history.location.pathname.includes('/login') ||
          history.location.pathname.includes('/home/cricket/v1') ||
          history.location.pathname.includes('/home/basketball/v1') ||
          history.location.pathname.includes('/home/football/v1') ||
          history.location.pathname.includes('/home/kabaddi/v1') ||
          history.location.pathname.includes('/home/baseball/v1') ||
          history.location.pathname.includes('/tournaments/cricket/v1') ||
          history.location.pathname.includes('/tournaments/football/v1') ||
          history.location.pathname.includes('/tournaments/basketball/v1') ||
          history.location.pathname.includes('/tournaments/kabaddi/v1') ||
          history.location.pathname.includes('/tournaments/baseball/v1') ||
          history.location.pathname.includes('/leagues/cricket/:id/v1') ||
          history.location.pathname.includes('/leagues/basketball/:id/v1') ||
          history.location.pathname.includes('/leagues/football/:id/v1') ||
          history.location.pathname.includes('/leagues/kabaddi/:id/v1') ||
          history.location.pathname.includes('/matches/cricket/v1') ||
          history.location.pathname.includes('/matches/basketball/v1') ||
          history.location.pathname.includes('/matches/football/v1') ||
          history.location.pathname.includes('/matches/kabaddi/v1') ||
          history.location.pathname.includes('/matches/baseball/v1') ||
          history.location.pathname.includes('/game/leader-board/v1') ||
          history.location.pathname.includes('/profile/v1') ||
          history.location.pathname.includes('/more/v1') ||
          history.location.pathname.includes('/point-system/v1') ||
          history.location.pathname === '/signup-validate-cpf' ||
          history.location.pathname === '/confirm-password' ||
          history.location.pathname === '/forgot-password' ||
          history.location.pathname === '/verification' ||
          history.location.pathname === '/' ||
          history.location.pathname === '/sign-up'
            ? activeSport && `/home/${activeSport[0]?.sKey.toLowerCase()}`
            : history.location.pathname} />
            : <Component {...props} />)}
    />
  )
}

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.elementType.isRequired
}

const mapStateToProps = state => ({
  isAuthenticated: !!state.auth.token
})

export default connect(mapStateToProps, null, null, { pure: false })(PublicRoute)
