import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

function SkeletonTable (props) {
  const { Lines, series } = props
  return (
    <Fragment>
      {Array(Lines || 4)
        .fill()
        .map((index, i) => (
          <tr key={i} className={series && 'mt-3 mb-3'}>
            <td>
              {' '}
              <Skeleton width="40%" />
              {' '}
            </td>
            <td className='text-center'>
              {' '}
              <Skeleton width="20%" />
              {' '}
            </td>
            <td className='text-center'>
              {' '}
              <Skeleton width="20%" />
              {' '}
            </td>
            {
              series && (
              <td className='align_right'>
                <Skeleton width="20%" />
              </td>
              )
            }
          </tr>
        ))}
    </Fragment>
  )
}

SkeletonTable.propTypes = {
  numberOfColumns: PropTypes.number,
  Lines: PropTypes.number,
  series: PropTypes.bool
}

export default SkeletonTable
