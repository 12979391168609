import React from 'react'
import { FormattedMessage } from 'react-intl'
import UserHeader from '../../components/UserHeader'
import PaymentPage from './PaymentScreen'

function Payment (props) {
  return (
    <>
      <UserHeader
        {...props}
        title={<FormattedMessage id='PaymentCode' />}
        backURL='/deposit'
        />
      <PaymentPage {...props} />
    </>
  )
}

export default Payment
