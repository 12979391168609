/* eslint-disable no-unused-vars */
import React, { useState, Fragment, useEffect } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Nav, NavItem, NavLink, TabContent, Table } from 'reactstrap'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useQueryState } from 'react-router-use-location-state'
import { FormattedMessage } from 'react-intl'
import qs from 'query-string'
import { currencyConverter, isUpperCase } from '../../../helper'
import MyUpcomingMatch from '../components/MyUpcomingMatch'
import UserHome from 'Common/src/components/User/UserHome'
import Tournaments from '../Tournament/Tournaments'

function Matches (props) {
  const { location, mainIndex, match, history, paymentSlide, setPaymentSlide, currencyLogo, userInfo, onGetUserProfile, tournaments } = props
  const [activeTab, setActiveTab] = useState('1')
  const [activeState, setActiveState] = useQueryState('matchType', 'UP')
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  useEffect(() => { // handle the response
    if (location && location.state && location.state.tab) {
      toggle(`${location.state.tab}`)
    }
    const obj = qs.parse(location.search)
    if (obj?.matchType) {
      const active = obj.matchType === 'UP' ? '1' : obj.matchType === 'L' ? '2' : obj.matchType === 'CMP' ? '3' : ''
      setActiveState(obj.matchType)
      toggle(active)
    }
  }, [])
  useEffect(() => { // handle the response
    if (match?.params?.sportsType) {
      const sport = match.params.sportsType
      isUpperCase(sport) && history.push(`/matches/${sport.toLowerCase()}`)
    }
  }, [match.params.sportsType])

  useEffect(() => {
    if (paymentSlide) {
      onGetUserProfile()
    }
  }, [paymentSlide])

  return (
    <>
      <Nav className="live-tabs justify-content-around">
        <NavItem className="text-center">
          <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => {
            toggle('1')
            setActiveState('UP')
          }}><FormattedMessage id="Matches_Upcoming" /></NavLink>
        </NavItem>
        <NavItem className="text-center">
          <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => {
            toggle('2')
            setActiveState('L')
          }}><FormattedMessage id="Matches_Live" /></NavLink>
        </NavItem>
        <NavItem className="text-center">
          <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => {
            toggle('3')
            setActiveState('CMP')
          }}><FormattedMessage id="Matches_Completed" /></NavLink>
        </NavItem>
      </Nav>
      {tournaments
        ? <div className="home-container tournament-container-height">
        <TabContent activeTab={activeTab}>
          <Fragment>
            <Tournaments {...props} mainIndex={mainIndex} activeState={activeState} subIndex={parseInt(activeTab)} />
          </Fragment>
        </TabContent>
      </div>
        : <div className="home-container">
        <TabContent activeTab={activeTab}>
          <Fragment>
            <MyUpcomingMatch {...props} mainIndex={mainIndex} subIndex={parseInt(activeTab)} />
          </Fragment>
        </TabContent>
        </div>}

      {paymentSlide
        ? <>
            <div className="s-team-bg" onClick={() => setPaymentSlide(false)}></div>
            <Card className='filter-card'>
              <CardHeader className='d-flex align-items-center justify-content-between'>
                <button onClick={() => { setPaymentSlide(false) }}><FormattedMessage id='Wallet_Details' /></button>
                <button onClick={() => setPaymentSlide(false)}><FormattedMessage id='League_Close' /></button>
              </CardHeader>
              <CardBody className='payment-box'>

              <Table className="m-0 bg-white payment">
                <thead>
                  <tr className='text-center'> <th colSpan='2'><FormattedMessage id="PROFILE_TOTAL_BALANCE" /></th> </tr>
                  <tr className='text-center'> <th colSpan='2'>{currencyLogo}{userInfo && userInfo.nCurrentTotalBalance ? currencyConverter(userInfo.nCurrentTotalBalance) : 0} </th> </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><FormattedMessage id="PROFILE_DEP_BALANCE" /></td>
                    <td className='rightAlign'>{currencyLogo}{userInfo && userInfo.nCurrentDepositBalance ? currencyConverter(userInfo.nCurrentDepositBalance) : 0}</td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="PROFILE_WIN_BALANCE" /></td>
                    <td className='rightAlign'>{currencyLogo}{userInfo && userInfo.nCurrentWinningBalance ? currencyConverter(userInfo.nCurrentWinningBalance) : 0}</td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="PROFILE_CASH_BONUS" /></td>
                    <td className='rightAlign'>{currencyLogo}{userInfo && userInfo.nCurrentBonus ? currencyConverter(userInfo.nCurrentBonus) : 0}</td>
                  </tr>
                </tbody>
              </Table>
              </CardBody>
              <CardFooter className='border-0 bg-white p-0'>
                <Button color='primary-two' onClick={() => props.history.push('/deposit')} className='w-100'><FormattedMessage id="PROFILE_ADD_CASH" /></Button>
              </CardFooter>
            </Card>
          </>
        : ''
        }
    </>
  )
}

Matches.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.object
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      activeTab: PropTypes.number,
      tab: PropTypes.string
    }),
    search: PropTypes.string
  }),
  pathName: PropTypes.string,
  history: PropTypes.object,
  mainIndex: PropTypes.number,
  paymentSlide: PropTypes.bool,
  setPaymentSlide: PropTypes.func,
  currencyLogo: PropTypes.string,
  userInfo: PropTypes.object,
  onGetUserProfile: PropTypes.func,
  tournaments: PropTypes.bool
}
export default UserHome(Matches)
