import React, { useState, lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import LeagueHeader from '../components/LeagueHeader'
import Loading from '../../../component/Loading'
import qs from 'query-string'
import { useLocation } from 'react-router-dom'
const CreateTeamPage = lazy(() => import('./CreateTeam'))

function CreateTeam (props) {
  const { match, history } = props
  const location = useLocation()
  const [nextStep, setNextStep] = useState(true)
  const [VideoStream, setVideoStream] = useState(false)
  const obj = qs.parse(location.search)

  return (
    <>
      <LeagueHeader
        {...props}
        VideoStream={VideoStream}
        backTab={location?.state?.activeTab}
        goToBack={history?.location?.pathname?.includes('tournament') ? `/tournament/leagues/${match.params.sportsType}/${match.params.id}` : `/leagues/${match.params.sportsType}/${match.params.id}`}
        nextStep={nextStep}
        notShowing
        search={obj?.homePage ? 'homePage=yes' : ''}
        setNextStep={setNextStep}
        setVideoStream={setVideoStream}
      />
      <Suspense fallback={<Loading />}>
        <CreateTeamPage {...props} nextStep={nextStep} setNextStep={setNextStep}/>
      </Suspense>
    </>
  )
}
CreateTeam.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      sportsType: PropTypes.string
    })
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object
  }),
  history: PropTypes.object
}
export default CreateTeam
