import React, { lazy, Suspense } from 'react'
import UserHeader from '../../User/components/UserHeader'
import PropTypes from 'prop-types'
import Loading from '../../../component/Loading'
import { FormattedMessage } from 'react-intl'
const MatchTipsPage = lazy(() => import('./MatchTips'))

function MatchTips (props) {
  return (
    <>
      <UserHeader {...props} title={<FormattedMessage id='League_matchTips' />} />
      <Suspense fallback={<Loading />}>
        <MatchTipsPage {...props}/>
      </Suspense>
    </>
  )
}

MatchTips.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
}

export default MatchTips
