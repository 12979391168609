import React, { useState, useRef, useEffect, Fragment } from 'react'
import {
  Alert, Button, Input,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators
} from 'reactstrap'
import UserDeposit from 'Common/src/components/User/UserDeposit'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Loading from '../../../component/Loading'
import { currencyConverter } from '../../../helper'

function DepositPage (props) {
  const {
    resMessage,
    loading,
    modalMessage,
    url,
    location,
    history,
    appliedPromocode,
    setApplied,
    isAddedDeposit,
    promoCodeList, paymentList, amountData,
    activeTab,
    setActiveTab,
    setLoading,
    // AddingCashFree,
    paymentLink,
    currencyLogo,
    settingValidation,
    bannerImg,
    matchDetails, matchLeagueDetails,
    onGetMatchDetails, onGetLeagueDetails, onBannerStatictics,
    checkPromocode,
    userInfo,
    paymentData,
    CreatePayment
  } = props
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)
  const [promoData, setPromoData] = useState({ sPromo: '', nAmount: 0 })
  const [PromoCodes, setPromoCodes] = useState(false)
  const [PlistData, setPlistData] = useState([])
  const [message, setMessage] = useState('')
  const [alert, setAlert] = useState(false)
  const [depositData, setDeposit] = useState([])
  const [validationDeposit, setValidationDeposit] = useState({})
  const [error, setError] = useState('')
  const [PaymentGateway, setPaymentGateway] = useState('')
  const [matchData, setMatchData] = useState({})
  const previousProps = useRef({ matchDetails, promoCodeList, paymentList, amountData, location, appliedPromocode, isAddedDeposit, paymentData }).current

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === bannerImg.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? bannerImg.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  useEffect(() => { // hanlde the response
    if (previousProps.promoCodeList !== promoCodeList) {
      if (promoCodeList) setPlistData(promoCodeList)
      setLoading(false)
    }
    return () => { previousProps.promoCodeList = promoCodeList }
  }, [promoCodeList])

  useEffect(() => { // hanlde the response
    if (settingValidation) {
      setValidationDeposit(settingValidation)
      if (!location?.state?.amountData) {
        setPromoData({ sPromo: '', nAmount: settingValidation?.nMin })
      }
    }
  }, [settingValidation])

  useEffect(() => { // hanlde the response
    if (previousProps.amountData !== amountData) {
      if (amountData && amountData.nAmount) {
        setPromoData({ ...promoData, nAmount: parseFloat(Number((amountData.nAmount)).toFixed(2)) })
      }
    }
    return () => {
      previousProps.amountData = amountData
    }
  }, [amountData && amountData.nAmount])

  useEffect(() => { // hanlde the response
    if (previousProps.checkPromocode !== checkPromocode) {
      if (checkPromocode === false) {
        clearPromo()
      }
    }
    return () => {
      previousProps.checkPromocode = checkPromocode
    }
  }, [checkPromocode])
  useEffect(() => { // hanlde the response
    if (location && location.state && location.state.amountData) {
      setPromoData({ ...promoData, nAmount: parseFloat(Number((location.state.amountData.nAmount)).toFixed(2)) })
    }
  }, [location.state && location.state.amountData && location.state.amountData.nAmount])

  useEffect(() => {
    if (location && location.state) {
      setMessage(location.state.message)
      setAlert(true)
      setTimeout(() => {
        setAlert(false)
      }, 2000)
    }
  }, [location && location.state])

  useEffect(() => { // hanlde the response
    if (previousProps.paymentList !== paymentList) {
      if (paymentList) setDeposit(paymentList)
      setLoading(false)
    }
    return () => { previousProps.paymentList = paymentList }
  }, [paymentList])

  useEffect(() => { // hanlde the response
    if (promoData && (promoData.nAmount > validationDeposit?.nMax || promoData.nAmount < validationDeposit?.nMin)) {
      setError(<Fragment><FormattedMessage id="Deposit_Between_To" /> {`${currencyLogo}${currencyConverter(validationDeposit?.nMin)}`} <FormattedMessage id="Common_and" /> {`${currencyLogo}${currencyConverter(validationDeposit?.nMax)}`} </Fragment>)
    }
  }, [promoData])

  useEffect(() => { // hanlde the response
    if (previousProps.paymentLink !== paymentLink) {
      if (paymentLink) {
        if (userInfo && !userInfo.bIsInternalAccount) {
          history.goBack()
          const newWindow = window.open(paymentLink, '_self', 'noopener,noreferrer')
          if (newWindow) newWindow.opener = null
        }
      }
    }
    return () => { previousProps.paymentLink = paymentLink }
  }, [paymentLink])

  useEffect(() => { // hanlde the response
    if (previousProps.isAddedDeposit !== isAddedDeposit) {
      if (isAddedDeposit) {
        if (userInfo && userInfo.bIsInternalAccount) {
          history.push('/profile')
        }
        setLoading(false)
      }
    }
    return () => { previousProps.isAddedDeposit = isAddedDeposit }
  }, [isAddedDeposit])

  useEffect(() => {
    if (previousProps.paymentData !== paymentData) {
      if (paymentData) {
        history.push('/payment', { paymentData, amount: promoData?.nAmount })
      }
    }
    return () => {
      previousProps.paymentData = paymentData
    }
  }, [paymentData])

  const handleChange = (e) => { // Change the value
    // if (e.target.value > validationDeposit?.nMax || e.target.value < validationDeposit?.nMin) setError(<Fragment><FormattedMessage id="Deposit_Between_To" /> {`${currencyLogo} ${validationDeposit?.nMin}`} <FormattedMessage id="Common_and" /> {`${currencyLogo} ${validationDeposit?.nMax}`} </Fragment>)
    if (e.target.value > validationDeposit?.nMax) {
      setError(`${validationDeposit?.sMaxMessage}`)
    } else if (e.target.value < validationDeposit?.nMin) {
      setError(`${validationDeposit?.sMinMessage}`)
    } else setError('')
    if (promoData?.sPromo && PlistData?.length > 1) {
      const dFata = PlistData.filter(data => data.sCode === promoData?.sPromo)
      if (dFata && !(promoData.nAmount > dFata.nMinAmount && promoData.nAmount < dFata.nMaxAmount)) {
        clearPromo()
        // setPromoData({ nAmount: parseFloat(Number((e.target.value)).toFixed(2)), sPromo: '' })
        setPromoData({ nAmount: e.target.value, sPromo: '' })
      }
    }
    // setPromoData({ ...promoData, nAmount: parseFloat(Number((e.target.value)).toFixed(2)) })
    setPromoData({ ...promoData, nAmount: e.target.value })
  }

  // const applePromoCode = (name) => {
  //   setPromoData({ ...promoData, sPromo: name })
  //   applyPromo({ nAmount: promoData.nAmount, sPromo: name })
  //   setPromoCodes(false)
  // }

  const addDeposit = () => {
    const data = {
      eType: PaymentGateway, nAmount: promoData.nAmount || 0, sPromocode: promoData.sPromo || ''
    }
    CreatePayment(data)
    setLoading(true)
    // setActiveTab(true)
    // if (promoData && promoData.nAmount && PaymentGateway !== 'CASHFREE') {
    //   AddingCashFree(PaymentGateway, promoData.nAmount, 'W', 'INR', checkPromocode ? promoData.sPromo : '')
    //   setActiveTab(true)
    // } else {
    //   AddingCashFree(promoData.nAmount, PaymentGateway, 'W', 'INR', checkPromocode ? promoData.sPromo : '')
    //   setActiveTab(true)
    // }
  }
  const handleonKeyPress = (e) => {
    if (e.key === '+' || e.key === '-' || e.key === 'e' || e.key === 'E') {
      e.preventDefault()
    }
  }

  function clearPromo () {
    setApplied(false)
    setPromoData({ ...promoData, sPromo: '' })
  }

  function PageChange () {
    // const data = {
    //   eType: 'PAYBROKERS', nAmount: promoData.nAmount || 0, sPromocode: promoData.sPromo || ''
    // }
    // CreatePayment(data)
    // setLoading(true)
    setActiveTab(false)
    setPaymentGateway('')
  }

  const goToIndex = (newIndex) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  useEffect(() => { // handle the response
    if (previousProps.matchDetails !== matchDetails) {
      if (matchDetails && matchDetails._id && matchDetails.eStatus === 'U') {
        if (matchData && matchData.iMatchId && matchData.iMatchLeagueId && matchLeagueDetails && !matchLeagueDetails.bCancelled) {
          history.push(`/leagues-detail/${(matchData.eCategory).toLowerCase()}/${matchData.iMatchId}/${matchData.iMatchLeagueId}`)
        } else if (matchData && matchData.iMatchId) {
          history.push(`/leagues/${(matchData.eCategory).toLowerCase()}/${matchData.iMatchId}`)
        }
      } else if (matchDetails && matchDetails._id && matchDetails.eStatus === 'L') {
        if (matchData && matchData.iMatchId && matchData.iMatchLeagueId && matchData.eTransactionType !== 'Play-Return' && matchLeagueDetails && !matchLeagueDetails.bCancelled) {
          history.push(`/leagues-details/${(matchData.eCategory).toLowerCase()}/${matchData.iMatchId}/${matchData.iMatchLeagueId}/participated`)
        } else if (matchData && matchData.iMatchId) {
          history.push(`/liveleague/${matchData.iMatchId}/${(matchData.eCategory).toLowerCase()}`)
        }
      } else if (matchDetails && matchDetails._id && (matchDetails.eStatus === 'CMP' || matchDetails.eStatus === 'I')) {
        if (matchData && matchData.iMatchId && matchData.iMatchLeagueId && matchData.eTransactionType !== 'Play-Return' && matchLeagueDetails && !matchLeagueDetails.bCancelled) {
          history.push(`/leagues-details/${(matchData.eCategory).toLowerCase()}/${matchData.iMatchId}/${matchData.iMatchLeagueId}/participated`)
        } else if (matchData && matchData.iMatchId) {
          history.push(`/completedleagues/participated/${matchData.iMatchId}/${(matchData.eCategory).toLowerCase()}`)
        }
      }
    }
    return () => {
      previousProps.matchDetails = matchDetails
    }
  }, [matchDetails])

  async function imageShawing (data) { // redirect to the click on banner
    setMatchData(data)
    if (data && data.eType === 'L') {
      const newWindow = window.open(data.sLink, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
    if (data && data.eType === 'S' && data.eScreen === 'D') {
      history.push('/deposit')
    }
    if (data && data.eType === 'S' && data.eScreen === 'S') {
      history.push({ pathname: '/page', state: { contentDetails: data.sDescription } })
    }
    if (data && data.eType === 'S' && data.eScreen === 'CR') {
      if (data && data.iMatchId && data.iMatchLeagueId) {
        await onGetLeagueDetails(data && data.iMatchLeagueId)
        await onGetMatchDetails(data && data.iMatchId)
      } else if (data && data.iMatchId) {
        onGetMatchDetails(data && data.iMatchId)
      }
    }
    data && data.key && onBannerStatictics(data && data.key)
  }

  const slides = bannerImg.map((item, index) => {
    return (
      <CarouselItem
        key={index}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <img src={item && item.src ? `${url}${item?.src}` : ''} key={`${item.key}`} alt={'banner'} onClick={() => imageShawing(item)} />
      </CarouselItem>
    )
  })
  return (
    <>
      { modalMessage ? <Alert isOpen={modalMessage} color="primary">{resMessage}</Alert> : ''}
      { alert && message ? <Alert isOpen={alert} color="primary">{message}</Alert> : ''}
      {loading && <Loading />}
      <div className='home-container deposit-banner'>
        <Fragment>
          {bannerImg && bannerImg.length > 0 &&
            (
              <div>
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                  className='home-banner'
                >
                  <CarouselIndicators items={bannerImg.sort((a, b) => a.nPosition - b.nPosition)} activeIndex={activeIndex} onClickHandler={goToIndex} />
                  {slides}
                  {
                    bannerImg?.length > 1 && (
                      <Fragment>
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                      </Fragment>
                    )
                  }
                </Carousel>
              </div>
            )
          }
        </Fragment>
      </div>
      <div className="user-container bg-white with-footer deposit-screen" onClick={() => { if (PromoCodes) setPromoCodes(false) }}>

        {activeTab
          ? <div className="form pb-0 pt-3 deposit-o" >
            <p className="m-msg text-left mt-0"><FormattedMessage id="Deposit_AmountBalance" /></p>
            <div className="position-relative">
              <span className="c-icon">{currencyLogo} </span>
              <Input type="number" autoComplete='off' className="m" onChange={handleChange} value={promoData.nAmount} onKeyPress={handleonKeyPress}
/>
              {error && <p className="error-text">{error}</p>}
            </div>
            <ul className="d-flex select-m">
              <li onClick={() => { setPromoData({ ...promoData, nAmount: promoData.nAmount ? parseInt(promoData.nAmount) + 5 : 5 }); setError('') }}>+ {currencyLogo}{currencyConverter(5)}</li>
              <li onClick={() => { setPromoData({ ...promoData, nAmount: promoData.nAmount ? parseInt(promoData.nAmount) + 10 : 10 }); setError('') }}>+ {currencyLogo}{currencyConverter(10)}</li>
              <li onClick={() => { setPromoData({ ...promoData, nAmount: promoData.nAmount ? parseInt(promoData.nAmount) + 20 : 20 }); setError('') }}>+ {currencyLogo}{currencyConverter(20)}</li>
            </ul>
            {/* <div className="ul-p-bar"></div>
            <p className="m-msg text-left mt-0"><FormattedMessage id="Have_Promocode" /></p>
            <FormGroup className="c-input mt-4">
              <Input onClick={() => { setPromoCodes(true) }} disabled={!promoData.nAmount} autoComplete='off' type="text" id="Promocoden" readOnly className={`bg-white ${appliedPromocode && promoData.sPromo ? 'hash-contain applied' : ''}`} required value={appliedPromocode ? `${promoData.sPromo}` + ' Applied!' : ''} />
              {!promoData.appliedPromocode && !promoData.sPromo && <Label className="no-change label m-0" for="Promocoden"><FormattedMessage id="User_Promocode" /></Label>}
              {appliedPromocode && promoData.sPromo &&
                <button className="i-icon" onClick={clearPromo}><FormattedMessage id='Remove' /></button>}
            </FormGroup> */}
            <div className="btn-bottom position-relative my-3">
              <Button disabled={!promoData?.nAmount || error} onClick={PageChange} color="primary" className="w-100 d-block"><FormattedMessage id="User_proceed" /></Button>
            </div>
          </div>
          : <div className="form pb-0 pt-3 deposit-o" >
            {/* <p className="m-msg text-left mt-0 mb-2"><FormattedMessage id="User_AMZandCaseback" /></p> */}
            <ul className="payment-o">
              {depositData !== {} && depositData && depositData.length > 0 && depositData.map((data, index) => {
                return (data.eKey !== 'CASHFREE_UPI' &&
                  <li key={data._id} className="d-flex align-items-center">
                    <Input type="radio" name="Deposit" autoComplete='off' id={`card${index}`} className="d-none" onClick={() => setPaymentGateway(data?.eKey)}/>
                    <label htmlFor={`card${index}`} value={data.eKey}>
                      <img src={url + data.sImage} className='fix40HeiWid'/>
                      <span className='ml-3'>
                        {data.sName}
                        <br />
                        {data?.sOffer}
                      </span>
                      <br/>
                    </label>
                  </li>
                )
              })}
            </ul>
            <div className="btn-bottom position-relative my-3">
              <Button disabled={!PaymentGateway} onClick={addDeposit} color="primary" className="w-100 d-block"><FormattedMessage id="Add" />  <FormattedMessage id="Team_Rupee" />{promoData.nAmount}</Button>
            </div>
          </div>
        }
      </div>
      {/* {PromoCodes
        ? <>
          <div className="s-team-bg" onClick={() => setPromoCodes(false)}></div>
          <Card className="filter-card show promo-card">
          <CardHeader className="d-flex align-items-center justify-content-between">
            <button><FormattedMessage id="User_Promocodes" /></button>
            <button onClick={() => { setPromoCodes(false) }} className="icon-close"></button>
          </CardHeader>
          <CardBody className='p-10'>
            <FormGroup className="c-input mt-2 mb-0">
              <Input type="text" id="Promocode" autoComplete='off' value={promoData.sPromo} required onChange={(e) => setPromoData({ ...promoData, sPromo: e.target.value })} />
              {!promoData.sPromo && <Label className="no-change label m-0" for="Promocode"><FormattedMessage id="Enter_Promocode" /></Label>}
              {
                promoData.sPromo && (
                  <Fragment>
                    <button className="i-icon" onClick={() => applePromoCode(promoData.sPromo)}><FormattedMessage id="User_Apply" /></button>
                  </Fragment>
                )
              }
            </FormGroup>
            <div className="p-title"><FormattedMessage id="User_promocodeForU" /></div>
            {PlistData !== {} && PlistData && PlistData.length > 0 && PlistData.map(data => {
              return (
                <div key={data._id} className="d-flex align-items-center justify-content-between promo-box">
                  <div>
                    <b>{data.sCode}</b>
                    <p>{data.sInfo}</p>
                  </div>
                  <Button color="white" onClick={() => applePromoCode(data.sCode)}><FormattedMessage id="User_Apply" /></Button>
                </div>
              )
            })}
          </CardBody>
          </Card>
          </>
        : ''
      } */}
    </>
  )
}
DepositPage.propTypes = {
  onGetMatchDetails: PropTypes.func,
  onGetUserProfile: PropTypes.func,
  onGetLeagueDetails: PropTypes.func,
  onBannerStatictics: PropTypes.func,
  matchDetails: PropTypes.shape({
    _id: PropTypes.string,
    eStatus: PropTypes.string
  }),
  matchLeagueDetails: PropTypes.shape({
    bCancelled: PropTypes.bool
  }),
  resMessage: PropTypes.string,
  resStatus: PropTypes.bool,
  promoCodeList: PropTypes.array,
  paymentList: PropTypes.array,
  settingValidation: PropTypes.object,
  applyPromo: PropTypes.func,
  applePromoCode: PropTypes.func,
  loading: PropTypes.bool,
  modalMessage: PropTypes.bool,
  PromoCodes: PropTypes.bool,
  applied: PropTypes.bool,
  appliedPromocode: PropTypes.bool,
  checkPromocode: PropTypes.bool,
  depositData: PropTypes.array,
  PlistData: PropTypes.array,
  handleChange: PropTypes.func,
  error: PropTypes.string,
  setError: PropTypes.func,
  setApplied: PropTypes.func,
  AddingCashFree: PropTypes.func,
  onJoinContest: PropTypes.func,
  amount: PropTypes.number,
  amountData: PropTypes.object,
  bannerImg: PropTypes.array,
  privateLeagueValidation: PropTypes.array,
  location: PropTypes.shape({
    state: PropTypes.shape({
      activeTab: PropTypes.number,
      joinData: PropTypes.object,
      amountData: PropTypes.shape({
        nAmount: PropTypes.number
      }),
      message: PropTypes.string
    }),
    search: PropTypes.string
  }),
  history: PropTypes.object,
  setActiveTab: PropTypes.func,
  isAddedDeposit: PropTypes.bool,
  activeTab: PropTypes.bool,
  url: PropTypes.string,
  paymentLink: PropTypes.string,
  currencyLogo: PropTypes.string,
  setLoading: PropTypes.func,
  userInfo: PropTypes.object,
  paymentData: PropTypes.object,
  CreatePayment: PropTypes.func
}
export default UserDeposit(DepositPage)
