import React, { useEffect } from 'react'
import { Row } from 'reactstrap'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Logo from '../assests/images/vrf_logo.svg'
import { GetActiveSports } from 'Common/src/redux/actions/activeSports'
import maintenance from '../assests/images/maintenance.svg'

function Maintenance (props) {
  const dispatch = useDispatch()
  const maintenanceMode = useSelector(state => state.settings.maintenanceMode)
  const activeSports = useSelector(state => state.activeSports.activeSports)
  const activeSport = activeSports && activeSports.length > 0 && activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => data)

  useEffect(() => {
    dispatch(GetActiveSports())
  }, [])

  useEffect(() => {
    if (!(maintenanceMode?.bIsMaintenanceMode) && activeSport) {
      props.history.push(`/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}`)
    }
  }, [activeSport])
  return (
    <div className="d-flex justify-content-center align-items-center maintenance h-100">
      <Row>
        <div className='notFound'>
          <img alt={<FormattedMessage id='Cartola_Pix' />} src={Logo} style={{ height: '66px' }} />
          <br/>
          {' '}
          <br/>
          <h1>{maintenanceMode?.sMessage || <FormattedMessage id="NotFound_Page" />}</h1>
          <br/>
          <img alt={<FormattedMessage id='Player_Image' />} src={maintenance} style={{ height: '166px' }} />
        </div>
      </Row>
    </div>
  )
}

Maintenance.propTypes = {
  sMessage: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

export default Maintenance
