import React, { Fragment } from 'react'
import PlayerDetails from 'Common/src/components/SportsLeagueList/PlayerDetails'
import Loading from '../../../component/SkeletonTable'
import { Table } from 'reactstrap'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { cricketStaticImage, footballStaticImage } from '../../../helper'
import PlayerImage from '../../../../src/assests/images/PlayerImage.png'

function PlayerInfo (props) {
  const { playerData, pointBreakUp, nScoredPoints, loading, match } = props
  return (
    <>
      {loading && <Loading series Lines={7}/>}
      <div className="player-info">
        <div className="league-header u-header">
          <div className="d-flex align-items-center header-i">
            <button onClick={() => props.onBackClick()} className="btn-link icon-left-arrow"></button>
            <div>
              <h1 className="text-uppercase">{playerData && playerData.sName}</h1>
            </div>
          </div>
        </div>
        <div className="player-detail d-flex align-items-center">
          <div className="p-i">
            <img className='h-100 w-100 borderRadius' src={match && match.params && match.params.sportsType === 'cricket' ? cricketStaticImage(playerData && playerData.eRole && playerData.eRole) : match && match.params && match.params.sportsType === 'football' ? footballStaticImage(playerData && playerData.eRole && playerData.eRole) : PlayerImage} alt="" />
          </div>
          <div className="player-d-i d-flex flex-wrap">
            <div className="item">
              <p><FormattedMessage id="Player_selectedBy" /></p>
              <b>{playerData && playerData.nSetBy ? playerData.nSetBy : <FormattedMessage id="Team_0" />}<FormattedMessage id="Common_perc" /></b>
            </div>
            <div className="item text-center">
              <p><FormattedMessage id="Player_points" /></p>
              <b>{nScoredPoints || <FormattedMessage id="Team_0" />}</b>
            </div>
            <div className="item text-right">
              <p><FormattedMessage id="Player_credits" /></p>
              <b>{playerData && playerData.nFantasyCredit ? Number.isInteger(playerData.nFantasyCredit) ? playerData.nFantasyCredit : playerData.nFantasyCredit.toFixed(2) : <FormattedMessage id="Team_0" />}</b>
            </div>
            <div className="item">
              <p><FormattedMessage id="Player_c" /></p>
              <b>{playerData && playerData.nCaptainBy ? playerData.nCaptainBy : <FormattedMessage id="Team_0" />}<FormattedMessage id="Common_perc" /></b>
            </div>
            <div className="item text-center">
              <p><FormattedMessage id="Player_vc" /></p>
              <b>{playerData && playerData.nViceCaptainBy ? playerData.nViceCaptainBy : <FormattedMessage id="Team_0" />}<FormattedMessage id="Common_perc" /></b>
            </div>
          </div>
        </div>
        <Table className="player-d-t">
          <thead>
            <tr>
              <th><FormattedMessage id="Player_event" /></th>
              <th><FormattedMessage id="Player_action" /></th>
              <th><FormattedMessage id="Player_points" /></th>
            </tr>
          </thead>
          <tbody>
            {
              loading
                ? <Loading series={true} Lines={7}/>
                : pointBreakUp && pointBreakUp.length && pointBreakUp.length !== 0
                  ? pointBreakUp.map(points => {
                    return (
                      <tr key={points._id}>
                        <td>{points && points.sName && points.sName}</td>
                        <td>{points && points.nPoint && points.nScoredPoints ? parseFloat(Number((points.nScoredPoints / points.nPoint)).toFixed(2)) : '-'}</td>
                        <td>{points && points.nScoredPoints && points.nScoredPoints} </td>
                      </tr>
                    )
                  })
                  : <Fragment>
                    <tr>
                      <td colSpan='3'>
                        <FormattedMessage id="NO_PointBreakup_Avail" />
                      </td>
                    </tr>
                  </Fragment>
            }
            <tr>
                    <td colSpan="2"><FormattedMessage id="Common_Total" /></td>
                    <td className='text-right'>{nScoredPoints}</td>
                  </tr>
          </tbody>
        </Table>
      </div>
    </>
  )
}
PlayerInfo.propTypes = {
  onBackClick: PropTypes.func,
  playerData: PropTypes.shape({
    nViceCaptainBy: PropTypes.number,
    nCaptainBy: PropTypes.number,
    nFantasyCredit: PropTypes.number,
    nSetBy: PropTypes.number,
    eRole: PropTypes.string,
    sName: PropTypes.string
  }),
  match: PropTypes.object,
  pointBreakUp: PropTypes.array,
  nScoredPoints: PropTypes.number,
  loading: PropTypes.bool
}
export default PlayerDetails(PlayerInfo)
