/* eslint-disable no-unused-vars */
/* eslint-disable no-unexpected-multiline */
/* eslint-disable func-call-spacing */
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { createBrowserHistory } from 'history'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './routes/index'
import Intl from '../src/intl/index'
import firebase from 'firebase'
import Ads from './Ads'
import ReactGA from 'react-ga'
import configs from './config'
import { VerifyToken, FirebaseToken } from '../../common/src/redux/actions/auth'
import { getUrl } from '../../common/src/redux/actions/url'
import { getCurrency, getBackground, isMaintenanceMode } from '../../common/src/redux/actions/setting'
import { store } from 'Common/src/redux/store'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { GetNotificationCount } from 'Common/src/redux/actions/notification'
import { GetActiveSports } from 'Common/src/redux/actions/activeSports'
import 'moment/locale/pt-br'
export const history = createBrowserHistory()

const config = {
  apiKey: 'AIzaSyCnMXPkgUONPApawdBx1m2z5kje_kk9g48',
  authDomain: 'cartola-pix.firebaseapp.com',
  projectId: 'cartola-pix',
  storageBucket: 'cartola-pix.appspot.com',
  messagingSenderId: '47869344044',
  appId: '1:47869344044:web:814e03ec3319134f191489',
  measurementId: 'G-44WB1DR00F'
}
firebase.initializeApp(config)
firebase.analytics()
if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging()

  messaging.onMessage((payload) => {
    navigator.serviceWorker
      .getRegistrations()
      .then((registration) => {
        registration[0].showNotification(payload && payload.notification && payload.notification.title, {
          body: payload.notification.body,
          icon: '/firebase-logo.png'
        })
      })
  })

  messaging.getToken()
    .then(function (token) {
      store.dispatch(FirebaseToken(token))
    })
    .catch(function (err) {
      console.log('Error Occurred. ' + err)
    })
}

function App () {
  const dispatch = useDispatch()
  const token = localStorage.getItem('Token')
  const tokenId = useSelector(state => state.auth.token)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const getUrlLink = useSelector(state => state.url.getUrl)
  const currencyLogo = useSelector(state => state.settings.currencyLogo)
  const backgroundCoverImage = useSelector(state => state.settings.backgroundCoverImage)
  const backgroundImage = useSelector(state => state.settings.backgroundImage)
  const activeSports = useSelector(state => state.activeSports.activeSports)
  const activeSport = activeSports && activeSports.length > 0 && activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => data)
  const [showingImage, setShowingImage] = useState(true)
  const [bannerImg, setBannerImg] = useState('')
  const [url, setUrl] = useState('')

  useEffect(() => {
    store.dispatch(isMaintenanceMode())
    dispatch(GetActiveSports())
    store.dispatch(getUrl())
  }, [])

  useEffect(async () => {
    if (window) {
      // store.dispatch(isMaintenanceMode())
      // We recommend to call `load` at application startup.
      // token && await store.dispatch(isMaintenanceMode(token))
      const fp = await FingerprintJS.load()
      // The FingerprintJS agent is ready.
      // Get a visitor identifier when you'd like to.
      const result = await fp.get()
      // This is the visitor identifier:
      const visitorId = result.visitorId
      localStorage.setItem('ID', visitorId)
      if (token && visitorId) {
        store.dispatch(VerifyToken(token, visitorId))
      }
      // else {
      //   history.push({ pathname: history.location.pathname, search: history.location.search })
      // }
      if (!currencyLogo) {
        store.dispatch(getCurrency())
      }
      if (!backgroundCoverImage || !backgroundImage) {
        store.dispatch(getBackground())
      }
      ReactGA.initialize('UA-204021649-1')
    }
  }, [token])

  useEffect(() => {
    if (getUrlLink) {
      setUrl(getUrlLink)
    }
    // else {
    //   if (!getUrlLink && token) {
    //     console.log('else one')
    //     store.dispatch(getUrl())
    //   }
    // }
  }, [getUrlLink])

  useEffect(() => {
    if (token && activeSport) {
      store.dispatch({
        type: 'TOKEN_LOGIN',
        payload: {
          token,
          userData
        }
      })
      if (history.location.pathname === '/login' || history.location.pathname === '/forgot-password' || history.location.pathname === '/signup-validate-cpf' || history.location.pathname === '/confirm-password' || history.location.pathname === '/' || history.location.pathname === '/signup' || history.location.pathname === '/signup-validate-dob') {
        history.push({ pathname: `/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}`, search: history.location.search })
      }
    }
  }, [tokenId, activeSport])

  useEffect(() => {
    const interval = setInterval(() => {
      token && dispatch(GetNotificationCount(token))
    }, 20000)

    return () => clearInterval(interval)
  }, [token])

  return (
    <div className='full-screen' style={{ backgroundImage: getUrlLink && backgroundCoverImage ? `url(${getUrlLink}${backgroundCoverImage})` : '' }}>
      {
        backgroundImage && getUrlLink && (
          <img className='back-Img' src={getUrlLink && backgroundImage ? `${getUrlLink}${backgroundImage}` : ''} />
        )
      }
      <div className='left-bar' onClick={() => showingImage && setShowingImage(false)}>
        {
          configs?.environment && (
            <div className='ui-feedback'>
              <p>{configs.environment}</p>
            </div>
          )
        }
        <Intl>
          <Router history={history}>
            <Ads bannerImg={bannerImg} history={history} setBannerImg={setBannerImg} setShowingImage={setShowingImage} showingImage={showingImage} url={url} />
            <div className={`${showingImage && url && bannerImg ? 'blur' : 'left-bar'}`}>
              <Routes/>
            </div>
          </Router>
        </Intl>
      </div>
    </div>
  )
}

App.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string
    })
  })
}

export default App
