import React, { useEffect, useState, lazy, Suspense, useRef } from 'react'
import UserHeader from '../../User/components/UserHeader'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../../component/Loading'
import { getMatchDetails } from 'Common/src/redux/actions/match'
import { getMatchLeagueDetails } from 'Common/src/redux/actions/league'
const PrivacyPolicyPage = lazy(() => import('./OtherContentPage'))

function Page (props) {
  const { location, history } = props
  const [contentDetails, setContentDetails] = useState('')
  const [image, setImage] = useState('')
  const [button, setButton] = useState(false)
  const [matchData, setMatchData] = useState({})
  const [loading, setLoading] = useState(false)

  const matchDetails = useSelector(state => state.match.matchDetails)
  const matchLeagueDetails = useSelector(state => state.league.matchLeagueDetails)
  const token = useSelector(state => state.auth.token)

  const previousProps = useRef({ matchDetails }).current

  const dispatch = useDispatch()

  useEffect(() => {
    if (location && location.state) {
      if (location.state.contentDetails) {
        setContentDetails(location.state.contentDetails)
      }
      if (location.state.image) {
        setImage(location.state.image)
      }
      if (location.state.button) {
        setButton(location.state.button)
      }
      if (location.state.data) {
        setMatchData(location.state.data)
      }
    }
  }, [])

  useEffect(() => { // handle the response
    if (previousProps.matchDetails !== matchDetails) {
      if (matchDetails && matchDetails._id && matchDetails.eStatus === 'U') {
        if (matchData && matchData.iMatchId && matchData.iMatchLeagueId && matchLeagueDetails && !matchLeagueDetails.bCancelled) {
          history.push(`/leagues-detail/${(matchData.eCategory).toLowerCase()}/${matchData.iMatchId}/${matchData.iMatchLeagueId}`)
        } else if (matchData && matchData.iMatchId) {
          history.push(`/leagues/${(matchData.eCategory).toLowerCase()}/${matchData.iMatchId}`)
        }
      } else if (matchDetails && matchDetails._id && matchDetails.eStatus === 'L') {
        if (matchData && matchData.iMatchId && matchData.iMatchLeagueId && matchData.eTransactionType !== 'Play-Return' && matchLeagueDetails && !matchLeagueDetails.bCancelled) {
          history.push(`/leagues-details/${(matchData.eCategory).toLowerCase()}/${matchData.iMatchId}/${matchData.iMatchLeagueId}/participated`)
        } else if (matchData && matchData.iMatchId) {
          history.push(`/liveleague/${matchData.iMatchId}/${(matchData.eCategory).toLowerCase()}`)
        }
      } else if (matchDetails && matchDetails._id && (matchDetails.eStatus === 'CMP' || matchDetails.eStatus === 'I')) {
        if (matchData && matchData.iMatchId && matchData.iMatchLeagueId && matchData.eTransactionType !== 'Play-Return' && matchLeagueDetails && !matchLeagueDetails.bCancelled) {
          history.push(`/leagues-details/${(matchData.eCategory).toLowerCase()}/${matchData.iMatchId}/${matchData.iMatchLeagueId}/participated`)
        } else if (matchData && matchData.iMatchId) {
          history.push(`/completedleagues/participated/${matchData.iMatchId}/${(matchData.eCategory).toLowerCase()}`)
        }
      }
      if (matchDetails && matchDetails._id) {
        setLoading(false)
      }
    }
    return () => {
      previousProps.matchDetails = matchDetails
    }
  }, [matchDetails])

  function onGetMatchDetails (ID) {
    if (ID && token) {
      dispatch(getMatchDetails(ID, '', token))
      setLoading(true)
    }
  }

  function onGetLeagueDetails (ID) {
    if (ID && token) {
      dispatch(getMatchLeagueDetails(ID, token))
      setLoading(true)
    }
  }

  return (
    <>
      {loading && <Loading />}
      <UserHeader {...props} title={<FormattedMessage id="Refer_a_friend" />}/>
      <Suspense fallback={<Loading />}>
        <PrivacyPolicyPage {...props} contentDetails={contentDetails} image={image} button={button} matchData={matchData} onGetMatchDetails={onGetMatchDetails} onGetLeagueDetails={onGetLeagueDetails} />
      </Suspense>
    </>
  )
}

Page.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}

export default Page
