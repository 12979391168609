/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Collapse, Button, CardBody, Card } from 'reactstrap'
import SkeletonUpcoming from '../../../component/SkeletonUpcoming'
import down from '../../../assests/images/down-arrow.svg'
import Home from '../../../assests/images/homeIconWhite.svg'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

function ScoreCards (props) {
  const { history, fullLiveInning, joinedLoading } = props
  const [feild, setFeild] = useState([false, false, false, false])
  const activeSports = useSelector(state => state.activeSports.activeSports)
  const activeSport = activeSports && activeSports.length > 0 && activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => data)

  return (
    <>
      {joinedLoading
        ? <SkeletonUpcoming scoredData/>
        : <Fragment>
          <div className="league-header u-header">
          <div className="d-flex align-items-center header-i">
            <button onClick={() => props.history.goBack()} className="btn-link icon-left-arrow"></button>
            <Button className='button-link bg-transparent py-2' tag={Link} to={`/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}`}><img src={Home}></img></Button>
            <h1><FormattedMessage id='Player_scoreCard' /></h1>
          </div>
          </div>
        <div className="score-cards">
          {
            fullLiveInning?.length > 0
              ? (
              <Fragment>
                {
                  fullLiveInning.map((inning, index) => {
                    return (
                      <Fragment key={index}>
                        <Button color=" d-flex justify-content-between align-item-center" onClick={() => {
                          setFeild(feild.map((data, index2) => index2 === index && !data))
                        }} style={{ marginBottom: '1rem' }}>
                          <span>{inning?.sName}</span>
                          <span>
                            {inning?.oEquations?.nRuns}/{inning?.oEquations?.nWickets} ({inning?.oEquations?.sOvers || 0})
                            <img className={`${feild[index] ? 'rotate' : ''}`}src={down}></img>
                          </span>
                        </Button>
                        <Collapse isOpen={feild[index]}>
                          <Card>
                            <CardBody>
                              <div style={{ overflowX: 'auto' }} className='mb-3'>
                                <table>
                                  <thead>
                                    <tr>
                                      <th><FormattedMessage id="ScoreCard_Bat" /></th>
                                      {/* <th></th> */}
                                      <th><FormattedMessage id="ScoreCard_Runs" /></th>
                                      <th><FormattedMessage id="ScoreCard_Balls" /></th>
                                      <th><FormattedMessage id="ScoreCard_4s" /></th>
                                      <th><FormattedMessage id="ScoreCard_6s" /></th>
                                      <th><FormattedMessage id="ScoreCard_SR" /></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      inning?.aBatters?.length > 0 && inning?.aBatters.map((bat, ind) => {
                                        const isInInning = inning?.oCurrentPartnership?.aBatters?.map(inn => inn.iBatterId)
                                        return (
                                          <Fragment key={ind}>
                                            <tr>
                                              <td className={`${isInInning.includes(bat?.iBatterId) ? 'blueText' : ''}`}>
                                                <div> {bat?.oBatter?.sName} </div>
                                                <span> {bat?.sHowOut} </span>
                                              </td>
                                              {/* <td className={`${isInInning.includes(bat?.iBatterId) ? 'blueText' : ''}`}>
                                              </td> */}
                                              <td>{bat?.nRuns}</td>
                                              <td>{bat?.nBallFaced}</td>
                                              <td>{bat?.nFours}</td>
                                              <td>{bat?.nSixes}</td>
                                              <td>{bat?.sStrikeRate}</td>
                                            </tr>
                                          </Fragment>
                                        )
                                      })
                                    }
                                    <tr>
                                      <td>
                                        <div><FormattedMessage id="ScoreCard_Extra" className='custom-span' /></div>
                                        <span>
                                          ( b - {inning?.oExtraRuns?.nByes || 0}, w - {inning?.oExtraRuns?.nWides || 0}, no - {inning?.oExtraRuns?.nNoBalls || 0}, lb - {inning?.oExtraRuns?.nLegByes || 0}, p - {inning?.oExtraRuns?.nPenalty || 0} )
                                        </span>
                                      </td>
                                      {/* <td>( b - {inning?.oExtraRuns?.nByes || 0}, w - {inning?.oExtraRuns?.nWides || 0}, no - {inning?.oExtraRuns?.nNoBalls || 0}, lb - {inning?.oExtraRuns?.nLegByes || 0}, p - {inning?.oExtraRuns?.nPenalty || 0} )</td> */}
                                      <td>{inning?.oExtraRuns?.nTotal}</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <h4><FormattedMessage id="ScoreCard_Total" /></h4>
                                        <span>( {inning?.oEquations?.sRunRate} <FormattedMessage id="ScoreCard_RPO" /> )</span>
                                      </td>
                                      {/* <td></td> */}
                                      <td>{inning?.oEquations?.nRuns}</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className='fow'>
                                <span className='blueText d-block'> <FormattedMessage id="ScoreCard_FOW" /> </span>
                                <span className='d-block'>
                                  {
                                    inning?.aFOWs.map((fow, indF) => ` ${fow?.nRuns}-${fow?.nWicketNumber} (${fow?.oBatter?.sName}, ${fow?.sOverDismissal})${indF === (inning?.aFOWs?.length - 1) ? '' : ', '} `)
                                  }
                                </span>
                              </div>
                              <div style={{ overflowX: 'auto' }} className='mb-3'>
                                <table>
                                  <thead>
                                    <tr>
                                      <th><FormattedMessage id="ScoreCard_Bwl" /></th>
                                      <th><FormattedMessage id="ScoreCard_Ov" /></th>
                                      <th><FormattedMessage id="ScoreCard_M" /></th>
                                      <th><FormattedMessage id="ScoreCard_R" /></th>
                                      <th><FormattedMessage id="ScoreCard_W" /></th>
                                      <th><FormattedMessage id="ScoreCard_NB" /></th>
                                      <th><FormattedMessage id="ScoreCard_WD" /></th>
                                      <th><FormattedMessage id="ScoreCard_Eco" /></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      inning?.aBowlers?.length > 0 && inning?.aBowlers.map((bowl, ind) => {
                                        return (
                                          <Fragment key={ind}>
                                            <tr>
                                              <td>{bowl?.oBowler?.sName}</td>
                                              <td>{bowl?.sOvers}</td>
                                              <td>{bowl?.nMaidens}</td>
                                              <td>{bowl?.nRunsConducted}</td>
                                              <td>{bowl?.nWickets}</td>
                                              <td>{bowl?.nNoBalls}</td>
                                              <td>{bowl?.nWides}</td>
                                              <td>{bowl?.sEcon}</td>
                                            </tr>
                                          </Fragment>
                                        )
                                      })
                                    }
                                  </tbody>
                                </table>
                              </div>
                            </CardBody>
                          </Card>
                        </Collapse>
                      </Fragment>
                    )
                  })
                }
              </Fragment>
                )
              : (
              <h1><FormattedMessage id="ScoreCard_None" /></h1>
                )
          }
        </div>
      </Fragment>
      }
    </>
  )
}

ScoreCards.propTypes = {
  matchDetails: PropTypes.shape({
    oHomeTeam: PropTypes.shape({
      nScore: PropTypes.Number
    }),
    oAwayTeam: PropTypes.shape({
      nScore: PropTypes.Number
    }),
    sWinning: PropTypes.string,
    sName: PropTypes.string,
    _id: PropTypes.string
  }),
  joinedLoading: PropTypes.bool,
  fullLiveInning: PropTypes.array,
  history: PropTypes.object
}
export default ScoreCards
