import React from 'react'
import WithdrawPage from './Withdraw'
import UserHeader from '../components/UserHeader'
import { FormattedMessage } from 'react-intl'
function Withdraw (props) {
  return (
    <>
    <UserHeader {...props} title={<FormattedMessage id='Withdraw' />} backURL='/profile'/>
    <WithdrawPage {...props}/>
    </>
  )
}

export default Withdraw
