import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { myMatchesList, getMatchList, getTournamentInfoList } from '../../redux/actions/match'
const Moment = require('moment')

export const SportsLeagueList = (Component) => {
  return (props) => {
    const { subIndex, mainIndex } = props
    const [url, setUrl] = useState('')
    const [Match, setMatch] = useState([])
    const [matchDetailsList, setMatchDetailList] = useState([])
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const matchList = useSelector(state => state.match.matchList)
    const matchDetailList = useSelector(state => state.match.matchDetailList)
    const resStatus = useSelector(state => state.match.resStatus)
    const resMessage = useSelector(state => state.match.resMessage)
    const getUrlLink = useSelector(state => state.url.getUrl)
    const token = useSelector(state => state.auth.token)
    const activeSports = useSelector(state => state.activeSports.activeSports)
    const tournamentInfoList = useSelector(state => state.match.tournamentInfoList)
    const currencyLogo = useSelector(state => state.settings.currencyLogo)
    const previousProps = useRef({
      matchList, matchDetailList, mainIndex, subIndex, getUrlLink, activeSports
    }).current

    useEffect(() => { // handle the initialize
      if (!subIndex && mainIndex) {
        FetchMatchList()
      } else {
        if (mainIndex && subIndex) {
          FetchingList(mainIndex, subIndex)
        }
      }
    }, [])

    useEffect(() => {
      if (getUrlLink) {
        setUrl(getUrlLink)
      }
    }, [getUrlLink])

    useEffect(() => { // handle the apis calling
        if(mainIndex && subIndex !== undefined) {
          FetchingList(mainIndex, subIndex)
        } else {
          if (mainIndex) {
            FetchMatchList()
          }
        }
    }, [mainIndex, subIndex])

    useEffect(() => {
      if (previousProps.matchList !== matchList) { // handle the response
        if (matchList) {
          let top = [], bottom = []
          if (matchList.length !== 0) {
            top = matchList.length !== 0 && matchList.filter(match => match.bMatchOnTop).sort((a, b) => new Date(a.dStartDate) - new Date(b.dStartDate))
            bottom = matchList.length !== 0 && matchList.filter(match => !match.bMatchOnTop).sort((a, b) => new Date(a.dStartDate) - new Date(b.dStartDate))
          }
          setMatch([...top, ...bottom])
          setLoading(false)
        }
      }
      return () => {
        previousProps.matchList = matchList
      }
    }, [matchList])
    
    useEffect(() => {
      if (previousProps.matchDetailList !== matchDetailList) { // handle the response
        if (matchDetailList) {
          setMatchDetailList(matchDetailList)
        }
      }
      return () => {
        previousProps.matchDetailList = matchDetailList
      }
    }, [matchDetailList])

    function FetchMatchList() {
      let sportsType = ''
      if (activeSports && activeSports.length > 0 ) {
        const activeSportsArr = [...activeSports];
        activeSportsArr.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => ((a.nPosition > b.nPosition) ? 1 : -1));
        sportsType =  activeSportsArr[mainIndex - 1].sKey
        if (sportsType) {
          dispatch(getMatchList(sportsType))
          dispatch(getTournamentInfoList(sportsType))
        }
        setLoading(true)
      }
    }

    function  FetchingList(MainIndex, subIndex) {
      if (subIndex) {
        let sportsType = ''
        const activeSportsArr = [...activeSports];
        activeSportsArr.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => ((a.nPosition > b.nPosition) ? 1 : -1));
        sportsType =  activeSportsArr[MainIndex - 1].sKey
        if (subIndex === 1) {
          sportsType && dispatch(myMatchesList(sportsType, token, 'U', 'MATCH'))
        } else if (subIndex === 2) {
          sportsType && dispatch(myMatchesList(sportsType, token, 'L', 'MATCH'))
        } else if (subIndex === 3) {
          sportsType && dispatch(myMatchesList(sportsType, token, 'CMP', 'MATCH'))
        }
        dispatch(getTournamentInfoList(sportsType))
        setLoading(true)
      }
    }
    return (
      <Component
        {...props}
        FetchMatchList={FetchMatchList}
        FetchingList={FetchingList}
        list={Match}
        url={url}
        loading={loading}
        resStatus={resStatus}
        resMessage={resMessage}
        matchDetailsList={matchDetailsList}
        tournamentInfoList={tournamentInfoList}
        currencyLogo={currencyLogo}
      />
    )
  }
}

export default SportsLeagueList
