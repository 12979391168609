import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import HomeTeam from '../../../assests/images/Team1.png'
import AwayTeam from '../../../assests/images/Team2.png'
import SkeletonUpcoming from '../../../component/SkeletonUpcoming'
import moment from 'moment'
import calendar from '../../../assests/images/calendar.svg'
import watch from '../../../assests/images/watch.svg'

function Scorecard (props) {
  const { matchDetails, loadingScorecard, url, matchSport } = props

  return (
    <>
      {loadingScorecard
        ? <SkeletonUpcoming scoredData/>
        : (
          <Fragment>
            {(matchSport && matchSport === 'CRICKET')
              ? (
                <div className="scorecard-cricket">
                  <div className="scorecard-item">
                    <div className="t-img"><img alt={<FormattedMessage id="Footer_Home" />} src={matchDetails && matchDetails.oHomeTeam && matchDetails.oHomeTeam.sImage ? `${url}${matchDetails.oHomeTeam.sImage}` : HomeTeam} /></div>
                    <p>
                      <span className="t-name">
                        <b>
                          {' '}
                          {matchDetails?.oHomeTeam?.sShortName}
                          {' '}
                        </b>
                        {' '}
                      </span>
                      <span>
                        {' '}
                        {matchDetails && matchDetails.oHomeTeam && matchDetails.oHomeTeam.nScore ? matchDetails.oHomeTeam.nScore : <FormattedMessage id="Team_ScoreData" />}
                        {' '}
                      </span>
                    </p>
                  </div>
                  <div className="scorecard-item">
                    <div className="t-img"><img alt={<FormattedMessage id="Footer_Away" />} src={matchDetails && matchDetails.oAwayTeam && matchDetails.oAwayTeam.sImage ? `${url}${matchDetails.oAwayTeam.sImage}` : AwayTeam} /></div>
                    <p>
                      <span className="t-name">
                        <b>
                          {' '}
                          {matchDetails?.oAwayTeam?.sShortName}
                          {' '}
                        </b>
                        {' '}
                      </span>
                      <span>
                        {' '}
                        {matchDetails && matchDetails.oAwayTeam && matchDetails.oAwayTeam.nScore ? matchDetails.oAwayTeam.nScore : <FormattedMessage id="Team_ScoreData" />}
                        {' '}
                      </span>
                    </p>
                  </div>
                  <div className="w-txt">
                    {matchDetails && matchDetails.sWinning}
                    {' '}
                  </div>
                </div>
                )
              : (
                <div className="scorecard">
                  <div className='match-date-time d-flex justify-content-between align-items-center'>
                    <div>
                      <img className='pr-2' src={calendar} />
                      {moment(matchDetails?.dStartDate).format('Do MMM, YYYY')}
                    </div>
                    <div>
                      <img className='pr-2' src={watch} />
                      {moment(matchDetails?.dStartDate).format('hh:mm A')}
                    </div>
                  </div>
                  {/* <div className='ended-div d-flex justify-content-center'><button>Ended</button></div> */}
                  <div className="scorecard-item">
                    <div className="first">
                      <img alt={<FormattedMessage id="Footer_Home" />} src={matchDetails && matchDetails.oHomeTeam && matchDetails.oHomeTeam.sImage ? `${url}${matchDetails.oHomeTeam.sImage}` : HomeTeam} />
                      <p>
                        <b>
                          {' '}
                          {matchDetails?.oHomeTeam?.sShortName}
                          {' '}
                        </b>
                        {' '}
                      </p>
                    </div>
                    <div className="second">
                      <p>
                        {' '}
                        {matchDetails && matchDetails.oHomeTeam && matchDetails.oHomeTeam.nScore ? matchDetails.oHomeTeam.nScore : '--'}
                        {' '}
                      </p>
                      <div className='dash'>VS</div>
                      <p>
                        {' '}
                        {matchDetails && matchDetails.oAwayTeam && matchDetails.oAwayTeam.nScore ? matchDetails.oAwayTeam.nScore : '--'}
                        {' '}
                      </p>
                    </div>
                    <div className="third">
                      <img alt={<FormattedMessage id="Footer_Away" />} src={matchDetails && matchDetails.oAwayTeam && matchDetails.oAwayTeam.sImage ? `${url}${matchDetails.oAwayTeam.sImage}` : AwayTeam} />
                      <p>
                        <b>
                          {' '}
                          {matchDetails?.oAwayTeam?.sShortName}
                          {' '}
                        </b>
                        {' '}
                      </p>
                    </div>
                  </div>
                  <div className="w-txt">
                    {matchDetails?.sWinning}
                    {' '}
                  </div>
                </div>
                )
          }
          </Fragment>
          )
      }
    </>
  )
}

Scorecard.propTypes = {
  matchDetails: PropTypes.shape({
    oHomeTeam: PropTypes.shape({
      nScore: PropTypes.number,
      sImage: PropTypes.string,
      sShortName: PropTypes.string
    }),
    oAwayTeam: PropTypes.shape({
      nScore: PropTypes.number,
      sImage: PropTypes.string,
      sShortName: PropTypes.string
    }),
    sWinning: PropTypes.string,
    sName: PropTypes.string,
    dStartDate: PropTypes.string
  }),
  loadingScorecard: PropTypes.bool,
  url: PropTypes.string,
  matchSport: PropTypes.string
}
export default Scorecard
