import React from 'react'
import { FormattedMessage } from 'react-intl'
import Trophy from '../../../assests/images/Trophy1.svg'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

function LoginMessage (props) {
  const { history } = props

  return (
    <div className="no-team d-flex align-items-center justify-content-center fixing-width2">
      <div className="" style={{ padding: '40px' }}>
        <img src={Trophy}></img>
        <h6 className='mt-4'>
          <FormattedMessage id="Trophy_Message" />
        </h6>
        <Button color="primary" className='mt-3' block onClick={() => history.push('/login')}><FormattedMessage id="Welcome_Login" /></Button>
      </div>
    </div>
  )
}

LoginMessage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

export default LoginMessage
