/* eslint-disable react/jsx-key */
import React, { useEffect, useState, Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
  Button, Card, CardBody, CardFooter, CardHeader, CarouselControl, Carousel, CarouselItem
} from 'reactstrap'
import {
  TelegramShareButton, LinkedinShareButton, LinkedinIcon, TelegramIcon, WhatsappShareButton, WhatsappIcon, FacebookShareButton, FacebookIcon, FacebookMessengerShareButton, FacebookMessengerIcon, TwitterShareButton, TwitterIcon
} from 'react-share'
import config from '../../../config'
import Loading from '../../../component/Loading'
import TransparentLogo from '../../../assests/images/vrf_transparent_logo.svg'
import TeamList from 'Common/src/components/SportsLeagueList/TeamList'
import { cricketStaticImage, footballStaticImage, allSportsRoles, convertFloat } from '../../../helper'
// import { cricketStaticImage, footballStaticImage, allSportsRoles } from '../../../helper'
import PlayerImage from '../../../assests/images/PlayerImage.png'
import qs from 'query-string'
const classNames = require('classnames')

function PreviewCricket (props) {
  const { location, allLeaderBoardList, myTeamsLeaderBoardList, getMyTeamLeaderBoardListFunc, myAllTeamPagination, url, match, index, completed, sportsType, getMatchPlayerList, loading, refreshContent, teamPreview, getUserTeam, userTeam, matchDetails, playerRoles, teamPlayerList, token, history, intl: { formatMessage } } = props
  const [allTeams, setAllTeams] = useState([])
  const [team, setTeams] = useState([])
  const [AllLeaderBoardList, setAllLeaderBoardList] = useState([])
  const [matchType, setMatchType] = useState('')
  const [TeamName, setTeamName] = useState('')
  const [ShareTeam, setShareTeam] = useState(false)
  const [teamView, setTeamView] = useState(index || 0)
  // const [viewTeam, setViewTeam] = useState([])
  const [teamDetails, setTeamDetails] = useState([])
  const [totalCredit, setTotalCredit] = useState(0)
  // const [totalScorePoints, setTotalScorePoints] = useState(0)
  const [animating, setAnimating] = useState(false)
  const [value] = useState(0)
  const limit = location?.state?.allUserLeagues
  const obj = qs.parse(props.location.search)
  const previousProps = useRef({ userTeam, team, allTeams, allLeaderBoardList, myTeamsLeaderBoardList }).current

  // useEffect(() => {
  //   if (myTeamPreViewUpcoming && userTeamId) {
  //     getUserTeam(userTeamId)
  //     setLoading(true)
  //   } else {
  //     changeTeam(teamView)
  //   }
  // }, [myTeamPreViewUpcoming, userTeamId])

  useEffect(() => {
    if (token && match && match.params && match.params.userLeagueID) {
      myAllTeamPagination(limit, value, match.params.userLeagueID)
      getMyTeamLeaderBoardListFunc(match.params.userLeagueID)
    }
    if (token && match && match.params && match.params.matchID) {
      getMatchPlayerList(match.params.matchID)
    }
  }, [token])

  useEffect(() => {
    if (token && match && match.params && match.params.userTeamID) {
      getUserTeam(match.params.userTeamID)
    }
    if (token && match && match.params && match.params.index) {
      setTeamView(parseInt(match.params.index))
    }
  }, [match.params, token])

  useEffect(() => {
    if (allLeaderBoardList && allLeaderBoardList?.length > 0 && myTeamsLeaderBoardList && myTeamsLeaderBoardList?.length > 0) {
      if (previousProps.allLeaderBoardList !== allLeaderBoardList && previousProps.myTeamsLeaderBoardList !== myTeamsLeaderBoardList) {
        const myTeams = myTeamsLeaderBoardList.map(data => data._id)
        const allUsers = allLeaderBoardList?.filter(data => !myTeams.includes(data._id))
        setAllLeaderBoardList(AllLeaderBoardList => [...AllLeaderBoardList, ...allUsers])
      }
      return () => {
        previousProps.allLeaderBoardList = allLeaderBoardList
        previousProps.myTeamsLeaderBoardList = myTeamsLeaderBoardList
      }
    }
  }, [allLeaderBoardList, myTeamsLeaderBoardList])

  useEffect(() => {
    if (matchType === 'U' && myTeamsLeaderBoardList && myTeamsLeaderBoardList.length !== 0) {
      const allUserLeagues = [...myTeamsLeaderBoardList.sort((a, b) => b.nTotalPoints - a.nTotalPoints)]
      setAllTeams(allUserLeagues)
    } else if (matchType !== 'U' && myTeamsLeaderBoardList && myTeamsLeaderBoardList.length > 0 && AllLeaderBoardList && AllLeaderBoardList.length > 0) {
      const allUserLeagues = [...myTeamsLeaderBoardList.sort((a, b) => b.nTotalPoints - a.nTotalPoints), ...AllLeaderBoardList.sort((a, b) => b.nTotalPoints - a.nTotalPoints)]
      setAllTeams(allUserLeagues)
    }
  }, [AllLeaderBoardList, myTeamsLeaderBoardList, matchType])

  useEffect(() => { // handle the response
    if (previousProps.value !== value) {
      if (value) {
        myAllTeamPagination(limit, value, match.params.userLeagueID)
        getMyTeamLeaderBoardListFunc(match.params.userLeagueID)
      }
    }
    return () => {
      previousProps.value = value
    }
  }, [value])

  // useEffect(() => {
  //   const Team = []
  //   if (matchPlayer && matchPlayer.length > 0 && userTeam) {
  //     matchPlayer.forEach(player => {
  //       if (userTeam && userTeam.aPlayers?.length > 0 && userTeam.aPlayers.some(player2 => player._id === player2.iMatchPlayerId)) {
  //         Team.push(player)
  //       }
  //     })
  //   }
  //   setViewTeam(Team)
  // }, [matchPlayer, userTeam])

  const next = () => {
    if (animating) return
    const nextIndex = teamView === allTeams.length - 1 ? 0 : teamView + 1
    // setTeamView(parseInt(nextIndex))
    // changeTeam(nextIndex)
    // if (nextIndex > limit) {
    //   setValue(value => value + limit)
    // }
    const userTeamId = allTeams[nextIndex]?.iUserTeamId
    history.replace({
      pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/team-preview/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${match.params.userLeagueID}/${userTeamId}/${nextIndex}` : `/team-preview/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${match.params.userLeagueID}/${userTeamId}/${nextIndex}`,
      search: `?${qs.stringify({
          homePage: obj?.homePage ? 'yes' : undefined
        })}`
    })
  }

  const previous = () => {
    if (animating) return
    const nextIndex = teamView === 0 ? allTeams.length - 1 : teamView - 1
    // setTeamView(parseInt(nextIndex))
    // changeTeam(nextIndex)
    const userTeamId = allTeams[nextIndex]?.iUserTeamId
    history.replace({
      pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/team-preview/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${match.params.userLeagueID}/${userTeamId}/${nextIndex}` : `/team-preview/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${match.params.userLeagueID}/${userTeamId}/${nextIndex}`,
      search: `?${qs.stringify({
          homePage: obj?.homePage ? 'yes' : undefined
        })}`
    })
  }

  useEffect(() => {
    if (teamPlayerList) {
      let TotalCredits = 0
      let TotalScorePoints = 0
      if (previousProps.userTeam !== userTeam && playerRoles) {
        if (userTeam && userTeam.length !== 0) {
          const playerRole = playerRoles?.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).sort((a, b) => a?.nPosition?.toString().localeCompare(b?.nPosition?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map(Role => Role.sName)
          const players = Object.assign({}, ...playerRole?.map(key => ({ [key]: [] })))
          userTeam?.aPlayers?.length > 0 && userTeam?.aPlayers?.map((Player) => {
            const PlayerDetails = teamPlayerList?.find(player => player._id === Player.iMatchPlayerId)
            Object.entries(players).map(([key, value]) => {
              return (key === PlayerDetails?.eRole) && players[PlayerDetails?.eRole]?.push(PlayerDetails)
            })
            TotalCredits = TotalCredits + Player.nFantasyCredit
            setTotalCredit(TotalCredits)
            if (Player && Player.iMatchPlayerId && userTeam.iCaptainId ? Player.iMatchPlayerId === userTeam.iCaptainId : Player === userTeam.iCaptainId) {
              const newPoints = (Number(Player.nScoredPoints) * 2)
              TotalScorePoints = TotalScorePoints + newPoints
            } else if (Player && Player.iMatchPlayerId && userTeam.iViceCaptainId ? Player.iMatchPlayerId === userTeam.iViceCaptainId : Player === userTeam.iViceCaptainId) {
              const newPoints = (Number(Player.nScoredPoints) * 1.5)
              TotalScorePoints = TotalScorePoints + newPoints
            } else { TotalScorePoints = TotalScorePoints + Number(Player.nScoredPoints) }
            // setTotalScorePoints(location?.state?.nTotalPoints)
            return players
          })
          setTeamDetails(userTeam)
          // if (matchType !== 'U') {
          const tempData = []
          // eslint-disable-next-line array-callback-return
          Object.entries(players).map(([key, value]) => {
            value && value.length > 0 && value.sort((a, b) => a.sName > b.sName ? 1 : -1).map(playerInfo => tempData.push(playerInfo))
          })
          // }
          setTeams(players)
        }
      }
    }
    return () => {
      previousProps.userTeam = userTeam
    }
  }, [allTeams, userTeam, teamPlayerList, playerRoles])

  useEffect(() => {
    if (matchDetails) {
      matchDetails.oHomeTeam && matchDetails.oHomeTeam.sName && setTeamName(matchDetails.oHomeTeam.sName)
      setMatchType(matchDetails.eStatus)
    }
  }, [matchDetails])

  function PlayerInfoFun (playerId) {
    if (matchType === 'U') {
      history.push({
        pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/view-player-leagueInfo/${(match.params.sportsType).toLowerCase()}/${match.params.matchID}/${match.params.userTeamID}/${playerId}` : `/view-player-leagueInfo/${(match.params.sportsType).toLowerCase()}/${match.params.matchID}/${match.params.userTeamID}/${playerId}`,
        search: `?${qs.stringify({
            index: match.params.index || undefined,
            userLeague: match.params.userLeagueID,
            homePage: obj?.homePage ? 'yes' : undefined,
            playerLeagueInfo: 'y'
          })}`
      })
    } else {
      history.push({
        pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/view-player-Info/${(match.params.sportsType).toLowerCase()}/${match.params.matchID}/${match?.params?.userTeamID}/${playerId}` : `/view-player-Info/${(match.params.sportsType).toLowerCase()}/${match.params.matchID}/${match?.params?.userTeamID}/${playerId}`,
        search: `?${qs.stringify({
            index: match.params.index || undefined,
            userLeague: match.params.userLeagueID,
            homePage: obj?.homePage ? 'yes' : undefined
          })}`
      })
    }
  }

  const slides = allTeams && allTeams.length !== 0 && allTeams.map((item, index) => {
    return (
      <CarouselItem
        key={index}
        className="custom-tag"
        onExited={() => setAnimating(false)}
        onExiting={() => setAnimating(true)}
        tag="div"
      >
        <div className="ground w-100">
          {
            Object.entries(team).map(([key, value]) => {
              return (
                <div key={value._id || value.iMatchPlayerId} className="ground-c w-100">
                  <h3>
                    {allSportsRoles(key)}
                    {/* {key === 'BATS' ? (<FormattedMessage id="Team_batsman" />) : key === 'WK' ? (<FormattedMessage id="Team_wk" />) : key === 'BWL' ? (<FormattedMessage id="Team_bowler" />) : key === 'ALLR' ? (<FormattedMessage id="Team_allRounder" />) : key} */}
                  </h3>
                  <div className="player-list d-flex align-items-center w-100 justify-content-center">
                    {
                      value && value.length !== 0 && value.map((playerInfo) => {
                        const playerName = playerInfo?.sName.split(' ')
                        return (
                          <div key={playerInfo._id || playerInfo.iMatchPlayerId} className="pbg" onClick={() => { PlayerInfoFun(playerInfo._id || playerInfo?.iMatchPlayerId) }}>
                            <div className="pg-img">
                              <img key={`inside${playerInfo._id}`} alt="Kohli" src={playerInfo?.sImage && url ? `${url}${playerInfo?.sImage}` : ((match && match.params && match && match.params && match.params.sportsType) || sportsType) === 'cricket' ? cricketStaticImage(playerInfo && playerInfo.eRole) : ((match && match.params && match && match.params && match.params.sportsType) || sportsType) === 'football' ? footballStaticImage(playerInfo && playerInfo.eRole) : PlayerImage} />
                              { playerInfo?.bShow && matchType === 'U' &&
                                <span className="tag2" />
                              }
                              {
                                (playerInfo.iMatchPlayerId === userTeam.iCaptainId) || (playerInfo?._id === userTeam.iCaptainId)
                                  ? <span className="tag"><FormattedMessage id="Team_C" /></span>
                                  : (playerInfo.iMatchPlayerId === userTeam.iViceCaptainId) || (playerInfo?._id === userTeam.iViceCaptainId)
                                      ? <span className="tag"><FormattedMessage id="Team_VC" /></span>
                                      : ''
                              }
                            </div>
                            <p className={classNames(history?.location?.pathname?.includes('tournament') ? 'backDarkBlue' : ({ backWhitePlayer: TeamName === playerInfo?.oTeam?.sName, backDarkBlue: TeamName !== playerInfo?.sTeamName }))}>
                              {playerInfo?.sName &&
                                `${playerName && playerName.length >= 2 ? playerInfo?.sName[0] : playerName[0]}
                                ${playerName && playerName.length === 2 ? playerName[1] : playerName && playerName.length === 3 ? `${playerName[2]}` : playerName && playerName.length === 4 ? `${playerName[3]}` : playerName && playerName.length === 5 ? `${playerName[4]}` : ''}`
                              }
                            </p>
                            {
                              !teamPreview
                                ? (
                                  <span>
                                    {matchType === 'U'
                                      ? playerInfo && playerInfo.nFantasyCredit > 0
                                        ? Number.isInteger(playerInfo.nFantasyCredit) ? playerInfo.nFantasyCredit : playerInfo.nFantasyCredit.toFixed(2) + ' Cr '
                                        : (<FormattedMessage id="Team_0CR" />)
                                      : (playerInfo?.iPlayerId === allTeams[teamView]?.iCaptainId) || (allTeams[teamView]?.iCaptainId && playerInfo?._id && (playerInfo?._id === allTeams[teamView]?.iCaptainId)) || (teamDetails?.iCaptainId && playerInfo._id && (playerInfo._id === teamDetails?.iCaptainId))
                                          ? convertFloat(playerInfo.nScoredPoints * 2) + ' Pts '
                                          : (playerInfo?.iPlayerId === allTeams[teamView]?.iViceCaptainId) || (allTeams[teamView]?.iViceCaptainId && playerInfo?._id && (playerInfo?._id === allTeams[teamView]?.iViceCaptainId)) || (teamDetails?.iViceCaptainId && playerInfo._id && (playerInfo._id === teamDetails?.iViceCaptainId))
                                              ? convertFloat(playerInfo.nScoredPoints * 1.5) + ' Pts '
                                              : parseFloat(Number((playerInfo.nScoredPoints)).toFixed(2)) + ' Pts '
                                      }
                                  </span>
                                  )
                                : (
                                  <span>
                                    {
                                    playerInfo && playerInfo.nFantasyCredit > 0
                                      ? Number(playerInfo.nFantasyCredit) ? playerInfo.nFantasyCredit : playerInfo.nFantasyCredit.toFixed(2) + ' Cr '
                                      : (<FormattedMessage id="Team_0CR" />)
                                    }
                                  </span>
                                  )
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </CarouselItem>
    )
  })

  return (
    <>
      <div className="p-logo">
        <img alt={<FormattedMessage id='Transparent_Logo' />} src={TransparentLogo} />
      </div>
      {loading && <Loading />}
      <div className={classNames('preview d-flex align-items-center justify-content-center', { 'p-cricket': sportsType === 'cricket', 'p-football': sportsType === 'football', 'p-basketball': sportsType === 'basketball', 'p-baseball': sportsType === 'baseball', 'p-kabaddi': sportsType === 'kabaddi' })}>
        <div className="preview d-flex align-items-center justify-content-center" >
          <div className="p-header d-flex align-items-center justify-content-between zIndex">
            <button className="bg-transparent icon-left-arrow"
              onClick={() => {
                if (matchDetails?.eStatus === 'U') {
                  if (obj?.homePage) {
                    history.push(history?.location?.pathname?.includes('tournament') ? `/tournament/leagues-detail/${match.params.sportsType}/${match.params.matchID}/${match.params.userLeagueID}?homePage=yes` : `/leagues-detail/${match.params.sportsType}/${match.params.matchID}/${match.params.userLeagueID}?homePage=yes`)
                  } else {
                    history.push(history?.location?.pathname?.includes('tournament') ? `/tournament/leagues-detail/${match.params.sportsType}/${match.params.matchID}/${match.params.userLeagueID}` : `/leagues-detail/${match.params.sportsType}/${match.params.matchID}/${match.params.userLeagueID}`)
                  }
                } else if (matchDetails?.eStatus === 'L' || matchDetails?.eStatus === 'I' || matchDetails?.eStatus === 'CMP') {
                  history.push(history?.location?.pathname?.includes('tournament') ? `/tournament/leagues-details/${match.params.sportsType}/${match.params.matchID}/${match.params.userLeagueID}/participated` : `/leagues-details/${match.params.sportsType}/${match.params.matchID}/${match.params.userLeagueID}/participated`)
                }
              }}
            />
            <div>

              {
              !(matchType === 'U') && !completed
                ? <button className="bg-transparent mr-3 icon-refresh" onClick={(e) => refreshContent(e)} />
                : ''
            }
            </div>
          </div>
          <Fragment>
            <Carousel
              activeIndex={parseInt(teamView)}
              className="w-100"
              slide={false}
            >
              {slides}
              {
              allTeams && allTeams.length >= 2 && (
                <Fragment>
                  <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                  <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                </Fragment>
              )
            }
            </Carousel>
          </Fragment>
          {allTeams && allTeams?.length > 0 && teamView >= 0 && (
          <div className="np-bottom-view" >
            <div className="d-flex align-items-center justify-content-between">
              <p className="txt">
                {allTeams[teamView]?.sUserName}
                {' '}
                {allTeams[teamView]?.sUserName ? <br /> : ''}
                {' '}
                {allTeams && teamView >= 0 && allTeams[teamView] && (allTeams[teamView]?.sTeamName || allTeams[teamView].sName)}
              </p>
              {matchType === 'U'
                ? (
                  <>
                    {' '}
                    <p className="txt">{(parseInt(teamView) + 1) + '/' + myTeamsLeaderBoardList?.length}</p>
                    {' '}
                  </>
                  )
                : (
                  <>
                    <p className="txt">{(parseInt(teamView) + 1) + '/' + allTeams?.length}</p>
                    {' '}
                  </>
                  ) }
              <p className="txt text-right">
                {matchType === 'U'
                  ? (
                    <>
                      <FormattedMessage id="Common_TotalCredits" />
                      {' '}
                      <br />
                    </>
                    )
                  : <FormattedMessage id="Common_TotalPoints" />}
                {' '}
                {matchType === 'U' ? totalCredit : convertFloat(allTeams?.find(data => data.iUserTeamId === match.params.userTeamID)?.nTotalPoints)}
                {' '}
              </p>
              {/* <p className="txt">{matchType === 'upcoming' ? <FormattedMessage id="Common_TotalCredits" /> : <FormattedMessage id="Common_TotalPoints" />}: {matchType === 'upcoming' ? totalCredit : allTeams && teamView >= 0 && allTeams[teamView] && allTeams[teamView].nTotalPoints ? allTeams && teamView >= 0 && allTeams[teamView] && allTeams[teamView].nTotalPoints : <FormattedMessage id="Team_0" />}</p> */}
            </div>
          </div>
          )}
          {ShareTeam
            ? (
              <>
                <div className="s-team-bg" onClick={() => setShareTeam(false)} />
                <Card className="filter-card select-team promo-card">
                  <CardHeader className='d-flex align-items-center justify-content-between m-0'>
                    <button><FormattedMessage id="Team_ShareTeam" /></button>
                    <button className="icon-close" onClick={() => { setShareTeam(false) }} />
                  </CardHeader>
                  <CardBody className="p-0">
                    <FacebookShareButton
                      className="Demo__some-network__share-button"
                      quote={formatMessage({ id: 'Static_Facebook' })}
                      url={config.facebook}
                    >
                      <FacebookIcon round size={40} />
                    </FacebookShareButton>
                    <FacebookMessengerShareButton
                      appId={config.facebookAppID}
                      className="Demo__some-network__share-button"
                      url={config.facebook}
                    >
                      <FacebookMessengerIcon round size={40} />
                    </FacebookMessengerShareButton>
                    <TwitterShareButton
                      className="Demo__some-network__share-button"
                      title={formatMessage({ id: 'Static_Twitter' })}
                      url={config.elevenWicket}
                    >
                      <TwitterIcon round size={40} />
                    </TwitterShareButton>
                    <TelegramShareButton
                      className="Demo__some-network__share-button"
                      title={formatMessage({ id: 'Static_Telegram' }) }
                      url={config.elevenWicket}
                    >
                      <TelegramIcon round size={40} />
                    </TelegramShareButton>
                    <WhatsappShareButton
                      className="Demo__some-network__share-button"
                      separator=":"
                      title={formatMessage({ id: 'Static_Title_Text' })}
                      url={config.elevenWicket}
                    >
                      <WhatsappIcon round size={40} />
                    </WhatsappShareButton>
                    <LinkedinShareButton className="Demo__some-network__share-button" url={config.elevenWicket}>
                      <LinkedinIcon round size={40} />
                    </LinkedinShareButton>
                  </CardBody>
                  <CardFooter className='p-0 border-0 bg-trnsparent m-0 d-flex justify-content-between'>
                    <Button className="w-100" color='primary' type="submit"><FormattedMessage id="League_join" /></Button>
                  </CardFooter>
                </Card>
              </>
              )
            : ''
        }
        </div>
      </div>
    </>
  )
}
PreviewCricket.propTypes = {
  data: PropTypes.shape({
    aPlayers: PropTypes.func,
    sTeamName: PropTypes.string
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      sportsType: PropTypes.string,
      matchID: PropTypes.string,
      userLeagueID: PropTypes.string,
      userTeamID: PropTypes.string,
      index: PropTypes.string
    })
  }),
  allTeams: PropTypes.shape([{
    iCaptainId: PropTypes.string,
    iViceCaptainId: PropTypes.string,
    sTeamName: PropTypes.string,
    nTotalPoints: PropTypes.Number,
    aPlayers: PropTypes.shape([{
      eRole: PropTypes.string
    }])
  }]),
  index: PropTypes.number,
  teamView: PropTypes.number,
  onBackClick: PropTypes.func,
  token: PropTypes.string,
  userTeamId: PropTypes.string,
  sportsType: PropTypes.string,
  userTeam: PropTypes.object,
  matchDetails: PropTypes.object,
  onPlayerInfoClick: PropTypes.func,
  refreshContent: PropTypes.func,
  getMatchPlayerList: PropTypes.func,
  getUserTeam: PropTypes.func,
  teamPreview: PropTypes.bool,
  setLoading: PropTypes.bool,
  url: PropTypes.string,
  loading: PropTypes.bool,
  completed: PropTypes.bool,
  myTeamPreViewUpcoming: PropTypes.bool,
  matchPlayer: PropTypes.array,
  teamPlayerList: PropTypes.array,
  userName: PropTypes.string,
  isPlayerInfo: PropTypes.bool,
  playerId: PropTypes.string,
  setPlayerId: PropTypes.func,
  setPlayerInfo: PropTypes.func,
  setPlayers: PropTypes.func,
  setUserTeamId: PropTypes.func,
  history: PropTypes.object,
  allLeaderBoardList: PropTypes.object,
  myTeamsLeaderBoardList: PropTypes.object,
  getMyTeamLeaderBoardListFunc: PropTypes.func,
  myAllTeamPagination: PropTypes.func,
  location: PropTypes.object,
  playerRoles: PropTypes.object,
  intl: PropTypes.object
}
export default injectIntl(TeamList(PreviewCricket))
