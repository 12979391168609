import React, { useState, useEffect, useRef } from 'react'
import UserNotification from 'Common/src/components/User/UserNotification'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import { Button, Card, CardBody, CardFooter, CardHeader } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
const classNames = require('classnames')

function NotificationPage (props) {
  const {
    checkNotification, nList, loading, filterSlide, setFilterSlide, ApplyFilter, Filter, filterData, setFilter, setSkip, limit
  } = props
  const [selectedFilter, setSelectedFilter] = useState([])
  const scrollRef = React.createRef()
  const [hasMoreMatches, setHasMoreMatches] = React.useState(true)
  const [NotificationList, setNotificationList] = React.useState([])
  const previousProps = useRef({ nList }).current

  function handleScroll () { // handle the Scrolling
    if (loading || !hasMoreMatches) {
      return
    }
    const wrappedElement = scrollRef.current
    if (wrappedElement.scrollHeight - wrappedElement.scrollTop - wrappedElement.clientHeight <= 0) {
      setSkip(skip => skip + limit)
    }
  }

  useEffect(() => { // handle the response
    checkNotification()
  }, [])

  useEffect(() => { // handle the response
    if (previousProps.nList !== nList) {
      if (nList && nList.length > 0) {
        setNotificationList((TransactionList) => [...TransactionList, ...nList])
        if (nList.length < limit) {
          setHasMoreMatches(false)
        }
      } else if (nList && nList.length === 0) {
        setHasMoreMatches(false)
      }
    }
    return () => {
      previousProps.nList = nList
    }
  }, [nList])

  function callApplyFilter () {
    ApplyFilter(Filter)
    setNotificationList([])
  }
  return (
    <>
      <div className="user-container no-footer" ref={scrollRef} onScroll={handleScroll}>
        <div className='notification-container' onClick={() => {
          if (filterSlide) setFilterSlide(false)
        }}>
              <div className='sort-box d-flex align-items-end justify-content-between pt-0'>
                <div className='sort-i position-relative'>
                </div>
                {/* <button onClick={() => { setFilterSlide(true) }} className='d-flex justify-content-between f-btn mt-3'><i className='icon-sound-mixer'></i>{selectedFilter && selectedFilter.length !== 0 ? selectedFilter && selectedFilter.join(', ') : <FormattedMessage id="League_Select_Filter" />}</button> */}
              </div>
        </div>
        {loading
          ? Array(5).fill().map((item, index) => (
            <div key={index} className="notification-box">
              <Skeleton height={20} width={200} />
              <Skeleton className="my-2" height={10} width={100 + '%'} />
              <Skeleton className="my-2" height={10} width={50 + '%'} />
              <div className="text-right d-flex justify-content-end">
                <Skeleton height={10} width={70} />
              </div>
            </div>
          ))
          : NotificationList && NotificationList.length !== 0
            ? NotificationList.map(data => {
              return (
                <div key={data._id} className={classNames('notification-box', { readed: data.eStatus === 1 })}>
                  <h3>{data.sTitle}</h3>
                  <p>{data.sMessage}</p>
                  <span className="time">{moment(data.dCreatedAt).format('DD MMM., h:mm A')}</span>
                </div>
              )
            })
            : NotificationList && NotificationList.length === 0 && <h1 className='centerFullWidth text-center mt-3'><FormattedMessage id="Notification_NotFound" /></h1>
        }
        {filterSlide
          ? <>
          <div className="s-team-bg" onClick={() => setFilterSlide(false)}></div>
          <Card className={classNames('filter-card', { show: filterSlide }) }>
            <CardHeader className='d-flex align-items-center justify-content-between'>
              <button onClick={() => { setFilterSlide(false) }}><i className='icon-left'></i><FormattedMessage id="League_filterBy" /></button>
            </CardHeader>
            <CardBody>
              <ul className='m-0 d-flex align-item-center flex-wrap'>
                {
                  filterData && filterData.length !== 0
                    ? filterData.map(data => {
                      return (
                        <li key={data.id}>
                          <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={Filter.includes(data.id)} />
                          <label htmlFor='FilterData' onClick={() => {
                            if (Filter.includes(data.id)) {
                              setFilter(Filter.filter(fData => fData !== data.id))
                              setSelectedFilter(selectedFilter.filter(fData => fData !== data.sHeading))
                            } else {
                              setFilter([...Filter, data.id])
                              setSelectedFilter([...selectedFilter, data.sHeading])
                            }
                          }
                          }>{data.sHeading}<i className='icon-checked'></i></label>
                        </li>
                      )
                    })
                    : <h3><FormattedMessage id="No_Filter" /></h3>
                }
              </ul>
            </CardBody>
            <CardFooter className='p-0 border-0 bg-trnsparent'>
              <Button color='primary' onClick={callApplyFilter} className='w-100'><FormattedMessage id="League_applyNow" /></Button>
            </CardFooter>
          </Card>
          </>
          : ''
        }
        {/* <button onClick={() => { setFilterSlide(true) }} className='bottomRight-btn'><i className='icon-sound-mixer'></i>{selectedFilter.length !== 0 && <div className='active'></div>}</button> */}
      </div>
    </>
  )
}
NotificationPage.propTypes = {
  nList: PropTypes.array,
  ApplyFilter: PropTypes.func,
  loading: PropTypes.bool,
  token: PropTypes.string,
  filterSlide: PropTypes.bool,
  limit: PropTypes.Number,
  listed: PropTypes.array,
  filterData: PropTypes.array,
  Filter: PropTypes.string,
  setFilter: PropTypes.func,
  setFilterSlide: PropTypes.func,
  setSkip: PropTypes.func,
  checkNotification: PropTypes.func,
  GetCount: PropTypes.func,
  nCount: PropTypes.array
}
export default UserNotification(NotificationPage)
