import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import finished from '../../../assests/images/finished.svg'
import upcoming from '../../../assests/images/upcoming.svg'
import live from '../../../assests/images/live.svg'
import cancelled from '../../../assests/images/cancelled.svg'
function TournamentScore (props) {
  const { matchDetails, url, showTabs, setShowTabs, userJoinLeaguesFunc, match } = props
  const [showAllData, setShowAllData] = useState(false)
  const token = useSelector(state => state.auth.token) || localStorage.getItem('Token')

  const scoreData = useMemo(() => {
    if (showAllData) return matchDetails?.aMatch
    else return matchDetails?.aMatch?.slice(0, 3)
  }, [showAllData])

  const getImg = (matchStatus) => {
    if (matchStatus === 'U' || (matchStatus === 'P')) return upcoming
    if (matchStatus === 'L') return live
    if (matchStatus === 'I' || matchStatus === 'CMP') return finished
    if (matchStatus === 'CNCL') return cancelled
  }

  return (
    <div className={classNames('tournament-score d-flex flex-column', { 'full-height': showTabs })}>
      {scoreData.map(matchData => (
        <div key={matchData._id} className='t-score-card row'>
          <div className={classNames('col-2 d-flex align-items-center justify-content-center score', { 'green-border': matchData?.eStatus === 'L' })}>
            <img src={getImg(matchData?.eStatus)} />
          </div>
          <div className='col-10 team-names'>
            <div className='team-1 py-2 d-flex justify-content-between align-items-center'>
              <div>
                <img src={url + matchData?.oHomeTeam?.sImage} />
                <span className='pl-2'>{matchData?.oHomeTeam?.sName}</span>
              </div>
              <div>
                {matchData?.oHomeTeam?.nScore}
              </div>
            </div>
            <div className='py-2 d-flex justify-content-between align-items-center'>
              <div>
                <img src={url + matchData?.oAwayTeam?.sImage} />
                <span className='pl-2'>{matchData?.oAwayTeam?.sName}</span>
              </div>
              <div>
                {matchData?.oAwayTeam?.nScore}
              </div>
            </div>
          </div>
        </div>
      ))}
      {matchDetails?.aMatch?.length > 3 && (
      <div className='btn-class'>
        <Button
          className={showTabs ? 'show-more-green-btn' : 'show-more-white-btn'}
          onClick={() => {
            setShowTabs(!showTabs)
            setShowAllData(!showAllData)
            if (showTabs) {
              userJoinLeaguesFunc(match.params.id, token)
            }
          }}
        >
          <FormattedMessage id='Show_More' />
        </Button>
      </div>
      )}
    </div>
  )
}

TournamentScore.propTypes = {
  matchDetails: PropTypes.object,
  url: PropTypes.string,
  showTabs: PropTypes.bool,
  setShowTabs: PropTypes.func,
  userJoinLeaguesFunc: PropTypes.func,
  match: PropTypes.object
}

export default TournamentScore
