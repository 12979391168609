import React, { useState } from 'react'
import LeagueHeader from '../components/LeagueHeader'
import LeaguesDetailPage from './LeaguesDetail'
import PropTypes from 'prop-types'
import qs from 'query-string'

function LeaguesDetail (props) {
  const { match, history } = props
  const [paymentSlide, setPaymentSlide] = useState(false)
  const [VideoStream, setVideoStream] = useState(false)
  const obj = qs.parse(props.location.search)
  return (
    <>
      <LeagueHeader {...props}
        goToBack={history?.location?.pathname?.includes('tournament') ? `/tournament/leagues/${match.params.sportsType}/${match.params.id}` : `/leagues/${match.params.sportsType}/${match.params.id}`}
        search={obj?.homePage ? 'homePage=yes' : ''}
        paymentSlide={paymentSlide}
        setVideoStream={setVideoStream}
        VideoStream={VideoStream}
        setPaymentSlide={setPaymentSlide} insideLeagueDetails showBalance/>
      <LeaguesDetailPage {...props} leagueDetailsPage switching
        paymentSlide={paymentSlide}
        setPaymentSlide={setPaymentSlide}/>
    </>
  )
}

LeaguesDetail.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }),
  match: PropTypes.object,
  homePage: PropTypes.string,
  history: PropTypes.object
}

export default LeaguesDetail
