import React, { useEffect, useState, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Alert, Input } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import PlayerInfo from './PlayerInfo'
import PlayerLeagueInfo from './PlayerLeagueInfo'
import PropTypes from 'prop-types'
import PlayerImage from '../../../assests/images/PlayerImage.png'
import { cricketStaticImage, footballStaticImage, rolesShortForm } from '../../../helper'
import { useSelector } from 'react-redux'
import qs from 'query-string'
const classNames = require('classnames')

function MyTeam (props) {
  const {
    params, teamDetails, allTeams, index, match, setUserTeamId, userTeamId, onLinesUp,
    setUserTeams, userTeams, teamPlayerList, noOfJoin,
    onPreviewTeam, history, viewOnly, leagueData, url, join, disabledRadio, teamId, disabledRadioFlag
  } = props

  const [data, setData] = useState([])
  const [playerPoints, setPlayerPoints] = useState()
  const [inLinesUp, setInLinesUp] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [previewCricket, setPreviewCricket] = useState(false)
  const [captainName, setCaptainName] = useState('')
  const [captainData, setCaptainData] = useState({})
  const [viceCaptainData, setViceCaptainData] = useState({})
  const [viceCaptainName, setViceCaptainName] = useState('')
  const [playerInfo, setPlayerInfo] = useState(false)
  const [modalMessage, setModalMessage] = useState(false)
  const [Message, setMessage] = useState('')
  const [playerLeagueInfo, setPlayerLeagueInfo] = useState(false)
  const [disabledTeam, setDisabledTeam] = useState(false)
  const activeSports = useSelector(state => state.activeSports.activeSports)
  const playerRoles = activeSports && activeSports.find(sport => sport.sKey === ((match.params.sportsType).toUpperCase()))?.aPlayerRoles
  const obj = qs.parse(location.search)

  useEffect(() => {
    if (!disabledRadioFlag) {
      setDisabledTeam(false)
    } else {
      if (disabledRadio === teamId) {
        setDisabledTeam(false)
      } else {
        setDisabledTeam(true)
      }
    }
  }, [disabledRadio, teamId, disabledRadioFlag])

  useEffect(() => {
    setUserTeams && setUserTeams([])
    setInLinesUp(1)
    setMessage('')
    if (teamDetails && teamDetails?.aPlayers?.length > 0 && teamPlayerList) {
      const playerRole = playerRoles?.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).sort((a, b) => a?.nPosition?.toString().localeCompare(b?.nPosition?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map(Role => Role.sName)
      const players = Object.assign({}, ...playerRole?.map(key => ({ [key]: [] })))
      let count = 0
      let points = 0
      teamDetails.aPlayers && teamDetails.aPlayers.length > 0 && teamDetails.aPlayers.map((data) => {
        const PlayerDetails = teamPlayerList && teamPlayerList.length !== 0 && teamPlayerList.find(player => player._id === data)
        Object.entries(players).map(([key, value]) => {
          return (key === PlayerDetails?.eRole) && players[PlayerDetails?.eRole].push(PlayerDetails)
        })
        if (teamPlayerList && teamPlayerList.length > 0 && teamPlayerList.some(data2 => data2._id === data && !data2.bShow)) {
          count = count + 1
        }
        if (data === teamDetails.iCaptainId) {
          const playerName = PlayerDetails?.sName.split(' ')
          setCaptainName(PlayerDetails?.sName && `${playerName && playerName.length >= 2 ? PlayerDetails?.sName[0] : playerName[0]} ${playerName && playerName.length === 2 ? playerName[1] : playerName && playerName.length === 3 ? `${playerName[2]}` : ''}`)
          setCaptainData(PlayerDetails)
        } else {
          if (data === teamDetails.iViceCaptainId) {
            const playerName = PlayerDetails?.sName.split(' ')
            setViceCaptainName(PlayerDetails?.sName && `${playerName && playerName.length >= 2 ? PlayerDetails?.sName[0] : playerName[0]} ${playerName && playerName.length === 2 ? playerName[1] : playerName && playerName.length === 3 ? `${playerName[2]}` : ''}`)
            setViceCaptainData(PlayerDetails)
          }
        }
        if (PlayerDetails && PlayerDetails.nScoredPoints) {
          if (data === teamDetails.iCaptainId) {
            const newPoints = (Number(PlayerDetails.nScoredPoints) * 2)
            points = points + newPoints
          } else if (data === teamDetails.iViceCaptainId) {
            const newPoints = (Number(PlayerDetails.nScoredPoints) * 1.5)
            points = points + newPoints
          } else { points = points + Number(PlayerDetails.nScoredPoints) }
        }
        return players
      })
      setPlayerPoints(points)
      setInLinesUp(count)
      setData(players)
    }
  }, [teamDetails, teamPlayerList])

  useEffect(() => {
    if (modalMessage) {
      setTimeout(() => {
        setModalMessage(false)
      }, 2000)
    }
  }, [modalMessage])

  function OnSubmitting (id) {
    if (userTeams.includes(id)) {
      const newData = userTeams.filter(x => x !== id)
      setUserTeams(newData)
    } else {
      if (noOfJoin === 1) {
        setUserTeams([id])
      } else {
        if (leagueData && leagueData.bUnlimitedJoin) {
          setUserTeams([...userTeams, id])
        } else if ((userTeams.length + 1) <= noOfJoin) {
          setUserTeams([...userTeams, id])
        } else {
          setMessage(<FormattedMessage id="Common_ReachedLimit" />)
          setModalMessage(true)
        }
      }
    }
  }

  return (
    <>
      {
        modalMessage
          ? <Fragment>
            <Alert isOpen={modalMessage} color="primary">{Message}</Alert>
          </Fragment>
          : ''
      }
      <div className={classNames('my-team', disabledTeam && 'pointer-none', join && 'team-custom-width', { selectTeamPreview: viewOnly })}>
        <button className="w-100 p-0 m-0 d-block bg-transparent" onClick={() => !viewOnly ? setUserTeams ? OnSubmitting(teamDetails._id) : setUserTeamId ? setUserTeamId(teamDetails._id) : !onPreviewTeam && setPreviewCricket(true) : ''}>
            {
              !props.points && (setUserTeams || setUserTeamId)
                ? <div className="mt-header d-flex align-items-center justify-content-between"><span className='team-name'>{teamDetails && teamDetails.sName}</span></div>
                : !viewOnly && !props.points
                    ? <div className="mt-header d-flex align-items-center justify-content-between"><span className='team-name'>{teamDetails && teamDetails.sName}</span>
              <div>
                <Link to={{
                  pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/create-team/${(match.params.sportsType).toLowerCase()}/${params}/${teamDetails._id}` : `/create-team/${(match.params.sportsType).toLowerCase()}/${params}/${teamDetails._id}`,
                  search: `?${qs.stringify({
                    homePage: obj?.homePage ? 'yes' : undefined
                  })}`,
                  state: { activeTab: obj?.activeTab }
                }} className="icon-edit"></Link>
                <Link to={{
                  pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/copy-team/${(match.params.sportsType).toLowerCase()}/${params}/${teamDetails._id}/copy` : `/copy-team/${(match.params.sportsType).toLowerCase()}/${params}/${teamDetails._id}/copy`,
                  search: `?${qs.stringify({
                    homePage: obj?.homePage ? 'yes' : undefined
                  })}`,
                  state: { activeTab: obj?.activeTab }
                }} className="icon-copy"></Link>
              </div>
            </div>
                    : props.points
                      ? <div className="mt-header d-flex align-items-center justify-content-between"><span className='team-name'>{teamDetails && teamDetails.sName}</span>
                      <span className="points"><FormattedMessage id="Team_points" />: <span className='p-points'>{teamDetails && teamDetails.nTotalPoints ? parseFloat(Number((teamDetails?.nTotalPoints)).toFixed(2)) : (playerPoints?.toFixed(2) || 0)}</span></span>
                    </div>
                      : <div className="mt-header d-flex align-items-center justify-content-between"><span className='team-name'>{teamDetails && teamDetails.sName}</span></div>

            }
            <div onClick={() => setUserTeams
              ? OnSubmitting(teamDetails._id)
              : setUserTeamId
                ? setUserTeamId(teamDetails._id)
                : onPreviewTeam
                  ? history.push({
                    pathname: history.location.pathname.includes('tournament') ? `/tournament/my-teams-preview/${(match.params.sportsType).toLowerCase()}/${match.params.id}/${teamDetails._id}` : `/my-teams-preview/${(match.params.sportsType).toLowerCase()}/${match.params.id}/${teamDetails._id}`,
                    search: `?${qs.stringify({
                            homePage: obj?.homePage ? 'yes' : undefined
                          })}`,
                    state: {
                      allTeams: allTeams.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })),
                      index,
                      data: { teamDetails },
                      match
                    }
                  })
                  : !viewOnly && setPreviewCricket(true)}>
                <div>
                <div className="team-p d-flex justify-content-center">
                  <div className="player">
                    <div className="img">
                      <img style={{ height: '66px', width: '66px' }} src={captainData?.sImage && url ? `${url}${captainData?.sImage}` : match.params.sportsType === 'cricket' ? cricketStaticImage(captainData?.eRole) : match.params.sportsType === 'football' ? footballStaticImage(captainData?.eRole) : PlayerImage} alt={<FormattedMessage id='Player' />} className="rounded-circle" />
                      <div className="position"><FormattedMessage id="Team_C" /></div>
                    </div>
                    <h3>{captainName}</h3>
                  </div>
                  <div className="player">
                    <div className="img">
                      <img style={{ height: '66px', width: '66px' }} src={viceCaptainData?.sImage && url ? `${url}${viceCaptainData?.sImage}` : match.params.sportsType === 'cricket' ? cricketStaticImage(viceCaptainData?.eRole) : match.params.sportsType === 'football' ? footballStaticImage(viceCaptainData?.eRole) : PlayerImage} alt={<FormattedMessage id='Player' />} className="rounded-circle" />
                      <div className="position"><FormattedMessage id="Team_VC" /></div>
                    </div>
                    <h3>{viceCaptainName}</h3>
                  </div>
                </div>
                <div className="mt-footer d-flex align-items-center justify-content-around">
                  {
                    Object.entries(data).map(([key, value]) => (
                      <span key={value._id} className='player-role-class'>{rolesShortForm(key)}<b>
                        {
                          value && value.length ? value.length : <FormattedMessage id="Team_0" />
                        }
                      </b></span>
                    ))
                  }
                </div>
              {
                onLinesUp && inLinesUp > 0 &&
                <div className="mt-footer d-flex backRed pl-2">
                  <span className='lineups-class'> {'>'} {inLinesUp} <FormattedMessage id="Team_No_Linesup" /></span>
                </div>
              }
            </div>
            </div>
          </button>
        </div>
       {join && <div className={classNames('d-flex align-items-center', disabledTeam && 'pointer-none')} onClick={() => !viewOnly ? setUserTeams ? OnSubmitting(teamDetails._id) : setUserTeamId ? setUserTeamId(teamDetails._id) : !onPreviewTeam && setPreviewCricket(true) : ''}>
       {!props.points
         ? setUserTeams
           ? <div className='Radio-MyTeam'>
                    <Input type="checkbox" disabled={disabledTeam} id={`teamDetails${teamDetails._id}`} name="gender" value={`${teamDetails._id}`}
                      checked={userTeams.includes(teamDetails._id)}
                    />
                    <label htmlFor={`teamDetails${teamDetails._id}`}></label>
                  </div>
           : setUserTeamId
             ? <div className='Radio-MyTeam'>
                      <Input type="radio" disabled={disabledTeam} id={`teamDetails${teamDetails._id}`} name="gender" value={`${teamDetails._id}`}
                        checked={teamDetails._id === userTeamId}
                      />
                      <label htmlFor={`teamDetails${teamDetails._id}`}></label>
                    </div>
             : ''
         : ''}
       </div>}
      {playerLeagueInfo ? <PlayerLeagueInfo {...props} isSeasonPoint={true} onBackClick={() => setPlayerLeagueInfo(false)} onPlayerInfoClick={() => setPlayerInfo(true)}></PlayerLeagueInfo> : ''}
      {playerInfo ? <PlayerInfo onBackClick={() => setPlayerInfo(false)}></PlayerInfo> : ''}
    </>
  )
}

MyTeam.propTypes = {
  params: PropTypes.string,
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
    location: PropTypes.object
  }),
  points: PropTypes.string,
  teamDetails: PropTypes.shape({
    aPlayers: PropTypes.shape([{
      aPlayers: PropTypes.func,
      push: PropTypes.func
    }]),
    _id: PropTypes.string,
    sName: PropTypes.string,
    length: PropTypes.string,
    iViceCaptainId: PropTypes.string,
    iCaptainId: PropTypes.string,
    nTotalPoints: PropTypes.number
    // _id: PropTypes.string
  }),
  leagueData: PropTypes.shape({
    _id: PropTypes.string,
    bUnlimitedJoin: PropTypes.string
  }),
  match: PropTypes.object,
  allTeams: PropTypes.array,
  index: PropTypes.number,
  noOfJoin: PropTypes.number,
  url: PropTypes.string,
  setUserTeamId: PropTypes.func,
  userTeamId: PropTypes.string,
  matchType: PropTypes.string,
  playerScorePoints: PropTypes.func,
  setUserTeams: PropTypes.func,
  userTeams: PropTypes.array,
  teamPlayerList: PropTypes.shape([{
    _id: PropTypes.string
  }]),
  onLinesUp: PropTypes.bool,
  onPreviewTeam: PropTypes.bool,
  viewOnly: PropTypes.bool,
  join: PropTypes.bool,
  disabledRadio: PropTypes.object,
  teamId: PropTypes.id,
  disabledRadioFlag: PropTypes.bool,
  homePage: PropTypes.string,
  playerRoles: PropTypes.object
}

export default MyTeam
