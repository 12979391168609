import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import storage from '../../localStorage/localStorage.js'
import { Login, logout, VerifyOTP, googleLogin } from '../../redux/actions/auth.js'
import { GetUserProfile, GetUserStatastics, UpdateUserProfile } from '../../redux/actions/profile.js'

export const LoginPage = (Component) => {
  return (props) => {
    const dispatch = useDispatch()
    const [modalMessage, setModalMessage] = useState(false)
    const [loading, setLoading] = useState(false)
    const [url, setUrl] = useState('')
    const [message, setMessage] = useState('')
    const [profileModelMessage, setProfileMessage] = useState('')
    const resStatus = useSelector(state => state.auth.resStatus)
    const resMessage = useSelector(state => state.auth.resMessage)
    const FirebaseToken = useSelector(state => state.auth.FirebaseToken)
    const profileMessage = useSelector(state => state.profile.resMessage)
    const profileStatus = useSelector(state => state.profile.resStatus)
    const updatedProfilePic = useSelector(state => state.profile.updateProfilePic)
    const userInformation = useSelector(state => state.profile.userInfo)
    const getUrlLink = useSelector(state => state.url.getUrl)
    const token = useSelector(state => state.auth.token)
    const userData = useSelector(state => state.auth.userData)
    const isAuth = useSelector(state => state.auth.isAuth)
    const addedDeposit = useSelector(state => state.profile.addDeposit)
    const statisticsData = useSelector(state => state.profile.statisticsData)
    const currencyLogo = useSelector(state => state.settings.currencyLogo)
    const nOtpSend = useSelector(state => state.auth.nOtpSend)
    const socialRegisterData = useSelector(state => state.auth.socialRegisterData)
    const userInfo = useSelector(state => state.profile.userInfo)
    const otpVerified = useSelector(state => state.auth.otpVerified)
    const loginUser = useSelector(state => state.auth.loginUser)
    const loginPass = useSelector(state => state.auth.loginPass)
    const previousProps = useRef({ resMessage, resStatus, userInfo, getUrlLink, socialRegisterData, updatedProfilePic }).current
    // call the user profile and statistics api call
    useEffect(() => {
      if (token) {
        dispatch(GetUserProfile(token))
        onGetUserStatastics()
        setLoading(true)
      }
    }, [token])

    useEffect(() => {
      if (getUrlLink) {
        setUrl(getUrlLink)
      }
    }, [getUrlLink])

    useEffect(() => { // handle response message
      if (previousProps.resMessage !== resMessage) {
        if (otpVerified === true && resStatus && previousProps.otpVerified !== otpVerified && loginUser && loginPass) {
          const deviceId = storage.getItem('ID')
          Loginfun('W', loginUser, loginPass, deviceId)
        } else {
          if (resMessage) {
            setMessage(resMessage)
            setModalMessage(true)
          }
          setLoading(false)
        }
      }
      return () => {
        previousProps.resMessage = resMessage
        previousProps.otpVerified = otpVerified
      }
    }, [resStatus, resMessage, otpVerified, loginUser, loginPass])

    useEffect(() => { // Handle api response
      if (previousProps.userInfo !== userInfo) {
        if (userInfo) {
          setLoading(false)
        }
      }
      return () => {
        previousProps.userInfo = userInfo
      }
    }, [userInfo])

    useEffect(() => { // Handle api response
      if (previousProps.profileMessage !== profileMessage) {
        if (profileMessage) {
          setLoading(false)
          setModalMessage(true)
          setProfileMessage(profileMessage)
          setTimeout(() => setModalMessage(false), 2000)
        }
        if (previousProps.updatedProfilePic !== updatedProfilePic) {
          if (updatedProfilePic) {
            dispatch(GetUserProfile(token))
            onGetUserStatastics()
          }
        }
      }
      return () => {
        previousProps.profileMessage = profileMessage
        previousProps.updatedProfilePic = updatedProfilePic
      }
    }, [profileMessage, profileStatus])

    useEffect(() => { // handle the resMessage and pop-up showing
      if (previousProps.resMessage !== resMessage) {
        if (resMessage && resMessage.length) {
          setMessage(resMessage)
          setModalMessage(true)
          setLoading(false)
          setTimeout(() => setModalMessage(false), 2000)
        }
      }
      return () => {
        previousProps.resMessage = resMessage
      }
    }, [resStatus, resMessage])

    useEffect(() => { // handle the loader
      if (socialRegisterData) {
        setLoading(false)
      }
    }, [socialRegisterData])
    function Loginfun(platform, userName, Password, loginID) { //login
      if (loginID) {
        dispatch(Login(FirebaseToken, platform, userName, Password, loginID))
        setLoading(true)
      }
    }

    function googleRegister(socialType, Token) { // social register
      if (Token && socialType) {
        dispatch(googleLogin(socialType, Token))
        setLoading(true)
      }
    }

    // verify otp
    function VerifyOTPFun(mobileNumber, Type, Auth, sCode, loginID) {
      dispatch(VerifyOTP(mobileNumber, Type, Auth, sCode, loginID, FirebaseToken))
    }

    function updateProfilePic(platform, img, data) {
      dispatch(UpdateUserProfile(platform, img, data, token));
      setLoading(true)
    }

    function Logout() {
      dispatch(logout(token))
      setLoading(true)
    }

    function onGetUserInfo() {
      dispatch(GetUserProfile(token))
      setLoading(true)
    }

    function onGetUserStatastics() {
      dispatch(GetUserStatastics(token))
      setLoading(true)
    }

    return (
      <Component
        {...props}
        message={message}
        modalMessage={modalMessage}
        profileMessage={profileMessage}
        loading={loading}
        profileStatus={profileStatus}
        isAuth={isAuth}
        nOtpSend={nOtpSend}
        profileData={userInfo}
        resStatus={resStatus}
        resMessage={resMessage}
        token={token}
        userData={userData}
        url={url}
        VerifyOTP={VerifyOTPFun}
        Logout={Logout}
        Login={Loginfun}
        userInformation={userInformation}
        updateProfilePic={updateProfilePic}
        currencyLogo={currencyLogo}
        googleSignUp={googleRegister}
        socialRegisterData={socialRegisterData}
        onGetUserInfo={onGetUserInfo}
        profileModelMessage={profileModelMessage}
        updatedProfilePic={updatedProfilePic}
        addedDeposit={addedDeposit}
        onGetUserStatastics={onGetUserStatastics}
        statisticsData={statisticsData}
        setModalMessage={setModalMessage}
        setMessage={setMessage}
      />
    )
  }
}

export default LoginPage
