import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import HomeHeader from '../../Home/components/HomeHeader'
import HomeFooter from '../../Home/components/HomeFooter'
import MessageLogin from './LoginMessage'
import LeagueHeader from '../../Home/components/LeagueHeader'

function MessageLoginIndex (props) {
  const { match } = props
  const [VideoStream, setVideoStream] = useState(false)
  const [paymentSlide, setPaymentSlide] = useState(false)

  return (
    <>
      {props.match?.path === '/leagues/:sportsType/:id/v1'
        ? <LeagueHeader
          {...props}
          goToBack={`/home/${match && match.params && match.params.sportsType}/v1`}
          setVideoStream={setVideoStream}
          VideoStream={VideoStream}
          paymentSlide={paymentSlide}
          setPaymentSlide={setPaymentSlide}
          showBalance
        />
        : <HomeHeader
        home
        {...props}
        active
        sportsRequired
        showBalance/>}
      <MessageLogin {...props}/>
      {props.match?.path === '/leagues/:sportsType/:id/v1' ? '' : <HomeFooter {...props} isPublic={(props.match?.path === '/matches/:sportsType/v1' || props.match?.path === '/profile/v1' || props.match?.path === '/leagues/:sportsType/:id/v1')}/>}
    </>
  )
}

MessageLoginIndex.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object
}

export default MessageLoginIndex
