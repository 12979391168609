import React from 'react'
import { FormattedMessage } from 'react-intl'
import UserHeader from '../components/UserHeader'
import ProfileInfoPage from './Preference'
function PreferenceInfo (props) {
  return (
    <>
      <UserHeader {...props} title={<FormattedMessage id="Profile_Preference" />} backURL="/profile"/>
      <ProfileInfoPage {...props}/>
    </>
  )
}

export default PreferenceInfo
