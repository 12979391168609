/* eslint-disable quote-props */
export default {
  'en-US': {
    // 'Static_Share_text': 'Hey there! I dare you to join this challenge. Let’s see who takes the crown. This is exclusive invite only contest of Cartola Pix for PCK vs BCC match.Use below contest code or link to join. Contest code: 12564f',
    'Static_Share_text':'Hey there! I dare you to join this challenge. Let’s see who takes the crown.This exclusive invite only contest of ',
    // 'Static_Title_Text': 'Check out my team for SOU-L vs BAN-L Cricket match. Make your own team and play with me:',
    'Static_Title_Text':'Use below contest code or link to join.Contest code or link to join.Contest code:',
    'Static_Facebook': 'Facebook',
    'Static_Twitter': 'Twitter',
    'Static_Telegram': 'Telegram',
    'Static_Share_ReferCode': 'Please use my Refer Code',
    'Static_In_Cartola_Pix': 'in cartola pix',
    'Kyc_Title': "KYC Verification",
    'Home_Info': "Information",
    'Refer_a_friend': "Refer a Friend",
    'Extra_Yes': "Yes",
    'Match_Winning_Zone': "YOU ARE IN THE WINNING ZONE",
    'Extra_No': "No",
    'NotFound_404': "404",
    'NotFound_Page': "Page Not Found!",
    'NotFound_GoHome': "Go Home",
    'Cashback_On': "Cashback On",
    'Bonus_On': "Bonus On",
    'Version': "Version",
    'FairPlay_Not_available': "Fair Play is not available",
    'No_Filter': "No Filter Are Available",
    'No_Contest': "No Contests Are Available",
    'No_Team': "No Teams Are Available",
    'Leaderboard': "Leaderboard",
    'Extra_PrizeBreakup': "Prize Breakup",
    'Extra_Conditions': "Conditions",
    'Share_Code': "Share this code to refer and earn bonus",
    'Contast_Min_Req': 'Entry Fee cannot be less than 5.',
    'Common_EmailError': 'Enter a valid email address',
    'Required_Email': 'Email address is required',
    'Common_ReachedLimit': 'Reached the limit to join the Contest',
    'Common_Required': 'Required Field',
    'Common_NotMatchPass': 'Password do not match',
    'Common_NumberOTP': 'Otp must be number',
    'Otp_Length_Number': 'Otp length should be 4',
    'Common_TotalCredits': 'Total Credits',
    'Common_RequiredEmail': 'Enter a email-id',
    'Common_RequiredMobile': 'Enter a Mobile-number',
    'Common_loginEmailPass': 'Invalid Email/Mobile',
    'Common_mobileError': 'Mobile number is already exist.',
    'Common_emailError': 'Email is already exist.',
    'Common_usernameError': 'Username is already exist.',
    'Common_properMobile': 'Mobile number must be 10 or 11 digits.',
    'Required_Mobile': 'Mobile number is required',
    'Common_length3': 'Name must be minimum 3 character.',
    'Common_length4': 'Length Should be 4',
    'Common_length5': 'Length Should be 5',
    'Common_Contest_length4': 'Contest Name must be minimum 4 character',
    'Contest_without_special_Character': 'Special character are not allowed in Contest name.',
    'Common_Contest_length16': 'Contest Name cannot be larger than 16 characters',
    'Common_UserName_length5': 'Username must of minimum of 3 character',
    'Common_CompanyName_length5': 'Company Name must of minimum of 3 character',
    'Common_CompanyPosition_length5': 'Position must of minimum of 3 character',
    'Contest_Amount_Between': 'Choose winning amount between 1 and 10000',
    'Contest_Amount_Between_2': 'Choose winning amount between ',
    'LeagueDetails_NoOffer': 'No offers are available',
    'Common_PassLength': 'Password must contain at least eight characters, at least one number and both lower and uppercase letters and special characters',
    'Common_ConfirmPasswordNotFound': 'Password not match',
    'Common_ReqOld': 'Current Password is Required.',
    'Common_ReqNew': 'Password is Required',
    'Common_Req_RePassword': 'Re-Password is Required',
    'Common_ReqConfirm': 'Confirm Password is Required',
    'Common_ReqContest_Size': 'Contest Size is Required',
    'Common_ReqContest_Price': 'Contest Prize is Required',
    'Common_Req_Email_Mobile': 'Email / Mobile is Required',
    'Common_Req_Mobile_No': 'Mobile Number is Required',
    'Common_Req_Email': 'Email is Required',
    'Common_Req_Name': 'Name is Required',
    'Common_Req_Username': 'Username is Required',
    'Common_ConfirmPassword': 'Confirm Password',
    'Common_NewPassword': 'New Password',
    'Common_OldPassword': 'Old Password',
    'Common_Submit': 'Submit',
    'User_Apply': 'Apply',
    'Note_Info': 'Note',
    'Change_IN': '(In ',
    'Change_SEC': 'Sec)',
    'Transaction_All': 'All',
    'Transaction_Cash': 'Cash',
    'Transaction_Bonus': 'Bonus',
    'Transaction_Cancel_WT_Req': 'Cancel Withdraw Request',
    'Transaction_Pending_WT_Req': 'You have a Pending Withdrawal request of R$',
    'Transaction_Pending_Req_OF': 'You have a Pending Withdrawal request of ',
    'Transaction_Cancel_it_wish': 'You may Cancel it if you wish to.',
    'User_WON_BY': 'WON BY',
    'User_BOTH_PLAYER': `BOTH TEAM'S HAVE SAME POINTS`,
    'User_VerifiedPend': 'Verification Pending',
    'User_VerifiedSucc': 'Verification Successful',
    'User_Rejected': 'Rejected',
    'User_promocodeForU': 'Promocodes For You',
    'Have_Promocode': 'Have a Promocode?',
    'Enter_Promocode': 'Enter Promocode',
    'User_Promocode': 'Promocode',
    'User_Promocodes': 'Promocodes',
    'Add': 'Add',
    'User_AddMoney': 'Add Money',
    'User_DCN': 'Debit, Credit & Net Banking',
    'User_AMZandCaseback': 'Deposit using Amazon Pay & Get cashback surprise',
    'User_proceed': 'Proceed',
    'User_validPAN': 'Please enter valid PAN Card Number',
    'User_PANCardNumber': 'Please enter PAN Card Number',
    'User_PanImage': 'Please add your Pan Card image',
    'User_AadharImage': 'Please add your Aadhaar Card image',
    'User_ADDPanImage': 'Add PAN Card Photo',
    'Complaint_ADDImage': 'Add Image',
    'User_PanNumber': 'PAN Card Number',
    'User_PanName': 'PAN Card Name',
    'User_AadharNumber': 'Aadhaar Card Number',
    'User_AadharCardFPic': 'Add Aadhaar Card Photo (Front)',
    'User_AadharCardBPic': 'Add Aadhaar Card Photo (Back)',
    'User_validAADHAR': 'Please enter valid Aadhaar Card Number',
    'User_AADHARCardNumber': 'Please enter Aadhaar Card Number',
    'Required_PAN_Card_Number': 'Pan Card Number is required',
    'Required_AADHARCardNumber': 'Aadhaar Card Number is required',
    'Required_AADHARCard_Len': 'Aadhaar Card Number length must be 12',
    'Required_AADHARCard_Number': 'Enter only numbers',
    'Required_AADHARCardImage': 'Aadhaar Card Image is required',
    'User_PAN': 'Pan Card',
    'Ball_Faced': 'Ball Faced',
    'Overs_Bowled': 'Overs Bowled',
    'User_AADHAAR': 'Aadhaar Card',
    'User_withdrawal': 'Withdrawal',
    'User_KycVerification': 'KYC Verification',
    'User_commission_Private_Contest': 'Commission of private contest',
    'User_Maximum': 'Maximum',
    'User_Player_From_team': 'players from a team',
    'User_profileInfo': 'Edit Profile',
    'User_leaderBoard': 'Leaderboard',
    'User_transaction': 'Transactions',
    'User_changePassword': 'Change Password',
    'User_logout': 'Logout',
    'profileInfo_userName': 'Username',
    'profileInfo_Fullname': 'Full Name',
    'profileInfo_Email': 'Email ID',
    'profileInfo_MobileNo': 'Mobile Number',
    'profileInfo_Gender': 'Gender',
    'profileInfo_Male': 'Male',
    'profileInfo_Female': 'Female',
    'profileInfo_Other': 'Other',
    'Verify_Email': 'Verify Your Email',
    'Verify_Mobile': 'Verify Your Mobile',
    'Common_and': 'and',
    'League_single_entry': 'Single Entry',
    'League_Multi_Entry': 'Multi Entry',
    'League_multiple_entry': 'Multiple Entry',
    'League_Single_Winner': 'Single Winner',
    'League_Multi_Winner': 'Multi Winner',
    'League_Guaranteed': 'Guaranteed',
    'League_Confirmed': 'Confirmed',
    'League_Upto': 'Upto',
    'League_Points': 'Points',
    'League_Entries': 'Entries',
    'League_Entry': 'Entry',
    'Match_IN_Review': '(In-Review)',
    'Common_sort': 'Sort',
    'League_Clear': 'Clear',
    'League_Close': 'Close',
    'League_Compare': 'Compare',
    'League_Notes': 'Lineups data may not be accurate. please refer other sources as well to confirm.',
    'League_Orange_Text': 'Players are in your team',
    'League_CompareTeam': 'Compare Team',
    'League_Team': 'Team',
    'League_CONTEST': 'Contest',
    'Deposit_CASHFREE': 'CashFree',
    'Deposit_AMAZON_PAY': 'Amazon Pay',
    'League_CommonPlayers': 'Common Players',
    'League_bothCVCSame': `Both team's C, and VC have same points`,
    'League_myCVCLeadBy': 'C & VC lead by',
    'League_opponentCVCLeadBy': 'Your opponents C & VC lead by',
    'League_bothPlayersLeadBy': 'Both Team players Points Same',
    'League_myPlayersLeadBy': 'players lead by',
    'League_opponentPlayersLeadBy': 'players lead by',
    'League_selectedTeams': 'Selected Teams',
    'Notification_NotFound': 'Notification not found',
    'Complaints_NotFound': 'Complaints not found',
    'Complaints_Complaints': 'Complaints',
    'Complaints_ADD_Complaints': 'Add Complaint/ Feedback',
    'Complaints_Feedback': 'Feedback',
    'Contest_cSizeGreater2': 'Contest Size should be greater than 2',
    'Contest_Contest_Size_between': 'Choose Contest Size between 2 and 500',
    'Contest_Size_between': 'Choose Contest Size between',
    'Contest_NumberGreater2': 'number should be greater than 2',
    'Contest_shouldBeNumber': 'Should be a Number',
    'Team_player11': 'Player Should be eleven.',
    'Common_ReqWk': 'Wicket KeePer Should be required',
    'Common_ReqAll': 'All Rounder Should be required',
    'Common_ReqBats': 'Batsman Should be required',
    'Common_ReqBall': 'Bowler Should be required',
    'Common_MinBats': 'Batsman Should be min',
    'Common_MinBowl': 'Bowler Should be min',
    'Common_Minwk': 'Wicket KeePer Should be min',
    'Common_MinAllR': 'All Rounder Should be min',
    'Common_perc': '%',
    'Common_percBy': '% C By',
    'Common_perVcBy': '% VC By',
    'Team_C': 'C',
    'Team_2x': '2x',
    'Team_1_5x': '1.5x',
    'Team_eleven': '11',
    'Team_VC': 'VC',
    'Team_9CR': '9 Cr',
    'Team_0CR': '0 Cr',
    'Team_0Pts': '0 Pts',
    'Team_0': '0',
    'Team_No_Linesup': 'Players are not announced in Lineups',
    'Team_Pts': 'Pts',
    'Team_Cr': 'Cr',
    'Team_Won': 'Won ',
    'Team_You_Won': 'YOU WON R$',
    'Team_Rupee': 'R$',
    'PROFILE_AVL_BALANCE': 'Available Balance',
    'PROFILE_DEP_BALANCE': 'Deposit Balance',
    'PROFILE_WIN_BALANCE': 'Win Balance',
    'PROFILE_CASH_BONUS': 'Cash Bonus',
    'PROFILE_ADD_CASH': 'Add Cash',
    'PROFILE_TOTAL_BALANCE': 'Total Balance',
    'PROFILE_BONUS_BALANCE': 'Bonus Balance',
    'Team_batsman': 'BATSMAN',
    'Team_wk': 'WICKET KEEPER',
    'Team_bowler': 'BOWLER',
    'Team_allRounder': 'ALL ROUNDER',
    'Team_ShareTeam': 'Share Team',
    'Team_ScoreData': 'Yet to bat',
    'Team_CreateTeam': 'Create Team',
    'Team_CreatePrivateLeague': 'Create Contest',
    'Common_Total': 'Total',
    'Common_TotalPoints': 'Total Points',
    'Match_IPL': 'Indian Premier Contest',
    'Common_usernameShouldBeSpace': 'User name must be alpha-numeric.',
    'Valid_Pincode': 'Please enter valid PIN Code',
    'Common_username_max_15Char': 'User name must be maximum of 16 characters',
    'Common_specialCharacter': 'User name Special character is not allowed to enter.',
    'OfferDetails_amazonPay': 'Amazon Pay Offer',
    'withDraw_ReqBankName': 'Bank name is required',
    'withDraw_ReqBankAccName': 'Bank account name is required',
    'withDraw_ReqBankBranchName': 'Bank branch name is required',
    'withDraw_AccNotValid': 'Account number is not valid',
    'withDraw_IFSCNotValid': 'IFSC code is not valid',
    'withDraw_ReqAccNo': 'Account number is required',
    'withDraw_AC_Should_Number': 'Account number should be number',
    'withDraw_ReqIFSCNo': 'IFSC code is required',
    'withDraw_amountWithdraw': 'Enter the amount you wish to withdraw from your balance.',
    'withDraw_withdrawalAmount': 'Withdrawal Method',
    'withDraw_WithdrawalBank': 'Bank Withdrawal',
    'withDraw_withdrawalPaytm': 'Paytm Withdrawal',
    'withDraw_withdrawalInstantPaytm': 'Instant Paytm Withdrawal',
    'withDraw_addBankDetails': 'Add Bank Details',
    'withDraw_viewBankDetails': 'View Bank Details',
    'withDraw_processWithdeaw': 'Proceed to Withdraw',
    'withDraw_bankName': 'Bank Name',
    'withDraw_bankBranchName': 'Bank Branch Name',
    'withDraw_bankAccName': 'Bank Account Holder Name',
    'withDraw_AccNo': 'Account Number',
    'withDraw_IfscCode': 'IFSC Code',
    'withDraw_Save': 'Save',
    'withDraw_1500': '1500',
    'withDraw_2500': '2500',
    'withDraw_5000': '5000',
    'deposit_25': '25',
    'Team_AllTeam': 'All Teams',
    'Team_AllTime': 'All Time',
    'League_TeamCompare': 'Team Comparison',
    'withDraw_Data1': 'Fee - R$10, 24-48 Working Hours',
    'withDraw_Data2': '3% Paytm charge, 24 hr process',
    'withDraw_Data3': '3% Paytm charge (once a week)',
    'Deposit_AmountBalance': 'Enter the amount you wish to add to your balance.',
    'ShareContest_ccc': 'Copied Successfully',
    'ShareContest_notCopyText': 'Could not copy text: ',
    'Footer_Home': 'Home',
    'Footer_Away': 'Away',
    'League_10': '10',
    'League_action': 'action',
    'League_Off': 'Off',
    'League_Applied': 'Applied!',
    'No_Transaction': 'No Transaction are available',
    'Footer_Matches': 'My Matches',
    'Footer_Profile': 'Profile',
    'Footer_More': 'More',
    'More_Feedback': 'Complaints & Feedback',
    'Complaint_Type': 'Type',
    'Complaint_Title': 'Title',
    'Complaint_Description': 'Descriptions',
    'Home_Cricket': 'Cricket',
    'Home_Football': 'Football',
    'Home_Kabaddi': 'Kabaddi',
    'Home_No_Match': 'No Match Found!',
    'Home_Cricket_Msg': 'No Upcoming Cricket matches available',
    'Home_Football_Msg': 'No Upcoming Football matches available',
    'Home_Kabaddi_Msg': 'No Upcoming Kabaddi matches available',
    'Matches_Cricket_Live': 'No Live Cricket matches available',
    'Matches_Football_Live': 'No Live Football matches available',
    'Matches_Kabaddi_Live': 'No Live Kabaddi matches available',
    'Matches_Cricket_Completed': 'No Completed Cricket matches available',
    'Matches_Football_Completed': 'No Completed Football matches available',
    'Matches_Kabaddi_Completed': 'No Completed Kabaddi matches available',
    'Matches_Upcoming': 'Upcoming',
    'Matches_Live': 'Live',
    'Matches_Watch_Live': 'Watch Live',
    'Matches_Watch_Now': 'Watch Now',
    'Matches_Completed': 'Completed',
    'More_PSystem': 'Point System',
    'More_HTP': 'How To Play ?',
    'More_offers': 'Offers',
    'More_IF': 'Invite Friend',
    'More_AboutUs': 'About Us',
    'More_ContactUs': 'Contact Us',
    'More_T&C': 'Terms & Conditions',
    'More_Policy': 'Policy',
    'More_pPolicy': 'Privacy Policy',
    'More_fpPolicy': 'Fair Play Policy',
    'More_12thmanPolicy': '12th Man Feature',
    'More_discPolicy': 'Disclaimer',
    'More_legaPolicy': 'Legality',
    'More_spotlPolicy': 'Is fantasy sport legal in India ?',
    'More_withdrawPolicy': 'How to withdraw ?',
    'More_F&Q': 'FAQ',
    'More_AppStore': 'App Store',
    'More_HTD': 'How to deposit ?',
    'More_HTR': 'How to register ?',
    'More_ChatSupport': 'Chat Support',
    'Welcome_Invite': 'Invited by a friend?',
    'Welcome_EnterCode': 'Enter Code',
    'Welcome_AlUser': 'Already an user?',
    'Welcome_Login': 'Login',
    'Welcome_Khelo': '#khelo',
    'Welcome_NewUser': 'New User?',
    'Welcome_SignUp': 'Sign Up',
    'Team_Compare': 'Team Compare',
    'NO_PointBreakup_Avail': 'No pointBreakup Avaialble',
    'Welcome_Hash': '#',
    'Congrats_you_win': 'Congratulations you have won in',
    'Win_contest': 'contest',
    'ProfileInfo_State': 'Select State',
    'ProfileInfo_City': 'Select City',
    'ProfileInfo_Country': 'Select Country',
    'Profile_Country': 'Country',
    'Profile_State': 'State',
    'Profile_City': 'City',
    'Profile_PinCode': 'Pin Code',
    'Profile_Address': 'Address',
    'ProfileInfo_DOB': 'Date of Birth',
    'Welcome_Endless': 'endless',
    'Welcome_LetsP': `Play`,
    'Login_Otp': 'OTP',
    'Login_Email': 'Email',
    'Login_Password': 'Password',
    'Login_ForgetPassword': 'Forgot password?',
    'Forgot_Password': 'Forgot Password',
    'Reset_Password': 'Reset Password',
    'OTP_Msg': 'You will receive OTP on ',
    'Login_ConfirmPassword': 'Confirm password',
    'Login_login': 'Log in',
    'Login_withLogin': 'OR LOGIN WITH',
    'Login_conGoogle': 'continue with Google',
    'Login_conFacebook': 'continue with Facebook',
    'Login_noAcc': 'Don’t have an account ?',
    'Login_signUp': 'Sign up',
    'SignUp_Confirm_18': "I am 18 years of age or older",
    'SignUp_Confirm_marketingInfo': "I agree to receive marketing information",
    'SingUp_Confirm_18_above' :'Please Comfirm That Your age is above 18 years',
    'SignUp_Age_18': 'Age must be greater than 18.',
    'SignUp_Confirm_State': 'I am not a resident of',
    'SignUp_Confirm_State1': 'Assam, Andhra Pradesh, Nagaland, Odisha, Sikkim',
    'SignUp_Confirm_State2': 'Telangana',
    'Login_Agreed': 'I have read and accepted ',
    'Login_Agreed_Term_Condition': 'Please Confirm that you are agree with terms and conditions.',
    'Login_VerifiedPassword': 'Confirm Password',
    'Login_ResendOTP': 'Resend OTP',
    'Login_VerifyOtp': 'Verify OTP',
    'SignUp_Agree': 'By continuing, you agree to our',
    'Signup_Terms': 'Terms & Conditions',
    'SignUp_And': 'and',
    'SignUp_PrivPolicy': 'Privacy Policy',
    'SignUp_Username': 'Username',
    'SignUp_Name': 'Name',
    'SignUp_CompanyName': 'Company',
    'SignUp_CompanyPosition': 'Position',
    'SignUp_MobNo': 'Mobile Number',
    'SignUp_SignUp': 'Sign Up',
    'SignUp_Play': 'Play',
    'SignUp_VerifyOtp': 'Verify OTP',
    'SignUp_GetOtp': 'Get OTP',
    'Home_Change': 'Change',
    'Transaction_Details': 'Transaction Details',
    'Transaction_ID': 'Transaction ID',
    'Transaction_Date': 'Transaction Date',
    'SignUp_Usernames': 'Email',
    'SignUp_Ref': 'Referral Code (Optional)',
    'ConfirmPass_digitOtp': 'Please enter the OTP to verify account. We have sent you a code to ',
    'ConfirmPass_notmatchCode': 'I didn’t get a code',
    'ConfirmPass_getStarted': 'Get Started',
    'Forget_Submit': 'Submit',
    'Forget_viaEmail': 'Via Email',
    'Forget_viaSms': 'Via SMS',
    'Preference_SMS': 'SMS',
    'Preference_Sound': 'Sound',
    'Preference_Vibration': 'Vibration',
    'Forget_rEmail': `Please enter your registered email address. We'll send an OTP to reset your password.`,
    'Forget_rMobile': `Please enter your registered mobile number. We'll send an OTP to reset your password.`,
    'Verification_verify': 'Verify',
    'Verification_getCode': 'I didn’t get a code',
    'Verification_4DigitCode': 'Please enter the OTP to verify your account. We have sent you a code to ',
    'Player_NoMatchList': 'No stats available for this player yet! you can view this player’s stats in the team’s next match of the tour',
    'Contast_NoLeeague1': 'Contests are not available yet!',
    'Contast_NoLeeague': 'No contests joined yet! Join one to win prizes.',
    'Contast_NoSeries': 'No Data Found',
    'Contast_NoRecord': 'No Record Found',
    'Contast_LeagueJoined': 'Leagues joined',
    'Contast_NoPlayer': 'No Player Joined Yet',
    'Contast_JoinLeeague': 'Join Contest',
    'League_join': 'Join',
    'League_join_create_contest': 'Create Contest ',
    'League_Payment': 'Payment',
    'League_Promocode': 'Select Promocode',
    'League_Total_Entry': 'Total Entry',
    'League_To_Pay': 'To Pay',
    'Blank_Promocode_List': 'No Promocode available for this contest.',
    'League_Discount': 'Discount',
    'League_In_Wallet': 'From wallet',
    'From_bonus': 'From Bonus',
    'League_Apply_Promocode': 'Apply Promocode',
    'League_Teams_Selected': 'Teams Selected',
    'League_switchTeam': 'Switch',
    'League_switch': 'Switch',
    'League_noSwitchTeam': 'No Switch Team Are Available',
    'League_joined': 'Joined',
    'League_contest_type': 'Contest Type',
    'League_league_type': 'League Type',
    'League_clear_all': 'Clear All',
    'League_prize_pool': 'Prize Pool',
    'League_no_of_teams': 'Number of Teams',
    'League_contest': 'Contest',
    'League_myContest': 'My Contest',
    'League_myTeams': 'My Teams',
    'League_matchTips': 'Match Tips',
    'League_powerByTips': 'Match Tips - powered by crictracker',
    'League_filterBy': 'Filter By',
    'League_announced_Lineups': 'Announced Lineups',
    'League_sortBy': 'Sort By',
    'League_popularity': 'Popularity',
    'League_spots': 'Spots',
    'League_entry': 'Entry',
    'League_prizePoll': 'Prize Pool',
    'League_applyNow': 'Apply Now',
    'Continue': 'Continue',
    'League_myContests': 'my contests',
    'League_playerStats': 'Player Stats',
    'League_scorecard': 'Scorecard',
    'League_prizeBreakup': 'Prize Breakup',
    'League_leaderboard': 'Leaderboard',
    'League_Rank': 'Rank',
    'League_Leaders': 'Leaders',
    'League_LoyaltyPoints': 'Loyalty Points',
    'League_Amazon_Gifts': 'Amazon pay gift card T&C',
    'League_Wins': 'Wins',
    'League_noContest': 'No Contests are available.',
    'League_notification': 'Notification not found',
    'Team_SaveTeam': 'Save Team',
    'Team_SelectTeam': 'Select Team',
    'Team_SelectAll': 'Select All',
    'Team_Title1': 'You can select max ',
    'Team_Title2': 'teams for this contest.',
    'Team_points': 'Points',
    'Team_WK': 'WK',
    'Team_BAT': 'BAT',
    'Team_Batsmen': 'Batsmen',
    'Team_AllRounder': 'All Rounder',
    'Team_AR': 'AR',
    'Team_BOWL': 'BOWL',
    'Teams_noTeam': 'No Team Available',
    'Teams_noTeamAdded': 'No Team created yet!',
    'Teams_noData': 'No data found. Please check back after a while.',
    'Teams_noPrizeBreakup': 'No Prize Breakup Found',
    'Teams_createNew': 'Create A Team',
    'Player_selectedBy': 'Selected By',
    'Player_selBy': 'Sel By',
    'Player_points': 'Points',
    'Player_point': 'Point',
    'Player_players': 'players',
    'Player_filter': 'Filter',
    'Player_credits': 'Credits',
    'Player_scoreCard': 'Scorecard',
    'Player_c': 'C By',
    'Player_vc': 'VC By',
    'Player_event': 'Events',
    'Player_action': 'Actions',
    'Player_matchWise': 'Matchwise Fantasy Stats',
    'League_prizePool': 'Prize Pool',
    'League_enterCode': 'Join contest',
    'League_createContest': 'Create a contest',
    'League_1stPrize': '1st Prize',
    'League_Prize': 'Prize',
    'League_winners': 'Winners',
    'League_left': 'left',
    'League_bonus': 'Bonus',
    'Matches_LineupsOut': 'Lineups Out',
    'Contest_createeam': 'Create A New Team',
    'Contest_contestName': 'Contest Name',
    'Contest_contestSize': 'Contest Size',
    'Contest_contestPrize': 'Contest Prize',
    'Contest_allowTeam': 'Allow multiple teams ?',
    'Contest_allowPool': 'Allow contest to be confirmed with minimal possible participants?',
    'Contest_entryFee': 'Entry Fee',
    'Contest_joinContest': 'Join Contest',
    'Contest_inviteCode': 'Contest Code',
    'Contest_inviteCodetoJoinContest': 'Enter the contest code to join contest.',
    'Contest_createContest': 'Create Contest',
    'Contest_joinAndcreateContest': 'Join & Create Contest',
    'Contest_bonus': 'You Will Get Creator Bonus of ',
    'Contest_NoOfWinners': 'Choose total no. of winners',
    'Contest_poolText': 'By default all decided participaints are required for contest to be confirmed',
    'Contest_FeeText': 'Entry Fee is calculated based on contest size & contest prize',
    'Team_chooseCaptain': 'Choose Captain and Vice Captain',
    'Team_12thman': 'Captain gets 2x and Vice Captain gets 1.5x Points',
    'Team_Select': 'Select',
    'Team_creditLeft': 'Credits Left',
    'Team_players': 'Players',
    'other_Next': 'Next',
    'other_Prev': 'Previous',
    'other_shareContest': 'Share the contest code',
    'other_copy': 'copy',
    'other_shareWhatsapp': 'share on Whatsapp',
    'other_sharingOption': 'other sharing options',
    'pointSystem_ODI': 'ODI',
    'pointSystem_T10': 'T-10',
    'pointSystem_T20': 'T-20',
    'pointSystem_100BALLS': '100 BALLS',
    'pointSystem_TEST': 'TEST',
    'pointSystem_attributeName': 'Attribute Name',
    'pointSystem_minimum': 'Minimum',
    'pointSystem_fromTo': 'From - to',
    'pointSystem_From': 'From',
    'pointSystem_To': 'To',
    'pointSystem_This_Week': 'This Week',
    'pointSystem_This_Month': 'This Month',
    'pointSystem_This_Year': 'This Year',
    'pointSystem_Other_Options': 'Other Options',
    'Footer_Bonus': 'Bonus',
    'Footer_Wins': 'Wins',
    'Profile_Preference': 'Preferences',
    'Match_NotStarted': 'Match is not started',
    'Match_Download': 'Download',
    'Match_DownloadTeams': 'Download Teams',
    'Match_TeamLocked': 'Teams Locked! Download and track Your Competition.',
    'Match_Cancel': 'Cancel',
    'Match_VS': 'VS',
    'WON': 'WON R$',
    'ONLY_WON': 'WON',
    'WON_Multiple_Prize': 'Won Multiple Prizes >',
    'WON_Multiple_Prize1': 'Won Multiple Prizes ',
    'ONLY_WON_Gadget': 'Won Gadgets',
    'YOU_ONLY_WON_Gadget': 'You Won Gadgets',
    'WON_Multiple_Prizes': 'Won Multiple Prizes',
    'YOU_WON_Multiple_Prizes': 'You Won Multiple Prizes',
    'Team': 'Team',
    'Contest': 'Contest',
    'Apply_filter':'Apply filter',
    'league_Wins':'Wins',
    'OFFER_TITLE': 'Offer',
    'League_Select_Filter': 'Select Filters',
    'Team_BATS': 'BATS',
    'Team_ALLR': 'ALLR',
    'Team_BWL': 'BWL',
    'YOU_WON_Multiple_Prizes': 'You Won Multiple Prizes',
    'Common_Should_Be_MIN': 'Should be min',
    'Common_Should_Be_REQ': 'is required',
    'JOIN_ERR': 'Code should be greater than 4',
    'No_PointBreakup':'No PointBreakup Available',
    'Available_balance':'Available Balance',
    'Deposited_balance':'Deposit Balance',
    'Win_balance':'Win Balance',
    'LeaderBoard_Series': 'Series-Wise',
    'LeaderBoard_month': 'Last Month',
    'LeaderBoard_Serieswise': 'Series-wise',
    'LeaderBoard_allTeams':'All Teams',
    'Select_Series':'Select Series',
    'No_Team_Available':'No Teams Available to Switch',
    'No_Score_Point_Available':'No Score Points Are Available',
    'No_Sports_Available':'No Sports Are Available',
    'Withdraw_addBankDetails':'Add Your Bank Details',
    'Withdraw_fill_BankDetails':'Please fill your bank details first.',
    'Withdraw_greaterThan10':'Amount Should be greater than 10',
    'Withdraw_not_greaterThan_WinBalance':'Amount Can not Be greater than win Balance',
    'Deposit_min_25':'Minimum amount to deposit is ',
    'Withdraw_Between_To':'Withdraw Amount between',
    'Deposit_Between_To':'Deposit Amount between',
    'Deposit_between':'Between',
    'Game_Cricket':'CRICKET',
    'Game_Football':'FOOTBALL',
    'Match_Player_Not_Exist':'Match Player is not exist.',
    'Match_Player_Role_Not_Exist':'Player Role are not exist',
    'No_Team_Available_To_Join':'No Team Available To Join',
    'No_Conditions_Here': 'No Conditions Here',
    'Enter_New_Email': 'Enter New Email',
    'Enter_New_Mobile': 'Enter New Mobile',
    'ScoreCard_None': 'ScoreCard is not available.',
    'ScoreCard_Bwl': 'Bowler',
    'ScoreCard_Bat': 'Batsman',
    'ScoreCard_Runs': 'R',
    'ScoreCard_Balls': 'B',
    'ScoreCard_4s': '4s',
    'ScoreCard_6s': '6s',
    'ScoreCard_SR': 'SR',
    'ScoreCard_Ov': 'Ov',
    'ScoreCard_M': 'M',
    'ScoreCard_R': 'R',
    'ScoreCard_W': 'W',
    'ScoreCard_NB': 'NB',
    'ScoreCard_WD': 'WD',
    'ScoreCard_Eco': 'Eco',
    'ScoreCard_FOW': 'FALL OF WICKETS',
    'ScoreCard_RPO': 'Runs Per over',
    'ScoreCard_Total': 'Total',
    'ScoreCard_Extra': 'Extras',
    'No_DATA_LABEL': 'No Data Found. Please check back after a while.',
    'Restricted_state': 'Selected state is restricted',
    'Match_Disable_Msg': 'Contest for this match will open soon. Stay Tuned...!',
    'No_Future_Date': 'Date should not be future date',
    'Wrong_birth_year': 'Enter proper birth date!',
    'Only_alphabets_allowed': 'Only alphabets are allowed!',
    'Match_Live_Msg': 'Times  up!!',
    'Playing': 'Playing',
    'My_Refer_Code': 'My Refer Code',
    'Pay': 'Pay',
    'Enter_the_amount': 'Enter Amount : ',
    'Profile_deposite_info_msg': `Money you added will be shown here. You can use it to join contest, but can't withdraw.`,
    'Profile_balance_info_msg': 'Money thay you can withdraw or re-use to join any contest.',
    'Valid_Credentials': 'Please enter a valid credentials.',
    'Winning_Breakup': 'Winning Breakup',
    'Verification': 'Verification',
    'Complaint_Details': 'Complaint Details',
    'Feedback_Details': 'Feedback Details',
    'Chat': 'Chat',
    'LeaderBoard': 'LeaderBoard',
    'Matches': 'Matches',
    'Notifications': 'Notifications',
    'Invite_A_Friend': 'Invite a Friend',
    'Bank_Details': 'Bank Details',
    'Withdraw': 'Withdraw',
    'Verify_Email': 'Verify Email',
    'Verify_Mobile': 'Verify Mobile',
    'Cartola_Pix': 'Cartola Pix',
    'Success': 'Success',
    'Error': 'Error',
    'Diamond': 'Diamond',
    'Diamond_Disable': 'DiamondDisable',
    'Multi': 'Multi',
    'Multi_Disable': 'MultiDisable',
    'Copy': 'Copy',
    'Copy_Disable': 'CopyDisable',
    'Loyalty': 'Loyalty',
    'Loyalty_Disable': 'LoyaltyDisable',
    'Trophy': 'Trophy',
    'Player': 'Player',
    'Goto_Icon': 'Goto Icon',
    'Player_Image': 'Player Image',
    'Medal': 'Medal',
    'Dollar': 'Dollar',
    'Bat': 'Bat',
    'Ball': 'Ball',
    'Transparent_Logo': 'Transparent Logo',
    'Aahdar_Card_Photo': 'Aadhar Card Photo',
    'Contests': 'Contests',
    'Reply': 'Reply',
    'Play_Now': 'Play Now',
    'Promo_Applied': 'Applied...!',
    'Info': 'Info',
    'Enter_Team_Name': 'Enter Team Name',
    'Wallet_Details': 'Wallet Details',
    'Remove': 'Remove',
    'Available_Cash_Balance': 'Available Cash Balance',
    'Enter_CPF': 'Please enter your CPF Card number.',
    'CPF_Number': 'CPF Number',
    'CPF_Required': 'CPF is Required',
    'CPF_LengthValidation': 'Enter a valid 11 digit CPF',
    'Agree_to_conditions': 'I am agree to all the',
    'fullname_validation': 'Full name must be alpha-numeric.',
    'Total_Win': 'Total Win',
    'Team_name_alpha_numeric': 'Team name must be alpha-numeric',
    'Team_name_10_characters': 'Team name must be maximum of 10 characters',
    'PaymentCode': 'Payment Code & QR',
    'Copy_PIX_Code': 'Copy PIX Code',
    'QR_Code': 'QR Code',
    'Already_Paid': 'Already Paid? Update your balance!',
    'Deposit_success_msg': 'Deposit Successful',
    'Deposit_refund_msg': 'Your payment is refunded.',
    'Deposit_cancel_msg': 'Deposit failed',
    'Deposit_pending_msg': `It seems like you haven't paid yet!`,
    'Trophy_Message': 'Trophy is waiting for you. But before that we need you to login to make your experiances even better.',
    'No_Data_Found': 'No data found. Please check back after a while.',
    'Okay': 'Okay',
    'Deposited': ' have been deposited to your account successfully!',
    'Your_deposit': 'Your deposit of ',
    'Deposit_failed': ' has been failed. Please try again!',
    'Withdrawal_request': 'Withdrawal request sent!',
    'Withdraw_amount': 'Your request for the withdrawal of amount ',
    'Sent_success': ' has been sent successfully.',
    'Confirmation': 'Confirmation',
    'Cancel_Withdraw_first': 'Are you sure you want to cancel your withdrawal request of ',
    'Cancel_Withdraw_second': '? This can not be undone.',
    'Join_contest': 'Join Contest',
    'Create_contest': 'Create contest',
    'ConfirmPass_resend': 'Resend OTP',
    'Share': 'Share',
    'Does_not_support_share': `Your browser doesn't support the Web Share`,
    'Common_name_length': 'Full name must be 2 to 30 characters.',
    'Bankdetails_branchname': 'Branch name must be less than or equal to 35 characters.',
    'Bankdetails_acountname': 'Account name must be less than or equal to 35 characters.',
    'Bankdetails_acountnumber': 'Account number must be 9 to 18 digits.',
    'EditProfile_address': 'Address must be 5 to 50 characters.',
    'Complaint_title_err': 'Title must be less than or equal to 35 characters.',
    'Complaint_description_err': 'Description must be less than or equal to 250 characters.',
    'More_offer_details': 'Offer Details',
    'football_leagues': 'Football Leagues',
    'StartDate': 'Start Date',
    'EndDate': 'End Date',
    'Complain_Pending': 'Pending',
    'Complain_In_Progress': 'In-Progress',
    'Complain_Decline': 'Decline',
    'Complain_Resolve': 'Resolve',
    'No_tournament_found': 'No tournament found',
    'Winner': '',
    'Winners': '',
    'Football': 'Football',
    'Deposit': 'Deposit',
    'Player_select_alert_msg': 'You have already selected 11 players',
    'Sum_of_prizes': 'Sum of prizes',
    'Tournaments': 'Tournaments',
    'Teams': 'Teams',
    'Add_player': 'Add Player',
    'Search_player': 'Search Player',
    'General': 'General',
    'More_info': 'More Info',
    'First_Inning': 'First Inning',
    'Second_Inning': 'Secong Inning',
    'Inning': 'Inning',
    'Event': 'Event',
    'Good_luck': 'Good luck!',
    'Show_More': 'Show More',
    'Penalty_dispute': 'Penalty dispute',
    'Match_cancelled': 'Match cancelled',
    'Data_not_available': 'Data not available',
    'Position': 'Position',
    'Confirm': 'Confirm',
    'Players_not_found': 'Players not found',
    'Clear_Filters': 'Clear Filters'
  },
  'pt-BR': {
    // 'Static_Share_text': 'Ei! Eu te desafio a participar desse desafio. Vamos ver quem leva a coroa. Este é um concurso exclusivo apenas para convidados do Fansportiz para PCK vs BCC. Use abaixo o código do concurso ou link para participar. Código do concurso: 12564f',
    'Static_Share_text':'Use abaixo o código do torneio ou link para participar da competição',
    // 'Static_Title_Text': 'Confira minha time para o jogo SOU-L vs BAN-L Cricket. Faça seu próprio time e jogue comigo:',
    'Static_Title_Text' : 'Código do torneio: ',
    'Static_Facebook': 'Facebook',
    'Static_Twitter': 'Twitter',
    'Static_Telegram': 'Telegram',
    'Static_Share_ReferCode': 'Por favor use meu código de referência',
    'Static_In_Fansportiz': 'in Fansportiz',
    'Kyc_Title': "Verificação de Identidade",
    'Refer_a_friend': "Crie uma liga",
    'Extra_Yes': "Informação",
    'Match_Winning_Zone': "VOCÊ ESTÁ NA ZONA VENCEDORA",
    'Extra_No': "Não",
    'NotFound_404': "404",
    'NotFound_Page': "Página não encontrada!",
    'NotFound_GoHome': "Ir para página principal",
    'Cashback_On': "Cashback",
    'Bonus_On': "Bônus ativado",
    'Version': "Versão",
    'FairPlay_Not_available': "Fair Play não está disponível",
    'No_Filter': "Nenhum filtro está disponível",
    'No_Contest': "Não há torneios disponíveis",
    'No_Team': "Nenhum time está disponível",
    'Leaderboard': "Tabela de Líderes",
    'Extra_PrizeBreakup': "Premio",
    'Extra_Conditions': "condições",
    'Share_Code': "Compartilhe este código para indicar e ganhar bônus",
    'Contast_Min_Req': 'A taxa de inscrição não pode ser inferior a 5.',
    'Required_Email': 'É necessário um endereço de e-mail',
    'Common_ReachedLimit': 'Atingiu o limite para participar no Torneio',
    'Common_Required': 'Campo obrigatório',
    'Common_NumberOTP': 'Senha temporária deve ser um número',
    'Common_TotalCredits': 'Créditos totais',
    'Common_loginEmailPass': 'E-mail/Celular inválido',
    'Common_mobileError': 'O número do celular já existe.',
    'Common_emailError': 'O e-mail já existe.',
    'Common_usernameError': 'O nome de usuário já existe.',
    'Required_Mobile': 'O número de celular é necessário',
    'Common_length3': 'O nome deve ter no mínimo 3 caracteres.',
    'Common_length4': 'O comprimento deve ser 4',
    'Common_length5': 'O comprimento deve ser 5',
    'Common_Contest_length4': 'O nome do torneio deve ter no mínimo 4 caracteres',
    'Contest_without_special_Character': 'Caracteres especiais não são permitidos no nome do Torneio.',
    'Common_Contest_length16': 'O nome do torneio não pode ter mais de 16 caracteres',
    'Contest_Amount_Between': 'Escolha o valor vencedor entre 1 e 10000',
    'Contest_Amount_Between_2': 'Escolha o valor vencedor entre ',
    'LeagueDetails_NoOffer': 'Não há ofertas disponíveis',
    'Common_ConfirmPasswordNotFound': 'Senha não corresponde',
    'Common_ReqOld': 'A senha atual é necessária.',
    'Common_ReqNew': 'Senha requerida',
    'Common_Req_RePassword': 'Senha necessária',
    'Common_ReqConfirm': 'Confirme a senha é necessária',
    'Common_ReqContest_Size': 'O tamanho do torneio é necessário',
    'Common_ReqContest_Price': 'O prêmio do torneio é necessário',
    'Common_Req_Email_Mobile': 'E-mail / celular é necessário',
    'Common_Req_Mobile_No': 'Número de celular é necessário',
    'Common_Req_Email': 'O e-mail é necessário',
    'Common_Req_Name': 'Nome é necessário',
    'Common_Req_Username': 'O nome de usuário é necessário',
    'Common_ConfirmPassword': 'Digite novamente sua senha',
    'Common_NewPassword': 'Nova Senha',
    'Common_OldPassword': 'Senha Antiga',
    'User_Apply': 'Aplicar',
    'Note_Info': 'Nota',
    'Change_IN': '(Em',
    'Change_SEC': 'seg)',
    'Transaction_All': 'Tudo',
    'Transaction_Cash': 'Dinheiro',
    'Transaction_Bonus': 'Bônus',
    'Transaction_Cancel_WT_Req': 'Cancelar pedido de retirada',
    'Transaction_Pending_WT_Req': 'Você tem uma solicitação de retirada pendente de R$',
    'Transaction_Pending_Req_OF': 'Você tem uma solicitação de retirada pendente de ',
    'Transaction_Cancel_it_wish': 'Você pode cancelá-lo se desejar.',
    'User_WON_BY': 'ganhou por',
    'User_BOTH_PLAYER': 'Ambas os times têm os mesmos pontos',
    'User_VerifiedPend': 'Verificação pendente',
    'User_VerifiedSucc': 'Verificação realizada com sucesso',
    'User_promocodeForU': 'Códigos promocionais para você',
    'Have_Promocode': 'Você possui um código promocional?',
    'User_Promocode': 'Código Promocional',
    'User_Promocodes': 'Códigos promocionais',
    'User_AddMoney': 'Adicionar dinheiro',
    'User_DCN': 'Débito, Crédito & Net Banking',
    'User_AMZandCaseback': 'Deposite usando o Amazon Pay e receba uma surpresa de reembolso',
    'User_validPAN': 'Please enter valid PAN Card Number',
    'User_PANCardNumber': 'Please enter PAN Card Number',
    'User_PanImage': 'Please add your Pan Card image',
    'User_AadharImage': 'Please add your Aadhaar Card image',
    'User_ADDPanImage': 'Add PAN Card Photo',
    'Complaint_ADDImage': 'Adicionar imagem',
    'User_PanNumber': 'PAN Card Number',
    'User_PanName': 'PAN Card Name',
    'User_AadharNumber': 'Aadhaar Card Number',
    'User_AadharCardFPic': 'Add Aadhaar Card Photo (Front)',
    'User_AadharCardBPic': 'Add Aadhaar Card Photo (Back)',
    'User_validAADHAR': 'Please enter valid Aadhaar Card Number',
    'User_AADHARCardNumber': 'Please enter Aadhaar Card Number',
    'Required_PAN_Card_Number': 'Pan Card Number is required',
    'Required_AADHARCardNumber': 'Aadhaar Card Number is required',
    'Required_AADHARCard_Len': 'Aadhaar Card Number length must be 12',
    'Required_AADHARCard_Number': 'Digite apenas números',
    'Required_AADHARCardImage': 'Aadhaar Card Image is required',
    'User_PAN': 'Pan Card',
    'Ball_Faced': 'Ball Faced',
    'Overs_Bowled': 'Overs Bowled',
    'User_AADHAAR': 'Aadhaar Card',
    'User_withdrawal': 'Saque',
    'User_KycVerification': 'Verificação de Identidade',
    'User_commission_Private_Contest': 'Comissão de Torneio Privado',
    'User_Maximum': 'Máximo',
    'User_Player_From_team': 'jogadores de um time',
    'User_profileInfo': 'Editar Perfil',
    'User_leaderBoard': 'Tabela de Líderes',
    'User_transaction': 'Transações',
    'User_changePassword': 'Mudar Senha',
    'User_logout': 'Sair',
    'profileInfo_userName': 'Nome de usuário',
    'profileInfo_Fullname': 'Nome completo',
    'profileInfo_Email': 'E-mail',
    'profileInfo_MobileNo': 'Número de celular',
    'profileInfo_Gender': 'Gênero',
    'profileInfo_Male': 'Masculino',
    'profileInfo_Female': 'Feminino',
    'profileInfo_Other': 'Outro',
    'Verify_Email': 'Verifique seu e-mail',
    'Verify_Mobile': 'Verifique seu celular',
    'Common_and': 'e',
    'League_single_entry': 'Entrada única',
    'League_Multi_Entry': 'Entrada múltipla',
    'League_multiple_entry': 'Múlti Entradas',
    'League_Single_Winner': 'Ganhador único',
    'League_Multi_Winner': 'Ganhadores múltiplos',
    'League_Confirmed': 'Confirmado',
    'League_Upto': 'Até',
    'League_Points': 'Pontos',
    'League_Entries': 'Entradas',
    'League_Entry': 'Entrada',
    'Match_IN_Review': '(Em revisão)',
    'Common_sort': 'Ordenar',
    'League_Clear': 'Limpar',
    'League_Close': 'Fechar',
    'League_Compare': 'Comparar',
    'League_Notes': 'Os dados das escalações podem não ser precisos. Consulte, por favor, outras fontes também para confirmar.',
    'League_Orange_Text': 'Os jogadores estão no seu time',
    'League_CompareTeam': 'Comparar time',
    'League_Team': 'Time',
    'League_CONTEST': 'Torneio',
    'Deposit_CASHFREE': 'CashFree',
    'Deposit_AMAZON_PAY': 'Amazon Pay',
    'League_CommonPlayers': 'Jogadores Comuns',
    'League_bothCVCSame': 'Ambas os times C e VC têm os mesmos pontos',
    'League_myCVCLeadBy': 'Seus times C & VC lideradas por',
    'League_opponentCVCLeadBy': 'Seus oponentes C & VC lideram por',
    'League_bothPlayersLeadBy': 'Ambos os jogadores do time têm os mesmos pontos',
    'League_myPlayersLeadBy': 'Os jogadores do seu time lideram por',
    'League_opponentPlayersLeadBy': 'Os jogadores do seu oponente lideram por',
    'League_selectedTeams': 'Times Selecionadas',
    'Notification_NotFound': 'Notificação não encontrada',
    'Complaints_NotFound': 'Reclamações não encontradas',
    'Complaints_Complaints': 'Reclamações',
    'Complaints_ADD_Complaints': 'Adicionar reclamação/comentário',
    'Complaints_Feedback': 'Sugestaõs',
    'Contest_cSizeGreater2': 'O tamanho do torneio deve ser maior que 2',
    'Contest_Contest_Size_between': 'Escolha o tamanho do torneio entre 2 e 500',
    'Contest_Size_between': 'Escolha o tamanho do torneio entre',
    'Contest_NumberGreater2': 'número deve ser maior que 2',
    'Contest_shouldBeNumber': 'Deve ser um número',
    'Team_player11': 'Os jogadores devem ser onze.',
    'Common_ReqWk': 'Wicket KeePer Should be required',
    'Common_ReqAll': 'All Rounder Should be required',
    'Common_ReqBats': 'Batsman Should be required',
    'Common_ReqBall': 'Bowler Should be required',
    'Common_MinBats': 'Batsman Should be min',
    'Common_MinBowl': 'Bowler Should be min',
    'Common_Minwk': 'Wicket KeePer Should be min',
    'Common_MinAllR': 'All Rounder Should be min',
    'Common_perc': '%',
    'Common_percBy': '%C',
    'Common_perVcBy': '%VC',
    'Team_C': 'C',
    'Team_2x': '2x',
    'Team_1_5x': '1.5x',
    'Team_eleven': '11',
    'Team_VC': 'VC',
    'Team_9CR': '9 Cr',
    'Team_0CR': '0 Cr',
    'Team_0Pts': '0 Pts',
    'Team_0': '0',
    'Team_No_Linesup': 'Os jogadores não são anunciados nas escalações',
    'Team_Pts': 'Pts',
    'Team_Cr': 'Cr',
    'Team_Won': 'Ganhou ',
    'Team_You_Won': 'Você ganhou R$',
    'Team_Rupee': 'R$',
    'PROFILE_AVL_BALANCE': 'Saldo disponível',
    'PROFILE_DEP_BALANCE': 'Saldo Depositado',
    'PROFILE_WIN_BALANCE': 'Ganhos',
    'PROFILE_CASH_BONUS': 'Bônus em dinheiro',
    'PROFILE_ADD_CASH': 'Adicionar dinheiro',
    'PROFILE_TOTAL_BALANCE': 'Saldo Total',
    'PROFILE_BONUS_BALANCE': 'Bônus',
    'Team_batsman': 'BATSMAN',
    'Team_wk': 'WICKET KEEPER',
    'Team_bowler': 'BOWLER',
    'Team_allRounder': 'ALL ROUNDER',
    'Team_CreateTeam': 'Criar novo time',
    'Team_CreatePrivateLeague': 'Criar Torneio Privado',
    'Common_Total': 'Total',
    'Common_TotalPoints': 'Total de Pontos',
    'Common_usernameShouldBeSpace': 'O nome de usuário deve ser alfanumérico.',
    'Valid_Pincode': 'Por favor, insira um código PIN válido.',
    'Common_username_max_15Char': 'O nome de usuário deve ter no máximo 16 caracteres',
    'Common_specialCharacter': 'Nome de usuário não pode conter caracteres especiais.',
    'OfferDetails_amazonPay': 'Oferta Amazon Pay',
    'withDraw_ReqBankName': 'O nome do banco é necessário',
    'withDraw_ReqBankAccName': 'O nome da conta bancária é necessário',
    'withDraw_ReqBankBranchName': 'O nome da agência bancária é necessário',
    'withDraw_AccNotValid': 'O número da conta não é válido',
    'withDraw_IFSCNotValid': 'O código IBAN não é válido.',
    'withDraw_ReqAccNo': 'O número da conta é necessário',
    'withDraw_AC_Should_Number': 'O número da conta deve ser o número',
    'withDraw_ReqIFSCNo': 'O código IBAN é necessário',
    'withDraw_amountWithdraw': 'Valor de saque.',
    'withDraw_withdrawalAmount': 'Método de Retirada',
    'withDraw_WithdrawalBank': 'Saque Bancário',
    'Add_bank_details': 'Adicionar dados bancários',
    'withDraw_viewBankDetails': 'Ver dados bancários',
    'withDraw_bankName': 'Nome do banco',
    'withDraw_bankBranchName': 'Nome da agência bancária',
    'withDraw_bankAccName': 'Nome do proprietário da conta bancária',
    'withDraw_AccNo': 'Número da conta',
    'withDraw_IfscCode': 'Código IBAN',
    'withDraw_Save': 'Salvar',
    'withDraw_1500': '1500',
    'withDraw_2500': '2500',
    'withDraw_5000': '5000',
    'deposit_25': '25',
    'Team_AllTeam': 'Todas os times',
    'Team_AllTime': 'Sempre',
    'League_TeamCompare': 'Comparação de time',
    'Deposit_AmountBalance': 'Insira o valor que deseja adicionar ao seu saldo.',
    'ShareContest_ccc': 'Copiado com sucesso',
    'ShareContest_notCopyText': 'Não foi possível copiar o texto: ',
    'Footer_Home': 'Início',
    'Footer_Away': 'Fora',
    'League_10': '10',
    'League_action': 'ação',
    'League_Off': 'Desligado',
    'League_Applied': 'Aplicado!',
    'No_Transaction': 'Nenhuma transação está disponível',
    'Footer_Matches': 'Minhas',
    'Footer_Profile': 'Perfil',
    'Footer_More': 'Mais',
    'More_Feedback': 'Reclamações e Sugestões',
    'Complaint_Type': 'Tipo',
    'Complaint_Title': 'Título',
    'Complaint_Description': 'Descrição',
    'Home_Cricket': 'Críquete',
    'Home_Football': 'Futebol',
    'Home_Kabaddi': 'Kabaddi',
    'Home_No_Match': 'Nenhuma partida encontrada!',
    'Home_Cricket_Msg': 'Nenhuma partida de Críquete disponível',
    'Home_Football_Msg': 'Nenhuma partida de Futebol disponível',
    'Home_Kabaddi_Msg': 'Nenhuma partida de Kabaddi disponível',
    'Matches_Cricket_Live': 'Não há partidas de Críquete Ao Vivo disponíveis',
    'Matches_Football_Live': 'Não há partidas de Futebol Ao Vivo disponíveis',
    'Matches_Kabaddi_Live': 'Não há partidas de Kabaddi Ao vivo disponíveis',
    'Matches_Cricket_Completed': 'Não há partidas de Críquete concluídas disponíveis',
    'Matches_Football_Completed': 'Não há partidas de Futebol concluídas disponíveis',
    'Matches_Kabaddi_Completed': 'Nenhuma partida de Kabaddi concluída disponível',
    'Matches_Upcoming': 'Em Breve',
    'Matches_Live': 'Ao Vivo',
    'Matches_Watch_Live': 'Assista Ao Vivo',
    'Matches_Watch_Now': 'Assistir Agora',
    'Matches_Completed': 'Concluído',
    'More_PSystem': 'Sistema de Pontos',
    'More_HTP': 'Como jogar ?',
    'More_offers': 'Ofertas',
    'More_IF': 'Convidar amigo',
    'More_AboutUs': 'Sobre nós',
    'More_ContactUs': 'Contate-nos',
    'More_T&C': 'Termos e Condições',
    'More_Policy': 'Política',
    'More_pPolicy': 'Privacidade',
    'More_fpPolicy': 'Jogo Responsável',
    'More_discPolicy': 'Isenção de responsabilidade',
    'More_legaPolicy': 'Legalidade',
    'More_withdrawPolicy': 'Como retirar ?',
    'More_F&Q': 'PERGUNTAS FREQUENTES',
    'More_AppStore': 'Loja de aplicativos',
    'More_HTD': 'Como depositar ?',
    'More_HTR': 'Como registrar ?',
    'More_ChatSupport': 'Suporte Ao Vivo',
    'Welcome_Invite': 'Convidado por um amigo?',
    'Welcome_EnterCode': 'Insira o Código',
    'Welcome_AlUser': 'Já é usuário?',
    'Welcome_Login': 'Entrar',
    'Welcome_Khelo': '#khelo',
    'Welcome_NewUser': 'Novo usuário?',
    'Welcome_SignUp': 'Registrar-se',
    'Team_Compare': 'Comparar time',
    'NO_PointBreakup_Avail': 'Nenhum ponto de separação disponível',
    'Welcome_Hash': '#',
    'Congrats_you_win': 'Parabéns você ganhou em',
    'Win_contest': 'torneio',
    'ProfileInfo_State': 'Selecione Estado',
    'ProfileInfo_City': 'Selecione a cidade',
    'ProfileInfo_Country': 'Selecione o pais',
    'Profile_Country': 'País',
    'Profile_State': 'Estado',
    'Profile_City': 'Cidade',
    'Profile_PinCode': 'Código PIN',
    'Profile_Address': 'Endereço',
    'ProfileInfo_DOB': 'Data de nascimento',
    'Welcome_Endless': 'sem fim',
    'Welcome_LetsP': `Vamos jogar`,
    'Login_Otp': 'Senha Temporária',
    'Login_Email': 'E-mail',
    'Login_Password': 'Senha',
    'Login_ForgetPassword': 'Esqueceu sua senha?',
    'Login_ConfirmPassword': 'Digite novamente sua senha',
    'Login_login': 'Entrar',
    'Login_withLogin': 'OU ENTRAR COM',
    'Login_conGoogle': 'continuar com o Google',
    'Login_conFacebook': 'continuar com o Facebook',
    'Login_noAcc': 'Não tem uma conta?',
    'Sign_up': 'Registro',
    'Login_signUp': 'Registrar',
    'SignUp_Confirm_18':  'Por favor confirme que sua idade é maior que 18 anos',
    'SignUp_Age_18': 'A idade deve ser maior que 18.',
    "SingUp_Confirm_18_above" : 'Por favor confirme que sua idade é maior que 18 anos',
    'SignUp_Confirm_State': 'Eu não sou um residente de',
    'Login_Agreed': 'Eu li e aceito os ',
    'Login_VerifiedPassword': 'Digite novamente sua senha',
    'Login_ResendOTP': 'Reenviar senha temporária',
    'Login_VerifyOtp': 'Verificar senha temporária',
    "Login_Agreed_Term_Condition" : 'Por favor confirme que você está de acordo com os termos e condiçôes',
    'SignUp_Agree': 'Ao continuar, você concorda com nossos',
    'Signup_Terms': 'Termos & Condições.',
    'SignUp_And': 'e',
    'SignUp_SignUp': 'Registrar-se',
    'SignUp_PrivPolicy': 'Privacidade',
    'SignUp_Username': 'Nome de usuário',
    'SignUp_Name': 'Nome',
    'SignUp_MobNo': 'Número de celular',
    'SignUp_VerifyOtp': 'Verificar senha temporária',
    'SignUp_GetOtp': 'Obter senha temporária',
    'Home_Change': 'Mudar',
    'Transaction_Details': 'Detalhes da transação',
    'Transaction_ID': 'ID da transação',
    'Transaction_Date': 'Data da transação',
    'SignUp_Usernames': 'E-mail',
    'SignUp_Ref': 'Código de referência (opcional)',    
    'ConfirmPass_notmatchCode': 'Eu não recebi um código',
    'ConfirmPass_getStarted': 'Entrar',
    'Submit': 'Atualizar Senha',
    'Forget_viaEmail': 'Via email',
    'Forget_viaSms': 'Por SMS',
    'Preference_SMS': 'SMS',
    'Preference_Sound': 'Som',
    'Preference_Vibration': 'Vibração',    
    'Forget_rMobile': `Por favor, digite seu número de celular registrado. Enviaremos uma senha temporária para redefinir sua senha.`,
    'Verification_verify': 'Verificar',
    'Verification_getCode': 'Eu não recebi um código',
    'Contast_NoLeeague1': 'Os torneios ainda não estão disponíveis!',
    'Contast_NoLeeague': 'Você ainda não está inscrito em nenhum torneio. Junte-se a nós para ganhar prêmios!',
    'No_Data_Found': 'Nenhum dado encontrado',
    'Contast_NoRecord': 'Nenhum Registro Encontrado',
    'Contast_LeagueJoined': 'Ligas aderidas',
    'Contast_NoPlayer': 'Nenhum jogador entrou ainda',
    'Contast_JoinLeeague': 'Veja os Torneios',
    'League_join': 'Participar',
    'League_Payment': 'Pagamento',
    'League_Promocode': 'Selecione o código promocional',
    'League_Total_Entry': 'Entrada Total',
    'League_To_Pay': 'Pagar',
    'Blank_Promocode_List': 'Nenhum código promocional disponível para este torneio.',
    'League_Discount': 'Desconto',
    'League_In_Wallet': 'Na carteira',
    'From_bonus': 'De bônus',
    'League_Apply_Promocode': 'Aplicar código promocional',
    'League_Teams_Selected': 'Times Selecionados',
    'Switch': 'Mudar',
    'League_noSwitchTeam': 'Nenhume time para troca está disponível',
    'League_joined': 'Entrou',
    'League_contest_type': 'Tipo de torneio',
    'League_league_type': 'Tipo de Liga',
    'League_clear_all': 'Limpar tudo',
    'League_prize_pool': 'Conjunto de prêmios',
    'League_no_of_teams': 'Número de Times',
    'League_contest': 'Torneios',
    'League_myContest': 'Meus Torneios',
    'League_myTeams': 'Meus Times',
    'League_matchTips': 'Dicas de jogo',
    'League_powerByTips': 'Dicas de jogo - alimentado por crictracker',
    'League_filterBy': 'Filtrar por',
    'League_announced_Lineups': 'Alinhamentos anunciados',
    'League_sortBy': 'Ordenar por',
    'League_popularity': 'Popularidade',
    'League_spots': 'Pontos',
    'League_entry': 'Entrada',
    'League_prizePoll': 'Conjunto de prêmios',
    'League_applyNow': 'Aplique agora',
    'League_Continue': 'Continuar',
    'League_myContests': 'Meus torneios',
    'League_playerStats': 'Jogadores',
    'Scorecard': 'Tabela de Resultados',
    'League_prizeBreakup': 'Divisão do Prêmio',
    'League_leaderboard': 'Tabela',
    'League_Rank': 'Classificação',
    'League_Leaders': 'Líderes',
    'League_LoyaltyPoints': 'Pts de fidelidade',
    'League_Amazon_Gifts': 'T&C do cartão-presente pago da Amazon',
    'League_Wins': 'Vitórias',
    'League_noContest': 'Não há Torneios disponíveis.',
    'League_notification': 'Notificação não encontrada',
    'Team_SaveTeam': 'Salvar time',
    'Team_SelectTeam': 'Selecionar time',
    'Team_SelectAll': 'Selecionar tudo',
    'Team_Title1': 'Você pode selecionar max ',
    'Team_Title2': 'times para este torneio.',
    'Team_points': 'PONTOS',
    'Team_WK': 'WK',
    'Team_BAT': 'BAT',
    'Team_Batsmen': 'Batsmen',
    'Team_AllRounder': 'All Rounder',
    'Team_AR': 'AR',
    'Team_BOWL': 'BOWL',
    'Teams_noTeam': 'Nenhum time disponível',
    'Teams_noTeamAdded': 'Nenhum time foi criado ainda',
    'Check_back_after_while': 'Nenhum dado encontrado. Por favor, tente mais tarde.',
    'Teams_noPrizeBreakup': 'Nenhuma divisão de prêmio encontrada',
    'Player_selectedBy': 'Escolhido por',
    'Player_selBy': 'sel Por',
    'Player_points': 'Pontos',
    'Player_point': 'Ponto',
    'Player_players': 'Jogadores',
    'Player_filter': 'Filtro',
    'Player_credits': 'Créditos',
    'Player_scoreCard': 'tabela de resultados',
    'Player_c': '%C',
    'Player_vc': '%VC',
    'Player_event': 'Eventos',
    'Player_action': 'Ações',
    'Player_matchWise': 'Estatísticas do Matchwise Fantasy',
    'League_prizePool': 'Prêmios',
    'League_enterCode': 'Digite o código',
    'League_createContest': 'Crie um torneio',
    'League_1stPrize': '1º Prémio',
    'League_Prize': 'Prêmio',
    'League_winners': 'Vencedores',
    'Teams': 'Times',
    'League_bonus': 'Bônus',
    'Matches_LineupsOut': 'Alinhamento fora',
    'Contest_createeam': 'Criar um novo time',
    'Contest_contestName': 'Nome do torneio',
    'Contest_contestSize': 'Número de participantes',
    'Contest_contestPrize': 'Prêmio do torneio',
    'Contest_allowTeam': 'Permitir vários times?',
    'Contest_allowPool': 'Permitir que o torneio comece com o número mínimo de participantes?',
    'Contest_entryFee': 'Taxa de entrada',
    'Contest_entryFee2': 'Valor da inscrição por time',
    'Contest_joinContest': 'Participe do torneio',
    'Contest_inviteCode': 'Código do torneio',
    'Contest_inviteCodetoJoinContest': 'Digite o código de convite para participar do torneio.',
    'Contest_createContest': 'Criar torneio privado',
    'Contest_joinAndcreateContest': 'Criar torneio e participar',
    'Contest_bonus': 'Você receberá um bônus de criador de ',
    'Contest_NoOfWinners': 'Selecione o número total de vencedores',
    'Contest_poolText': 'Por padrão, todos os participantes são necessários para que a torneio comece',
    'Contest_FeeText': 'A taxa de inscrição é calculada com base no número de participantes e no prêmio do torneio',
    'Team_chooseCaptain': 'Selecione Capitão e Vice-Capitão',
    'Team_12thman': 'Capitão recebe 2x e Vice Capitão recebe 1,5x Pontos',
    'Team_Select': 'Selecione',
    'Team_creditLeft': 'Créditos restantes',
    'Team_players': 'Jogadores',
    'other_Next': 'Próximo',
    'other_Prev': 'Anterior',
    'other_shareContest': 'Compartilhe o código do torneio',
    'other_copy': 'COPIAR',
    'other_shareWhatsapp': 'compartilhe no Whatsapp',
    'other_sharingOption': 'outras opções de compartilhamento',
    'pointSystem_ODI': 'ODI',
    'pointSystem_T10': 'T-10',
    'pointSystem_T20': 'T-20',
    'pointSystem_100BALLS': '100 BALLS',
    'pointSystem_TEST': 'TEST',
    'pointSystem_attributeName': 'Nome do Atributo',
    'pointSystem_minimum': 'Mínimo',
    'pointSystem_fromTo': 'De para',
    'pointSystem_From': 'A partir de',
    'pointSystem_To': 'Para',
    'pointSystem_This_Week': 'Essa semana',
    'pointSystem_This_Month': 'Este mês',
    'pointSystem_This_Year': 'Este ano',
    'pointSystem_Other_Options': 'Outras opções',
    'Footer_Bonus': 'Bônus',
    'Footer_Wins': 'Vitórias',
    'Profile_Preference': 'Preferências',
    'Match_NotStarted': 'A partida não foi iniciada',
    'Match_Download': 'Baixar',
    'Match_DownloadTeams': 'Baixar equipes',
    'Match_TeamLocked': 'Times bloqueados! Baixe e acompanhe o seu torneio.',
    'Match_Cancel': 'Cancelar',
    'Match_VS': 'VS',
    'WON': 'Ganhou R$',
    'ONLY_WON': 'GANHOU',
    'WON_Multiple_Prize': 'Ganhou vários prêmios >',
    'WON_Multiple_Prize1': 'Ganhou vários prêmios ',
    'ONLY_WON_Gadget': 'Ganhou Gadgets',
    'YOU_ONLY_WON_Gadget': 'Você Ganhou Gadgets',
    'WON_Multiple_Prizes': 'Ganhou vários prêmios',
    'Team': 'Time',
    'Contest': 'Torneio',
    'Apply_filter':'Aplicar filtro',
    'league_Wins':'Vitórias',
    'OFFER_TITLE': 'Detalhes da oferta',
    'League_Select_Filter': 'Selecione Filtros',
    'Team_BATS': 'BATS',
    'Team_ALLR': 'ALLR',
    'Team_BWL': 'BWL',
    'YOU_WON_Multiple_Prizes': 'Você ganhou vários prêmios',
    'Common_Should_Be_MIN': 'Você deve selecionar pelo menos',
    'Common_Should_Be_REQ': 'Você deve selecionar pelo menos 1',
    'JOIN_ERR': 'O código deve ser maior que 4',
    'No_PointBreakup':'Nenhum PointBreakup Disponível',
    'Available_balance':'Saldo Disponivel',
    'Deposited_balance':'Saldo de Depósito',
    'Win_balance':'Saldo de vitória',
    'LeaderBoard_Series': 'Temporada',
    'LeaderBoard_month': 'Mês Passado',
    'LeaderBoard_Serieswise': 'Temporada',
    'LeaderBoard_allTeams':'Todas os times',
    'Select_Series':'Selecionar Série',
    'No_Team_Available':'Nenhum time disponível para mudar',
    'No_Score_Point_Available':'Não há pontos de pontuação disponíveis',
    'No_Sports_Available':'Nenhum esporte está disponível',
    'Withdraw_addBankDetails':'Adicione seus dados bancários',
    'Withdraw_fill_BankDetails':'Por favor, preencha seus dados bancários primeiro.',
    'Withdraw_greaterThan10':'Montante deve ser maior que 10',
    'Withdraw_not_greaterThan_WinBalance':'O montante não pode ser maior que o saldo de ganhos',
    'Deposit_min_25':'O montante mínimo para depósito é ',
    'Withdraw_Between_To':'Retirar Valor entre',
    'Deposit_Between_To':'Valor do Depósito entre',
    'Deposit_between':'Entre',
    'Game_Cricket':'CRICKET',
    'Game_Football':'FOOTBALL',
    'Match_Player_Not_Exist':'Match Player is not exist.',
    'Match_Player_Role_Not_Exist':'O papel do jogador não existe',
    'No_Team_Available_To_Join':'Nenhum time disponível para participar',
    'No_Conditions_Here': 'Nenhuma condição aqui',
    'Enter_New_Email': 'Digite novo e-mail',
    'Enter_New_Mobile': 'Entre no novo celular',
    'ScoreCard_None': 'ScoreCard não está disponível.',
    'ScoreCard_Bwl': 'Bowler',
    'ScoreCard_Bat': 'Batsman',
    'ScoreCard_Runs': 'R',
    'ScoreCard_Balls': 'B',
    'ScoreCard_4s': '4s',
    'ScoreCard_6s': '6s',
    'ScoreCard_SR': 'SR',
    'ScoreCard_Ov': 'Ov',
    'ScoreCard_M': 'M',
    'ScoreCard_R': 'R',
    'ScoreCard_W': 'W',
    'ScoreCard_NB': 'NB',
    'ScoreCard_WD': 'WD',
    'ScoreCard_Eco': 'Eco',
    'ScoreCard_FOW': 'FALL OF WICKETS',
    'ScoreCard_RPO': 'Runs Per over',
    'ScoreCard_Total': 'Total',
    'ScoreCard_Extra': 'Extra',
    'Restricted_state': 'O estado selecionado é restrito',
    'Match_Disable_Msg': 'O torneio para esta partida será aberto em breve. Fique ligado...!',
    'No_Future_Date': 'A data não deve ser uma data futura',
    'Wrong_birth_year': 'Digite a data de nascimento correta!',
    'Only_alphabets_allowed': 'Apenas caracteres alfanuméricos são permitidos!',
    'Match_Live_Msg': 'Acabou o tempo!!',
    'Playing': 'Jogando',
    'My_Refer_Code': 'Meu código de referência',
    'Pay': 'Pague',
    'Enter_the_amount': 'Digite o valor : ',
    'Team_name_alpha_numeric': 'O nome do time deve ser alfanumérico',
    'Team_name_10_characters': 'O nome do time deve ter no máximo 10 caracteres',
    'Profile_deposite_info_msg': `O dinheiro que você depositou será mostrado aqui. Você pode usá-lo para participar da competição, mas não pode sacar.`,
    'Profile_balance_info_msg': 'Dinheiro que você pode retirar ou reutilizar para participar de qualquer tornieo.',
    'Valid_Credentials': 'Insira sua credencial correta.',
    'Winning_Breakup': 'Divisão de prêmios',
    'Verification': 'Verificação',
    'Complaint_Details': 'Detalhes da reclamação',
    'Feedback_Details': 'Detalhes do feedback',
    'Chat': 'Chat',
    'LeaderBoard': 'Tabela',
    'Matches': 'Partidas',
    'Notifications': 'Notificações',
    'Invite_A_Friend': 'Convidar um amigo',
    'Bank_Details': 'Detalhes bancários',
    'Withdraw': 'Saquer',
    'Verify_Email': 'Enviar código',
    'Verify_Mobile': 'Verificar número de telefone',
    'Fansportiz': 'Fansportiz',
    'Success': 'Sucesso',
    'Error': 'Erro',
    'Diamond': 'Diamante',
    'Diamond_Disable': 'Desativar diamante',
    'Multi': 'Multi',
    'Multi_Disable': 'Desativar Multi',
    'Copy': 'Copiar',
    'Copy_Disable': 'Desativar cópia',
    'Loyalty': 'Lealdade',
    'Loyalty_Disable': 'Lealdade Desativar',
    'Trophy': 'Troféu',
    'Player': 'Jogador',
    'Goto_Icon': 'Ícone Ir',
    'Player_Image': 'Imagem do jogador',
    'Medal': 'Medalha',
    'Dollar': 'Dólar',
    'Bat': 'Taco',
    'Ball': 'Bola',
    'Transparent_Logo': 'Logo Transparente',
    'Aahdar_Card_Photo': 'Foto do Cartão Aadhar',
    'Contests': 'Torneios',
    'Reply': 'Responder',
    'Play_Now': 'Jogue agora',
    'Promo_Applied': 'Aplicado...!',
    'Info': 'Informações',
    'Enter_Team_Name': 'Insira o Nome de Time',
    'Wallet_Details': 'Detalhes da carteira',
    'Remove': 'Remover',
    'Available_Cash_Balance': 'Saldo disponível',
    'Trophy_Message': 'Troféu está esperando por você. Mas antes disso, precisamos que você faça o login para tornar sua experiência ainda melhor.',
    'notification_icon': 'Notificação',
    'New_User': 'Novo usuário?',
    'Reset_Password': 'Redefinir senha',
    'Will_received_OTP': 'Você receberá código de confirmação em ',
    'Forgot_Password': 'Esqueceu a senha',
    'fullname_validation': 'O nome completo deve ser alfanumérico.',
    'Profile_pic_validation': 'Selecione um arquivo com menos de 5 MB',
    'Will_received_OTP_on_email': 'Você receberá um código de confirmação neste e-mail',
    'Okay': 'Ok',
    'Withdrawal_request': 'Solicitação de saque enviada!',
    'Withdraw_amount': 'Seu pedido de saque ',
    'Sent_success': ' foi enviado com sucesso.',
    'Confirmation': 'Confirmação',
    'Cancel_Withdraw_first': 'Tem certeza de que deseja cancelar sua solicitação de saque de ',
    'Cancel_Withdraw_second': '? Isto não pode ser desfeito.',
    'Fansportiz': 'Fansportiz',
    'Join_contest': 'Participe do torneio',
    'Create_contest': 'Criar um torneio',
    'ConfirmPass_resend': 'Reenviar código de confirmação',
    'Share': 'Compartilhar',
    'Does_not_support_share': `Seu navegador não suporta o Web Share`,
    'Common_name_length': 'O nome completo deve ter de 2 a 30 caracteres.',
    'Bankdetails_branchname': 'O nome da agência deve ter menos ou igual a 35 caracteres.',
    'Bankdetails_acountname': 'O nome da conta deve ter menos ou igual a 35 caracteres.',
    'Bankdetails_acountnumber': 'O número da conta deve ter de 9 a 18 dígitos.',
    'EditProfile_address': 'O endereço deve ter de 5 a 50 caracteres.',
    'Complaint_title_err': 'O título deve ser menor ou igual a 35 caracteres.',
    'Complaint_description_err': 'A descrição deve ter menos ou igual a 250 caracteres.',
    'More_offer_details': 'Detalhes da oferta',
    'Enter_CPF': 'Informe o número do seu CPF.',
    'CPF_Number': 'Número do CPF',
    'Forget_Submit': 'Atualizar Senha',
    'OTP_Msg': 'Você receberá um senha temporária em',
    'Teams_noData': 'Nenhum dado encontrado. Por favor, verifique novamente depois de um tempo.',
    'Total_Win': 'Ganhos',
    'Static_In_Cartola_Pix': 'no Pixfantasy',
    'Common_Submit': 'Atualizar Senha',
    'Contast_NoSeries': 'Nenhum dado encontrado',
    'User_proceed': 'Continuar',
    'other_Continue': 'Continuar',
    'League_multi_entry': 'Várias equipes',
    'League_join_create_contest': 'Criar concurso ',
    'Verification_4DigitCode': 'Digite o código de confirmação para verificar sua conta. Enviamos um código no seu telefone ',
    'Player_NoMatchList': 'Ainda não há estatísticas disponíveis para este jogador! você pode ver as estatísticas deste jogador na próxima partida do time do tour',
    'Forget_rEmail': `Por favor, digite seu endereço de e-mail cadastrado. Enviaremos um código de confirmação no seu telefone para redefinir sua senha.`,
    'ConfirmPass_digitOtp': 'Insira o código de confirmação para verificar a conta. Enviamos um código para você ', 
    'SignUp_Confirm_State1': 'Telangana, Assam, Odisha, Sikkim, Nagaland, Andhra Pradesh, Arunachal Pradesh, Karnataka e ',
    'SignUp_Confirm_State2': 'Meghalaya',
    'More_spotlPolicy': 'O esporte de fantasia é legal na Índia ?',
    'More_12thmanPolicy': 'Característica do 12º homem',
    'withDraw_Data1': 'Taxa - ₹ 10, 24-48 horas de trabalho',
    'withDraw_Data2': 'Taxa de 3% Paytm, processo 24 horas',
    'withDraw_Data3': 'Taxa de 3% Paytm (uma vez por semana)',
    'Proceed': 'procedimento',
    'withDraw_withdrawalPaytm': 'Saque Paytm',
    'withDraw_withdrawalInstantPaytm': 'Saque Instantâneo do Paytm',
    'Match_IPL': 'Concurso Premier Indiano',
    'Team_ShareTeam': 'Compartilhar time',
    'Team_ScoreData': 'Ainda para bater',
    'League_Guaranteed': 'Garantido',
    'Add': 'Adicionar',
    'Enter_Promocode': 'Insira o código promocional',
    'User_Rejected': 'Rejeição de verificação',
    'Common_PassLength': 'A senha deve ter de 8 a 15 caracteres com pelo menos 1 caractere minúsculo e 1 maiúsculo, 1 número e 1 caractere especial.',
    'Common_UserName_length5': 'O nome de usuário deve ter de 3 a 15 caracteres e ser alfanumérico.',
    'Common_properMobile': 'Por favor, insira um número de celular válido.',
    'Common_RequiredEmail': 'Por favor insira o endereço de e-mail válido.',
    'Common_RequiredMobile': 'Por favor, insira um número de celular válido.',
    'Otp_Length_Number': 'Código de confirmação deve ter de 4 a 6 dígitos.',
    'Common_NotMatchPass': 'A senha e a senha de confirmação devem ser iguais.',
    'Common_MobileError': 'Por favor, insira um número de celular válido.',
    'Common_EmailError': 'Por favor insira o endereço de e-mail válido.',
    'Continue': 'Continuar',
    'Leagues': 'Ligas',
    'StartDate': 'Data inicial',
    'EndDate': 'Data final',
    'Complain_Pending': 'Pendente',
    'Complain_In_Progress': 'Em andamento',
    'Complain_Decline': 'Declínio',
    'Complain_Resolve': 'Resolver',
    'League_switch': 'Alternar',
    'League_left': 'disponível',
    'CPF_Required': 'CPF Obrigatório',
    'No_tournament_found': 'Nenhum torneio encontrado',
    'PaymentCode': 'Código de pagamento e QR',
    'Copy_PIX_Code': 'Copiar Código PIX',
    'QR_Code': 'Código QR',
    'Already_Paid': 'Já pagou? Atualize seu saldo!',
    'Deposit_pending_msg': `Parece que você ainda não pagou!`,
    'Winner': 'Vencedor',
    'Winners': 'Vencedores',
    'futebol_leagues': 'Ligas de Futebol',
    'football_leagues': 'Ligas de Futebol',
    'Contest_Ridirect_Butto': 'Entendi',
    'Transaction_Cash_League_Details': 'em dinheiro',
    'Deposit': 'Depositar',
    'Player_select_alert_msg': 'Você já selecionou 11 jogadores.',
    'Deposit_success_msg': 'Depósito realizado com sucesso',
    'Deposit_refund_msg': 'Seu pagamento foi reembolsado.',
    'Deposit_cancel_msg': 'Depósito falhou',
    'Deposited': ' foram depositados na sua conta com sucesso!',
    'Your_deposit': 'Seu depósito de',
    'Deposit_failed': ' falhou. Por favor, tente novamente!',
    'Sum_of_prizes': 'Premiação',
    'Tournaments': 'Torneios',
    'Add_player': 'Adicionar',
    'Search_player': 'Procurar Jogador',
    'General': 'Geral',
    'More_info': 'Mais info',
    'First_Inning': 'Primeiro Tempo',
    'Second_Inning': 'Segundo Tempo',
    'Inning': 'Tempo',
    'Event': 'Evento',
    'Good_luck': 'Boa sorte!',
    'Show_More': 'Mostrar mais',
    'Penalty_dispute': 'Disputa de pênaltis',
    'Match_cancelled': 'Partida cancelada',
    'Data_not_available': 'Dados não disponíveis',
    'Position': 'Posiçao',
    'Confirm': 'Confirmar',
    'Players_not_found': 'Jogadores não encontrados',
    'Clear_Filters': 'Limpar filtros'
  }
}
