import React, { lazy, Suspense } from 'react'
import LoginHeader from '../components/loginHeader'
import Loading from '../../../component/Loading'
import { FormattedMessage } from 'react-intl'
const VerificationForm = lazy(() => import('./Verification'))

function Verification (props) {
  return (
    <div className="auth-box">
      <LoginHeader backURL='/login' title={<FormattedMessage id='Verification'/>} />
      <Suspense fallback={<Loading />}>
        <VerificationForm {...props}/>
      </Suspense>
    </div>
  )
}

export default Verification
