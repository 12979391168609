import axios from '../../axios/instanceAxios'
import storage from '../../localStorage/localStorage'

import {
  CHANGE_LANGUAGE,
  GET_CURRENCY,
  GET_BACKGROUND,
  CLEAR_BACKGROUND_MESSAGE,
  CLEAR_CURRENCY_MESSAGE,
  MAINTENANCE_MODE,
  CLEAR_MAINTENANCE_MODE
} from "../../constants";

//change the languages
export const changeLanguage = ({ language = 'en-US' }) => {
  return (dispatch) => {
    storage.setItem('language', language)
    dispatch({
      type: CHANGE_LANGUAGE,
      payload: { language }
    })
  }
}

// get currency logo
export const getCurrency = () => (dispatch) => {
  dispatch({ type: CLEAR_CURRENCY_MESSAGE })
  axios
    .get(`/currency/v1`)
    .then((response) => {
      dispatch({
        type: GET_CURRENCY,
        payload: {
          currencyLogo: response.data.data.sLogo,
          resStatus: true,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_CURRENCY,
        payload: {
          currencyLogo: 'R$',
          resStatus: false,
        },
      });
    });
};

// get the background image
export const getBackground = () => (dispatch) => {
  dispatch({ type: CLEAR_BACKGROUND_MESSAGE })
  axios
    .get(`/side-background/v1`)
    .then((response) => {
      dispatch({
        type: GET_BACKGROUND,
        payload: {
          sImage: response.data.data.sImage,
          sBackImage: response.data.data.sBackImage,
          resStatus: true,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_BACKGROUND,
        payload: {
          sImage: '',
          sBackImage: '',
          resStatus: false,
        },
      });
    });
};

// Get the maintaince data 
export const isMaintenanceMode = () => (dispatch) => {
  dispatch({
    type: CLEAR_MAINTENANCE_MODE
  })
  axios
    .get(`/maintenance-mode/v1`)
    .then((response) => {
      dispatch({
        type: MAINTENANCE_MODE,
        payload: {
          data: response.data.data,
          resStatus: true,
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: MAINTENANCE_MODE,
        payload: {
          data: null,
          resStatus: false,
        },
      });
    });
};