/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Button, Form, FormGroup, Input, Label, Alert } from 'reactstrap'
import { verifyEmail, verifyPassword, verifyMobileNumber, verifyLength } from '../../../helper'
import { FormattedMessage } from 'react-intl'
import LoginPage from 'Common/src/components/Auth/LoginPage'
import PropTypes from 'prop-types'
// import { OldSocialLogin as SocialLogin } from 'react-social-login'
import Loading from '../../../component/Loading'
import { Link } from 'react-router-dom'
// import googleIcon from '../../../assests/images/google_icon.svg'
// import facebookIcon from '../../../assests/images/facebook_icon.svg'
import wrongIcon from '../../../assests/images/wrongIcon.svg'
import rightIcon from '../../../assests/images/rightIcon.svg'
const classNames = require('classnames')

function LoginForm (props) {
  const { loading, Login, message, modalMessage, nOtpSend, setMessage, setModalMessage, VerifyOTP } = props
  const [userName, setUserName] = useState('')
  const [Password, setPassword] = useState('')
  const [errPassword, setErrPassword] = useState('')
  const [errUserName, setErrUserName] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [token, setToken] = useState('')
  const previousProps = useRef({ nOtpSend }).current

  // first time Otp send time redirect to verification page.
  useEffect(() => {
    if (previousProps.nOtpSend !== nOtpSend) {
      if (nOtpSend) {
        // props.history.push('/verification', { userName, Password })
        const deviceId = localStorage.getItem('ID')
        VerifyOTP(userName, verifyEmail(userName) ? 'E' : 'M', 'L', 1234, deviceId)
      }
    }
    return () => {
      previousProps.nOtpSend = nOtpSend
    }
  }, [nOtpSend])

  // after sucessfuly refgister redirect to sign up
  // useEffect(() => {
  //   if (socialRegisterData) {
  //     props.history.push({ pathname: '/sign-up', state: { socialRegisterData, token } })
  //   }
  // }, [socialRegisterData])

  // set the username and password value
  function handleChange (event, type) {
    switch (type) {
      case 'UserName':
        // if (verifyMobileNumber(event.target.value) || verifyEmail(event.target.value)) {
        //   setErrUserName('')
        // } else if (!verifyLength(event.target.value, 1)) {
        //   setErrUserName(<FormattedMessage id="Common_Req_Email_Mobile" />)
        // } else {
        //   setErrUserName(<FormattedMessage id="Common_loginEmailPass" />)
        // }
        setUserName(event.target.value)
        break
      case 'Password':
        // if (verifyPassword(event.target.value)) {
        //   setErrPassword('')
        // } else {
        //   setErrPassword(<FormattedMessage id="Common_PassLength" />)
        // }
        setPassword(event.target.value)
        break
      default:
        break
    }
  }

  // Login with username and password
  function logIn (e) {
    e.preventDefault()
    if (verifyPassword(Password) && verifyEmail(userName)) {
      const deviceId = localStorage.getItem('ID')
      Login('W', userName, Password, deviceId)
    } else {
      // if (!verifyLength(userName, 1)) {
      //   setErrUserName(<FormattedMessage id="Common_Required" />)
      // }
      // if (!verifyLength(Password, 1)) {
      //   setErrPassword(<FormattedMessage id="Common_Required" />)
      // }
      setMessage(<FormattedMessage id="Valid_Credentials" />)
      setModalMessage(true)
      setTimeout(() => {
        setModalMessage(false)
      }, 2000)
    }
  }

  // Google Login
  // function googleLogin (user, err) {
  //   if (user && user._token && user._token.idToken) {
  //     // googleSignUp('G', user._token.idToken)
  //     setToken(user._token.idToken)
  //   }
  // }

  // Facebook Login
  // function facebookLogin (user, err) {
  //   if (user && user._token && user._token.accessToken) {
  //     // googleSignUp('F', user._token.accessToken)
  //     setToken(user._token.accessToken)
  //   }
  // }
  return (
    <>
      {
        modalMessage
          ? (
            <Fragment>
              <Alert color="primary" isOpen={modalMessage}>{message}</Alert>
            </Fragment>
            )
          : ''
      }
      {loading && <Loading />}
      <Form className="form login">
        <FormGroup className="c-input">
          <Input autocomplete="off" className={classNames({ 'hash-contain': userName, error: errUserName })} id="userName" onChange={(e) => { handleChange(e, 'UserName') }} type="text" />
          <Label className="label m-0" for="userName" ><FormattedMessage id="SignUp_Usernames" /></Label>
          {userName && (!errUserName) && <img alt={<FormattedMessage id='Success' />} className='success-img' src={rightIcon} />}
          {errUserName && <img alt={<FormattedMessage id='Error' />} className='error-img' src={wrongIcon} />}
          <p className="error-text">{errUserName}</p>
        </FormGroup>
        <FormGroup className="c-input mb-0">
          <Input autocomplete="off"
            className={classNames({ 'hash-contain': Password, error: errPassword })}
            id="password"
            onChange={(e) => { handleChange(e, 'Password') }}
            type="password"
          />
          <Label className="label m-0" for="password" ><FormattedMessage id="Login_Password" /></Label>
          {Password && (!errPassword) && <img alt={<FormattedMessage id='Success' />} className='success-img' src={rightIcon} />}
          {errPassword && <img alt={<FormattedMessage id='Error' />} className='error-img' src={wrongIcon} />}
          <p className="error-text">{errPassword}</p>
        </FormGroup>
        <FormGroup>
          <div className="text-right">
            <Link className="forgot-link mb-3" to="/forgot-password"><FormattedMessage id="Login_ForgetPassword" /></Link>
          </div>
        </FormGroup>
        <Button block className='submit-button btn-secondary' disabled={!Password || !userName} onClick={logIn} type='submit'><FormattedMessage id="Login_login" /></Button>
        <div className="b-link">
          <FormattedMessage id="Login_noAcc" />
          {' '}
          <Link className='signup-text' title="Sign up" to="/sign-up"><FormattedMessage id="Login_signUp" /></Link>
        </div>
        {/* <div className="d-flex flex-column align-items-center login-with">
          <p className="small-text"><FormattedMessage id="Login_withLogin" /></p>
          <div className='fullWidth'>
            <SocialLogin
              provider='google'
              appId='218538323308-p1bf5od94pbdfna1rstq3s1kea8gpgfr.apps.googleusercontent.com'
              key='AIzaSyBbVb54ZxgNwG-c3ImBDBRS2OZrlVO_23s'
              callback={googleLogin}
              scope={'https://www.googleapis.com/auth/user.gender.read'}
            >
              <div className="s-btn m-0 btn btn-secondary btn-block">
                <img src={googleIcon} alt="Google" />
                <FormattedMessage id="Login_conGoogle" />
              </div>
            </SocialLogin>
          </div>
          <div className='socialIcon'>
            <SocialLogin
              provider='facebook'
              appId='255241426395097'
              callback={facebookLogin}
            >
              <div className="s-btn m-0 btn btn-secondary btn-block">
                <img src={facebookIcon} alt="Facebook" width="34px" />
                <FormattedMessage id="Login_conFacebook" />
              </div>
            </SocialLogin>
          </div>
        </div> */}
      </Form>
    </>
  )
}

LoginForm.propTypes = {
  Login: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      message: PropTypes.string
    })
  }),
  loading: PropTypes.bool,
  modalMessage: PropTypes.bool,
  nOtpSend: PropTypes.bool,
  socialRegisterData: PropTypes.bool,
  message: PropTypes.string,
  handleChange: PropTypes.func,
  googleSignUp: PropTypes.func,
  setMessage: PropTypes.func,
  setModalMessage: PropTypes.func,
  VerifyOTP: PropTypes.func
}

export default LoginPage(LoginForm)
