import React, { lazy, Suspense } from 'react'
import UserHeader from '../../User/components/UserHeader'
import Loading from '../../../component/Loading'
import { FormattedMessage } from 'react-intl'
const ComplaintsList = lazy(() => import('./ComplaintsList'))

function PointSystem (props) {
  return (
    <>
      <UserHeader {...props} title={<FormattedMessage id='More_Feedback' />} backURL='/more'/>
      <Suspense fallback={<Loading />}>
        <ComplaintsList {...props}/>
      </Suspense>
    </>
  )
}

export default PointSystem
