import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import HeaderText from '../../../assests/images/vrf_logo_black.svg'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import qs from 'query-string'

function LoginHeader (props) {
  const { backURL, title } = props
  const location = useLocation()
  const obj = qs.parse(location.search)
  return (
    <div className={title ? 'auth-header d-flex align-items-center' : 'auth-header d-flex align-items-center justify-content-center'}>
      {location.pathname === '/login'
        ? <Link className="icon-left btn-icon" to="/"></Link>
        : <Link className="icon-left btn-icon" to={{
          pathname: backURL,
          search: `?${qs.stringify({
                shareCode: obj?.shareCode || undefined
              })}`,
          state: location?.state
        }}></Link>}
      {title ? <p className='auth-header-title'>{ title }</p> : <img src={HeaderText} alt={<FormattedMessage id='Cartola_Pix' />} />}
    </div>
  )
}

LoginHeader.propTypes = {
  backURL: PropTypes.string,
  title: PropTypes.string
}

export default LoginHeader
