import React from 'react'
import { FormattedMessage } from 'react-intl'
import UserHeader from '../../User/components/UserHeader'
import ChatsPage from './Chats'
function Chats (props) {
  return (
    <>
      <UserHeader {...props} title={<FormattedMessage id='Chat' />} />
      <ChatsPage />
    </>
  )
}
export default Chats
