import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
function SkeletonMoreData (props) {
  const { length } = props
  return (
    <Fragment>
      {Array(length)
        .fill()
        .map((index, i) => (
          <div key={i} className='user-container'>
            <div className="userprofile mt-3">
              <ul className='p-links'>
                <li style={{ textAlign: 'left' }}>
                  <Skeleton className='mt-3 mb-3' height={20} width={280}/>
                </li>
                <li style={{ textAlign: 'left' }}>
                  <Skeleton className='mt-3 mb-3' height={20} width={280}/>
                </li>
                <li style={{ textAlign: 'left' }}>
                  <Skeleton className='mt-3 mb-3' height={20} width={280}/>
                </li>
                <li style={{ textAlign: 'left' }}>
                  <Skeleton className='mt-3 mb-3' height={20} width={280}/>
                </li>
                <li style={{ textAlign: 'left' }}>
                  <Skeleton className='mt-3 mb-3' height={20} width={280}/>
                </li>
                <li style={{ textAlign: 'left' }}>
                  <Skeleton className='mt-3 mb-3' height={20} width={280}/>
                </li>
              </ul>
            </div>
            <p className='mt-3 moreVersion'><Skeleton className='mt-3 mb-3' height={20} width={150}/></p>
          </div>
        ))}
    </Fragment>
  )
}

SkeletonMoreData.propTypes = {
  leagueDetails: PropTypes.bool,
  length: PropTypes.number
}

export default SkeletonMoreData
