import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import UserHeader from '../components/UserHeader'
import DepositPage from './Deposit'
function Deposit (props) {
  const [activeTab, setActiveTab] = useState(true)
  return (
    <>
      <UserHeader
        {...props}
        title={<FormattedMessage id='Deposit' />}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        backURL={props?.location?.state?.backUrl || '/profile'}
        />
      <DepositPage {...props} payment setActiveTab={setActiveTab} activeTab={activeTab}/>
    </>
  )
}

Deposit.propTypes = {
  location: PropTypes.object
}

export default Deposit
