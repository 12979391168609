/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import PlayerImage from '../../../assests/images/PlayerImage.png'
import PlayerDetails from 'Common/src/components/SportsLeagueList/PlayerDetails'
import Loading from '../../../component/SkeletonPlayerLeagueInfo'
import { cricketStaticImage, footballStaticImage, rolesShortForm } from '../../../helper'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import HomeImage from '../../../assests/images/homeIconWhite.svg'
import noPlayerStatsLogo from '../../../assests/images/noplayer-stat-logo.svg'
import info from '../../../assests/images/info-icon-gray.svg'
import { useSelector } from 'react-redux'
import qs from 'query-string'
function ViewPlayerLeagueInfo (props) {
  const { playerData, seasonMatch, loading, match, location, history } = props
  const url = useSelector(state => state.url.getUrl)
  const activeSports = useSelector(state => state.activeSports.activeSports)
  const activeSport = activeSports && activeSports.length > 0 && activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => data)

  const obj = qs.parse(props.location.search)
  const [playerTotalPoints, setPlayerTotalPoints] = useState(0)

  useEffect(() => {
    if (seasonMatch) {
      const points = seasonMatch?.length > 1 ? seasonMatch.reduce((a, b) => a?.nScoredPoints + b?.nScoredPoints) : seasonMatch[0]?.nScoredPoints
      setPlayerTotalPoints(points || 0)
    }
  }, [seasonMatch])

  return (
    <Fragment>
      <div className="player-info p-league-info pb-0">
        <div className="league-header u-header">
          <div className="d-flex align-items-center header-i">
            <button
              className="btn-link icon-left-arrow"
              onClick={() => {
                if (location?.state?.teamCreationPage) {
                  history.push({
                    pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/create-team/${match.params.sportsType}/${match.params.matchID}` : `/create-team/${match.params.sportsType}/${match.params.matchID}`,
                    search: `?${qs.stringify({
                      homePage: obj?.homePage ? 'yes' : undefined
                    })}`,
                    state: { ...location.state }
                  })
                } else if (obj?.index && obj?.userLeague) {
                  history.push({
                    pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/team-preview/${match.params.sportsType}/${match.params.matchID}/${obj?.userLeague}/${match.params.userTeamID}/${parseInt(obj.index)}` : `/team-preview/${match.params.sportsType}/${match.params.matchID}/${obj?.userLeague}/${match.params.userTeamID}/${parseInt(obj.index)}`,
                    search: `?${qs.stringify({
                      homePage: obj?.homePage ? 'yes' : undefined
                    })}`
                  })
                } else {
                  history.push({
                    pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/my-teams-preview/${match.params.sportsType}/${match.params.matchID}/${match.params.userTeamID}` : `/my-teams-preview/${match.params.sportsType}/${match.params.matchID}/${match.params.userTeamID}`,
                    search: `?${qs.stringify({
                      homePage: obj?.homePage ? 'yes' : undefined
                    })}`
                  })
                }
              }}
            />
            <Button className='button-link bg-transparent py-2' tag={Link} to={activeSport && `/home/${(activeSport[0]?.sKey).toLowerCase()}`}><img src={HomeImage} /></Button>
            <div>
              <h1>{playerData && playerData.sName ? playerData.sName : ''}</h1>
              <p>
                {playerData && playerData.sTeamName}
                {', '}
                {playerData && rolesShortForm(playerData.eRole)}
              </p>
            </div>
          </div>
        </div>
        <div className="player-detail d-flex align-items-center">
          <div className="p-i mt-0">
            <img alt="" className="h-100 v-100 fullBorderRadius" src={playerData?.sImage && url ? `${url}${playerData?.sImage}` : match && match.params && match.params.sportsType === 'cricket' ? cricketStaticImage(playerData && playerData.eRole) : match && match.params && match.params.sportsType === 'football' ? footballStaticImage(playerData && playerData.eRole) : PlayerImage} />
          </div>
          <div className="player-d-i d-flex justify-content-around">
            <div className="item m-0">
              <p><FormattedMessage id="Player_credits" /></p>
              <b>{playerData && playerData.nFantasyCredit ? Number.isInteger(playerData.nFantasyCredit) ? playerData.nFantasyCredit : playerData.nFantasyCredit.toFixed(2) : 0}</b>
            </div>
            <div className="item m-0">
              <p><FormattedMessage id="Common_TotalPoints" /></p>
              <b>{playerTotalPoints}</b>
            </div>
          </div>
        </div>
        <ul className="p-league">
          {seasonMatch && seasonMatch.length > 0 && <h2 className="pli-t"><FormattedMessage id="Player_matchWise" /></h2>}
          {
            loading
              ? <Loading />
              : seasonMatch && seasonMatch.length > 0 && seasonMatch?.sort((a, b) => new Date(b?.oMatch?.dStartDate) - new Date(a?.oMatch?.dStartDate)).map(match2 => {
                return (
                  <li key={match._id}
                    onClick={() => {
                      if (location?.state?.teamCreationPage) {
                        history.push({
                          pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/create-team/view-player-info/${match && match.params && (match.params.sportsType).toLowerCase()}/${match && match.params && match.params.matchID}/${match2?._id}` : `/create-team/view-player-info/${match && match.params && (match.params.sportsType).toLowerCase()}/${match && match.params && match.params.matchID}/${match2?._id}`,
                          state: { ...location.state, matchPlayerId: match2?._id },
                          search: `?${qs.stringify({
                        playerLeagueInfo: 'y',
                        homePage: obj?.homePage ? 'y' : undefined,
                        userLeague: obj?.userLeague || undefined,
                        index: obj?.index ? parseInt(obj?.index) : undefined
                      })}`
                        })
                      } else {
                        history.push({
                          pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/view-player-Info/${match && match.params && (match.params.sportsType).toLowerCase()}/${match && match.params && match.params.matchID}/${match && match.params && match.params.userTeamID}/${match?.params?.playerId}` : `/view-player-Info/${match && match.params && (match.params.sportsType).toLowerCase()}/${match && match.params && match.params.matchID}/${match && match.params && match.params.userTeamID}/${match?.params?.playerId}`,
                          state: { matchPlayerId: match2._id },
                          search: `?${qs.stringify({
                        playerLeagueInfo: 'y',
                        homePage: obj?.homePage ? 'y' : undefined,
                        userLeague: obj?.userLeague || undefined,
                        index: obj?.index ? parseInt(obj?.index) : undefined
                      })}`
                        })
                      }
                    }}
                  >
                    <div className="d-flex">
                      <h3 className="l-name">
                        {match2?.oMatch?.sName}
                        {' '}
                        <span className='pl-2'><img src={info} /></span>
                      </h3>
                      <span className="l-date">
                        {match2?.oMatch?.dStartDate && moment(match2.oMatch.dStartDate).format('MMM. D, YYYY')}
                        {' '}
                      </span>
                    </div>
                    <div className="d-flex">
                      <div className="l-state">
                        <p><FormattedMessage id="Player_selectedBy" /></p>
                        <b>
                          {match2?.nSetBy}
                          {' '}
                          <FormattedMessage id="Common_perc" />
                        </b>
                      </div>
                      <div className="l-state text-center">
                        <p><FormattedMessage id="Player_credits" /></p>
                        <b>{Number.isInteger(match2?.nFantasyCredit) ? match2?.nFantasyCredit : match2?.nFantasyCredit.toFixed(2)}</b>
                      </div>
                      <div className="l-state text-right">
                        <p><FormattedMessage id="Player_points" /></p>
                        <b>{match2?.nScoredPoints}</b>
                      </div>
                    </div>
                  </li>
                )
              })
          }
          {
            seasonMatch?.length <= 0 && (
              <div className="noplayer-stat">
                <img src={noPlayerStatsLogo} />
                <FormattedMessage id="Player_NoMatchList" />
              </div>
            )
          }
        </ul>
      </div>
    </Fragment>
  )
}
ViewPlayerLeagueInfo.propTypes = {
  onBackClick: PropTypes.func,
  onPlayerInfoClick: PropTypes.func,
  playerData: PropTypes.shape({
    eRole: PropTypes.string,
    sName: PropTypes.string,
    nFantasyCredit: PropTypes.number,
    nScoredPoints: PropTypes.number,
    sImage: PropTypes.string
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      matchID: PropTypes.string,
      playerId: PropTypes.string,
      userTeamID: PropTypes.string,
      sportsType: PropTypes.string
    }),
    _id: PropTypes.string
  }),
  history: PropTypes.object,
  nScoredPoints: PropTypes.number,
  seasonMatch: PropTypes.array,
  loading: PropTypes.bool,
  pId: PropTypes.string
}
export default PlayerDetails(ViewPlayerLeagueInfo)
