import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { VerificationSendOTP, VerificationVerifyOTP } from '../../redux/actions/auth.js'
import { UpdateUserProfile } from '../../redux/actions/profile'
import storage from '../../localStorage/localStorage'
export const Verification = (Component) => {
  return (props) => {
    const dispatch = useDispatch()
    const [modalMessage, setModalMessage] = useState(false)
    const [verifiedOtp, setVerified] = useState(false)
    const [sendedOTP, setSendedOTP] = useState(false)
    
    const token = useSelector(state => state.auth.token)
    const resStatus = useSelector(state => state.auth.resStatus)
    const resMessage = useSelector(state => state.auth.resMessage)
    const sendOtp = useSelector(state => state.auth.sendOtp)
    const otpVerified = useSelector(state => state.auth.otpVerified)
    const userInfo = useSelector(state => state.auth.userData)
    const FirebaseToken = useSelector(state => state.auth.FirebaseToken)
    const [loading, setLoading] = useState(false)
    const previousProps = useRef({ resStatus, resMessage, otpVerified, sendOtp }).current

    useEffect(() => {
      setSendedOTP(false)
      setVerified(false)
    }, [])

    useEffect(() => {
      if (previousProps.sendOtp !== sendOtp) {  // handle the response
        if (sendOtp) {
          setSendedOTP(sendOtp)
          setLoading(false)
        }
      }
      return () => {
        previousProps.sendOtp = sendOtp
      }
    }, [sendOtp])
  
    useEffect(() => {
      if (previousProps.otpVerified !== otpVerified) {  // handle the response
        if (otpVerified !== null) {
          setVerified(otpVerified)
          setLoading(false)
        }
      }
      return () => {
        previousProps.otpVerified = otpVerified
      }
    }, [otpVerified])

    useEffect(() => { // Handle api response
      if (previousProps.resMessage !== resMessage) {
        if (resMessage) {
          setLoading(false)
          setModalMessage(true)
          setTimeout(() => setModalMessage(false), 3000)
        }
      }
      return () => {
        previousProps.resMessage = resMessage
      }
    }, [resStatus, resMessage])

    function updateProfile(platform, type, userData) {
      if (token) {
        dispatch(UpdateUserProfile(platform, type, userData, token))
        setLoading(true)
      } 
    }

    function onSendOTP(mobileNumber, Type, Auth){
      if (token) {
        dispatch(VerificationSendOTP(mobileNumber, Type, Auth, token))
        setLoading(true)
      }
    }
    function onVerifyOTP(mobileNumber, Type, Auth, sCode){
      const deviceId = storage.getItem('ID')
      token && dispatch(VerificationVerifyOTP(mobileNumber, Type, Auth, sCode, deviceId, token, FirebaseToken))
    }
    return (
      <Component
        {...props}
        resStatus={resStatus}
        modalMessage={modalMessage}
        loading={loading}
        setLoading={setLoading}
        userInfo={userInfo}
        resMessage={resMessage}
        updateProfile={updateProfile}
        onSendOTP={onSendOTP}
        onVerifyOTP={onVerifyOTP}
        sendOtp={sendedOTP}
        otpVerified={verifiedOtp}
        />
    )
  }
}
export default Verification