import React, { lazy, Suspense } from 'react'
import LoginHeader from '../../components/loginHeader'
import Loading from '../../../../component/Loading'
import PropTypes from 'prop-types'
const ValidateCPFForm = lazy(() => import('./ValidateCPF'))

function ValidateCPF (props) {
  const { location } = props
  return (
    <div className="auth-box">
      <LoginHeader backURL={location?.state?.path === '/' ? '/' : '/login'} />
      <Suspense fallback={<Loading />}>
        <ValidateCPFForm {...props}/>
      </Suspense>
    </div>
  )
}

ValidateCPF.propTypes = {
  location: PropTypes.object
}
export default ValidateCPF
