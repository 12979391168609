import React from 'react'
import { Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import logo from '../../assests/images/vrf_welcome_logo.svg'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

function Welcome (props) {
  const { location } = props

  const activeSports = useSelector(state => state.activeSports.activeSports)
  const activeSport = activeSports && activeSports.length > 0 && activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => data)

  return (
    <div className="d-flex justify-content-center align-items-center h-100 welcome">
      <img alt={<FormattedMessage id='Cartola_Pix' />} src={logo} width="300px" />
      <div className="welcome-inner">
        <Link className="btn btn-purple d-block" to={activeSport && `/home/${(activeSport[0]?.sKey).toLowerCase()}/v1`}>
          {' '}
          <FormattedMessage id="Welcome_LetsP" />
        </Link>
        <div className="welcome-login-block text-center">
          <Row>
            <Col className="col-6">
              <p className="small-text m-0"><FormattedMessage id="Welcome_NewUser" /></p>
              <Link to={{ pathname: '/sign-up', state: { path: location?.pathname } }}><FormattedMessage id="Welcome_SignUp" /></Link>
            </Col>
            <Col className="col-6">
              <p className="small-text m-0"><FormattedMessage id="Welcome_AlUser" /></p>
              <Link to="/login"><FormattedMessage id="Welcome_Login" /></Link>
            </Col>
          </Row>
        </div>
        {/* <div className="welcome-footer"><FormattedMessage id="Welcome_Khelo" /><span className='endless-text'><FormattedMessage id="Welcome_Endless" className='endless-text' /></span></div> */}
      </div>
    </div>
  )
}
Welcome.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      id: PropTypes.string
    })
  })
}
export default Welcome
