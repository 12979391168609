import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMyTeamPlayerList } from '../../redux/actions/team'
import { getUniquePlayers, getUniquePlayersLeague } from '../../redux/actions/player'
import { getMatchDetails } from '../../redux/actions/match'

export const MatchDetails = (Component) => {
  return (props) => {
    const dispatch = useDispatch()
    const matchDetails = useSelector(state => state.match.matchDetails)
    const token = useSelector(state => state.auth.token)
    const getUrlLink = useSelector(state => state.url.getUrl)
    const teamPlayerList = useSelector(state => state.team.teamPlayerList)
    const uniquePlayerList = useSelector(state => state.player.uniquePlayerList)
    const uniquePlayerLeagueList = useSelector(state => state.player.uniquePlayerLeagueList)

    function onGetMatchDetails(ID) {
      if (ID && token) {
        dispatch(getMatchDetails(ID, '', token))
      }
    }
    
    function onMatchPlayer(id) {
      if (id) {
        dispatch(getMyTeamPlayerList(id))
      }
    }

    function onUniquePlayer(id) {
      if (token && id) {
        dispatch(getUniquePlayers(id, token))
      }
    }

    function onUniquePlayerLeague(id) {
      if (token && id) {
        dispatch(getUniquePlayersLeague(id, token))
      }
    }
    return (
      <Component
        {...props}
        data={matchDetails || props.location.state && props.location.state.data}
        url={getUrlLink}
        onMatchPlayer={onMatchPlayer}
        onUniquePlayer={onUniquePlayer}
        onUniquePlayerLeague={onUniquePlayerLeague}
        teamPlayerList={teamPlayerList}
        uniquePlayerList={uniquePlayerList}
        uniquePlayerLeagueList={uniquePlayerLeagueList}
        onGetMatchDetails={onGetMatchDetails}
        token={token}
      />
    )
  }
}

export default MatchDetails

