import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, NavLink, useHistory } from 'react-router-dom'
// import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
// import { Row } from 'reactstrap'
import { currencyConverter } from '../../../helper'
import Logo from '../../../assests/images/vrf_logo.svg'
import Cricket from '../../../assests/images/cricket.svg'
import CricketFill from '../../../assests/images/cricketFill.svg'
import Kabaddi from '../../../assests/images/kabaddi.svg'
import BasketBall from '../../../assests/images/Basketball.svg'
import BasketBallFill from '../../../assests/images/BasketballFill.svg'
import Football from '../../../assests/images/football.svg'
import FootballFill from '../../../assests/images/footballFill.svg'
import walletIcon from '../../../assests/images/ic_wallet.svg'
import notificationIcon from '../../../assests/images/notification-icon.svg'
import UserNotification from '../../../../../common/src/components/User/UserNotification'
import { GetUserProfile } from '../../../../../common/src/redux/actions/profile'
import { FormattedMessage } from 'react-intl'

function HomeHeader (props) {
  const { sportsRequired, setPaymentSlide, nCount, token, activeSports, onGetActiveSports, setMainIndex, match, active, showNotificationIcon } = props
  const [totalBalance, setTotalBalance] = useState(0)

  const history = useHistory()
  const dispatch = useDispatch()
  const currencyLogo = useSelector(state => state.settings.currencyLogo)
  const userInfo = useSelector(state => state.profile.userInfo)

  const previousProps = useRef({
    match,
    totalBalance
  }).current

  useEffect(() => {
    (!activeSports || activeSports.length === 0) && token && sportsRequired && onGetActiveSports()
    dispatch(GetUserProfile(token))
  }, [token])

  useEffect(() => {
    if (previousProps.totalBalance !== userInfo?.nCurrentTotalBalance) {
      setTotalBalance(userInfo?.nCurrentTotalBalance)
    }

    return () => {
      previousProps.totalBalance = userInfo?.nCurrentTotalBalance
    }
  }, [userInfo])

  useEffect(() => {
    activeSports && activeSports.length !== 0 && activeSports.map((data, index) => {
      const name = data?.sKey.toLowerCase()
      match && match.params && match.params.sportsType === name && setMainIndex && setMainIndex(index + 1)
      return null
    })
  }, [activeSports])

  useEffect(() => {
    if (previousProps.match !== match) {
      if (match && match.params) {
        activeSports && activeSports.length !== 0 && activeSports.map((data, index) => {
          const name = data?.sKey.toLowerCase()
          match?.params?.sportsType === name && setMainIndex && setMainIndex(index + 1)
          return null
        })
      }
    }
    return () => {
      previousProps.match = match
    }
  }, [match && match.params && match.params.sportsType])

  function redirectToDeposit () {
    history.push({ pathname: '/deposit', state: { backUrl: history.location.pathname } })
  }

  return (
    <div className="home-header">
      <div className="header-i d-flex align-items-center justify-content-between">
        <img src={Logo} alt={<FormattedMessage id='Cartola_Pix' />} width='122'/>
        {token && <ul className="d-flex m-0 ht-link">
          {history?.location?.pathname !== '/more' &&
            <li onClick={redirectToDeposit} className='d-flex align-items-end flex-column deposite-text-header'>
              <p>{currencyLogo}{totalBalance ? currencyConverter(totalBalance) : 0}</p>
              <p><FormattedMessage id='Deposit' /></p>
            </li>
          }
          {showNotificationIcon && <li>
            <Link to='/notifications'>
              <img src={notificationIcon} alt={<FormattedMessage id='Notifications' />} width={20}></img>
              {nCount && nCount.nUnreadCount > 0 ? <span className="count">{nCount && nCount.nUnreadCount}</span> : '' }
            </Link>
          </li>}
          {
            props.showBalance && (
              <li className='mr-2 mt-2 ml-2' role='button'> <img src={walletIcon} onClick={() => setPaymentSlide(true)}></img> </li>
            )
          }
        </ul>}
      </div>
      {!active
        ? <ul className="d-flex justify-content-around align-items-center match-links sports m-0 fixHeight">
          {
            activeSports && activeSports.length > 0
              ? activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => {
                return (
                <li key={data.sKey}>
                  <NavLink activeClassName='active' to={props.home ? `/home/${data?.sKey.toLowerCase()}` : `/matches/${data?.sKey.toLowerCase()}`} >
                    <img
                      src={ data.sKey === 'CRICKET' ? (history?.location?.pathname?.includes('/cricket') ? CricketFill : Cricket) : data.sKey === 'FOOTBALL' ? (history?.location?.pathname?.includes('/football') ? FootballFill : Football) : data.sKey === 'KABADDI' ? Kabaddi : data.sKey === 'BASKETBALL' ? (history?.location?.pathname?.includes('basketball') ? BasketBallFill : BasketBall) : Cricket}
                      alt={data.sKey} />
                    <div
                      className='sportsText'>
                      {data.sName.charAt(0).toUpperCase()}{data.sName.slice(1).toLowerCase()}
                    </div>
                  </NavLink>
                </li>
                )
              })
              : ''
          }
        </ul>
        : ''
      }
    </div>
  )
}

HomeHeader.propTypes = {
  active: PropTypes.bool,
  sportsRequired: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      sportsType: PropTypes.string
    }),
    path: PropTypes.string,
    url: PropTypes.string
  }),
  home: PropTypes.bool,
  GetCount: PropTypes.func,
  setMainIndex: PropTypes.func,
  setPaymentSlide: PropTypes.func,
  showBalance: PropTypes.bool,
  onGetActiveSports: PropTypes.func,
  nCount: PropTypes.shape({
    nUnreadCount: PropTypes.number
  }),
  activeSports: PropTypes.array,
  token: PropTypes.string,
  location: PropTypes.object,
  showNotificationIcon: PropTypes.bool
}

export default UserNotification(HomeHeader)
