import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import UserHeader from '../../User/components/UserHeader'
import Loading from '../../../component/Loading'
import { FormattedMessage } from 'react-intl'
const PointSystemPage = lazy(() => import('./PointSystem'))

function PointSystem (props) {
  return (
    <>
      {/* <UserHeader {...props} title={<FormattedMessage id='More_PSystem' />} backURL='/more'/> */}
      <UserHeader {...props} title={<FormattedMessage id='More_PSystem' />} backURL={props?.match?.path === '/point-system/v1' ? '/more/v1' : '/more'}/>
      <Suspense fallback={<Loading />}>
        <PointSystemPage {...props}/>
      </Suspense>
    </>
  )
}

PointSystem.propTypes = {
  match: PropTypes.object
}

export default PointSystem
