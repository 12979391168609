import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMoreList } from '../../redux/actions/more'

function MorePage(Component) {
  return (props) => {
    const { match } = props
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const token = useSelector(state => state.auth.token)
    const moreContentList = useSelector(state => state.more.moreContentList)
    const resMessage = useSelector(state => state.more.resMessage)
    const resStatus = useSelector(state => state.more.resStatus)
    const previousProps = useRef({
      moreContentList, resMessage, resStatus
    }).current
    useEffect(() => { // fetch the more list.
      if (match && match.params && !match.params.slug) {
        dispatch(getMoreList())
      }
      setLoading(true)
    }, [token])

    useEffect(() => { // handle the response
      if (previousProps.resMessage !== resMessage) {
        if (resMessage) {
          setLoading(false)
        }
      }
      return () => {
        previousProps.resMessage = resMessage
      }
    }, [resStatus, resMessage])

    useEffect(() => { // separate the list from the name.
      if (previousProps.moreContentList !== moreContentList) {
        if (moreContentList) {
          const teams = {}
          if (moreContentList) {
            moreContentList && moreContentList.length !== 0 && moreContentList.sort((a,b) => a.nPriority - b.nPriority).forEach((league) => {
              if (!league.sCategory) {
                teams[league.sTitle] = []
                teams[league.sTitle].push(league)
              } else {
                if (!teams[league.sCategory]) {
                  teams[league.sCategory] = []
                }
                teams[league.sCategory].push({ ...league })
              }
            })
            setLoading(false)
            setData(teams)
          }
        }
      }
      return () => {
        previousProps.moreContentList = moreContentList
      }
    }, [moreContentList])

    return (
      <Component
        {...props}
        loading={loading}
        data={data}
        token={token}
      />
    )
  }
}

export default MorePage
