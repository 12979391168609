import React, { lazy, Suspense } from 'react'
import UserHeader from '../../User/components/UserHeader'
import PropTypes from 'prop-types'
import Loading from '../../../component/Loading'
import { FormattedMessage } from 'react-intl'
const GameLeaderboardDetailPage = lazy(() => import('./GameLeaderboardDetail'))
function GameLeaderboardDetail (props) {
  const { match, data } = props
  return (
    <>
      {/* <UserHeader {...props} title={<FormattedMessage id='LeaderBoard' />} backURL={match && match.params && match.params.detailsId ? { pathname: `/game/leaderboard/${match.params.detailsId}`, state: { data } } : '/game/leaderboard'}/> */}
      <UserHeader {...props} title={<FormattedMessage id='LeaderBoard' />} backURL={match && match.params && match.params.detailsId ? { pathname: match.path === '/game/leaderboard/details/:detailsId/v1' ? `/game/leaderboard/${match.params.detailsId}/v1` : `/game/leaderboard/${match.params.detailsId}`, state: { data } } : match.path === '/game/leaderboard/:id/v1' ? '/game/leader-board/v1' : '/game/leaderboard'}/>
      <Suspense fallback={<Loading />}>
        <GameLeaderboardDetailPage {...props} />
      </Suspense>
    </>
  )
}

GameLeaderboardDetail.propTypes = {
  match: PropTypes.object,
  data: PropTypes.object
}

export default GameLeaderboardDetail
