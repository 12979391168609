import React from 'react'
import PropTypes from 'prop-types'
import UserHeader from '../../User/components/UserHeader'
import ShareContestPage from './ShareContest'
import { FormattedMessage } from 'react-intl'
function ShareContest (props) {
  const { match, history, location } = props

  return (
    <>
      <UserHeader {...props} title={history?.location?.pathname?.includes('tournament') ? location?.state?.matchDetails?.sName : <FormattedMessage id='Invite_A_Friend' />} backURL={history?.location?.pathname?.includes('tournament') ? `/tournament/leagues/${match?.params?.sportsType}/${match.params?.id}?activeTab=2` : `/leagues/${match?.params?.sportsType}/${match.params?.id}?activeTab=2`}/>
      <ShareContestPage {...props} matchId={match && match.params && match.params.id}/>
    </>
  )
}

ShareContest.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object
}

export default ShareContest
