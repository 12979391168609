import axios from '../../axios/instanceAxios'
import {
    MATCH_LIST,
    MATCH_DETAILS,
    MY_UPCOMING_MATCH_LIST,
    GET_HOME_BANNER,
    CLEAR_MATCH_MESSAGE,
    CLEAR_MATCH_LIST,
    GET_BANNER_STATICS,
    TOURNAMENT_LIST,
    CLEAR_TOURNAMENT_LIST,
    TOURNAMENT_INFO_LIST
} from "../../constants";
import { catchBlankData } from '../../helper'

const errMsg = 'Server is unavailable.'

export const getMatchList = (sportsType) => async (dispatch) => { // matches list
  dispatch({ type: CLEAR_MATCH_MESSAGE })
  dispatch({ type: CLEAR_MATCH_LIST })
  await axios.get(`/match/list/v1?sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: MATCH_LIST,
      payload: {
        resMessage: response.data.message,
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
      dispatch(catchBlankData(MATCH_LIST))
  })
}

export const getMatchDetails = (id, sportsType, token) => (dispatch) => { // get one match details
  dispatch({ type: CLEAR_MATCH_MESSAGE })
  axios.get(`/match/${id}/v1?sportsType=${sportsType}`, { headers: { Authorization: token } }).then((response) => {
      dispatch({
          type: MATCH_DETAILS,
          payload: {
              resMessage: response.data.message,
              data: response.data.data,
              resStatus: true
          }
      })
  }).catch(() => {
      dispatch(catchBlankData(MATCH_DETAILS))
  })
}

export const myMatchesList = (sportsType, token, type, eType) => async (dispatch) => { // my upcoming match list
  await axios.get(`/my-matches/list/v4?sportsType=${sportsType}&type=${type}&eType=${eType}`, { headers: { Authorization: token } }).then((response) => {
    dispatch({
      type: MY_UPCOMING_MATCH_LIST,
      payload: {
          data: response.data.data,
          resMessage: response.data.message,
          resStatus: true
      }
    })
  }).catch(() => {
      dispatch(catchBlankData(MY_UPCOMING_MATCH_LIST))
  })
}

export const GetHomeBanner = (place) => (dispatch) => { // home banner list
  axios.get(`/banner/list/${place}/v1`).then((response) => {
    dispatch({
      type: GET_HOME_BANNER,
      payload: {
          bannerData: response.data.data,
          resMessage: response.data.message,
          resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: GET_HOME_BANNER,
      payload: {
        bannerData: [],
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const getBannerStatics = (ID, token) => (dispatch) => { // banner statics
  axios.post(`/banner/log/${ID}/v1`, {}, { headers: { Authorization: token } }).then((response) => {
    dispatch({
        type: GET_BANNER_STATICS,
        payload: {
            resMessage: response.data.message,
            resStatus: true
        }
    })
  }).catch((error) => {
    dispatch({
        type: GET_BANNER_STATICS,
        payload: {
            resMessage: error.response ? error.response.data.message : errMsg,
            resStatus: false
        }
    })
  })
}

export const getTournamentList = (sportsType) => async (dispatch) => {
  dispatch({ type: CLEAR_TOURNAMENT_LIST })
  await axios.get(`/tournament/list/v1?sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: TOURNAMENT_LIST,
      payload: {
        resMessage: response.data.message,
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(TOURNAMENT_LIST))
  })
}

export const getTournamentInfoList = (sportsType) => async (dispatch) => {
  dispatch({ type: CLEAR_TOURNAMENT_LIST })
  await axios.get(`/match-league/info/list/v1?sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: TOURNAMENT_INFO_LIST,
      payload: {
        data: response.data.data,
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(TOURNAMENT_INFO_LIST))
  })
}