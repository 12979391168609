/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment, useRef, Suspense } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Card, CardBody, CardFooter, CardHeader, Alert, Form, FormGroup, Input, Label, Table } from 'reactstrap'
import { verifyLength, isUpperCase, currencyConverter } from '../../../helper'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import Loading from '../../../component/Loading'
import MyTeam from '../components/MyTeam'
import { joinPrivateContest, joinTeam } from '../../../Analytics.js'
import PrivateJoinContest from 'Common/src/components/SportsLeagueList/PrivateJoinContest'
import qs from 'query-string'
import classNames from 'classnames'
function JoinContestPage (props) {
  const {
    resMessage,
    verifyContestDetails,
    verifyingContest,
    isTeamList,
    match,
    joiningContest,
    modalOpen,
    joinedContest,
    sucessFullyJoin,
    updatedTeamList,
    loading,
    amountData,
    verifiedId,
    location,
    onMyJoinList,
    teamPlayerList,
    onFetchMatchPlayer,
    token,
    currencyLogo,
    onGetUserProfile,
    userInfo,
    onFetchTournamentPlayer,
    history
  } = props
  const [alert, setAlert] = useState(false)
  const [code, setCode] = useState('')
  const [sportType, setSportType] = useState('')
  const [matchId, setMatchId] = useState('')
  const [tournamentId, setTournamentId] = useState('')
  const [errCode, setErrCode] = useState('')
  const [userTeams, setUserTeams] = useState([])
  const [modalMessage, setModalMessage] = useState(false)
  const [selectedAll, setSelectedAll] = useState(false)
  const [modalMessage2, setModalMessage2] = useState(false)
  const [totalPay, setTotalPay] = useState(0)
  const [totalPayFinal, setTotalPayFinal] = useState(0)
  const [noOfJoin, setNoOfJoin] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const [updatedFilterData, setUpdatedFilterData] = useState([])
  const mainTeamList = useSelector(state => state.team.teamList)
  const previousProps = useRef({ verifyContestDetails, updatedTeamList, joinedContest, sucessFullyJoin, amountData }).current

  useEffect(() => {
    const obj = qs.parse(location.search)
    if (match && match.params && match.params.id) {
      if (history?.location?.pathname?.includes('tournament')) {
        setTournamentId(match && match.params && match.params.id)
      } else {
        setMatchId(match && match.params && match.params.id)
      }
    } else {
      if (obj.matchId) {
        setMatchId(obj.matchId)
      } else if (obj.tournamentId) {
        setTournamentId(obj.tournamentId)
      }
    }
    if (match?.params?.sportsType) {
      const sport = match.params.sportsType
      setSportType(sport)
    } else {
      if (obj.sportsType) {
        const sport = obj.sportsType
        setSportType(sport)
      }
    }
  }, [])

  useEffect(() => {
    const obj = qs.parse(location.search)
    if (obj) {
      if (obj.code) {
        setCode(obj.code)
      }
      if ((obj.matchId && obj.sportsType) || (obj.tournamentId && obj.sportsType)) {
        const sport = obj.sportsType
        setSportType(sport)
        if (obj?.matchId) {
          setMatchId(obj.matchId)
        } else if (obj?.tournamentId) {
          setTournamentId(obj?.tournamentId)
        }
        props.history.push({
          pathname: obj?.matchId ? `/join-contest/${sport.toLowerCase()}/${obj.matchId}` : obj?.tournamentId ? `/join-contest/${sport.toLowerCase()}/${obj.tournamentId}` : '',
          search: `?${qs.stringify({
            code: obj?.code || undefined
          })}`
        })
      }
    }
    if (match && match.params && match.params.id && (teamPlayerList?.length === 0 || !teamPlayerList)) {
      if (history?.location?.pathname?.includes('tournament')) {
        setTournamentId(match && match.params && match.params.id)
        onFetchTournamentPlayer(match && match.params && match.params.id)
      } else {
        setMatchId(match && match.params && match.params.id)
        onFetchMatchPlayer(match && match.params && match.params.id)
      }
    }
    if (obj && obj.matchId && (teamPlayerList?.length === 0 || !teamPlayerList)) {
      onFetchMatchPlayer(obj && obj.matchId)
    } else if (obj && obj.tournamentId && (teamPlayerList?.length === 0 || !teamPlayerList)) {
      onFetchTournamentPlayer(obj && obj.tournamentId)
    }
    // if (match?.params?.sportsType) {
    //   const sport = match.params.sportsType
    //   setSportType(sport)
    //   isUpperCase(sport) && props.history.push(`/join-contest/${sport.toLowerCase()}/${match.params.id}`)
    // }
  }, [token])

  useEffect(() => {
    if (selectedAll) {
      let updatedSelectedTeam = []
      if (updatedTeamList && updatedTeamList.length > 0) {
        updatedSelectedTeam = updatedTeamList.map(data => data._id)
        setUserTeams(updatedSelectedTeam)
      } else if (mainTeamList && mainTeamList.length > 0) {
        updatedSelectedTeam = mainTeamList.map(data => data._id)
        setUserTeams(updatedSelectedTeam)
      }
    } else {
      setUserTeams([])
    }
  }, [selectedAll])

  useEffect(() => {
    if (previousProps.sucessFullyJoin !== sucessFullyJoin) {
      if (sucessFullyJoin) {
        history.push({ pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/leagues/${(sportType).toLowerCase()}/${tournamentId}` : `/leagues/${(sportType).toLowerCase()}/${matchId}`, state: { message: resMessage } })
      }
    }
    return () => {
      previousProps.sucessFullyJoin = sucessFullyJoin
    }
  }, [sucessFullyJoin])

  useEffect(() => {
    if (previousProps.verifyContestDetails !== verifyContestDetails) {
      if (verifyContestDetails && verifyContestDetails._id) {
        if (isTeamList) {
          setModalMessage(true)
        } else {
          history.push({ pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/create-team/${(sportType).toLowerCase()}/${tournamentId}/join/${verifyContestDetails._id}/private/${verifyContestDetails.sShareCode}` : `/create-team/${(sportType).toLowerCase()}/${matchId}/join/${verifyContestDetails._id}/private/${verifyContestDetails.sShareCode}` })
        }
      }
    }
    return () => {
      previousProps.verifyContestDetails = verifyContestDetails
    }
  }, [verifyContestDetails])
  useEffect(() => {
    if (previousProps.amountData !== amountData) {
      if (amountData && amountData?.oValue?.nAmount > 0) {
        history.push({
          pathname: '/deposit',
          state: {
            amountData: amountData?.oValue,
            message: 'Insufficient Balance',
            joinData: {
              userTeams, verifiedId, code
            }
          }
        })
      }
    }
    return () => {
      previousProps.amountData = amountData
    }
  }, [amountData])

  useEffect(() => {
    if (previousProps.updatedTeamList !== updatedTeamList) {
      if (updatedTeamList !== null && updatedTeamList.length === 0 && verifyContestDetails?._id && match?.params?.id && code) {
        history.push({ pathname: history?.location?.pathname?.includes('tournament') ? `/tournament/create-team/${(sportType).toLowerCase()}/${tournamentId}/join/${verifiedId}/private/${verifyContestDetails.sShareCode}` : `/create-team/${(sportType).toLowerCase()}/${matchId}/join/${verifiedId}/private/${verifyContestDetails.sShareCode}` })
      }
    }
    return () => {
      previousProps.updatedTeamList = updatedTeamList
    }
  }, [updatedTeamList])

  function handleChangeJoin (event, type) { // set the value
    switch (type) {
      case 'Code':
        if (verifyLength(event.target.value, 4)) {
          setErrCode('')
        } else {
          if (!verifyLength(event.target.value, 4)) {
            setErrCode(<FormattedMessage id="JOIN_ERR" />)
          }
        }
        setCode(event.target.value)
        break
      default:
        break
    }
  }

  function JoinContest () { // join contest
    setModalMessage2(false)
    if (userTeams && userTeams.length > 0 && verifyLength(code, 4) && verifiedId) {
      joiningContest(userTeams, verifiedId, code)
      callJoinContestEvent()
      callJoinTeamEvent()
      setModalMessage(false)
    } else {
      if (!verifyLength(code, 4)) {
        setErrCode(<FormattedMessage id="JOIN_ERR" />)
      }
    }
  }

  function checkTeamJoin (e) {
    e.preventDefault()
    if (verifyLength(code, 4) && match && match.params && match.params.id) {
      verifyingContest(match.params.id, code)
      onMyJoinList(match.params.id)
    }
  }

  function callJoinContestEvent () {
    if (code && match && match.params && match.params.id && location.pathname) {
      joinPrivateContest(code, match.params.id, location.pathname)
    } else {
      code && match && match.params && match.params.id && joinPrivateContest(code, match.params.id, '')
    }
  }

  function callJoinTeamEvent () {
    if (userTeams && match && match.params && match.params.id && location.pathname) {
      joinTeam(userTeams, match.params.id, location.pathname)
    } else {
      userTeams && match && match.params && match.params.id && joinTeam(userTeams, match.params.id, '')
    }
  }

  useEffect(() => {
    if (userInfo !== previousProps.userInfo) {
      if (userInfo && userInfo.nCurrentTotalBalance) {
        const value = verifyContestDetails && updatedFilterData && ((verifyContestDetails.nPrice * updatedFilterData.length) - (userInfo && userInfo.nCurrentTotalBalance))
        setTotalPay(value > 0 ? value.toFixed(2) : 0)
        const value2 = verifyContestDetails?.nPrice * updatedFilterData?.length || 0
        setTotalPayFinal(value2)
      }
    }
    return () => {
      previousProps.userInfo = userInfo
    }
  }, [userInfo])

  return (
    <>
      {alert && alertMessage ? <Alert isOpen={alert} className='select-all' color="primary">{alertMessage}</Alert> : ''}
      {loading && <Loading />}
      {
        modalOpen && resMessage
          ? <Fragment>
            <Alert isOpen={modalOpen} color="primary">{resMessage}</Alert>
          </Fragment>
          : ''
      }
      <div className="user-container join-contest bg-white no-footer">
        <Form className="form">
          <p className="m-msg text-center mt-0"><FormattedMessage id="Contest_inviteCodetoJoinContest" /></p>
          <FormGroup className="c-input">
            <Input type="text" autoComplete='off' maxLength={6} id="InviteCode" onChange={(e) => { handleChangeJoin(e, 'Code') }} value={code} className={classNames({ 'hash-contain': code }) } />
            <Label className="label m-0" for="InviteCode"><FormattedMessage id="Contest_inviteCode" /></Label>
            <p className="error-text">{errCode}</p>
          </FormGroup>
          {/* <div className="btn-bottom text-center"> */}
            <Button type="submit" disabled={!code || errCode} color="primary" className="w-100 d-block" onClick={(e) => checkTeamJoin(e)}><FormattedMessage id="Contest_joinContest" /></Button>
          {/* </div> */}
        </Form>
        {
          modalMessage
            ? <>
              <div className="s-team-bg" onClick={() => {
                setModalMessage(false)
                setSelectedAll(false)
              }}></div>
              <Card className="filter-card select-team promo-card">
                <CardHeader className='d-flex align-items-center justify-content-between m-0'>
                  <button><FormattedMessage id="Team_SelectTeam" /></button>
                  <button onClick={() => {
                    setModalMessage(false)
                    setSelectedAll(false)
                  }} className="icon-close"></button>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="two-button border-0 bg-white m-0 d-flex justify-content-center card-footer">
                    <Button type="submit" className="create-team-button" onClick={() => history.push(`/create-team/${(sportType).toLowerCase()}/${match.params.id}/join/${verifiedId}/private/${verifyContestDetails.sShareCode}`)}><FormattedMessage id="Contest_createeam" /></Button>
                  </div>
                  {
                    verifyContestDetails.bPrivateLeague && verifyContestDetails.bMultipleEntry && (
                      <Fragment>
                        <div className='SelectAll d-flex align-items-center'>
                          <input type="radio" id='name' name="gender" onClick={() => setSelectedAll(!selectedAll)}
                              checked={selectedAll}
                          />
                          <label htmlFor='name'><FormattedMessage id="Team_SelectAll" /> </label>
                        </div>
                      </Fragment>
                    )
                  }
                  {
                    (mainTeamList && mainTeamList.length
                      ? <Fragment>
                        {(
                          mainTeamList && mainTeamList.length !== 0 && mainTeamList.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map((data, index) => {
                            return (
                              <div className='d-flex justify-content-betwee' key={data._id}>
                                <MyTeam {...props} UserTeamChoice upcoming join
                                  setUserTeams={(Id) => setUserTeams(Id)}
                                  userTeams={userTeams}
                                  teamList={mainTeamList} UpdatedTeamList={updatedTeamList} hidden params={match.params.id}
                                  noRefresh={true}
                                  teamId={data._id}
                                  leagueData={verifyContestDetails}
                                  noOfJoin={verifyContestDetails && verifyContestDetails.bMultipleEntry ? verifyContestDetails.nMax - verifyContestDetails.nJoined : 1}
                                  index={index} teamDetails={data} key={data._id} allTeams={mainTeamList}
                                  disabledRadio={updatedTeamList?.find((item) => item._id === data._id)?._id}
                                  disabledRadioFlag={updatedTeamList?.length !== 0}
                                  />
                              </div>
                            )
                          })
                        )
                        }
                      </Fragment>
                      : <Fragment>
                        <div className="no-team d-flex align-items-center justify-content-center">
                          <div className="">
                            <i className="icon-trophy"></i>
                            <h6 className="m-3"><FormattedMessage id="Teams_noTeamAdded" /></h6>
                            <Link to={`/create-team/${(sportType).toLowerCase()}/${match.params.id}/join/${verifiedId}/private/${verifyContestDetails.sShareCode}`} className="btn btn-primary"><FormattedMessage id="Teams_createNew" /></Link>
                          </div>
                        </div>
                      </Fragment>
                    )
                  }
                </CardBody>
                <CardFooter className='p-0 border-0 bg-trnsparent m-0 d-flex justify-content-between'>
                  {/* <Button type="submit" color='border' className="w-100" onClick={() => props.history.push(`/create-team/${(sportType).toLowerCase()}/${matchId}/join/${verifiedId}/private/${verifyContestDetails.sShareCode}`)}><FormattedMessage id="Team_CreateTeam" /></Button> */}
                  <Button type="submit" color='primary-two' className="w-100"
                  disabled={userTeams && userTeams.length === 0}
                    onClick={() => {
                      if ((verifyContestDetails && userTeams) && userTeams.length > (verifyContestDetails.nMax - verifyContestDetails.nJoined)) {
                        setAlert(true)
                        setAlertMessage(<p><FormattedMessage id="Team_Title1" /> {verifyContestDetails.nMax - verifyContestDetails.nJoined || '-'} <FormattedMessage id="Team_Title2" /></p>)
                        setTimeout(() => {
                          setAlertMessage('')
                          setAlert(false)
                        }, 2000)
                        setUserTeams([])
                      } else if (updatedTeamList?.length >= userTeams.length) {
                        const filterData = updatedTeamList.filter(data => userTeams.includes(data._id))
                        onGetUserProfile()
                        setUpdatedFilterData(filterData)
                        setModalMessage2(true)
                        setModalMessage(false)
                      } else {
                        setAlert(true)
                        setAlertMessage(<p><FormattedMessage id="Team_Title1" /> {noOfJoin || '-'} <FormattedMessage id="Team_Title2" /></p>)
                        setTimeout(() => {
                          setAlertMessage('')
                          setAlert(false)
                        }, 2000)
                        setUserTeams([])
                      }
                    }}><FormattedMessage id="League_join" /></Button>
                </CardFooter>
              </Card>
            </>
            : modalMessage2
              ? <>
              {loading && <Loading />}
              <div className="s-team-bg" onClick={() => setModalMessage2(false)}></div>
              <Card className="filter-card select-team promo-card">
                <CardHeader className='d-flex align-items-center justify-content-between m-0'>
                  <button><FormattedMessage id="League_Payment" /></button>
                  <button onClick={() => { setModalMessage2(false) }} className="icon-close"></button>
                </CardHeader>
                <CardBody className="p-0 teamXShawing">
                  <div className='teamJoin'>
                    {
                      updatedFilterData && updatedFilterData.length && (
                        <h3>
                          {updatedFilterData.length} <FormattedMessage id="League_Teams_Selected" />
                        </h3>
                      )
                    }
                  </div>
                  <div className='selectedTeamList'>
                    {
                      updatedFilterData && updatedFilterData.length !== 0
                        ? updatedFilterData.sort((a, b) => a?.sTeamName?.toString().localeCompare(b?.sTeamName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map((data1, index) => {
                          return (
                            <Suspense key={data1._id} fallback={<Loading />}>
                              <MyTeam {...props} upcoming
                                noOfJoin={noOfJoin}
                                params={match && match.params.id} index={index} teamDetails={data1} key={data1._id} allTeams={updatedFilterData} UserTeamChoice viewOnly />
                            </Suspense>
                          )
                        })
                        : ''
                    }
                  </div>
                  <Table className="m-0 bg-white promocode">
                    <thead>
                      <tr>
                        <th><FormattedMessage id="League_Total_Entry" /></th>
                        <th className='rightAlign'>{currencyLogo}{verifyContestDetails && updatedFilterData && currencyConverter(verifyContestDetails.nPrice * updatedFilterData.length)} ( {verifyContestDetails && updatedFilterData && `${currencyConverter(verifyContestDetails.nPrice)} X ${currencyConverter(updatedFilterData.length)}`} )</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><FormattedMessage id="League_In_Wallet" /></td>
                        <td className='rightAlign'>{currencyLogo}{userInfo && userInfo.nCurrentTotalBalance ? currencyConverter(userInfo.nCurrentTotalBalance) : 0}</td>
                      </tr>
                    </tbody>
                    {
                      totalPay && currencyLogo
                        ? (
                        <tfoot>
                          <tr>
                            <td><h1><FormattedMessage id="League_To_Pay" /></h1></td>
                            <td className='rightAlign'>{currencyLogo}{currencyConverter(totalPay)}</td>
                          </tr>
                        </tfoot>
                          )
                        : ''
                    }
                  </Table>
                </CardBody>
                <CardFooter className='p-0 border-0 bg-trnsparent m-0 d-flex justify-content-between'>
                  <Button type="submit" color='primary-two' className="w-100"
                    disabled={userTeams && userTeams.length === 0}
                    onClick={() => JoinContest(userTeams)}>
                    {
                      totalPay > 0
                        ? <FormattedMessage id="User_AddMoney" />
                        : <><FormattedMessage id="League_join" /> (<FormattedMessage id='Pay'/> <FormattedMessage id='Team_Rupee'/>{totalPayFinal})</>
                    }
                  </Button>
                </CardFooter>
              </Card>
            </>
              : ''
        }
      </div>
    </>
  )
}

JoinContestPage.propTypes = {
  isTeamList: PropTypes.bool,
  loading: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.object,
    url: PropTypes.string
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.object
  }),
  amountData: PropTypes.shape({
    oValue: PropTypes.shape({
      nAmount: PropTypes.number
    })
  }),
  verifyingContest: PropTypes.func,
  joiningContest: PropTypes.func,
  resMessage: PropTypes.string,
  onFetchMatchPlayer: PropTypes.func,
  modalOpen: PropTypes.bool,
  joinedContest: PropTypes.bool,
  sucessFullyJoin: PropTypes.bool,
  verifyContestDetails: PropTypes.object,
  modalMessage: PropTypes.string,
  token: PropTypes.string,
  verifiedId: PropTypes.string,
  updatedTeamList: PropTypes.array,
  teamList: PropTypes.array,
  onMyJoinList: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }),
  teamPlayerList: PropTypes.shape({
    length: PropTypes.number
  }),
  currencyLogo: PropTypes.string,
  userInfo: PropTypes.object,
  onGetUserProfile: PropTypes.func,
  onFetchTournamentPlayer: PropTypes.func
}

export default PrivateJoinContest(JoinContestPage)
