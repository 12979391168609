import React from 'react'
import PropTypes from 'prop-types'
import HomeFooter from '../../Home/components/HomeFooter'
import HomeHeader from '../../Home/components/HomeHeader'
import ProfilePage from './Profile'
function Profile (props) {
  return (
    <>
      <HomeHeader active={true} />
      <ProfilePage {...props}/>
      <HomeFooter {...props} isPublic={props.match?.path === '/profile/v1'}/>
    </>
  )
}

Profile.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string
  })
}

export default Profile
