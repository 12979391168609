import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMyTeamList, getUserTeam, getCompareUserTeam, getMyTeamPlayerList, getTournamentMatchPlayer } from '../../redux/actions/team'
import { getMatchDetails } from '../../redux/actions/match'
import { listMatchPlayer } from '../../redux/actions/player'
import { getAllTeamLeaderBoardList, getMyTeamLeaderBoardList } from '../../redux/actions/leaderBoard'
import qs from 'query-string'

export const TeamList = (Component) => {
  return (props) => {
    const { match, activeTab, notCalling, location, history } = props
    const [userTeamData, setUserTeamData] = useState({})
    const [loading, setLoading] = useState(false)
    const [url, setUrl] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [message, setMessage] = useState('')
    const dispatch = useDispatch()
    const teamList = useSelector(state => state.team.teamList)
    const userTeam = useSelector(state => state.team.userTeam)
    const teamPlayerList = useSelector(state => state.team.teamPlayerList)
    const matchDetails = useSelector(state => state.match.matchDetails)
    const getUrlLink = useSelector(state => state.url.getUrl)
    const userCompareTeam = useSelector(state => state.team.userCompareTeam)
    const matchPlayer = useSelector(state => state.player.matchPlayer)
    const myTeamsLeaderBoardList = useSelector(state => state.leaderBoard.myTeamsLeaderBoardList)
    const allLeaderBoardList = useSelector(state => state.leaderBoard.allLeaderBoardList)
    const nPutTime = useSelector(state => state.leaderBoard.nPutTime)
    const token = useSelector(state => state.auth.token)
    const activeSports = useSelector(state => state.activeSports.activeSports)
    const teamResMessage = useSelector(state => state.team.resMessage)
    const playerRoles = activeSports && activeSports.find(sport => sport.sKey === ((match.params.sportsType).toUpperCase())) && activeSports.find(sport => sport.sKey === ((match.params.sportsType).toUpperCase())).aPlayerRoles
    const previousProps = useRef({
      teamList, userTeam, activeTab, userCompareTeam, matchDetails, teamPlayerList, getUrlLink
    }).current
    const obj = qs.parse(location.search)

    useEffect(async () => {
      if((match && match.params && match.params.id) && !(history && history.location && history.location.pathname && history.location.pathname.includes('tournament'))) {
          myTeamPlayerList(match.params.id)
          await !notCalling && myTeamList()
      } else if((match && match.params && match.params.id) && (history && history.location && history.location.pathname && history.location.pathname.includes('tournament'))) {
          getTournamentMatchPlayerFunc(match.params.id)
      }
      if(match && match.params && match.params.matchID && match.params.sportsType && token && !teamPlayerList) {
        if (history && history.location && history.location.pathname && history.location.pathname.includes('tournament')) {
            getTournamentMatchPlayerFunc(match.params.matchID)
          } else {
            myTeamPlayerList(match.params.matchID)
          }
          // dispatch(getMyTeamPlayerList(match.params.matchID, token))
          dispatch(getMatchDetails(match.params.matchID, match.params.sportsType, token))
          dispatch(getMyTeamList(match.params.matchID, token))
          setLoading(true)
      }
      // if (teamPlayerList && teamPlayerList.length >= 1 && (match && match.params && match.params.id && teamPlayerList[0].iMatchId !== match.params.id)) {
      //   await dispatch(getMyTeamPlayerList(match.params.id, token))
      // } else if (teamPlayerList && teamPlayerList.length >= 1 && (match && match.params && match.params.matchID && teamPlayerList[0].iMatchId !== match.params.matchID)) {
      //   await dispatch(getMyTeamPlayerList(match.params.matchID, token))
      // }
    }, [token])

    useEffect(() => {
      if (message) {
        setTimeout(() => setModalOpen(false), 5000)
      }
    }, [message])

    useEffect(() => {
      if (previousProps.teamList !== teamList) { // handle the loader
        if (teamList) {
          setLoading(false)
        }
      }
      return () => {
        previousProps.teamList = teamList
      }
    }, [teamList])

    useEffect(() => {
      if (getUrlLink) {
        setUrl(getUrlLink)
      }
    }, [getUrlLink])

    useEffect(() => {
      if (previousProps.userTeam !== userTeam) { // handle the loader
        if (userTeam) {
          setUserTeamData(userTeam)
          setLoading(false)
        }
      }
      return () => {
        previousProps.userTeam = userTeam
      }
    }, [userTeam])

    useEffect(() => {
      if (previousProps.teamPlayerList !== teamPlayerList) { // handle the loader
        if (teamPlayerList) {
          setLoading(false)
        }
      }
      return () => {
        previousProps.teamPlayerList = teamPlayerList
      }
    }, [teamPlayerList])

    function getUserTeamFun(userTeamId){
      token && dispatch(getUserTeam(userTeamId, token))
    }

    function getUserCompareTeamFun(userTeamId){
      token && dispatch(getCompareUserTeam(userTeamId, token))
    }
  
    function refreshContent() {
      if(match && match.params && (match.params.id || match.params.matchID) && token) {
        if (match.params.id) {
          dispatch(getMyTeamList(match.params.id, token))
        } else {
          dispatch(getMyTeamList(match.params.matchID, token))
        }
        setLoading(true)
      }
    }

    function getMatchPlayerList (Id) {
      if(Id) {
        token && dispatch(listMatchPlayer(Id, token))
      }
    }

    function myTeamList() {
      if (token && match.params.id) {
        dispatch(getMyTeamList(match.params.id, token))
        setLoading(true)
      }
    }

    function myTeamPlayerList(matchId) {
      dispatch(getMyTeamPlayerList(matchId, token))
      setLoading(true)
    }

    function getTournamentMatchPlayerFunc (matchId) {
      dispatch(getTournamentMatchPlayer(matchId, token))
    }

    function getMyTeamLeaderBoardListFunc(leagueId) {
      dispatch(getMyTeamLeaderBoardList(leagueId, token))
    }
    
    function myAllTeamPagination(limit, offset, leagueId) {
      dispatch(getAllTeamLeaderBoardList(limit, offset, leagueId, token, nPutTime))
    }
  
    return(
      <Component
        {...props}
        loading={loading}
        refreshContent={refreshContent}
        getUserTeam={getUserTeamFun}
        getUserCompareTeam={getUserCompareTeamFun}
        getMatchPlayerList={getMatchPlayerList}
        matchPlayer={matchPlayer}
        teamList={teamList}
        userTeam={userTeamData}
        setLoading={setLoading}
        token={token}
        matchDetails={matchDetails}
        teamPlayerList={teamPlayerList}
        myTeamList={myTeamList}
        url={url}
        playerRoles={playerRoles}
        allLeaderBoardList={allLeaderBoardList}
        getMyTeamLeaderBoardListFunc={getMyTeamLeaderBoardListFunc}
        myAllTeamPagination={myAllTeamPagination}
        myTeamsLeaderBoardList={myTeamsLeaderBoardList}
        sportsType={match.params.sportsType}
        teamResMessage={teamResMessage}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        message={message}
        setMessage={setMessage}
      />
    )
  }
}

export default TeamList
