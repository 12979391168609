import React, { useState, lazy, Suspense } from 'react'
import UserHeader from '../../User/components/UserHeader'
import Loading from '../../../component/Loading'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
const CMSContentPage = lazy(() => import('./CMSContentPage'))

function CMSContent (props) {
  const [title, setTitle] = useState('')
  const token = useSelector(state => state.auth.token)

  return (
    <>
      <UserHeader title={title} backURL={token ? '/more' : props.match.path.includes('/v1') ? '/more/v1' : '/sign-up'} {...props}/>
      <Suspense fallback={<Loading />}>
        <CMSContentPage {...props} setTitle={setTitle}/>
      </Suspense>
    </>
  )
}

CMSContent.propTypes = {
  match: PropTypes.object
}

export default CMSContent
