import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { verifyContest, joinContest, resetVerifyContest } from '../../redux/actions/contest'
import { GetUserProfile } from '../../redux/actions/profile'
import { getMyTeamList, getMyJoinList, getMyTeamPlayerList, getTournamentMatchPlayer } from '../../redux/actions/team'


export const Contest = (Component) => {
  return (props) => {
    const { match } = props
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    const [isTeamList, setIsTeamList] = useState(null);
    const [url, setUrl] = useState('')
    const [verifiedId, setVerifiedId] = useState('')
    const [updatedTeamList, setUpdatedTeamList] = useState([]);
    const currencyLogo = useSelector(state => state.settings.currencyLogo)
    const resStatus = useSelector(state => state.contest.resStatus)
    const resMessage = useSelector(state => state.contest.resMessage)
    const joinedContest = useSelector(state => state.contest.joinedContest)
    const amountData = useSelector(state => state.contest.amountData)
    const getUrlLink = useSelector(state => state.url.getUrl)
    const sucessFullyJoin = useSelector(state => state.contest.sucessFullyJoin)
    const verifyContestDetails = useSelector(state => state.contest.verifyContest)
    const teamPlayerList = useSelector(state => state.team.teamPlayerList)
    const teamList = useSelector(state => state.team.teamList)
    const contestJoinList = useSelector(state => state.team.contestJoinList)
    const token = useSelector(state => state.auth.token)
    const userInfo = useSelector(state => state.profile.userInfo)
    const previousProps = useRef({ getUrlLink, resMessage, resStatus, contestJoinList, joinedContest, sucessFullyJoin, verifyContestDetails }).current

    useEffect(() => {
      if (match && match.params && match.params.id) {
        getMyTeamListFun(match.params.id)
      }
    }, [token])

    useEffect(() => {
      if (getUrlLink) {
        setUrl(getUrlLink)
      }
    }, [getUrlLink])

    useEffect(() => {
      if (previousProps.resMessage !== resMessage) { // handle the response
        if(resMessage) {
          setLoading(false)
        }
        if(!resStatus) {
          setModalOpen(true)
        }
      }
      return () => {
        previousProps.resMessage = resMessage
      }
    }, [resMessage, resStatus])

    useEffect(() => {
      if (previousProps.teamList !== teamList) { // handle the response
        if(teamList) {
          if( teamList.length > 0){
          setIsTeamList(true)
        } else {
          setIsTeamList(false)
        }
        setLoading(false)
      }
      }
      return () => {
        previousProps.teamList = teamList
      }
    }, [teamList])

    useEffect(() => {
      if (previousProps.contestJoinList !== contestJoinList) { // handle the response of the updated list
          if(contestJoinList && contestJoinList.length > 0 && teamList && teamList.length > 0 && verifyContestDetails && verifyContestDetails._id) {
            const Data = contestJoinList.find(contest => contest.iMatchLeagueId === verifyContestDetails._id)
            const Teams = Data && Data.aUserTeams && teamList.filter(team => 
              !Data.aUserTeams.includes(team._id)
            )
            if(Teams && Teams.length > 0) {
              setUpdatedTeamList(Teams)
            }else {
              if (Data && Data.aUserTeams && Data.aUserTeams.length > 0) {
                setUpdatedTeamList(Teams)
              } else {
                setUpdatedTeamList(teamList)
              }
            }
          } else {
            if (contestJoinList && contestJoinList.length === 0) {
              setUpdatedTeamList(teamList)
            }
          }
          setLoading(false)
      }
      return () => {
        previousProps.contestJoinList = contestJoinList
      }
    }, [contestJoinList])

    useEffect(() => {
      if (previousProps.verifyContestDetails !== verifyContestDetails) { // handle the response
        if (verifyContestDetails && verifyContestDetails._id) {
          setVerifiedId(verifyContestDetails._id)
          onMyJoinList(match && match.params && match.params.id)
        }
      }
      return () => {
        previousProps.verifyContestDetails = verifyContestDetails
      }
    }, [verifyContestDetails])
    
    useEffect(() => {
      if(modalOpen){
        setTimeout(() => {
          setModalOpen(false)
        }, 3000);
      }
    }, [modalOpen])
    function joinContestFun(userTeamId, matchId, code) {
      token && dispatch(joinContest(userTeamId, matchId, code, token))
      setLoading(true)
    }
    function verifyContestFun(matchId, code) {
      token && dispatch(verifyContest(matchId, code, token))
      setLoading(true)
    }
    function getMyTeamListFun(ID) {
      token && dispatch(getMyTeamList(ID, token))
      setLoading(true)
    }
    function onMyJoinList(ID) {
      token && dispatch(getMyJoinList(ID, token))
      setLoading(true)
    }
    function onResetVerifyContest() {
      dispatch(resetVerifyContest())
    }
    function onFetchMatchPlayer(id) {
      token && dispatch(getMyTeamPlayerList(id, token))
    }

    function onFetchTournamentPlayer (tournamentId) {
      token && dispatch(getTournamentMatchPlayer(tournamentId, token))
    }

    function onGetUserProfile() {
      if (token) {
        dispatch(GetUserProfile(token))
      } 
    }

    return (
      <Component
        {...props}
        token={token}
        loading={loading}
        modalOpen={modalOpen}
        getMyTeamList={getMyTeamListFun}
        isTeamList={isTeamList}
        joinedContest={joinedContest}
        verifyContestDetails={verifyContestDetails}
        verifyingContest={verifyContestFun}
        joiningContest={joinContestFun}
        resMessage={resMessage}
        setModalOpen={setModalOpen}
        updatedTeamList={updatedTeamList}
        onMyJoinList={onMyJoinList}
        amountData={amountData}
        sucessFullyJoin={sucessFullyJoin}
        verifiedId={verifiedId}
        teamPlayerList={teamPlayerList}
        onFetchMatchPlayer={onFetchMatchPlayer}
        onResetVerifyContest={onResetVerifyContest}
        url={url}
        onGetUserProfile={onGetUserProfile}
        currencyLogo={currencyLogo}
        userInfo={userInfo}
        onFetchTournamentPlayer={onFetchTournamentPlayer}
      />
    )
  }
}

export default Contest
