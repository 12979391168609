const dev = {
  elevenWicket: 'https://www.11wickets.com/app',
  environment: 'dev',
  sharePrivateContest: 'https://elevenwickets.page.link/zw1C',
  facebook: 'https://www.facebook.com/',
  facebookAppID: '521270401588372',
  firebaseKey: 'BMQ13pk_LZ2R_UVyWCPyJKfkBiAioHE8oPwBxXlfaxJ4BHf0cJ-DxzMRrleshpRHSw6q74_CmA7ISFJ_np15xT4',
  shareMessage1: 'Hey there! I dare you to join this challenge. Let’s see who takes the crown.This is exclusive invite only contest of Fansportiz for ',
  shareMessage2: 'match.Use below contest code or link to join.Contest code: '
}

const prod = {
  elevenWicket: 'https://www.11wickets.com/app',
  environment: '',
  sharePrivateContest: 'https://elevenwickets.page.link/zw1C',
  facebook: 'https://www.facebook.com/',
  facebookAppID: '521270401588372',
  firebaseKey: 'BMQ13pk_LZ2R_UVyWCPyJKfkBiAioHE8oPwBxXlfaxJ4BHf0cJ-DxzMRrleshpRHSw6q74_CmA7ISFJ_np15xT4',
  shareMessage1: 'Hey there! I dare you to join this challenge. Let’s see who takes the crown.This is exclusive invite only contest of Fansportiz for ',
  shareMessage2: 'match.Use below contest code or link to join.Contest code: '
}

const staging = {
  elevenWicket: 'https://www.11wickets.com/app',
  environment: 'staging',
  sharePrivateContest: 'https://elevenwickets.page.link/zw1C',
  facebook: 'https://www.facebook.com/',
  facebookAppID: '521270401588372',
  firebaseKey: 'BMQ13pk_LZ2R_UVyWCPyJKfkBiAioHE8oPwBxXlfaxJ4BHf0cJ-DxzMRrleshpRHSw6q74_CmA7ISFJ_np15xT4',
  shareMessage1: 'Hey there! I dare you to join this challenge. Let’s see who takes the crown.This is exclusive invite only contest of Fansportiz for ',
  shareMessage2: 'match.Use below contest code or link to join.Contest code: '
}

const test = {
  elevenWicket: 'https://www.11wickets.com/app',
  environment: 'test',
  sharePrivateContest: 'https://elevenwickets.page.link/zw1C',
  facebook: 'https://www.facebook.com/',
  facebookAppID: '521270401588372',
  firebaseKey: 'BMQ13pk_LZ2R_UVyWCPyJKfkBiAioHE8oPwBxXlfaxJ4BHf0cJ-DxzMRrleshpRHSw6q74_CmA7ISFJ_np15xT4',
  shareMessage1: 'Hey there! I dare you to join this challenge. Let’s see who takes the crown.This is exclusive invite only contest of Fansportiz for ',
  shareMessage2: 'match.Use below contest code or link to join.Contest code: '
}
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  module.exports = prod
} else if (process.env.REACT_APP_ENVIRONMENT === 'development') {
  module.exports = dev
} else if (process.env.NODE_ENV === 'test') {
  module.exports = test
} else if (process.env.NODE_ENV === 'staging') {
  module.exports = staging
}
