import React, { Fragment } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import PlayerImage from '../../../assests/images/PlayerImage.png'
import PlayerDetails from 'Common/src/components/SportsLeagueList/PlayerDetails'
import Loading from '../../../component/SkeletonPlayerLeagueInfo'
import { cricketStaticImage, footballStaticImage } from '../../../helper'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import HomeImage from '../../../assests/images/homeIconWhite.svg'
// import noPlayerStatsLogo from '../../../assests/images/noplayer-stat-logo.svg'
import info from '../../../assests/images/info-icon-gray.svg'
import { useSelector } from 'react-redux'

function PlayerLeagueInfo (props) {
  const { playerData, seasonMatch, loading, match, onBackClick, onPlayerInfoClick, playerScorePoints } = props
  const url = useSelector(state => state.url.getUrl)
  const activeSports = useSelector(state => state.activeSports.activeSports)
  const activeSport = activeSports && activeSports.length > 0 && activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => data)

  function goToScoreList (match) {
    playerScorePoints(match._id)
    onPlayerInfoClick()
  }

  return (
    <Fragment>
      <div className="player-info p-league-info pb-0">
        <div className="league-header u-header">
          <div className="d-flex align-items-center header-i">
            <button
              onClick={() => onBackClick()} className="btn-link icon-left-arrow"></button>
            <Button className='button-link bg-transparent py-2' tag={Link} to={activeSport && `/home/${(activeSport[0]?.sKey).toLowerCase()}`}><img src={HomeImage}></img></Button>
            <div>
              <h1>{playerData && playerData.sName ? playerData.sName : ''}</h1>
              <p>{playerData && playerData.sTeamName}{', '}{playerData && playerData.eRole}</p>
            </div>
          </div>
        </div>
        <div className="player-detail d-flex align-items-center">
          <div className="p-i mt-0">
            <img src={playerData?.sImage && url ? `${url}${playerData?.sImage}` : match && match.params && match.params.sportsType === 'cricket' ? cricketStaticImage(playerData && playerData.eRole) : match && match.params && match.params.sportsType === 'football' ? footballStaticImage(playerData && playerData.eRole) : PlayerImage} alt="" className="h-100 v-100 fullBorderRadius" />
          </div>
          <div className="player-d-i d-flex justify-content-around">
            <div className="item m-0">
              <p><FormattedMessage id="Player_credits" /></p>
              <b>{playerData && playerData.nFantasyCredit ? Number.isInteger(playerData.nFantasyCredit) ? playerData.nFantasyCredit : playerData.nFantasyCredit.toFixed(2) : 0}</b>
            </div>
            <div className="item m-0">
              <p><FormattedMessage id="Common_TotalPoints" /></p>
              <b>{playerData && playerData.nScoredPoints ? playerData.nScoredPoints : 0}</b>
            </div>
          </div>
        </div>
        <ul className="p-league">
          {seasonMatch && seasonMatch.length > 0 && <h2 className="pli-t"><FormattedMessage id="Player_matchWise" /></h2>}
          {
            loading
              ? <Loading />
              : seasonMatch && seasonMatch.length > 0 && seasonMatch.map(match2 => {
                return (
                <li onClick={() => goToScoreList(match2)}
                key={match._id}>
                  <div className="d-flex">
                    <h3 className="l-name">{match2 && match2.oMatch && match2.oMatch.sName} <span className='pl-2'><img src={info} /></span></h3>
                    <span className="l-date">{match2 && match2.oMatch && match2.oMatch.dStartDate && moment(match2.oMatch.dStartDate).format('ll')} </span>
                  </div>
                  <div className="d-flex">
                    <div className="l-state">
                      <p><FormattedMessage id="Player_selectedBy" /></p>
                      <b>{match2 && match2.nSetBy && match2.nSetBy} <FormattedMessage id="Common_perc" /></b>
                    </div>
                    <div className="l-state text-center">
                      <p><FormattedMessage id="Player_credits" /></p>
                      <b>{match2 && match2.nFantasyCredit && Number.isInteger(match2.nFantasyCredit) ? match2.nFantasyCredit : match2.nFantasyCredit.toFixed(2)}</b>
                    </div>
                    <div className="l-state text-right">
                      <p><FormattedMessage id="Player_points" /></p>
                      <b>{match2 && match2.nScoredPoints && match2.nScoredPoints}</b>
                    </div>
                  </div>
                </li>
                )
              })
          }
          {
            seasonMatch && !seasonMatch.length && (
              <div className="text-center">
                <FormattedMessage id="Player_NoMatchList" />
              </div>
            )
          }
        </ul>
      </div>
    </Fragment>
  )
}
PlayerLeagueInfo.propTypes = {
  onBackClick: PropTypes.func,
  onPlayerInfoClick: PropTypes.func,
  playerData: PropTypes.shape({
    eRole: PropTypes.string,
    sName: PropTypes.string,
    nFantasyCredit: PropTypes.number,
    nScoredPoints: PropTypes.number,
    sImage: PropTypes.string,
    sTeamName: PropTypes.string
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      matchID: PropTypes.string,
      playerId: PropTypes.string,
      userTeamID: PropTypes.string,
      sportsType: PropTypes.string
    }),
    _id: PropTypes.string
  }),
  history: PropTypes.object,
  nScoredPoints: PropTypes.number,
  seasonMatch: PropTypes.array,
  loading: PropTypes.bool,
  pId: PropTypes.string,
  playerScorePoints: PropTypes.func
}
export default PlayerDetails(PlayerLeagueInfo)
