import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

function SkeletonProfile (props) {
  const { length } = props
  return (
    <Fragment>
      {Array(length)
        .fill()
        .map((index, i) => (
          <div key={i} className='user-container'>
            <div className="userprofile" style={{ backgroundColor: 'white', textAlign: 'center' }}>
              <div className='d-flex justify-content-start align-items-center ml-3'>
                <Skeleton className='mt-3' circle={true} height={80} width={80}/>
                <div className='u-name ml-3'>
                  <Skeleton height={20} width={80}/>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-around mt-3">
                <Skeleton height={180} width={450}/>
              </div>
              <div className="d-flex align-items-center justify-content-around mt-3">
                <Skeleton height={50} width={450}/>
              </div>
              <center>
                <div className="u-matchinfo d-flex align-items-center justify-content-center" style={{ textAlign: 'center' }}>
                  <div className='mr-5'>
                    <Skeleton height={95} width={95}/>
                  </div>
                  <div className='ml-2 mr-5'>
                    <Skeleton height={95} width={95}/>
                  </div>
                  <div className='ml-2'>
                    <Skeleton height={95} width={95}/>
                  </div>
                </div>
              </center>
              <div className="d-flex align-items-center justify-content-around mt-3">
                <Skeleton height={200} width={450} className='mb-3'/>
              </div>
            </div>
          </div>
        ))}
    </Fragment>
  )
}

SkeletonProfile.propTypes = {
  leagueDetails: PropTypes.bool,
  length: PropTypes.number
}

export default SkeletonProfile
